import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Dropdown,
  Table,
} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import api from "../../../../../../services/api";
import moment from "moment";
import { Show_Alert } from "../../../../../../store/actions/alert";
import "./styles.css";
import Colors from "../../../../../../styles/Colors";
import Icon from "../../../../../../functions/Icon";

import Realizadas from "./TabsDecubito/DecubitoRealizadas/Realizadas";
import NaoRealizadas from "./TabsDecubito/DecubitoNaoRealizadas/NaoRealizadas";
import Esquecidas from "./TabsDecubito/DecubitoEsquecidas/Esquecidas";
import DuplaChecagem from "./TabsDecubito/DecubitosDuplaChecagem/DuplaChecagem";
import Sensor from "./TabsDecubito/DecubitoSensor/Sensor";
import Suspensas from "./TabsDecubito/DecubitoSuspensas/Suspensas";
import Detalhado from "./TabsDecubito/DecubitoDetalhado/Detalhado";
import VisaoGeral from "./VisaoGeral/VisaoGeral";
import PerfilPaciente from "../../../PerfilPaciente/PerfilPaciente";
import RelatoriosPaciente from "../RelatoriosPaciente";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Angulacoes from "../Angulacoes";

var btns = [
  {
    name: "Visão Geral",
  },
  {
    name: "Sensor",
  },
  {
    name: "Angulações",
  },
  {
    name: "Detalhado",
  },
];

function RelatorioDecubito() {
  const dispatch = useDispatch();
  const location = useLocation();
  const widthScreen = window.innerWidth;
  const patientSelected = useSelector((state) => state.patientSelected);

  const [tab, setTab] = useState(1);

  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  const handleMiddleClick = (e, subTabIndex) => {
    if (e.button === 1) {
      e.preventDefault();
      const currentTab = getQueryParam("tab");
      const url = `${window.location.origin}${window.location.pathname}?tab=${currentTab}&subtab=${subTabIndex}`;
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    const subTab = getQueryParam("subtab");
    if (subTab !== null && !isNaN(subTab)) {
      setTab(parseInt(subTab));
    }
  }, [location]);

  return (
    <div>
      <Row className="tabs">
        <Col
          className="col-internal-report-page justify-content-center"
          xs={4}
          md={2}
          onClick={() => setTab(0)}
          onMouseDown={(e) => handleMiddleClick(e, 0)} // Para a aba 'Indicadores'
        >
          <h6 className={tab === 0 ? "active-text" : ""}>Visão Geral</h6>
        </Col>
        <Col
          className="col-internal-report-page justify-content-center"
          xs={4}
          md={2}
          onClick={() => setTab(1)}
          onMouseDown={(e) => handleMiddleClick(e, 1)} // Para a aba 'Pacientes de Risco'
        >
          <h6 className={tab === 1 ? "active-text" : ""}>Sensor</h6>
        </Col>
        <Col
          className="col-internal-report-page justify-content-center"
          xs={4}
          md={2}
          onClick={() => setTab(2)}
          onMouseDown={(e) => handleMiddleClick(e, 2)} // Para a aba 'Pacientes de Risco'
        >
          <h6 className={tab === 2 ? "active-text" : ""}>Angulações</h6>
        </Col>
        {widthScreen > 770 && (
          <Col
            className="col-internal-report-page justify-content-center"
            xs={4}
            md={2}
            onClick={() => setTab(3)}
            onMouseDown={(e) => handleMiddleClick(e, 3)} // Para a aba 'Pacientes de Risco'
          >
            <h6 className={tab === 3 ? "active-text" : ""}>Detalhado</h6>
          </Col>
        )}
      </Row>
      <Row className="tab-bar">
        <Col
          className={
            "col-tab-internal-report-page " + (tab === 0 ? "active-tab" : "")
          }
          xs={4}
          md={2}
          onClick={() => setTab(0)}
        />
        <Col
          className={
            "col-tab-internal-report-page " + (tab === 1 ? "active-tab" : "")
          }
          xs={4}
          md={2}
          onClick={() => setTab(1)}
        />
        <Col
          className={
            "col-tab-internal-report-page " + (tab === 2 ? "active-tab" : "")
          }
          xs={4}
          md={2}
          onClick={() => setTab(2)}
        />
        {widthScreen > 770 && (
          <Col
            className={
              "col-tab-internal-report-page " + (tab === 3 ? "active-tab" : "")
            }
            xs={4}
            md={2}
            onClick={() => setTab(3)}
          />
        )}
      </Row>
      {widthScreen < 770 && (
        <>
          <Row className="tabs">
            <Col
              className="col-internal-report-page justify-content-center"
              xs={4}
              md={2}
              onClick={() => setTab(3)}
            >
              <h6 className={tab === 3 ? "active-text" : ""}>Detalhado</h6>
            </Col>
          </Row>
          <Row className="tab-bar">
            <Col
              className={
                "col-tab-internal-report-page " +
                (tab === 3 ? "active-tab" : "")
              }
              xs={4}
              md={2}
              onClick={() => setTab(3)}
            />
          </Row>
        </>
      )}
      {tab == 0 && <VisaoGeral />}
      {tab == 1 && <Sensor />}
      {tab == 2 && <Angulacoes />}
      {tab == 3 && <Detalhado />}
      {/* {tab == 3 && <NaoRealizadas />} */}
      {/* {tab == 3 && <Suspensas />}
      {tab == 4 && <Esquecidas />} */}
      {/* {tab == 5 && <DuplaChecagem />} */}
    </div>
  );
}

export default RelatorioDecubito;
