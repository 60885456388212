import React, { useState, useEffect, useRef } from "react";
import { HorizontalBar } from "react-chartjs-2";
import Colors from "../../styles/Colors";
import Icon from "../../functions/Icon";
import EmptyContainer from "./EmptyContainer";
import ModalPatientsRisks from "./ModalData/ModalPatientsRisks";
import { Col, Spinner } from "react-bootstrap";
import { Chart } from "chart.js";

function Comorbidities(props) {
  const { comorbidities, allComorbidities, loading } = props;

  const chartRef = useRef(null);
  const situationalChartRef = useRef(null);
  const [comorbidity, setComorbidity] = useState({});
  const [situationalComorbidity, setSituationalComorbidity] = useState({});
  const [comorbidityHeight, setComorbidityHeight] = useState(null);
  const [patientIds, setPatientIds] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  const COMORBIDITIES = "Pacientes com lesão por pressão";
  const ALL_COMORBIDITES = "Total de pacientes de risco";

  useEffect(() => {
    setComorbidity({});
    setSituationalComorbidity({});
    if (comorbidities && allComorbidities) {
      const arrayComor = {
        situational: [],
        non_situational: [],
      };
      const arrayAllComor = {
        situational: [],
        non_situational: [],
      };
      const arrayLabelComor = {
        situational: [],
        non_situational: [],
      };
      const arrayColorComor = {
        situational: [],
        non_situational: [],
      };
      let comorbiditiesEmptyVerify = true;
      let allComorbiditiesEmptyVerify = true;
      allComorbidities.map((item, index) => {
        if (comorbiditiesEmptyVerify && item.counter > 0)
          comorbiditiesEmptyVerify = false;
        let allCommorbidityItem = comorbidities.find(
          (i) => i.comorbidity_id == item.comorbidity_id
        );
        if (item.is_situational) {
          if (allCommorbidityItem) {
            arrayComor.situational.push(allCommorbidityItem.counter);
            arrayAllComor.situational.push(
              item.counter - allCommorbidityItem.counter
            );
          } else {
            arrayComor.situational.push(0);
            arrayAllComor.situational.push(item.counter);
          }
          arrayColorComor.situational.push(Colors.dark_blue);
          arrayLabelComor.situational.push(item.comorbidity_name);
        } else {
          if (allCommorbidityItem) {
            arrayComor.non_situational.push(allCommorbidityItem.counter);
            arrayAllComor.non_situational.push(
              item.counter - allCommorbidityItem.counter
            );
          } else {
            arrayComor.non_situational.push(0);
            arrayAllComor.non_situational.push(item.counter);
          }
          arrayColorComor.non_situational.push(Colors.dark_blue);
          arrayLabelComor.non_situational.push(item.comorbidity_name);
        }
      });
      var objComorSituational = {
        labels: arrayLabelComor.situational,
        datasets: [
          {
            label: COMORBIDITIES,
            data: arrayComor.situational,
            backgroundColor: arrayColorComor.situational,
          },
          {
            label: ALL_COMORBIDITES,
            data: arrayAllComor.situational,
            backgroundColor: Colors.brand_blue,
          },
        ],
      };
      var objComorNonSituational = {
        labels: arrayLabelComor.non_situational,
        datasets: [
          {
            label: COMORBIDITIES + " NS",
            data: arrayComor.non_situational,
            backgroundColor: arrayColorComor.non_situational,
          },
          {
            label: ALL_COMORBIDITES + " NS",
            data: arrayAllComor.non_situational,
            backgroundColor: Colors.brand_blue,
          },
        ],
      };
      if (comorbiditiesEmptyVerify && allComorbiditiesEmptyVerify) {
        setComorbidity(objComorNonSituational);
        setSituationalComorbidity(objComorSituational);
      } else {
        setComorbidity(objComorNonSituational);
        setSituationalComorbidity(objComorSituational);
      }
      let comorbidadesChartHeight = 0;
      if (objComorNonSituational.labels.length <= 3) {
        comorbidadesChartHeight = 200;
      } else if (
        objComorNonSituational.labels.length > 3 &&
        objComorNonSituational.labels.length < 6
      ) {
        comorbidadesChartHeight = 200;
      } else if (
        objComorNonSituational.labels.length > 6 &&
        objComorNonSituational.labels.length < 10
      ) {
        comorbidadesChartHeight = 250;
      } else {
        comorbidadesChartHeight = 380;
      }
      if (comorbidadesChartHeight > 0) {
        setComorbidityHeight(comorbidadesChartHeight);
      }
    }
  }, [comorbidities, allComorbidities]);

  const optionsComor = {
    onClick: function (evt, item) {
      if (item.length === 0) return;
      let name = "";
      let currentChartRef = null;
      let commorbidityDataObj = null;
      if (
        item[0]._view.datasetLabel &&
        item[0]._view.datasetLabel.includes("NS")
      ) {
        name = "Comorbidade";
        commorbidityDataObj = comorbidity;
        currentChartRef = chartRef.current.chartInstance;
      } else {
        name = "Estado situacional";
        commorbidityDataObj = situationalComorbidity;
        currentChartRef = situationalChartRef.current.chartInstance;
      }

      const elements = currentChartRef.getElementsAtEventForMode(
        evt,
        "nearest",
        { intersect: true },
        true
      );
      if (elements.length > 0) {
        const index = elements[0]._index;
        const datasetIndex = elements[0]._datasetIndex;
        const label = commorbidityDataObj.labels[index];
        const datasetLabel = commorbidityDataObj.datasets[datasetIndex].label
          .replace("NS", "")
          .trim();
        const commorbidityTypeArr =
          datasetLabel === ALL_COMORBIDITES ? allComorbidities : comorbidities;
        const comorbidityItem = commorbidityTypeArr.find(
          (i) => i.comorbidity_name == label.trim()
        );
        if (comorbidityItem) {
          handleModalPatientRisks(
            `${name}: ${comorbidityItem.comorbidity_name}`,
            comorbidityItem.patientIds,
            datasetLabel
          );
        }
      }
    },
    onHover: function (event, chartElement) {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              if (value % 1 === 0) {
                return value;
              }
            },
          },
        },
      ],
      yAxes: [
        {
          barPercentage: 0.5,
          stacked: true,
        },
      ],
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: Colors.darker_gray,
        fontFamily: "Rubik-Regular",
        generateLabels: function (chart) {
          const labelsOriginal =
            Chart.defaults.global.legend.labels.generateLabels(chart);
          const labelsModified = labelsOriginal.map((label) => {
            label.text = label.text.replace("NS", "");
            return label;
          });
          return labelsModified;
        },
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const index = tooltipItem.index;
          const datasetIndex = tooltipItem.datasetIndex;
          const label = data.labels[index];
          const currentValue = data.datasets[datasetIndex].data[index];
          if (
            data.datasets[datasetIndex].label.replace("NS", "").trim() ===
            ALL_COMORBIDITES
          ) {
            const otherValue = data.datasets[0].data[index];
            const totalValue = currentValue + otherValue;
            return `${ALL_COMORBIDITES}: ${totalValue}`;
          } else {
            return `${COMORBIDITIES}: ${currentValue}`;
          }
        },
      },
    },
  };

  function handleModalPatientRisks(title, patientIds, type) {
    setPatientIds(patientIds);
    setSelectedData({
      label: title,
      patientQuantity: patientIds.length,
      type,
    });
    setShowPatientsRisksData(true);
  }

  return (
    <>
      <Col xs={12} md={6} className="mt-4">
        <div className="card-report h-100">
          {loading ? (
            <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="comorbidities h-100">
              <h4>Comorbidades</h4>

              {comorbidity && comorbidityHeight ? (
                <div className="mt-3">
                  <HorizontalBar
                    ref={chartRef}
                    data={comorbidity}
                    options={optionsComor}
                    height={comorbidityHeight}
                  />
                </div>
              ) : (
                <EmptyContainer
                  text="Ainda não há dados sobre comorbidades dos pacientes"
                  iconName="description_1"
                />
              )}
            </div>
          )}
        </div>
      </Col>
      <Col xs={12} md={6} className="mt-4">
        <div className="card-report h-100">
          {loading ? (
            <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="comorbidities h-100">
              <h4>Estados situacionais</h4>

              {situationalComorbidity && comorbidityHeight ? (
                <div className="mt-3">
                  <HorizontalBar
                    ref={situationalChartRef}
                    data={situationalComorbidity}
                    options={optionsComor}
                    height={comorbidityHeight}
                  />
                </div>
              ) : (
                <EmptyContainer
                  text="Ainda não há dados sobre comorbidades dos pacientes"
                  iconName="description_1"
                />
              )}
            </div>
          )}
        </div>
      </Col>
      {showPatientsRisksData && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={selectedData?.label}
          subTitle={
            `${selectedData?.patientQuantity} ${
              selectedData?.patientQuantity === 1 ? "paciente" : "pacientes"
            }` +
            " " +
            `(${selectedData?.type})`
          }
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Fatores de risco",
            "Avaliação de risco",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="populationalData"
        />
      )}
    </>
  );
}

export default Comorbidities;
