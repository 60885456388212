export function Show_Alert(options) {
  return {
    type: "SHOW_ALERT",
    options,
  };
}

export function Hidden_Alert() {
  return {
    type: "HIDDEN_ALERT",
  };
}
