const INITIAL_STATE = {
  dados: [],
};

function evolutionWoundSelected(state = INITIAL_STATE, action) {
  if (action.type === "SET_EVOLUTION_WOUND_SELECTED") {
    return { ...state, dados: action.dados };
  } else if (action.type === "RESET_EVOLUTION_WOUND_SELECTED") {
    state = INITIAL_STATE;
  }

  return state;
}

export default evolutionWoundSelected;
