import React, { useEffect, useState } from "react";
import Colors from "../../styles/Colors";
import Icon from "../../functions/Icon";
import { Pie } from "react-chartjs-2";
import {
  IncidenceStageColor,
  resizeChartBasedOnWidth,
} from "../../functions/utils";
import ModalPatientsRisks from "./ModalInfo/ModalPatientsRisks";
import ModalPatientsRisksData from "./ModalData/ModalPatientsRisks";
import EmptyContainer from "./EmptyContainer";

const arrayFake2 = [
  {
    name: "Fora de risco",
    color: Colors.brand_pink,
  },
  {
    name: "Baixo",
    color: Colors.brand_red,
  },
  {
    name: "Moderado",
    color: Colors.brand_orange,
  },
  {
    name: "Alto",
    color: Colors.brand_yellow,
  },
  {
    name: "Muito alto",
    color: "#1BE099",
  },
];
function RiskPatientsPie(props) {
  const { patientRisks } = props;

  const [selectedData, setSelectedData] = useState(null);
  const [patientIds, setPatientIds] = useState([]);
  const [patientsRisks, setPatientsRisks] = useState(null);
  const [patientsRisksEmpty, setPatientsRisksEmpty] = useState(false);
  const [showPatientsRisksInfo, setShowPatientsRisksInfo] = useState(false);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  useEffect(() => {
    if (patientRisks) {
      var array = [];
      var arrayLabels = [];
      var arrayColors = [];
      var arrayCounter = [];
      var patientsRisksVerify = true;
      patientRisks.map((item, index) => {
        if (patientRisks && item.counter > 0) patientsRisksVerify = false;
        arrayLabels.push(`${item.percentage}% ${item.text}`);
        array.push(item.percentage);
        arrayColors.push(IncidenceStageColor(item.index));
        arrayCounter.push(item.counter);
      });
      var obj = {
        labels: arrayLabels,
        datasets: [
          {
            counter: arrayCounter,
            data: array,
            backgroundColor: arrayColors,
            borderWidth: 0,
          },
        ],
      };
      setPatientsRisks(patientsRisksVerify ? null : obj);
      setPatientsRisksEmpty(patientsRisksVerify);
    }
  }, [patientRisks]);

  const optionsPiePatientRisks = {
    onClick: function (evt, item) {
      if (item.length > 0) {
        const index = item[0]._index;
        const label = patientsRisks.labels[index];
        const counter = patientsRisks.datasets[0].counter[index];
        setSelectedData({
          label: label.split("%")[1],
          patientQuantity: counter,
        });
        setPatientIds(patientRisks[index].patientIds);
        setShowPatientsRisksData(true);
      }
    },
    onHover: function (event, chartElement) {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    legend: {
      position: "right",
      align: "start",
      fullWidth: true,
      labels: {
        fontColor: Colors.darker_gray,
        usePointStyle: true,
        padding: 20,
        fontFamily: "Rubik-Regular",
      },
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return data["labels"][tooltipItem[0]["index"]];
        },
        label: function (tooltipItem, data) {
          var temp = data["datasets"][0]["counter"][tooltipItem["index"]];
          return `Quant. de pacientes: ${temp}`;
        },
      },
    },
  };
  return (
    <>
      <div className="d-flex align-items-center">
        <h4 className="mb-0">Pacientes de risco</h4>
        <button
          className="btn-info-chart"
          onClick={() => setShowPatientsRisksInfo(true)}
        >
          <Icon
            color={Colors.brand_gray}
            size={22}
            icon="info"
            className="ml-2"
          />
        </button>
      </div>

      {patientsRisks ? (
        <div className="d-flex h-100 align-items-center">
          <Pie
            data={patientsRisks}
            options={optionsPiePatientRisks}
            height={resizeChartBasedOnWidth()}
          />
        </div>
      ) : (
        <EmptyContainer
          text="Ainda não há dados sobre o pacientes de risco"
          iconName="description_1"
        />
      )}

      {showPatientsRisksInfo && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksInfo}
          show_modal={showPatientsRisksInfo}
        />
      )}
      {showPatientsRisksData && (
        <ModalPatientsRisksData
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={selectedData?.label}
          subTitle={`${selectedData?.patientQuantity} ${
            selectedData?.patientQuantity === 1 ? "paciente" : "pacientes"
          }`}
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Fatores de risco",
            "Avaliação de risco",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="populationalData"
        />
      )}
    </>
  );
}

export default RiskPatientsPie;
