import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Form,
  Dropdown,
} from "react-bootstrap";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import CheckBox from "../CheckBox";
import RadioBtn from "../RadioBtn";
import Select from "react-select";
import VerifyPwWithUser from "./VerifyPwWithUser";

import list_wounds from "../../functions/list_wounds";
import api from "../../services/api";
import moment from "moment";
import { Show_Alert } from "../../store/actions/alert";
import {
  ConvertToMinutes,
  GetTagColor,
  GetTagName,
  ConvertRuntime,
  userHasPermission,
} from "../../functions/utils";
import patientTab from "../../store/reducers/patientTab";

var originalUsers = null;
var timer = null;

var type_time = [
  {
    label: "horas",
    value: "hours",
  },
  {
    label: "minutos",
    value: "minutes",
  },
  // {
  //   label: "dias",
  //   value: "days",
  // },
];

function ModalWithdrawPatient({ ...props }) {
  const {
    handler_modal,
    show_modal,
    handler_reload,
    reload,
    patient_id,
    notification_id,
    patient_name,
    room,
    subroom,
    is_edit,
  } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [showPw, setShowPw] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");

  const [motiveChooser, setMotiveChooser] = useState({ id: 0 });
  const [listUserChooser, setListUserChooser] = useState([]);
  const [otherMotive, setOtherMotive] = useState("");

  const [listMotives, setListMotives] = useState([]);

  const [wounds, setWounds] = useState([{ wound_id: 0, stage_id: 0 }]);
  const [timeSuspend, setTimeSuspend] = useState("");
  const [suspendForever, setSuspendForever] = useState(false);

  useEffect(() => {
    function LoadMotives() {
      api
        .get(`/motive_discharge_hospitals`)
        .then((response) => {
          if (response.status == 200) {
            setListMotives(response.data);
          }
        })
        .catch((error) => console.log("LoadMotives " + error));
    }

    LoadMotives();
  }, []);

  function VerifyFields() {
    var ret = false;

    //Suspender aviso
    if (
      motiveChooser.id > 0
      // && hasWound != null
    ) {
      ret = true;
    }

    // if (user.dados.permission_id > 3) {
    //   if (listUserChooser.length > 0) {
    //     ret = true;
    //   } else {
    //     ret = false;
    //   }
    // }
    if (!suspendForever) {
      if (timeSuspend == "") {
        ret = false;
      }
    }

    // if (hasWound) {
    //   wounds.map((item) => {
    //     if (item.wound_id == 0 || item.stage_id == 0) ret = false;
    //   });
    // }

    if (motiveChooser?.name == "Outros") {
      if (otherMotive == "") ret = false;
    }
    return ret;
  }

  function Suspend(user_id) {
    setLoadingBtn("flex");
    setShowPw(false);
    // var arrayNurses = [];
    // listUserChooser.map((item) => {
    //   arrayNurses.push({ id: item.id });
    // });

    var id = user_id;
    if (user_id == undefined) id = user.dados.id;
    // if (arrayNurses.length > 0) user_id = arrayNurses[0].id;
    // else user_id = user.dados.id;
    const apiRequest = is_edit ? api.put : api.post;
    apiRequest(`/patients/${patient_id}/discharge_hospital`, {
      user_id: id,
      motive_discharge_hospital_id: motiveChooser.id,
      other_motive: otherMotive,
      is_wound: null,
      wounds,
    })
      .then((response) => {
        if (response.status == 204) {
          console.log("Suspend success");
          console.log(response.data);
          dispatch(
            Show_Alert({
              type: "success",
              msg: is_edit
                ? "Motivo de alta atualizado"
                : "A alta foi informada com sucesso",
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("Suspend error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar essa alteração",
          })
        );
      })
      .finally(() => {
        ResetFields();
        setLoadingBtn("none");
        handler_modal(false);
      });
  }

  function ResetFields() {
    setMotiveChooser({ id: 0 });
    setListUserChooser([]);
    setOtherMotive("");
    setWounds({ wound_id: 0, stage_id: 0 });
  }

  return (
    <>
      <Modal
        show={show_modal}
        onHide={() => handler_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {showPw && (
          <VerifyPwWithUser
            handler_show_pw={setShowPw}
            show_pw={showPw}
            handler_request={Suspend}
          />
        )}
        <Modal.Body className="p-0 py-3">
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <div className="modal-title">
                  {is_edit ? "Editar motivo da " : "Dar "} alta
                </div>
              </Col>
              <Col
                xs={4}
                md={2}
                lg={2}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>
            <Row
              className="mt-3 patient-info"
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <p className="mb-1">Paciente</p>
                <div className="modal-title mb-1" id="patient-name">
                  {patient_name}
                </div>
                <p className="mb-2" id="room-name">
                  {room && subroom == undefined && room}
                  {room !== undefined &&
                    subroom !== undefined &&
                    room + " | " + subroom}
                </p>
              </Col>
            </Row>
          </div>

          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={8} md={10} lg={10} className="pl-0">
                <div className="modal-title"></div>
              </Col>
            </Row>
          </div>

          <div className="modal-list">
            <div style={{ paddingLeft: 40, paddingRight: 40 }}>
              <Row
                className="mt-3 mb-1 modal-text-bold-sm"
                style={{ color: Colors.darkest_gray }}
              >
                Motivo
              </Row>
            </div>

            {listMotives.map((item, index) => {
              return (
                <div
                  key={index}
                  className="modal-item modal-item-edit"
                  onClick={() => {
                    setTimeSuspend("");
                    setSuspendForever(true);
                    setOtherMotive("");
                    setMotiveChooser(item);
                  }}
                >
                  <p
                    style={{
                      color:
                        item.id === motiveChooser?.id
                          ? Colors.brand_blue
                          : Colors.darkest_gray,
                    }}
                    className="modal-name mb-0"
                  >
                    {item.name}
                  </p>

                  <div className="container-check">
                    <input
                      className={`input-round ${
                        item.id != motiveChooser?.id && "radio-inactive"
                      }`}
                      type="radio"
                      checked={item.id === motiveChooser?.id}
                      onClick={() => {
                        setTimeSuspend("");
                        setSuspendForever(true);
                        setOtherMotive("");
                        setMotiveChooser(item);
                      }}
                    />
                  </div>
                </div>
              );
            })}

            {motiveChooser.name == "Outros" && (
              <div className="mb-3 px-4">
                <input
                  className="default-input md"
                  type="text"
                  placeholder="Digite outro motivo aqui"
                  value={otherMotive}
                  onChange={(e) => setOtherMotive(e.target.value)}
                />
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center mx-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-2">
            <div
              className="secondary-btn"
              style={{
                fontSize: "100%",
              }}
              onClick={() => handler_modal(false)}
            >
              Cancelar
            </div>
          </Col>
          <Col xs={6} md={5} className="px-0">
            <button
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                if (userHasPermission(user)) {
                  Suspend();
                  return false;
                }
                setShowPw(true);
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyFields() == true ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                {is_edit ? "Salvar" : "Confirmar"}
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalWithdrawPatient;
