import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import "./styles.css";
import { GetColorAvaliation, userIsAdmin } from "../../functions/utils";
import DischargePatient from "../../components/Modals/DischargePatient";
import VerifyPw from "../../components/Modals/VerifyPw";

import moment from "moment";
import "moment/locale/pt-br";
import { renderPhoto } from "../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../Home/Notificacoes/Notificacoes";
import DeleteDischargedPatient from "../../components/Modals/DeleteDischargedPatient";
moment.locale("pt-br");

function CardPaciente({ history, ...props }) {
  const { handler_reload, reload, handler_reload_beacons } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [paciente, setPaciente] = useState(props.dados);
  const [userCreateOrDischarge, setUserCreateOrDischage] = useState(null);
  const [showDischarge, setShowDischarge] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [userChooser, setUserChooser] = useState(0);

  useEffect(() => {
    setPaciente(props.dados);
    let user = {};
    if (props.status) {
      user = {
        name: props.dados.user_name_origin,
        user_origin_image: props.dados.user_image_origin,
        date: props.dados.created_at,
        action: "Criado por",
      };
    } else {
      user = {
        name: props.dados.user_name_discharge_hospital,
        user_origin_image: props.dados.user_image_discharge_hospital,
        date: props.dados.datetime_discharge_hospital,
        action: "Informado por",
      };
    }
    setUserCreateOrDischage(user);
  }, [props.dados]);

  const handleMouseDown = (e) => {
    dispatch({
      type: "SET_PATIENT_SELECTED",
      dados: { id: paciente.id, name: paciente.name },
    });
    dispatch({ type: "RESET_PATIENT_TAB" });
    if (e.button === 0) {
      history.push({
        pathname: "/Pacientes/Perfil-Paciente",
      });
    } else if (e.button === 1) {
      window.open("/Pacientes/Perfil-Paciente", "_blank");
    }
  };

  const handleClick = (e) => {
    dispatch({ type: "SET_PATIENT_SELECTED", dados: paciente });
    dispatch({ type: "RESET_PATIENT_TAB" });
    if (e.ctrlKey || (navigator.platform.startsWith("Mac") && e.metaKey)) {
      window.open("/Pacientes/Perfil-Paciente", "_blank");
    } else {
      history.push({ pathname: "/Pacientes/Perfil-Paciente" });
    }
  };

  return (
    <>
      {showPw && (
        <VerifyPw
          handler_show_pw={setShowPw}
          show_pw={showPw}
          handler_user={setUserChooser}
          id_user={userChooser}
        />
      )}
      {showDischarge && (
        <DischargePatient
          patient={paciente}
          listPatient={props.listPatient}
          handler_patient={props.handler_patient}
          handler_show_discharge={setShowDischarge}
          show_discharge={showDischarge}
          handler_reload={handler_reload}
          reload={reload}
          handler_user={setUserChooser}
          id_user={userChooser}
          handler_show_pw={setShowPw}
          handler_reload_beacons={handler_reload_beacons}
        />
      )}
      {showDelete && (
        <DeleteDischargedPatient
          patient={paciente}
          handler_show_modal={setShowDelete}
          show_modal={showDelete}
          handler_reload={handler_reload}
          reload={reload}
        />
      )}
      <div
        className="patient-card"
        onClick={handleClick}
        onAuxClick={handleMouseDown}
      >
        <Row className="title-row">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
            }}
          >
            <div className="title limit_word">{paciente.initials}</div>
            <div className="limit_word">
              {paciente.room == null && paciente.subroom}
              {paciente.room != null &&
                paciente.subroom != null &&
                `${paciente.room}  |  ${paciente.subroom}`}
            </div>
          </div>
          {props.status && (
            <div
              className="secondary-btn"
              style={{ height: 35, width: 106 }}
              onClick={(e) => {
                e.stopPropagation();
                setShowDischarge(true);
              }}
            >
              <Icon
                color={Colors.brand_pink}
                size={20}
                icon="user-check"
                className="mr-2"
              />
              Dar alta
            </div>
          )}
          {!props.status && userIsAdmin(user) && (
            <div
              className="secondary-btn"
              style={{ height: 35, width: 106 }}
              onClick={(e) => {
                e.stopPropagation();
                setShowDelete(true);
              }}
            >
              <Icon
                color={Colors.brand_pink}
                size={20}
                icon="trash"
                className="mr-2"
              />
              Excluir
            </div>
          )}
        </Row>
        {props.status && (
          <>
            <Row className="mt-3 dados">
              <Icon color={Colors.brand_blue} size={22} icon="band-aid" />
              <div className="d-flex" style={{ marginLeft: 8 }}>
                {paciente.admission_wound !== null ||
                paciente.qtd_wounds_incidence > 0 ||
                paciente.qtd_wounds_admission > 0 ? (
                  <>
                    {paciente.qtd_wounds_incidence === 0 &&
                      paciente.qtd_wounds_admission === 0 && (
                        <p>Nenhuma lesão</p>
                      )}
                    {paciente.qtd_wounds_incidence > 0 && (
                      <p>
                        {paciente.qtd_wounds_incidence}{" "}
                        {paciente.qtd_wounds_incidence > 1
                          ? "lesões incididas"
                          : "lesão incidida"}
                      </p>
                    )}
                  </>
                ) : (
                  <p>Não informado</p>
                )}

                {paciente.qtd_wounds_incidence > 0 &&
                  paciente.qtd_wounds_admission > 0 && (
                    <p className="mx-2">|</p>
                  )}

                {paciente.qtd_wounds_admission > 0 && (
                  <p>
                    {paciente.qtd_wounds_admission}{" "}
                    {paciente.qtd_wounds_admission > 1
                      ? "lesões admitidas"
                      : "lesão admitida"}
                  </p>
                )}
              </div>
            </Row>
            <Row className="mt-2 dados">
              <div
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 14,
                  backgroundColor: GetColorAvaliation(paciente.score),
                  marginLeft: 1,
                  marginRight: 11,
                }}
              />
              {paciente.score > 0 && (
                <p style={{ marginRight: 2 }}>Escore {paciente.score}:</p>
              )}
              <p>{paciente.score_description}</p>
            </Row>
          </>
        )}

        {!props.status && (
          <>
            <Row className="mt-3 dados">
              <Icon color={Colors.brand_blue} size={22} icon="enter" />
              <div style={{ marginLeft: 8 }}>
                {paciente.motive_discharge_hospital_name}{" "}
                {paciente.other_motive_discharge_hospital_name
                  ? `(${paciente.other_motive_discharge_hospital_name})`
                  : ""}
              </div>
            </Row>
            <Row className="mt-2 dados">
              <Icon color={Colors.brand_blue} size={22} icon="band-aid" />
              <div className="d-flex" style={{ marginLeft: 8 }}>
                {paciente.admission_wound !== null ||
                paciente.qtd_wounds_incidence > 0 ||
                paciente.qtd_wounds_admission > 0 ? (
                  <>
                    {paciente.qtd_wounds_incidence === 0 &&
                      paciente.qtd_wounds_admission === 0 && (
                        <p>Nenhuma lesão</p>
                      )}
                    {paciente.qtd_wounds_incidence > 0 && (
                      <p>
                        {paciente.qtd_wounds_incidence}{" "}
                        {paciente.qtd_wounds_incidence > 1
                          ? "lesões incididas"
                          : "lesão incidida"}
                      </p>
                    )}
                  </>
                ) : (
                  <p>Não informado</p>
                )}

                {paciente.qtd_wounds_incidence > 0 &&
                  paciente.qtd_wounds_admission > 0 && (
                    <p className="mx-2">|</p>
                  )}

                {paciente.qtd_wounds_admission > 0 && (
                  <p>
                    {paciente.qtd_wounds_admission}{" "}
                    {paciente.qtd_wounds_admission > 1
                      ? "lesões admitidas"
                      : "lesão admitida"}
                  </p>
                )}
              </div>
            </Row>
          </>
        )}

        {userCreateOrDischarge && (
          <Row className="mt-3 container-user">
            <div className="c-profile-dropdown mr-2">
              {renderPhoto(
                makeUserDataToPhoto(
                  userCreateOrDischarge.name,
                  userCreateOrDischarge.image !== null
                    ? userCreateOrDischarge.image
                    : null
                )
              )}
            </div>
            <div className="dados ml-2">
              <p className="m-0 name">
                {userCreateOrDischarge.action} {userCreateOrDischarge.name}
              </p>

              <p className="m-0 date">
                {moment(userCreateOrDischarge.date).format("LLL")}
              </p>
            </div>
          </Row>
        )}
      </div>
    </>
  );
}

export default CardPaciente;
