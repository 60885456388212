import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import "../../styles/global.css";
import CheckBox from "../CheckBox";
import { useDispatch } from "react-redux";

function GeneralDisplayedData(props) {
  const { headers, handler } = props;

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (show) {
      window.addEventListener("click", handleClose);
    }

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [show]);

  const handleDropdownClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {show && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10,
          }}
        />
      )}
      <Dropdown
        className="drop-sort"
        show={show}
        onToggle={() => setShow(!show)}
        onClick={handleDropdownClick}
        style={{
          padding: "9px!important",
          fontSize: "16px!important",
          zIndex: 11,
        }}
      >
        <Dropdown.Toggle
          id="dropdown-sort"
          className={`btn-filter general-display-data`}
        >
          Dados exibidos
        </Dropdown.Toggle>

        <Dropdown.Menu
          rootCloseEvent={"click"}
          className="div-drop-sort"
          style={{
            borderRadius: "10px",
            width: "220px",
          }}
        >
          {headers.map((item, index) => {
            if (item.immutable) return;
            return (
              <div
                className="d-flex"
                style={{
                  margin: "22px 8px",
                  marginTop: index === 1 ? "5px" : "22px",
                  marginBottom: index === headers.length - 1 ? "5px" : "22px",
                }}
              >
                <CheckBox
                  checked={item.selected}
                  value={item.name}
                  size={"20px"}
                  onclick={() => {
                    const newHeaders = headers.map((header) => {
                      if (header.name === item.name) {
                        header.selected = !header.selected;
                      }
                      return header;
                    });
                    dispatch({
                      type: "SET_GENERAL_DATA_HEADERS",
                      headers: newHeaders,
                    });
                    handler(newHeaders);
                  }}
                />{" "}
                <p className="ml-2 general-display-data-label">{item.label}</p>
              </div>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default GeneralDisplayedData;
