import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "react-date-range";
import { Button } from "react-bootstrap";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Colors from "../styles/Colors";
import Icon from "../functions/Icon";
import "../styles/global.css";
import { ptBR } from "date-fns/locale";

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

const customPtBR = {
  ...ptBR,
  localize: {
    ...ptBR.localize,
    day: (n, options) => {
      const dayNames = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
      return options?.width === "abbreviated"
        ? dayNames[n]
        : capitalize(ptBR.localize.day(n, options));
    },
    month: (n, options) => {
      const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      const monthNamesAbbreviated = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      return options?.width === "abbreviated"
        ? monthNamesAbbreviated[n]
        : monthNames[n];
    },
  },
};

function DatePicker({ selectedDate, dateHandler }) {
  const [date, setDate] = useState(new Date(moment(selectedDate).utc(0)));
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const togglePicker = () => setShowPicker(!showPicker);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    dateHandler(moment(newDate).format("YYYY-MM-DD"));
    setShowPicker(false);
  };

  return (
    <div ref={pickerRef} style={{ position: "relative", width: "100%" }}>
      <Button
        style={{
          backgroundColor: "transparent",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#ddd",
          minHeight: 48,
          borderRadius: 10,
        }}
        variant="light"
        className="d-flex btn-date-range align-items-center"
        onClick={togglePicker}
      >
        <Icon
          color={Colors.darker_gray}
          size={22}
          icon="calendar"
          className="mr-2"
        />
        <p className="m-0 text_e-left sensor-filter">
          {selectedDate
            ? moment(selectedDate).format("DD/MM/YYYY")
            : "Selecionar data"}
        </p>
      </Button>

      {showPicker && (
        <div>
          <Calendar
            className="date-picker-calendar"
            locale={customPtBR}
            date={date}
            onChange={(date) => handleDateChange(date)}
            minDate={new Date("2017-01-01")}
            maxDate={new Date()}
          />
        </div>
      )}
    </div>
  );
}

export default DatePicker;
