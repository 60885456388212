import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
  Form,
} from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";
import { NormalizeWord, VerifyMatchesText } from "../../functions/utils";

var unitSelect = null;
var listUnitsOriginal = [];
function UnidadeDropdown(props) {
  const { history, screen } = props;
  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const unit = useSelector((state) => state.unit);
  const updateUnit = useSelector((state) => state.updateUnit);

  // Exibir dropdown
  const [show, setShow] = useState(false);

  const [query, setQuery] = useState("");

  const screensWithoutAllUnits = ["adherence", "decubit-map", "settings"];

  useEffect(() => {
    Search("");
  }, [show]);

  function SetarUnit(item) {
    setQuery("");
    Search("");
    dispatch({ type: "UNIT_SET", selecionado: item });

    // Se o usuário está na tela de perfil do paciente, redirecione para a lista de pacientes
    if (screen === "patient-profile") {
      history.push("/pacientes");
    }
  }

  useEffect(() => {
    unitSelect = unit.selecionado?.id;
  }, []);

  useEffect(() => {
    if (
      screensWithoutAllUnits.includes(screen) &&
      unit.selecionado.url_friendly == "all-units"
    ) {
      const userUnits = userUnit.list.filter(
        (i) => i.url_friendly != "all-units"
      );
      if (userUnits.length > 0) {
        dispatch({ type: "UNIT_SET", selecionado: userUnits[0] });
      }
    }
  }, [screen]);

  useEffect(() => {
    function LoadUnitsUser() {
      api
        .get("/users/units")
        .then((response) => {
          if (response.status == 200) {
            var listUnits = [];
            response.data.map((item) => {
              listUnits.push(item);
            });

            console.log("listUnits HERE");
            console.log(listUnits);

            // Check if unit has deleted
            if (unit.selecionado.id != undefined) {
              var hasUnit = listUnits.some(
                (item) => item.id == unit.selecionado.id
              );
              if (!hasUnit) {
                dispatch({ type: "UNIT_SET", selecionado: response.data[0] });
              }
            }

            listUnitsOriginal = listUnits;
            dispatch({ type: "USER_UNIT_SET", list: listUnits });

            if (
              !["reports", "patients", "patient-profile"].includes(screen) &&
              unit.selecionado?.url_friendly == "all-units"
            ) {
              dispatch({ type: "UNIT_SET", selecionado: response.data[0] });
            }

            if (unit.selecionado.id == undefined) {
              dispatch({ type: "UNIT_SET", selecionado: response.data[0] });
            }
          }
        })
        .catch((error) => {
          console.log("LoadUnitsUser error " + error);
        });
    }

    LoadUnitsUser();
  }, [updateUnit]);

  const Search = (word) => {
    setQuery(word);

    if (listUnitsOriginal.length == 0) listUnitsOriginal = userUnit.list;

    if (word == "") {
      dispatch({ type: "USER_UNIT_SET", list: listUnitsOriginal });
      return false;
    }

    var newList = listUnitsOriginal.filter((item) => {
      const normalize = NormalizeWord(item.name);
      const normalizeQuery = NormalizeWord(word);
      if (normalize.includes(normalizeQuery)) return item;
    });

    dispatch({ type: "USER_UNIT_SET", list: newList });
  };

  if (unit.selecionado?.id != undefined) {
    return (
      <Dropdown
        className="drop-sort px-3"
        onToggle={() => setShow(!show)}
        data-testid="navbar-unit"
      >
        <Dropdown.Toggle
          id="dropdown-unidade"
          className={`btn-unidade ${props.cssClasses}`}
          style={props.cssStyles}
          data-testid="navbar-unit-toggle"
        >
          <div className="mr-3">
            <p className="limit_word" style={{ width: 100 }}>
              {unit.selecionado.name}
            </p>
          </div>
          <Icon color={Colors.darker_gray} size={20} icon="arrow-select" />
        </Dropdown.Toggle>

        <Dropdown.Menu rootCloseEvent={"click"} className="div-drop-sort">
          <div className="my-2">
            <input
              style={{ height: 42 }}
              className="default-input"
              type="text"
              placeholder="Pesquisar"
              value={query}
              onChange={(e) => Search(e.target.value)}
            />
          </div>

          <div className="container-auto-units">
            {userUnit.list.length == 0 ? (
              <p className="no-unit">Nenhum resultado foi encontrado</p>
            ) : (
              userUnit.list.map((item, index) => {
                if (
                  screensWithoutAllUnits.includes(screen) &&
                  item.url_friendly == "all-units"
                ) {
                  return null;
                }
                return (
                  <Dropdown.Item
                    key={index}
                    className="drop-sort-item"
                    onClick={() => SetarUnit(item)}
                    data-testid="navbar-unit-item"
                  >
                    <div
                      style={
                        unit.selecionado.id == item.id
                          ? { color: Colors.brand_blue }
                          : {}
                      }
                    >
                      <p>{item.name}</p>
                    </div>
                  </Dropdown.Item>
                );
              })
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    return null;
  }
}

export default UnidadeDropdown;
