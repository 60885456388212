import React, { useState } from "react";
import { Col, Row, Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

import api from "@Services/api";
import { Show_Alert } from "@Store/actions/alert";
import Colors from "@Styles/Colors";
import Icon from "@Functions/Icon";

import "../../PainelSensores.scss";

function ModalEnableDisable({ ...props }) {
  const dispatch = useDispatch();
  const { handler_show_modal, show_modal, handler_reload, reload, beacon } =
    props;

  console.log("beacon");
  console.log(beacon);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [motive, setMotive] = useState("");

  function Save() {
    setLoadingBtn("flex");

    api
      .put(`/beacons/${beacon.id}`, {
        name: beacon.name,
        mac: beacon.mac,
        status: beacon.status == 1 ? 0 : 1,
        unit_id: beacon.unit_id,
        //motive,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("Save success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: `O sensor foi ${
                response.data.status == 0 ? "inativado" : "ativado"
              }`,
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("Save error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: `Não foi possível ${
              beacon.status == 1 ? "inativar" : "ativar"
            } esse sensor`,
          })
        );
      })
      .finally(() => {
        handler_show_modal(false);
        setLoadingBtn("none");
      });
  }

  function VerifyFields() {
    let verify = true;

    // if (beacon.status == 1) {
    //   if (motive == "") verify = false;
    // }

    return verify;
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-filter"
      data-testid="beacon-panel-toggle"
    >
      <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Col xs={8}>
            <div className="modal-title">
              {beacon.status == 1 ? "Inativar" : "Ativar"} sensor
            </div>
          </Col>
          <Col xs={2} md={2} lg={2} className="pr-0 d-flex justify-content-end">
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </Col>
        </Row>

        <div className="mt-4">
          <p className="text-delete-user">
            O sensor <span>{beacon.name}</span> será{" "}
            {beacon.status == 1 ? "inativado" : "ativado"}, tem certeza que
            deseja continuar?
          </p>
        </div>

        {/* {beacon.status == 1 && (
          <Col xs={12} md={12} className="mt-4 px-0">
            <Form.Group>
              <Form.Label className="field-label">
                Motivo da inativação
              </Form.Label>
              <Form.Control
                className="input-lg"
                value={motive}
                onChange={(v) => setMotive(v.target.value)}
              />
            </Form.Group>
          </Col>
        )} */}
      </Modal.Body>

      <Modal.Footer className="row justify-content-end align-items-center pb-0">
        <Col xs={4} md={3} lg={3} className="px-0 mr-3">
          <div
            className="secondary-btn modal-footer-btn"
            onClick={() => handler_show_modal(false)}
          >
            Cancelar
          </div>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={!VerifyFields() || loadingBtn === "flex"}
            style={{
              backgroundColor: VerifyFields()
                ? Colors.brand_pink
                : Colors.lighter_gray,
              cursor: "pointer",
            }}
            // style={{ backgroundColor: Colors.brand_pink, cursor: "pointer" }}
            className="mb-0 modal-footer-btn btn-confirm"
            onClick={() => Save()}
            data-testid="beacon-panel-toggle-action"
          >
            <p
              className="mb-0"
              style={{
                color: VerifyFields() ? Colors.white : Colors.dark_gray,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              {beacon.status == 1 ? "Inativar" : "Ativar"}
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEnableDisable;
