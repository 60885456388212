const colorDefault = "#7FA7E6";
const mapping_back = {
  name: "back",
  areas: [
    {
      id: 18,
      name: "Occipital",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 20,
      name: "Escápula direita",
      shape: "circle",
      coords: [220, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 19,
      name: "Escápula esquerda",
      shape: "circle",
      coords: [140, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 21,
      name: "Coluna",
      shape: "circle",
      coords: [180, 280, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 23,
      name: "Cotovelo direito",
      shape: "circle",
      coords: [300, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 22,
      name: "Cotovelo esquerdo",
      shape: "circle",
      coords: [70, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 24,
      name: "Sacral",
      shape: "circle",
      coords: [180, 350, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 26,
      name: "Ísquio direito",
      shape: "circle",
      coords: [210, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 25,
      name: "Ísquio esquerdo",
      shape: "circle",
      coords: [150, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 28,
      name: "Calcâneo direito",
      shape: "circle",
      coords: [210, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 27,
      name: "Calcâneo esquerdo",
      shape: "circle",
      coords: [160, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 40,
      name: "Glúteo direito",
      shape: "circle",
      coords: [220, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 41,
      name: "Glúteo esquerdo",
      shape: "circle",
      coords: [140, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 36,
      name: "Interno joelho direito",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 39,
      name: "Interno joelho esquerdo",
      shape: "circle",
      coords: [155, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 37,
      name: "Externo maléolo esquerdo",
      shape: "circle",
      coords: [135, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 38,
      name: "Externo maléolo direito",
      shape: "circle",
      coords: [240, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

export default mapping_back;
