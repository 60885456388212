import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";

import { GetColorAvaliation } from "../../../../../functions/utils";
import Icon from "../../../../../functions/Icon";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import moment from "moment";
import "./styles.css";
import { renderPhoto } from "../../../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../../../Home/Notificacoes/Notificacoes";

function DetailsAvaliationHistoric({ ...props }) {
  const { handler_show_modal, show_modal, avaliation } = props;

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Detalhes da ação</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all mt-3 pb-0">
          <p className="details-text">{avaliation.details.text}</p>
          <div className="container-list-gray mt-3">
            {avaliation.details.details.values.map((item, index) => {
              return (
                <div key={index} className="list-gray-details">
                  <p className="list-gray-details-title mb-0">{item.factor}</p>
                  <p className="list-gray-details-subtitle mb-0">
                    {item.score}
                  </p>
                </div>
              );
            })}
          </div>

          <div
            className="my-3 button-result"
            style={{
              backgroundColor: GetColorAvaliation(
                avaliation.details.details.result
              ),
            }}
          >
            <p>
              Escore {avaliation.details.details.result} -{" "}
              {avaliation.details.details.name_result}
            </p>
          </div>
        </div>
      </Modal.Body>

      <div className="px-4 pb-4 ">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div className="c-profile-dropdown mr-2">
            {renderPhoto(
              makeUserDataToPhoto(
                avaliation.user.name,
                avaliation.user.image !== null
                  ? avaliation.user.url_image
                  : null
              )
            )}
          </div>

          <div className="dados-default">
            <div className="name-default">Por {avaliation.user.name}</div>
            <div className="date-default">
              {moment(avaliation.created_at).format("LLL")}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DetailsAvaliationHistoric;
