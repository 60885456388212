import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import TwoBarStacked from "./Charts/TwoBarStacked";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import { GetColorAvaliation } from "../../functions/utils";
import EmptyContainer from "./EmptyContainer";
import "./styles.css";

function RiskPatients({ history }) {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);

  const [loading, setLoading] = useState(false);

  const [patientsGeneralData, setPatientsGeneralData] = useState(null);
  const [patientsWithRisk, setPatientsWithRisk] = useState(0);
  const [patientsWithIncidenceWound, setPatientsWithIncidenceWound] =
    useState(0);
  const [patientsWithAdmissionWound, setPatientsWithAdmissionWound] =
    useState(0);

  const [labels, setLabel] = useState(null);
  const [scores, setScores] = useState(null);
  const [chartColors, setChartColors] = useState(null);
  const [comorbiditiesCounter, setComorbiditiesCounter] = useState(null);
  const [situationalStatesCounter, setSituationalStatesCounter] =
    useState(null);
  const [comorbiditiesName, setComorbiditiesName] = useState(null);
  const [situationalStatesName, setSituationalStatesName] = useState(null);

  useEffect(() => {
    if (unit.selecionado?.id != undefined) {
      LoadPatientsGeneralData();
    }
  }, [unit]);

  function LoadPatientsGeneralData() {
    setLoading(true);
    api
      .get(`/dashboard/patients-general-data`, {
        params: {
          unitId: unit.selecionado.id,
          dateInitial: "1900-01-01",
          dateFinal: "2050-01-01",
          situation: "active",
          type: "riskPatients",
          withRisk: true,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          const patientsData = response.data.sort((a, b) => {
            if (a.woundsCount < b.woundsCount) return 1;
            if (a.woundsCount > b.woundsCount) return -1;
            if (a.score === 0 && b.score !== 0) return 1;
            if (a.score !== 0 && b.score === 0) return -1;
            if (a.score > 18 && b.score <= 18) return 1;
            if (a.score <= 18 && b.score > 18) return -1;
            if (a.score > b.score) return 1;
            if (a.score < b.score) return -1;
            if (a.comorbiditesCount < b.comorbiditesCount) return 1;
            if (a.comorbiditesCount > b.comorbiditesCount) return -1;
            return 0;
          });

          const patientsDataCounters = patientsData.reduce(
            (acc, item) => {
              if (item.comorbiditesCount > 0 || item.riskFactorsCount > 0) {
                acc.names.push(item.name);
                acc.comorbiditiesCounter.push(item.comorbiditesCount);
                acc.situationalStatesCounter.push(item.riskFactorsCount);
                acc.comorbiditiesName.push(item.comorbiditiesName);
                acc.situationalStatesName.push(item.situationalStatesName);
                acc.chartColors.push(GetColorAvaliation(item.score));
                const scoreData = {
                  score: item.score,
                  description: item.scoreDescription,
                };
                if (
                  !acc.scores.some(
                    (obj) => obj.description === scoreData.description
                  )
                ) {
                  acc.scores.push(scoreData);
                }
              }

              if (item.withRisk) {
                acc.totalWithRisk += 1;
              }

              if (item.woundsIncidenceCount > 0) {
                acc.totalWoundsIncidenceCount += 1;
              }

              if (item.woundsAdmissionCount > 0) {
                acc.totalWoundsAdmissionCount += 1;
              }

              return acc;
            },
            {
              totalWithRisk: 0,
              totalWoundsIncidenceCount: 0,
              totalWoundsAdmissionCount: 0,
              names: [],
              comorbiditiesCounter: [],
              situationalStatesCounter: [],
              comorbiditiesName: [],
              situationalStatesName: [],
              chartColors: [],
              scores: [],
            }
          );
          setLabel(patientsDataCounters.names);
          setScores(patientsDataCounters.scores);
          setComorbiditiesCounter(patientsDataCounters.comorbiditiesCounter);
          setSituationalStatesCounter(
            patientsDataCounters.situationalStatesCounter
          );
          setComorbiditiesName(patientsDataCounters.comorbiditiesName);
          setSituationalStatesName(patientsDataCounters.situationalStatesName);
          setChartColors(patientsDataCounters.chartColors);

          setPatientsGeneralData(patientsData);
          setPatientsWithRisk(patientsDataCounters.totalWithRisk);
          setPatientsWithIncidenceWound(
            patientsDataCounters.totalWoundsIncidenceCount
          );
          setPatientsWithAdmissionWound(
            patientsDataCounters.totalWoundsAdmissionCount
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleMouseDown = (patientItem, e) => {
    dispatch({
      type: "SET_PATIENT_SELECTED",
      dados: { id: patientItem.id, name: patientItem.name },
    });
    if (e.button === 0) {
      dispatch({ type: "RESET_PATIENT_TAB" });
      history.push({
        pathname: "/Pacientes/Perfil-Paciente",
      });
    } else if (e.button === 1) {
      window.open("/Pacientes/Perfil-Paciente", "_blank");
    }
  };

  return (
    <div className="risk-patients card-report">
      {!loading ? (
        <div>
          <p className="risk-patients-title">Pacientes Internados</p>
          <Row className="hospitalized-patients">
            <Col xs={12} md={3}>
              <div className="data-hospitalized-patients">
                <p className="title-hospitalized-data">Em risco</p>
                <p className="value-hospitalized-data">
                  <Icon icon="warning" size={24} color={Colors.brand_blue} />
                  {patientsWithRisk}
                </p>
                <p className="description-hospitalized-data">
                  Pacientes de risco
                </p>
              </div>
            </Col>
            <Col xs={12} md={9} className="d-flex">
              <div className="data-hospitalized-patients second-data second-data-mobile">
                <p className="title-hospitalized-data">Com lesão</p>
                <p className="value-hospitalized-data">
                  <Icon icon="warning" size={24} color={Colors.brand_blue} />
                  {patientsWithAdmissionWound}
                </p>
                <p className="description-hospitalized-data">
                  Pacientes de risco com lesões admitidas
                </p>
              </div>
              <div className="second-data-mobile">
                <p className="title-hospitalized-data">&nbsp;</p>
                <p className="value-hospitalized-data">
                  <Icon icon="warning" size={24} color={Colors.brand_blue} />
                  {patientsWithIncidenceWound}
                </p>
                <p className="description-hospitalized-data">
                  Pacientes de risco com lesões incididas
                </p>
              </div>
            </Col>
          </Row>
          <div className="patients-in-risk">
            <p className="title-patients-in-risk mb-3">Pacientes em risco</p>
            {patientsGeneralData && patientsGeneralData.length > 0 ? (
              <div>
                <div className="list-patients-in-risk scrollableDiv ">
                  {patientsGeneralData.map((patient) => {
                    return (
                      <div
                        className="item-list-patients-in-risk d-flex"
                        onMouseDown={(e) => handleMouseDown(patient, e)}
                      >
                        <div>
                          <p className="patient-name-item-list-patients-in-risk">
                            {patient.name}
                          </p>
                          <p className="data-item-list-patients-in-risk">
                            <Icon
                              icon="warning"
                              size={16}
                              color={Colors.brand_blue}
                            />
                            {patient.comorbiditesCount}{" "}
                            {patient.comorbiditesCount === 1
                              ? "comorbidade"
                              : "comorbidades"}
                          </p>
                          {patient.riskFactorsCount > 0 && (
                            <p className="data-item-list-patients-in-risk">
                              <Icon
                                icon="warning"
                                size={16}
                                color={Colors.brand_blue}
                              />
                              {patient.riskFactorsCount}{" "}
                              {patient.riskFactorsCount === 1
                                ? "estado situacional"
                                : "estados situcionais"}
                            </p>
                          )}
                          <p className="data-item-list-patients-in-risk">
                            <Icon
                              icon="band-aid"
                              size={16}
                              color={Colors.brand_blue}
                            />
                            {patient.woundsAdmissionCount}{" "}
                            {patient.woundsAdmissionCount === 1
                              ? "lesão admitida"
                              : "lesões admitidas"}{" "}
                            / {patient.woundsIncidenceCount}{" "}
                            {patient.woundsIncidenceCount === 1
                              ? "lesão incidida"
                              : "lesões incididas"}
                          </p>
                        </div>
                        <div>
                          <div
                            className="score-item-list-patients-in-risk"
                            style={{
                              border: `1px solid ${GetColorAvaliation(
                                patient.score
                              )}`,
                              color: GetColorAvaliation(patient.score),
                            }}
                          >
                            <p>
                              {patient.score > 0
                                ? `Score ${patient.score} - ${patient.scoreDescription}`
                                : "Sem avaliação de risco"}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {comorbiditiesName.length > 0 ||
                situationalStatesName.length > 0 ? (
                  <TwoBarStacked
                    className="mt-5"
                    labels={labels}
                    scores={scores}
                    comorbiditiesCounter={comorbiditiesCounter}
                    situationalStatesCounter={situationalStatesCounter}
                    comorbiditiesName={comorbiditiesName}
                    situationalStatesName={situationalStatesName}
                    colors={chartColors}
                  />
                ) : (
                  <EmptyContainer
                    text="Ainda não há dados sobre pacientes de risco com comorbidades ou fatores de risco"
                    iconName="bar-chart"
                  />
                )}
              </div>
            ) : (
              <div className="mb-3">
                <EmptyContainer
                  text="Ainda não há dados sobre pacientes de risco "
                  iconName="bar-chart"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
}

export default RiskPatients;
