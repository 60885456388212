import moment from "moment";

const calculateAverageAngles = (historic) => {
  const forbiddenPositions = ["desvinculado", "sem-sinal", "pendente"];
  const angles = historic.angles;
  if (
    !angles ||
    angles.length === 0 ||
    forbiddenPositions.includes(historic.position)
  ) {
    return null;
  }
  if (angles.length === 1) {
    return angles[0];
  } else {
    let min = angles[0].min;
    let max = angles[0].max;
    let totalAverage = 0;

    angles.forEach((obj) => {
      min = Math.min(min, obj.min);
      max = Math.max(max, obj.max);
      totalAverage += obj.average;
    });

    const average = (totalAverage / angles.length).toFixed(2);

    return { min, max, average };
  }
};

export default {
  custom: (context) => {
    const { body } = context;
    if (!body || (body && body[0].lines.length === 0)) {
      context.opacity = 0;
      return;
    }
  },
  callbacks: {
    title: (state, data) => {
      const props = state[0];
      if (props.datasetIndex > 1) return null;

      const dataset = data.datasets[props.datasetIndex];
      if (dataset.dataType === "historic") {
        const historic =
          data.datasets[props.datasetIndex].historic[props.index];
        const position = data.datasets[props.datasetIndex].labels[props.index];
        const angles = calculateAverageAngles(historic);
        let anglesTooltip = angles
          ? "\nAngulação Média: " + angles.average + "º"
          : "";
        if (!isNaN(angles?.min) && !isNaN(angles?.max)) {
          let angleMin = angles.min;
          let angleMax = angles.max;
          if (angles.min > angles.max) {
            angles.min = angleMax;
            angles.max = angleMin;
          }
          if (angles.min > angles.average) angles.min = angles.average;
          if (angles.max < angles.average) angles.max = angles.average;
          anglesTooltip +=
            "\nAngulação Mínima: " +
            angles.min +
            "º" +
            "\nAngulação Máxima: " +
            angles.max +
            "º";
        }

        return position !== "Não há dados"
          ? `Posição: ${
              data.datasets[props.datasetIndex].labels[props.index]
            } ${anglesTooltip}`
          : null;
      } else if (dataset.dataType === "positions") {
        return "Relógio de Decúbito";
      }
    },
    afterTitle: (state, data) => {
      const props = state[0];
      if (props.datasetIndex > 1) return null;
      const dataset = data.datasets[props.datasetIndex];

      if (dataset.dataType === "historic") {
        const position = dataset.labels[props.index];
        if (position === "Não há dados") return null;
        const historic = dataset.historic[props.index];
        const period = `\nPeríodo: ${moment(
          historic.datetime_position_initial,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm:ss")} - ${moment(
          historic.datetime_position_final,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm:ss")}\n`;
        const suspension = historic.suspensionItem
          ? `${historic.isPaused ? "Pausa" : "Suspensão"}: ${moment(
              historic.suspensionItem.initial,
              "YYYY-MM-DD HH:mm:ss"
            ).format("HH:mm:ss")} - ${moment(
              historic.suspensionItem.final,
              "YYYY-MM-DD HH:mm:ss"
            ).format("HH:mm:ss")}\n`
          : "";

        return period + suspension;
      } else if (dataset.dataType === "positions") {
        const schedule = dataset.schedules[props.index];
        return `\nInício: ${moment(schedule.schedule, "HH:mm:ss").format(
          "HH:mm:ss"
        )}\nFinal: ${moment(schedule.scheduleEnd, "HH:mm:ss").format(
          "HH:mm:ss"
        )}\n`;
      }
    },
    label: (props, data) => {
      if (props.datasetIndex > 1) return null;
      const dataset = data.datasets[props.datasetIndex];
      if (dataset.dataType === "historic") {
        console.log("DATA TOOLTIP", data);
        const position = data.datasets[props.datasetIndex].labels[props.index];
        if (position === "Não há dados") return ` Sem sinal`;
        const historic =
          data.datasets[props.datasetIndex].historic[props.index];
        const dateInitial = new Date(historic.datetime_position_initial);
        const dateFinal = new Date(historic.datetime_position_final);
        const historicDiffInSeconds = (dateFinal - dateInitial) / 1000;
        const time = moment(
          moment().format("YYYY-MM-DD 00:00:00"),
          "YYYY-MM-DD HH:mm:ss"
        ).add(historicDiffInSeconds, "seconds");
        const labelLines = [];
        labelLines.push(`Tempo total na posição: ${time.format("HH:mm:ss")}`);
        if (historic.suspensionItem) {
          const dateInitial = new Date(historic.suspensionItem.initial);
          const dateFinal = new Date(historic.suspensionItem.final);
          const suspensionDiffInSeconds = (dateFinal - dateInitial) / 1000;
          const suspensionTime = moment(
            moment().format("YYYY-MM-DD 00:00:00"),
            "YYYY-MM-DD HH:mm:ss"
          ).add(suspensionDiffInSeconds, "seconds");
          labelLines.push(
            `Tempo total da ${
              historic.isPaused ? "pausa" : "suspensão"
            }: ${moment(suspensionTime).format("HH:mm:ss")}`
          );
          labelLines.push(`Motivo: ${historic.suspensionItem.motive}`);
        }
        return labelLines;
      } else if (dataset.dataType === "positions") {
        const position = data.datasets[props.datasetIndex].labels[props.index];
        return ` ${position}`;
      }
    },
  },
};
