import React, { useEffect, useState } from "react";
import ImageMapper from "react-image-mapper";
import Colors from "../../../styles/Colors";
import Icon from "../../../functions/Icon";
import { Spinner } from "react-bootstrap";
import EmptyContainer from "../EmptyContainer";
import ModalWounds from "./ModalWounds";
import { DeepClone } from "../../../functions/utils";
import ModalPatientsRisks from "../ModalData/ModalPatientsRisks";

const colorDefault = "#7FA7E6";
const mapping_front = {
  name: "front",
  areas: [
    {
      id: 5,
      name: "Orelha direita",
      shape: "circle",
      coords: [140, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 6,
      name: "Orelha esquerda",
      shape: "circle",
      coords: [220, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 1,
      name: "Boca",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 8,
      name: "Ombro esquerdo",
      shape: "circle",
      coords: [280, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 9,
      name: "Ombro direito",
      shape: "circle",
      coords: [90, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 10,
      name: "Trocânter esquerdo",
      shape: "circle",
      coords: [250, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 11,
      name: "Trocânter direito",
      shape: "circle",
      coords: [120, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 43,
      name: "Anterior - Joelho esquerdo",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 13,
      name: "Externo - Joelho direito",
      shape: "circle",
      coords: [110, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 14,
      name: "Interno - Maléolo esquerdo",
      shape: "circle",
      coords: [210, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 15,
      name: "Interno - Maléolo direito",
      shape: "circle",
      coords: [160, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 16,
      name: "Hálux esquerdo",
      shape: "circle",
      coords: [230, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 17,
      name: "Hálux direito",
      shape: "circle",
      coords: [140, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 42,
      name: "Anterior - Joelho direito",
      shape: "circle",
      coords: [160, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 30,
      name: "Externo - Joelho esquerdo",
      shape: "circle",
      coords: [260, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

const mapping_back = {
  name: "back",
  areas: [
    {
      id: 18,
      name: "Occipital",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 20,
      name: "Escápula direita",
      shape: "circle",
      coords: [220, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 19,
      name: "Escápula esquerda",
      shape: "circle",
      coords: [140, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 21,
      name: "Coluna",
      shape: "circle",
      coords: [180, 280, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 23,
      name: "Cotovelo direito",
      shape: "circle",
      coords: [300, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 22,
      name: "Cotovelo esquerdo",
      shape: "circle",
      coords: [70, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 24,
      name: "Sacral",
      shape: "circle",
      coords: [180, 350, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 26,
      name: "Ísquio direito",
      shape: "circle",
      coords: [210, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 25,
      name: "Ísquio esquerdo",
      shape: "circle",
      coords: [150, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 28,
      name: "Calcâneo direito",
      shape: "circle",
      coords: [210, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 27,
      name: "Calcâneo esquerdo",
      shape: "circle",
      coords: [160, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 40,
      name: "Glúteo direito",
      shape: "circle",
      coords: [220, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 41,
      name: "Glúteo esquerdo",
      shape: "circle",
      coords: [140, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 36,
      name: "Interno - Joelho direito",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 39,
      name: "Interno - Joelho esquerdo",
      shape: "circle",
      coords: [155, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 37,
      name: "Externo - Maléolo esquerdo",
      shape: "circle",
      coords: [135, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 38,
      name: "Externo - Maléolo direito",
      shape: "circle",
      coords: [240, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

function IncidenceArea(props) {
  const { wounds } = props;

  const [loading, setLoading] = useState(false);
  const [backArea, setBackArea] = useState(null);
  const [frontArea, setFrontArea] = useState(null);
  const [showWounds, setShowWounds] = useState(false);
  const [hoveredAreaBack, setHoveredAreaBack] = useState(null);
  const [hoveredAreaFront, setHoveredAreaFront] = useState(null);
  const [areaId, setAreaId] = useState(null);
  const [patientIds, setPatientIds] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  function getTipPosition(area) {
    return { top: `${area.center[1] - 30}px`, left: `${area.center[0]}px` };
  }
  function GetValueWound(hoveredArea) {
    var found = wounds.find((item) => item.wound_id == hoveredArea.id);
    if (found != undefined) return `Quant. de lesões: ${found.counter}`;
  }

  function GetNameWound(hoveredArea) {
    var found = wounds.find((item) => item.wound_id == hoveredArea.id);
    if (found != undefined) return `${found.wound_name}`;
  }

  function ResetColorBall() {
    mapping_front.areas.map((item) => {
      item.preFillColor = "rgba(127,167,230, 0.7)";
    });

    mapping_back.areas.map((item) => {
      item.preFillColor = "rgba(127,167,230, 0.7)";
    });
  }

  function HeatMap(counter, counters) {
    const max = Math.max(...counters);
    const min = Math.min(...counters);
    const range = max - min;
    const colorRange = range / 5;
    if (counter <= min + colorRange) return Colors.brand_yellow;
    else if (counter <= min + colorRange * 2) return "#FF872C";
    else if (counter <= min + colorRange * 3) return "#FF4949";
    else if (counter <= min + colorRange * 4) return "#B21515";
    return "#540303";
  }

  useEffect(() => {
    if (wounds) {
      let arrayFront = [];
      let arrayBack = [];
      const mapping_front_clone = DeepClone(mapping_front);
      const mapping_back_clone = DeepClone(mapping_back);
      const counters = wounds.map((i) => i.counter);
      ResetColorBall();
      mapping_front_clone.areas.map((item) => {
        var found = wounds.find((i) => i.wound_id == item.id);
        if (found != undefined) {
          item.patientIds = found.patientIds;
          item.preFillColor = HeatMap(parseFloat(found.counter), counters);
          arrayFront.push(item);
        }
      });

      mapping_back_clone.areas.map((item) => {
        var found = wounds.find((i) => i.wound_id == item.id);
        if (found != undefined) {
          item.patientIds = found.patientIds;
          item.preFillColor = HeatMap(parseFloat(found.counter), counters);
          arrayBack.push(item);
        }
      });
      mapping_front_clone.areas = arrayFront;
      mapping_back_clone.areas = arrayBack;
      setFrontArea(mapping_front_clone);
      setBackArea(mapping_back_clone);
    }
  }, [wounds]);

  function handleModalPatientRisks(title, areaId, patientIds) {
    setPatientIds(patientIds);
    setAreaId(areaId);
    setSelectedData({
      label: title,
      patientQuantity: patientIds.length,
    });
    setShowPatientsRisksData(true);
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <h4 className="mb-0">Regiões de incidência</h4>
        <button onClick={() => setShowWounds(true)} className="btn-info-chart">
          <Icon
            color={Colors.brand_gray}
            size={22}
            icon="info"
            className="ml-2"
          />
        </button>
      </div>

      {loading && (
        <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {frontArea &&
      backArea &&
      (frontArea?.areas.length > 0 || backArea?.areas.length > 0) ? (
        <>
          <div className="d-flex justify-content-center mt-4">
            <div className="mr-4 container-model">
              <ImageMapper
                src={require("../../../assets/img/model-human/men-front.jpg")}
                width={164}
                imgWidth={367}
                map={frontArea}
                onMouseEnter={(area) => setHoveredAreaFront(area)}
                onMouseLeave={(area) => setHoveredAreaFront(null)}
                onClick={(area) =>
                  handleModalPatientRisks(
                    "Região da lesão: " + area.name,
                    area.id,
                    area.patientIds
                  )
                }
              />
              {hoveredAreaFront != null && (
                <span
                  className="tooltip-model"
                  style={{ ...getTipPosition(hoveredAreaFront) }}
                >
                  <p>{GetNameWound(hoveredAreaFront)}</p>
                  <p>{GetValueWound(hoveredAreaFront)}</p>
                </span>
              )}
            </div>

            <div className="container-model">
              <ImageMapper
                src={require("../../../assets/img/model-human/men-back.jpg")}
                width={164}
                imgWidth={367}
                map={backArea}
                onMouseEnter={(area) => setHoveredAreaBack(area)}
                onMouseLeave={(area) => setHoveredAreaBack(null)}
                onClick={(area) => {
                  handleModalPatientRisks(
                    "Região da lesão: " + area.name,
                    area.id,
                    area.patientIds
                  );
                }}
              />
              {hoveredAreaBack != null && (
                <span
                  className="tooltip-model"
                  style={{ ...getTipPosition(hoveredAreaBack) }}
                >
                  <p>{GetNameWound(hoveredAreaBack)}</p>
                  <p>{GetValueWound(hoveredAreaBack)}</p>
                </span>
              )}
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-center align-items-center">
            <img
              className="img-fluid"
              style={{ maxWidth: 320 }}
              src={require("../../../assets/img/heat-map.png")}
            />
          </div>
        </>
      ) : (
        <EmptyContainer
          text="Ainda não há dados sobre regiões de incidência"
          iconName="description_1"
        />
      )}
      {showWounds && (
        <ModalWounds
          handler_show_modal={setShowWounds}
          show_modal={showWounds}
        />
      )}
      {showPatientsRisksData && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={selectedData?.label}
          subTitle={`${selectedData?.patientQuantity} ${
            selectedData?.patientQuantity === 1 ? "paciente" : "pacientes"
          }`}
          removeColumns={[
            "Qntd. de lesões",
            "Avaliação de risco",
            "Fatores de risco",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          areaId={areaId}
          type="incidenceArea"
        />
      )}
    </>
  );
}

export default IncidenceArea;
