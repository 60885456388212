const list_wounds = [
  {
    label: "Orelha direita",
    value: 5,
  },
  {
    label: "Orelha esquerda",
    value: 6,
  },
  {
    label: "Boca",
    value: 1,
  },
  {
    label: "Ombro esquerdo",
    value: 8,
  },
  {
    label: "Ombro direito",
    value: 9,
  },
  {
    label: "Trocânter esquerdo",
    value: 10,
  },
  {
    label: "Trocânter direito",
    value: 11,
  },
  {
    label: "Joelho esquerdo",
    value: 12,
  },
  {
    label: "Joelho direito",
    value: 13,
  },
  {
    label: "Maléolo esquerdo",
    value: 14,
  },
  {
    label: "Maléolo direito",
    value: 15,
  },
  {
    label: "Hálux esquerdo",
    value: 16,
  },
  {
    label: "Hálux direito",
    value: 17,
  },
  {
    label: "Interno - Joelho direito",
    value: 36,
  },
  {
    label: "Interno - Joelho esquerdo",
    value: 39,
  },
  {
    label: "Occipital",
    value: 18,
  },
  {
    label: "Escápula esquerda",
    value: 19,
  },
  {
    label: "Escápula direita",
    value: 20,
  },
  {
    label: "Coluna",
    value: 21,
  },
  {
    label: "Cotovelo esquerdo",
    value: 22,
  },
  {
    label: "Cotovelo direito",
    value: 23,
  },
  {
    label: "Sacral",
    value: 24,
  },
  {
    label: "Ísquio esquerdo",
    value: 25,
  },
  {
    label: "Ísquio direito",
    value: 26,
  },
  {
    label: "Calcâneo esquerdo",
    value: 27,
  },
  {
    label: "Calcâneo direito",
    value: 28,
  },
  {
    label: "Externo - Maléolo esquerdo",
    value: 37,
  },
  {
    label: "Externo - Maléolo direito",
    value: 38,
  },
  {
    label: "Glúteo esquerdo",
    value: 41,
  },
  {
    label: "Glúteo direito",
    value: 40,
  },
  {
    label: "Anterior - Joelho direito",
    value: 42,
  },
  {
    label: "Anterior - Joelho esquerdo",
    value: 43,
  },
];

export default list_wounds;
