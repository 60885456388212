import api from "@Services/api";
import moment from "moment";

const SensorApi = async ({
  patient_id,
  initialDate,
  finalDate,
  loadingHandler,
}) => {
  if (!patient_id) throw new Error("`patient_id` is empty");
  if (!initialDate || !finalDate) throw new Error("`date` is empty");

  const endpoint = `/patients/${patient_id}/report-notifications/beacon`;
  const queryParams = {
    initialDate,
    finalDate,
  };

  try {
    loadingHandler(true);
    const response = await api.get(endpoint, { params: queryParams });
    if (response.status !== 200) throw new Error(response.statusText);
    const reports = [];
    console.log("SensorApi", response.data);

    ////////////////////////////////
    for (const report of response.data) {
      var arrayMorning = [];
      var arrayAftermoon = [];

      report.positionsSchedule.forEach((item) => {
        const scheduleFormat = parseInt(
          moment(item.schedule, "HH:mm:ss").format("HH:mm")
        );

        if (scheduleFormat >= 0 && scheduleFormat <= 11)
          arrayMorning.push(item);
        else arrayAftermoon.push(item);
      });

      if (arrayAftermoon.length === 0 && arrayMorning.length > 0) {
        const lastScheduleMorning = JSON.parse(
          JSON.stringify(arrayMorning[arrayMorning.length - 1])
        );
        lastScheduleMorning.schedule = "12:00:00";
        arrayAftermoon.push(lastScheduleMorning);
      }

      if (arrayAftermoon.length > 0 && arrayMorning.length === 0) {
        const lastScheduleAftermoon = JSON.parse(
          JSON.stringify(arrayAftermoon[arrayAftermoon.length - 1])
        );
        lastScheduleAftermoon.schedule = "00:00:00";
        arrayMorning.push(lastScheduleAftermoon);
      }

      if (
        arrayAftermoon.length > 0 &&
        arrayMorning.length > 0 &&
        arrayMorning[0].schedule !== "00:00:00"
      ) {
        const lastScheduleAftermoon = JSON.parse(
          JSON.stringify(arrayAftermoon[arrayAftermoon.length - 1])
        );
        lastScheduleAftermoon.schedule = "00:00:00";
        arrayMorning.unshift(lastScheduleAftermoon);
      }

      console.log("arrayMorning", arrayMorning);
      console.log("arrayAftermoon", arrayAftermoon);

      reports.push({
        beaconsHistoricPosition: report.beaconsHistoricPosition,
        positionsSchedule: [...arrayMorning, ...arrayAftermoon],
        date: report.date,
      });
    }
    return reports;
  } catch (error) {
    console.log("error", error);
    throw new Error("Não foi possível carregar o relatório do sensor");
  }
};

export default SensorApi;
