import { combineReducers } from "redux";

import user from "./user";
import userUnit from "./userUnit";
import unit from "./unit";
import alert from "./alert";
import patientSelected from "./patientSelected";
import patientTab from "./patientTab";
import updateUnit from "./updateUnit";
import evolutionWoundSelected from "./evolutionWoundSelected";
import sortSelected from "./sortSelected";
import patientHomeTab from "./patientHomeTab";
import patientsFilter from "./patientsFilter";
import defaultWoundState from "./defaultWoundState";
import hospitalPlan from "./hospitalPlan";
import zoomSelected from "./zoomSelected";
import generalDataHeaders from "./generalDataHeaders";

export default combineReducers({
  user,
  userUnit,
  unit,
  alert,
  patientSelected,
  patientTab,
  updateUnit,
  evolutionWoundSelected,
  sortSelected,
  patientHomeTab,
  patientsFilter,
  defaultWoundState,
  hospitalPlan,
  zoomSelected,
  generalDataHeaders,
});
