import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import Colors from "../../styles/Colors";
import Icon from "../../functions/Icon";
import { GetColorBattery, userWithoutPermission } from "../../functions/utils";
import ProgressBar from "react-percent-bar";
import LinkSensorDropdown from "../../pages/Home/MudancaDecubitoNew/LinkSensorDropdown";
import "./styles.css";
import moment from "moment";
import api from "../../services/api";
import { useSelector } from "react-redux";
import VerifyPwWithUser from "./VerifyPwWithUser";
import ModalBeacon from "../../pages/Pacientes/DetalhesPaciente/Tabs/Informacoes/ModalBeacon";
import ModalSensor from "./ModalSensor";

function ModalLowBatterySensors(props) {
  const { show, handler, sensorsData } = props;
  const [showAgreement, setShowAgreement] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  return (
    <>
      <Modal
        show={show}
        onHide={() => handler(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="low-battery-modal"
      >
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 9px 16px",
            }}
          >
            <div>
              <div className="modal-title">Sensores com bateria baixa</div>
            </div>
            <div>
              <div className="modal-close-btn" onClick={() => handler(false)}>
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>
          <div>
            <Table className="user-table" responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Última vinculação</th>
                  <th>Paciente</th>
                  <th>Bateria</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sensorsData.map((item) => {
                  let sensorBattery = !isNaN(
                    item?.notification?.beacon?.battery
                  )
                    ? parseInt(item.notification.beacon.battery)
                    : 0;
                  return (
                    <tr>
                      <td>{item.notification.beacon.name}</td>
                      <td>
                        {moment(
                          item.notification.beacon.battery.lastLinkedDatetime
                        ).format("DD/MM/YYYY")}
                      </td>
                      <td>{item.patient_initials}</td>
                      <td>
                        <div className="container-battery d-flex align-items-center">
                          <ProgressBar
                            fillColor={GetColorBattery(sensorBattery)}
                            percent={sensorBattery}
                            width={60}
                            height={16}
                          />
                          <p className="ml-2 percentage-text">
                            {sensorBattery + "%"}
                          </p>
                        </div>
                      </td>
                      <td>
                        <LinkSensorDropdown
                          handler_low_battery={handler}
                          handler_agreement={setShowAgreement}
                          patient={item}
                          handler_patient={setSelectedPatient}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      <ModalAgreementRemoveSensor
        show={showAgreement}
        handler={setShowAgreement}
        handler_low_battery={handler}
        patient={selectedPatient}
      />
    </>
  );
}

function ModalAgreementRemoveSensor(props) {
  const { show, handler, handler_low_battery, patient } = props;

  const user = useSelector((state) => state.user);

  const [showPw, setShowPw] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [showModalSensor, setShowModalSensor] = useState(false);
  const [reload, setReload] = useState(false);
  function unlinkSensor(userId) {
    setLoadingBtn("flex");
    api
      .put(`/patients/${patient.patient_id}`, {
        beacon_id: 0,
        name: patient.patient_name,
        user_id: userId ? userId : user.dados.id,
      })
      .then((response) => {
        handler(false);
        setShowModalSensor(true);
      })
      .finally(() => {
        setLoadingBtn("none");
      });
  }

  return (
    <>
      {showPw && (
        <VerifyPwWithUser
          handler_show_pw={setShowPw}
          show_pw={showPw}
          modal_name={"Autenticar confirmação"}
          handler_request={unlinkSensor}
        />
      )}
      <Modal
        show={show}
        onHide={() => {
          handler_low_battery(true);
          handler(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="agreement-modal"
      >
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 9px 16px",
            }}
          >
            <div>
              <div className="modal-title">Remova o sensor anterior</div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => {
                  handler(false);
                  handler_low_battery(true);
                }}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>
          <div
            className="agreement-modal-content"
            style={{
              padding: "20px 9px 16px",
            }}
          >
            <p>
              Por favor, remova o sensor anterior e solicite a troca da bateria
              dele.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{ width: "fit-content", minWidth: "120px" }}
            className="default-btn"
            onClick={() => {
              if (!userWithoutPermission(user)) {
                unlinkSensor();
                return false;
              }
              setShowPw(true);
            }}
          >
            <span
              style={{
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              Ok, entendi
            </span>
            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Modal.Footer>
      </Modal>
      {showModalSensor && (
        <ModalSensor
          data={patient}
          handler_modal={setShowModalSensor}
          show_modal={showModalSensor}
          link={true}
          handler_reload={setReload}
          reload={reload}
          patient_id={patient.patient_id}
          room={patient.highRoomName ? patient.highRoomName : patient.room_name}
          subroom={patient.highRoomName ? patient.room_name : null}
          date_birth={patient.date_birth}
          age={patient.age}
          patient_name={patient.patient_name}
          unit_id={patient?.notification?.unit_id}
        />
      )}
    </>
  );
}

export default ModalLowBatterySensors;
