import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  ProgressBar,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import VerifyPw from "../../../../../components/Modals/VerifyPw";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import {
  GetImc,
  CalculateAge,
  userHasPermission,
  userWithoutPermission,
} from "../../../../../functions/utils";
import moment from "moment";
import "./styles.css";
import CheckBox from "../../../../../components/CheckBox";

const progressValue = 16.66666666666667;

function PreencherDiagrama(props) {
  const { handler_show_modal, show_modal, wound, handler_reload, reload } =
    props;
  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loadingBtn, setLoadingBtn] = useState("none");

  const [listMotives, setListMotives] = useState([]);
  const [totalStep, setTotalStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [showDiagram, setShowDiagram] = useState(false);
  const [progress, setProgress] = useState(progressValue);

  const [listUsers, setListUsers] = useState([]);

  //Enviar no request
  const [options, setOptions] = useState([]);

  //Formato pra mostrar o diagrama
  const [diagram, setDiagram] = useState([]);

  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });
  const [showPw, setShowPw] = useState(false);

  useEffect(() => {
    function LoadMotives() {
      api
        .get("/diagram/ishikawa/types-options")
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadMotives success");
            console.log(response.data);

            setListMotives(response.data);
            setTotalStep(response.data.length);
            setCurrentStep(1);

            if (wound?.types != undefined) {
              //Se tiver editando
              FormatChecks();
            }
          }
        })
        .catch((error) => {
          console.log("LoadMotives " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de motivos. Verifique sua conexão.",
            })
          );
        });
    }

    //Se permission_id for 1,2 ou 3 seta quem ta logado direto no responsavel
    if (userHasPermission(user)) {
      setUserChooser({ label: user.dados.name, value: user.dados.id });
    }

    if (show_modal) LoadMotives();
  }, [show_modal]);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: patientSelected.dados.unit_id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userWithoutPermission(user)) {
      LoadUsers();
    }
  }, []);

  function FormatChecks() {
    var array = [];
    wound.types.map((item) => {
      item.options.map((i) => {
        if (i.is_checked == 1) {
          var obj = {};
          obj.title = item.name;
          obj.id = i.patient_type_option_diagram_id;
          obj.value = i.values;
          obj.is_checked = i.is_checked;
          obj.other = "";

          //Add value no input "outros"
          if (i.is_others == 1) {
            obj.value = "Outros";
            obj.other = i.values;
          }

          array.push(obj);
        }
      });
    });
    setOptions(array);
  }

  function AttProgress(type) {
    if (type == "next") {
      setProgress(progress + progressValue);
    } else {
      setProgress(progress - progressValue);
    }
  }

  function FormatData() {
    var pessoal = [];
    var paciente = [];
    var metodo = [];
    var materiais = [];
    var estrutura = [];
    var ambiente = [];

    options.map((item) => {
      if (item.title == "Pessoal") {
        pessoal.push(item);
      }
      if (item.title == "Paciente") {
        paciente.push(item);
      }
      if (item.title == "Método") {
        metodo.push(item);
      }
      if (item.title == "Materiais") {
        materiais.push(item);
      }
      if (item.title == "Estrutura") {
        estrutura.push(item);
      }
      if (item.title == "Meio ambiente") {
        ambiente.push(item);
      }
    });

    setDiagram([paciente, pessoal, metodo, estrutura, materiais, ambiente]);
  }

  function HandlerCheck(op, title) {
    var found = options.find((i) => i.id == op.id);
    if (found != undefined) {
      //Remove
      var newList = options.filter((i) => i.id != op.id);
      setOptions(newList);
    } else {
      //Add
      var obj = {
        title,
        id: op.id,
        value: op.name,
        is_checked: 1,
        other: "",
      };
      setOptions([...options, obj]);
    }
  }

  function VerifyCheck() {
    let verify = true;
    if (options.length == 0 && currentStep == 6) verify = false;
    return verify;
  }

  function SaveDiagram() {
    setLoadingBtn("flex");

    var patient_type_options = [];
    options.map((item) => {
      if (item.value == "Outros") {
        item.value = item.other;
      }
      patient_type_options.push(item);
    });

    listMotives.map((item) => {
      item.options.map((i) => {
        var found = patient_type_options.find((a) => a.id == i.id);

        if (found == undefined) {
          var obj = {};
          obj.id = i.id;
          obj.value = i.name;
          obj.is_checked = 0;
          patient_type_options.push(obj);
        }
      });
    });

    api
      .put(
        `/patients/${patientSelected.dados.id}/wounds/${wound.patient_wound_id}/diagram/ishikawa`,
        {
          user_id: userChooser.value,
          patient_type_options,
        }
      )
      .then((response) => {
        if (response.status == 200) {
          console.log("SaveDiagram success");
          console.log(response.data);
          dispatch(
            Show_Alert({
              type: "success",
              msg: "Diagrama de Ishikawa preenchido com sucesso",
            })
          );

          handler_reload(reload == true ? false : true);
        }
      })
      .catch((error) => {
        console.log("SaveDiagram " + error);

        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar o Diagrama de Ishikawa. Verifique sua conexão.",
          })
        );
      })
      .finally(() => {
        setLoadingBtn("none");
        handler_show_modal(false);
      });
  }

  if (!showDiagram) {
    return (
      <Modal
        show={show_modal}
        onHide={() => handler_show_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <div className="modal-title">Preencher diagrama</div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            {listMotives.map((item, index) => {
              if (index + 1 == currentStep) {
                return (
                  <div key={index}>
                    <div className="header-diagram d-flex justify-content-between mt-4 mb-3">
                      <div className="container-header-info">
                        <h3 className="mb-0">{item.name}</h3>
                        <p>Selecionar causas</p>
                      </div>

                      <p className="mb-0 step-text">
                        {currentStep}/{totalStep}
                      </p>
                    </div>

                    <div>
                      {item.options.map((op, ind) => {
                        var found = options.find((i) => i.id == op.id);

                        return (
                          <div key={ind}>
                            <div
                              onClick={() => {
                                if (found != undefined) {
                                  //Remove
                                  var newList = options.filter(
                                    (i) => i.id != op.id
                                  );
                                  setOptions(newList);
                                } else {
                                  //Add
                                  var obj = {
                                    title: item.name,
                                    id: op.id,
                                    value: op.name,
                                    is_checked: 1,
                                    other: "",
                                  };
                                  setOptions([...options, obj]);
                                }
                              }}
                              key={ind}
                              className="modal-item d-flex justify-content-between align-item-center flex-wrap"
                            >
                              <p
                                className="mb-0 modal-name"
                                style={{
                                  color:
                                    found != undefined
                                      ? Colors.brand_blue
                                      : Colors.darkest_gray,
                                }}
                              >
                                {op.name}
                              </p>
                              <CheckBox
                                checked={found?.id === op.id}
                                value={op}
                                onclick={HandlerCheck}
                              />
                            </div>

                            {found?.value == "Outros" && (
                              <input
                                className="default-input"
                                type="text"
                                placeholder="Digitar outra causa"
                                onChange={(e) => {
                                  var newList = options.map((i) => {
                                    if (i.id == op.id) {
                                      i.other = e.target.value;
                                    }
                                    return i;
                                  });

                                  setOptions(newList);
                                }}
                                value={found.other}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <div className="w-100 mb-3">
            <ProgressBar style={{ height: 10 }} now={progress} />
          </div>
          <div className="w-100 d-flex justify-content-between">
            {currentStep == 1 && <div style={{ width: 122 }} />}

            {currentStep > 1 && (
              <button
                style={{ maxWidth: 122 }}
                className="modal-footer-btn mr-0 btn-cancel"
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                  AttProgress("prev");
                }}
              >
                Voltar
              </button>
            )}

            <button
              disabled={VerifyCheck() == true ? false : true}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                maxWidth: 122,
                backgroundColor:
                  VerifyCheck() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                if (currentStep == totalStep) {
                  FormatData();
                  setShowDiagram(true);
                } else {
                  setCurrentStep(currentStep + 1);
                }

                AttProgress("next");
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyCheck() == true ? "white" : Colors.dark_gray,
                }}
              >
                Próximo
              </p>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal
        show={show_modal}
        onHide={() => handler_show_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {showPw && (
          <VerifyPw
            handler_show_pw={setShowPw}
            show_pw={showPw}
            handler_user={setUserChooser}
            id_user={userChooser.value}
          />
        )}
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <div className="modal-title">Diagrama Preenchido</div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            {userWithoutPermission(user) && (
              <div className="my-4">
                <Form.Label className="field-label">Responsável</Form.Label>
                <Select
                  captureMenuScroll={false}
                  classNamePrefix="react-select"
                  placeholder={"Selecionar"}
                  options={listUsers}
                  isClearable={false}
                  value={userChooser}
                  onChange={(item) => {
                    setUserChooser(item);
                    setShowPw(true);
                  }}
                />
              </div>
            )}

            <div>
              <div className="container-header-info my-2">
                <h3 className="mb-0">{wound.wound_name}</h3>
                <p className="mt-0">
                  {" "}
                  {wound.type == "admission" ? "Admitida" : "Incidida"} |{" "}
                  {wound.stage_name}
                </p>
              </div>

              <p className="diagram-title">Diagrama de Ishikawa</p>
            </div>

            <div className="container-ball">
              <div className="ball" />
            </div>

            <div className="container-diagram">
              <div className="line">
                <div className="section-diagram">
                  <div className="container-section-diagram">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Paciente</p>
                    </div>

                    {diagram[0].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.other == "" ? item.value : item.other}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div className="line-mid" />

                <div className="section-diagram">
                  <div className="container-section-diagram d-flex flex-column align-items-end">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Pessoal</p>
                    </div>

                    {diagram[1].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.other == "" ? item.value : item.other}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    position: "absolute",
                    backgroundColor: "transparent",
                    top: 34,
                    zIndex: 1,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ddd",
                      width: 195,
                      height: 1,
                    }}
                  />
                </div>
              </div>

              <div className="line">
                <div className="section-diagram">
                  <div className="container-section-diagram">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Método</p>
                    </div>

                    {diagram[2].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.other == "" ? item.value : item.other}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div className="line-mid" />

                <div className="section-diagram">
                  <div className="container-section-diagram d-flex flex-column align-items-end">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Estrutura</p>
                    </div>

                    {diagram[3].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.other == "" ? item.value : item.other}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    position: "absolute",
                    backgroundColor: "transparent",
                    top: 34,
                    zIndex: 1,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ddd",
                      width: 195,
                      height: 1,
                    }}
                  />
                </div>
              </div>

              <div className="line">
                <div className="section-diagram">
                  <div className="container-section-diagram">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Materiais</p>
                    </div>

                    {diagram[4].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.other == "" ? item.value : item.other}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div className="line-mid" />

                <div className="section-diagram">
                  <div className="container-section-diagram d-flex flex-column align-items-end">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Ambiente</p>
                    </div>

                    {diagram[5].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.other == "" ? item.value : item.other}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    position: "absolute",
                    backgroundColor: "transparent",
                    top: 34,
                    zIndex: 1,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ddd",
                      width: 195,
                      height: 1,
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="container-footer">
              <div className="line-footer" />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <div
            className={`w-100 d-flex ${
              loadingBtn !== "none"
                ? "justify-content-end"
                : "justify-content-between"
            }`}
          >
            {loadingBtn === "none" && (
              <button
                style={{ maxWidth: 122 }}
                className="modal-footer-btn mr-0 btn-cancel"
                onClick={() => {
                  setShowDiagram(false);
                }}
              >
                Voltar
              </button>
            )}

            <button
              disabled={userChooser.value > 0 ? false : true}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                maxWidth: 180,
                backgroundColor:
                  userChooser.value > 0
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => SaveDiagram()}
            >
              <p
                className="mb-0"
                style={{
                  color: userChooser.value > 0 ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Salvar diagrama
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PreencherDiagrama;
