import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

function ModalPolitica(props) {
  // Exibição do modal
  const [show, setShow] = useState(props.show);
  const handler = props.handler;

  useEffect(() => {
    handler(show);
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter modal-termos"
        scrollable
        centered
      >
        <Row
          style={{ justifyContent: "space-between", alignItems: "center" }}
          className="mb-4 px-2"
        >
          <Col>
            <div className="modal-title">Política de privacidade</div>
          </Col>
          <Col xs={2} md={2} lg={2} className="pr-0 d-flex justify-content-end">
            <div className="modal-close-btn" onClick={() => setShow(false)}>
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </Col>
        </Row>

        <Modal.Body
          style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 40 }}
        >
          <h6 style={{ textAlign: "justify" }}>
            Aqui está descrito como coletamos, registramos, protegemos,
            utilizamos, divulgamos, transferimos e/ou armazenamos os dados e as
            informações dos usuários, pacientes e/ou clientes. Se você não
            concordar com esta Política de privacidade, por favor, não utilize
            nossos sistemas, produtos e serviços e não forneça dados para a
            Preveni.
          </h6>

          <div style={{ textAlign: "justify" }}>
            <p className="my-3">
              Ao utilizar os sistemas, serviços e/ou produtos da Preveni e dar o
              aceite nesta Política de privacidade no momento do cadastro, os
              usuários declaram e aceitam a integralidade deste instrumento,
              conforme previsto na legislação.
            </p>

            <p className="my-3">
              Esta Política de privacidade poderá ser alterada, modificada seus
              itens, adicionado mais informações e/ou excluído cláusulas a
              qualquer momento, para atender aos requisitos legais e/ou mercado
              e/ou tecnologia. As modificações serão avisadas através dos canais
              de comunicação com os usuários com a data de início de vigência. O
              usuário ao permanecer no sistema, declara que concorda com as
              alterações realizadas.
            </p>

            <p className="my-3">
              Quando o usuário, de forma voluntária, insere suas informações
              e/ou dados de terceiros, a navegação é facilitada e melhorada nos
              sistemas da Preveni e o uso de suas ferramentas. Portanto, a
              proatividade do usuário em fornecedor os dados significa que ele e
              a comunidade desejam ser beneficiado com os sistemas, serviços e
              produtos da Preveni e concorda com os termos de privacidade,
              inclusive com eventual compartilhamento seguro com terceiros,
              entre eles, sistemas de pagamentos digitais, para a correta
              prestação dos serviços pelos produtos da Preveni. Dados
              considerados sensíveis, quando necessário, serão anonimizados para
              garantir a privacidade dos usuários.
            </p>

            <p className="my-3">
              Os respectivos processos de tratamento de dados e a preocupação de
              manter a segurança para o não compartilhamento indevido, serão
              mantidos em eventuais seleções de terceiros parceiros.
            </p>

            <p className="my-3">
              Para ser um usuário, a primeira etapa é o cadastro pessoal da
              pessoa física, e dados para pagamentos, este quando ocorrer
              compras nos serviços e/ou produtos da Preveni. Se o usuário
              decidir em não fornecer as informações solicitadas, eventuais
              funcionalidades não terão acesso aos seus sistemas, produtos,
              serviços, conteúdos ou responder as suas dúvidas. Se o usuário é
              pessoa jurídica, outras informações serão necessárias para
              finalizar o cadastro, previstas nos formulários disponíveis nos
              sistemas.
            </p>

            <p className="my-3">
              Se o usuário inserir dados pessoais de terceiros, para a prestação
              dos serviços dos sistemas e produtos da Preveni, o usuário fica
              única e exclusivamente responsável por garantir que este terceiro
              tenha autorizado a inserção de seus dados, isentando desde já a
              Preveni de qualquer eventual indenização em razão desse tratamento
              de dados do terceiro, mesmo que este não seja propriamente o
              usuário dos sistemas, produtos e serviços da Preveni.
            </p>

            <p className="my-3">
              Os dados podem ser inseridos durante o cadastro e/ou momento
              posterior, de preferência do usuário, para alterar, incluir ou
              excluir informações do seu perfil pessoal e/ou de sua empresa e/ou
              requerer o acesso a determinadas serviços e/ou ferramentas. O
              login e a senha que dão acesso aos sistemas e ao cadastro do
              usuário são de utilização pessoal e intransferível, em razão disso
              a Preveni não se responsabiliza por eventual utilização ou
              manipulação não autorizada dessas informações por terceiros,
              portanto, o usuário deve tomar as medidas necessárias para manter
              em segurança e sigilo os dados e os acessos aos sistemas.
            </p>

            <p className="my-3">
              O usuário tem direito a acessar, alterar, corrigir e/ou eliminar
              os dados registrados por ele. Se o usuário decidir em revogar o
              consentimento e autorização do uso dos seus dados anonimizados nos
              sistemas, serviços e/ou produtos da Preveni, esta poderá remover
              seus dados para consultas futuras, entretanto, algumas informações
              permanecerão arquivadas, para o fim único e exclusivo da Preveni
              poder atender requisitos legais.
            </p>

            <p className="my-3">
              As informações pessoais são protegidas e garantidas que não serão
              compartilhadas de forma indevida.
            </p>

            <p className="my-3">
              Os dados podem ser combinados e/ou cruzados com outros dados com o
              objetivo de melhorar os sistemas, serviços, produtos, conteúdo e
              publicidade para os usuários.
            </p>

            <p className="my-3">
              Os usuários se comprometem a avisar imediatamente a Preveni, caso
              ocorra eventual uso não autorizado de sua conta ou dados ou de
              acesso não autorizado por terceiros à mesma. Os usuário são os
              únicos responsáveis pelas operações efetuadas em sua conta, pois o
              acesso à mesma só é possível através de senha, cujo conhecimento é
              único e exclusivo do usuário.
            </p>

            <p className="my-3">
              Os sistemas, serviços e/ou produtos da Preveni poderão utilizar
              “cookies”, “pixel tags” e/ou “web beacons” (dados registrados no
              computador dos usuários que demonstram as suas atividades na
              internet, dados demográficos, fluxo de tráfego e comportamento dos
              usuários) para permitir sua identificação e melhorar a qualidade
              das informações oferecidas em seu portal para os usuários.
            </p>

            <p className="my-3">
              O uso dessas tecnologias é uma prática da indústria, pois permite
              o armazenamento de informações importantes, como os acessos dos
              usuários nos sistemas, serviços e/ou produtos da Preveni, para
              posteriormente não ser apresentado um conteúdo repetido ou
              ultrapassado e sempre buscar a melhoria da experiência do usuário
              no uso dos sistemas.
            </p>

            <p className="my-3">
              Caso o Usuário não deseje receber as tecnologias, como os
              “cookies”, ou queira receber um prévio aviso antes de utilizar,
              deverá programar, a seu critério, em seu navegador da web estas
              opções.
            </p>

            <p className="my-3">
              Essas tecnologias ajudam a compreender melhor o comportamento dos
              usuários, informam as seções dos sites que foram visitados, ajuda
              e medir a eficácia das propagandas e pesquisas na web.
            </p>

            <p className="my-3">
              As informações coletadas por “cookies” e outras tecnologias são
              tratadas como informações não pessoais.
            </p>

            <p className="my-3">
              A Preveni utiliza os dados que coleta sobre os usuários dos nossos
              serviços, para melhorar os sistemas, serviços e/ou produtos, para
              desenvolver novos serviços e/ou funcionalidades e para análise de
              mercado.
            </p>

            <p className="my-3">
              As informações poderão ser combinados com dados de terceiros,
              respeitando a privacidade e segurança do usuário.
            </p>

            <p className="my-3">
              A Preveni não fornecerá as informações dos usuários a terceiros
              sem prévia autorização do mesmo, exceto em casos necessários para
              atender eventuais solicitações judiciais de entidades
              governamentais, nos limites da legislação vigente, com o objetivo
              de proteção de direitos, propriedades e/ou segurança dos sistemas,
              serviços e/ou produtos da Preveni, de seus usuários e/ou
              terceiros, na forma da lei.
            </p>

            <p className="my-3">
              Os dados cedidas pelos usuários e registradas em razão do seu uso
              ao sistema, e que não o torne identificado de forma pessoal,
              descrito como dados anonimizados, poderão ser utilizadas pela
              Preveni e servirão como insumos para novos produtos, sistemas e/ou
              serviços, análise e tratamento de dados, formação de estatísticas
              dos produtos e eventuais direcionamento de ações de marketing.
              Através do seu cadastro, uso e fornecimento de dados à Preveni, o
              usuário declara e de forma deliberada aceita os essas condições da
              Política de Privacidade sobre o uso de informações de formas
              anônimas.
            </p>

            <p className="my-3">
              Os dados cedidos pelos usuários que o tornem identificável, tem
              como objetivo que os usuários se relacionem com os sistemas,
              serviços, produtos e/ou as ferramentas disponíveis e/ou com outros
              usuários, para atender exclusivamente os comandos realizados por
              estes usuários nos sistemas da Preveni.
            </p>

            <p className="my-3">
              Os dados cedidos pelo usuário são utilizados para gerenciar a
              conta dos usuários para customizar e melhorar os serviços,
              processar os comandos solicitados pelos próprios usuários nos
              sistemas da Preveni, como as ferramentas solicitadas no ato do
              cadastro ou posteriormente e/ou o débito de valores nas formas de
              pagamento previstos, quando expressamente autorizados pelos
              usuários, informar atualizações dos sistemas, serviços e/ou
              produtos, para a comunicação de novidades e ações de marketing ou
              de parceiros, e/ou quando eventualmente exigidos, na forma da lei,
              por autoridades.
            </p>

            <p className="my-3">
              Para cancelar o envio de mensagens, e-mails, atualizar e/ou
              corrigir seus dados, direitos estes garantidos aos usuários, os
              usuários poderão acessar o link de descadastramento nos e-mails ou
              através de solicitação pelo e-mail contato@preveni.com.br.
            </p>

            <p className="my-3">
              A Preveni poderá convidar os usuários para participar de pesquisas
              e/ou entrevistas para melhoria dos serviços, sistemas, produtos ou
              conhecimento de mercado.
            </p>

            <p className="my-3">
              Utilizamos tecnologias e outras medidas para proteger os dados que
              temos sob controle contra uso indevido, acesso não autorizado,
              alteração, destruição ilegal ou acidental e perda acidental.
            </p>

            <p className="my-3">
              Solicitamos que todos os nossos colaboradores ou terceiros
              autorizados que tenham acesso dos dados respeitem a sua
              confidencialidade, usem com segurança, sigilo e em atendimento aos
              termos deste instrumento.
            </p>

            <p className="my-3">
              Os usuários não devem realizar qualquer forma de disseminação de
              material ou informação de caráter ilegal, imoral, antiético,
              ofensivo, racista ou xenofóbico ou qualquer um que viole os
              objetivos da Preveni e sua comunidade.
            </p>

            <p className="my-3">
              Os usuário não devem fornecer à Preveni e/ou a qualquer outro
              usuário dados que ele acredite serem danosas à sua pessoa, ao seu
              âmbito profissional ou à sociedade. Ao publicar comentários,
              mensagens ou arquivos em locais públicos dos sistemas da Preveni
              ou em outro canal de comunicação, o usuário deve ter ciência que
              estas informações ou dados serão visualizadas por outros usuários
              e o público em geral. Os usuários precisam se registrar nos
              sistemas para terem acesso aos serviços.
            </p>

            <p className="my-3">
              Os dados de menores de idade não poderão ser submetidas aos
              sistemas da Preveni por pessoas com idade inferior a 18 anos, sem
              o prévio consentimento dos pais ou responsável. É proibido que
              menores de idade utilizem os serviços, produtos, sistemas e/ou
              ferramentas da Preveni sem o referido consentimento.
            </p>

            <p className="my-3">
              Se dados de menores de idade forem inseridos nos sistemas da
              Preveni, é necessário que o usuário tenha o consentimento
              específico por um dos pais ou pelo responsável legal. Com a
              aceitação dos termos previstos nesta Política de privacidade, no
              ato do cadastro, fica tacitamente entendido que este consentimento
              foi permitido pelos pais ou responsável, e que os dados inseridos
              nos sistemas, junto com as informações dos menores de idade, são
              destes responsáveis, ficando estes exclusivamente responsáveis
              pelo conteúdo acessado pelos menores de idade.
            </p>

            <p className="my-3">
              Se eventuais dados de terceiros forem inseridos nos sistemas da
              Preveni, é necessário que o usuário tenha o consentimento
              específico pelo terceiro cadastrado. Com a aceitação dos termos
              previstos nesta Política de privacidade, no ato do cadastro, fica
              tacitamente entendido que este consentimento foi permitido pelo
              terceiro, e que os dados inseridos nos sistemas, junto com as
              informações dos terceiro, ficando estes exclusivamente
              responsáveis pelo conteúdo.
            </p>

            <p className="my-3">
              Quando o usuário cancelar o seu cadastro, terá o prazo de até 45
              (quarenta e cinco) dias a contar do último dia de acesso nos
              sistemas, serviços e/ou produtos da Preveni para fazer uma
              solicitação sobre os seus dados. Após este prazo, não é mais
              garantido a disponibilidade dos dados do usuário. A partir deste o
              usuário declara que tem ciência, conforme legislação em vigor, que
              possui obrigação de manter um registro e/ou backup físico destes
              arquivos pelos prazos exigidos em lei, independente dos serviços
              e/ou produtos fornecidos pelos produtos e sistemas.
            </p>

            <p className="my-3">
              Se os usuários tiverem eventuais questionamentos ou dúvidas sobre
              essa Política de privacidade ou sugestão sobre os sistemas,
              serviços e/ou produtos da Preveni, poderá enviar um e-mail para o
              contato@preveni.com.br.
            </p>

            <p className="my-3">
              A comunicação será encaminhada para o membro responsável pelo
              tratamento das informações do usuário, conforme Lei Geral de
              Proteção de Dados, que, para todos os fins, é o Bruno Rondem.
            </p>

            <p className="my-3">
              Caso os usuários considerarem que o processamento de seus dados
              pessoais, por parte da Preveni, não estão em conformidade com as
              leis gerais e de proteção de dados vigentes, tem o direito de
              apresentar queixa através do envio de e-mail para
              contato@preveni.com.br.
            </p>

            <p className="my-3">
              Para aumentarmos a garantia de que os dados estão seguros,
              comunicamos essas Políticas de privacidade e segurança aos
              colaboradores e impomos as salvaguardas de privacidade na empresa.
            </p>

            <p className="my-3">
              Reservamo-nos o direito de alterar esta Política de privacidade a
              qualquer momento e as alterações entrarão em vigor após a
              publicação da Política de Privacidade modificada.
            </p>

            <p className="my-3">
              Se ocorrer eventuais alterações a este instrumento iremos avisá-lo
              pelo-mail cadastrado.
            </p>

            <p className="my-3">
              A Preveni recomenda os usuários a revisarem de forma periódica
              esta Política de privacidade para obter as informações mais
              recentes sobre nossas práticas de privacidade.
            </p>

            <p className="my-3">
              Se os usuários não aceitarem os termos desta Política de
              Privacidade, pedimos que o Usuário não se cadastrem e que não faça
              uso de nossos sistemas, ferramentas, produtos e/ou serviços. Ao
              continuar a usar os sistemas, ferramentas, produtos e/ou serviços
              após as modificações realizadas nesta Política de privacidade, os
              usuários concordam automaticamente com as alterações.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalPolitica;
