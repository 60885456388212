import React from "react";
import {
  Spinner,
  Table,
} from "react-bootstrap"
import * as Redux from "react-redux"
import moment from "moment"

import { Show_Alert } from "@Store/actions/alert"
import Icon from "@Functions/Icon"
import Pagination from '@Components/Pagination/Pagination'

import { SensorsPanelsHistoricApi } from '../js/Api'

export default function BeaconHistoric({ handler_historic, show_historic, beacon }) {
  const dispatch = Redux.useDispatch()

  const [loading, setLoading] = React.useState(false)
  const [list, setList] = React.useState([])
  const [beacon_id] = React.useState(beacon.id)

  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(12)
  const [pagination, setPagination] = React.useState([])

  const onApiSuccess = React.useCallback(response => {
    setList(response.data)
    setPagination(response)
  }, [
    setList,
    setPagination
  ])

  const onApiError = React.useCallback(error => {
    dispatch(
      Show_Alert({
        type: 'error',
        msg: error.message
      })
    )
  }, [dispatch])

  const onApiFinally = React.useCallback(() => {
    setLoading(false)
  }, [setLoading])

  React.useEffect(() => {
    setLoading(true)

    const props = {
      beacon_id,
      page,
      pageSize
    }
    SensorsPanelsHistoricApi(props)
      .then(onApiSuccess)
      .catch(onApiError)
      .finally(onApiFinally)
  }, [
    beacon_id,
    page,
    pageSize,
    onApiSuccess,
    onApiError,
    onApiFinally
  ]);

  const onPaginationChange = ({ page, pageSize }) => {
    setPage(page)
    setPageSize(pageSize)
  }

  const onGoBack = event => {
    event.stopPropagation()
    handler_historic(false)
  }

  const convertDatetimeAndUser = (datetime, user) => {
    return (
      <div className="container-info-enable">
        <h4>{ moment(datetime).format('DD/MM/YYYY [às] HH:mm') }</h4>
        <p>{ user.name }</p>
      </div>
    )
  }

  const calculeDatetimesInDays = (datetime_initial, datetime_final) => {
    const initial = moment(datetime_initial)
    const final = datetime_final ? moment(datetime_final) : moment()

    const diff = final.diff(initial, 'days') 
    return `${diff} ${diff > 1 ? 'dias': 'dia'}`
  }
  return (
    <section className="beacon-panel-historic" data-testid="beacon-panel-historic">
      <div className="d-flex mb-2 container-breadcrumb">
        <div className="breadcrumb-item-edit d-flex align-items-center">
          <a
            className="title-painel-sensores"
            href="#"
            onClick={ onGoBack }
            data-testid="beacon-panel-historic-back"
          >
            Painel de sensores
          </a>
          <Icon
            color="#666666"
            size={18}
            icon="arrow-left"
            className="mx-2 breadcrumb-icon"
          />
          <p>Sensor {beacon.name}</p>
        </div>
      </div>

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h2 className="screen-name mb-4">Histórico do sensor {beacon.name}</h2>
      </div>

      {!loading && list.length == 0 && (
        <div className="container-error-default my-3" data-testid="beacon-panel-historic-empty">
          <p>Nenhum resultado foi encontrado.</p>
        </div>
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && list.length > 0 && (
        <div className="mt-3" data-testid="beacon-panel-historic-list">
          <Table className="user-table" responsive>
            <thead>
              <tr>
                <th>Paciente</th>
                <th>Vinculado</th>
                <th>Desvinculado</th>
                <th>Período</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {
                        item.patient
                          ? item.patient.name
                          : '-'
                      }
                    </td>
                    <td>
                      { 
                        item.datetime_initial && item.userLink
                          ? convertDatetimeAndUser(item.datetime_initial, item.userLink) 
                          : '-'
                      }
                    </td>
                    <td>
                      { 
                        item.datetime_final && item.userUnlink
                          ? convertDatetimeAndUser(item.datetime_final, item.userUnlink) 
                          : '-'
                      }
                    </td>
                    <td>
                      { calculeDatetimesInDays(item.datetime_initial, item.datetime_final) }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      {!loading && list.length > 0 && pagination && (
        <Pagination 
            onChange={ onPaginationChange } 
            data={ pagination }
        />
      )}
    </section>
  )
}

