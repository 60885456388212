//Add
export function SaveToken(token) {
  localStorage.setItem("preveni_token_v2", JSON.stringify(token));
}

//----------------------------------------------

// Remove
export function RemoveToken() {
  localStorage.removeItem("preveni_token_v2");
}
