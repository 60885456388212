import React, { useState, useEffect, createRef } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import "../../../styles/global.css";
import "./styles.css";

import api from "../../../../src/services/api";
import { Show_Alert } from "../../../store/actions/alert";
import ReCAPTCHA from "react-google-recaptcha";

function EsqueceuSenha() {
  const dispatch = useDispatch();
  const recaptchaRef = createRef();

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [erroEmail, setErroEmail] = useState("");
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");

  // Verifica validade do email
  function VerifyEmail(email) {
    setSubmit(false);
    setEmail(email);

    // Teste formato
    if (email.length > 0) {
      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var res = new RegExp(regex).test(email);
      if (!res) {
        setErroEmail("Por favor, insira um endereço de e-mail válido");
        setValidEmail(false);
      } else {
        setValidEmail(true);
        setErroEmail("");
      }
    } else {
      setErroEmail("O campo de e-mail é obrigatório");
      setValidEmail(false);
    }

    // Teste existência pela API
    // Se existir, setValidEmail(true)
    // Se não existe, setValidEmail(false)
  }

  function RunCaptcha() {
    if (!validEmail || email == "") return false;

    setSubmit(true);
    setLoadingBtn("flex");
    recaptchaRef.current.execute();
  }

  // Envia para API
  function send(recaptchaToken) {
    api
      .post("/forget-password", {
        email,
        recaptchaToken,
      })
      .then((response) => {
        if (response.status == 204) {
          setSuccess(true);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          dispatch(
            Show_Alert({
              type: "error",
              msg: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível enviar um email de recuperação, contate o suporte.",
            })
          );
        }
      })
      .finally(() => setLoadingBtn("none"));
  }

  // Componente de tela de sucesso
  function successScreen() {
    return (
      <div className="box-sucesso">
        <Icon color={Colors.brand_green} size={70} icon="check-circle" />

        <h5 className="text-left title mt-3">Verifique seu e-mail</h5>

        <div className="dark-msg">Enviamos um e-mail para:</div>
        <div className="email">{email}</div>
        <div className="light-msg">
          Acesse seu e-mail e clique no link para <br /> conseguir redefinir sua
          senha.
        </div>

        <div
          className="default-btn text-center mt-4 mb-0"
          onClick={() => (window.location = "/")}
        >
          Voltar para Login
        </div>
      </div>
    );
  }

  return (
    <Container fluid className="container-esqueceu">
      {!success && (
        <div className="box-esqueceu">
          <a href="/" className="pink-link">
            Voltar para página de Login
          </a>

          <h5 className="text-left title mt-3">Esqueceu sua senha?</h5>
          <h6 className="text-left sm-title">
            Preencha seu e-mail no campo abaixo, que te ajudaremos a recuperar
            sua senha.
          </h6>

          <div className="field-label">E-mail</div>

          <input
            className={
              erroEmail.trim().length > 0
                ? "default-input invalid-field"
                : "default-input mb-2"
            }
            placeholder={"Digite seu e-mail"}
            value={email}
            onChange={(v) => VerifyEmail(v.target.value)}
          />
          {erroEmail.trim().length > 0 && (
            <div className="invalid-msg mb-2">{erroEmail}</div>
          )}

          <div
            className="default-btn text-center mt-4"
            onClick={(e) => {
              if (loadingBtn === "flex") {
                e.preventDefault();
              } else {
                RunCaptcha();
              }
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={send}
            />
            <p
              style={{
                display: loadingBtn == "none" ? "flex" : "none",
                margin: 0,
              }}
            >
              Recuperar senha
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </div>
        </div>
      )}

      {success && successScreen()}
    </Container>
  );
}

export default EsqueceuSenha;
