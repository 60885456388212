import React, { useState, useEffect } from "react";
import { Col, Form, Spinner, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import CheckBox from "../../../../components/CheckBox";
import Icon from "../../../../functions/Icon";

function TempoSuspensao() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [loading, setLoading] = useState(true);
  const [hospital, setHospital] = useState({});
  const [timeSuspend, setTimeSuspend] = useState(0);
  const [defaultTimeIsEnable, setDefaultTimeIsEnable] = useState(false);
  const [typeTimeSuspend, setTypeTimeSuspend] = useState({
    label: "minutos",
    value: "minutes",
  });
  var type_time = [
    {
      label: "minutos",
      value: "minutes",
    },
  ];

  useEffect(() => {
    function LoadHospital() {
      api
        .get(`/hospitals/${user.dados.hospital_id}`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadHospital sucess");
            var data = response.data;
            console.log(data);
            setHospital(data);
          }
        })
        .catch((error) => {
          console.log("LoadHospital error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as informações do hospital",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    function loadHospitalSuspensionTime() {
      api
        .get("default-settings/hospitals/suspension-time")
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadHospital sucess");
            setTimeSuspend(response.data.minutes);
            setDefaultTimeIsEnable(response.data.is_enable);
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setDefaultTimeIsEnable(false);
          } else {
            console.log("LoadHospital error " + error);
            dispatch(
              Show_Alert({
                type: "error",
                msg: "Não foi possível carregar as informações do hospital",
              })
            );
          }
        })
        .finally(() => setLoading(false));
    }

    LoadHospital();
    loadHospitalSuspensionTime();
  }, []);

  function SaveTime() {
    setLoadingBtn("flex");
    api
      .post("default-settings/hospitals/suspension-time", {
        minutes: timeSuspend,
        is_enable: defaultTimeIsEnable,
      })
      .then((response) => {
        dispatch(
          Show_Alert({
            type: "success",
            msg: "Tempo padrão de suspensão do hospital editado com sucesso",
          })
        );
      })
      .catch((error) => {
        console.log("LoadHospital error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível o tempo padrão de suspensão do hospital",
          })
        );
      });
    setLoadingBtn("none");
  }

  return (
    <div>
      <h2 className="screen-name mb-4">Tempo de suspensão padrão</h2>

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && (
        <>
          <Col xs={12} md={10} lg={8} xl={4} className="mb-4 px-0">
            <div className="d-flex align-items-end">
              <div style={{ width: "100%" }}>
                <Form.Label className="field-label">
                  Tempo padrão de suspensão
                </Form.Label>
                <input
                  type="number"
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    backgroundColor: !defaultTimeIsEnable
                      ? Colors.lightest_gray
                      : "transparent",
                  }}
                  disabled={!defaultTimeIsEnable}
                  className="default-input"
                  placeholder="Digite o período"
                  value={timeSuspend}
                  onChange={(v) => {
                    var val = v.target.value;
                    if (val <= 0) val = 0;
                    val = Math.floor(val);
                    setTimeSuspend(val);
                  }}
                />
              </div>

              <div style={{ width: "40%" }}>
                <Dropdown className="mdd-dropdown-time">
                  <Dropdown.Toggle
                    disabled={!defaultTimeIsEnable}
                    style={{ fontSize: "15px" }}
                    className="w-100 dropdown-dashboard-time"
                    id="dropdown-mdd-time"
                  >
                    {typeTimeSuspend.label}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    rootCloseEvent={"click"}
                    className="div-drop-sort"
                  >
                    {type_time.map((item) => {
                      return (
                        <Dropdown.Item
                          style={{
                            color:
                              item.value == typeTimeSuspend.value
                                ? Colors.brand_blue
                                : Colors.dark_gray,
                          }}
                          onClick={() => setTypeTimeSuspend(item)}
                        >
                          <Icon
                            icon={item.icon}
                            size={24}
                            color={
                              item.value == typeTimeSuspend.value
                                ? Colors.brand_blue
                                : Colors.dark_gray
                            }
                            className="mr-3"
                          />
                          {item.label}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center mt-3 mb-3"
              onClick={() => {
                console.log(defaultTimeIsEnable);
                setDefaultTimeIsEnable(!defaultTimeIsEnable);
              }}
            >
              <CheckBox noMargin={true} checked={!defaultTimeIsEnable} />
              <p className="modal-name ml-2 mb-0" style={{ fontSize: 14 }}>
                Desativado
              </p>
            </div>
          </Col>

          <div className="mt-5">
            <button
              disabled={timeSuspend === 0 && defaultTimeIsEnable}
              onClick={() => SaveTime()}
              className=" modal-footer-btn btn-confirm float-right"
              style={{
                backgroundColor:
                  timeSuspend === 0 && defaultTimeIsEnable
                    ? Colors.lighter_gray
                    : Colors.brand_pink,
                width: 100,
              }}
            >
              <p
                className="mb-0"
                style={{
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Salvar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default TempoSuspensao;
