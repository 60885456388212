import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Col, Container, Row } from "react-bootstrap";

import "./styles.css";

import MainNavBar from "../../components/Nav/MainNavbar";

import AderenciaProtocolo from "./AderenciaProtocolo";
import Indicators from "./Indicators";
import RiskPatients from "./RiskPatients";
import PopulationalData from "./PopulationalData";
import Analysis from "./Analysis";

const widthScreen = window.innerWidth;
const heightScreen = window.innerHeight;
const heightChart = widthScreen <= 1370 ? 170 : 86;
const heightChartPie = widthScreen <= 1680 ? 180 : 120;

function RelatoriosInternalTabs(props) {
  const { history, reportInternalPage, handlerReportInternalPage } = props;

  // A função handleMiddleClick agora recebe o índice diretamente
  const handleMiddleClick = (e, tabIndex) => {
    if (e.button === 1) {
      e.preventDefault();
      const newUrl = `${window.location.origin}${window.location.pathname}?internalPage=${tabIndex}`;
      window.open(newUrl, "_blank");
    }
  };

  const handleTabClick = (index) => {
    handlerReportInternalPage(index);
  };

  return (
    <div>
      <Row className="tabs">
        <Col
          className="col-internal-report-page justify-content-center"
          xs={4}
          md={2}
          onClick={() => handleTabClick(0)}
          onMouseDown={(e) => handleMiddleClick(e, 0)} // Para a aba 'Indicadores'
        >
          <h6 className={reportInternalPage === 0 ? "active-text" : ""}>
            Indicadores
          </h6>
        </Col>
        <Col
          className="col-internal-report-page justify-content-center"
          xs={4}
          md={2}
          onClick={() => handleTabClick(1)}
          onMouseDown={(e) => handleMiddleClick(e, 1)} // Para a aba 'Pacientes de Risco'
        >
          <h6 className={reportInternalPage === 1 ? "active-text" : ""}>
            Pacientes de Risco
          </h6>
        </Col>
        <Col
          className="col-internal-report-page justify-content-center"
          xs={4}
          md={2}
          onClick={() => handleTabClick(2)}
          onMouseDown={(e) => handleMiddleClick(e, 2)} // Para a aba 'Pacientes de Risco'
        >
          <h6 className={reportInternalPage === 2 ? "active-text" : ""}>
            Dados populacionais
          </h6>
        </Col>
        {widthScreen > 770 && (
          <Col
            className="col-internal-report-page justify-content-center"
            xs={4}
            md={2}
            onClick={() => handleTabClick(3)}
            onMouseDown={(e) => handleMiddleClick(e, 3)} // Para a aba 'Pacientes de Risco'
          >
            <h6 className={reportInternalPage === 3 ? "active-text" : ""}>
              Análises
            </h6>
          </Col>
        )}
      </Row>
      <Row className="tab-bar">
        <Col
          className={
            "col-tab-internal-report-page " +
            (reportInternalPage === 0 ? "active-tab" : "")
          }
          xs={4}
          md={2}
          onClick={() => handlerReportInternalPage(0)}
        />
        <Col
          className={
            "col-tab-internal-report-page " +
            (reportInternalPage === 1 ? "active-tab" : "")
          }
          xs={4}
          md={2}
          onClick={() => handlerReportInternalPage(1)}
        />
        <Col
          className={
            "col-tab-internal-report-page " +
            (reportInternalPage === 2 ? "active-tab" : "")
          }
          xs={4}
          md={2}
          onClick={() => handlerReportInternalPage(2)}
        />
        {widthScreen > 770 && (
          <Col
            className={
              "col-tab-internal-report-page " +
              (reportInternalPage === 3 ? "active-tab" : "")
            }
            xs={4}
            md={2}
            onClick={() => handlerReportInternalPage(3)}
          />
        )}
      </Row>
      {widthScreen < 770 && (
        <>
          <Row className="tabs">
            <Col
              className="col-internal-report-page justify-content-center"
              xs={4}
              md={2}
              onClick={() => handlerReportInternalPage(3)}
            >
              <h6 className={reportInternalPage === 3 ? "active-text" : ""}>
                Análises
              </h6>
            </Col>
          </Row>
          <Row className="tab-bar">
            <Col
              className={
                "col-tab-internal-report-page " +
                (reportInternalPage === 3 ? "active-tab" : "")
              }
              xs={4}
              md={2}
              onClick={() => handlerReportInternalPage(3)}
            />
          </Row>
        </>
      )}
      {reportInternalPage == 0 && <Indicators />}
      {reportInternalPage == 1 && <RiskPatients history={history} />}
      {reportInternalPage == 2 && <PopulationalData history={history} />}
      {reportInternalPage == 3 && <Analysis />}
    </div>
  );
}

function Relatorios({ history }) {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const hospitalPlan = useSelector((state) => state.hospitalPlan);
  const [reportPage, setReportPage] = useState(0);
  const [reportInternalPage, setReportInternalPage] = useState(0);

  const handleOpenInNewTab = (path) => {
    const newUrl = window.location.origin + path;
    window.open(newUrl, "_blank");
  };

  const handleMiddleClick = (e, reportPageIndex) => {
    if (e.button === 1) {
      e.preventDefault();
      // A URL agora deve refletir a mudança para a página de relatórios correta
      const newUrl = `${window.location.origin}${window.location.pathname}?reportPage=${reportPageIndex}`;
      window.open(newUrl, "_blank");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const internalPage = searchParams.get("internalPage");
    if (internalPage) {
      setReportInternalPage(Number(internalPage));
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    // Aqui deve ser 'reportPage' se a sua lógica esperar isso para mudar entre as categorias
    const page = searchParams.get("reportPage");
    if (page) {
      setReportPage(Number(page));
    }
  }, []);

  return (
    <div className="mb-5">
      <MainNavBar
        history={history}
        screen={reportPage == 1 ? "adherence" : "reports"}
      />

      <Container fluid className="main-container">
        <h3>Relatórios</h3>
        <div>
          <Row className="tabs mt-4">
            <Col
              className="col-report-page"
              xs={6}
              md={2}
              onClick={() => setReportPage(0)}
              // Passamos o índice para a função handleMiddleClick correspondente à página de relatórios
              onMouseDown={(e) => handleMiddleClick(e, 0)}
            >
              <h6 className={reportPage === 0 ? "active-text" : ""}>
                Lesões por pressão
              </h6>
            </Col>
            {hospitalPlan.dados.type === 1 && (
              <Col
                xs={6}
                md={2}
                onClick={() => setReportPage(1)}
                className="col-report-page"
                // Passamos o índice para a função handleMiddleClick correspondente à página de relatórios
                onMouseDown={(e) => handleMiddleClick(e, 1)}
              >
                <h6 className={reportPage === 1 ? "active-text" : ""}>
                  Aderência ao protocolo
                </h6>
              </Col>
            )}
          </Row>
          <Row className="tab-bar">
            <Col
              className={
                "col-tab-report-page " + (reportPage === 0 ? "active-tab" : "")
              }
              xs={6}
              md={2}
              lg={2}
              onClick={() => setReportPage(0)}
            />
            <Col
              className={
                "col-tab-report-page " + (reportPage === 1 ? "active-tab" : "")
              }
              xs={6}
              md={2}
              lg={2}
              onClick={() => setReportPage(1)}
            />
          </Row>
        </div>
        {reportPage === 0 && (
          <RelatoriosInternalTabs
            history={history}
            reportInternalPage={reportInternalPage}
            handlerReportInternalPage={setReportInternalPage}
          />
        )}
        {reportPage === 1 && <AderenciaProtocolo />}
      </Container>
    </div>
  );
}

export default Relatorios;
