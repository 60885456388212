import moment from "moment";
import React, { useState, useEffect } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import EditDropown from "../PainelSensores/src/components/EditBeaconDropdown";
import ModalAddShift from "./ModalAddShift";
import ModalDeleteShift from "./ModalDeleteShift";
import ModalEditShift from "./ModalEditShift";

function EscalaPlantoes() {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit.selecionado);
  const user = useSelector((state) => state.user);

  const [shifts, setShifts] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");

  const [shift, setShift] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    function loadShifts() {
      setLoading(true);
      api
        .get(`hospitals/${user.dados.hospital_id}/shifts`, {
          params: {
            unitId: unit.id,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            setShifts(response.data);
            console.log("LoadHospital sucess");
          }
        })
        .catch((error) => {
          console.log("LoadHospital error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as informações dos plantões",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    loadShifts();
  }, [reload, unit]);

  function editShift(shift) {
    setLoading(true);
    api
      .put(`hospitals/${user.dados.hospital_id}/shifts/${shift.id}`, shift)
      .then((response) => {
        if (response.status == 200) {
          setShifts(response.data);
          console.log("LoadHospital sucess");
        }
      })
      .catch((error) => {
        console.log("LoadHospital error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível carregar as informações dos plantões",
          })
        );
      })
      .finally(() => {
        setLoading(false);
        setReload(!reload);
      });
  }

  return (
    <div>
      <div className="d-flex align-items-center title-patient mb-4">
        <h2 className="screen-name mb-4">Escala de plantões</h2>
        <button
          onClick={() => {
            setShowAddModal(true);
          }}
          style={{
            backgroundColor: Colors.brand_pink,
          }}
          className="modal-footer-btn mr-0 btn-cancel"
        >
          <p
            style={{
              color: "white",
              display: "flex",
              fontSize: "15px",
            }}
          >
            Adicionar plantão
          </p>
        </button>
      </div>

      {!loading && shifts.length === 0 && (
        <div className="container-error-default my-3">
          <p>Nenhum resultado foi encontrado.</p>
        </div>
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && shifts.length > 0 && (
        <Table className="user-table" responsive>
          <thead>
            <tr>
              <th>Nome do plantão</th>
              <th>Horário de início</th>
              <th>Data de início</th>
              <th>Ativo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {shifts.map((item, index) => {
              return (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.start_time.substr(0, 5)}</td>
                  <td>{moment(item.start_date).format("DD/MM/YYYY")}</td>
                  <td>
                    <Form.Check
                      type="switch"
                      label=" "
                      checked={item.status}
                      readOnly={true}
                      onClick={() => {
                        item.status = !item.status;
                        editShift(item);
                      }}
                      id={`sensor-panel-item-toggle-${index}`}
                      data-testid="beacon-panel-item-toggle"
                      className={`o-toggle o-toggle-success ${
                        item.status === 1 ? "is-checked" : ""
                      }`}
                    />
                  </td>
                  <td>
                    <EditDropown
                      index={index}
                      beacon={item}
                      handler_select_beacon={setShift}
                      handler_modal_edit_beacon={setShowEditModal}
                      handler_modal_delete_beacon={setShowDeleteModal}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {showAddModal && (
        <ModalAddShift
          reload={reload}
          handler_reload={setReload}
          show_modal={showAddModal}
          handler_show_modal={setShowAddModal}
        />
      )}
      {showEditModal && (
        <ModalEditShift
          shift={shift}
          reload={reload}
          handler_reload={setReload}
          show_modal={showEditModal}
          handler_show_modal={setShowEditModal}
        />
      )}
      {showDeleteModal && (
        <ModalDeleteShift
          shift={shift}
          reload={reload}
          handler_reload={setReload}
          show_modal={showDeleteModal}
          handler_show_modal={setShowDeleteModal}
        />
      )}
    </div>
  );
}

export default EscalaPlantoes;
