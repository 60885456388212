import React, { useEffect, useState } from "react";
import Icon from '../functions/Icon';
import "../styles/global.css";

import Colors from '../styles/Colors';

function Display(props) {
    const [ display, setDisplay ] = useState(props.value)
    const handler = props.onclick

    useEffect(() => {
        handler(display)
    }, [display])

    return(
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div 
                className={ display == 'card' ? "btn-display active" : "btn-display" }
                onClick={() => setDisplay('card')}>
                <Icon 
                    color={ display == 'card' ? '#fff' : Colors.darker_gray } 
                    size={22} 
                    icon="card-view"
                />
            </div>
            <div 
                className={ display == 'list' ? "btn-display active ml-2" : "btn-display ml-2" }
                onClick={() => setDisplay('list')}>
                <Icon 
                    color={ display == 'list' ? '#fff' : Colors.darker_gray } 
                    size={22} 
                    icon="list"
                />
            </div>
        </div>
    );
}

export default Display;