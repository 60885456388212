import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Colors from "../../../../../../../styles/Colors";
import {
  resizePatientOverviewChartBasedOnWidth,
  secondsToFullTime,
  secondsToHoursMinutes,
} from "../../../../../../../functions/utils";
import { getColor } from "../../../../../../../components/Charts/src/support/utils";

function TotalTimePositionPie(props) {
  const { historics } = props;

  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const historicLabel = [];
    const historicTime = [];
    const historicColors = [];
    for (const historic of historics.positionTime) {
      if (historic.position == "Tempo Pausado") {
        continue;
      }
      historicLabel.push(historic.position);
      historicTime.push(historic.time);
      historicColors.push(getColor(historic.position));
    }
    setLabels(historicLabel);
    setData(historicTime);
    setColors(historicColors);
  }, [historics]);

  return (
    <>
      <Pie
        height={resizePatientOverviewChartBasedOnWidth()}
        data={{
          labels: labels,
          datasets: [
            {
              label: "# of Votes",
              data,
              backgroundColor: colors,
              borderColor: colors,
              borderWidth: 1,
            },
          ],
        }}
        options={{
          plugins: {
            datalabels: {
              color: "#000",
              textAlign: "center",
              font: {
                size: 16,
              },
            },
          },
          legend: {
            position: "bottom",
            align: "center",
            fullWidth: true,
            labels: {
              fontColor: "#A0A4A9",
              usePointStyle: true,
              fontFamily: "Rubik-Regular",
              padding: 27,
            },
          },
          tooltips: {
            callbacks: {
              title: function (tooltipItem, data) {
                return data["labels"][tooltipItem[0]["index"]];
              },
              label: function (tooltipItem, data) {
                var temp = data["datasets"][0]["data"][tooltipItem["index"]];
                return `Tempo: ${secondsToFullTime(temp)}`;
              },
            },
          },
        }}
      />
    </>
  );
}

export default TotalTimePositionPie;
