import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../../../../../services/api";
import moment from "moment";
import { Show_Alert } from "../../../../../../../../store/actions/alert";
import Colors from "../../../../../../../../styles/Colors";
import Icon from "../../../../../../../../functions/Icon";
import ModalEsquecidas from "./ModalEsquecidas";

var itemClicked = null;

function Esquecidas() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [date_start, setDate_start] = useState("");
  const [date_end, setDate_end] = useState("");
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    function LoadList() {
      api
        .get(
          `/patients/${patientSelected.dados.id}/report-notifications/forgotten`,
          {
            params: {
              date_start,
              date_end,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadList forgotten success");
            console.log(response.data);

            setList(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadList forgotten error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg:
                "Não foi possível carregar a lista de mudanças de decúbito esquecidas",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadList();
  }, [date_end]);

  function handleCallback(start, end) {
    setDate_start(moment(start).format("YYYY-MM-DD"));
    setDate_end(moment(end).format("YYYY-MM-DD"));
  }

  return (
    <div>
      {showModal && (
        <ModalEsquecidas
          handler_show_modal={setShowModal}
          show={showModal}
          dados={itemClicked}
        />
      )}
      <div className="mt-4">
        <div className="mt-3">
          <Col xs={12} md={8} xl={5}>
            <Form.Label className="field-label">Período</Form.Label>
            <Row className="justify-content-start align-items-center mb-3 mx-0">
              <DateRangePicker
                initialSettings={{
                  locale: {
                    cancelLabel: "Cancelar",
                    applyLabel: "Aplicar",
                  },
                  applyButtonClasses: "btn-dr-custom",
                }}
                onCallback={(start, end) => handleCallback(start, end)}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#ddd",
                    minHeight: 48,
                    borderRadius: 10,
                  }}
                  variant="light"
                  className="d-flex btn-date-range align-items-center"
                >
                  <Icon
                    color={Colors.darker_gray}
                    size={22}
                    icon="calendar"
                    className="mr-2"
                  />
                  <p className="m-0 text_e-left">
                    {date_start != ""
                      ? moment(date_start).format("DD/MM/YYYY")
                      : "Data inicial"}{" "}
                    -{" "}
                    {date_end != ""
                      ? moment(date_end).format("DD/MM/YYYY")
                      : "Data final"}
                  </p>
                </Button>
              </DateRangePicker>
            </Row>
          </Col>
        </div>
      </div>

      {loading && (
        <div className="mt-5 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && list.length == 0 && (
        <div className="container-error-default mt-5">
          <p>Nenhum resultado foi encontrado.</p>
        </div>
      )}

      {!loading && list.length > 0 && (
        <div className="mt-2">
          <Table className="patient-table" responsive hover>
            <thead>
              <tr>
                <th>Aviso</th>
                <th>Posição</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      itemClicked = item;
                      setShowModal(true);
                    }}
                  >
                    <td>{`${moment(item.datetime_schedule).format(
                      "DD/MM"
                    )} às ${moment(item.datetime_schedule).format("LT")}`}</td>
                    <td>{item.position_name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default Esquecidas;
