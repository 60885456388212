import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";

import MainNavbar from "../../../../components/Nav/MainNavbar";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import "./styles.css";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";

//Tabs
import Informacoes from "../Tabs/Informacoes/Informacoes";
import Lesoes from "../Tabs/Lesoes/Lesoes";
import AvaliacaoRiscoPaciente from "../Tabs/AvaliacaoRiscoPaciente/AvaliacaoRiscoPaciente";
import Prescricao from "../Tabs/Prescricao/Prescricao";
import RelogioDecubito from "../Tabs/RelogioDecubito/RelogioDecubito";
import CheckListPaciente from "../Tabs/CheckListPaciente/CheckListPaciente";
import DiagramaIshikawa from "../Tabs/DiagramaIshikawa/DiagramaIshikawa";
import Observacoes from "../Tabs/Observacoes/Observacoes";
import RelatoriosPaciente from "../Tabs/RelatoriosPaciente/RelatoriosPaciente";
import Historico from "../Tabs/Historico/Historico";

var buttons = [
  {
    icon: "info",
    name: "Informações",
    planType: -1,
  },
  {
    icon: "band-aid",
    name: "Lesões",
    planType: -1,
  },
  {
    icon: "clipboard",
    name: "Avaliação de risco",
    planType: -1,
  },
  {
    icon: "list",
    name: "Prescrição",
    planType: -1,
  },
  {
    icon: "clock-cicle",
    name: "Relógio de decúbito",
    planType: 1,
  },
  {
    icon: "check-square",
    name: "Checklist",
    planType: -1,
  },
  {
    icon: "ishikawa-diagram",
    name: "Diagrama de Ishikawa",
    planType: -1,
  },
  {
    icon: "description",
    name: "Observações",
    planType: -1,
  },
  {
    icon: "pizza-chart",
    name: "Relatórios",
    planType: -1,
  },
  {
    icon: "history",
    name: "Histórico do paciente",
    planType: -1,
  },
];

var withWound = undefined;
var openConfirmed = undefined;
function PerfilPaciente({ history }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);
  const patientTab = useSelector((state) => state.patientTab);
  const user = useSelector((state) => state.user);
  const hospitalPlan = useSelector((state) => state.hospitalPlan);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (patientTab.selected && !isNaN(patientTab.selected)) {
      setActiveTab(patientTab.selected);
    }
  }, [patientTab.selected]);

  useEffect(() => {
    withWound = undefined;
    if (location.state?.withWound == true) {
      withWound = true;
      let state = { ...history.location.state };
      delete state.withWound;
      history.replace({ ...history.location, state });
    }

    openConfirmed = undefined;
    if (location.state?.openConfirmed == true) {
      openConfirmed = true;
      let state = { ...history.location.state };
      delete state.openConfirmed;
      history.replace({ ...history.location, state });
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");

    if (tab !== null && !isNaN(tab)) {
      setActiveTab(Number(tab));
    }
  }, [location]);

  const handleMouseDown = (e, index) => {
    e.preventDefault();
    const newUrl = `/Pacientes/Perfil-Paciente?tab=${index}`;
    if (e.button === 0) {
      setActiveTab(index);
      history.push(newUrl);
    } else if (e.button === 1) {
      window.open(newUrl, "_blank");
    }
  };

  return (
    <div>
      <MainNavbar history={history} screen="patient-profile" />
      <Container fluid className="main-container">
        <Breadcrumb
          history={history}
          list_breadcrumb={[
            { name: "Home", url: "Home" },
            { name: "Pacientes", url: "Pacientes" },
            { name: "Detalhes do paciente", url: "" },
          ]}
        />
        <h2 className="name-patient">{patientSelected.dados.name}</h2>
        <Row>
          <Col xs={12} md={3}>
            {buttons.map((item, index) => {
              if (
                item.planType !== -1 &&
                item.planType !== hospitalPlan.dados.type
              ) {
                return null;
              }
              if (
                item.name === "Observações" &&
                user.dados.permission.id > 4 &&
                user.dados.permission.id < 6
              ) {
                return null;
              }
              return (
                <button
                  key={index}
                  onMouseDown={(e) => handleMouseDown(e, index)}
                  className={`btn-profile-patient ${
                    activeTab === index ? "btn-active" : ""
                  }`}
                >
                  <Icon
                    color={
                      activeTab === index ? Colors.brand_blue : Colors.dark_gray
                    }
                    size={18}
                    icon={item.icon}
                    className="mr-3"
                  />
                  {item.name}
                </button>
              );
            })}
          </Col>
          <Col xs={12} md={9} className="mt-4 mt-md-0">
            {activeTab === 0 && <Informacoes />}
            {activeTab === 1 && (
              <Lesoes
                withWound={withWound}
                openConfirmed={openConfirmed}
                history={history}
              />
            )}
            {activeTab === 2 && <AvaliacaoRiscoPaciente />}
            {activeTab === 3 && <Prescricao />}
            {activeTab === 4 && <RelogioDecubito />}
            {activeTab === 5 && <CheckListPaciente />}
            {activeTab === 6 && <DiagramaIshikawa />}
            {activeTab === 7 && <Observacoes />}
            {activeTab === 8 && <RelatoriosPaciente />}
            {activeTab === 9 && <Historico />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PerfilPaciente;
