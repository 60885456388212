import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Col, Container, Row, Button } from "react-bootstrap";
import MainNavbar from "../../../components/Nav/MainNavbar";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import moment from "moment";

import Colors from "../../../styles/Colors";
import "../../../styles/global.css";
import "../styles.css";

import Icon from "../../../functions/Icon";

const MOCK_BUNDLES = [
  {
    id: 1,
    title: "Lorem ipsum dolor sit amet",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum mi in dignissim aliquet. Donec feugiat dolor ac elit imperdiet placerat. Sed aliquam ullamcorper pharetra. Sed aliquet, lorem eget ornare fringilla, dui neque pellentesque lectus, a ultricies turpis velit sit amet mauris. Aliquam erat volutpat. Nunc sodales convallis varius. Vivamus scelerisque ex scelerisque luctus interdum. Nulla rhoncus justo quis velit dapibus tincidunt. Ut vel rutrum ex. Suspendisse potenti. Sed sit amet aliquet leo. Sed eu luctus metus. Aliquam id rhoncus tortor. Donec ultrices, orci in sodales aliquam, erat mauris rhoncus orci, sed posuere velit dolor vel enim. Curabitur cursus quam ut nunc lobortis, vitae auctor turpis eleifend. Nam quis venenatis lectus. Etiam vulputate dolor eget enim dictum vehicula. Mauris tristique rutrum mi. Suspendisse luctus egestas elementum. Etiam elit nisl, condimentum id porta ut, luctus non justo.",
    date_published: "2020-03-15T03:00:00.000Z",
    updated_at: "2020-04-29 15:14:23",
  },
  {
    id: 2,
    title: "Lorem ipsum dolor sit amet",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum mi in dignissim aliquet. Donec feugiat dolor ac elit imperdiet placerat. Sed aliquam ullamcorper pharetra. Sed aliquet, lorem eget ornare fringilla, dui neque pellentesque lectus, a ultricies turpis velit sit amet mauris. Aliquam erat volutpat. Nunc sodales convallis varius. Vivamus scelerisque ex scelerisque luctus interdum. Nulla rhoncus justo quis velit dapibus tincidunt. Ut vel rutrum ex. Suspendisse potenti. Sed sit amet aliquet leo. Sed eu luctus metus. Aliquam id rhoncus tortor. Donec ultrices, orci in sodales aliquam, erat mauris rhoncus orci, sed posuere velit dolor vel enim. Curabitur cursus quam ut nunc lobortis, vitae auctor turpis eleifend. Nam quis venenatis lectus. Etiam vulputate dolor eget enim dictum vehicula. Mauris tristique rutrum mi. Suspendisse luctus egestas elementum. Etiam elit nisl, condimentum id porta ut, luctus non justo.",
    date_published: "2020-03-15T03:00:00.000Z",
    updated_at: "2020-04-29 15:14:23",
  },
  {
    id: 3,
    title: "Lorem ipsum dolor sit amet",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum mi in dignissim aliquet. Donec feugiat dolor ac elit imperdiet placerat. Sed aliquam ullamcorper pharetra. Sed aliquet, lorem eget ornare fringilla, dui neque pellentesque lectus, a ultricies turpis velit sit amet mauris. Aliquam erat volutpat. Nunc sodales convallis varius. Vivamus scelerisque ex scelerisque luctus interdum. Nulla rhoncus justo quis velit dapibus tincidunt. Ut vel rutrum ex. Suspendisse potenti. Sed sit amet aliquet leo. Sed eu luctus metus. Aliquam id rhoncus tortor. Donec ultrices, orci in sodales aliquam, erat mauris rhoncus orci, sed posuere velit dolor vel enim. Curabitur cursus quam ut nunc lobortis, vitae auctor turpis eleifend. Nam quis venenatis lectus. Etiam vulputate dolor eget enim dictum vehicula. Mauris tristique rutrum mi. Suspendisse luctus egestas elementum. Etiam elit nisl, condimentum id porta ut, luctus non justo.",
    date_published: "2020-03-15T03:00:00.000Z",
    updated_at: "2020-04-29 15:14:23",
  },
  {
    id: 4,
    title: "Lorem ipsum dolor sit amet",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum mi in dignissim aliquet. Donec feugiat dolor ac elit imperdiet placerat. Sed aliquam ullamcorper pharetra. Sed aliquet, lorem eget ornare fringilla, dui neque pellentesque lectus, a ultricies turpis velit sit amet mauris. Aliquam erat volutpat. Nunc sodales convallis varius. Vivamus scelerisque ex scelerisque luctus interdum. Nulla rhoncus justo quis velit dapibus tincidunt. Ut vel rutrum ex. Suspendisse potenti. Sed sit amet aliquet leo. Sed eu luctus metus. Aliquam id rhoncus tortor. Donec ultrices, orci in sodales aliquam, erat mauris rhoncus orci, sed posuere velit dolor vel enim. Curabitur cursus quam ut nunc lobortis, vitae auctor turpis eleifend. Nam quis venenatis lectus. Etiam vulputate dolor eget enim dictum vehicula. Mauris tristique rutrum mi. Suspendisse luctus egestas elementum. Etiam elit nisl, condimentum id porta ut, luctus non justo.",
    date_published: "2020-03-15T03:00:00.000Z",
    updated_at: "2020-04-29 15:14:23",
  },
  {
    id: 5,
    title: "Lorem ipsum dolor sit amet",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum mi in dignissim aliquet. Donec feugiat dolor ac elit imperdiet placerat. Sed aliquam ullamcorper pharetra. Sed aliquet, lorem eget ornare fringilla, dui neque pellentesque lectus, a ultricies turpis velit sit amet mauris. Aliquam erat volutpat. Nunc sodales convallis varius. Vivamus scelerisque ex scelerisque luctus interdum. Nulla rhoncus justo quis velit dapibus tincidunt. Ut vel rutrum ex. Suspendisse potenti. Sed sit amet aliquet leo. Sed eu luctus metus. Aliquam id rhoncus tortor. Donec ultrices, orci in sodales aliquam, erat mauris rhoncus orci, sed posuere velit dolor vel enim. Curabitur cursus quam ut nunc lobortis, vitae auctor turpis eleifend. Nam quis venenatis lectus. Etiam vulputate dolor eget enim dictum vehicula. Mauris tristique rutrum mi. Suspendisse luctus egestas elementum. Etiam elit nisl, condimentum id porta ut, luctus non justo.",
    date_published: "2020-03-15T03:00:00.000Z",
    updated_at: "2020-04-29 15:14:23",
  },
  {
    id: 6,
    title: "Lorem ipsum dolor sit amet",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum mi in dignissim aliquet. Donec feugiat dolor ac elit imperdiet placerat. Sed aliquam ullamcorper pharetra. Sed aliquet, lorem eget ornare fringilla, dui neque pellentesque lectus, a ultricies turpis velit sit amet mauris. Aliquam erat volutpat. Nunc sodales convallis varius. Vivamus scelerisque ex scelerisque luctus interdum. Nulla rhoncus justo quis velit dapibus tincidunt. Ut vel rutrum ex. Suspendisse potenti. Sed sit amet aliquet leo. Sed eu luctus metus. Aliquam id rhoncus tortor. Donec ultrices, orci in sodales aliquam, erat mauris rhoncus orci, sed posuere velit dolor vel enim. Curabitur cursus quam ut nunc lobortis, vitae auctor turpis eleifend. Nam quis venenatis lectus. Etiam vulputate dolor eget enim dictum vehicula. Mauris tristique rutrum mi. Suspendisse luctus egestas elementum. Etiam elit nisl, condimentum id porta ut, luctus non justo.",
    date_published: "2020-03-15T03:00:00.000Z",
    updated_at: "2020-04-29 15:14:23",
  },
];

function Bundle({ history }) {
  const [search, setSearch] = useState("");
  const [itens, setItens] = useState(MOCK_BUNDLES);

  useEffect(() => {
    if (search != "" && search.length > 0) {
      var lsearch = search.toLowerCase();
      setItens(
        MOCK_BUNDLES.filter(
          (p) =>
            p.title.toLowerCase().includes(lsearch) ||
            p.content.toLowerCase().includes(lsearch)
        )
      );
    } else {
      setItens(MOCK_BUNDLES);
    }
  }, [search]);

  return (
    <div>
      <MainNavbar history={history} />

      <Container
        fluid
        className="main-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Breadcrumb
          history={history}
          list_breadcrumb={[
            { name: "Descubra", url: "Descubra" },
            { name: "Bundle", url: "" },
          ]}
        />

        <h2 className="title mb-4 mt-2">Bundle</h2>

        <Row>
          <Col xs={12} md={6} lg={4}>
            <div className="fake-input py-2 pl-3">
              <Icon color={Colors.darker_gray} size={24} icon="search" />
              <input
                className="borderless-input ml-2 search"
                placeholder="Buscar"
              />
            </div>
          </Col>
        </Row>

        <Row className="align-items-center mt-4">
          {itens.map((item) => {
            const options = { year: "numeric", month: "long", day: "numeric" };
            var datef = new Date(item.updated_at);
            var timef = datef.toTimeString();

            moment.locale("pt-BR");
            datef = moment(datef)
              .format("DD,MMMM,YYYY,HH:mm")
              .split(",");

            var month = datef[1].charAt(0).toUpperCase() + datef[1].slice(1);

            datef =
              datef[0] + " de " + month + " de " + datef[2] + " às " + datef[3];

            return (
              <Col xs={6} md={4} lg={4} className="mb-4">
                <div className="main-card">
                  <div className="card-title">{item.title}</div>
                  <div className="card-date mt-2">Última revisão: {datef}</div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Bundle;
