import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Icon from "../../../../../../functions/Icon";
import Colors from "../../../../../../styles/Colors";

function EditBeaconDropdown(props) {
  const {
    index,
    beacon,
    handler_select_beacon,
    handler_modal_edit_beacon,
    handler_modal_delete_beacon,
  } = props;
  const [showToggleEdit, setShowToggleEdit] = useState(false);
  return (
    <Dropdown
      className="drop-sort mr-3"
      onToggle={() => setShowToggleEdit(!showToggleEdit)}
    >
      <Dropdown.Toggle
        id={`sensor-panel-item-toggle-${index}`}
        className={`beacon-toggle-dropdown btn-filter-mdd ${
          showToggleEdit ? "active" : ""
        }`}
      >
        <Icon
          color={showToggleEdit ? Colors.brand_blue : Colors.darker_gray}
          size={22}
          icon="dots-vertical"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="drop-crud-options"
        style={{
          marginTop: "0px!important",
        }}
      >
        <Dropdown.Item
          onClick={() => {
            handler_select_beacon(beacon);
            handler_modal_edit_beacon(true);
          }}
        >
          Editar
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            handler_select_beacon(beacon);
            handler_modal_delete_beacon(true);
          }}
        >
          Excluir
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default EditBeaconDropdown;
