import api from "@Services/api";

export const SensorsPanelsApi = async ({
  currentPage,
  qtdDisplay,
  unit_id,
  mac,
  batteryFilter,
}) => {
  if (!currentPage) throw new Error("Param `page` left in request");
  if (!qtdDisplay) throw new Error("Param `page` left in request");
  if (!unit_id) throw new Error("Param `unit_id` left in request");
  if (mac === null) throw new Error("Param `mac` left in request");

  const endpoint = "/beacons";
  const params = {
    page: currentPage,
    pageSize: qtdDisplay,
    pagination: true,
    unit_id,
    mac,
    battery: batteryFilter,
  };

  try {
    const response = await api.get(endpoint, { params });

    if (response.status !== 200)
      throw new Error("Não foi possível carregar a lista de sensores");

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SensorsPanelHospitalUnitsApi = async ({ hostipal_id }) => {
  if (!hostipal_id) throw new Error("Param `hospital_id` is left in request.");

  const endpoint = `/hospitals/${hostipal_id}/units`;

  try {
    const response = await api.get(endpoint);

    if (response.status !== 200)
      throw new Error("Não foi possível carregar a lista de unidades.");

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SensorsPanelsHistoricApi = async ({
  beacon_id,
  page,
  pageSize,
}) => {
  if (!page) throw new Error("Param `page` left in request");
  if (!pageSize) throw new Error("Param `page` left in request");
  if (!beacon_id) throw new Error("Param `unit_id` left in request");

  const endpoint = `/beacons/${beacon_id}/historic`;
  const params = {
    page,
    pageSize,
  };

  try {
    const response = await api.get(endpoint, { params });

    if (response.status !== 200)
      throw new Error("Não foi possível carregar o histórico do sensor.");

    return response.data;
  } catch (error) {
    throw error;
  }
};
