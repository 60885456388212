import React, { useEffect, useState } from "react";
import Colors from "../../../../../../../styles/Colors";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { Chart } from "chart.js";
import { resizeChartBasedOnWidth } from "../../../../../../../functions/utils";
import ModalRankingSuspensionsDetails from "../../../../../../Relatorios/ModalRankingSuspensionsDetails";

function SuspensionsByShiftBar(props) {
  const { suspensionsData } = props;

  const [data, setData] = useState(null);
  const [options, setOptions] = useState(null);
  const [lastShiftSuspensionIds, setLastShiftSuspensionIds] = useState(null);
  const [showSuspensionsDetailsModal, setShowSuspensionsDetailsModal] =
    useState(null);

  useEffect(() => {
    if (suspensionsData) {
      const labels = [];
      const labelsTime = [];
      const datasets = [];
      let index = 0;
      for (const shift of suspensionsData) {
        let arrayData = new Array(suspensionsData.length).fill(0);
        arrayData[index] = shift.counter;
        labels.push(shift.date);
        labelsTime.push(shift.time);
        datasets.push({
          ids: shift.ids,
          label: "Plantão " + shift.shiftName + ` (DT:${index + 1})`,
          data: arrayData,
          backgroundColor: shift.shiftColor,
          borderColor: shift.shiftColor,
          borderWidth: 1,
        });
        index++;
      }
      setOptions({
        onClick: function (evt, item) {
          if (item.length > 0) {
            const element = item[0];
            const index = element._index;

            const suspensionIds = datasets[index].ids;
            setLastShiftSuspensionIds({
              ids: suspensionIds,
            });
            setShowSuspensionsDetailsModal(true);
          }
        },
        onHover: function (event, chartElement) {
          event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
        tooltips: {
          mode: "nearest",
          intersect: true,
          filter: function (tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const currentLabel = dataset.label;
            const currentDataValue = dataset.data[tooltipItem.index];
            const isHighestValue = data.datasets.every((otherDataset) => {
              const otherDataValue = otherDataset.data[tooltipItem.index];
              return currentDataValue >= otherDataValue;
            });
            return isHighestValue;
          },
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.index];
              const labelText = dataset.label.replace(/\s+\(DT:\d+\)/gm, "");
              return labelText + `: ${dataset.data[tooltipItem.index]}`;
            },
            afterLabel: function () {
              return "";
            },
          },
        },
        responsive: true,
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 7,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value, index, values) => {
                  return [value, labelsTime[index]];
                },
              },
              barPercentage: 0.5,
            },
          ],
          yAxes: [
            {
              position: "left",
              gridLines: {
                color: Colors.light_gray,
                offsetGridLines: false,
                borderDash: [6],
                lineWidth: 1,
              },
              ticks: {
                callback: function (value) {
                  if (Number.isInteger(value)) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      });
      setData({
        labels,
        datasets,
      });
    }
  }, [suspensionsData]);

  return (
    data &&
    options && (
      <>
        <Bar data={data} options={options} height={resizeChartBasedOnWidth()} />
        <div className="container-subtitle mt-4">
          {data.datasets
            .reduce((acc, item) => {
              const nome = item.label.replace(/\s+\(DT:\d+\)/, "");
              if (!acc.find((accItem) => accItem.label.startsWith(nome))) {
                acc.push(item);
              }
              return acc;
            }, [])
            .map((item, index) => (
              <div className="subtitle-item mr-4">
                <div
                  className="indicator-radius"
                  style={{ backgroundColor: item.backgroundColor }}
                />
                <p>{item.label.replace(/\s+\(DT:\d+\)/, "")}</p>
              </div>
            ))}
        </div>
        {showSuspensionsDetailsModal && (
          <ModalRankingSuspensionsDetails
            origin={["patient"]}
            suspension={lastShiftSuspensionIds}
            handler_show_modal={setShowSuspensionsDetailsModal}
            show_modal={showSuspensionsDetailsModal}
          />
        )}
      </>
    )
  );
}

export default SuspensionsByShiftBar;
