import React from "react";
import Colors from "../../../styles/Colors";

function ButtonFilter(props) {
  const { text, checked, onClick } = props;
  return (
    <button
      onClick={onClick}
      style={{
        background: checked ? Colors.brand_blue : Colors.lightest_gray,
      }}
      className="btn-click"
    >
      <p
        className="mb-0"
        style={{
          color: checked ? "white" : Colors.darker_gray,
        }}
      >
        {text}
      </p>
    </button>
  );
}

export default ButtonFilter;
