const colorDefault = "#7FA7E6";
const mapping_front = {
  name: "front",
  areas: [
    {
      id: 5,
      name: "Orelha direita",
      shape: "circle",
      coords: [140, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 6,
      name: "Orelha esquerda",
      shape: "circle",
      coords: [220, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 1,
      name: "Boca",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 8,
      name: "Ombro esquerdo",
      shape: "circle",
      coords: [280, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 9,
      name: "Ombro direito",
      shape: "circle",
      coords: [90, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 10,
      name: "Trocânter esquerdo",
      shape: "circle",
      coords: [250, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 11,
      name: "Trocânter direito",
      shape: "circle",
      coords: [120, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 43,
      name: "Anterior joelho esquerdo",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 13,
      name: "Externo joelho direto",
      shape: "circle",
      coords: [110, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 14,
      name: "Interno maléolo esquerdo",
      shape: "circle",
      coords: [210, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 15,
      name: "Interno maléolo direito",
      shape: "circle",
      coords: [160, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 16,
      name: "Hálux esquerdo",
      shape: "circle",
      coords: [230, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 17,
      name: "Hálux direito",
      shape: "circle",
      coords: [140, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 42,
      name: "Anterior joelho direto",
      shape: "circle",
      coords: [160, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 30,
      name: "Externo joelho esquerdo",
      shape: "circle",
      coords: [260, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

export default mapping_front;
