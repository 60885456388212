import React, { useEffect, useState } from "react";
import Icon from "../functions/Icon";
import "../styles/global.css";

function CheckBox(props) {
  const [disabled, setDisabled] = useState(props.disabled);
  const [value, setValue] = useState(props.value);
  const [checked, setChecked] = useState(props.checked);
  const [hovered, setHovered] = useState(props.hovered);
  const [noMargin, setNoMargin] = useState(props.noMargin);
  const handler = props.onclick;

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  useEffect(() => {
    setHovered(props.hovered);
  }, [props.hovered]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {(props.size == null || props.size == "md") && (
        <div
          style={{
            cursor: disabled ? "unset" : "pointer",
            margin: noMargin ? "0px" : "5px",
          }}
          className={
            checked
              ? "check-box checked"
              : hovered
              ? "check-box hovered"
              : "check-box"
          }
          onClick={
            value != undefined
              ? () => {
                  handler(value);
                }
              : handler
          }
        >
          {checked && <Icon color={"#fff"} size={24} icon={"check"} />}
        </div>
      )}
      {props.size == "sm" && (
        <div
          style={{ cursor: disabled ? "unset" : "pointer" }}
          className={
            checked
              ? "check-box sm checked"
              : hovered
              ? "check-box sm hovered"
              : "check-box sm"
          }
          onClick={
            value != undefined
              ? () => {
                  handler(value);
                }
              : handler
          }
        >
          {checked && <Icon color={"#fff"} size={14} icon={"check"} />}
        </div>
      )}
      {props.size == "20px" && (
        <div
          style={{ cursor: disabled ? "unset" : "pointer" }}
          className={
            checked
              ? "check-box 20px checked"
              : hovered
              ? "check-box 20px hovered"
              : "check-box 20px"
          }
          onClick={
            value != undefined
              ? () => {
                  handler(value);
                }
              : handler
          }
        >
          {checked && <Icon color={"#fff"} size={20} icon={"check"} />}
        </div>
      )}
    </div>
  );
}

export default CheckBox;
