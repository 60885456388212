import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Spinner, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import api from "@Services/api";
import Colors from "@Styles/Colors";
import Icon from "@Functions/Icon";
import { Show_Alert } from "@Store/actions/alert";

import "../../PainelSensores.scss";

function ModalAddBeacon({ ...props }) {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit.selecionado);
  const { handler_show_modal, show_modal, handler_reload, reload } = props;

  const [mac, setMac] = useState("");
  const [status, setStatus] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState("none");

  function Save() {
    setLoadingBtn("flex");
    api
      .post("/beacons", {
        name: mac.slice(-2),
        mac,
        status,
        unit_id: unit.id,
      })
      .then((res) => {
        dispatch(
          Show_Alert({
            type: "success",
            msg: "Sensor adicionado com sucesso",
          })
        );
        handler_reload(!reload);
      })
      .catch((error) => {
        console.log("Save error " + error);
        console.log(error.response.status);
        if (error.response.status === 409) {
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Sensor já existente",
            })
          );
          return;
        }
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar o sensor",
          })
        );
      })
      .finally(() => {
        handler_show_modal(false);
        setLoadingBtn("none");
      });
  }

  function VerifyFields() {
    let verify = false;

    if (mac !== "" && unit) {
      verify = true;
    }

    return verify;
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-filter"
      data-testid="beacon-panel-toggle"
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Adicionar sensor</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>
        <div className="modal-all">
          <div className="header-discharge mt-3">
            <div className="mb-4">
              <Form.Label className="field-label">MAC</Form.Label>
              <input
                className="default-input"
                type="text"
                placeholder="Digite o MAC do sensor"
                value={mac}
                onChange={(e) => setMac(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Form.Label className="field-label">Unidade</Form.Label>
              <Select
                captureMenuScroll={false}
                classNamePrefix="react-select"
                placeholder={"Selecionar unidade"}
                isClearable={false}
                isDisabled={true}
                value={{
                  label: unit.name,
                  value: unit.id,
                }}
              />
            </div>
            <div className="mb-4">
              <Form.Label className="field-label">Status</Form.Label>
              <div>
                <Form.Check
                  type="switch"
                  label=" "
                  checked={status}
                  readOnly={true}
                  onClick={() => setStatus(!status)}
                  id={`sensor-status`}
                  className={`d-inline-block z-index-5 o-toggle o-toggle-success ${
                    true ? "is-checked" : ""
                  }`}
                />
                <span className="sensor-status-label">
                  {status ? "Ativo" : "Inativo"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="row justify-content-end align-items-center pb-0">
        <Col xs={4} md={3} lg={3} className="px-0 mr-3">
          <div
            className="secondary-btn modal-footer-btn"
            onClick={() => handler_show_modal(false)}
          >
            <p>Cancelar</p>
          </div>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={!VerifyFields() || loadingBtn === "flex"}
            style={{
              backgroundColor: VerifyFields()
                ? Colors.brand_pink
                : Colors.lighter_gray,
              cursor: "pointer",
            }}
            className="mb-0 modal-footer-btn btn-confirm"
            onClick={() => Save()}
            data-testid="beacon-panel-toggle-action"
          >
            <p
              className="mb-0"
              style={{
                color: VerifyFields() ? Colors.white : Colors.dark_gray,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              Adicionar
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddBeacon;
