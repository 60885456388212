import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Dropdown,
} from "react-bootstrap";

import Icon from "../../../../../functions/Icon";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import "./styles.css";

import RelatorioAvaliacao from "./Tabs/RelatorioAvaliacao";
import RelatorioLesao from "./Tabs/RelatorioLesao";
import RelatorioDecubito from "./Tabs/RelatorioDecubito";
import RelatorioCheckList from "./Tabs/RelatorioCheckList";

let btns = [
  {
    icon: "beds",
    removable: true,
    name: "Mudança de decúbito",
    value: 0,
  },
  {
    icon: "clipboard",
    name: "Avaliação de risco",
    value: 2,
  },
  {
    icon: "check-square",
    name: "Checklist",
    value: 3,
  },
];

function RelatoriosPaciente() {
  let initialTab = 0;
  const hospitalPlan = useSelector((state) => state.hospitalPlan);
  if (hospitalPlan.dados.type !== 1) {
    btns = btns.filter((item) => item.removable !== true);
    initialTab = 2;
  }
  const [tab, setTab] = useState(initialTab);

  return (
    <div>
      <h2 className="screen-name">Relatórios</h2>

      <Row className="mt-4 mb-5">
        {btns.map((item, index) => {
          return (
            <Col key={item.value} xs={12} md={6} xl={3}>
              <div
                onClick={() => setTab(item.value)}
                className="btn-relatorio-patient"
                style={{
                  background:
                    tab == item.value ? "rgba(70,141,255, .1)" : "white",
                }}
              >
                <Icon color={Colors.brand_blue} size={24} icon={item.icon} />
                <p
                  className="mb-0"
                  style={{
                    color:
                      tab == item.value ? Colors.brand_blue : Colors.dark_gray,
                  }}
                >
                  {item.name}
                </p>
              </div>
            </Col>
          );
        })}
      </Row>

      {tab === 0 && <RelatorioDecubito />}
      {tab === 1 && <RelatorioLesao />}
      {tab === 2 && <RelatorioAvaliacao />}
      {tab === 3 && <RelatorioCheckList />}
    </div>
  );
}

export default RelatoriosPaciente;
