import React, { useState, useEffect } from "react";

import { Col, Row, Form, Spinner, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { Show_Alert } from "@Store/actions/alert";
import DecubitWatch from "@Components/Charts/DecubitWatch/DecubitWatch";
import SensorApi from "./src/js/api";

import "./styles.css";
import Icon from "../../../../../../../../functions/Icon";
import SortSensorChart from "../../../../../../../../components/Dropdowns/SortSensorChart";
import DateRangerPicker from "../../../../../../../../components/DateRangerPicker";
import Colors from "../../../../../../../../styles/Colors";

function Sensor() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [dateInterval, setDateInterval] = useState({
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });
  const [response, setResponse] = useState(null);
  const sortInitialState = {
    name: "Data decrescente",
    value: "desc",
    initialState: true,
  };
  const [sortChartDate, setSortChartDate] = useState(sortInitialState);

  useEffect(() => {
    const onSensorApiSuccess = (response) => {
      setResponse(response);
      setSortChartDate(sortInitialState);
    };

    const onSensorApiError = (error) => {
      dispatch(
        Show_Alert({
          type: "error",
          msg: error.message,
        })
      );
    };

    const onSensorApiFinally = () => {
      setLoading(false);
    };

    const props = {
      loadingHandler: setLoading,
      patient_id: patientSelected.dados.id,
      initialDate: dateInterval.start,
      finalDate: dateInterval.end,
    };

    SensorApi(props)
      .then(onSensorApiSuccess)
      .catch(onSensorApiError)
      .finally(onSensorApiFinally);
  }, [dateInterval, patientSelected.dados.id, dispatch]);

  useEffect(() => {
    if (response && response.length > 0 && !sortChartDate?.initialState) {
      const reversedResponse = [...response];
      reversedResponse.reverse();
      setResponse(reversedResponse);
    }
  }, [sortChartDate]);

  const handleCallback = (start, end) => {
    let dateStart = moment(start);
    let dateEnd = moment(end);
    if (dateEnd.isAfter(moment(), "day")) {
      dateEnd = moment();
    }
    if (dateStart.isAfter(moment(), "day")) {
      dateStart = moment().subtract(1, "day");
      dateEnd = moment();
    }
    const datesDiff = dateEnd.diff(dateStart, "days");
    if (datesDiff > 7) {
      dispatch(
        Show_Alert({
          type: "error",
          msg: "Intervalo de datas não pode exceder 7 dias.",
        })
      );
    } else {
      setDateInterval({
        start: dateStart.format("YYYY-MM-DD"),
        end: dateEnd.format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <div>
      <div className="mt-4">
        <div className="mt-3 mb-4">
          <Row>
            <Col xs={12} md={8} xl={5}>
              <Form.Label className="field-label">Período</Form.Label>
              <Row className="justify-content-start align-items-center mb-1 mx-0">
                <DateRangerPicker
                  dateInterval={dateInterval}
                  dateIntervalHandler={setDateInterval}
                  maxDays={7}
                />
              </Row>

              <div className="d-flex align-items-center">
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon="info"
                  className="mr-1"
                />
                <p className="info-sensor">
                  O período de seleção máximo é de 7 dias
                </p>
              </div>
            </Col>
            <Col>
              <Form.Label className="field-label">Ordenar Por</Form.Label>
              <SortSensorChart
                handler={setSortChartDate}
                sort={sortChartDate}
              />
            </Col>
          </Row>
        </div>
      </div>

      {loading && (
        <div className="mt-5 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && response && (
        <Row>
          <Col xs={12}>
            {response.map((item, index) => (
              <div className="chart-sensor-card mb-5">
                <p className="chart-sensor-card-date">
                  {response.length > 1 &&
                    moment(item.date).format("DD/MM/YYYY")}
                </p>
                <DecubitWatch key={index} {...item} />
              </div>
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Sensor;
