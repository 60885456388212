import React from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import Icon from "../../../../../functions/Icon";
import Colors from "../../../../../styles/Colors";
import moment from "moment";
import { renderPhoto } from "../../../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../../../Home/Notificacoes/Notificacoes";

function DetailsDoubleHistoric({ ...props }) {
  const { handler_show_modal, show, dados } = props;

  return (
    <div>
      <Modal
        show={show}
        onHide={() => handler_show_modal(false)}
        centered
        className="modal-decubito"
      >
        <Modal.Body
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            paddingTop: 5,
            paddingBottom: 15,
          }}
        >
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Col xs={6} md={6} lg={6} className="pl-0">
              <div>
                <div className="modal-title">Detalhes da ação</div>
              </div>
            </Col>

            <Col
              xs={2}
              md={6}
              lg={6}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <Row>
            <p className="details-text mb-3">{dados.title}</p>
          </Row>
          <Row className="mb-2 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="calendar"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {moment(dados.created_at).format("LL")}
            </div>
          </Row>

          <Row className="mb-2 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="clock"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {moment(dados.created_at).format("LT")}
            </div>
          </Row>

          {dados.details.text && (
            <div>
              <Row className="mb-2 align-items-center">
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon="clock-arrow-up"
                  className="mr-2"
                />
                <div
                  className="modal-text-sm"
                  style={{ color: Colors.darker_gray }}
                >
                  {dados.details.text}
                </div>
              </Row>

              {!dados.details.is_edit && (
                <Row className="mb-0 align-items-center">
                  <Icon
                    color={Colors.brand_blue}
                    size={20}
                    icon="refresh"
                    className="mr-2"
                  />
                  <div
                    className="modal-text-sm"
                    style={{ color: Colors.darker_gray }}
                  >
                    {dados.details.position_name}
                  </div>
                </Row>
              )}

              {!dados.details.is_edit ? (
                <Row className="patients-notifications-suspends__motive flex-column mt-4">
                  <h2 className="title-motive">Motivo</h2>
                  {dados.details.is_others === true && (
                    <p className="text-motive">Outros</p>
                  )}
                  <p className="text-motive">{dados.details.motive_name}</p>
                </Row>
              ) : (
                <>
                  <Row className="patients-notifications-suspends__motive flex-column mt-4">
                    <h2 className="title-motive">Motivo Atual</h2>
                    <p className="text-motive">
                      {dados.details.new_motive_name}
                    </p>
                    {dados.details.new_motive_other != "null" && (
                      <p className="text-motive">
                        {dados.details.new_motive_other}
                      </p>
                    )}
                  </Row>
                  <Row className="patients-notifications-suspends__motive flex-column mt-4">
                    <h2 className="title-motive">Motivo Anterior</h2>
                    <p className="text-motive">
                      {dados.details.old_motive_name}
                      {dados.details.old_motive_other != "null" && (
                        <p className="text-motive">
                          {dados.details.old_motive_other}
                        </p>
                      )}
                    </p>
                  </Row>
                </>
              )}
            </div>
          )}
        </Modal.Body>

        <div className="px-4 pb-2 mt-3">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div className="c-profile-dropdown mr-2">
              {renderPhoto(
                makeUserDataToPhoto(
                  dados.user.name,
                  dados.user.image !== null ? dados.user.url_image : null
                )
              )}
            </div>

            <div className="dados-default">
              <div className="name-default">Por {dados.user.name}</div>
              <div className="date-default">
                {moment(dados.created_at).format("LLL")}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DetailsDoubleHistoric;
