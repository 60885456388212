const INITIAL_STATE = {
  dados: [],
};

function patientSelected(state = INITIAL_STATE, action) {
  if (action.type === "SET_PATIENT_SELECTED") {
    return { ...state, dados: action.dados };
  } else if (action.type === "RESET_PATIENT_SELECTED") {
    state = INITIAL_STATE;
  }

  return state;
}

export default patientSelected;
