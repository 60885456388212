import React from "react"

import BeaconList from './src/components/BeaconList'
import BeaconHistoric from "./src/components/BeaconHistoric"

import "./PainelSensores.scss"

const PainelSensores = () => {
  const [beaconSelected, setBeaconSelected] = React.useState(null)
  const [beaconHistoricShow, setBeaconHistoricShow] = React.useState(false)

  const onBeaconItemSelected = beacon => {
    setBeaconSelected(beacon)
    setBeaconHistoricShow(true)
  }

  return (
    <div className="o-page" data-testid="beacon-panel">
      {!beaconHistoricShow && (
        <BeaconList 
          onBeaconSelect={ onBeaconItemSelected }
        />
      )}
      {beaconHistoricShow && (
        <BeaconHistoric
          handler_historic={ setBeaconHistoricShow }
          show_historic={ beaconHistoricShow }
          beacon={ beaconSelected }
        />
      )}
    </div>
  )
}

export default PainelSensores;
