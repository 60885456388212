import React from "react";
import { Modal } from "react-bootstrap";

import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import "./styles.css";
import { monthAbbToFullDictionary } from "../../../functions/utils";

function ModalIndicatorDetails({ ...props }) {
  const { handler_show_modal, show_modal, details } = props;

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">{details.title}</div>
            <p className="subtitle-report">
              Detalhes do cálculo em {monthAbbToFullDictionary()[details.month]}
            </p>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all">
          <div className="modal-details-values mt-4 d-flex text-center">
            <div className="modal-details-value-with-description">
              <p>{details.firstValue}</p>
              <p>{details.firstDescription}</p>
            </div>
            <div className="modal-details-value-with-description">
              <p>{details.operation}</p>
            </div>
            <div className="modal-details-value-with-description">
              <p>{details.secondValue}</p>
              <p>{details.secondDescription}</p>
            </div>
            <div className="modal-details-value-with-description">
              <p>=</p>
            </div>
            <div className="modal-details-value-with-description">
              <p>{details.resultValue}</p>
              <p>{details.resultDescription}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalIndicatorDetails;
