import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import PwStrengthMeter from "../../../components/PwStrengthMeter";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import "../EsqueceuSenha/styles.css";
import { useParams } from "react-router";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";

function DefinirSenha({ history }) {
  const dispatch = useDispatch();
  let hash = history?.location?.pathname?.split("/")[2];
  const [showPwd, setShowPwd] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [levelPwd, setLevelPwd] = useState("");
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [msgError, setMsgError] = useState("");

  useEffect(() => {
    //Se n tiver hash na url, redireciona pra pagina de login
    if (hash == undefined) history.push({ pathname: "/" });
  });

  function ChangePassword() {
    setMsgError("");
    if (password == "" || passwordConfirm == "") {
      setMsgError("Preencha todos o campos");
      return false;
    }
    if (password != passwordConfirm) {
      setMsgError("As senhas não são iguais");
      return false;
    }

    setLoadingBtn("flex");
    api
      .post(`/user-password/${hash}`, {
        password,
      })
      .then((response) => {
        if (response.status == 204) {
          console.log("ChangePassword success");
          console.log(response.data);

          //Desloga da conta atual
          localStorage.clear();

          dispatch(
            Show_Alert({ type: "success", msg: "Senha alterada com sucesso" })
          );
          history.push({ pathname: "/" });
        }
      })
      .catch((error) => {
        console.log("ChangePassword error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível alterar a senha. Tente novamente mais tarde.",
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  return (
    <Container fluid className="container-esqueceu">
      <div className="box-esqueceu">
        <a href="/" className="pink-link">
          Voltar para página de Login
        </a>

        <h5 className="text-left title mt-3">Definir nova senha</h5>
        <h6 className="text-left sm-title">Preencha uma nova senha.</h6>

        <Form className="w-100">
          <Form.Row>
            <Col xs={12} md={12} className="px-0">
              <Form.Group>
                <Form.Label className="field-label">Nova Senha</Form.Label>
                <div className="fake-input">
                  <Form.Control
                    placeholder="Digite uma nova senha"
                    className="borderless-input"
                    type={showPwd ? "text" : "password"}
                    onChange={(v) => {
                      setMsgError("");
                      setPassword(v.target.value);
                    }}
                  />
                  <div onClick={() => setShowPwd(!showPwd)}>
                    <Icon
                      color={Colors.darker_gray}
                      size={24}
                      icon={showPwd ? "eye-off" : "eye-on"}
                      className="mr-3"
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row className="mb-0">
            <Col xs={12} md={12} className="px-0">
              <Form.Group>
                <Form.Label className="field-label">
                  Confirmar nova senha
                </Form.Label>
                <div className="fake-input">
                  <Form.Control
                    placeholder="Repita a nova senha"
                    className="borderless-input"
                    type={showNewPwd ? "text" : "password"}
                    onChange={(v) => {
                      setMsgError("");
                      setPasswordConfirm(v.target.value);
                    }}
                  />
                  <div onClick={() => setShowNewPwd(!showNewPwd)}>
                    <Icon
                      color={Colors.darker_gray}
                      size={24}
                      icon={showNewPwd ? "eye-off" : "eye-on"}
                      className="mr-3"
                    />
                  </div>
                </div>
                <PwStrengthMeter value={password} handler={setLevelPwd} />
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>

        {msgError != "" && <div className="invalid-msg">{msgError}</div>}

        <button
          disabled={levelPwd < 3}
          className={`default-btn text-center mt-4 ${
            levelPwd < 3 ? "disabled-btn" : ""
          }`}
          onClick={(e) => {
            if (loadingBtn === "flex") {
              e.preventDefault();
            } else {
              ChangePassword();
            }
          }}
        >
          <p
            style={{
              color: levelPwd >= 3 ? "white" : Colors.dark_gray,
              display: loadingBtn == "none" ? "flex" : "none",
              margin: 0,
            }}
          >
            Definir senha
          </p>

          <Spinner
            style={{ display: loadingBtn }}
            animation="border"
            role="status"
            variant="light"
            size="sm"
          />
        </button>
      </div>
    </Container>
  );
}

export default DefinirSenha;
