import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import MainNavbar from "../../../components/Nav/MainNavbar";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import Icon from "../../../functions/Icon";
import IconAchievements from "../../../functions/IconAchievements";
import Colors from "../../../styles/Colors";
import { HexToRgbA, ToggleFullScreen } from "../../../functions/utils";

import MapaLeitos from "./MapaLeitos";
import DuplaChecagem from "./DuplaChecagem";

var fullScreen = false;

function MudancaoDecubito({ history }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userUnit = useSelector((state) => state.userUnit);

  const [aba, setAba] = useState("map");

  return (
    <div>
      <MainNavbar history={history} />

      <Container
        fluid
        className="bg-white"
        style={{ paddingRight: 80, paddingLeft: 80 }}
      >
        <div className="pt-5 container-header-screen d-flex justify-content-between align-items-center">
          <h2 className="p-0">Mudança de decúbito</h2>
          <button
            onClick={() => ToggleFullScreen()}
            style={{
              border: "none",
              background: "#F5F6F9",
              width: 50,
              height: 50,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 6,
            }}
          >
            <Icon color={Colors.darker_gray} size={24} icon="maximize" />
          </button>
        </div>

        <div className="px-2 mt-4">
          <Row className="tabs mt-4">
            <Col xs={6} md={2} lg={2} onClick={() => setAba("map")}>
              <h6
                className={aba == "map" ? "active-text" : ""}
                onClick={() => setAba("map")}
              >
                Mapa de leitos
              </h6>
            </Col>
            <Col xs={6} md={2} lg={2} onClick={() => setAba("double")}>
              <h6
                className={aba == "double" ? "active-text" : ""}
                onClick={() => setAba("double")}
              >
                Dupla checagem
              </h6>
            </Col>
          </Row>

          <Row className="tab-bar">
            <Col
              xs={2}
              md={2}
              lg={2}
              onClick={() => setAba("map")}
              className={aba == "map" ? "active-tab" : ""}
            />
            <Col
              xs={2}
              md={2}
              lg={2}
              onClick={() => setAba("double")}
              className={aba == "double" ? "active-tab" : ""}
            />
            <Col xs={8} md={8} lg={8}></Col>
          </Row>
        </div>

        {aba == "map" && <MapaLeitos history={history} />}

        {aba == "double" && <DuplaChecagem history={history} />}
      </Container>
    </div>
  );
}

export default MudancaoDecubito;
