import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { GetOrderIndexColor } from "../../functions/utils";
import EmptyContainer from "./EmptyContainer";
import { Spinner } from "react-bootstrap";
import DOMPurify from "dompurify";

function AnalysisComorbiditiesPie(props) {
  const { title, loading, comorbiditiesPercentages } = props;

  const [analysisComorbidities, setAnalysisComorbidities] = useState(null);
  const [chartWidth, setChartWidth] = useState("auto");

  useEffect(() => {
    if (analysisComorbidities && analysisComorbidities.labels.length >= 10) {
      setChartWidth(
        Math.max(analysisComorbidities.labels.length * 2, window.innerWidth)
      );
    }
  }, [analysisComorbidities]);

  useEffect(() => {
    if (comorbiditiesPercentages) {
      const labels = [];
      const values = [];
      const colors = [];

      comorbiditiesPercentages.forEach((item, index) => {
        labels.push(item.comorbidity);
        values.push(item.percentage);
        colors.push(GetOrderIndexColor(index));
      });

      const chartObj = {
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: colors,
            borderWidth: 0,
          },
        ],
      };

      setAnalysisComorbidities(chartObj);
    }
  }, [comorbiditiesPercentages]);

  const optionsComorbiditiesPie = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value, index, values) {
              const allowedValues = [0, 20, 40, 60, 80, 100];
              if (allowedValues.includes(value)) {
                return value + "%";
              }
            },
            max: 100,
            stepSize: 20,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.labels[tooltipItem.index] || "";
          let value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `${label}: ${value}%`;
        },
      },
    },
    legend: {
      display: false,
      position: "bottom",
      align: "center",
      fullWidth: true,
      labels: {
        fontSize: 12,
        fontColor: "#666666",
        usePointStyle: true,
        fontFamily: "Rubik-Regular",
        generateLabels: function (chart) {
          const data = chart.data;
          return data.labels.map((label, index) => ({
            text: "  " + label,
            fillStyle: data.datasets[0].backgroundColor[index],
            strokeStyle: data.datasets[0].backgroundColor[index],
            lineWidth: 0,
            hidden:
              isNaN(data.datasets[0].data[index]) ||
              chart.getDatasetMeta(0).data[index].hidden,
            index: index,
          }));
        },
      },
    },
  };

  return (
    <>
      <div className="card-report mt-4">
        {!loading ? (
          comorbiditiesPercentages ? (
            <>
              <p className="risk-patients-title mb-4">{title}</p>
              {comorbiditiesPercentages
                .sort((a, b) => b.percentage - a.percentage)
                .map((item, index) => (
                  <div className="mb-3" key={index}>
                    <div className="analysis-comorbidities-combinations d-flex">
                      <div>
                        <div className="analysis-comorbidities-combinations-percentage">
                          <div
                            style={{
                              transform: "translateY(-1px)",
                              width: 16,
                              height: 16,
                              borderRadius: 14,
                              backgroundColor: GetOrderIndexColor(index),
                              marginLeft: 1,
                              marginRight: 11,
                            }}
                          />
                          <p>
                            {item.percentage.toString().split(".")[0]}
                            {item.percentage.toString().split(".").length ==
                            2 ? (
                              <span>
                                .{item.percentage.toString().split(".")[1]}%
                              </span>
                            ) : (
                              <>%</>
                            )}
                          </p>
                        </div>
                        <p className="analysis-comorbidities-combinations-patients-count">
                          {item.patientCount}{" "}
                          {item.patientCount > 1 ? "pacientes" : "paciente"}
                        </p>
                      </div>
                      <div className="analysis-comorbidities-combinations-description d-flex justify-content-center align-items-center">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.text),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              {analysisComorbidities && (
                <>
                  <div class="chartContainer mt-5">
                    <div style={{ width: chartWidth }}>
                      <Bar
                        height={20}
                        width={
                          analysisComorbidities.labels.length == 1
                            ? 30
                            : analysisComorbidities.labels.length <= 3
                            ? analysisComorbidities.labels.length * 20
                            : analysisComorbidities.labels.length * 5
                        }
                        data={analysisComorbidities}
                        options={optionsComorbiditiesPie}
                      />
                    </div>
                  </div>
                  <div className="container-subtitle mt-4">
                    {analysisComorbidities.datasets[0].data.map(
                      (item, index) => (
                        <div className="subtitle-item mr-4">
                          <div
                            className="indicator-radius"
                            style={{
                              backgroundColor:
                                analysisComorbidities.datasets[0]
                                  .backgroundColor[index],
                            }}
                          />
                          <p>{analysisComorbidities.labels[index]}</p>
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <EmptyContainer
              text={`Ainda não há dados sobre ${
                title.includes("Comorbidades")
                  ? "comorbidades"
                  : "estados situacionais"
              } dos pacientes com lesão por pressão nesse período`}
              iconName="description_1"
            />
          )
        ) : (
          <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </>
  );
}

export default AnalysisComorbiditiesPie;
