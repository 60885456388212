const INITIAL_STATE = {
  reload: false,
};

function updateUnit(state = INITIAL_STATE, action) {
  if (action.type === "UPDATE_UNIT_SET") {
    return { ...state, reload: action.reload };
  } else if (action.type === "UPDATE_UNIT_CLEAR") {
    state = INITIAL_STATE;
  }

  return state;
}

export default updateUnit;
