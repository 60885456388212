import React, { useState } from "react";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import ModalRankingDiagrama from "./ModalRanking";

function IshikawaDiagram(props) {
  const { ishikawaDiagrams } = props;
  const [showModalDiagram, setShowModalDiagram] = useState(false);
  return (
    <div className="ishikawa-diagrams">
      <h4>Ranking Diagrama de Ishikawa</h4>
      <p className="subtitle-report">Causas raízes das lesões por pressão</p>

      {(!ishikawaDiagrams ||
        (ishikawaDiagrams && ishikawaDiagrams.length == 0)) && (
        <div
          className="container-empty h-100 w-100 mt-3"
          style={{
            minHeight: "300px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Icon
              color={Colors.darker_gray}
              size={32}
              icon="ishikawa-diagram"
              className="mb-3"
            />
            <p>
              Ainda não foram registradas as causas raízes das lesões. <br />
              As causas raízes das lesões aparecerão aqui.
            </p>
          </div>
        </div>
      )}

      {ishikawaDiagrams && ishikawaDiagrams.length > 0 && (
        <>
          <div className="mt-4">
            {ishikawaDiagrams.map((item, index) => {
              if (index <= 2) {
                return (
                  <div key={index} className="rr-item">
                    <div className="d-flex align-items-center">
                      <p className="rr-position">{index + 1}°</p>
                      <p className="rr-title">{item.name}</p>
                    </div>
                    <p className="rr-qtd">{item.counter}</p>
                  </div>
                );
              }
            })}
          </div>

          <div className="mt-4 d-flex justify-content-center">
            <button
              className="btn-visu"
              onClick={() => {
                setShowModalDiagram(true);
              }}
            >
              Visualizar lista completa
            </button>
          </div>
        </>
      )}
      {showModalDiagram && (
        <ModalRankingDiagrama
          handler_show_modal={setShowModalDiagram}
          show_modal={showModalDiagram}
          list={ishikawaDiagrams}
          nameModal={{
            title: "Ranking Diagrama de Ishikawa",
            subtitle: "Causas raízes das lesões por pressão",
          }}
        />
      )}
    </div>
  );
}

export default IshikawaDiagram;
