import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";
import ImageMapper from "react-image-mapper";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import {
  GetImc,
  CalculateAge,
  RemovePontos,
  RemoveVirgulas,
  userWithoutPermission,
} from "../../functions/utils";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import "./styles.css";
import ModalWound from "../../components/Modals/ModalWound";
import api from "../../services/api";
import list_wounds from "../../functions/list_wounds";
import { Show_Alert } from "../../store/actions/alert";
import VerifyPwWithUser from "./VerifyPwWithUser";
// import mapping_front from "../../functions/mapping_front";
// import mapping_back from "../../functions/mapping_back";

const colorDefault = "#7FA7E6";
//ORIGINAIS
const mapping_front_original = {
  name: "front",
  areas: [
    {
      id: 5,
      name: "Orelha direita",
      shape: "circle",
      coords: [140, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 6,
      name: "Orelha esquerda",
      shape: "circle",
      coords: [220, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 1,
      name: "Boca",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 8,
      name: "Ombro esquerdo",
      shape: "circle",
      coords: [280, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 9,
      name: "Ombro direito",
      shape: "circle",
      coords: [90, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 10,
      name: "Trocânter esquerdo",
      shape: "circle",
      coords: [250, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 11,
      name: "Trocânter direito",
      shape: "circle",
      coords: [120, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 43,
      name: "Anterior - Joelho esquerdo",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 13,
      name: "Externo - Joelho direito",
      shape: "circle",
      coords: [110, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 14,
      name: "Interno - Maléolo esquerdo",
      shape: "circle",
      coords: [210, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 15,
      name: "Interno - Maléolo direito",
      shape: "circle",
      coords: [160, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 16,
      name: "Hálux esquerdo",
      shape: "circle",
      coords: [230, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 17,
      name: "Hálux direito",
      shape: "circle",
      coords: [140, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 42,
      name: "Anterior - Joelho direito",
      shape: "circle",
      coords: [160, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 30,
      name: "Externo - Joelho esquerdo",
      shape: "circle",
      coords: [260, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

const mapping_back_original = {
  name: "back",
  areas: [
    {
      id: 18,
      name: "Occipital",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 20,
      name: "Escápula direita",
      shape: "circle",
      coords: [220, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 19,
      name: "Escápula esquerda",
      shape: "circle",
      coords: [140, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 21,
      name: "Coluna",
      shape: "circle",
      coords: [180, 280, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 23,
      name: "Cotovelo direito",
      shape: "circle",
      coords: [300, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 22,
      name: "Cotovelo esquerdo",
      shape: "circle",
      coords: [70, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 24,
      name: "Sacral",
      shape: "circle",
      coords: [180, 350, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 26,
      name: "Ísquio direito",
      shape: "circle",
      coords: [210, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 25,
      name: "Ísquio esquerdo",
      shape: "circle",
      coords: [150, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 28,
      name: "Calcâneo direito",
      shape: "circle",
      coords: [210, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 27,
      name: "Calcâneo esquerdo",
      shape: "circle",
      coords: [160, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 40,
      name: "Glúteo direito",
      shape: "circle",
      coords: [220, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 41,
      name: "Glúteo esquerdo",
      shape: "circle",
      coords: [140, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 36,
      name: "Interno - Joelho direito",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 39,
      name: "Interno - Joelho esquerdo",
      shape: "circle",
      coords: [155, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 37,
      name: "Externo - Maléolo esquerdo",
      shape: "circle",
      coords: [135, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 38,
      name: "Externo - Maléolo direito",
      shape: "circle",
      coords: [240, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

//USADO NA TELA
const mapping_front = {
  name: "front",
  areas: [
    {
      id: 5,
      name: "Orelha direita",
      shape: "circle",
      coords: [140, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 6,
      name: "Orelha esquerda",
      shape: "circle",
      coords: [220, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 1,
      name: "Boca",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 8,
      name: "Ombro esquerdo",
      shape: "circle",
      coords: [280, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 9,
      name: "Ombro direito",
      shape: "circle",
      coords: [90, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 10,
      name: "Trocânter esquerdo",
      shape: "circle",
      coords: [250, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 11,
      name: "Trocânter direito",
      shape: "circle",
      coords: [120, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 12,
      name: "Joelho esquerdo",
      shape: "circle",
      coords: [220, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 13,
      name: "Joelho direito",
      shape: "circle",
      coords: [150, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 14,
      name: "Interno - Maléolo esquerdo",
      shape: "circle",
      coords: [210, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 15,
      name: "Interno - Maléolo direito",
      shape: "circle",
      coords: [160, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 16,
      name: "Hálux esquerdo",
      shape: "circle",
      coords: [230, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 17,
      name: "Hálux direito",
      shape: "circle",
      coords: [140, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 42,
      name: "Anterior - Joelho direito",
      shape: "circle",
      coords: [160, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 30,
      name: "Externo - Joelho esquerdo",
      shape: "circle",
      coords: [260, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

const mapping_back = {
  name: "back",
  areas: [
    {
      id: 18,
      name: "Occipital",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 20,
      name: "Escápula direita",
      shape: "circle",
      coords: [260, 200, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 19,
      name: "Escápula esquerda",
      shape: "circle",
      coords: [100, 200, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 21,
      name: "Coluna",
      shape: "circle",
      coords: [180, 280, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 23,
      name: "Cotovelo direito",
      shape: "circle",
      coords: [300, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 22,
      name: "Cotovelo esquerdo",
      shape: "circle",
      coords: [70, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 24,
      name: "Sacral",
      shape: "circle",
      coords: [180, 350, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 26,
      name: "Ísquio direito",
      shape: "circle",
      coords: [220, 420, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 25,
      name: "Ísquio esquerdo",
      shape: "circle",
      coords: [140, 420, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 28,
      name: "Calcâneo direito",
      shape: "circle",
      coords: [210, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 27,
      name: "Calcâneo esquerdo",
      shape: "circle",
      coords: [160, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

var wound = null;
var wound_id_edit = 0;
var wound_edit = [];

function ModalAddWound(props) {
  const {
    handler_show_wound_add,
    show_wound_add,
    woundsPatient,
    handler_reload,
    reload,
    default_wound_state,
  } = props;
  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [showModalWound, setShowModalWound] = useState(false);

  const [listWounds, setListWounds] = useState([]);

  const [front, setFront] = useState(mapping_front);
  const [back, setBack] = useState(mapping_back);
  const [hoveredArea, setHoveredArea] = useState(null);
  const [hoveredAreaBack, setHoveredAreaBack] = useState(null);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [listAddWounds, setListAddWounds] = useState([]);

  const [showOtherWound, setShowOtherWound] = useState(false);
  const [otherWound, setOtherWound] = useState("");

  const [showPw, setShowPw] = useState(false);

  function getTipPosition(area) {
    return { top: `${area.center[1] - 30}px`, left: `${area.center[0]}px` };
  }

  useEffect(() => {
    function ResetColorBall() {
      mapping_front.areas.map((item) => {
        item.preFillColor = "rgba(127,167,230, 0.7)";
      });

      mapping_back.areas.map((item) => {
        item.preFillColor = "rgba(127,167,230, 0.7)";
      });
    }

    ResetColorBall();
  }, []);

  function renderModalOtherWound() {
    return (
      <Modal
        show={showOtherWound}
        onHide={() => {
          setShowOtherWound(false);
          setOtherWound("");
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-pw"
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <div className="modal-title">Adicionar local</div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => {
                  setShowOtherWound(false);
                  setOtherWound("");
                }}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            <Form.Label className="field-label">Local</Form.Label>
            <input
              className="default-input"
              type="text"
              placeholder="Ex.: Dedo anelar direito"
              value={otherWound}
              onChange={(e) => setOtherWound(e.target.value)}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={otherWound == "" ? true : false}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  otherWound == "" ? Colors.lighter_gray : Colors.brand_pink,
              }}
              onClick={() => {
                wound_id_edit = 0;
                setShowOtherWound(false);
                setShowModalWound(true);
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: otherWound == "" ? Colors.darker_gray : "white",
                }}
              >
                Continuar
              </p>
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    function FilterWounds() {
      //Filtra as lesoes já cadastradas (tanto nas bolas do boneco quanto no select do modal local)
      var filterFront = [];
      var filterBack = [];

      mapping_front_original.areas.map((item) => {
        var found = woundsPatient.find((i) => i.wound_id == item.id);
        if (found == undefined) {
          filterFront.push(item);
        }
      });

      mapping_back_original.areas.map((item) => {
        var found = woundsPatient.find((i) => i.wound_id == item.id);
        if (found == undefined) {
          filterBack.push(item);
        }
      });

      //List wounds select
      var listSelect = [];
      list_wounds.map((item) => {
        var found = woundsPatient.find((i) => i.wound_id == item.value);
        if (found == undefined) {
          listSelect.push(item);
        }
      });

      setListWounds(listSelect);
      mapping_front.areas = filterFront;
      mapping_back.areas = filterBack;
      setFront(mapping_front);
      setBack(mapping_back);
    }

    FilterWounds();
  }, []);

  useEffect(() => {
    function AddColor() {
      //Filtra das que acabaram de ser adicionadas
      var filterFront = [];
      var filterBack = [];

      mapping_front.areas.map((item) => {
        var found = listAddWounds.find((i) => i.wound_id == item.id);
        if (found != undefined) {
          item.preFillColor = Colors.brand_red;
        }
        filterFront.push(item);
      });

      mapping_back.areas.map((item) => {
        var found = listAddWounds.find((i) => i.wound_id == item.id);
        if (found != undefined) {
          item.preFillColor = Colors.brand_red;
        }
        filterBack.push(item);
      });

      //List wounds select
      var listSelect = [];
      list_wounds.map((item) => {
        var found = listAddWounds.find((i) => i.wound_id == item.value);
        if (found == undefined) {
          listSelect.push(item);
        }
      });

      setListWounds(listSelect);
      mapping_front.areas = filterFront;
      mapping_back.areas = filterBack;
      setFront(mapping_front);
      console.log(front, "AAAAAAAAAAAAA");
      setBack(mapping_back);
    }

    if (listAddWounds.length > 0) {
      AddColor();
    }
  }, [listAddWounds]);

  function mainImgWasPressed(item) {
    var found = listAddWounds.find((i) => i.wound_id == item.id);
    if (found == undefined) {
      wound_id_edit = 0;
      wound_edit = undefined;
      wound = item;
      setShowModalWound(true);
    } else {
      wound_id_edit = item.id;
      wound_edit = found;
      setShowModalWound(true);
    }
  }

  function RegisterWound(userId = null) {
    setLoadingBtn("flex");

    listAddWounds.map((wound, index) => {
      //Descriptions
      var arrayDescriptions = [];
      wound.descriptions.map((item) => {
        var obj = {};
        obj.description_ids = item.description_ids
          ? item.description_ids
          : null;
        obj.type_description_id = item.type_description_id;
        obj.description_id = item.description_id;
        obj.value = item.value;
        arrayDescriptions.push(obj);
      });

      /* Add nova seção quando clica no btn "OUTROS" */
      if (wound.type_description_name != "" && wound.value > 0) {
        arrayDescriptions.push({
          type_description_name: wound.type_description_name,
          value: wound.value,
        });
      }

      api
        .post(`/patients/${patientSelected.dados.id}/wounds`, {
          medical_devices: wound.medical_devices,
          type: wound.type,
          wound_name: wound.wound_name,
          wound_id: wound.is_other == true ? 0 : wound.wound_id,
          stage_id: wound.stage_id,
          note: wound.note,
          wound_type_note: wound.wound_type_note,
          user_id: userId ? userId : wound.user.value,
          height: wound.height != "" ? wound.height.replace(",", ".") : "",
          width: wound.width != "" ? wound.width.replace(",", ".") : "",
          depth: wound.depth != "" ? wound.depth.replace(",", ".") : "",
          descriptions: arrayDescriptions,
        })
        .then(async (response) => {
          if (response.status == 200) {
            console.log("RegisterWound success ");
            console.log(response.data);
            let last = false;
            if (listAddWounds.length - 1 == index) {
              last = true;
            }
            handler_show_wound_add(false);
            dispatch(
              Show_Alert({
                type: "success",
                msg:
                  listAddWounds.length == 1
                    ? "Lesão notificada com sucesso"
                    : "Lesões notificadas com sucesso",
              })
            );
            await SaveImages(response.data.id, wound.images, last);
            handler_reload(reload == true ? false : true);
          }
        })
        .catch((error) => {
          handler_show_wound_add(false);
          console.log("RegisterWound error " + error);
          Show_Alert({
            type: "error",
            msg: "Não foi possível cadastrar essa lesão.",
          });
          setLoadingBtn("none");
        });
    });
  }

  async function SaveImages(idPatientWound, images, last) {
    if (images.length == 0) {
      return false;
    }

    const formData = new FormData();
    images.forEach((item) => {
      formData.append("image[]", item.file);
      formData.append("filename", item.fileName);
    });

    try {
      const response = await api.post(
        `/patients/${patientSelected.dados.id}/wounds/${idPatientWound}/images`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData.boundary}`,
          },
        }
      );

      if (response.status === 200 && last) {
        console.log("SaveImages success");
        console.log(response.data);
        dispatch(
          Show_Alert({
            type: "success",
            msg:
              listAddWounds.length == 1
                ? "Lesão notificada com sucesso"
                : "Lesões notificadas com sucesso",
          })
        );
        handler_show_wound_add(false);
      }
    } catch (error) {
      console.log("SaveImages error " + error);
      handler_show_wound_add(false);
      Show_Alert({
        type: "error",
        msg: "Não foi possível salvar as novas imagens da lesão",
      });
    } finally {
      setLoadingBtn("none");
    }
  }

  return (
    <Modal
      show={show_wound_add}
      onHide={() => {
        handler_show_wound_add(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-filter modal-pw"
    >
      {showModalWound && (
        <ModalWound
          handler_show_wound={setShowModalWound}
          show_wound={showModalWound}
          list_wounds={listWounds}
          wound_add={wound}
          handler_list_add={setListAddWounds}
          list_add={listAddWounds}
          wound_id_edit={wound_id_edit}
          handler_other_wound={setOtherWound}
          other_wound={otherWound}
          wound_edit={wound_edit}
        />
      )}

      {showOtherWound && renderModalOtherWound()}

      <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Col xs={6} md={2} lg={6}>
            <div className="modal-title">
              {!default_wound_state ? "Notificar" : "Notificar"} lesão por
              pressão
            </div>
          </Col>
          <Col xs={2} md={2} lg={2} className="pr-0 d-flex justify-content-end">
            <div
              className="modal-close-btn"
              onClick={() => {
                handler_show_wound_add(false);
              }}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </Col>
        </Row>

        <div className="d-flex flex-wrap mt-4">
          <div>
            <p className="wound-modal-title">Selecione o local da lesão</p>

            {front.name != undefined && back.name != undefined && (
              <div className="mb-5 mt-2">
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    <ImageMapper
                      src={require(`../../assets/img/model-human/${
                        patientSelected.dados.gender == "F" ? "female" : "men"
                      }-front.jpg`)}
                      width={164}
                      imgWidth={367}
                      map={front}
                      onClick={(item) => mainImgWasPressed(item)}
                      onMouseEnter={(area) => setHoveredArea(area)}
                      onMouseLeave={(area) => setHoveredArea(null)}
                    />
                    {hoveredArea != null && (
                      <span
                        className="tooltip-model"
                        style={{ ...getTipPosition(hoveredArea) }}
                      >
                        {hoveredArea.name}
                      </span>
                    )}
                  </Col>

                  <Col xs={6} md={6} lg={6}>
                    <ImageMapper
                      src={require(`../../assets/img/model-human/${
                        patientSelected.dados.gender == "F" ? "female" : "men"
                      }-back.jpg`)}
                      width={164}
                      imgWidth={367}
                      map={back}
                      onClick={(item) => mainImgWasPressed(item)}
                      onMouseEnter={(area) => setHoveredAreaBack(area)}
                      onMouseLeave={(area) => setHoveredAreaBack(null)}
                    />
                    {hoveredAreaBack != null && (
                      <span
                        className="tooltip-model"
                        style={{ ...getTipPosition(hoveredAreaBack) }}
                      >
                        {hoveredAreaBack.name}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            )}

            <div
              className="d-flex justify-content-center align-items-center container-other-wound"
              style={{ maxWidth: 326 }}
              onClick={() => {
                setShowOtherWound(showOtherWound == true ? false : true);
              }}
            >
              <Icon
                color={Colors.brand_pink}
                size={18}
                icon={"plus"}
                className="mr-2"
              />
              <p>Adicionar outro local</p>
            </div>
          </div>

          <div className="container-wound-add">
            <p className="wound-modal-title">Lesões adicionadas</p>

            {listAddWounds.length == 0 && (
              <div className="container-error-default">
                <p>Nenhuma lesão adicionada</p>
              </div>
            )}

            {listAddWounds.map((item, index) => {
              return (
                <div
                  key={index}
                  className="card-new-wound mt-3 position-relative"
                  onClick={() => {
                    wound_id_edit = item.wound_id;
                    wound_edit = item;
                    setShowModalWound(true);
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div
                      className="ball-wound mr-2"
                      style={{ backgroundColor: Colors.brand_red }}
                    />
                    <p className="name-wound-add">{item.wound_name}</p>
                  </div>

                  <p className="stage-wound-add mt-2">
                    {item.stage_name} {item.stage_name && "-"} {""}
                    {item.type == "incidence" ? "Incidência" : "Admissão"}
                  </p>

                  <div className="btn-wound-edit">
                    <Icon color={Colors.darkest_gray} size={18} icon={"edit"} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="row justify-content-end align-items-center pb-0">
        <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
          <button
            className="modal-footer-btn mr-0 btn-cancel"
            onClick={() => {
              handler_show_wound_add(false);
            }}
          >
            Cancelar
          </button>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={
              (listAddWounds.length > 0 ? false : true) || loadingBtn === "flex"
            }
            className="mb-0 modal-footer-btn btn-confirm"
            style={{
              backgroundColor:
                listAddWounds.length > 0
                  ? Colors.brand_pink
                  : Colors.lighter_gray,
            }}
            onClick={() => {
              if (userWithoutPermission(user)) {
                setShowPw(true);
              } else {
                RegisterWound();
              }
            }}
          >
            <p
              className="mb-0"
              style={{
                color:
                  listAddWounds.length > 0 ? Colors.white : Colors.dark_gray,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              Adicionar
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
      {showPw && (
        <VerifyPwWithUser
          handler_show_pw={setShowPw}
          show_pw={showPw}
          modal_name={"Autenticar notificação"}
          handler_request={RegisterWound}
        />
      )}
    </Modal>
  );
}

export default ModalAddWound;
