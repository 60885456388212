import React from "react";
import { Row, Col } from "react-bootstrap";

import "./styles.css";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";

function Breadcrumb({ history, ...props }) {
  const { list_breadcrumb } = props;
  return (
    <div className="d-flex container-breadcrumb">
      {list_breadcrumb.map((item, index) => {
        var last = index == list_breadcrumb.length - 1 ? true : false;
        return (
          <div key={index} className="breadcrumb-item-edit">
            {!last && (
              <a
                href={`/${item.url}`}
                style={{ color: last ? Colors.darker_gray : Colors.dark_gray }}
              >
                {item.name}
              </a>
            )}
            {last && <p>{item.name}</p>}
            {!last && (
              <Icon
                color="#666666"
                size={18}
                icon="arrow-left"
                className="mx-2 breadcrumb-icon"
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Breadcrumb;
