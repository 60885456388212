import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "../../styles/global.css";
import Icon from "../../functions/Icon";

function LastMonthsPeriodDropdown({ period, periodHandler }) {
  const [show, setShow] = useState(false);

  return (
    <Dropdown
      className="drop-sort dropdown-hide-icon"
      show={show}
      onToggle={() => setShow(!show)}
      style={{
        padding: "9px!important",
        fontSize: "16px!important",
      }}
    >
      <Dropdown.Toggle id="dropdown-sort" className={`dropdown-clear`}>
        <span>Últimos {period} meses</span>
        <span>
          <Icon icon="arrow-select" size={20} />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        rootCloseEvent={"click"}
        className="div-drop-sort dropdown-period-adherence"
        style={{
          borderRadius: "10px",
        }}
      >
        <div>
          {[6, 12].map((i) => {
            return (
              <p
                className="adherence-period"
                onClick={() => {
                  periodHandler(i);
                  setShow(false);
                }}
              >
                Últimos {i} meses
              </p>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LastMonthsPeriodDropdown;
