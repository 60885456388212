import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";
import ImageMapper from "react-image-mapper";

// import NewWoundBlock from "../../../../../components/Modals/NewWoundBlock";
import WoundEditConfirm from "../../../../../components/Modals/WoundEditConfirm";
import ModalAddWound from "../../../../../components/Modals/ModalAddWound";
import VerifyPw from "../../../../../components/Modals/VerifyPw";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import {
  GetImc,
  CalculateAge,
  userHasPermission,
  userHasGeneralPermissions,
} from "../../../../../functions/utils";
import moment from "moment";
import "./styles.css";

import mapping_front from "../../../../../functions/mapping_front";
import mapping_back from "../../../../../functions/mapping_back";
import { renderPhoto } from "../../../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../../../Home/Notificacoes/Notificacoes";

var woundEditConfirm = null;
var isConfirm = false;
var isConfirmed = false;
var isEvolution = false;

function Lesoes({ ...props }) {
  const { withWound, openConfirmed, history } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const woundState = useSelector((state) => state.defaultWoundState);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [front, setFront] = useState({});
  const [back, setBack] = useState({});
  const [status, setStatus] = useState(1); //1 notificadas, 0 confirmadas
  const [hoveredArea, setHoveredArea] = useState(null);
  const [hoveredAreaBack, setHoveredAreaBack] = useState(null);
  const [showWound, setShowWound] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [defaultWoundState, setDefaultWoundState] = useState(null);
  const [isFirstPatientLoad, setIsFirstPatientLoad] = useState(false);

  const [patient, setPatient] = useState({});
  const [wounds, setWounds] = useState([]);

  //Todas as lesoes do paciente
  const [listWounds, setListWounds] = useState([]);

  // const [block, setBlock] = useState(false);
  // const [showModalBlock, setShowModalBlock] = useState(false);

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //Veririca se tem prop withWound
    //Se sim é pq um paciente acabou de ser cadastrado com lesão
    //Então abre o modal de add lesão automaticamente
    if (withWound == true && !showModalAdd) {
      setShowModalAdd(true);
    }
  }, [withWound]);

  useEffect(() => {
    function loadHospitalWoundState() {
      api
        .get("default-settings/hospitals/wound-state")
        .then((response) => {
          if (response.status == 200) {
            // console.log("LoadHospital sucess");
            setDefaultWoundState(response.data.is_enable == 1 ? true : false);
            if (response.data.is_enable) {
              setStatus(1);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setDefaultWoundState(false);
          } else {
            console.log("LoadHospital error " + error);
            dispatch(
              Show_Alert({
                type: "error",
                msg: "Não foi possível carregar as informações do hospital",
              })
            );
          }
        })
        .finally(() => {
          //setLoading(false);
        });
    }
    loadHospitalWoundState();
  }, []);

  function getTipPosition(area) {
    return { top: `${area.center[1] - 30}px`, left: `${area.center[0]}px` };
  }

  function mainImgWasPressed(item) {
    var found = wounds.find((i) => i.wound_id == item.id);
    if (found != undefined) {
      woundEditConfirm = found;
      isConfirm = false;
      isConfirmed = status == 1 ? false : true;

      setShowWound(true);
    }
  }

  useEffect(() => {
    function LoadPatient() {
      api
        .get(`/patients/${patientSelected.dados.id}`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPatient success");
            console.log(response.data);

            setPatient(response.data);

            setListWounds(response.data.wounds);

            var list = response.data.wounds;

            FilterWounds(1, list);
            FilterWounds(2, list);

            if (!defaultWoundState && isFirstPatientLoad) {
              FilterWoundsInitial(0, list);
            }

            setWounds(list);
          }
        })
        .catch((error) => {
          console.log("LoadPatient Error" + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as lesoes desse paciente.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    if (defaultWoundState !== null) {
      LoadPatient();
      setIsFirstPatientLoad(true);
    }
  }, [defaultWoundState, reload, openConfirmed]);

  function FilterWounds(value, list) {
    ChangeColorBall(value, list);
    setWounds(list);
    setStatus(value);
  }

  function FilterWoundsInitial(value, listWounds) {
    var list = null;
    if (value == 1) {
      var list = listWounds.filter((item) => item.verify_nurse == null);
    } else {
      var list = listWounds.filter((item) => item.verify_nurse != null);
    }

    ChangeColorBall(value, list);
    setWounds(list);
    setStatus(value);
  }

  function ChangeColorBall(value, list) {
    ResetColorBall();

    //Troca cor pra blue (Notificadas) ou red (Confirmadas)
    list.map((item) => {
      mapping_front.areas.map((i) => {
        if (i.id == item.wound_id) {
          i.preFillColor =
            item.verify_nurse == null ? Colors.brand_blue : Colors.brand_red;
        }
        return i;
      });
      mapping_back.areas.map((i) => {
        if (i.id == item.wound_id) {
          i.preFillColor =
            item.verify_nurse == null ? Colors.brand_blue : Colors.brand_red;
        }
        return i;
      });
    });

    setFront(mapping_front);
    setBack(mapping_back);
  }

  function ResetColorBall() {
    mapping_front.areas.map((item) => {
      item.preFillColor = "rgba(127,167,230, 0.7)";
    });

    mapping_back.areas.map((item) => {
      item.preFillColor = "rgba(127,167,230, 0.7)";
    });
  }

  function renderUserImg(obj) {
    var url = require("../../../../../assets/img/icon_user.png");
    if (obj.image != null) {
      url = obj.url_image;
    }

    return (
      <img
        alt={obj.name}
        title={obj.name}
        style={{ width: 40, height: 40, borderRadius: "100%" }}
        src={url}
      />
    );
  }

  return (
    <div>
      {showWound && (
        <WoundEditConfirm
          show_wound={showWound}
          handler_show_wound={setShowWound}
          handler_reload={setReload}
          reload={reload}
          wound_edit_confirm={woundEditConfirm}
          is_confirm={isConfirm}
          is_confirmed={isConfirmed}
          is_evolution={isEvolution}
          wounds={wounds}
        />
      )}

      {showModalAdd && (
        <ModalAddWound
          handler_show_wound_add={setShowModalAdd}
          show_wound_add={showModalAdd}
          woundsPatient={listWounds}
          handler_reload={setReload}
          reload={reload}
          default_wound_state={defaultWoundState}
        />
      )}
      {/* {showModalBlock && (
        <NewWoundBlock
          handler_modal={setShowModalBlock}
          show_modal={showModalBlock}
        />
      )} */}
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h2 className="screen-name">Lesões</h2>
        <button
          onClick={() => {
            //Se tiver lesão notificada sem confirmar
            //Não deixa abrir o modal de nova lesão
            // if (block) {
            //   setShowModalBlock(true);
            //   return false;
            // }

            setShowModalAdd(true);
          }}
          style={{ width: "fit-content" }}
          className="default-btn"
        >
          Nova lesão
        </button>
      </div>

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      <Row>
        {!loading && listWounds.length == 0 && wounds.length == 0 && (
          <div className="container-error-default">
            <p>Não foram confirmadas lesões por pressão para esse paciente.</p>
          </div>
        )}

        {!loading &&
          front.name != undefined &&
          back.name != undefined &&
          wounds.length > 0 &&
          showModalAdd == false && (
            <>
              <div className="d-flex">
                <div className="mr-4 container-model">
                  <ImageMapper
                    src={require(`../../../../../assets/img/model-human/${
                      patient.gender == "F" ? "female" : "men"
                    }-front.jpg`)}
                    width={164}
                    imgWidth={367}
                    map={front}
                    onClick={(area) => mainImgWasPressed(area)}
                    onMouseEnter={(area) => setHoveredArea(area)}
                    onMouseLeave={(area) => setHoveredArea(null)}
                  />
                  {hoveredArea != null && (
                    <span
                      className="tooltip-model"
                      style={{ ...getTipPosition(hoveredArea) }}
                    >
                      {hoveredArea.name}
                    </span>
                  )}
                </div>

                <div className="mr-5 container-model">
                  <ImageMapper
                    src={require(`../../../../../assets/img/model-human/${
                      patient.gender == "F" ? "female" : "men"
                    }-back.jpg`)}
                    width={164}
                    imgWidth={367}
                    map={back}
                    onClick={(area) => mainImgWasPressed(area)}
                    onMouseEnter={(area) => setHoveredAreaBack(area)}
                    onMouseLeave={(area) => setHoveredAreaBack(null)}
                  />
                  {hoveredAreaBack != null && (
                    <span
                      className="tooltip-model"
                      style={{ ...getTipPosition(hoveredAreaBack) }}
                    >
                      {hoveredAreaBack.name}
                    </span>
                  )}
                </div>
              </div>

              <div className="container-wounds mt-4 mt-md-0">
                {wounds.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{ width: 420, cursor: "pointer" }}
                      className="my-2"
                      onClick={() => {
                        dispatch({
                          type: "SET_EVOLUTION_WOUND_SELECTED",
                          dados: { wound: item },
                        });
                        history.push({
                          pathname:
                            "/Pacientes/Perfil-Paciente/Lesoes/Evolucao",
                        });
                      }}
                    >
                      <div
                        className={
                          item.verify_nurse != null
                            ? "main-card position-relative main-card-notif"
                            : "main-card position-relative"
                        }
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="card-name mb-1 d-flex">
                              <div
                                className="ball-wound mr-2"
                                style={{
                                  background:
                                    item.verify_nurse == null
                                      ? Colors.brand_blue
                                      : Colors.brand_red,
                                }}
                              />{" "}
                              {item.wound.position != null &&
                                (item.wound.position == "front"
                                  ? "Anterior - "
                                  : item.wound.position === "external"
                                  ? "Externo - "
                                  : item.wound.position === "internal"
                                  ? "Interno - "
                                  : item.wound.position === null
                                  ? ""
                                  : "Posterior - ")}
                              {item.wound.name}
                            </div>

                            <div className="card-quarto">
                              {" "}
                              {item.stage?.name}
                              {item.stage && " - "}
                              {item.type == "incidence"
                                ? "Incidência"
                                : "Admissão"}
                            </div>

                            {item.verify_nurse == null && (
                              <div className="card-type tag-confirmed-lesion tag-notification-lesion">
                                <p>Notificada</p>
                              </div>
                            )}
                            {item.verify_nurse != null && (
                              <div className="card-type tag-confirmed-lesion">
                                <p>Confirmada</p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div
                          className="mt-3"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <div className="c-profile-dropdown mr-2">
                            {renderPhoto(
                              makeUserDataToPhoto(
                                item.user.name,
                                item.user.image !== null
                                  ? item.user.url_image
                                  : null
                              )
                            )}
                          </div>

                          <div className="dados-default">
                            <div className="name-default">
                              Por {item.user.name}
                            </div>
                            <div className="date-default">
                              {moment(item.created_at).format("LLL")}
                            </div>
                          </div>

                          {item.verify_nurse != null &&
                            userHasGeneralPermissions(user) && (
                              <div style={{ marginLeft: 80 }}>
                                <button
                                  className="secondary-btn btn-next-lesion"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    isConfirm = false;
                                    isConfirmed = false;
                                    isEvolution = true;
                                    woundEditConfirm = item;
                                    setShowWound(true);
                                  }}
                                >
                                  <Icon
                                    color={Colors.brand_pink}
                                    size={20}
                                    icon="line-chart"
                                  />
                                  <p
                                    className="mb-0 ml-2"
                                    style={{
                                      color: Colors.brand_pink,
                                    }}
                                  >
                                    Evoluir
                                  </p>
                                </button>
                              </div>
                            )}

                          {userHasGeneralPermissions(user) &&
                            user.dados.id != item.user.id &&
                            item.verify_nurse == null &&
                            defaultWoundState && (
                              <div style={{ marginLeft: 80 }}>
                                <button
                                  className="secondary-btn btn-next-lesion btn-confirmed-lesion"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    isConfirm = true;
                                    isConfirmed = false;
                                    isEvolution = false;
                                    woundEditConfirm = item;
                                    setShowWound(true);
                                  }}
                                >
                                  <Icon
                                    color={Colors.brand_pink}
                                    size={20}
                                    icon="check-square"
                                  />
                                  <p
                                    className="mb-0 ml-2"
                                    style={{
                                      color: Colors.brand_pink,
                                    }}
                                  >
                                    Confirmar
                                  </p>
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                      {item.verify_nurse != null && (
                        <div className="card-bottom-confirmed d-flex align-items-center">
                          <Icon
                            color={Colors.brand_green}
                            size={18}
                            icon={"check"}
                            className="mr-2"
                          />
                          <p className="mb-0">
                            Confirmada por {item.verify_nurse.name}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
      </Row>
    </div>
  );
}

export default Lesoes;
