import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import Sound from "react-sound";
import Ws from "@adonisjs/websocket-client";

import "./styles.css";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import { HexToRgbA } from "../../../functions/utils";
import ModalDecubito from "../Home/ModalDecubito";
import moment from "moment";

import soundFile from "../../../assets/sound/alert_sound.mp3";
var last_date = null;
var listNotifications2 = [];
var timeSound = null;
const typeAdd = "minutes";

function MapaLeitos() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const unit = useSelector((state) => state.unit);

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [dados, setDados] = useState({});

  const [listNotifications, setListNotifications] = useState([]);
  const [play, setPlay] = useState(false);
  // const [timeSound, setTimeSound] = useState(null);
  const [verifySound, setVerifySound] = useState(false);

  useEffect(() => {
    function WebSocketInit() {
      //Web Socket Adonis
      const ws = Ws(`${process.env.REACT_APP_WEB_SOCKET_ENDPOINT}`, {
        path: "ws",
        reconnection: false,
      });
      ws.connect();

      const topic = ws.subscribe("websocket");
      topic.on("ready", () => {
        topic.on("notification", (response) => {
          console.log("[ws ml] New decubito");
          var obj = response.data;
          console.log(obj);
          if (obj.unit_id != unit.selecionado.id) return false;

          //Remove card
          if (obj.tag == "forget") {
            var newList = listNotifications2.filter(
              (item) => item.notification_id != obj.notification_id
            );
            console.log("newList filter");
            console.log(newList);
            setListNotifications(newList);
            listNotifications2 = newList;
            return false;
          }

          var newList = listNotifications2.map((item) => {
            if (item.room_name == obj.room) {
              if (item.subrooms == undefined) {
                item.notification = obj;
                item.patient_name = obj.name;
              } else {
                item.subrooms.map((i) => {
                  if (i.room_name == obj.subroom) {
                    i.notification = obj;
                    i.patient_name = obj.name;
                  }
                });
              }
            }
            return item;
          });
          console.log("newList");
          console.log(newList);
          setListNotifications(newList);
          listNotifications2 = newList;
          if (obj.sound) {
            setPlay(true);
            last_date = moment().add(timeSound, typeAdd);
          }
        });
      });

      ws.on("open", () => {
        console.log("ws connect ml");
      });

      ws.on("close", () => {
        console.log("ws disconnect ml");
        setTimeout(() => {
          WebSocketInit();
        }, 9000);
      });
    }

    WebSocketInit();
  }, [unit]);

  // useEffect(() => {
  //   console.log("HERE");
  //   if (play && timeSound != null) {
  //     last_date = moment().add(timeSound, "minutes");
  //   }
  // }, [play, timeSound]);

  // console.log("play " + play);

  useEffect(() => {
    function LoadTimeSound() {
      api
        .get(`/hospitals/${user.dados.hospital_id}`)
        .then((response) => {
          if (response.status == 200) {
            // setTimeSound(response.data.time_sound);
            timeSound = response.data.time_sound;
          }
        })
        .catch((error) => {
          console.log("LoadTimeSound error " + error);
        });
    }

    LoadTimeSound();
  }, []);

  useEffect(() => {
    /*
      Compara se a ultima data salva com os minutos 
      do hospital já adicionados, é menor qua a data atual
    */
    // console.log("LAST_DATE " + last_date);
    if (last_date != null) {
      if (last_date.isBefore(moment())) {
        console.log("ENTROU NO IF");
        PlaySound();
      }
    }

    setTimeout(() => {
      setVerifySound(!verifySound);
    }, 1);
  }, [verifySound]);

  function PlaySound() {
    console.log("PLAY SOUND");
    console.log(listNotifications2);
    last_date = null;
    listNotifications2.map((item) => {
      if (item.notification != undefined) {
        if (item.notification.sound && !play) {
          console.log("timeSound " + timeSound);
          last_date = moment().add(timeSound, typeAdd);
          setPlay(true);
        }
      } else {
        item.subrooms.map((i) => {
          if (i.notification != undefined) {
            if (i.notification.sound && !play) {
              console.log("timeSound " + timeSound);
              last_date = moment().add(timeSound, typeAdd);
              setPlay(true);
            }
          }
        });
      }
    });
  }

  // useEffect(() => {
  //   if (play) {
  //     console.log("HEREEEEE");
  //     console.log("play " + play);
  //     console.log("TIMESOUND " + timeSound);
  //     last_date = moment().add(timeSound, "seconds");
  //   }
  // }, [play]);

  useEffect(() => {
    if (unit.selecionado?.id != undefined) {
      LoadNotifications();
    } else {
      setLoading(false);
    }
  }, [unit, reload]);

  function LoadNotifications() {
    api
      .get(`/notifications/map`, {
        params: {
          unit_id: unit.selecionado.id,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("LoadNotifications success");
          console.log(response.data);
          setListNotifications(response.data);
          listNotifications2 = response.data;
          PlaySound();

          // if (last_date != null) {
          //   /*
          //     Compara se a ultima data salva com os minutos do hospital já adicionados, é menor qua a data atual
          //     Se não for menor ainda, não deixa verificar se tem sound: true no retorno pra tocar o som
          //     */
          //   if (!last_date.isBefore(moment())) return false;
          // }

          // response.data.map((item) => {
          //   if (item.notification != undefined) {
          //     if (item.notification.sound && !play) setPlay(true);
          //   } else {
          //     item.subrooms.map((i) => {
          //       if (i.notification != undefined) {
          //         if (i.notification.sound && !play) setPlay(true);
          //       }
          //     });
          //   }
          // });
        }
      })
      .catch((error) => {
        console.log("LoadNotifications error " + error);

        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível carregar a lista de mapa de leitos",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="pb-5">
      <Sound
        url={soundFile}
        playStatus={play ? "PLAYING" : "STOPPED"}
        onFinishedPlaying={() => setPlay(false)}
        volume={100}
      />

      {showModal && (
        <ModalDecubito
          data={dados}
          handler_show_modal={setShowModal}
          show={showModal}
          handler_reload={setReload}
          reload={reload}
        />
      )}
      <Row className="justify-content-start align-items-center">
        {loading && (
          <div className="mt-3 w-100 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {!loading && listNotifications.length == 0 && (
          <div className="container-error-default">
            <p>Nenhum resultado foi encontrado.</p>
          </div>
        )}

        {!loading &&
          listNotifications.map((item, index) => {
            if (item.subrooms.length == 0) {
              if (item.patient_name != null) {
                return (
                  <Col key={index} xs={12} md={4} lg={3} className="my-2">
                    <div
                      className="main-card"
                      style={{
                        cursor:
                          item.notification != undefined ? "pointer" : "unset",
                        backgroundColor:
                          item.notification == undefined
                            ? Colors.lightest_gray
                            : "white",
                        minHeight: 178,
                      }}
                      onClick={() => {
                        if (item.notification == undefined) return false;
                        if (item.notification.tag == "realized") return false;
                        setDados(item.notification);
                        setShowModal(true);
                      }}
                    >
                      {item.notification != undefined && (
                        <div
                          className={`status-tag ${item.notification.tag}`}
                          style={{
                            backgroundColor:
                              item.notification.tag == "delayed"
                                ? Colors.brand_red
                                : item.notification.tag == "realized"
                                ? Colors.brand_green
                                : Colors.brand_yellow,
                          }}
                        >
                          {item.notification.tag == "delayed"
                            ? "ATRASADA"
                            : item.notification.tag == "realized"
                            ? "REALIZADA"
                            : "EM BREVE"}
                        </div>
                      )}

                      {item.notification == undefined && (
                        <div style={{ height: 23 }} />
                      )}

                      <div className="card-name mt-3 mb-1">
                        {item.patient_initials}
                      </div>

                      <div className="card-quarto mb-3">{item.room_name}</div>

                      {item.notification != undefined && (
                        <div>
                          <div className="card-data-sm mb-2">
                            <Icon
                              color={Colors.brand_blue}
                              size={20}
                              icon="clock"
                              className="mr-2"
                            />
                            {item.notification != undefined &&
                              moment(
                                item.notification.datetime_schedule
                              ).format("LT")}
                          </div>
                          <div className="card-data-sm">
                            <Icon
                              color={Colors.brand_blue}
                              size={20}
                              icon="refresh"
                              className="mr-2"
                            />
                            {item.notification?.position}
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                );
              } else {
                return (
                  <Col key={index} xs={12} md={4} lg={3} className="my-2">
                    <div className="main-card main-card-empty">
                      <div>
                        <p>{item.room_name}</p>
                      </div>
                    </div>
                  </Col>
                );
              }
            } else {
              return item.subrooms.map((i, a) => {
                if (i.patient_name != null) {
                  return (
                    <Col key={a} xs={12} md={4} lg={3} className="my-2">
                      <div
                        className="main-card"
                        style={{
                          cursor:
                            i.notification != undefined ? "pointer" : "unset",
                          backgroundColor:
                            i.notification == undefined
                              ? Colors.lightest_gray
                              : "white",
                          minHeight: 178,
                        }}
                        onClick={() => {
                          if (i.notification == undefined) return false;
                          if (i.notification.tag == "realized") return false;
                          setDados(i.notification);
                          setShowModal(true);
                        }}
                      >
                        {i.notification != undefined && (
                          <div
                            className={`status-tag ${i.notification.tag}`}
                            style={{
                              backgroundColor:
                                i.notification.tag == "delayed"
                                  ? Colors.brand_red
                                  : i.notification.tag == "realized"
                                  ? Colors.brand_green
                                  : Colors.brand_yellow,
                            }}
                          >
                            {i.notification.tag == "delayed"
                              ? "ATRASADA"
                              : i.notification.tag == "realized"
                              ? "REALIZADA"
                              : "EM BREVE"}
                          </div>
                        )}
                        {i.notification == undefined && (
                          <div style={{ height: 23 }} />
                        )}

                        <div className="card-name mt-3 mb-1">
                          {i.patient_initials}
                        </div>

                        <div className="card-quarto mb-3">
                          {item.room_name} | {i.room_name}
                        </div>

                        {i.notification != undefined && (
                          <div>
                            <div className="card-data-sm mb-2">
                              <Icon
                                color={Colors.brand_blue}
                                size={20}
                                icon="clock"
                                className="mr-2"
                              />
                              {i.notification != undefined &&
                                moment(i.notification.datetime_schedule).format(
                                  "LT"
                                )}
                            </div>
                            <div className="card-data-sm">
                              <Icon
                                color={Colors.brand_blue}
                                size={20}
                                icon="refresh"
                                className="mr-2"
                              />
                              {i?.notification?.position}
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                } else {
                  return (
                    <Col key={a} xs={12} md={4} lg={3} className="my-2">
                      <div className="main-card main-card-empty">
                        <div>
                          <p>
                            {item.room_name} <span>|</span> {i.room_name}
                          </p>
                        </div>
                      </div>
                    </Col>
                  );
                }
              });
            }
          })}
      </Row>
    </div>
  );
}

export default MapaLeitos;
