import React from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import Colors from "../../styles/Colors";
import Icon from "../../functions/Icon";

const ModalWarning = ({ handler_modal, show_modal, title, desc }) => {
  return (
    <Modal
      show={show_modal}
      onHide={() => handler_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-filter"
    >
      <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Col xs={10}>
            <div className="modal-title">{title}</div>
          </Col>
          <Col xs={2} className="pr-0 d-flex justify-content-end">
            <div
              className="modal-close-btn"
              onClick={() => handler_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </Col>
        </Row>

        <div className="my-3 container-wound-block">
          <p>{desc}</p>
        </div>
      </Modal.Body>

      <Modal.Footer className="row justify-content-end align-items-center pb-0">
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            className="mb-0 modal-footer-btn btn-confirm"
            style={{
              backgroundColor: Colors.brand_pink,
            }}
            onClick={() => handler_modal(false)}
          >
            <p
              className="mb-0"
              style={{
                color: "white",
              }}
            >
              Ok, entendi
            </p>
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWarning;
