import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import { useSelector } from "react-redux";
import "./styles.css";
import api from "../../services/api";

function VerifyPwWithUser(props) {
  const {
    handler_show_pw,
    show_pw,
    handler_request,
    handler_hide_tec_users,
    modal_name,
  } = props;

  const unit = useSelector((state) => state.unit);

  // Exibição do modal
  const [loadingBtn, setLoadingBtn] = useState("none");
  //Modal pw
  const [password, setPassword] = useState("");
  const [msgPw, setMsgPw] = useState("");
  const [viewPwd, setViewPwd] = useState(false);
  const [userChooser, setUserChooser] = useState(0);
  const [listUsers, setListUsers] = useState([]);
  const [modalStyle, setModalStyle] = useState({});

  useEffect(() => {
    function LoadUsers() {
      let usersList = [];
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3,9",
            unit_id: unit.selecionado.id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);
            usersList = response.data;
            var array = [];
            if (handler_hide_tec_users) {
              usersList = usersList.filter((item) => {
                return item.permission_id !== 3 && item.permission_id !== 9;
              });
            }
            usersList.map((item) => {
              array.push({
                label: item.name,
                value: item.id,
              });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    LoadUsers();
  }, []);

  function VerifyPw() {
    setLoadingBtn("flex");

    api
      .post("/verify-login", {
        user_id: userChooser.value,
        password,
      })
      .then((response) => {
        if (response.status == 200) {
          if (response.data) {
            handler_request(userChooser.value);
            handler_show_pw(false);
          } else {
            setMsgPw("A senha inserida está incorreta");
          }
        }
      })
      .catch((error) => {
        console.log("VerifyPw " + error);
        setMsgPw("Algo não saiu como o esperado. Tente novamente mais tarde.");
      })
      .finally(() => setLoadingBtn("none"));
  }

  const handleMenuOpen = () => {
    if (window.innerHeight < 700) {
      setModalStyle({ transform: "translateY(-105px)" });
    }
  };

  const handleMenuClose = () => {
    setModalStyle({});
  };

  return (
    <Modal
      show={show_pw}
      onHide={() => {
        handler_show_pw(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-pw"
      backdrop="static"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", ...modalStyle }}
      tabIndex="1"
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          VerifyPw();
        }
      }}
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">
              {modal_name || "Autenticar confirmação"}
            </div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => {
                handler_show_pw(false);
              }}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div style={{ overflow: "unset" }} className="modal-all">
          <div className="mb-4">
            <Form.Label className="field-label">Responsável</Form.Label>
            <Select
              captureMenuScroll={false}
              classNamePrefix="react-select"
              placeholder={"Selecionar"}
              noOptionsMessage={() => "Indisponível"}
              options={listUsers}
              isClearable={false}
              value={userChooser}
              onChange={(item) => {
                setUserChooser(item);
              }}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  VerifyPw();
                }
              }}
            />
          </div>

          <Form.Label className="field-label">Senha</Form.Label>
          <div className="default-input last-input">
            <input
              type={viewPwd ? "text" : "password"}
              className="borderless-input"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div onClick={() => setViewPwd(!viewPwd)}>
              <Icon
                color={Colors.darker_gray}
                size={24}
                icon={viewPwd ? "eye-off" : "eye-on"}
              />
            </div>
          </div>

          {msgPw != "" && <p className="mt-2 mb-0 msg-pw">{msgPw}</p>}
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end align-items-center">
        <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
          <button
            className="modal-footer-btn mr-0 btn-cancel"
            onClick={() => {
              handler_show_pw(false);
            }}
          >
            Cancelar
          </button>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={(password != "" ? false : true) || loadingBtn === "flex"}
            className="mb-0 modal-footer-btn btn-confirm"
            style={{
              backgroundColor:
                password != "" ? Colors.brand_pink : Colors.lighter_gray,
            }}
            onClick={() => VerifyPw()}
          >
            <p
              className="mb-0"
              style={{
                color: password != "" ? Colors.white : Colors.dark_gray,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              Continuar
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default VerifyPwWithUser;
