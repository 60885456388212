import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import Colors from "../../../styles/Colors";
import moment from "moment";
import EmptyContainer from "../../Relatorios/EmptyContainer";

function Exposure(props) {
  const chartRef = useRef(null);

  const { historicWounds, typeDescriptions } = props;

  const [data, setData] = useState(null);
  const [exposureDescriptions, setExposureDescriptions] = useState(null);

  useEffect(() => {
    if (historicWounds && typeDescriptions) {
      const labels = [];
      let exposure = typeDescriptions.find((i) => i.name.includes("Exposição"));
      let hasData = false;
      let noneDataId = 0;
      let exposureDescriptions = exposure.descriptions.map((i) => {
        if (i.name == "Sem Exposição") {
          noneDataId = i.id;
        }
        return {
          id: i.id,
          label: i.name,
          data: [],
          borderWidth: 4,
          pointRadius: 5,
          borderColor: Colors.brand_blue,
          backgroundColor: Colors.brand_blue,
          fill: false,
        };
      });
      setExposureDescriptions(exposureDescriptions);
      let minId =
        exposureDescriptions.reduce(
          (min, item) => (item.id < min ? item.id : min),
          exposureDescriptions[0].id
        ) - 1;
      for (const historic of historicWounds) {
        let date;
        if (historic.date_realized !== undefined) {
          date = moment(historic.date_realized).format("DD/MM");
        } else if (
          historic.date_confirmed !== undefined &&
          historic.date_confirmed != "Invalid date"
        ) {
          date = moment(historic.date_confirmed).format("DD/MM");
        } else {
          date = moment(historic.date_notification).format("DD/MM");
        }
        labels.push(date);
        let exposureHistoric = historic.descriptions.find(
          (i) => parseInt(i.type_description_id) == exposure.id
        );
        let exposureDescriptionIds = (
          exposureHistoric.description_id == "null" &&
          exposureHistoric.descriptions_ids
            ? exposureHistoric.descriptions_ids
            : exposureHistoric.description_id
        )
          .replace("[", "")
          .replace("]", "")
          .split(",")
          .filter((i) => !isNaN(parseInt(i)))
          .map((i) => parseInt(i));
        let noExposure = exposureDescriptions.find(
          (i) => i.label == "Sem Exposição"
        );
        if (exposureDescriptionIds.length > 0) {
          if (noExposure) {
            noExposure.data.push(null);
          }
          exposureDescriptions.map((i) => {
            if (
              !exposureDescriptionIds.includes(i.id) &&
              i.label != "Sem Exposição"
            ) {
              i.data.push(null);
            }
          });
          for (const exposureDescriptionId of exposureDescriptionIds) {
            exposureDescriptions
              .find((i) => i.id == exposureDescriptionId)
              .data.push(exposureDescriptionId);
          }
        } else {
          exposureDescriptions.map((i) => {
            if (i.label != "Sem Exposição") {
              i.data.push(null);
            }
          });
          if (noExposure) {
            noExposure.data.push(minId);
          }
        }
        if (noExposure) {
          noExposure.id = minId;
        }
      }

      const exposureIds = exposureDescriptions.map((i) => i.id);
      exposureDescriptions.push({
        data: [Math.min(...exposureIds), Math.max(...exposureIds)],
        fill: false,
        borderColor: "transparent",
        backgroundColor: "transparent",
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
      });
      setData({ labels, datasets: exposureDescriptions });
    }
  }, [historicWounds, typeDescriptions]);

  return (
    <>
      {data && exposureDescriptions && (
        <Row className="mt-5">
          <Col md={12}>
            <div className="card-report">
              <h4 className="mb-0">Exposição</h4>
              <div className="mt-4">
                <div class="chartContainer">
                  <div
                    style={{
                      width:
                        data?.datasets[0].data.length > 7
                          ? Math.max(
                              data?.labels.length * 50,
                              window.innerWidth
                            )
                          : null,
                    }}
                  >
                    <Line
                      ref={chartRef}
                      data={data}
                      options={{
                        maintainAspectRatio: false,
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, data) {
                              if (
                                tooltipItem.datasetIndex ==
                                exposureDescriptions.length - 1
                              ) {
                                return;
                              }
                              let exposureLabel = exposureDescriptions.find(
                                (i) => i.id == tooltipItem.yLabel
                              )?.label;
                              return `${exposureLabel}: ${tooltipItem.label}`;
                            },
                          },
                        },
                        legend: {
                          display: false,
                        },
                        scales: {
                          yAxes: [
                            {
                              gridLines: {
                                borderDash: [4, 4],
                              },
                              ticks: {
                                callback: function (value, index, values) {
                                  return exposureDescriptions.find(
                                    (i) => i.id == value
                                  )?.label;
                                },
                              },
                            },
                          ],
                          xAxes: [
                            {
                              gridLines: {
                                display: false,
                                drawBorder: false,
                              },
                            },
                          ],
                        },
                      }}
                      height={300}
                      width={100}
                    />
                  </div>
                </div>
                <div className="container-subtitle mt-4">
                  <div className="subtitle-item mr-4">
                    <div
                      className="indicator-radius"
                      style={{ backgroundColor: Colors.brand_blue }}
                    />
                    <p>Notificação / Evolução</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Exposure;
