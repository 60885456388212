import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import VerifyPw from "../../../../../components/Modals/VerifyPw";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import { GetImc, CalculateAge } from "../../../../../functions/utils";
import moment from "moment";
import "./styles.css";
import PreencherDiagrama from "../../Tabs/DiagramaIshikawa/PreencherDiagrama";
import { renderPhoto } from "../../../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../../../Home/Notificacoes/Notificacoes";

var wound = null;

function DiagramaIshikawa() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);

  const [list, setList] = useState([]);

  //Quando o card ta c/ a tag preenchido
  const [diagram, setDiagram] = useState([]);
  const [diagramInfo, setDiagramInfo] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [showModalDiagram, setShowModalDiagram] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    wound = null;
  }, []);

  useEffect(() => {
    function LoadList() {
      api
        .get(`/patients/${patientSelected.dados.id}/diagram/ishikawa`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadList success");
            console.log(response.data);

            setList(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadList error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de lesões confirmadas. Verifique sua conexão.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadList();
  }, [reload]);

  function LoadDiagram(patient_wound_id) {
    api
      .get(
        `/patients/${patientSelected.dados.id}/wounds/${patient_wound_id}/diagram/ishikawa`
      )
      .then((response) => {
        if (response.status == 200) {
          console.log("LoadDiagram success");
          console.log(response.data);

          setDiagramInfo(response.data);
          FormatData(response.data.types);
        }
      })
      .catch((error) => {
        console.log("LoadDiagram error " + error);
      });
  }

  function FormatData(data) {
    var pessoal = null;
    var paciente = null;
    var metodo = null;
    var materiais = null;
    var estrutura = null;
    var ambiente = null;

    data.map((item) => {
      var filter = item.options.filter((i) => i.is_checked == 1);
      if (item.name == "Pessoal") {
        pessoal = filter;
      }
      if (item.name == "Paciente") {
        paciente = filter;
      }
      if (item.name == "Método") {
        metodo = filter;
      }
      if (item.name == "Materiais") {
        materiais = filter;
      }
      if (item.name == "Estrutura") {
        estrutura = filter;
      }
      if (item.name == "Meio ambiente") {
        ambiente = filter;
      }
    });

    if (pessoal == null) {
      pessoal = [];
    }
    if (paciente == null) {
      paciente = [];
    }
    if (metodo == null) {
      metodo = [];
    }
    if (materiais == null) {
      materiais = [];
    }
    if (estrutura == null) {
      estrutura = [];
    }
    if (ambiente == null) {
      ambiente = [];
    }

    setDiagram([paciente, pessoal, metodo, estrutura, materiais, ambiente]);
  }

  function renderUserImg(obj) {
    var url = require("../../../../../assets/img/icon_user.png");
    if (obj != null) {
      url = obj;
    }

    return (
      <img style={{ width: 40, height: 40, borderRadius: "100%" }} src={url} />
    );
  }

  function renderModalDiagram() {
    return (
      <Modal
        show={showModalDiagram}
        onHide={() => setShowModalDiagram(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div className="container-tag-diagrama">
              <p className="mb-0">Preenchido</p>
            </div>

            <div className="d-flex">
              <div
                className="modal-close-btn mr-3"
                onClick={() => {
                  wound = diagramInfo;
                  setShowModalDiagram(false);
                  setShowModal(true);
                }}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="edit" />
              </div>

              <div
                className="modal-close-btn"
                onClick={() => setShowModalDiagram(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            <div>
              <div className="container-header-info my-2">
                <h3 className="mb-0">{diagramInfo.wound_name}</h3>
                <p className="mt-0">
                  {" "}
                  {diagramInfo.type == "admission"
                    ? "Admitida"
                    : "Incidida"} | {diagramInfo.stage_name}
                </p>
              </div>

              <p className="diagram-title">Diagrama de Ishikawa</p>
            </div>

            <div className="container-ball">
              <div className="ball" />
            </div>

            <div className="container-diagram">
              <div className="line">
                <div className="section-diagram">
                  <div className="container-section-diagram">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Paciente</p>
                    </div>

                    {diagram[0].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.values}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div className="line-mid" />

                <div className="section-diagram">
                  <div className="container-section-diagram d-flex flex-column align-items-end">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Pessoal</p>
                    </div>

                    {diagram[1].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.values}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    position: "absolute",
                    backgroundColor: "transparent",
                    top: 34,
                    zIndex: 1,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ddd",
                      width: 195,
                      height: 1,
                    }}
                  />
                </div>
              </div>

              <div className="line">
                <div className="section-diagram">
                  <div className="container-section-diagram">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Método</p>
                    </div>

                    {diagram[2].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.values}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div className="line-mid" />

                <div className="section-diagram">
                  <div className="container-section-diagram d-flex flex-column align-items-end">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Estrutura</p>
                    </div>

                    {diagram[3].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.values}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    position: "absolute",
                    backgroundColor: "transparent",
                    top: 34,
                    zIndex: 1,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ddd",
                      width: 195,
                      height: 1,
                    }}
                  />
                </div>
              </div>

              <div className="line">
                <div className="section-diagram">
                  <div className="container-section-diagram">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Materiais</p>
                    </div>

                    {diagram[4].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.values}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div className="line-mid" />

                <div className="section-diagram">
                  <div className="container-section-diagram d-flex flex-column align-items-end">
                    <div className="section-diagram-bg">
                      <p className="mb-0 section-diagram-title">Ambiente</p>
                    </div>

                    {diagram[5].map((item, index) => {
                      return (
                        <p className="op-choose" key={index}>
                          {item.values}
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    position: "absolute",
                    backgroundColor: "transparent",
                    top: 34,
                    zIndex: 1,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ddd",
                      width: 195,
                      height: 1,
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="container-footer">
              <div className="line-footer" />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <div className="w-100">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {renderUserImg(diagramInfo.verify_nurse_image)}

              <div className="dados-default">
                <div className="name-default">
                  Lesão confirmada por {diagramInfo.verify_nurse_name}
                </div>
                <div className="date-default">
                  {moment(diagramInfo.date_verify_nurse).format("LLL")}
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  function renderItem(item, index) {
    if (item.diagram_user == null) {
      return (
        <Col
          xs={12}
          md={6}
          key={index}
          style={{ width: 360, cursor: "pointer" }}
          className="my-2"
          onClick={() => {
            wound = item;
            setShowModal(true);
          }}
        >
          <div className="main-card main-card-notif">
            <div
              className="container-tag-diagrama"
              style={{ backgroundColor: Colors.brand_yellow }}
            >
              <p className="mb-0">Pendente</p>
            </div>

            <div className="card-name mb-1 d-flex">{item.wound_name}</div>

            <div className="card-quarto">
              {" "}
              {item.type == "incidence" ? "Incidência" : "Admissão"}
              &nbsp;
              {" | "} &nbsp;
              {item.stage_name}
            </div>

            <div
              className="mt-3"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="c-profile-dropdown mr-2">
                {renderPhoto(
                  makeUserDataToPhoto(
                    item.verify_nurse_name,
                    item.verify_nurse_image !== null
                      ? item.verify_nurse_image
                      : null
                  )
                )}
              </div>

              <div className="dados-default">
                <div className="name-default">
                  Lesão confirmada por {item.verify_nurse_name}
                </div>
                <div className="date-default">
                  {moment(item.date_verify_nurse).format("LLL")}
                </div>
              </div>
            </div>
          </div>
          <div className="card-notif-bottom">Preencher diagrama</div>
        </Col>
      );
    } else {
      return (
        <Col
          xs={12}
          md={6}
          key={index}
          style={{ width: 360, cursor: "pointer" }}
          className="my-2"
          onClick={() => {
            LoadDiagram(item.patient_wound_id);
            setShowModalDiagram(true);
          }}
        >
          <div className="main-card main-card-notif">
            <div className="container-tag-diagrama">
              <p className="mb-0">Preenchido</p>
            </div>

            <div className="card-name mb-1 d-flex">{item.wound_name}</div>

            <div className="card-quarto">
              {" "}
              {item.type == "incidence" ? "Incidência" : "Admissão"}
              &nbsp;
              {" | "} &nbsp;
              {item.stage_name}
            </div>

            <div
              className="mt-3"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="c-profile-dropdown mr-2">
                {renderPhoto(
                  makeUserDataToPhoto(
                    item.verify_nurse_name,
                    item.verify_nurse_image !== null
                      ? item.verify_nurse_image
                      : null
                  )
                )}
              </div>

              <div className="dados-default">
                <div className="name-default">
                  Lesão confirmada por {item.verify_nurse_name}
                </div>
                <div className="date-default">
                  {moment(item.date_verify_nurse).format("LLL")}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ paddingVertical: 3 }}
            className="card-bottom-confirmed d-flex align-items-start"
          >
            <Icon
              color={Colors.brand_green}
              size={18}
              icon={"check"}
              className="mr-2"
            />
            <div>
              <p className="mb-0">
                Preenchido por {item.diagram_user},{" "}
                {moment(item.diagram_created).format("LLL")}{" "}
              </p>
            </div>
          </div>
        </Col>
      );
    }
  }

  return (
    <div>
      {showModal && (
        <PreencherDiagrama
          handler_show_modal={setShowModal}
          show_modal={showModal}
          wound={wound}
          handler_reload={setReload}
          reload={reload}
        />
      )}

      {showModalDiagram && diagram.length > 0 && renderModalDiagram()}

      <h2 className="screen-name mb-4">Diagrama de Ishikawa</h2>

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {list.length == 0 && !loading && (
        <div className="container-error-default">
          <p>
            A análise de causa-raiz ficará disponível quando uma lesão por
            pressão incidida na unidade for confirmada.
          </p>
        </div>
      )}

      <Row>
        {list.map((item, index) => {
          return renderItem(item, index);
        })}
      </Row>
    </div>
  );
}

export default DiagramaIshikawa;
