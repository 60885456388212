import React, { useEffect, useState } from "react";
import { Col, Modal, Spinner, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../styles/Colors";
import Icon from "../../functions/Icon";
import api from "../../services/api";
import { Show_Alert } from "../../store/actions/alert";

function ModalPatientsPerDay({ ...props }) {
  const {
    reload,
    handlerReload,
    handler_show_modal,
    show_modal,
    type,
    year,
    month,
    patientsPerDay,
  } = props;

  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);

  const [patients, setPatients] = useState(null);
  const [loading, setLoading] = useState("none");

  useEffect(() => {
    if (patientsPerDay) {
      setPatients(patientsPerDay);
    }
  }, [patientsPerDay]);

  function VerifyFields() {
    let verify = false;

    if (patients !== "" && patients >= 0) {
      verify = true;
    }

    return verify;
  }

  function SavePatientsPerDay() {
    setLoading("flex");
    api
      .post("/dashboard/wound-report-metrics", {
        unitId: unit.selecionado.id,
        type,
        year: year,
        month: month + 1,
        type: "patientsPerDay",
        value: patients,
      })
      .then((response) => {
        if (response.status == 200) {
          handlerReload(!reload);
          dispatch(
            Show_Alert({
              type: "success",
              msg: "Pacientes/dia editado com sucesso",
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível editar a meta",
          })
        );
      })
      .finally(() => {
        setLoading("none");
        handler_show_modal(false);
      });
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-filter"
      data-testid="beacon-panel-toggle"
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Editar paciente/dia</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>
        <div className="modal-all">
          <div className="header-discharge mt-3">
            <div className="mb-4">
              <Form.Label className="field-label">Paciente/dia</Form.Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="default-input"
                  type="number"
                  placeholder="Digite o valor da meta"
                  min={0}
                  value={patients}
                  onChange={(e) => setPatients(e.target.value)}
                  style={{
                    borderRadius: "7px 0px 0px 7px",
                    "border-right": "none",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30px",
                    height: "49px",
                    borderRadius: "0px 7px 7px 0px",
                    padding: "12px 22px",
                    backgroundColor: "#F5F6F9",
                    border: "1px solid #DDDDDD",
                  }}
                >
                  *
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="row justify-content-end align-items-center pb-0">
        <Col xs={4} md={3} lg={3} className="px-0 mr-3">
          <div
            className="secondary-btn modal-footer-btn"
            onClick={() => handler_show_modal(false)}
          >
            <p>Cancelar</p>
          </div>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={!VerifyFields() || loading === "flex"}
            style={{
              backgroundColor: VerifyFields()
                ? Colors.brand_pink
                : Colors.lighter_gray,
              cursor: "pointer",
            }}
            className="mb-0 modal-footer-btn btn-confirm"
            onClick={() => SavePatientsPerDay()}
          >
            <p
              className="mb-0"
              style={{
                color: VerifyFields() ? Colors.white : Colors.dark_gray,
                display: loading == "none" ? "flex" : "none",
              }}
            >
              Salvar
            </p>
            <Spinner
              style={{ display: loading }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalPatientsPerDay;
