import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import VerifyPw from "../../../../../components/Modals/VerifyPw";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import moment from "moment";
import "./styles.css";
import NovaObs from "./NovaObs";

var noteObj = null;

function Observacoes() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [reload, setReload] = useState(false);
  const [listNotes, setListNotes] = useState([]);
  const [btnOp, setBtnOp] = useState(0);

  function renderUserImg(obj) {
    var url = require("../../../../../assets/img/icon_user.png");
    if (obj?.image != null) {
      url = obj.url_image;
    }

    return (
      <img
        style={{ width: 40, height: 40, borderRadius: "100%" }}
        alt={obj?.name}
        title={obj?.name}
        src={url}
      />
    );
  }

  useEffect(() => {
    function LoadNotes() {
      api
        .get(`/patients/${patientSelected.dados.id}/notes`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadNotes success");
            console.log(response.data);

            setListNotes(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadNotes error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg:
                "Não foi possível carregar a lista de observações. Verifique sua conexão.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadNotes();
  }, [reload]);

  function DeleteNote() {
    setLoadingBtn("flex");
    api
      .delete(`/patients/${patientSelected.dados.id}/notes/${noteObj.id}`)
      .then((response) => {
        if (response.status == 204) {
          var newList = listNotes.filter((item) => item.id != noteObj.id);
          setListNotes(newList);
        }
      })
      .catch((error) => {
        console.log("DeleteNote " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível excluir essa observação",
          })
        );
      })
      .finally(() => {
        setLoadingBtn("none");
        setShowModalDelete(false);
      });
  }

  function renderModalDelete() {
    return (
      <Modal
        show={showModalDelete}
        onHide={() => {
          setShowModalDelete(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter modal-pw"
      >
        <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={6} md={2} lg={6}>
              <div className="modal-title">Excluir Observação</div>
            </Col>
            <Col
              xs={2}
              md={2}
              lg={2}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => {
                  setShowModalDelete(false);
                }}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <div className="mt-4">
            <p className="delete-text">
              Tem certeza que deseja excluir a observação?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              className="modal-footer-btn mr-0 btn-cancel"
              onClick={() => {
                setShowModalDelete(false);
              }}
            >
              Cancelar
            </button>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor: Colors.brand_red,
              }}
              onClick={() => DeleteNote()}
            >
              <p
                className="mb-0"
                style={{
                  color: "white",
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Excluir
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div>
      {showModal && (
        <NovaObs
          handler_show_modal={setShowModal}
          show_modal={showModal}
          noteObj={noteObj}
          handler_reload={setReload}
          reload={reload}
        />
      )}

      {showModalDelete && renderModalDelete()}

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h2 className="screen-name">Observações</h2>
        <button
          onClick={() => {
            noteObj = null;
            setShowModal(true);
          }}
          style={{ width: "fit-content" }}
          className="default-btn"
        >
          Nova observação
        </button>
      </div>

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && listNotes.length == 0 && (
        <div className="container-error-default">
          <p>Não foram cadastradas observações para esse paciente.</p>
        </div>
      )}

      <Row>
        {listNotes.map((item, index) => {
          return (
            <Col
              xs={12}
              key={index}
              style={{ borderRadius: 10 }}
              className="my-2"
            >
              <div className="main-card">
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {renderUserImg(item.user)}

                    <div className="dados-default">
                      <div className="name-default">{item.user?.name}</div>
                      <div className="date-default">
                        {moment(item.created_at).format("LLL")}
                      </div>
                    </div>
                  </div>

                  {item.user?.id != user.dados.id &&
                    item.created_at != item.updated_at && (
                      <p className="text-obs-edit">Editada</p>
                    )}

                  {item.user?.id == user.dados.id && (
                    <Dropdown
                      onClick={() => setBtnOp(item.id)}
                      className="container-drop-obs"
                    >
                      <Dropdown.Toggle
                        className="btn-drop-obs"
                        id="dropdown-basic"
                      >
                        <Icon
                          color={
                            item.id == btnOp
                              ? Colors.brand_blue
                              : Colors.darkest_gray
                          }
                          size={24}
                          icon="dots-vertical"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          href="#"
                          onClick={(e) => {
                            e.stopPropagation();
                            noteObj = item;
                            setShowModal(true);
                          }}
                        >
                          <Icon
                            color={Colors.dark_gray}
                            size={20}
                            icon="edit"
                            className="mr-2"
                          />
                          Editar observação
                        </Dropdown.Item>

                        <Dropdown.Item
                          href="#"
                          onClick={(e) => {
                            e.stopPropagation();
                            noteObj = item;
                            setShowModalDelete(true);
                          }}
                        >
                          <Icon
                            color={Colors.dark_gray}
                            size={20}
                            icon="trash"
                            className="mr-2"
                          />
                          Excluir observação
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>

                <p className="mt-4 obs-text">{item.note}</p>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default Observacoes;
