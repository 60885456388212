import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  Brush,
} from "recharts";
import DatePicker from "../../../../../components/DatePicker";
import { Row, Form, Spinner } from "react-bootstrap";
import { getAngleColor } from "../../../../../components/Charts/src/support/utils";
import api from "../../../../../services/api";
import moment from "moment";
import EmptyContainer from "../../../../Relatorios/EmptyContainer";
import { useSelector } from "react-redux";

const processApiData = (apiData) => {
  const processedData = new Array(5760).fill(null).map((_, index) => ({
    time: moment()
      .startOf("day")
      .add(index * 15, "seconds")
      .format("HH:mm:ss"),
  }));
  const positions = new Set();

  apiData.forEach((item) => {
    const [, time] = item.date.split(" ");
    const [hour, minute, second] = time.split(":");
    const index =
      parseInt(hour) * 240 +
      parseInt(minute) * 4 +
      Math.floor(parseInt(second) / 15);

    positions.add(item.position);

    processedData[index] = {
      ...processedData[index],
      [`${item.position}Vertical`]: Math.abs(item.angleHorizontal),
      [`${item.position}Horizontal`]: Math.abs(item.angleVertical),
    };
  });

  return {
    data: processedData,
    positions: Array.from(positions),
  };
};

const CustomizedLine = (props) => {
  const { points, stroke, strokeWidth, dataKey } = props;
  const validSegments = [];
  let currentSegment = [];

  points.forEach((point, index) => {
    if (point.payload[dataKey] !== undefined) {
      currentSegment.push(point);
      if (
        index === points.length - 1 ||
        points[index + 1].payload[dataKey] === undefined
      ) {
        validSegments.push([...currentSegment]);
        currentSegment = [];
      }
    }
  });

  return (
    <g>
      {validSegments.map((segment, segmentIndex) => (
        <polyline
          key={segmentIndex}
          points={segment
            .map((point, i) => {
              const x = point.x;
              const y = point.y;
              if (
                i === segment.length - 1 &&
                segmentIndex !== validSegments.length - 1
              ) {
                return `${x},${y} ${points[points.indexOf(point) + 1].x},${y}`;
              }
              return `${x},${y}`;
            })
            .join(" ")}
          fill="none"
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      ))}
    </g>
  );
};

const CustomTick = ({ x, y, payload }) => {
  const hour = payload.value.split(":")[0];
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {`${hour}h`}
      </text>
    </g>
  );
};

const CustomLegend = (props) => {
  const { payload, handleClick, hiddenLines } = props;

  const preferredPositions = ["Dorsal", "Prona", "Em-pe/sentado"];

  const groups = payload.reduce((acc, item) => {
    const position = item.value.split(" ")[0];
    if (!acc[position]) {
      acc[position] = [];
    }
    acc[position].push(item);
    return acc;
  }, {});

  return (
    <ul
      className="d-flex justify-content-center align-items-center"
      style={{ listStyle: "none", padding: 0 }}
    >
      {Object.keys(groups).map((position, index) => (
        <li key={index} style={{ margin: "30px 30px 0px 30px" }}>
          <ul style={{ listStyle: "none", paddingLeft: "15px" }}>
            {groups[position]
              .sort((a, b) => {
                const isPreferred = preferredPositions.includes(position);
                const aStrong = a.dataKey.includes("Horizontal");
                const bStrong = b.dataKey.includes("Horizontal");
                if (isPreferred) {
                  return aStrong === bStrong ? 0 : aStrong ? -1 : 1;
                } else {
                  return aStrong === bStrong ? 0 : aStrong ? 1 : -1;
                }
              })
              .map((entry, idx) => (
                <li
                  key={idx}
                  style={{
                    marginBottom: "5px",
                    cursor: "pointer",
                    opacity: hiddenLines.includes(entry.dataKey) ? 0.5 : 1,
                  }}
                  onClick={() => handleClick(entry)}
                >
                  <span
                    style={{
                      display: "inline-block",
                      width: 30,
                      height: 5,
                      backgroundColor: entry.color,
                      marginRight: 5,
                      verticalAlign: "middle",
                    }}
                  ></span>
                  <span
                    style={{
                      color: "#666",
                      fontFamily: idx === 0 ? "Rubik-Medium" : "Rubik-Regular",
                    }}
                  >
                    {entry.value}
                  </span>
                </li>
              ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const preferredPositions = ["dorsal", "prona", "em-pe/sentado"];

    const sortedPayload = [...payload].sort((a, b) => {
      const positionA = a.dataKey.replace(/Vertical|Horizontal/, "");
      const isPreferredA = preferredPositions.includes(positionA);
      return isPreferredA ? 1 : -1;
    });

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          padding: "10px",
          border: "1px solid #ccc",
          color: "#FFFFFF",
          fontFamily: "Rubik-Medium",
          fontSize: "14px",
        }}
      >
        <p className="label">{`Período: ${label}\n\n`}</p>
        <p>Posição: {sortedPayload[0].name.split(" - ")[0]}</p>
        {sortedPayload.map((entry, index) => (
          <span key={index}>
            <p>{`${entry.name.split(" - ")[1]}: ${entry.value}°`}</p>
          </span>
        ))}
      </div>
    );
  }

  return null;
};

function Angulacoes() {
  const patientSelected = useSelector((state) => state.patientSelected);

  const [data, setData] = useState([]);
  const [positions, setPositions] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hiddenLines, setHiddenLines] = useState([]);

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1300);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1300);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  async function fetchData() {
    setLoading(true);
    api
      .get(`/patients/${patientSelected.dados.id}/angulations?date=${date}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length <= 2) {
            setNoData(true);
          } else {
            const { data: processedData, positions } = processApiData(
              response.data
            );
            setData(processedData);
            setPositions(positions);
            setNoData(false);
          }
        }
      })
      .catch((error) => {
        setNoData(true);
        console.error("Erro ao carregar os dados:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleLegendClick(entry) {
    const { dataKey } = entry;
    setHiddenLines((prev) =>
      prev.includes(dataKey)
        ? prev.filter((key) => key !== dataKey)
        : [...prev, dataKey]
    );
  }

  useEffect(() => {
    fetchData();
  }, [date]);

  return (
    <>
      <div>
        <Form.Label className="field-label">Período</Form.Label>
        <Row
          style={{
            maxWidth: "248px",
          }}
          className="justify-content-start align-items-center mx-0"
        >
          <DatePicker selectedDate={date} dateHandler={setDate} />
        </Row>
      </div>
      {!loading ? (
        <div
          className="card-report overview-card-report"
          style={{ overflowX: isLargeScreen ? "hidden" : "auto" }}
        >
          {!noData ? (
            <>
              <h4 className="mb-5">
                Monitoramento de angulação do paciente ao longo do tempo por
                posição
              </h4>
              <ResponsiveContainer
                width={isLargeScreen ? "100%" : 1300}
                height={600}
              >
                <LineChart
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="time" interval={239} tick={<CustomTick />} />
                  <YAxis
                    domain={[0, 180]}
                    ticks={[
                      0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180,
                    ]}
                    tickFormatter={(value) => `${value}°`}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    content={
                      <CustomLegend
                        handleClick={handleLegendClick}
                        hiddenLines={hiddenLines}
                      />
                    }
                  />
                  <Brush dataKey="time" height={30} stroke="#8884d8" />
                  {positions.flatMap((position) => [
                    <Line
                      key={`${position}Horizontal`}
                      type="stepAfter"
                      dataKey={`${position}Horizontal`}
                      stroke={getAngleColor(position, true)}
                      strokeWidth={3}
                      dot={false}
                      shape={<CustomizedLine />}
                      hide={hiddenLines.includes(`${position}Horizontal`)}
                      name={`${
                        position.charAt(0).toUpperCase() + position.slice(1)
                      } - Angulação Vertical`}
                    />,
                    <Line
                      key={`${position}Vertical`}
                      type="stepAfter"
                      dataKey={`${position}Vertical`}
                      stroke={getAngleColor(position, false)}
                      strokeWidth={3}
                      dot={false}
                      shape={<CustomizedLine />}
                      hide={hiddenLines.includes(`${position}Vertical`)}
                      name={`${
                        position.charAt(0).toUpperCase() + position.slice(1)
                      } - Angulação Lateral`}
                    />,
                  ])}
                </LineChart>
              </ResponsiveContainer>
            </>
          ) : (
            <EmptyContainer
              text="Ainda não há dados sobre angulações neste dia"
              iconName="description_1"
            />
          )}
        </div>
      ) : (
        <div className="my-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  );
}

export default Angulacoes;
