import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../services/api";
import { Container, Form, Spinner } from "react-bootstrap";

import "../CadastrarOtp/styles.css";
import logo from "../../../assets/img/logo.png";
import Colors from "../../../styles/Colors";
import { Show_Alert } from "../../../store/actions/alert";

function ValidarOtp({ history }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [token, setToken] = useState("");
  const [qrCode, setQrCode] = useState(null);
  const [secret, setSecret] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState("none");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && token.length === 6) {
        event.preventDefault();
        ValidateOtp();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [token]);

  function ValidateOtp() {
    setLoadingBtn("flex");
    api
      .post("/otp/validator", {
        token,
      })
      .then((response) => {
        if (response.data) {
          history.push({
            pathname: "/Home",
          });
        } else {
          dispatch(
            Show_Alert({
              type: "error",
              msg: "O código de segurança está inválido.",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          Show_Alert({
            type: "error",
            msg: "O código de segurança está inválido.",
          })
        );
      })
      .finally(() => {
        setLoadingBtn("none");
      });
  }

  return (
    <div>
      <Container
        fluid
        className="main-container d-flex justify-content-center align-items-center container-otp"
      >
        <img
          src={logo}
          className="logo-nav mb-5"
          style={{
            maxHeight: "4.2em",
          }}
        />
        <div className="card-report otp-register">
          <h4 className="mb-4">Validar MFA</h4>
          <p>
            Para prosseguir com a autenticação na aplicação da Preveni, é
            necessário inserir o seu código de segurança gerado pelo seu
            aplicativo de autenticação.
          </p>
          <div className="mt-4 d-flex flex-column align-items-center">
            <div>
              <Form.Label>
                Digite o código de verificação do seu aplicativo de autenticação
              </Form.Label>
              <input
                className="default-input"
                type="text"
                name="secret"
                placeholder="000000"
                onChange={(e) => setToken(e.target.value)}
              />
            </div>
            <button
              disabled={token.length !== 6}
              onClick={ValidateOtp}
              style={{
                background:
                  token.length == 6 ? Colors.brand_pink : Colors.lightest_gray,
              }}
              className="btn-click mt-3"
            >
              <p
                className="mb-0"
                style={{
                  display: loadingBtn == "none" ? "flex" : "none",
                  color: token.length == 6 ? "white" : Colors.dark_gray,
                }}
              >
                Validar código de verificação
              </p>
              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ValidarOtp;
