import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import ModalWarning from "../../../../../components/Modals/ModalWarning";
import DetailsCheckList from "./DetailsCheckList";
import NewCheckList from "./NewCheckList";
import VerifyPw from "../../../../../components/Modals/VerifyPw";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import moment from "moment";
import "./styles.css";
import { renderPhoto } from "../../../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../../../Home/Notificacoes/Notificacoes";
import { userHasGeneralPermissions } from "../../../../../functions/utils";

var checkListObj = {};

function CheckListPaciente() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [showNewCheckList, setShowNewCheckList] = useState(false);
  const [showCheckList, setShowCheckList] = useState(false);
  const [listChecklist, setListChecklist] = useState([]);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [hasPrescription, setHasPrescription] = useState(false);

  useEffect(() => {
    function LoadChecklist() {
      api
        .get(`/patients/${patientSelected.dados.id}/checklists`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadChecklist success");
            console.log(response.data);

            setListChecklist(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadChecklist error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de Checklist",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadChecklist();
  }, [reload]);

  useEffect(() => {
    function LoadPrescriptionsPatient() {
      api
        .get(`patients/${patientSelected.dados.id}/prescriptions?checklist=1`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPrescriptionsPatient success");
            console.log(response.data);

            if (response.data.length > 0) {
              setHasPrescription(true);
            }
          }
        });
    }

    LoadPrescriptionsPatient();
  }, []);

  function GetDesc() {
    if (userHasGeneralPermissions(user)) {
      return "Preencha a prescrição antes de realizar o checklist.";
    } else {
      return "Solicite à (o) enfermeira(o) que preencha a prescrição antes de realizar o checklist.";
    }
  }

  return (
    <div>
      {showNewCheckList && (
        <NewCheckList
          handler_show_modal={setShowNewCheckList}
          show_modal={showNewCheckList}
          handler_reload={setReload}
          reload={reload}
        />
      )}

      {showCheckList && (
        <DetailsCheckList
          handler_show_modal={setShowCheckList}
          show_modal={showCheckList}
          check_list={checkListObj}
        />
      )}

      {showModalWarning && (
        <ModalWarning
          handler_modal={setShowModalWarning}
          show_modal={showModalWarning}
          title={"Não é possível notificar"}
          desc={GetDesc()}
        />
      )}

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h2 className="screen-name">Checklist</h2>
        <button
          onClick={() => {
            if (hasPrescription) setShowNewCheckList(true);
            else setShowModalWarning(true);
          }}
          style={{
            width: "fit-content",
          }}
          className="default-btn"
        >
          Nova checagem
        </button>
      </div>

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && listChecklist.length == 0 && (
        <div className="container-error-default">
          <p>Não foram realizadas checagens para esse paciente.</p>
        </div>
      )}

      <Row>
        {listChecklist.map((item, index) => {
          return (
            <Col
              className="mb-4"
              xs={12}
              md={6}
              key={index}
              onClick={() => {
                checkListObj = item;
                setShowCheckList(true);
              }}
            >
              <div
                className="card-check-list"
                onClick={() => setShowCheckList(true)}
              >
                <h4>{item.title}</h4>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div className="c-profile-dropdown mr-2">
                    {renderPhoto(
                      makeUserDataToPhoto(
                        item.user_name,
                        item.user_image !== null ? item.user_image : null
                      )
                    )}
                  </div>

                  <div className="dados-default">
                    <div className="name-default">Por {item.user_name}</div>
                    <div className="date-default">
                      {moment(item.created).format("LLL")}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default CheckListPaciente;
