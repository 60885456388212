import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

function FilterChooser(props) {
  const {
    unidades_selected,
    qtd_lesoes_selected,
    date_start_selected,
    date_end_selected,
    escores_selected,
  } = props;

  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);

  const ESCORES = [
    {
      qtd: "out",
      name: "Fora de risco (maior ou igual 19)",
    },
    {
      qtd: "low",
      name: "Baixo (15 - 18)",
    },
    {
      qtd: "moderate",
      name: "Moderado (13 - 14)",
    },
    {
      qtd: "high",
      name: "Alto (10 - 12)",
    },
    {
      qtd: "veryhigh",
      name: "Muito alto (6 - 9)",
    },
    {
      qtd: "none",
      name: "Sem avaliação de risco",
    },
  ];

  var arrayQtd = [
    {
      qtd: "0",
      name: "0",
    },
    {
      qtd: "1",
      name: "1",
    },
    {
      qtd: "2",
      name: "2",
    },
    {
      qtd: "3",
      name: "3",
    },
    {
      qtd: "4",
      name: "4",
    },
    {
      qtd: "5",
      name: "5",
    },
    {
      qtd: "more",
      name: "6 +",
    },
  ];

  // Exibição do modal
  const [show, setShow] = useState(props.show);

  // Opções
  const [unidades, setUnidades] = useState(userUnit.list);
  const [qtdsLesoes, setQtdsLesoes] = useState(arrayQtd);
  const [escores, setEscores] = useState(ESCORES);

  // Selecionadas
  const [unidade, setUnidade] = useState([]);
  const [qtdLesoes, setQtdLesoes] = useState([]);
  const [escore, setEscore] = useState([]);
  const [date_start, setDate_start] = useState("");
  const [date_end, setDate_end] = useState("");

  // Salvar
  const [aplicar, setAplicar] = useState(false);

  // Handlers pra aplicar mudanças
  useEffect(() => {
    if (aplicar) {
      const handler_unidade = props.handler_unidade;
      handler_unidade(unidade);
      const handler_qtdlesoes = props.handler_qtdlesoes;
      handler_qtdlesoes(qtdLesoes);
      const handler_escore = props.handler_escore;
      handler_escore(escore);
      const handler_date_start = props.handler_date_start;
      handler_date_start(date_start);
      const handler_date_end = props.handler_date_end;
      handler_date_end(date_end);

      //Volta pra pagina 1
      const handler_current_page = props.handler_current_page;
      handler_current_page(1);

      setShow(false);
      setAplicar(false);

      dispatch({
        type: "SET_PATIENTS_FILTER",
        dados: {
          ativos: props.ativos,
          unidade,
          qtdLesoes,
          escore,
          dateStart: date_start,
          dateEnd: date_end,
        },
      });
    }
  }, [aplicar]);

  useEffect(() => {
    if (unidades_selected.length > 0) {
      setUnidade(unidades_selected);
    }
    if (qtd_lesoes_selected.length > 0) {
      setQtdLesoes(qtd_lesoes_selected);
    }
    if (date_start_selected != "") {
      setDate_start(date_start_selected);
    }
    if (date_end_selected != "") {
      setDate_end(date_end_selected);
    }
    if (escores_selected.length > 0) {
      setEscore(escores_selected);
    }
  }, [
    unidades_selected,
    qtd_lesoes_selected,
    date_start_selected,
    date_end_selected,
    escores_selected,
  ]);

  // Seleção das opções
  function toggleUnidade(u) {
    var list = [];
    unidade.forEach((item) => {
      list.push(item);
    });

    if (list.includes(u)) {
      list = list.filter((item) => item != u);
    } else {
      list.push(u);
    }
    setUnidade(list);
  }

  function toggleQtdLesoes(q) {
    var list = [];
    qtdLesoes.forEach((item) => {
      list.push(item);
    });

    if (list.includes(q)) {
      list = list.filter((item) => item != q);
    } else {
      list.push(q);
    }
    setQtdLesoes(list);
  }

  function toggleEscore(e) {
    var list = [];
    escore.forEach((item) => {
      list.push(item);
    });

    if (list.includes(e)) {
      list = list.filter((item) => item != e);
    } else {
      list.push(e);
    }

    setEscore(list);
  }

  // Limpar
  function clear() {
    setUnidade([]);
    setQtdLesoes([]);
    setEscore([]);
    setDate_start("");
    setDate_end("");
    dispatch({
      type: "RESET_PATIENTS_FILTER",
    });
  }

  function handleCallback(start, end) {
    setDate_start(moment(start).format("YYYY-MM-DD"));
    setDate_end(moment(end).format("YYYY-MM-DD"));
  }

  function Verify() {
    let c = "";

    if (
      unidades_selected.length > 0 ||
      qtd_lesoes_selected.length > 0 ||
      date_start_selected != "" ||
      date_end_selected != "" ||
      escores_selected.length > 0
    )
      c = "active";

    return c;
  }

  useEffect(() => {
    if (escores_selected.length != undefined) {
      setEscore(escores_selected);
    }
  }, [escores_selected]);

  return (
    <>
      <div
        className={`btn-filter ${props.cssClasses} ${Verify()}`}
        style={props.cssStyles}
        onClick={() => setShow(true)}
      >
        <Icon
          color={Verify() == "" ? Colors.darker_gray : Colors.brand_blue}
          size={22}
          icon="filter"
          className="mr-2"
        />
        Filtros
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter"
      >
        <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={6} md={2} lg={2}>
              <div className="modal-title">Filtros</div>
            </Col>
            <Col
              xs={2}
              md={2}
              lg={2}
              className="pr-0 d-flex justify-content-end"
            >
              <div className="modal-close-btn" onClick={() => setShow(false)}>
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <div className="modal-text mt-3 mb-2">Unidade</div>
          <Row className="justify-content-start align-items-center mb-3">
            {unidades.map((u, i) => {
              return (
                <Col key={i} xs={4} md={3} lg={3} className="pr-0 mb-2">
                  <div
                    className={
                      unidade.includes(u.id) ? "gray-btn active" : "gray-btn"
                    }
                    onClick={() => toggleUnidade(u.id)}
                  >
                    {u.name}
                  </div>
                </Col>
              );
            })}
          </Row>

          <div className="modal-text mt-3 mb-2">Quantidade de lesões</div>
          <Row className="justify-content-start align-items-center mb-3 mx-0">
            {qtdsLesoes.map((q, i) => {
              return (
                <Col key={i} className="pl-0">
                  <div
                    className={
                      qtdLesoes.includes(q.qtd) ? "gray-btn active" : "gray-btn"
                    }
                    onClick={() => toggleQtdLesoes(q.qtd)}
                  >
                    {q.name}
                  </div>
                </Col>
              );
            })}
          </Row>

          <div className="modal-text mt-3 mb-2">Escore de risco</div>
          <Row className="justify-content-start align-items-center mb-3 mx-0 flex-wrap">
            {escores.map((e, i) => {
              return (
                <div className="m-1">
                  <div
                    className={
                      escore.includes(e.qtd)
                        ? "gray-btn active p-3"
                        : "gray-btn p-3"
                    }
                    onClick={() => toggleEscore(e.qtd)}
                  >
                    {e.name}
                  </div>
                </div>
              );
            })}
          </Row>

          <div className="modal-text mt-3 mb-2">Período</div>
          <Row className="justify-content-start align-items-center mb-3 mx-0">
            <DateRangePicker
              initialSettings={{
                locale: {
                  cancelLabel: "Cancelar",
                  applyLabel: "Aplicar",
                },
                drops: "up",
                applyButtonClasses: "btn-dr-custom",
              }}
              onCallback={(start, end) => handleCallback(start, end)}
            >
              <Button variant="light" className="d-flex btn-date-range">
                <Icon
                  color={Colors.darker_gray}
                  size={22}
                  icon="calendar"
                  className="mr-2"
                />
                <p className="m-0 text_e-left">
                  {date_start != ""
                    ? moment(date_start).format("DD/MM/YYYY")
                    : "Data inicial"}{" "}
                  -{" "}
                  {date_end != ""
                    ? moment(date_end).format("DD/MM/YYYY")
                    : "Data final"}
                </p>
              </Button>
            </DateRangePicker>
          </Row>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-3">
            <div
              className="secondary-btn modal-footer-btn"
              onClick={() => clear()}
            >
              Limpar
            </div>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <div
              className="default-btn mb-0 modal-footer-btn"
              onClick={() => setAplicar(true)}
            >
              Aplicar
            </div>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FilterChooser;
