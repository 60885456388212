import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import moment from "moment";

import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import { HexToRgbA } from "../../../functions/utils";
import ModalDouble from "./ModalDouble";

function DuplaChecagem() {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [listDouble, setListDouble] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dados, setDados] = useState([]);

  useEffect(() => {
    function LoadDouble() {
      api
        .get(`/doublecheck`, {
          params: {
            unit_id: unit.selecionado.id,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            // console.log("LoadDouble success");
            // console.log(response.data);

            setListDouble(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadDouble error " + error);

          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de dupla checagem",
            })
          );
        })
        .finally(() => {
          setTimeout(() => {
            setReload(!reload);
          }, 5000);
          setLoading(false);
        });
    }

    if (unit.selecionado?.id != undefined) {
      LoadDouble();
    } else {
      setLoading(false);
    }
  }, [unit, reload]);

  return (
    <div>
      {showModal && (
        <ModalDouble
          handler_show_modal={setShowModal}
          show={showModal}
          handler_reload={setReload}
          reload={reload}
          dados={dados}
        />
      )}

      <Row className="justify-content-start align-items-center">
        {loading && (
          <div className="mt-3 w-100 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {!loading && listDouble.length == 0 && (
          <div className="container-error-default">
            <p>Nenhum resultado foi encontrado.</p>
          </div>
        )}

        {!loading &&
          listDouble.map((item, index) => {
            return (
              <Col key={index} xs={12} md={4} lg={3} className="my-2">
                <div
                  className="main-card"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setDados(item);
                    setShowModal(true);
                  }}
                >
                  <div
                    className={`status-tag`}
                    style={{
                      backgroundColor: Colors.brand_yellow,
                    }}
                  >
                    PENDENTE
                  </div>

                  <div className="card-name mt-3 mb-1">{item.initials}</div>

                  <div className="card-quarto mb-3">
                    {item.room == null && item.subroom}
                    {item.room != null && `${item.room} | ${item.subroom}`}
                  </div>

                  <div>
                    <div className="card-data-sm mb-2">
                      <Icon
                        color={Colors.brand_blue}
                        size={20}
                        icon="clock"
                        className="mr-2"
                      />
                      {moment(item.datetime_schedule).format("LT")}
                    </div>
                    <div className="card-data-sm">
                      <Icon
                        color={Colors.brand_blue}
                        size={20}
                        icon="refresh"
                        className="mr-2"
                      />
                      {item.position}
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
    </div>
  );
}

export default DuplaChecagem;
