import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import { Button } from "react-bootstrap";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Colors from "../styles/Colors";
import Icon from "../functions/Icon";
import "../styles/global.css";
import { ptBR } from "date-fns/locale";
import { Show_Alert } from "../store/actions/alert";
import { useDispatch } from "react-redux";

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

const customPtBR = {
  ...ptBR,
  localize: {
    ...ptBR.localize,
    day: (n, options) => {
      const dayNames = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
      return options?.width === "abbreviated"
        ? dayNames[n]
        : capitalize(ptBR.localize.day(n, options));
    },
    month: (n, options) => {
      const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      const monthNamesAbbreviated = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      return options?.width === "abbreviated"
        ? monthNamesAbbreviated[n]
        : monthNames[n];
    },
  },
};

function DateRangerPicker({ dateInterval, dateIntervalHandler, maxDays }) {
  const dispatch = useDispatch();
  const [state, setState] = useState([
    {
      startDate: new Date(moment(dateInterval.start).utc(0)),
      endDate: new Date(moment(dateInterval.end).utc(0)),
      key: "selection",
    },
  ]);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (ranges) => setState([ranges.selection]);

  const togglePicker = () => setShowPicker(!showPicker);

  return (
    <div ref={pickerRef} style={{ position: "relative", width: "100%" }}>
      <Button
        style={{
          backgroundColor: "transparent",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#ddd",
          minHeight: 48,
          borderRadius: 10,
        }}
        variant="light"
        className="d-flex btn-date-range align-items-center"
        onClick={togglePicker}
      >
        <Icon
          color={Colors.darker_gray}
          size={22}
          icon="calendar"
          className="mr-2"
        />
        <p className="m-0 text_e-left sensor-filter">
          {dateInterval.start
            ? moment(dateInterval.start).format("DD/MM/YYYY")
            : "Data inicial"}{" "}
          -{" "}
          {dateInterval.end
            ? moment(dateInterval.end).format("DD/MM/YYYY")
            : "Data final"}
        </p>
      </Button>

      {showPicker && (
        <div>
          <DateRange
            locale={customPtBR}
            editableDateInputs={true}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            ranges={state}
            minDate={new Date("2017-01-01")}
            maxDate={new Date()}
          />
          <Button
            className="button-date-ranger-cancel"
            onClick={() => setShowPicker(false)}
          >
            Cancelar
          </Button>
          <Button
            className="button-date-ranger-confirm"
            onClick={() => {
              setShowPicker(false);
              const { startDate, endDate } = state[0];
              const datesDiff = moment(endDate).diff(startDate, "days");
              if (maxDays && datesDiff > parseInt(maxDays)) {
                dispatch(
                  Show_Alert({
                    type: "error",
                    msg: ` Intervalo de datas não pode exceder ${maxDays} dias.`,
                  })
                );
              } else {
                dateIntervalHandler({
                  start: moment(startDate).format("YYYY-MM-DD"),
                  end: moment(endDate).format("YYYY-MM-DD"),
                });
              }
            }}
          >
            Aplicar
          </Button>
        </div>
      )}
    </div>
  );
}

export default DateRangerPicker;
