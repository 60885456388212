import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Bar, Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../functions/Icon";
import api from "../../services/api";
import { Show_Alert } from "../../store/actions/alert";
import Colors from "../../styles/Colors";
import ModalRankingDiagrama from "./ModalRanking";
import LastShiftsSuspensionsDropdown from "../../components/Dropdowns/LastShiftsSuspensionsDropdown";
import ModalPatientsRisks from "./ModalData/ModalPatientsRisks";
import {
  resizeBmiChartBasedOnWidth,
  secondsToHoursMinutes,
} from "../../functions/utils";
import DateRangePickerMonthYear from "../../components/DateRangePickerMonthYear";
import ModalRankingSuspensionsDetails from "./ModalRankingSuspensionsDetails";

var nameModal = {};
var listRanking = [];

function AderenciaProtocolo({ history }) {
  const unit = useSelector((state) => state.unit.selecionado);

  const [loading, setLoading] = useState(false);
  const startOfMonth = moment().date(1).format("YYYY-MM-DD");
  const endOfMonth = moment().format("YYYY-MM-DD");
  const [dateInterval, setDateInterval] = useState({
    start: startOfMonth,
    end: endOfMonth,
  });
  const [dateRange, setDateRange] = useState("6 meses");

  const [protocolAdherence, setProtocolAdherence] = useState(null);
  const [protocolAdherenceSix, setProtocolAdherenceSix] = useState(null);
  const [suspensions, setSuspensions] = useState(null);
  const [suspensionsSix, setSuspensionsSix] = useState(null);
  const [firstChartProtocolAdherence, setFirstChartProtocolAdherence] =
    useState(null);
  const [chartProtocolAdherenceGender, setChartProtocolAdherenceGender] =
    useState(null);
  const [chartShiftAdherence, setChartShiftAdherence] = useState(null);
  const [chartSuspensionByShift, setChartSuspensionByShift] = useState(null);
  const [
    optionsFirstChartProtocolAdherence,
    setOptionsFirstChartProtocolAdherence,
  ] = useState(null);
  const [optionsSuspensionByShift, setOptionsSuspensionByShift] =
    useState(null);
  const [showModalSuspensions, setShowModalSuspensions] = useState(false);
  const [showSuspensionsDetailsModal, setShowSuspensionsDetailsModal] =
    useState(false);

  const [indicatorData, setIndicatorData] = useState({});
  const [indicatorMonths, setIndicatorMonths] = useState([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoadedSix, setDataLoadedSix] = useState(false);

  const [lastShiftsQuantity, setLastShiftsQuantity] = useState({
    name: "Últimos 7 plantões",
    value: 7,
  });

  const [patientIds, setPatientIds] = useState([]);
  const [lastShiftsSuspensionIds, setLastShiftSuspensionIds] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  const [averageDelay, setAverageDelay] = useState(null);
  const [averageShiftsDelay, setAverageShiftsDelay] = useState(null);

  const [period, setPeriod] = useState(6);
  const [yearMonthInterval, setYearMonthInterval] = useState({
    month: moment().format("MMMM"),
    year: moment().format("YYYY"),
  });

  const optionsaAdherenceImcSex = {
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 7,
      },
    },
    scales: {
      yAxes: [
        {
          position: "left",
          gridLines: {
            color: Colors.light_gray,
            offsetGridLines: false,
            borderDash: [6],
            lineWidth: 1,
          },
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              if (value % 1 === 0) {
                return value + "%";
              }
            },
          },
        },
      ],
    },
  };

  function LoadProtocolAdherence(intervalPeriod) {
    let dateFilter = dateInterval;
    if (intervalPeriod?.type === "adherencePeriod") {
      dateFilter = {
        start: intervalPeriod.start,
        end: intervalPeriod.end,
      };
    }
    setLoading(true);
    api
      .get(`/dashboard/protocol-adherence`, {
        params: {
          initialDate: dateFilter.start,
          finalDate: dateFilter.end,
          unitId: unit.id,
        },
      })
      .then((response) => {
        if (intervalPeriod?.type !== "adherencePeriod") {
          if (
            response.status == 200 &&
            Object.keys(response.data[0]).length > 0
          ) {
            let { off, M, F, ...shifts } = response.data[0];
            let avgMaleRateArray = [];
            let avgFemaleRateArray = [];
            let offResumeCommon = off[0];
            off = off.slice(1);
            let labels = off.map((obj) => Object.keys(obj)[0]);
            let colorM,
              colorF = Colors.transparent;
            if (M) {
              M = M.slice(1);
              avgMaleRateArray = M.map((obj) => {
                const key = Object.keys(obj)[0];
                const metaData = obj[key].metaData;
                const adhesionRate = parseFloat(metaData.adhesionRate);
                if (adhesionRate > 0) {
                  return parseFloat(metaData.adhesionRate);
                }
              });
              colorM = Colors.brand_blue;
            }
            if (F) {
              F = F.slice(1);
              avgFemaleRateArray = F.map((obj) => {
                const key = Object.keys(obj)[0];
                const metaData = obj[key].metaData;
                const adhesionRate = parseFloat(metaData.adhesionRate);
                if (adhesionRate > 0) {
                  return parseFloat(metaData.adhesionRate);
                }
              });
              colorF = Colors.brand_pink;
            }
            setProtocolAdherence({
              shiftsCommon: sortShifts(shifts),
              shifts: sortShifts(shifts),
              generalCommon: off,
              general: off,
              months: labels,
              avgTotalRate: offResumeCommon.avgTotalRate,
              totalTime: offResumeCommon.totalTime,
              totalPatients: offResumeCommon.totalPatients,
            });
            setChartProtocolAdherenceGender({
              labels: [
                "IMC Desconhecido",
                "< 18.5",
                "18.5 - 24.9",
                "25 - 29.9",
                "30+",
              ],
              datasets: [
                {
                  type: "line",
                  fill: false,
                  pointBackgroundColor: colorM,
                  borderColor: colorM,
                  pointRadius: 5,
                  pointHoverRadius: 8,
                  label: "Homens",
                  data: avgMaleRateArray,
                },
                {
                  type: "line",
                  fill: false,
                  pointBackgroundColor: colorF,
                  borderColor: colorF,
                  pointRadius: 5,
                  pointHoverRadius: 8,
                  label: "Mulheres",
                  data: avgFemaleRateArray,
                  tension: 0.1,
                },
              ],
            });
          } else {
            setProtocolAdherence(null);
            setChartProtocolAdherenceGender(null);
          }
          setLoading(false);
        } else {
          if (
            response.status == 200 &&
            Object.keys(response.data[0]).length > 0
          ) {
            let { off, M, F, ...shifts } = response.data[0];
            let offResumeCommon = off[0];
            off = off.slice(1);
            let labels = off.map((obj) => Object.keys(obj)[0]);
            function extractMetaDataProperty(array, propertyName) {
              return array.map((obj) => {
                const key = Object.keys(obj)[0];
                const metaData = obj[key].metaData;
                return metaData[propertyName];
              });
            }

            let patientsArr = extractMetaDataProperty(off, "patientsArr");
            let totalPatientsArray = extractMetaDataProperty(
              off,
              "totalPatients"
            );
            let totalRateArray = off.map((obj) => {
              const key = Object.keys(obj)[0];
              const metaData = obj[key].metaData;
              return parseFloat(metaData.adhesionRate);
            });
            if (totalRateArray.every((num) => num === 0)) {
              setFirstChartProtocolAdherence(null);
            } else {
              setFirstChartProtocolAdherence({
                labels,
                datasets: [
                  {
                    yAxisID: "aderencia",
                    label: "Aderência ao protocolo (%)",
                    type: "line",
                    fill: false,
                    backgroundColor: Colors.brand_blue,
                    borderColor: Colors.brand_blue,
                    pointBackgroundColor: Colors.brand_blue,
                    pointRadius: 5,
                    pointHoverRadius: 8,
                    data: totalRateArray,
                  },
                  {
                    yAxisID: "qntdPacientes",
                    label: "Qtd. pacientes monitorados",
                    type: "bar",
                    backgroundColor: Colors.dark_blue,
                    borderWidth: 1,
                    data: totalPatientsArray,
                    patientsArr,
                  },
                ],
              });
            }
            setProtocolAdherenceSix({
              shiftsCommon: sortShifts(shifts),
              shifts: sortShifts(shifts),
              generalCommon: off,
              general: off,
              months: labels,
              avgTotalRate: offResumeCommon.avgTotalRate,
              totalTime: offResumeCommon.totalTime,
              totalPatients: offResumeCommon.totalPatients,
            });
          } else {
            setProtocolAdherenceSix(null);
            setFirstChartProtocolAdherence(null);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function LoadSuspensions(intervalPeriod) {
    let dateFilter = dateInterval;
    if (intervalPeriod?.type === "adherencePeriod") {
      dateFilter = {
        start: intervalPeriod.start,
        end: intervalPeriod.end,
      };
    }
    setLoading(true);
    api
      .get(`/dashboard/suspensions`, {
        params: {
          initialDate: dateFilter.start,
          finalDate: dateFilter.end,
          unitId: unit.id,
        },
      })
      .then((response) => {
        if (intervalPeriod?.type !== "adherencePeriod") {
          if (
            response.data &&
            response.data[0].off &&
            response.status == 200 &&
            Object.keys(response.data[0]).length > 0 &&
            response.data[0].off.length > 0
          ) {
            let { off, lastShifts, ...motives } = response.data[0];
            let offTotal = off[0];
            const duration = moment.duration(offTotal.totalTime, "minutes");
            const hours = Math.floor(duration.asHours());
            const remainingMinutes = duration.minutes();
            offTotal.totalTime = `${hours} h ${remainingMinutes} min`;
            const shifts = [];
            for (const key in motives) {
              if (motives[key]["totalTime"] === 0) {
                motives[key]["totalTime"] = "00:00:00";
              } else if (motives[key]["totalTime"] > 0) {
                function pad(value, length) {
                  return String(value).padStart(length, "0");
                }
                const duration = moment.duration(
                  motives[key]["totalTime"],
                  "minutes"
                );
                const hours = pad(Math.floor(duration.asHours()), 2);
                const mins = pad(Math.floor(duration.minutes()), 2);
                const secs = pad(Math.floor(duration.seconds()), 2);
                motives[key]["totalTime"] = `${hours}:${mins}:${secs}`;
              }
              if (key.includes("plantao_")) {
                shifts.push({
                  [key.replace("plantao_", "Plantão ")]: motives[key],
                });
                delete motives[key];
              }
            }
            off = off.slice(1);
            const lastShiftsLabels = [];
            const lastShiftsTimeLabels = [];
            const lastShiftsDatasets = [];
            if (lastShifts.length > lastShiftsQuantity.value) {
              let numberOfElementsToRemove =
                lastShifts.length - lastShiftsQuantity.value;
              lastShifts = lastShifts.slice(numberOfElementsToRemove);
            }
            let data = new Array(lastShiftsQuantity.value).fill(0);
            for (const [index, shift] of lastShifts.entries()) {
              let randomColor =
                Object.values(Colors)[
                  Math.floor(Math.random() * Object.values(Colors).length)
                ];
              const shiftAlreadyExistInDataset = lastShiftsDatasets.find(
                (obj) =>
                  obj.label.replace(/\s+\(DT:\d+\)/gm, "") ==
                  "Plantão " + shift.shiftName
              );
              if (shiftAlreadyExistInDataset) {
                randomColor = shiftAlreadyExistInDataset.backgroundColor;
              }
              data[index] = shift.counter;
              lastShiftsLabels.push(shift.date);
              lastShiftsTimeLabels.push(shift.time);
              lastShiftsDatasets.push({
                alreadyExists: shiftAlreadyExistInDataset ? true : false,
                label: "Plantão " + shift.shiftName + ` (DT:${index + 1})`,
                backgroundColor: shift.shiftColor,
                borderWidth: 1,
                data: data.slice(),
                ids: shift.ids,
              });
              data[index] = 0;
            }
            setOptionsSuspensionByShift({
              onClick: function (evt, item) {
                if (item.length > 0) {
                  const element = item[0];
                  const index = element._index;

                  const suspensionIds = lastShiftsDatasets[index].ids;
                  setLastShiftSuspensionIds({
                    ids: suspensionIds,
                  });
                  setShowSuspensionsDetailsModal(true);
                }
              },
              onHover: function (event, chartElement) {
                event.target.style.cursor = chartElement[0]
                  ? "pointer"
                  : "default";
              },
              tooltips: {
                mode: "nearest",
                intersect: true,
                filter: function (tooltipItem, data) {
                  const dataset = data.datasets[tooltipItem.datasetIndex];
                  const currentLabel = dataset.label;
                  const currentDataValue = dataset.data[tooltipItem.index];
                  const isHighestValue = data.datasets.every((otherDataset) => {
                    const otherDataValue = otherDataset.data[tooltipItem.index];
                    return currentDataValue >= otherDataValue;
                  });
                  return isHighestValue;
                },
                callbacks: {
                  label: function (tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.index];
                    const labelText = dataset.label.replace(
                      /\s+\(DT:\d+\)/gm,
                      ""
                    );
                    return labelText + `: ${dataset.data[tooltipItem.index]}`;
                  },
                  afterLabel: function () {
                    return "";
                  },
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              elements: {
                point: {
                  radius: 7,
                },
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    ticks: {
                      callback: (value, index, values) => {
                        return [value, lastShiftsTimeLabels[index]];
                      },
                    },
                    barPercentage: 0.3,
                  },
                ],
                yAxes: [
                  {
                    position: "left",
                    gridLines: {
                      color: Colors.light_gray,
                      offsetGridLines: false,
                      borderDash: [6],
                      lineWidth: 1,
                    },
                    ticks: {
                      callback: function (value) {
                        if (Number.isInteger(value)) {
                          return value;
                        }
                      },
                    },
                  },
                ],
              },
            });
            setChartSuspensionByShift({
              labels: lastShiftsLabels,
              datasets: lastShiftsDatasets,
            });
            setSuspensions({
              general: off,
              generalSix: off,
              generalTotal: offTotal,
              shifts,
              lastShifts: lastShifts.slice(-5),
              motives: Object.entries(motives)
                .map(([key, value], index) => ({
                  ids: value.ids,
                  index,
                  name: key,
                  totalTime: value.totalTime,
                  totalSuspensions: value.totalSuspensions,
                }))
                .sort((a, b) => {
                  if (a.totalSuspensions === b.totalSuspensions) {
                    return b.totalTime.localeCompare(a.totalTime);
                  }
                  return b.totalSuspensions - a.totalSuspensions;
                }),
            });
            setLoading(false);
          } else {
            setChartSuspensionByShift(null);
            setSuspensions(null);
          }
          setDataLoaded(false);
        } else {
          if (
            response.data &&
            response.data[0].off &&
            response.status == 200 &&
            Object.keys(response.data[0]).length > 0 &&
            response.data[0].off.length > 0
          ) {
            let { off } = response.data[0];
            off = off.slice(1);
            setSuspensionsSix({
              generalSix: off,
            });
            setLoading(false);
          } else {
            setSuspensionsSix(null);
          }
        }
      });
  }

  function LoadAverageDelayedTimes() {
    setLoading(true);
    api
      .get(`/dashboard/average-delay-time`, {
        params: {
          initialDate: dateInterval.start,
          finalDate: dateInterval.end,
          unitId: unit.id,
        },
      })
      .then((response) => {
        if (response.data) {
          setAverageDelay(response.data.averageDelay);
          setAverageShiftsDelay(response.data.averageShiftsDelay);
        }
      });
  }

  function indicators(protocolAdherence, suspensions) {
    let allIndicators = {};
    const shiftDatasets = [];
    const months = protocolAdherence?.months ? protocolAdherence.months : [];
    const monitoredHours = [];
    const monitoredPatients = [];
    const monitoredSuspensions = [];
    const monitoredSuspensionTime = [];
    const generalProtocolAdherence = [];
    const shiftProtocolAdherence = [];
    const protocolGeneral = protocolAdherence?.general
      ? protocolAdherence.general
      : [];

    if (protocolAdherence?.general) {
      for (const adherenceData of protocolGeneral) {
        const month = Object.keys(adherenceData)[0];
        const metaData = adherenceData[month].metaData;
        monitoredHours.push({
          [month]: metaData.totalTime,
        });
        monitoredPatients.push({
          [month]: metaData.totalPatients,
        });
        generalProtocolAdherence.push({
          [month]: metaData.adhesionRate,
        });
      }
    }

    if (protocolAdherence?.shifts) {
      for (const shift of Object.entries(protocolAdherence.shifts)) {
        const shiftName = shift[0];
        const shiftColor = shift[1] ? shift[1][0]?.shiftColor : null;
        const shiftMonths = shift[1].slice(1);
        const shiftAdherence = [];
        for (const month of shiftMonths) {
          const monthName = Object.keys(month)[0];
          shiftAdherence.push({
            [Object.keys(month)[0]]: month[monthName].metaData.adhesionRate,
          });
        }
        shiftProtocolAdherence.push({
          [shiftName]: shiftAdherence,
          shiftColor,
        });
      }
    }

    if (suspensions) {
      for (const suspensionData of suspensions.generalSix) {
        const month = Object.keys(suspensionData)[0];
        const metaData = suspensionData[month].metaData;
        monitoredSuspensions.push({
          [month]: metaData.totalSuspensions,
        });
        let suspensionTimeFormatted;
        if (metaData.totalTime > 1440) {
          const duration = moment.duration(metaData.totalTime, "minutes");
          const hours = Math.floor(duration.asHours());
          const remainingMinutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");
          suspensionTimeFormatted = `${hours}:${remainingMinutes}:${seconds}`;
        } else {
          suspensionTimeFormatted = moment
            .utc(
              moment.duration(metaData.totalTime, "minutes").asMilliseconds()
            )
            .format("HH:mm:ss");
        }
        monitoredSuspensionTime.push({
          [month]: suspensionTimeFormatted,
        });
      }
    }

    allIndicators = {
      monitoredHours,
      monitoredPatients,
      monitoredSuspensions,
      monitoredSuspensionTime,
      shiftProtocolAdherence,
    };

    for (let key in allIndicators) {
      let indicatorKey;
      let indicatorMonths = [];
      allIndicators[key].forEach((indicator, index) => {
        const monthKey = Object.keys(indicator)[0];
        indicatorKey = monthKey;
        if (key !== "shiftProtocolAdherence") {
          if (!months.includes(monthKey)) {
            delete allIndicators[key][index];
          } else {
            if (!indicatorMonths.includes(monthKey)) {
              indicatorMonths.push(monthKey);
            }
          }
        } else {
          indicatorKey = "plantao_" + monthKey;
          allIndicators["plantao_" + monthKey] = [];
          indicator[monthKey].forEach((obj) => {
            const shiftData = Object.keys(obj);
            const objMonthKey = shiftData[0];
            const shiftColor = shiftData[1];
            if (months.includes(objMonthKey)) {
              allIndicators["plantao_" + monthKey].push({
                [objMonthKey]: parseInt(obj[objMonthKey]) + "%",
                shiftColor: indicator.shiftColor,
              });
            }
            if (!indicatorMonths.includes(objMonthKey)) {
              indicatorMonths.push(objMonthKey);
            }
          });
          let diffMonths = months.filter((x) => !indicatorMonths.includes(x));
          for (const diffMonth of diffMonths) {
            allIndicators[indicatorKey].push({
              [diffMonth]: 0 + "%",
            });
          }
        }
      });
      let diffMonths = months.filter((x) => !indicatorMonths.includes(x));
      for (const diffMonth of diffMonths) {
        allIndicators[key].push({
          [diffMonth]: 0,
        });
      }
      const uniqueKeys = new Set();
      const filteredArray = [];

      allIndicators[key].forEach((obj) => {
        const key = Object.keys(obj)[0];
        //if (!uniqueKeys.has(key)) {
        uniqueKeys.add(key);
        filteredArray.push(obj);
        //}
      });
      allIndicators[key] = filteredArray;
    }
    delete allIndicators["shiftProtocolAdherence"];
    allIndicators["avgProtocolAdherence"] = [];
    for (const general of protocolGeneral) {
      const month = Object.keys(general)[0];
      allIndicators["avgProtocolAdherence"].push({
        [month]: parseInt(general[month].metaData.adhesionRate) + "%",
      });
    }

    for (const key of Object.keys(allIndicators)) {
      let newKeyValue;
      const tempValue = allIndicators[key];
      switch (key) {
        case "monitoredPatients":
          newKeyValue = "1_Pacientes monitorados";
          break;
        case "monitoredHours":
          newKeyValue = "2_Horas de monitoramento";
          break;
        case "monitoredSuspensions":
          newKeyValue = "5_Quantidade de suspensões";
          break;
        case "monitoredSuspensionTime":
          newKeyValue = "6_Tempo de suspensão";
          break;
        case "avgProtocolAdherence":
          newKeyValue = "3_Taxa média de aderência ao protocolo geral";
          break;
        default:
          if (key.includes("plantao_")) {
            newKeyValue = "4_Plantão " + key.replace("plantao_", "");
            break;
          }
      }
      delete allIndicators[key];
      allIndicators[newKeyValue] = tempValue;
      const shiftColor = allIndicators[newKeyValue][0]?.shiftColor;
      delete allIndicators[newKeyValue][0]?.shiftColor;
      const data = [];
      if (key.includes("plantao_")) {
        allIndicators[newKeyValue].forEach((obj) => {
          delete obj.shiftColor;
          for (const key in obj) {
            data.push(parseFloat(obj[key], 10));
          }
        });
      }
      if (newKeyValue.includes("4_")) {
        //!data.every((value) => value === 0)
        if (true) {
          shiftDatasets.push({
            type: "line",
            fill: false,
            backgroundColor: shiftColor,
            borderColor: shiftColor,
            pointBackgroundColor: shiftColor,
            pointRadius: 5,
            pointHoverRadius: 8,
            label: newKeyValue.replace("4_", ""),
            data,
            fill: false,
          });
        }
      }
    }

    const sortedKeys = Object.keys(allIndicators).sort((a, b) => {
      a = parseInt(a.split("_")[0]);
      b = parseInt(b.split("_")[0]);
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    const sortedObj = sortedKeys.reduce((newObj, key) => {
      newObj[key.split("_")[1]] = allIndicators[key];
      return newObj;
    }, {});
    let nullMonths = [];
    Object.keys(sortedObj).forEach(function (key) {
      sortedObj[key].map((obj, index) => {
        if (key == "Pacientes monitorados") {
          if (Object.values(obj)[0] === 0) {
            nullMonths.push(index);
          }
        }
        if (nullMonths.includes(index)) {
          obj[Object.keys(obj)[0]] = "-";
        }
      });
    });
    setIndicatorMonths(months);
    setIndicatorData(sortedObj);
    if (shiftDatasets.length > 0) {
      setChartShiftAdherence({
        labels: months,
        datasets: shiftDatasets,
      });
    } else {
      setChartShiftAdherence(null);
    }
  }

  function handleCallback(start, end) {
    let dateStart = moment(start);
    let dateEnd = moment(end);
    if (dateEnd.isAfter(moment(), "day")) {
      dateEnd = moment();
    }
    if (dateStart.isAfter(moment(), "day")) {
      dateStart = moment().subtract(1, "day");
      dateEnd = moment();
    }
    const datesDiff = dateEnd.diff(dateStart, "days");
    let dateDiffPrefix = "";
    let dateRange = "";
    if (datesDiff >= 30) {
      let diffInMonths = Math.floor(datesDiff / 30);
      dateDiffPrefix = datesDiff > 60 ? "meses" : "mês";
      dateRange = diffInMonths + " " + dateDiffPrefix;
    } else {
      dateDiffPrefix = datesDiff > 1 ? "dias" : "dia";
      dateRange = datesDiff + " " + dateDiffPrefix;
    }
    setDateRange(dateRange);
    setDateInterval({
      start: dateStart.format("YYYY-MM-DD"),
      end: dateEnd.format("YYYY-MM-DD"),
    });
  }

  function convertSuspensionMotivesToList(input) {
    const output = [];
    for (const key in input) {
      const item = {
        ids: input[key].ids,
        name: input[key].name,
        counter: input[key].totalSuspensions,
        counterTime: input[key].totalTime,
      };
      output.push(item);
    }

    return output;
  }

  function sortShifts(obj) {
    return Object.keys(obj)
      .sort()
      .reduce((result, key) => {
        result[key] = obj[key];
        return result;
      }, {});
  }

  function handleModalPatientRisks(title, patientIds) {
    setPatientIds(patientIds);
    setSelectedData({
      label: title,
      patientQuantity: new Set(patientIds).size,
    });
    setShowPatientsRisksData(true);
  }

  useEffect(() => {
    if (
      dateInterval !== null &&
      dateInterval.start !== "" &&
      dateInterval.end !== "" &&
      dataLoadedSix &&
      !dataLoaded
    ) {
      LoadSuspensions();
      LoadProtocolAdherence();
      setDataLoaded(true);
      LoadAverageDelayedTimes();
    }
  }, [dataLoadedSix, dateInterval, unit]);

  useEffect(() => {
    LoadAverageDelayedTimes();
    setDataLoadedSix(true);
  }, [unit]);

  useEffect(() => {
    if (chartSuspensionByShift?.datasets.length > 0) {
      LoadSuspensions();
    }
  }, [lastShiftsQuantity]);

  useEffect(() => {
    if (
      protocolAdherenceSix !== null ||
      (protocolAdherenceSix &&
        Object.keys(protocolAdherenceSix).length === 0) ||
      suspensionsSix !== null
    ) {
      indicators(protocolAdherenceSix, suspensionsSix);
    } else {
      setIndicatorMonths([]);
      setIndicatorData({});
      setChartShiftAdherence(null);
    }
  }, [protocolAdherenceSix, suspensionsSix]);

  useEffect(() => {
    if (firstChartProtocolAdherence) {
      const optionsAdherence = {
        onClick: function (evt, item) {
          if (item.length > 0) {
            const index = item[1]._index;
            const patientIds =
              firstChartProtocolAdherence.datasets[1].patientsArr[index];
            if (patientIds.length > 0) {
              handleModalPatientRisks("Pacientes de risco", patientIds);
              setShowPatientsRisksData(true);
            }
            /*const ageItems = Object.keys(ageGroups).map((key) => ({
              ...ageGroups[key],
              id: key,
            }));
            const ageSelectedItem = ageItems[index];
            if (ageSelectedItem.patientIds.length > 0) {
              handleModalPatientRisks(
                "Faixa etária: " +
                  (ageSelectedItem.text === "Sem idade"
                    ? ageSelectedItem.text
                    : ageSelectedItem.text + " anos"),
                ageSelectedItem.patientIds
              );
              setShowPatientsRisksData(true);
            }*/
          }
        },
        onHover: function (event, chartElement) {
          event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 7,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              position: "right",
              type: "linear",
              scaleLabel: {
                display: true,
              },
              id: "qntdPacientes",
              gridLines: {
                color: Colors.light_gray,
                offsetGridLines: false,
                borderDash: [6],
                lineWidth: 1,
              },
              ticks: {
                beginAtZero: false,
                callback: function (value) {
                  if (Number.isInteger(value)) {
                    return value;
                  }
                },
              },
            },
            {
              stacked: false,
              position: "left",
              type: "linear",
              id: "aderencia",
              gridLines: {
                color: Colors.light_gray,
                offsetGridLines: false,
                borderDash: [6],
                lineWidth: 1,
              },
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value + "%";
                  }
                },
              },
            },
          ],
        },
        barPercentage: 0.1,
        barThickness: 0.1,
        maxBarThickness: 0.2,
      };
      setOptionsFirstChartProtocolAdherence(optionsAdherence);
    }
  }, [firstChartProtocolAdherence]);

  useEffect(() => {
    let intervalDate = moment(
      `${yearMonthInterval.month} ${yearMonthInterval.year}`,
      "MMMM YYYY"
    );
    let intervalPeriod = {
      type: "adherencePeriod",
      start: intervalDate
        .clone()
        .subtract(period, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      end: intervalDate.clone().endOf("month").format("YYYY-MM-DD"),
    };
    LoadSuspensions(intervalPeriod);
    LoadProtocolAdherence(intervalPeriod);
  }, [period, yearMonthInterval, unit]);

  return !loading ? (
    <div>
      {true && <h3>Indicadores dos últimos meses</h3>}
      <DateRangePickerMonthYear
        interval={yearMonthInterval}
        intervalHandler={setYearMonthInterval}
        period={period}
        periodHandler={setPeriod}
      />
      <Row className="mt-4 mb-4">
        {true && (
          <Col md={6} lg={6} sm={12} className="mb-report-card">
            <div className="card-report h-100">
              <h4
                className="mb-4"
                style={{
                  fontSize: "19px",
                }}
              >
                Aderência ao protocolo
              </h4>
              {loading && (
                <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}

              {!loading &&
                (firstChartProtocolAdherence &&
                optionsFirstChartProtocolAdherence ? (
                  <div>
                    <Bar
                      data={firstChartProtocolAdherence}
                      options={optionsFirstChartProtocolAdherence}
                    />
                    <div className="container-subtitle mt-4">
                      <div className="subtitle-item mr-4">
                        <div className="indicator-radius" />
                        <p>Aderência ao protocolo (%)</p>
                      </div>
                      <div className="subtitle-item">
                        <div className="indicator-square" />
                        <p>Qtd. pacientes monitorados</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="container-empty w-100 mt-3"
                    style={{
                      minHeight: "250px",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <Icon
                        color={Colors.darker_gray}
                        size={32}
                        icon="podium"
                        className="mb-3"
                      />
                      <p>Ainda não há dados sobre aderência ao protocolo</p>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
        )}
        {true && (
          <Col md={6} lg={6} sm={12} className="mb-report-card">
            <div className="card-report h-100">
              <h4
                className="mb-0"
                style={{
                  fontSize: "19px",
                }}
              >
                Aderência por plantão
              </h4>
              {!loading && chartShiftAdherence ? (
                <Col sm={12} className="mt-4">
                  <Line
                    options={optionsaAdherenceImcSex}
                    data={chartShiftAdherence}
                  ></Line>
                  <div className="container-subtitle mt-4">
                    {chartShiftAdherence.datasets.map((item, index) => {
                      return (
                        <div className="subtitle-item mr-4">
                          <div
                            className="indicator-radius"
                            style={{
                              backgroundColor: item.backgroundColor,
                            }}
                          />
                          <p>{item.label}</p>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              ) : (
                <div
                  className="container-empty w-100 mt-3"
                  style={{
                    minHeight: "250px",
                  }}
                >
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <Icon
                      color={Colors.darker_gray}
                      size={32}
                      icon="line-chart"
                      className="mb-3"
                    />
                    <p>
                      Ainda não há dados sobre aderência ao protocolo por
                      plantão
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Col>
        )}
        {true && (
          <Col xs={12}>
            <div className="card-report h-100">
              <Col>
                {!loading && indicatorMonths.length > 0 ? (
                  <Table className="borderless" responsive>
                    <thead>
                      <tr>
                        <th></th>
                        {indicatorMonths.map((item, index) => {
                          return (
                            <th
                              style={{
                                minWidth: "150px",
                                maxWidth: "150px",
                              }}
                            >
                              {item}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(indicatorData).map((key) => {
                        return (
                          <tr>
                            <td>{key}</td>
                            {indicatorData[key].map((item, index) => {
                              return <td>{item[Object.keys(item)[0]]}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div
                    className="container-empty w-100 mt-3"
                    style={{
                      minHeight: "250px",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <Icon
                        color={Colors.darker_gray}
                        size={32}
                        icon="line-chart"
                        className="mb-3"
                      />
                      <p>
                        Ainda não há indicadores referentes ao protocolo de
                        aderência
                      </p>
                    </div>
                  </div>
                )}
              </Col>
            </div>
          </Col>
        )}
        <div className="w-100 d-flex flex-wrap my-4">
          <Col xs={12} md={6} xl={3}>
            <div>
              <Form.Label className="field-label">Período</Form.Label>
              <Row className="justify-content-start align-items-center mb-3 mx-0">
                <DateRangePicker
                  initialSettings={{
                    locale: {
                      cancelLabel: "Cancelar",
                      applyLabel: "Aplicar",
                    },
                    applyButtonClasses: "btn-dr-custom",
                  }}
                  onCallback={(start, end) => handleCallback(start, end)}
                >
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#ddd",
                      minHeight: 48,
                      borderRadius: 10,
                    }}
                    variant="light"
                    className="d-flex btn-date-range align-items-center"
                  >
                    <Icon
                      color={Colors.darker_gray}
                      size={22}
                      icon="calendar"
                      className="mr-2"
                    />
                    <p className="m-0 text_e-left">
                      {dateInterval.start != ""
                        ? moment(dateInterval.start).format("DD/MM/YYYY")
                        : "Data inicial"}{" "}
                      -{" "}
                      {dateInterval.end != ""
                        ? moment(dateInterval.end).format("DD/MM/YYYY")
                        : "Data final"}
                    </p>
                  </Button>
                </DateRangePicker>
              </Row>
            </div>
          </Col>
        </div>
        {true && (
          <Col md={6} lg={6} sm={12} className="mb-report-card">
            <div className="card-report h-100">
              <h4
                className="mb-4"
                style={{
                  fontSize: "19px",
                }}
              >
                Taxa média de aderência
              </h4>
              {loading && (
                <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}

              {!loading &&
                (protocolAdherence ? (
                  <div
                    className="card-report-info"
                    style={{
                      backgroundColor: "#FFF",
                    }}
                  >
                    <Row
                      style={{
                        padding: "30px",
                      }}
                    >
                      <Col md={6} sm={12}>
                        <span className="report-value">
                          {isNaN(protocolAdherence.avgTotalRate)
                            ? 0
                            : protocolAdherence.avgTotalRate}
                          %
                        </span>
                        <span className="report-desc">
                          Taxa média de aderência
                        </span>
                      </Col>
                      <Col md={6} sm={12}>
                        <span className="report-value">
                          {protocolAdherence.totalPatients}
                        </span>
                        <span className="report-desc">
                          Pacientes monitorados
                        </span>
                      </Col>
                      <Col xl={12}>
                        <div className="border-grey-95"></div>
                        {true &&
                          (protocolAdherence.shiftsCommon &&
                          Object.keys(protocolAdherence.shiftsCommon).length >
                            0 ? (
                            <span>
                              {Object.keys(protocolAdherence.shiftsCommon).map(
                                (key) => {
                                  return (
                                    <div className="subinfo">
                                      <span className="report-desc mr-3">
                                        Plantão {key}
                                      </span>
                                      <span>
                                        {" "}
                                        {
                                          protocolAdherence.shiftsCommon[key][0]
                                            .avgTotalRate
                                        }{" "}
                                        %
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                            </span>
                          ) : (
                            <div className="subinfo">
                              <span className="mr-3">
                                Não há informações disponíveis sobre a aderência
                                de algum plantão específico.
                              </span>
                            </div>
                          ))}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div
                    className="container-empty w-100 mt-3"
                    style={{
                      minHeight: "250px",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <Icon
                        color={Colors.darker_gray}
                        size={32}
                        icon="line-chart"
                        className="mb-3"
                      />
                      <p>Ainda não há dados sobre aderência ao protocolo</p>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
        )}
        {true && (
          <Col md={6} lg={6} sm={12} className="mb-report-card">
            <div className="card-report h-100">
              <h4
                className="mb-4"
                style={{
                  fontSize: "19px",
                }}
              >
                Tempo médio de atraso
              </h4>
              {loading && (
                <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}

              {!loading &&
                (averageDelay ? (
                  <div
                    className="card-report-info"
                    style={{
                      backgroundColor: "#FFF",
                    }}
                  >
                    <Row
                      style={{
                        padding: "30px",
                      }}
                    >
                      <Col md={6} sm={12}>
                        <span className="report-value">
                          {isNaN(averageDelay)
                            ? 0
                            : secondsToHoursMinutes(averageDelay)}
                        </span>
                        <span className="report-desc">
                          Tempo médio de atraso na mudança de decúbito
                        </span>
                      </Col>
                      <Col xl={12}>
                        <div className="border-grey-95"></div>
                        {averageShiftsDelay && averageShiftsDelay.length > 0 ? (
                          <span>
                            {averageShiftsDelay.map((shiftData) => {
                              return (
                                <div className="subinfo">
                                  <span className="report-desc mr-3">
                                    {shiftData.shiftName}
                                  </span>
                                  <span>
                                    {secondsToHoursMinutes(
                                      shiftData.delayedTime
                                    )}
                                  </span>
                                </div>
                              );
                            })}
                          </span>
                        ) : (
                          <div className="subinfo">
                            <span className="mr-3">
                              Não há informações disponíveis sobre o tempo médio
                              de atraso de algum plantão específico.
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div
                    className="container-empty w-100 mt-3"
                    style={{
                      minHeight: "250px",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <Icon
                        color={Colors.darker_gray}
                        size={32}
                        icon="line-chart"
                        className="mb-3"
                      />
                      <p>Ainda não há dados sobre tempo médio de atraso</p>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
        )}
        {true && (
          <Col md={12} className="mb-report-card">
            <div className="card-report h-100">
              <h4
                className="mb-0"
                style={{
                  fontSize: "19px",
                }}
              >
                Aderência ao protocolo por sexo e IMC
              </h4>
              {!loading &&
              firstChartProtocolAdherence &&
              chartProtocolAdherenceGender ? (
                <Col sm={12} className="mt-4">
                  <Line
                    options={optionsaAdherenceImcSex}
                    data={chartProtocolAdherenceGender}
                    height={resizeBmiChartBasedOnWidth()}
                  ></Line>
                  <div className="container-subtitle mt-4">
                    <div className="subtitle-item mr-4">
                      <div
                        className="indicator-radius"
                        style={{
                          backgroundColor: "pink",
                        }}
                      />
                      <p>Mulheres</p>
                    </div>
                    <div className="subtitle-item">
                      <div className="indicator-radius" />
                      <p>Homens</p>
                    </div>
                  </div>
                </Col>
              ) : (
                <div
                  className="container-empty w-100 mt-3"
                  style={{
                    minHeight: "250px",
                  }}
                >
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <Icon
                      color={Colors.darker_gray}
                      size={32}
                      icon="line-chart"
                      className="mb-3"
                    />
                    <p>
                      Ainda não há dados sobre aderência ao protocolo por sexo e
                      IMC
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Col>
        )}
        <Col xs={12} className="mt-0 mb-report-card">
          <div className="card-report h-100">
            <div className="d-flex justify-content-between mb-3">
              <div>
                <h4
                  style={{
                    fontSize: "19px",
                  }}
                >
                  Ranking avisos suspensos
                </h4>
                <p className="subtitle-report">
                  Motivos da suspensão dos avisos
                </p>
              </div>
            </div>
            {!suspensions && (
              <div
                className="container-empty w-100 mt-3 mb-3"
                style={{
                  height: "200px",
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <Icon
                    color={Colors.darker_gray}
                    size={32}
                    icon="podium"
                    className="mb-3"
                  />
                  <p>
                    As mudanças de decúbito ainda não foram suspensas.
                    <br />
                    Os motivos das suspensões aparecerão aqui.
                  </p>
                </div>
              </div>
            )}

            {!loading &&
              suspensions?.motives &&
              suspensions.motives.length > 0 && (
                <Row>
                  <Col md={9}>
                    <>
                      <div className="mt-4">
                        <Table className="borderless" responsive>
                          <thead>
                            <tr>
                              <th>Motivo</th>
                              <th>Tempo</th>
                              <th>Qntd.</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(suspensions.motives).map(
                              (value, index) => {
                                if (index > 2) {
                                  return;
                                }
                                value = value[1];
                                return (
                                  <tr>
                                    <td>
                                      <div
                                        key={index}
                                        className="rr-item"
                                        style={{
                                          border: "none",
                                          padding: "0px",
                                        }}
                                      >
                                        <div className="d-flex align-items-center">
                                          <p className="rr-position">
                                            {index + 1}°
                                          </p>
                                          <p className="rr-title">
                                            {value.name}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>{value.totalTime}</td>
                                    <td>{value.totalSuspensions}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>

                      <div className="mt-4 mb-4 d-flex justify-content-center">
                        <button
                          className="btn-visu"
                          onClick={() => {
                            listRanking = convertSuspensionMotivesToList(
                              suspensions.motives
                            );
                            nameModal = {
                              title: "Ranking motivos",
                              subtitle: "Mudança de decúbito não realizada",
                            };
                            setShowModalSuspensions(true);
                          }}
                        >
                          Visualizar lista completa
                        </button>
                      </div>
                    </>
                  </Col>
                  <Col className="d-flex justify-content-center align-items-center">
                    <div
                      className="card-report-info"
                      style={{
                        minHeight: "250px",
                      }}
                    >
                      <Row
                        style={{
                          padding: "30px",
                        }}
                      >
                        <Col sm={12} className="mb-3">
                          <span className="report-value">
                            {suspensions.generalTotal.totalSuspensions}
                          </span>
                          <span className="report-desc">
                            Qntd. total de suspensões
                          </span>
                        </Col>
                        <Col sm={12}>
                          <span className="report-value">
                            {suspensions.generalTotal.totalTime}
                          </span>
                          <span className="report-desc">
                            Tempo total de suspensão
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
          </div>
        </Col>
        <Col sm={12}>
          <div className="card-report h-100">
            <div className="last-shifts-title">
              <h4
                className="mb-0"
                style={{
                  fontSize: "19px",
                }}
              >
                Suspensões por plantão
              </h4>
              <LastShiftsSuspensionsDropdown
                sort={lastShiftsQuantity}
                handler={setLastShiftsQuantity}
              />
            </div>
            {!loading &&
            suspensions &&
            chartSuspensionByShift &&
            chartSuspensionByShift.datasets.length > 0 ? (
              <Col sm={12}>
                <Col
                  sm={12}
                  className="mt-4"
                  style={{
                    minHeight: "400px",
                  }}
                >
                  <Bar
                    data={chartSuspensionByShift}
                    options={optionsSuspensionByShift}
                  />
                </Col>
                <div className="container-subtitle mt-4">
                  {chartSuspensionByShift.datasets.map((item, index) => {
                    if (item.alreadyExists) {
                      return;
                    }
                    return (
                      <div className="subtitle-item mr-4">
                        <div
                          className="indicator-radius"
                          style={{
                            backgroundColor: item.backgroundColor,
                          }}
                        />
                        <p>{item.label.replace(/\s+\(DT:\d+\)/gm, "")}</p>
                      </div>
                    );
                  })}
                </div>
              </Col>
            ) : (
              <div
                className="container-empty w-100 mt-3"
                style={{
                  minHeight: "250px",
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <Icon
                    color={Colors.darker_gray}
                    size={32}
                    icon="podium"
                    className="mb-3"
                  />
                  <p>Ainda não há dados sobre suspensões por plantão</p>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>

      {showPatientsRisksData && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={selectedData?.label}
          subTitle={`${selectedData?.patientQuantity} ${
            selectedData?.patientQuantity === 1 ? "paciente" : "pacientes"
          }`}
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="indicators"
        />
      )}

      {showModalSuspensions && (
        <ModalRankingDiagrama
          origin={"suspensionsRank"}
          handler_show_modal={setShowModalSuspensions}
          show_modal={showModalSuspensions}
          list={listRanking}
          nameModal={nameModal}
        />
      )}

      {showSuspensionsDetailsModal && (
        <ModalRankingSuspensionsDetails
          origin={"adherence"}
          suspension={lastShiftsSuspensionIds}
          handler_show_modal={setShowSuspensionsDetailsModal}
          show_modal={showSuspensionsDetailsModal}
        />
      )}
    </div>
  ) : (
    <div className="text-center">
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export default AderenciaProtocolo;
