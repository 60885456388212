import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import VerifyPw from "../../../../../components/Modals/VerifyPw";
import {
  GetColorAvaliation,
  userHasPermission,
  userWithoutPermission,
} from "../../../../../functions/utils";
import Icon from "../../../../../functions/Icon";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import moment from "moment";
import "./styles.css";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import CheckBox from "../../../../../components/CheckBox";

function NewCheckList({ ...props }) {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);
  const user = useSelector((state) => state.user);

  const { handler_show_modal, show_modal, handler_reload, reload } = props;

  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");

  const [listUsers, setListUsers] = useState([]);
  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });
  const [showPw, setShowPw] = useState(false);

  //Send
  const [prescriptions, setPrescriptions] = useState([]);

  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    function LoadPrescriptionsPatient() {
      api
        .get(`patients/${patientSelected.dados.id}/prescriptions?checklist=1`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPrescriptionsPatient success");
            console.log(response.data);

            var newList = response.data.map((item) => {
              item.is_checked = 0;
              return item;
            });
            setPrescriptions(newList);
          }
        })
        .catch((error) => {
          console.log("LoadPrescriptionsPatient error " + error);
        })
        .finally(() => setLoading(false));
    }

    LoadPrescriptionsPatient();
  }, []);

  useEffect(() => {
    if (prescriptions.length > 0) {
      var found = prescriptions.find((item) => item.is_checked == 0);
      if (found == undefined) setCheckAll(true);
    }
  }, [prescriptions]);

  useEffect(() => {
    //Se permission_id for 1,2 ou 3 seta quem ta logado direto no responsavel
    if (userHasPermission(user)) {
      setUserChooser({ label: user.dados.name, value: user.dados.id });
    }
  }, []);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: patientSelected.dados.unit_id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userWithoutPermission(user)) {
      LoadUsers();
    }
  }, []);

  function CheckAll() {
    var newList = prescriptions.map((item) => {
      item.is_checked = checkAll == false ? 1 : 0;
      return item;
    });

    setCheckAll(!checkAll);
    setPrescriptions(newList);
  }

  function VerifyFields() {
    var ret = false;
    var found = prescriptions.find((item) => item.is_checked == 1);
    if (userChooser.value > 0 && found != undefined) return true;
    return ret;
  }

  function SetarPrescription(obj) {
    var newList = prescriptions.map((item) => {
      if (item.id == obj.id) {
        item.is_checked = item.is_checked == 1 ? 0 : 1;
      }
      return item;
    });
    setCheckAll(false);
    setPrescriptions(newList);
  }

  function RegisterCheckList() {
    var arrayPrescription = [];
    prescriptions.map((item) => {
      arrayPrescription.push({
        value_prescription_id: item.id,
        value: item.value,
        is_checked: item.is_checked,
      });
    });

    setLoadingBtn("flex");
    api
      .post(`/patients/${patientSelected.dados.id}/checklists`, {
        user_id: userChooser.value,
        prescriptions: arrayPrescription,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("RegisterCheckList success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "Checagem realizada",
            })
          );

          handler_reload(!reload);
          handler_show_modal(false);
        }
      })
      .catch((error) => {
        console.log("RegisterCheckList error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível realizar a checagem.",
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        {showPw && (
          <VerifyPw
            handler_show_pw={setShowPw}
            show_pw={showPw}
            handler_user={setUserChooser}
            id_user={userChooser.value}
          />
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Nova checagem</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        {!loading && prescriptions.length == 0 && (
          <div className="container-error-default mt-4">
            <p>Preencha a prescrição antes de realizar o checklist.</p>
          </div>
        )}

        <div className="modal-all mt-4">
          {!loading && userWithoutPermission(user) && prescriptions.length > 0 && (
            <div className="mb-4">
              <Form.Label className="field-label">Responsável</Form.Label>
              <Select
                captureMenuScroll={false}
                classNamePrefix="react-select"
                placeholder={"Selecionar"}
                options={listUsers}
                isClearable={false}
                value={userChooser}
                onChange={(item) => {
                  setUserChooser(item);
                  setShowPw(true);
                }}
              />
            </div>
          )}

          {!loading && prescriptions.length > 0 && (
            <div className="modal-item" onClick={() => CheckAll()}>
              <p
                style={{
                  width: "90%",
                  color:
                    checkAll == true ? Colors.brand_blue : Colors.darkest_gray,
                }}
                className="modal-name mb-0"
              >
                {checkAll == true ? "Desmarcar todos" : "Selecionar todos"}
              </p>

              <CheckBox checked={checkAll} onclick={CheckAll} />
            </div>
          )}

          {prescriptions.map((item, index) => {
            return (
              <div key={index} className="modal-item">
                <p
                  onClick={() => SetarPrescription(item)}
                  style={{
                    width: "90%",
                    color:
                      item.is_checked == 1
                        ? Colors.brand_blue
                        : Colors.darkest_gray,
                  }}
                  className="modal-name mb-0"
                >
                  {item.value}
                </p>

                <CheckBox
                  checked={item.is_checked == 1}
                  value={item}
                  onclick={SetarPrescription}
                />
              </div>
            );
          })}
        </div>
      </Modal.Body>

      {!loading && prescriptions.length > 0 && (
        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <div className="d-flex w-100  justify-content-end align-items-end">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <button
                  className="modal-footer-btn mr-3 btn-cancel"
                  onClick={() => handler_show_modal(false)}
                  style={{ maxWidth: 125 }}
                >
                  Cancelar
                </button>
                <button
                  disabled={
                    (VerifyFields() == true ? false : true) ||
                    loadingBtn === "flex"
                  }
                  className="mb-0 modal-footer-btn btn-confirm btn-save-check-list"
                  style={{
                    backgroundColor:
                      VerifyFields() == true
                        ? Colors.brand_pink
                        : Colors.lighter_gray,
                  }}
                  onClick={() => RegisterCheckList()}
                >
                  <p
                    className="mb-0"
                    style={{
                      color:
                        VerifyFields() == true ? "white" : Colors.dark_gray,
                      display: loadingBtn == "none" ? "flex" : "none",
                    }}
                  >
                    Salvar
                  </p>

                  <Spinner
                    style={{ display: loadingBtn }}
                    animation="border"
                    role="status"
                    variant="light"
                    size="sm"
                  />
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default NewCheckList;
