import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import Colors from "../../../styles/Colors";
import {
  CalculateArea,
  resizeChartBasedOnWidth,
} from "../../../functions/utils";
import moment from "moment";
import EmptyContainer from "../../Relatorios/EmptyContainer";

function Size(props) {
  const { historicWounds } = props;
  const [area, setArea] = useState(null);
  const [depth, setDepth] = useState(null);

  useEffect(() => {
    if (historicWounds) {
      var labels = [];
      let data = [];
      let dataIsEmpty = true;
      let dataDepth = [];
      let dataDepthIsEmpty = true;
      historicWounds.map((i) => {
        let date;
        if (i.date_realized !== undefined) {
          date = moment(i.date_realized).format("DD/MM");
        } else if (
          i.date_confirmed !== undefined &&
          i.date_confirmed != "Invalid date"
        ) {
          date = moment(i.date_confirmed).format("DD/MM");
        } else {
          date = moment(i.date_notification).format("DD/MM");
        }
        labels.push(date);
        const area = CalculateArea(
          i.width.replace(",", "."),
          i.height.replace(",", ".")
        );
        const depth = parseFloat(i.depth.replace(",", "."));
        if (area > 0) {
          dataIsEmpty = false;
        }
        if (!isNaN(depth)) {
          dataDepthIsEmpty = false;
        }
        data.push(area);
        dataDepth.push(depth);
      });
      setArea(
        dataIsEmpty
          ? null
          : {
              labels: labels,
              datasets: [
                {
                  label: "Área (cm²)",
                  data: data,
                  tension: 0,
                  fill: false,
                  backgroundColor: Colors.brand_blue,
                  borderColor: Colors.brand_blue,
                  borderWidth: 3,
                  pointBackgroundColor: Colors.brand_blue,
                  pointRadius: 5,
                  pointHoverRadius: 6,
                },
              ],
            }
      );
      setDepth(
        dataDepthIsEmpty
          ? null
          : {
              labels: labels,
              datasets: [
                {
                  label: "Profundidade (cm)",
                  data: dataDepth,
                  tension: 0,
                  fill: false,
                  backgroundColor: Colors.brand_blue,
                  borderColor: Colors.brand_blue,
                  borderWidth: 3,
                  pointBackgroundColor: Colors.brand_blue,
                  pointRadius: 5,
                  pointHoverRadius: 6,
                },
              ],
            }
      );
    }
  }, [historicWounds]);

  const optionsLine = {
    maintainAspectRatio: false,
    bezierCurve: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: Colors.light_gray,
            offsetGridLines: false,
            borderDash: [6],
            lineWidth: 1,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
      labels: {
        fontColor: Colors.darker_gray,
        fontFamily: "Rubik-Regular",
      },
    },
  };
  return (
    <>
      <h3 className="page-description mt-4 mb-4">Tamanho</h3>
      <Row>
        <Col md={12} lg={6} sm={12} xs={12} xl={6}>
          <div className="card-report">
            <h4 className="mb-0">Área (cm²)</h4>
            {area ? (
              <div className="mt-4">
                <div class="chartContainer">
                  <div
                    style={{
                      width:
                        area?.datasets[0].data.length > 7
                          ? Math.max(
                              area?.labels.length * 50,
                              window.innerWidth
                            )
                          : null,
                    }}
                  >
                    <Line
                      height={resizeChartBasedOnWidth()}
                      data={area}
                      options={optionsLine}
                      height={300}
                      width={100}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <EmptyContainer
                text="Ainda não há dados sobre a área da lesão"
                iconName="line-chart"
              />
            )}
          </div>
        </Col>
        <Col md={12} lg={6} sm={12} xs={12} xl={6}>
          <div className="card-report h-100">
            <h4 className="mb-0">Profundidade (cm)</h4>
            {depth ? (
              <div className="mt-4">
                <div class="chartContainer">
                  <div
                    style={{
                      width:
                        depth?.datasets[0].data.length > 7
                          ? Math.max(
                              depth?.labels.length * 50,
                              window.innerWidth
                            )
                          : null,
                    }}
                  >
                    <Line
                      height={resizeChartBasedOnWidth()}
                      data={depth}
                      options={optionsLine}
                      height={300}
                      width={100}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <EmptyContainer
                text="Ainda não há dados sobre a profundidade da lesão"
                iconName="line-chart"
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Size;
