const INITIAL_STATE = {
  headers: [],
};

function generalDataHeaders(state = INITIAL_STATE, action) {
  if (action.type === "SET_GENERAL_DATA_HEADERS") {
    return { ...state, headers: action.headers };
  } else if (action.type === "RESET_GENERAL_DATA_HEADERS") {
    state = INITIAL_STATE;
  }

  return state;
}

export default generalDataHeaders;
