import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Dropdown,
  Table,
} from "react-bootstrap";
import { Line } from "react-chartjs-2";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import api from "../../../../../../services/api";
import moment from "moment";
import { Show_Alert } from "../../../../../../store/actions/alert";
import "./styles.css";
import Colors from "../../../../../../styles/Colors";
import Icon from "../../../../../../functions/Icon";
import DetailsAvaliation from "../../AvaliacaoRiscoPaciente/DetailsAvaliation"; //Usando Modal da tab avaliacao pq é igual

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  legend: {
    display: false,
    labels: {
      fontColor: Colors.darker_gray,
      fontFamily: "Rubik-Regular",
    },
  },
};

var avaliationObj = {};

function RelatorioAvaliacao() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [lastAvaliations, setLastAvaliations] = useState([]);
  const [date_start, setDate_start] = useState("");
  const [date_end, setDate_end] = useState("");
  const [listAvaliations, setListAvaliations] = useState([]);
  const [showAvaliation, setShowAvaliation] = useState(false);

  function handleCallback(start, end) {
    setDate_start(moment(start).format("YYYY-MM-DD"));
    setDate_end(moment(end).format("YYYY-MM-DD"));
  }

  useEffect(() => {
    function LoadLastAvaliations() {
      api
        .get(`/patients/${patientSelected.dados.id}/last-avaliations`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadLastAvaliation grafic");
            console.log(response.data);

            var labelsAvaliations = [];
            var dataAvaliations = [];
            response.data.map((item) => {
              labelsAvaliations.push(moment(item.created_at).format("DD/MM"));
              dataAvaliations.push(item.result);
            });

            var obj = {
              labels: labelsAvaliations,
              datasets: [
                {
                  data: dataAvaliations,
                  fill: false,
                  backgroundColor: Colors.brand_blue,
                  borderColor: Colors.brand_blue,
                },
              ],
            };

            setLastAvaliations(obj);
          }
        })
        .catch((error) => {
          console.log("LoadLastAvaliations erro " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar o grafico.",
            })
          );
        });
    }

    LoadLastAvaliations();
  }, []);

  useEffect(() => {
    function LoadAvaliations() {
      let date_start_convert = "";
      let date_end_convert = "";
      if (date_start != "") {
        date_start_convert = moment(date_start).format("YYYY-MM-DD");
      }
      if (date_end != "") {
        date_end_convert = moment(date_end).format("YYYY-MM-DD");
      }

      api
        .get(`/patients/${patientSelected.dados.id}/avaliations`, {
          params: {
            date_start: date_start_convert,
            date_end: date_end_convert,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadAvaliations");
            console.log(response.data);

            setListAvaliations(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadAvaliations erro " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível o histórico de avaliações desse paciente.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadAvaliations();
  }, [date_end]);

  return (
    <div>
      {showAvaliation && (
        <DetailsAvaliation
          handler_show_modal={setShowAvaliation}
          show_modal={showAvaliation}
          avaliation={avaliationObj}
        />
      )}
      <div>
        <h4 className="status">Escore / Data da avaliação</h4>
        <div className="mt-3">
          <Line height={86} data={lastAvaliations} options={options} />
        </div>
      </div>

      <div className="mt-4">
        <h4 className="status">Histórico de avaliações</h4>
        <div className="mt-3">
          <Col xs={12} md={8} xl={5}>
            <Form.Label className="field-label">Período</Form.Label>
            <Row className="justify-content-start align-items-center mb-3 mx-0">
              <DateRangePicker
                initialSettings={{
                  locale: {
                    cancelLabel: "Cancelar",
                    applyLabel: "Aplicar",
                  },
                  drops: "up",
                  applyButtonClasses: "btn-dr-custom",
                }}
                onCallback={(start, end) => handleCallback(start, end)}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#ddd",
                    minHeight: 48,
                    borderRadius: 10,
                  }}
                  variant="light"
                  className="d-flex btn-date-range align-items-center"
                >
                  <Icon
                    color={Colors.darker_gray}
                    size={22}
                    icon="calendar"
                    className="mr-2"
                  />
                  <p className="m-0 text_e-left">
                    {date_start != ""
                      ? moment(date_start).format("DD/MM/YYYY")
                      : "Data inicial"}{" "}
                    -{" "}
                    {date_end != ""
                      ? moment(date_end).format("DD/MM/YYYY")
                      : "Data final"}
                  </p>
                </Button>
              </DateRangePicker>
            </Row>
          </Col>
        </div>
      </div>

      {loading && (
        <div className="mt-5 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && listAvaliations.length == 0 && (
        <div className="container-error-default mt-5">
          <p>Não foram encontradas avaliações para esse paciente.</p>
        </div>
      )}

      {listAvaliations.length > 0 && (
        <div className="mt-2">
          <Table className="patient-table" responsive hover>
            <thead>
              <tr>
                <th>Escore</th>
                <th>Risco</th>
                <th>Data</th>
                <th>Responsável</th>
              </tr>
            </thead>
            <tbody>
              {listAvaliations.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      avaliationObj = item;
                      setShowAvaliation(true);
                    }}
                  >
                    <td>{item.result}</td>
                    <td>{item.name_result}</td>
                    <td>{moment(item.created_at).format("LLL")}</td>
                    <td>{item.user.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default RelatorioAvaliacao;
