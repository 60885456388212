import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import api from "../../../../../../../../services/api";
import { Show_Alert } from "../../../../../../../../store/actions/alert";
import Icon from "../../../../../../../../functions/Icon";
import Colors from "../../../../../../../../styles/Colors";
import { HexToRgbA } from "../../../../../../../../functions/utils";
import moment from "moment";

export function makeUserDataToPhoto(name, image) {
  return {
    dados: {
      name: name,
      image: image ? image : null,
      url_image: image ? image : null,
    },
  };
}

function ModalDetalhado({ ...props }) {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const { handler_show_modal, show, dados } = props;

  console.log("DADOS", dados);

  const renderPhoto = (user, size = null) => {
    if (user && user.dados && user.dados.image != null)
      return (
        <img
          src={user.dados.url_image}
          className={`c-profile-dropdown__img ${
            size && size === "lg" ? "is-lg" : ""
          }`.trim()}
        />
      );

    let name;

    if (user.dados.name.split(" ").length > 1) {
      name = user.dados.name
        .split(" ")
        .reduce((p, c) => `${p.charAt(0)}${c.charAt(0)}`);
    } else {
      name = user.dados.name.substring(0, 2).toUpperCase();
    }

    return (
      <span
        className={`c-profile-dropdown__initial ${
          size && size === "lg" ? "is-lg" : ""
        }`.trim()}
      >
        {name}
      </span>
    );
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={() => handler_show_modal(false)}
        centered
        className="modal-decubito"
      >
        <Modal.Body
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            paddingTop: 5,
            paddingBottom: 15,
          }}
        >
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Col xs={6} md={6} lg={6} className="pl-0">
              <div
                className="status-tag"
                style={{
                  backgroundColor: Colors.dark_gray,
                }}
              >
                AVISO SUSPENSO
              </div>
            </Col>

            <Col
              xs={2}
              md={6}
              lg={6}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <Row className="mb-3 align-items-center">
            <p className="modal-title-sm">Detalhes da suspensão</p>
          </Row>

          <Row className="mb-2 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="calendar"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {moment(dados.created_at).format("LL")}
            </div>
          </Row>

          <Row className="mb-2 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="clock"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {moment(dados.created_at).format("LT")}
            </div>
          </Row>

          <Row className="mb-2 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="clock-arrow-up"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {dados.text.includes("INDETERMINATE")
                ? "Suspendeu por tempo indeterminado."
                : dados.text}
            </div>
          </Row>

          <Row className="mb-3 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="refresh"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {dados.notification.position.name}
            </div>
          </Row>

          <Row className="patients-notifications-suspends__motive flex-column mt-4">
            <h2 className="title-motive">Motivo</h2>
            {dados.notificationMotive.other_motive != null && (
              <p className="text-motive">Outros</p>
            )}
            <p className="text-motive">
              {dados.notificationMotive.other_motive != null
                ? dados.notificationMotive.other_motive
                : dados.notificationMotive.motive.name}
            </p>
          </Row>
          {/* {renderNurses()} */}
        </Modal.Body>
        <div className="px-4 pb-2 mt-3">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div className="c-profile-dropdown mr-2">
              {renderPhoto(makeUserDataToPhoto(dados.user.name, null))}
            </div>

            <div className="dados-default">
              <div className="name-default">Por {dados.user.name}</div>
              <div className="date-default">
                {moment(dados.created_at).format("LLL")}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalDetalhado;
