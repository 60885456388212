import React, { useEffect, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import EmptyContainer from "./EmptyContainer";
import ModalPatientsRisks from "./ModalData/ModalPatientsRisks";
import { resizeChartBasedOnWidth } from "../../functions/utils";

function BodyMassIndex(props) {
  const { bodyMassIndexes } = props;

  const [bmi, setBmi] = useState(null);
  const [patientIds, setPatientIds] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  useEffect(() => {
    if (bodyMassIndexes) {
      const arrayImcValue = [];
      const arrayImcLabel = [];
      var imcEmptyVerify = true;

      if (
        bodyMassIndexes.low.counter > 0 ||
        bodyMassIndexes.appropriate.counter > 0 ||
        bodyMassIndexes.overweight.counter > 0 ||
        bodyMassIndexes.obesity.counter
      ) {
        imcEmptyVerify = false;
      }

      for (const key in bodyMassIndexes) {
        if (bodyMassIndexes.hasOwnProperty(key)) {
          arrayImcValue.push(bodyMassIndexes[key].counter);
          arrayImcLabel.push(bodyMassIndexes[key].text);
        }
      }

      const objImc = {
        labels: arrayImcLabel,
        datasets: [
          {
            label: "Quant. de pacientes",
            data: arrayImcValue,
            borderWidth: 0,
            backgroundColor: arrayImcLabel.map(() => Colors.dark_blue),
          },
        ],
      };
      setBmi(imcEmptyVerify ? null : objImc);
    }
  }, [bodyMassIndexes]);

  const optionsImc = {
    onClick: function (evt, item) {
      if (item.length > 0) {
        const index = item[0]._index;
        const datasetIndex = item[0]._datasetIndex;
        const bmiItems = Object.keys(bodyMassIndexes).map((key) => ({
          ...bodyMassIndexes[key],
          id: key,
        }));
        const bmiSelectedItem = bmiItems[index];
        if (bmiSelectedItem.patientIds.length > 0) {
          handleModalPatientRisks(
            "IMC: " + bmiSelectedItem.text,
            bmiSelectedItem.patientIds
          );
          setShowPatientsRisksData(true);
        }
      }
    },
    onHover: function (event, chartElement) {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              if (value % 1 === 0) {
                return value;
              }
            },
          },
        },
      ],
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: Colors.darker_gray,
        fontFamily: "Rubik-Regular",
      },
    },
  };

  function handleModalPatientRisks(title, patientIds) {
    setPatientIds(patientIds);
    setSelectedData({
      label: title,
      patientQuantity: patientIds.length,
    });
    setShowPatientsRisksData(true);
  }

  return (
    <div className="body-mass-indexes h-100">
      <h4>Índice de massa corporal</h4>

      {bmi ? (
        <div className="mt-3">
          <HorizontalBar
            data={bmi}
            options={optionsImc}
            height={resizeChartBasedOnWidth()}
          />
        </div>
      ) : (
        <EmptyContainer
          text="Ainda não há dados sobre o IMC dos pacientes"
          iconName="description_1"
        />
      )}
      {showPatientsRisksData && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={selectedData?.label}
          subTitle={`${selectedData?.patientQuantity} ${
            selectedData?.patientQuantity === 1 ? "paciente" : "pacientes"
          }`}
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Fatores de risco",
            "Avaliação de risco",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="populationalData"
        />
      )}
    </div>
  );
}

export default BodyMassIndex;
