import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import Colors from "../../../styles/Colors";
import { resizeChartBasedOnWidth } from "../../../functions/utils";
import ModalIndicatorDetails from "../ModalInfo/ModalIndicatorDetails";

function LineDotted(props) {
  const { labels, datasets, details } = props;

  const [monthDetails, setMonthDetails] = useState(false);
  const [showModalDetails, setShowModalDetails] = useState(false);

  const data = {
    labels,
    datasets,
  };

  const options = {
    onClick: (e, element) => {
      if (element.length > 0) {
        setMonthDetails(details[element[0]._index]);
        setShowModalDetails(true);
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
      labels: {
        fontColor: Colors.darker_gray,
        fontFamily: "Rubik-Regular",
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} height={resizeChartBasedOnWidth()} />
      {showModalDetails && (
        <ModalIndicatorDetails
          show_modal={showModalDetails}
          handler_show_modal={setShowModalDetails}
          details={monthDetails}
        />
      )}
    </div>
  );
}

export default LineDotted;
