import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Spinner } from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import TwoBarVertically from "./Charts/TwoBarVertically";
import EmptyContainer from "./EmptyContainer";
import DensityDropdown from "../../components/Dropdowns/DensityDropdown";
import LineDotted from "./Charts/LineDotted";
import InformationButton from "./InformationButton";
import ModalDensity from "./ModalInfo/ModalDensity";
import ModalIncidence from "./ModalInfo/ModalIncidence";
import ModalGoal from "../../components/Modals/ModalGoal";
import api from "../../services/api";
import moment from "moment";
import ModalPatientsPerDay from "../../components/Modals/ModalPatientsPerDay";
import ModalReportedWound from "./ModalInfo/ModalReportedWound";
import ModalPatientsRisks from "./ModalData/ModalPatientsRisks";
import LastMonthsPeriodDropdown from "../../components/Dropdowns/LastMonthsPeriodDropdown";
import DateRangePickerMonthYear from "../../components/DateRangePickerMonthYear";
import Axios from "axios";

function Indicators(props) {
  const unit = useSelector((state) => state.unit);

  const currentDate = moment();

  const [firstLoadDone, setFirstLoadDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [reload, handlerReload] = useState(false);
  const [reloadAll, handlerReloadAll] = useState(false);

  const [showModalReportedWound, setShowModalReportedWound] = useState(false);
  const [showModalIncidenceInfo, setShowModalIncidenceInfo] = useState(false);
  const [showModalDensityInfo, setShowModalDensityInfo] = useState(false);
  const [showModalGoal, setShowModalGoal] = useState(false);
  const [showModalPatientsPerDay, setShowModalPatientsPerDay] = useState(false);

  const [type, setType] = useState("incidence");

  const [densityDropdownSort, setDensityDropdownSort] = useState({
    name: "paciente",
    value: "patient",
  });
  const [daysWithoutWound, setDaysWithoutWound] = useState("-");
  const [recordDaysWithoutWound, setRecordDaysWithoutWound] = useState("-");
  const [actualIncidenceTarget, setActualIncidenceTarget] = useState(0);
  const [actualDensityTarget, setActualDensityTarget] = useState(0);
  const [patientsPerDay, setPatientsPerDay] = useState(null);
  const [patientsPerDayPeriod, setPatientsPerDayPeriod] = useState(null);
  const [patientsPerDayGeneral, setPatientsPerDayGeneral] = useState(null);

  const [patientsWithPressureWounds, setPatientsWithPressureWounds] = useState(
    []
  );
  const [
    patientsWithIncidencePressureWounds,
    setPatientsWithIncidencePressureWounds,
  ] = useState([]);
  const [patientsWithRisk, setPatientsWithRisk] = useState([]);
  const [generalPatients, setGeneralPatients] = useState([]);
  const [pressureWounds, setPressureWounds] = useState([]);
  const [incidenceWoundIncidence, setIncidenceWoundIncidence] = useState(0);
  const [incidenceWoundDensity, setIncidenceWoundDensity] = useState(0);

  const [monthDirection, setMonthDirection] = useState(null);
  const [actualMonthTab, setActualMonthTab] = useState(currentDate.month() + 1);
  const [actualMonthAndYear, setActualMonthAndYear] = useState({
    monthName: currentDate.format("MMMM"),
    month: currentDate.month() + 1,
    year: currentDate.year(),
  });

  const [monthsData, setMonthsData] = useState(null);
  const [sixMonthsDensityChart, setSixMonthsDensityChart] = useState(null);
  const [sixMonthsIncidenceChart, setSixMonthsIncidenceChart] = useState(null);
  const [
    sixMonthsRiskPatientsAndPatientsWithWoundsChart,
    setSixMonthsRiskPatientsAndPatientsWithWoundsChart,
  ] = useState(null);
  const [sixMonthsWoundTypesChart, setSixMonthsWoundTypesChart] =
    useState(null);

  const [title, setTitle] = useState("Pacientes de risco");
  const [patientIds, setPatientIds] = useState(null);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  const [yearMonthInterval, setYearMonthInterval] = useState({
    month: moment().format("MMMM"),
    year: moment().format("YYYY"),
  });
  const [period, setPeriod] = useState(6);

  useEffect(() => {
    if (unit.selecionado?.id != undefined) {
      setLoading(true);
      setLoadingChart(true);
      LoadIndicators(
        false,
        actualMonthAndYear.month + (firstLoadDone ? 1 : 0),
        actualMonthAndYear.year
      );
      LoadPatientsPerDay();
      LoadWoundReportMetrics();
      LoadChartData();
    }
  }, [unit, reloadAll]);

  useEffect(() => {
    if (unit.selecionado?.id != undefined && firstLoadDone) {
      setLoading(true);
      LoadIndicators(
        false,
        actualMonthAndYear.month + 1,
        actualMonthAndYear.year
      );
      LoadWoundReportMetrics();
    }
  }, [reload]);

  useEffect(() => {
    if (unit.selecionado?.id != undefined && firstLoadDone) {
      setLoading(true);
      if (actualMonthAndYear.month >= 0 && actualMonthAndYear.month <= 11) {
        LoadWoundReportMetrics();
        LoadIndicators(
          false,
          actualMonthAndYear.month + 1,
          actualMonthAndYear.year
        );
      }
    }
  }, [actualMonthAndYear]);

  useEffect(() => {
    recalculateDensityChart();
  }, [monthsData, patientsPerDayPeriod]);

  function recalculateDensityChart() {
    if (monthsData) {
      const monthDensityCounts = [];
      const monthDensityDetails = [];
      for (const monthData of monthsData) {
        const patientsPerDayData = patientsPerDayPeriod.find(
          (i) => i.monthAbb == monthData.monthAbb
        );

        let denominator = monthData.generalPatients.length;
        if (patientsPerDayData && patientsPerDayData.patientsPerDay) {
          denominator = patientsPerDayData.patientsPerDay;
        }

        let numerator =
          densityDropdownSort.value == "patient"
            ? monthData.patientsWithIncidencePressureWoundsInCurrentMonth.length
            : monthData.pressureWoundsIncidence.length;

        let densityValue = 0;
        if (denominator == 0 && numerator == 0) {
          densityValue = null;
        } else {
          densityValue = (numerator / denominator) * 1000;
          densityValue = isNaN(densityValue)
            ? 0
            : parseFloat(densityValue.toFixed(2));
        }
        monthDensityCounts.push(densityValue);
        monthDensityDetails.push({
          title: `Densidade por ${densityDropdownSort.name} (‰)`,
          firstValue: numerator,
          firstDescription:
            densityDropdownSort.value == "patient"
              ? "Pacientes com LP Incididas"
              : "Pacientes com lesão por pressão",
          secondValue: denominator,
          secondDescription: "Pacientes do dia",
          operation: "÷",
          resultValue: densityValue,
          resultDescription: "Densidade da lesão",
          month: monthData.monthAbb,
        });
      }
      if (
        !monthDensityCounts.every(
          (element) => element === 0 || element === null
        )
      ) {
        setSixMonthsDensityChart({
          labels: monthsData.map((i) => i.monthAbb),
          data: monthDensityCounts,
          details: monthDensityDetails,
        });
      } else {
        setSixMonthsDensityChart(null);
      }
    }
  }

  function LoadChartData() {
    let intervalDate = moment(
      `${yearMonthInterval.month} ${yearMonthInterval.year}`,
      "MMMM YYYY"
    );
    let intervalPeriod = {
      start: intervalDate
        .clone()
        .subtract(period, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      end: intervalDate.clone().endOf("month").format("YYYY-MM-DD"),
    };
    setLoadingChart(true);
    LoadIndicators(true, null, null, intervalPeriod);
  }

  useEffect(() => {
    if (actualMonthTab < 1) {
      let newDate = moment()
        .month(11)
        .year(actualMonthAndYear.year - 1);
      setActualMonthAndYear({
        monthName: newDate.format("MMMM"),
        month: newDate.month() + 1,
        year: newDate.year(),
      });
      setActualMonthTab(12);
    } else if (actualMonthTab > 12) {
      let newDate = moment()
        .month(0)
        .year(actualMonthAndYear.year + 1);
      setActualMonthAndYear({
        monthName: newDate.format("MMMM"),
        month: newDate.month() - 1,
        year: newDate.year(),
      });
      setActualMonthTab(1);
    } else {
      let newMonth =
        actualMonthAndYear.month + (monthDirection == "next" ? 1 : -1);
      let newDate = moment().month(newMonth).year(actualMonthAndYear.year);
      setActualMonthAndYear({
        monthName: newDate.format("MMMM"),
        month: newDate.month(),
        year: newDate.year(),
      });
    }
  }, [actualMonthTab]);

  useEffect(() => {
    setPatientsPerDayGeneral(
      patientsPerDay == null ? generalPatients.length : patientsPerDay
    );
  }, [patientsPerDay, generalPatients]);

  useEffect(() => {
    if (
      (patientsPerDayGeneral != null,
      patientsPerDayGeneral > 0,
      patientsPerDayPeriod)
    ) {
      if (densityDropdownSort.value == "patient") {
        setIncidenceWoundDensity(
          (patientsWithIncidencePressureWounds.length / patientsPerDayGeneral) *
            1000
        );
      } else if (densityDropdownSort.value == "woundPressure") {
        setIncidenceWoundDensity(
          (pressureWounds.length / patientsPerDayGeneral) * 1000
        );
      }
      recalculateDensityChart();
    }
  }, [
    pressureWounds,
    densityDropdownSort,
    patientsPerDayPeriod,
    patientsPerDayGeneral,
    patientsWithIncidencePressureWounds,
  ]);

  useEffect(() => {
    LoadChartData();
  }, [period, yearMonthInterval]);

  function LoadWoundReportMetrics() {
    api
      .get("/dashboard/wound-report-metrics", {
        params: {
          unitId: unit.selecionado.id,
          year: actualMonthAndYear.year,
          month: actualMonthAndYear.month + (firstLoadDone ? 1 : 0),
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setPatientsPerDay(response.data.patientsPerDay);
          setDaysWithoutWound(response.data.lastIncidenceWoundDaysDiffFromNow);
          setRecordDaysWithoutWound(response.data.recordDaysWithoutWound);
          setActualIncidenceTarget(response.data.incidenceTarget || 0);
          setActualDensityTarget(response.data.densityTarget || 0);
        }
      });
  }

  function LoadPatientsPerDay() {
    api
      .get("/dashboard/wound-report-metrics/patients-per-day", {
        params: {
          unitId: unit.selecionado.id,
          period: 6,
          periodType: "month",
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setPatientsPerDayPeriod(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const cancelTokenRef = useRef();

  function LoadIndicators(isChart, month, year, period) {
    let isChartCancelled = false;
    if (isChart) {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel(
          "Operação cancelada devido a uma nova solicitação."
        );
      }
      cancelTokenRef.current = Axios.CancelToken.source();
      isChartCancelled = true;
      setLoadingChart(true);
    }
    api
      .get("/dashboard/indicators", {
        params: {
          unitId: unit.selecionado.id,
          initialDate: period?.start,
          finalDate: period?.end,
          month,
          year,
        },
        cancelToken: isChart ? cancelTokenRef.current.token : null,
      })
      .then((response) => {
        if (response.status == 200) {
          if (!isChart) {
            setPatientsWithPressureWounds(
              response.data.patientsWithPressureWounds
            );
            setPatientsWithIncidencePressureWounds(
              response.data.patientsWithIncidencePressureWoundsInCurrentMonth
            );
            setPatientsWithRisk(response.data.patientsWithRisk);
            setGeneralPatients(response.data.generalPatients);
            setPressureWounds(response.data.pressureWoundsIncidence);
            setIncidenceWoundIncidence(
              (response.data.patientsWithIncidencePressureWoundsInCurrentMonth
                .length /
                response.data.patientsWithRisk.length) *
                100
            );
          } else {
            const monthsData = response.data;
            setMonthsData(monthsData);
            const labels = monthsData.map((i) => i.monthAbb);
            const monthIncidenceCounts = [];
            const monthIncidenceDetails = [];
            const monthDensityCounts = [];

            const monthRiskPatients = [];
            const monthRiskPatientsIds = [];
            const monthPatientsWithPressureWound = [];
            const monthPatientsWithPressureWoundIds = [];

            const monthIncidenceWounds = [];
            const monthAdmissionWounds = [];

            for (const monthData of monthsData) {
              let incidenceValue = 0;
              const hasRiskPatients = monthData.patientsWithRisk.length > 0;
              const hasIncidencePatients =
                monthData.patientsWithIncidencePressureWoundsInCurrentMonth
                  .length > 0;

              if (hasRiskPatients && hasIncidencePatients) {
                incidenceValue =
                  (monthData.patientsWithIncidencePressureWoundsInCurrentMonth
                    .length /
                    monthData.patientsWithRisk.length) *
                  100;
                incidenceValue = parseFloat(incidenceValue.toFixed(2));
              } else if (!hasIncidencePatients && !hasRiskPatients) {
                incidenceValue = null;
              }
              monthIncidenceDetails.push({
                title: "Incidência da lesão por pressão (%)",
                firstValue:
                  monthData.patientsWithIncidencePressureWoundsInCurrentMonth
                    .length,
                firstDescription: "Pacientes com LP Incididas",
                secondValue: monthData.patientsWithRisk.length,
                secondDescription: "Pacientes de risco",
                resultValue: incidenceValue,
                resultDescription: "Incidência da lesão",
                operation: "÷",
                month: monthData.monthAbb,
              });
              monthIncidenceCounts.push(incidenceValue);

              const hasGeneralPatients = monthData.generalPatients.length > 0;

              let densityValue = 0;
              if (hasGeneralPatients && hasIncidencePatients) {
                densityValue =
                  (monthData.patientsWithIncidencePressureWoundsInCurrentMonth
                    .length /
                    monthData.generalPatients.length) *
                  1000;
                densityValue = isNaN(densityValue) ? 0 : densityValue;
              } else if (!hasIncidencePatients) {
                densityValue = null;
              }

              monthDensityCounts.push(densityValue);

              monthRiskPatients.push(monthData.patientsWithRisk.length);
              monthRiskPatientsIds.push(monthData.patientsWithRisk);
              monthPatientsWithPressureWound.push(
                monthData.patientsWithPressureWounds.length
              );
              monthPatientsWithPressureWoundIds.push(
                monthData.patientsWithPressureWounds
              );

              monthIncidenceWounds.push(
                monthData.pressureWoundsIncidence.length
              );
              monthAdmissionWounds.push(
                monthData.pressureWoundsAdmission.length
              );
            }

            if (!monthIncidenceCounts.every((element) => element === null)) {
              setSixMonthsIncidenceChart({
                labels,
                data: monthIncidenceCounts,
                details: monthIncidenceDetails,
              });
            } else {
              setSixMonthsIncidenceChart(null);
            }

            const allZeroMonthRiskPatients = monthRiskPatients.every(
              (element) => element === 0
            );

            const allZeroMonthPatientsWithPressureWound =
              monthPatientsWithPressureWound.every((element) => element === 0);
            if (
              allZeroMonthRiskPatients &&
              allZeroMonthPatientsWithPressureWound
            ) {
              setSixMonthsRiskPatientsAndPatientsWithWoundsChart(null);
            } else {
              setSixMonthsRiskPatientsAndPatientsWithWoundsChart({
                labels,
                riskPatients: monthRiskPatients,
                riskPatientsIds: monthRiskPatientsIds,
                patientsWithPressureWounds: monthPatientsWithPressureWound,
                patientsWithPressureWoundsIds:
                  monthPatientsWithPressureWoundIds,
              });
            }

            const allZeroMonthIncidenceWounds = monthIncidenceWounds.every(
              (element) => element === 0
            );
            const allZeroMonthAdmissionWounds = monthAdmissionWounds.every(
              (element) => element === 0
            );
            if (allZeroMonthIncidenceWounds && allZeroMonthAdmissionWounds) {
              setSixMonthsWoundTypesChart(null);
            } else {
              setSixMonthsWoundTypesChart({
                labels,
                incidenceWounds: monthIncidenceWounds,
                admissionWounds: monthAdmissionWounds,
              });
            }
          }
        }
        isChartCancelled = false;
      })
      .finally(() => {
        setLoading(false);
        setFirstLoadDone(true);
        if (isChart && !isChartCancelled) {
          setLoadingChart(false);
        }
      });
  }

  function showPatientsData(title, patientIds) {
    if (patientIds.length > 0) {
      setTitle(title);
      setPatientIds([...new Set(patientIds)]);
      setShowPatientsRisksData(true);
    }
  }

  return (
    <div>
      <div className="card-days-wound-notification mb-5">
        <Row>
          <Col md={6}>
            <div className="days-without-notification mb-2">
              <div className="mr-4">
                <Icon icon="band-aid" size={55} color={Colors.brand_blue} />
              </div>
              {daysWithoutWound !== "-" ? (
                <div>
                  <p>{daysWithoutWound} dias</p>
                  <p>sem notificações de incidência de lesões por pressão</p>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="days-without-notification">
              <div className="mr-4">
                <Icon icon="podium" size={55} color={Colors.brand_blue} />
              </div>
              {recordDaysWithoutWound !== "-" ? (
                <div>
                  <p>{recordDaysWithoutWound} dias</p>
                  <p>É o nosso recorde</p>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="card-report">
        <div className="card-header-month-pagination mb-4">
          <p className="text-capitalize">{actualMonthAndYear.monthName}</p>
          <div className="ml-3" id="icons">
            <div
              className="mr-3"
              onClick={() => {
                setActualMonthTab(actualMonthTab - 1);
                setMonthDirection("prev");
              }}
            >
              <Icon icon="arrow-left" size={20} />
            </div>

            {(currentDate.month() + 1 !== actualMonthTab ||
              currentDate.year() !== actualMonthAndYear.year) && (
              <div
                onClick={() => {
                  setActualMonthTab(actualMonthTab + 1);
                  setMonthDirection("next");
                }}
              >
                <Icon icon="arrow-left" size={20} />
              </div>
            )}
          </div>
        </div>
        <div className="density-incidence-info">
          {!loading ? (
            <Row>
              <Col md={6}>
                <div className="d-flex">
                  <p className="title-description">Incidência</p>
                  <InformationButton modalHandler={setShowModalIncidenceInfo} />
                </div>
                <div className="density-incidence-content">
                  <div
                    onClick={() =>
                      showPatientsData(
                        "Pacientes com LP Incididas",
                        patientsWithIncidencePressureWounds
                      )
                    }
                  >
                    <p className="upper-info-text patients-risks-data">
                      {patientsWithIncidencePressureWounds.length}
                    </p>
                    <p className="lower-info-text">
                      Pacientes com LP Incididas
                    </p>
                  </div>
                  <p className="upper-info-text symbol">÷</p>
                  <div
                    onClick={() =>
                      showPatientsData("Pacientes de risco", patientsWithRisk)
                    }
                  >
                    <p className="upper-info-text patients-risks-data">
                      {patientsWithRisk.length}
                    </p>
                    <p className="lower-info-text">Pacientes de risco</p>
                  </div>
                  <p className="upper-info-text symbol">=</p>
                  <div>
                    <p className="upper-info-text">
                      {isNaN(incidenceWoundIncidence)
                        ? "0.00"
                        : incidenceWoundIncidence.toFixed(2)}
                      %
                    </p>
                    <p className="lower-info-text">Incidência de Lesão</p>
                  </div>
                </div>
                <div className="goal-info mt-4">
                  <div className="d-flex justify-content-between">
                    <p
                      className="goal-edit"
                      onClick={() => {
                        setType("incidence");
                        setShowModalGoal(true);
                      }}
                    >
                      Meta <Icon icon="edit" size={20} color={"#666666"} />
                    </p>
                    <p
                      className={
                        !actualIncidenceTarget
                          ? "goal-results"
                          : "goal-results " +
                            (incidenceWoundIncidence > actualIncidenceTarget
                              ? "unlike"
                              : "like")
                      }
                    >
                      {actualIncidenceTarget > 0 && (
                        <Icon
                          className="mr-2"
                          icon={"thumbs-up"}
                          size={24}
                          color={
                            incidenceWoundIncidence > actualIncidenceTarget
                              ? "#ff4949"
                              : "#13ce66"
                          }
                        />
                      )}
                      {actualIncidenceTarget
                        ? actualIncidenceTarget.toFixed(2)
                        : "0"}
                      %
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="d-flex align-items-center">
                  <p className="title-description mr-2">Densidade por</p>
                  <DensityDropdown
                    sort={densityDropdownSort}
                    handler={setDensityDropdownSort}
                  />
                  <InformationButton modalHandler={setShowModalDensityInfo} />
                </div>
                <div className="density-incidence-content">
                  <div
                    onClick={() => {
                      const title =
                        densityDropdownSort.value == "patient"
                          ? "Pacientes com LP Incididas"
                          : "Pacientes com lesão por pressão";
                      const patientIds =
                        densityDropdownSort.value == "patient"
                          ? patientsWithIncidencePressureWounds
                          : pressureWounds;
                      showPatientsData(title, patientIds);
                    }}
                  >
                    <p className="upper-info-text patients-risks-data">
                      {densityDropdownSort.value == "patient"
                        ? patientsWithIncidencePressureWounds.length
                        : pressureWounds.length}
                    </p>
                    <p className="lower-info-text">
                      {densityDropdownSort.value == "patient"
                        ? "Pacientes com LP Incididas"
                        : "Lesões por pressão"}
                    </p>
                  </div>
                  <p className="upper-info-text symbol">÷</p>
                  <div>
                    <div className="d-flex align-items-center">
                      <p
                        className="upper-info-text patients-risks-data"
                        onClick={() =>
                          showPatientsData("Pacientes / dia", generalPatients)
                        }
                      >
                        {patientsPerDayGeneral}{" "}
                      </p>
                      <p
                        className="edit-day-general-patients ml-3"
                        onClick={() => {
                          setShowModalPatientsPerDay(true);
                        }}
                      >
                        <Icon icon="edit" size={20} color={"#666666"} />
                      </p>
                    </div>
                    <p className="lower-info-text">Pacientes / dia</p>
                  </div>
                  <p className="upper-info-text symbol">=</p>
                  <div>
                    <p className="upper-info-text">
                      {isNaN(incidenceWoundDensity)
                        ? "0.00"
                        : incidenceWoundDensity.toFixed(2)}
                      ‰
                    </p>
                    <p className="lower-info-text">Densidade da lesão</p>
                  </div>
                </div>
                <div className="goal-info mt-4">
                  <div className="d-flex justify-content-between">
                    <p
                      className="goal-edit"
                      onClick={() => {
                        setType("density");
                        setShowModalGoal(true);
                      }}
                    >
                      Meta <Icon icon="edit" size={20} color={"#666666"} />
                    </p>
                    <p
                      className={
                        !actualDensityTarget
                          ? "goal-results"
                          : "goal-results " +
                            (incidenceWoundDensity > actualDensityTarget
                              ? "unlike"
                              : "like")
                      }
                    >
                      {actualDensityTarget > 0 && (
                        <Icon
                          className="mr-2"
                          icon={"thumbs-up"}
                          size={24}
                          color={
                            incidenceWoundDensity > actualDensityTarget
                              ? "#ff4949"
                              : "#13ce66"
                          }
                        />
                      )}
                      {actualDensityTarget > 0
                        ? actualDensityTarget.toFixed(2)
                        : "0"}
                      ‰
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
      </div>
      <div className="my-4">
        <DateRangePickerMonthYear
          interval={yearMonthInterval}
          intervalHandler={setYearMonthInterval}
          period={period}
          periodHandler={setPeriod}
        />
      </div>
      <Row>
        <Col xs={12} md={6}>
          <div
            style={{ minHeight: 145 }}
            className="card-report chart-container"
          >
            <div className="d-flex align-items-center">
              <h4 className="mb-0">Incidência da lesão (%)</h4>
            </div>

            {!loadingChart ? (
              <div className="mt-4">
                {sixMonthsIncidenceChart &&
                sixMonthsIncidenceChart.data.length > 0 ? (
                  <LineDotted
                    details={sixMonthsIncidenceChart.details}
                    labels={sixMonthsIncidenceChart.labels}
                    datasets={[
                      {
                        label: "Incidência da lesão",
                        data: sixMonthsIncidenceChart.data,
                        tension: 0.1,
                        fill: false,
                        borderColor: Colors.brand_blue,
                        borderWidth: 2,
                        pointBackgroundColor: Colors.brand_blue,
                        pointRadius: 5,
                      },
                    ]}
                  />
                ) : (
                  <EmptyContainer
                    iconName="bar-chart"
                    text="Ainda não há dados sobre pacientes de risco"
                  />
                )}
              </div>
            ) : (
              <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100 h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div
            style={{ minHeight: 145 }}
            className="card-report chart-container"
          >
            <div className="d-flex align-items-center">
              <h4 className="mb-0">
                Densidade por {densityDropdownSort.name} (‰)
              </h4>
            </div>

            {!loadingChart ? (
              <div className="mt-4">
                {sixMonthsDensityChart &&
                sixMonthsDensityChart.data.length > 0 ? (
                  <LineDotted
                    details={sixMonthsDensityChart.details}
                    labels={sixMonthsDensityChart.labels}
                    datasets={[
                      {
                        label: "Densidade por " + densityDropdownSort.name,
                        data: sixMonthsDensityChart.data,
                        tension: 0.1,
                        fill: false,
                        borderColor: Colors.brand_blue,
                        borderWidth: 2,
                        pointBackgroundColor: Colors.brand_blue,
                        pointRadius: 5,
                      },
                    ]}
                  />
                ) : (
                  <EmptyContainer
                    iconName="bar-chart"
                    text="Ainda não há dados sobre pacientes de risco"
                  />
                )}
              </div>
            ) : (
              <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100 h-100">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div className="mt-5">
        <Row>
          <Col xs={12} md={6}>
            <div className="card-report h-100 chart-container">
              <h4>Pacientes de risco x com lesão</h4>
              {!loadingChart ? (
                <div className="h-100 mt-4">
                  {sixMonthsRiskPatientsAndPatientsWithWoundsChart ? (
                    <TwoBarVertically
                      labels={
                        sixMonthsRiskPatientsAndPatientsWithWoundsChart.labels
                      }
                      firstDatasetPatientIds={
                        sixMonthsRiskPatientsAndPatientsWithWoundsChart.riskPatientsIds
                      }
                      secondDatasetPatientIds={
                        sixMonthsRiskPatientsAndPatientsWithWoundsChart.patientsWithPressureWoundsIds
                      }
                      datasets={[
                        {
                          label: "Pacientes de risco",
                          data: sixMonthsRiskPatientsAndPatientsWithWoundsChart.riskPatients,
                          backgroundColor: Colors.dark_blue,
                          borderColor: Colors.dark_blue,
                          borderWidth: 1,
                        },
                        {
                          label: "Com lesão por pressão",
                          data: sixMonthsRiskPatientsAndPatientsWithWoundsChart.patientsWithPressureWounds,
                          backgroundColor: Colors.brand_blue,
                          borderColor: Colors.brand_blue,
                          borderWidth: 1,
                        },
                      ]}
                    />
                  ) : (
                    <EmptyContainer
                      iconName="bar-chart"
                      text="Ainda não há dados sobre pacientes de risco"
                    />
                  )}
                </div>
              ) : (
                <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100 h-100">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="card-report h-100 chart-container">
              <div style={{ display: "flex", alignItems: "Center" }}>
                <h4>Lesão por pressão</h4>
                <InformationButton
                  modalHandler={setShowModalReportedWound}
                  className="d-flex aligm-itens-center"
                />
              </div>
              {!loadingChart ? (
                <div className="h-100 mt-4">
                  {sixMonthsWoundTypesChart ? (
                    <TwoBarVertically
                      labels={sixMonthsWoundTypesChart.labels}
                      datasets={[
                        {
                          label: "Admissão",
                          data: sixMonthsWoundTypesChart.admissionWounds,
                          backgroundColor: Colors.dark_blue,
                          borderColor: Colors.dark_blue,
                          borderWidth: 1,
                        },
                        {
                          label: "Incidência",
                          data: sixMonthsWoundTypesChart.incidenceWounds,
                          backgroundColor: Colors.brand_blue,
                          borderColor: Colors.brand_blue,
                          borderWidth: 1,
                        },
                      ]}
                    />
                  ) : (
                    <EmptyContainer
                      iconName="bar-chart"
                      text="Ainda não há dados sobre pacientes de risco"
                    />
                  )}
                </div>
              ) : (
                <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100 h-100">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <ModalIncidence
        show_modal={showModalIncidenceInfo}
        handler_show_modal={setShowModalIncidenceInfo}
      />
      <ModalDensity
        show_modal={showModalDensityInfo}
        handler_show_modal={setShowModalDensityInfo}
        densityType={densityDropdownSort.value}
      />
      <ModalReportedWound
        show_modal={showModalReportedWound}
        handler_show_modal={setShowModalReportedWound}
      />
      <ModalGoal
        reload={reload}
        handlerReload={handlerReload}
        show_modal={showModalGoal}
        handler_show_modal={setShowModalGoal}
        type={type}
        year={actualMonthAndYear.year}
        month={actualMonthAndYear.month}
        goalIncidence={actualIncidenceTarget}
        goalIncidenceHandler={setActualIncidenceTarget}
        goalDensity={actualDensityTarget}
        goalDensityHandler={setActualDensityTarget}
      />
      <ModalPatientsPerDay
        reload={reloadAll}
        handlerReload={handlerReloadAll}
        show_modal={showModalPatientsPerDay}
        handler_show_modal={setShowModalPatientsPerDay}
        year={actualMonthAndYear.year}
        month={actualMonthAndYear.month}
        patientsPerDay={patientsPerDayGeneral}
      />
      {showPatientsRisksData && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={title}
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="indicators"
        />
      )}
    </div>
  );
}

export default Indicators;
