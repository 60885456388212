import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";

function ModalUserFilter({ ...props }) {
  const {
    handler_show_modal,
    show_modal,
    handler_reload,
    reload,
    handler_permission,
    permission,
    handler_status,
    status,
    handler_units,
    units,
  } = props;
  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const user = useSelector((state) => state.user);

  const [listunits, setListUnits] = useState([]);
  const [listPermission, setListPermission] = useState([]);

  const [permissionLocal, setPermissionLocal] = useState(permission);
  const [unitsLocal, setUnitsLocal] = useState(units);
  const [statusLocal, setStatusLocal] = useState(status);

  const colourStyles = {
    multiValue: (styles, { data }) => {
      const color = Colors.brand_blue;
      return {
        ...styles,
        backgroundColor: "#ECF3FF",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
      ":hover": {
        backgroundColor: "#ECF3FF",
        color: Colors.brand_blue,
      },
    }),
  };

  useEffect(() => {
    function LoadListPermission() {
      api
        .get(`/permissions`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadListPermission success");
            console.log(response.data);
            setListPermission(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadListPermission error " + error);
        });
    }

    LoadListPermission();
  }, []);

  useEffect(() => {
    function LoadUnits() {
      api
        .get(`/hospitals/${user.dados.hospital_id}/units`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUnits success");
            console.log(response.data);

            var array = [];
            response.data.map((item) => {
              array.push({
                label: item.name,
                value: item.id,
              });
            });
            setListUnits(array);
          }
        })
        .catch((error) => {
          console.log("LoadUnits error " + error);
        });
    }

    LoadUnits();
  }, []);

  function SetarProfile(obj) {
    var found = permissionLocal.find((item) => item.id == obj.id);
    if (found != undefined) {
      var newList = permissionLocal.filter((item) => item.id != obj.id);
      setPermissionLocal(newList);
    } else {
      setPermissionLocal([...permissionLocal, obj]);
    }
  }

  function Apply() {
    handler_permission(permissionLocal);
    handler_units(unitsLocal);
    handler_status(statusLocal);
    handler_show_modal(false);
  }

  function Clear() {
    handler_permission([]);
    handler_units([]);
    handler_status(true);
    handler_show_modal(false);
  }

  return (
    <div>
      <Modal
        show={show_modal}
        onHide={() => handler_show_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter"
      >
        <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={8}>
              <div className="modal-title">Filtros</div>
            </Col>
            <Col
              xs={2}
              md={2}
              lg={2}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <div className="mt-4">
            <div className="mb-4">
              <p className="modal-text mb-2">Perfil de acesso</p>
              {listPermission.map((item, index) => {
                var found = permissionLocal.find((i) => i.id == item.id);
                return (
                  <button
                    key={index}
                    style={{
                      background:
                        found != undefined
                          ? Colors.brand_blue
                          : Colors.lightest_gray,
                    }}
                    className="btn-click"
                    onClick={() => SetarProfile(item)}
                  >
                    <p
                      className="mb-0"
                      style={{
                        color:
                          found != undefined ? "white" : Colors.darker_gray,
                      }}
                    >
                      {item.name}
                    </p>
                  </button>
                );
              })}
            </div>

            <div className="mb-4">
              <p className="modal-text mb-2">Unidades</p>
              <Select
                captureMenuScroll={false}
                noOptionsMessage={() => "Sem opções"}
                classNamePrefix="react-select"
                placeholder="Selecionar unidades"
                closeMenuOnSelect={false}
                options={listunits}
                isMulti
                styles={colourStyles}
                isClearable={false}
                value={unitsLocal}
                onChange={(array) => {
                  setUnitsLocal(array == null ? [] : array);
                }}
              />
            </div>

            <div>
              <p className="modal-text mb-2">Status</p>
              <div className="d-flex align-items-center">
                <label className="switch mb-0">
                  <input
                    type="checkbox"
                    checked={statusLocal}
                    onClick={() => setStatusLocal(!statusLocal)}
                  />
                  <span className="slider round"></span>
                </label>
                <p className="modal-text ml-3">
                  {statusLocal == true ? "Ativo" : "Inativo"}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-3">
            <div
              className="secondary-btn modal-footer-btn"
              onClick={() => Clear()}
            >
              Limpar
            </div>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <div
              className="default-btn mb-0 modal-footer-btn"
              onClick={() => Apply()}
            >
              Aplicar
            </div>
          </Col>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalUserFilter;
