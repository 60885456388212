import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

var arrayOrder = [
  {
    name: "Data decrescente",
    value: "desc",
  },
  {
    name: "Data crescente",
    value: "asc",
  },
];

function SortSensorChart(props) {
  const { sort } = props;
  // Exibir dropdown
  const [show, setShow] = useState(false);

  // Ordenação escolhida
  const [selected, setSelected] = useState({
    name: "Data decrescente",
    value: "asc",
  });

  useEffect(() => {
    if (sort.name != undefined) {
      setSelected(sort);
    }
  }, [sort]);

  function SetarHandler(obj) {
    const handler = props.handler;
    handler(obj);
  }

  function GetNameSelected() {
    var name = selected.name;
    return name;
  }

  return (
    <Dropdown className="drop-sort" onToggle={() => setShow(!show)}>
      <Dropdown.Toggle
        id="dropdown-sort"
        className={`btn-filter white-dropdown no-caret sensor-filter`}
        style={props.cssStyles}
      >
        <Icon
          className={`mr-2 ${sort.value == "asc" ? "reverse-y" : ""}`}
          icon="sort-desc"
          size={22}
        />
        {GetNameSelected()}
      </Dropdown.Toggle>

      <Dropdown.Menu rootCloseEvent={"click"} className="div-drop-sort">
        {arrayOrder.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              className="drop-sort-item"
              onClick={() => {
                setSelected(item);
                SetarHandler(item);
              }}
            >
              <div
                style={
                  selected.name == item.name ? { color: Colors.brand_blue } : {}
                }
              >
                {index > 0
                  ? item.name
                  : props.status == false
                  ? "Data crescente"
                  : "Data decrescente"}
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SortSensorChart;
