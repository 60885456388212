import React, { useMemo } from "react";
import { Form, Table, Spinner, Col, Row, Dropdown } from "react-bootstrap";
import ProgressBar from "react-percent-bar";
import * as Redux from "react-redux";
import moment from "moment";

import { Show_Alert } from "@Store/actions/alert";
import Pagination from "@Components/Pagination/Pagination";
import { GetColorBattery } from "@Functions/utils";

import { SensorsPanelsApi } from "../js/Api";
import ModalEnableDisable from "./ModalEnableDisable";

import Colors from "../../../../../../styles/Colors";
import Icon from "../../../../../../functions/Icon";
import { useState } from "react";
import ModalAddBeacon from "./ModalAddBeacon";
import ModalEditBeacon from "./ModalEditBeacon";
import EditBeaconDropdown from "./EditBeaconDropdown";
import ModalDeleteBeacon from "./ModalDeleteBeacon";
import Select from "react-select";

export default function BeaconList({ onBeaconSelect }) {
  const dispatch = Redux.useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [list, setList] = React.useState([]);
  const unit = Redux.useSelector((state) => state.unit);
  const [mac, setMac] = React.useState("");

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(12);
  const [pagination, setPagination] = React.useState(null);
  const [lastPage, setLastPage] = React.useState(null);
  const [qtdDisplay, setQtdDisplay] = React.useState(12);
  const [totalResults, setTotalResults] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [hoverBack, setHoverBack] = React.useState(false);
  const [hoverNext, setHoverNext] = React.useState(false);

  const [beaconSelected, setBeaconSelected] = React.useState(null);
  const [modalBeaconDialogShow, setModalBeaconDialogShow] =
    React.useState(false);
  const [modalAddBeacon, setModalAddBeacon] = useState(false);
  const [modalEditBeacon, setModalEditBeacon] = useState(false);
  const [modalDeleteBeacon, setModalDeleteBeacon] = useState(false);
  const [batteryFilter, setBatteryFilter] = useState("all"); // Novo estado para o filtro de bateria
  const [sortField, setSortField] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState("asc"); // 'asc' ou 'desc'

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
    sortList(field);
  };

  const sortList = (field) => {
    const sortedList = [...list].sort((a, b) => {
      if (field === "bateria") {
        // A ordenação de bateria deve ser numérica
        return sortOrder === "asc"
          ? a.battery - b.battery
          : b.battery - a.battery;
      } else if (field === "ativo") {
        // A ordenação de ativo deve ser booleana
        return sortOrder === "asc" ? a.status - b.status : b.status - a.status;
      }
      // A ordenação padrão é alfabética/string
      return sortOrder === "asc"
        ? a[field] > b[field]
          ? 1
          : -1
        : a[field] < b[field]
        ? 1
        : -1;
    });
    setList(sortedList);
  };

  const onApiSuccess = React.useCallback(
    (response) => {
      setList(response.data);
      setPagination(response);
      setLastPage(response.lastPage);
      setTotalResults(response.total);
      BuildPagination(response.page, response.lastPage);
    },
    [setList, setPagination]
  );

  const onApiError = React.useCallback(
    (error) => {
      dispatch(
        Show_Alert({
          type: "error",
          msg: error.message,
        })
      );
    },
    [dispatch]
  );

  const onApiFinally = React.useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  React.useEffect(() => {
    setLoading(true);

    const props = {
      mac,
      currentPage,
      qtdDisplay,
      unit_id: unit.selecionado.id,
      batteryFilter,
    };
    SensorsPanelsApi(props)
      .then(onApiSuccess)
      .catch(onApiError)
      .finally(onApiFinally);
  }, [
    unit,
    mac,
    page,
    pageSize,
    reload,
    currentPage,
    qtdDisplay,
    batteryFilter,
    onApiSuccess,
    onApiError,
    onApiFinally,
  ]);

  function BuildPagination(currentPage, lastPage) {
    var pagination = [];
    for (var i = currentPage; i < currentPage + 5; i++) {
      if (i == currentPage && currentPage > 2) {
        pagination.push(currentPage - 2);
      }
      if (i == currentPage && currentPage > 1) {
        pagination.push(currentPage - 1);
      }

      if (i <= lastPage) {
        pagination.push(i);
      }
    }
    setPagination(pagination);
  }

  function SetarDisplay(qtd) {
    setCurrentPage(1);
    setQtdDisplay(qtd);
  }

  function renderDisplay() {
    var n = 0;

    if (lastPage > 1) {
      n = qtdDisplay * currentPage;
      if (currentPage == lastPage) n = totalResults;
    } else {
      n = totalResults;
    }

    return n;
  }

  const onPaginationChange = ({ page, pageSize }) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const onItemClick = (beacon) => {
    onBeaconSelect(beacon);
  };

  const onToggleChange = (beacon) => {
    setBeaconSelected(beacon);
    setModalBeaconDialogShow(true);
  };

  const getLinkedUserName = (linked) => {
    if (!linked) return "-";

    if (linked.userUnlink) return linked.userUnlink.name;

    return linked.userLink.name;
  };

  const getLinkedStatus = (linked) => {
    if (!linked || (linked && linked.userUnlink)) return "Desvinculado";

    return "Vinculado";
  };

  const batteryLevelOptions = [
    { value: "all", label: "Todos (0-100%)" },
    { value: "high", label: "Alta (70-100%)" },
    { value: "medium", label: "Média (30-70%)" },
    { value: "low", label: "Baixa (0-30%)" },
  ];

  const filterByBatteryLevel = (list, level) => {
    switch (level) {
      case "high":
        return list.filter((item) => item.battery >= 70);
      case "medium":
        return list.filter((item) => item.battery > 30 && item.battery < 70);
      case "low":
        return list.filter((item) => item.battery <= 30);
      case "all":
      default:
        return list;
    }
  };
  const filteredList = useMemo(
    () => filterByBatteryLevel(list, batteryFilter),
    [list, batteryFilter]
  );

  return (
    <section className="beacon-panel-list" data-testid="beacon-panel-list">
      <div className="d-flex align-items-center title-patient mb-4">
        <h2 className="screen-name mb-4">Painel de sensores</h2>
        <button
          style={{
            backgroundColor: Colors.brand_pink,
          }}
          className="modal-footer-btn mr-0 btn-cancel"
          onClick={() => setModalAddBeacon(true)}
        >
          <p
            style={{
              color: "white",
              display: "flex",
            }}
          >
            Adicionar sensor
          </p>
        </button>
      </div>

      <Form>
        <Form.Group as={Row}>
          <Col xs={12} md={8}>
            <Form.Label className="field-label">Buscar sensor</Form.Label>
            <Form.Control
              style={{ marginBottom: "15px" }}
              className="input-lg"
              type="text"
              placeholder="Digite o ID do sensor"
              defaultValue={mac}
              onChange={(e) => setMac(e.target.value)} // Adicionado onChange aqui
            />
          </Col>
          <Col xs={12} md={4}>
            <Form.Label className="field-label">Nível da bateria:</Form.Label>
            <Select
              classNamePrefix="react-select"
              options={batteryLevelOptions}
              value={batteryLevelOptions.find(
                (option) => option.value === batteryFilter
              )}
              onChange={(selectedOption) =>
                setBatteryFilter(selectedOption.value)
              }
              styles={{
                control: (base, state) => ({
                  ...base,
                  boxShadow: state.isFocused ? 0 : 0,
                  borderColor: state.isFocused
                    ? Colors.brand_blue
                    : base.borderColor,
                  "&:hover": {
                    borderColor: state.isFocused
                      ? Colors.brand_blue
                      : base.borderColor,
                  },
                }),
              }}
            />
          </Col>
        </Form.Group>
      </Form>

      {!loading && list.length === 0 && (
        <div className="container-error-default my-3">
          <p>Nenhum resultado foi encontrado.</p>
        </div>
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && list.length > 0 && (
        <div className="mt-3">
          <Table responsive hover className="patient-table">
            <thead>
              <tr>
                <th onClick={() => toggleSort("id")}>
                  ID {sortField === "id" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
                <th>Última vinculação</th>
                <th>Paciente</th>
                <th>Responsável</th>
                <th>Status</th>
                <th onClick={() => toggleSort("bateria")}>
                  Bateria{" "}
                  {sortField === "bateria" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
                <th onClick={() => toggleSort("ativo")}>
                  Ativo{" "}
                  {sortField === "ativo" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredList.map((item, index) => {
                return (
                  <tr
                    key={index}
                    data-testid="beacon-panel-item"
                    id={item.name}
                  >
                    <td onClick={() => onItemClick(item)}>{item.name}</td>
                    <td onClick={() => onItemClick(item)}>
                      {item.linked !== null
                        ? moment(item.linked.datetime_initial).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </td>
                    <td onClick={() => onItemClick(item)}>
                      {item.linked !== null
                        ? item.linked.patient.initials
                        : "-"}
                    </td>
                    <td onClick={() => onItemClick(item)}>
                      {getLinkedUserName(item.linked)}
                    </td>
                    <td onClick={() => onItemClick(item)}>
                      {getLinkedStatus(item.linked)}
                    </td>
                    <td onClick={() => onItemClick(item)}>
                      <div className="container-battery d-flex align-items-center">
                        <ProgressBar
                          fillColor={GetColorBattery(item.battery)}
                          percent={item.battery ? parseInt(item.battery) : 0}
                          width={60}
                          height={16}
                          borderColor={"#ddd"}
                        />
                        <p className="ml-2 percentage-text">
                          {item.battery ? item.battery + "%" : "-"}
                        </p>
                      </div>
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        label=" "
                        checked={item.status === 1}
                        readOnly={true}
                        onClick={() => onToggleChange(item)}
                        id={`sensor-panel-item-toggle-${index}`}
                        data-testid="beacon-panel-item-toggle"
                        className={`o-toggle o-toggle-success ${
                          item.status === 1 ? "is-checked" : ""
                        }`}
                      />
                    </td>
                    <td>
                      <EditBeaconDropdown
                        index={index}
                        beacon={item}
                        handler_select_beacon={setBeaconSelected}
                        handler_modal_edit_beacon={setModalEditBeacon}
                        handler_modal_delete_beacon={setModalDeleteBeacon}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <Row
        className="px-2 w-100"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Col xs={4} md={4} lg={3} style={{ textAlign: "left" }}>
          <div className="sm-txt" style={{ color: Colors.dark_gray }}>
            Exibindo
          </div>
          <div className="sm-txt">
            {renderDisplay()} de {totalResults} resultados
          </div>
        </Col>

        <Col xs={4} md={4} lg={6} style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {currentPage > 1 && (
              <div
                className="btn-nav-arrows"
                onMouseEnter={() => setHoverBack(true)}
                onMouseLeave={() => setHoverBack(false)}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <Icon
                  color={hoverBack ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}

            {!loading &&
              list.length > 0 &&
              pagination.length > 0 &&
              pagination.map((item) => {
                return (
                  <div
                    key={item}
                    className={
                      item == currentPage
                        ? "btn-nav-numbers active"
                        : "btn-nav-numbers"
                    }
                    onClick={() => {
                      setCurrentPage(item);
                    }}
                  >
                    {item}
                  </div>
                );
              })}

            {currentPage < lastPage && (
              <div
                className="btn-nav-arrows"
                style={{ transform: `rotateY(180deg)` }}
                onMouseEnter={() => setHoverNext(true)}
                onMouseLeave={() => setHoverNext(false)}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <Icon
                  color={hoverNext ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}
          </div>
        </Col>

        <Col xs={4} md={4} lg={3}>
          <Dropdown style={{ textAlign: "right" }}>
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown-nav-btn"
              id="dropdown-basic"
            >
              {qtdDisplay} resultados &nbsp;&nbsp;
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => SetarDisplay(12)}>
                12 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(24)}>
                24 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(36)}>
                36 resultados
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      {modalBeaconDialogShow && (
        <ModalEnableDisable
          show_modal={modalBeaconDialogShow}
          beacon={beaconSelected}
          reload={reload}
          handler_show_modal={setModalBeaconDialogShow}
          handler_reload={setReload}
        />
      )}

      {modalAddBeacon && (
        <ModalAddBeacon
          show_modal={modalAddBeacon}
          reload={reload}
          handler_show_modal={setModalAddBeacon}
          handler_reload={setReload}
        />
      )}

      {modalEditBeacon && (
        <ModalEditBeacon
          show_modal={modalEditBeacon}
          reload={reload}
          beacon={beaconSelected}
          handler_show_modal={setModalEditBeacon}
          handler_reload={setReload}
        />
      )}

      {modalDeleteBeacon && (
        <ModalDeleteBeacon
          show_modal={modalDeleteBeacon}
          beacon={beaconSelected}
          reload={reload}
          handler_show_modal={setModalDeleteBeacon}
          handler_reload={setReload}
        />
      )}
    </section>
  );
}
