import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";
import { Bar, Doughnut } from "react-chartjs-2";

import api from "../../../../../../services/api";
import list_wounds from "../../../../../../functions/list_wounds";
import moment from "moment";
import { Show_Alert } from "../../../../../../store/actions/alert";
import "./styles.css";
import Colors from "../../../../../../styles/Colors";
import Icon from "../../../../../../functions/Icon";
import { renderPhoto } from "../../../../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../../../../Home/Notificacoes/Notificacoes";

const colourStyles = {
  multiValue: (styles, { data }) => {
    const color = Colors.brand_blue;
    return {
      ...styles,
      backgroundColor: "#ECF3FF",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: Colors.brand_blue,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: Colors.brand_blue,
    ":hover": {
      backgroundColor: "#ECF3FF",
      color: Colors.brand_blue,
    },
  }),
};

const options = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
        },
      },
    ],
  },
};

const optionsPie = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  cutoutPercentage: 70,
  defaultColor: "#E6E6E6",
};

function RelatorioLesao() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [listWounds, setListWounds] = useState([]);
  const [listDates, setListDates] = useState([]);
  const [wounds, setWounds] = useState([]);

  const [wound, setWound] = useState({ label: "Todos", value: 0 });
  const [dates, setDates] = useState([{ label: "Todas", value: 0 }]);

  useEffect(() => {
    //Carrega os wounds do paciente
    //pra mostrar só os que tem lesao no select local
    function LoadPatient() {
      api
        .get(`patients/${patientSelected.dados.id}`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPatient success");
            console.log(response.data);

            var array = [];
            response.data.wounds.map((item) => {
              array.push({
                label: item.wound.name,
                value: item.wound.id,
              });
            });
            setListWounds([{ label: "Todos", value: 0 }, ...array]);
          }
        })
        .catch((error) => {
          console.log("LoadPatient " + error);
        });
    }

    LoadPatient();
  }, []);

  useEffect(() => {
    function LoadDates() {
      api
        .get(
          `patients/${patientSelected.dados.id}/wounds/${wound.value}/date_verify`
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadDates success");
            console.log(response.data);

            var array = [{ label: "Todas", value: 0 }];
            response.data.map((item, index) => {
              array.push({
                label: moment(item).format("DD/MM/YYYY"),
                value: index + 1,
              });
            });

            setListDates(array);
          }
        })
        .catch((error) => {
          console.log("LoadDates " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as datas. Verifique sua conexão.",
            })
          );
        });
    }

    setDates([{ label: "Todas", value: 0 }]);
    LoadDates();
  }, [wound]);

  useEffect(() => {
    function LoadWounds() {
      var date_verify = ["Todas"];
      if (dates[0].label != "Todas") {
        date_verify = [];
        dates.map((item) => {
          date_verify.push(
            moment(item.label, "DD/MM/YYYY").format("YYYY-MM-DD")
          );
        });
      }

      api
        .get(`patients/${patientSelected.dados.id}/wounds`, {
          params: {
            wound_id: wound.value == 0 ? "all" : wound.value,
            date_verify:
              date_verify[0] == "Todas" ? "all" : date_verify.join(","),
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadWounds");
            console.log(response.data);

            var newList = response.data.map((item, index) => {
              var obj = {};
              var labels = [];
              var data = [];
              var backgroundColor = [];
              var label = "porcentagem";

              var objDor = {};
              var dataDor = [];
              var backgroundColorDor = [];

              var objOdor = {};
              var dataOdor = [];
              var backgroundColorOdor = [];

              item.descriptions.map((i, ind) => {
                if (
                  i.type_description_name == "Tecido epitelial" ||
                  i.type_description_name == "Tecido necrótico" ||
                  i.type_description_name == "Esfacelo" ||
                  i.type_description_name == "Lesão fechada" ||
                  i.type_description_name == "Granulação"
                ) {
                  labels.push(i.type_description_name);
                  data.push(i.value != "" ? parseInt(i.value) : 0);
                  backgroundColor.push(GetColor(i.type_description_name));
                }

                if (i.type_description_name == "Dor") {
                  var v = 0;
                  var a = 0;
                  if (i.value != "") {
                    v = parseInt(i.value);
                    a = 100 - parseInt(i.value);
                  }
                  dataDor.push(v, a);
                  backgroundColorDor.push(Colors.brand_blue, "#ddd");
                }

                if (i.type_description_name == "Odor") {
                  var v = 0;
                  var a = 0;
                  if (i.value != "") {
                    v = parseInt(i.value);
                    a = 100 - parseInt(i.value);
                  }
                  dataOdor.push(v, a);
                  backgroundColorOdor.push(Colors.brand_pink, "#ddd");
                }
              });

              obj = {
                labels: labels,
                datasets: [{ label, data, backgroundColor }],
              };

              objDor = {
                datasets: [
                  {
                    data: dataDor,
                    backgroundColor: backgroundColorDor,
                    borderWidth: 0,
                  },
                ],
              };

              objOdor = {
                datasets: [
                  {
                    data: dataOdor,
                    backgroundColor: backgroundColorOdor,
                    borderWidth: 0,
                  },
                ],
              };

              item.descriptionsChart = obj;
              item.dor = objDor;
              item.odor = objOdor;
              return item;
            });

            setWounds(newList);
          }
        })
        .catch((error) => {
          console.log("LoadWounds " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar informações sobre a lesão. Verifique sua conexão.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    setLoading(true);
    LoadWounds();
  }, [wound, dates]);

  function GetColor(value) {
    let color = "";

    if (value == "Tecido epitelial") {
      color = Colors.brand_blue;
    } else if (value == "Esfacelo") {
      color = Colors.brand_pink;
    } else if (value == "Granulação") {
      color = Colors.brand_green;
    } else if (value == "Lesão fechada") {
      color = Colors.brand_orange;
    } else if (value == "Tecido necrótico") {
      color = Colors.brand_yellow;
    }

    return color;
  }

  function renderUserImg(obj) {
    var url = require("../../../../../../assets/img/icon_user.png");
    if (obj != null) {
      url = obj;
    }

    return (
      <img style={{ width: 40, height: 40, borderRadius: "100%" }} src={url} />
    );
  }

  function renderDate(item) {
    var date = "";

    if (item.type_realized == "notification") {
      date = moment(item.user_notification.date).format("LLL");
    } else if (item.type_realized == "confirmed") {
      date = moment(item.user_confirmed.date).format("LLL");
    } else {
      date = moment(item.user_reavaliation.date).format("LLL");
    }

    return <p className="date">{date}</p>;
  }

  function renderDrops(item) {
    let qtd = 0;

    if (item.description_name == "Pouco") {
      qtd = 1;
    }
    if (item.description_name == "Moderado") {
      qtd = 2;
    }
    if (item.description_name == "Abundante") {
      qtd = 3;
    }

    return (
      <div className="mr-2">
        <Icon
          style={{ marginRight: 4 }}
          color={qtd >= 1 ? Colors.brand_red : "#ddd"}
          size={20}
          icon="drop"
        />

        <Icon
          style={{ marginRight: 4 }}
          color={qtd >= 2 ? Colors.brand_red : "#ddd"}
          size={20}
          icon="drop"
        />

        <Icon
          style={{ marginRight: 4 }}
          color={qtd >= 3 ? Colors.brand_red : "#ddd"}
          size={20}
          icon="drop"
        />
      </div>
    );
  }

  function CalculateArea(width, height) {
    var ret = "";
    if (width != "null" && height != "null") {
      ret = width.replace(",", ".") * height.replace(",", ".");
      ret = String(ret.toFixed(2)).replace(".", ",");
    }
    return ret;
  }

  return (
    <div>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <div className="mt-4">
            <Form.Label className="field-label">Local</Form.Label>
            <Select
              captureMenuScroll={false}
              noOptionsMessage={() => "Sem opções"}
              classNamePrefix="react-select"
              options={listWounds}
              isClearable={false}
              value={wound}
              onChange={(item) => setWound(item)}
            />
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="mt-4">
            <Form.Label className="field-label">Data</Form.Label>
            <Select
              captureMenuScroll={false}
              noOptionsMessage={() => "Sem opções"}
              classNamePrefix="react-select"
              options={listDates}
              isClearable={false}
              isMulti
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              value={dates}
              isMulti
              styles={colourStyles}
              onChange={(array) => {
                if (array == null) {
                  setDates([{ label: "Todas", value: 0 }]);
                  return false;
                }

                var found = array.find((item) => item.label == "Todas");
                if (found != undefined && array.length > 2) {
                  setDates([{ label: "Todas", value: 0 }]);
                  return false;
                }

                var newList = array.filter((item) => item.label != "Todas");
                setDates(newList);
              }}
            />
          </div>
        </Col>
      </Row>

      {loading && (
        <div className="mt-5 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && wounds.length == 0 && (
        <div className="container-error-default mt-5">
          <p>Não foram encontradas lesões por pressão para esse paciente</p>
        </div>
      )}

      {!loading &&
        wounds.map((item, index) => {
          var exsudato = item.descriptions.find(
            (i) => i.type_description_name == "Quantidade de exsudato"
          );
          var exsudatoType = item.descriptions.find(
            (i) => i.type_description_name == "Tipo do exsudato"
          );

          var Dor = item.descriptions.find(
            (i) => i.type_description_name == "Dor"
          );

          var Odor = item.descriptions.find(
            (i) => i.type_description_name == "Odor"
          );

          return (
            <div key={index} className="card-wound mt-5">
              <Row className="p-3 m-0">
                <Col xs={12}>
                  <div className="card-header-wound">
                    <h3 className="mb-0">{item.wound_name}</h3>
                    <p className="status">
                      {" "}
                      {item.type_realized == "notification"
                        ? "Notificada"
                        : item.type_realized == "confirmed"
                        ? "Confirmada"
                        : "Reavaliada"}
                    </p>

                    {renderDate(item)}
                  </div>
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <div className="container-stage">
                    <p>
                      {" "}
                      {item.type_wound == "incidence"
                        ? "Incidência"
                        : "Admissão"}
                    </p>
                    <p>{item.stage_name}</p>
                  </div>
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <div className="container_medical_devices">
                    <p>Dispositivo médico</p>
                    <div className="d-flex align-items-center mt-2">
                      <Icon
                        color={
                          item.medical_devices == 0
                            ? Colors.brand_red
                            : Colors.brand_green
                        }
                        size={24}
                        icon={item.medical_devices == 0 ? "x" : "check"}
                        className="mr-2"
                      />
                      <p style={{ marginBottom: 0 }}>
                        {item.medical_devices == 0 ? "Não" : "Sim"}
                      </p>
                    </div>
                  </div>
                </Col>

                <Col xs={12} md={3}>
                  <div className="container-cube">
                    <div className="d-flex align-items-center">
                      <p style={{ width: 44, height: 2, marginRight: 4 }}></p>
                      <p style={{ width: 109, textAlign: "center" }}>
                        {" "}
                        {item.width != "null"
                          ? item.width.replace(".", ",")
                          : ""}
                      </p>
                    </div>

                    <div className="d-flex align-items-center">
                      <p
                        style={{
                          width: 44,
                          textAlign: "right",
                          marginRight: 4,
                        }}
                      >
                        {" "}
                        {item.height != "null"
                          ? item.height.replace(".", ",")
                          : ""}
                      </p>
                      <div className="cube">
                        <p>
                          A = {CalculateArea(item.width, item.height)}
                          cm²
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <div className="cube" style={{ width: "unset" }}>
                    <p>Profund. = {item.depth} cm</p>
                  </div>
                </Col>
              </Row>

              <Row
                className="p-3 m-0"
                style={{
                  borderTopWidth: 1,
                  borderTopColor: "#ddd",
                  borderTopStyle: "solid",
                }}
              >
                <Col xs={12} md={6}>
                  <div>
                    <p className="status">
                      Exsudato {exsudatoType?.description_name}
                    </p>
                    <div className="d-flex align-items-center">
                      {exsudato != undefined && renderDrops(exsudato)}
                      <p className="subt">{exsudato?.description_name}</p>
                    </div>
                  </div>

                  <div className="mt-4 mb-3 mb-md-0">
                    <p className="status">Tipo de tecido</p>
                    <div className="mt-3">
                      <Bar
                        data={item.descriptionsChart}
                        width={100}
                        height={300}
                        options={options}
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={12} md={6}>
                  <p className="status">Pele adjacente</p>
                  <p className="subt">Hiperemia</p>

                  <div className="d-flex mt-4 container-pele">
                    <Col xs={6}>
                      <div className="container-pele-pie">
                        <div>
                          <Doughnut
                            height={100}
                            data={item.dor}
                            options={optionsPie}
                          />
                        </div>
                        <p className="pele-percentage">{Dor?.value}%</p>
                      </div>
                      <p className="pele-name">Dor</p>
                    </Col>

                    <Col xs={6}>
                      <div className="container-pele-pie">
                        <div>
                          <Doughnut
                            height={100}
                            data={item.odor}
                            options={optionsPie}
                          />
                        </div>

                        <p className="pele-percentage">{Odor?.value}%</p>
                      </div>
                      <p className="pele-name">Odor</p>
                    </Col>
                  </div>
                </Col>
              </Row>

              <div className="p-4">
                <div className="w-100">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div className="c-profile-dropdown mr-2">
                      {renderPhoto(
                        makeUserDataToPhoto(
                          item.user_notification.name,
                          item.user_notification.image !== null
                            ? item.user_notification.image
                            : null
                        )
                      )}
                    </div>

                    <div className="dados-default">
                      <div className="name-default">
                        Notificada por {item.user_notification.name}
                      </div>
                      <div className="date-default">
                        {" "}
                        {moment(item.user_notification.date).format("LLL")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="card-bottom-confirmed d-flex flex-column align-items-start px-4 py-2">
                  {item.user_confirmed.name != null && (
                    <div className="w-100 d-flex align-items-center">
                      <Icon
                        color={Colors.brand_green}
                        size={18}
                        icon={"check-circle"}
                        className="mr-2"
                      />
                      <div>
                        <p className="mb-0">
                          Confirmado por {item.user_confirmed.name}, {""}
                          {moment(item.user_confirmed.date).format("LLL")}
                        </p>
                      </div>
                    </div>
                  )}

                  {item.user_reavaliation.name && (
                    <div className="w-100 d-flex align-items-center mt-2">
                      <Icon
                        color={Colors.darker_gray}
                        size={18}
                        icon={"edit"}
                        className="mr-2"
                      />
                      <div>
                        <p className="mb-0">
                          Reavaliada por {item.user_reavaliation.name}, {""}
                          {moment(item.user_reavaliation.date).format("LLL")}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default RelatorioLesao;
