import { filterSchedules, overrideSchedules } from "./Utils";
import Historics from "./Historics";

export default function Period() {
  return {
    ...this.options,
    getHistorics: Historics.bind(this),
    getSchedules: () => {
      let schedules = this.schedules.filter((schedule) =>
        filterSchedules(schedule, this.options)
      );
      if (schedules.length === 0) {
        schedules = [this.schedules[0]];
      }
      return overrideSchedules(schedules, this.options);
    },
  };
}
