import React, { useState, useEffect } from "react";
import { Col, Container, Row, ProgressBar, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import MainNavbar from "../../../components/Nav/MainNavbar";

import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import "./styles.css";
import Icon from "../../../functions/Icon";
import IconAchievements from "../../../functions/IconAchievements";
import Colors from "../../../styles/Colors";
import ModalInfo from "./ModalInfo";
import { HexToRgbA } from "../../../functions/utils";

var type_id = 0;

function Conquistas({ history }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const unit = useSelector((state) => state.unit);

  const [loading, setLoading] = useState(true);
  const [infoUser, setInfoUser] = useState([]);
  const [types, setTypes] = useState([]);
  const [communit, setCommunit] = useState([]);
  const [total, setTotal] = useState(0);
  const [userRanking, setUserRanking] = useState(0);

  //Modal
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    function LoadType() {
      api
        .get("/ranking/users/achievements/all")
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadType");
            console.log(response.data);

            setTotal(response.data.counter_total);
            setUserRanking(response.data.ranking_month);

            var array = [];
            var arraySelfiePerfil = [];
            var arrayCommunit = [];
            response.data.insignias.map((item, index) => {
              if (item.name == "Geral") {
                arraySelfiePerfil.push(item);
              } else if (item.name != "Comunidade") {
                array.push(item);
              } else {
                arrayCommunit.push(item);
              }
            });

            setCommunit(arrayCommunit);
            setInfoUser(arraySelfiePerfil);
            setTypes(array);
          }
        })
        .catch((error) => {
          console.log("LoadType " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as conquistas.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadType();
  }, []);

  return (
    <div>
      <MainNavbar history={history} />
      {showModal && (
        <ModalInfo
          handler_show_modal={setShowModal}
          show_modal={showModal}
          type_id={type_id}
        />
      )}

      <Container
        fluid
        className="bg-white"
        style={{ paddingRight: 80, paddingLeft: 80 }}
      >
        <div className="container-header-screen">
          <h2>Minhas conquistas</h2>
        </div>

        {loading && (
          <div className="mt-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {!loading && (
          <>
            <Row className="mt-4">
              <Col xs={12} md={6} lg={4} xl={2} className="mb-2 mb-md-0">
                <div className="achievement-info">
                  <Icon color={Colors.brand_blue} size={24} icon="medal" />
                  <p className="info-qtd">{total}</p>
                  <p className="info-title">Pontuação geral</p>
                </div>
              </Col>

              <Col xs={12} md={6} lg={4} xl={2}>
                <div className="achievement-info">
                  <Icon color={Colors.brand_blue} size={24} icon="podium" />
                  <p className="info-qtd">{userRanking}°</p>
                  <p className="info-title">Posição esse mês</p>
                </div>
              </Col>
            </Row>

            <div className="d-flex my-5">
              {infoUser.length > 0 &&
                infoUser[0].data.map((i) => {
                  return (
                    <div
                      key={i.ranking_type_id}
                      className="container-selfie-perfil mr-4"
                    >
                      <div
                        className="selfie-perfil-main"
                        style={{ borderColor: i.insignias[0].color }}
                      >
                        <div
                          className="selfie-perfil-border"
                          style={{
                            backgroundColor: HexToRgbA(i.insignias[0].color),
                          }}
                        >
                          <IconAchievements
                            color={i.insignias[0].color}
                            size={60}
                            icon={i.insignias[0].image}
                          />
                        </div>
                      </div>
                      <p>{i.name_type}</p>
                    </div>
                  );
                })}
            </div>

            {types.map((a) => {
              return (
                <div key={a.name} className="mb-5">
                  <div>
                    <div className="container-achievement">
                      <div className="d-flex">
                        <h3>{a.name}</h3>
                        <button
                          className="btn-achievemente-info d-flex"
                          onClick={() => {
                            type_id = a.data[0].ranking_type_id;
                            setShowModal(true);
                          }}
                        >
                          <Icon
                            color={Colors.dark_gray}
                            size={20}
                            icon="info"
                          />
                        </button>
                      </div>

                      <div className="my-2">
                        <ProgressBar
                          style={{ height: 8, background: HexToRgbA(a.color) }}
                        >
                          <ProgressBar
                            now={parseFloat(a.percentage) * 100}
                            style={{ background: a.color }}
                          />
                        </ProgressBar>
                      </div>

                      <div className="d-flex justify-content-between">
                        <p className="level-text" style={{ color: a.color }}>
                          {a.level}
                        </p>
                        <p className="points-text">
                          {" "}
                          {a.counter_user}/{a.counter_total}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap mt-4">
                    {a.data.map((item, index) => {
                      return item.insignias.map((i, a) => {
                        return (
                          <div
                            key={a}
                            style={{ width: 120 }}
                            className="mr-4 mb-2"
                          >
                            <CircularProgressbarWithChildren
                              strokeWidth={6}
                              value={parseFloat(i.percentage)}
                              styles={buildStyles({
                                pathColor: i.color,
                                trailColor: "#eee",
                              })}
                            >
                              <div
                                className="container-icon"
                                style={{
                                  backgroundColor:
                                    i.color != "#EEEEEE"
                                      ? HexToRgbA(i.color)
                                      : "#EEEEEE",
                                }}
                              >
                                <IconAchievements
                                  color={
                                    i.color != "#EEEEEE" ? i.color : "#C2C2C2"
                                  }
                                  size={60}
                                  icon={i.image}
                                />
                              </div>
                              <div
                                className="medal-points"
                                style={{ backgroundColor: i.color }}
                              >
                                <p
                                  style={{
                                    color:
                                      i.color == "#EEEEEE"
                                        ? "#C2C2C2"
                                        : "white",
                                  }}
                                >
                                  {i.counter_user}
                                </p>
                                <p
                                  style={{
                                    color:
                                      i.color == "#EEEEEE"
                                        ? "#C2C2C2"
                                        : "white",
                                  }}
                                >
                                  /{i.counter}
                                </p>
                              </div>
                            </CircularProgressbarWithChildren>
                            <p className="text-center mt-2 medal-title">
                              {i.name}
                            </p>
                          </div>
                        );
                      });
                    })}
                  </div>
                </div>
              );
            })}

            {communit.length > 0 && (
              <div className="mb-5">
                <div>
                  <div className="container-achievement">
                    <div className="d-flex">
                      <h3>{communit[0].name}</h3>
                    </div>

                    <div className="my-2">
                      <ProgressBar
                        style={{
                          height: 8,
                          background: HexToRgbA(communit[0].color),
                        }}
                      >
                        <ProgressBar
                          now={communit[0].percentage * 100}
                          style={{ background: communit[0].color }}
                        />
                      </ProgressBar>
                    </div>

                    <div className="d-flex justify-content-between">
                      <p
                        className="level-text"
                        style={{ color: communit[0].color }}
                      >
                        {communit[0].level}
                      </p>
                      <p className="points-text">
                        {communit[0].counter_user}/{communit[0].counter_total}
                      </p>
                    </div>
                  </div>
                </div>

                {communit[0].data.map((item) => {
                  return (
                    <>
                      <div className="mt-4">
                        <div className="container-achievement">
                          <div className="d-flex">
                            <h3>{item.name_type}</h3>
                            <button
                              className="btn-achievemente-info d-flex"
                              onClick={() => {
                                type_id = item.ranking_type_id;
                                setShowModal(true);
                              }}
                            >
                              <Icon
                                color={Colors.dark_gray}
                                size={20}
                                icon="info"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex mt-4">
                        {item.insignias.map((i) => {
                          return (
                            <div style={{ width: 120 }} className="mr-4 mb-2">
                              <CircularProgressbarWithChildren
                                strokeWidth={6}
                                value={parseFloat(i.percentage)}
                                styles={buildStyles({
                                  pathColor: i.color,
                                  trailColor: "#eee",
                                })}
                              >
                                <div
                                  className="container-icon"
                                  style={{
                                    backgroundColor:
                                      i.color != "#EEEEEE"
                                        ? HexToRgbA(i.color)
                                        : "#EEEEEE",
                                  }}
                                >
                                  <IconAchievements
                                    color={
                                      i.color != "#EEEEEE" ? i.color : "#C2C2C2"
                                    }
                                    size={60}
                                    icon={i.image}
                                  />
                                </div>
                                <div
                                  className="medal-points"
                                  style={{ backgroundColor: i.color }}
                                >
                                  <p
                                    style={{
                                      color:
                                        i.color == "#EEEEEE"
                                          ? "#C2C2C2"
                                          : "white",
                                    }}
                                  >
                                    {i.counter_user}
                                  </p>
                                  <p
                                    style={{
                                      color:
                                        i.color == "#EEEEEE"
                                          ? "#C2C2C2"
                                          : "white",
                                    }}
                                  >
                                    /{i.counter}
                                  </p>
                                </div>
                              </CircularProgressbarWithChildren>
                              <p className="text-center mt-2 medal-title">
                                {i.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default Conquistas;
