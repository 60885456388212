import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Spinner,
  Table,
} from "react-bootstrap"
import DateRangePicker from "react-bootstrap-daterangepicker"
import "bootstrap-daterangepicker/daterangepicker.css"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"

import { Show_Alert } from "@Store/actions/alert"
import Colors from "@Styles/Colors"
import Icon from "@Functions/Icon"

import Pagination from "@Components/Pagination/Pagination"

import ModalSuspensas from "./src/components/ModalSuspensas/ModalSupensas";
import SuspensasApi from './src/js/api'

import './Suspensas.scss'

function Suspensas() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected)
  const [loading, setLoading] = useState(true)
  const [date_start, setDate_start] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
  const [date_end, setDate_end] = useState(moment().format('YYYY-MM-DD'))
  const [list, setList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(12)
  const [pagination, setPagination] = useState(null)
  const [itemClicked, setItemClicked] = useState(null)

  const onPaginationChange = ({ page, pageSize }) => {
    setPage(page)
    setPageSize(pageSize)
  }

  useEffect(() => {
    const onApiSuccess = response => {
      setPagination(response)
      setList(response.data)
    }

    const onApiError = error => {
      dispatch(
        Show_Alert({
          type: "error",
          msg: error.message
        })
      )
    }

    const onApiFinally = () => {
      setLoading(false)
    }

    const props = {
      patient_id: patientSelected.dados.id, 
      date_start, 
      date_end, 
      page,
      pageSize
    }

    SuspensasApi(props)
      .then(onApiSuccess)
      .catch(onApiError)
      .finally(onApiFinally)
  }, [
    date_end, 
    date_start, 
    dispatch, 
    page, 
    pageSize, 
    patientSelected, 
    patientSelected.dados.id
  ])

  function handleCallback(start, end) {
    setDate_start(moment(start).format("YYYY-MM-DD"))
    setDate_end(moment(end).format("YYYY-MM-DD"))
  }

  const suspendNotificationDatetimeFormat = datetime => {
    return moment(datetime).format('DD/MM/YYYY [ás] HH:mm')
  }

  const suspendDatetimeFormat = datetime => {
    return moment(datetime).format('HH:mm')
  }

  const suspendMinutesFormat = minutes => {
    if (minutes > 60) return `${parseInt(minutes/60)} horas`

    if (minutes > 1) return `${minutes} minutos`

    return `${minutes} minuto`
  }

  const suspendMotive = ({ motive, other_motive }) => {
    if (motive.name === 'Outros') return other_motive

    return motive.name
  }

  const onSuspendItemClick = item => {
    setItemClicked(item)
    setShowModal(true)
  }

  return (
    <div className="patients-notifications-suspends">
      {showModal && (
        <ModalSuspensas
          handler_show_modal={setShowModal}
          show={showModal}
          suspended={itemClicked}
        />
      )}
      <div className="mt-4">
        <div className="mt-3">
          <Col xs={12} md={8} xl={5}>
            <Form.Label className="field-label">Período</Form.Label>
            <Row className="justify-content-start align-items-center mb-3 mx-0">
              <DateRangePicker
                initialSettings={{
                  locale: {
                    cancelLabel: "Cancelar",
                    applyLabel: "Aplicar",
                  },
                  applyButtonClasses: "btn-dr-custom",
                }}
                onCallback={(start, end) => handleCallback(start, end)}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#ddd",
                    minHeight: 48,
                    borderRadius: 10,
                  }}
                  variant="light"
                  className="d-flex btn-date-range align-items-center"
                >
                  <Icon
                    color={Colors.darker_gray}
                    size={22}
                    icon="calendar"
                    className="mr-2"
                  />
                  <p className="m-0 text_e-left">
                    {date_start !== ""
                      ? moment(date_start).format("DD/MM/YYYY")
                      : "Data inicial"}{" "}
                    -{" "}
                    {date_end !== ""
                      ? moment(date_end).format("DD/MM/YYYY")
                      : "Data final"}
                  </p>
                </Button>
              </DateRangePicker>
            </Row>
          </Col>
        </div>
      </div>

      {loading && (
        <div className="mt-5 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      
      {!loading && list.length === 0 && (
        <div className="container-error-default mt-5">
          <p>Nenhum resultado foi encontrado.</p>
        </div>
      )}

      {!loading && list.length > 0 && (
        <div className="mt-2">
          <Table className="patient-table" responsive hover>
            <thead>
              <tr>
                <th>Aviso</th>
                <th>Horário</th>
                <th>Supenso por</th>
                <th>Motivo</th>
                <th>Responsável</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={ () => onSuspendItemClick(item) }
                    role="button"
                  >
                    <td>{ suspendNotificationDatetimeFormat(item.notification.datetime_schedule) }</td>
                    <td>{ suspendDatetimeFormat(item.created_at) }</td>
                    <td>{ suspendMinutesFormat(item.time_minutes) }</td>
                    <td className="__column-fixed">
                      <span className="u-truncate-2">
                        { suspendMotive(item.notificationMotive) }
                      </span>
                    </td>
                    <td>{ item.user.name }</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      { !loading && list.length > 0 && pagination && (
        <Pagination 
          onChange={ onPaginationChange } 
          data={ pagination }
        ></Pagination>
      )}
    </div>
  );
}

export default Suspensas;
