import React, { useEffect, useState } from "react";
import Icon from '../functions/Icon';
import "../styles/global.css";

function RadioBtn(props) {
    const [ checked, setChecked ] = useState(props.checked)
    const [ hovered, setHovered ] = useState(props.hovered)
    const handler = props.onclick
    
    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    useEffect(() => {
        setHovered(props.hovered)
    }, [props.hovered])

    return(
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            { (props.size == null || props.size == 'md') && (
                <div 
                    className={ 
                        checked 
                        ? "radiobtn checked" 
                        : 
                        hovered 
                        ? "radiobtn hovered"
                        : "radiobtn"
                    } 
                    onClick={handler}>
                    { checked && (
                        <div className="radiobtn-check"/>
                    )}
                </div>
            )}

            { props.size == 'sm' && (
                <div 
                    className={ 
                        checked 
                        ? "radiobtn sm checked" 
                        : 
                        hovered
                        ? "radiobtn sm hovered"
                        : "radiobtn sm"
                    } 
                    onClick={handler}>
                    { checked && (
                        <div className="radiobtn-check"/>
                    )}
                </div>
            )}
        </div>
    );
}

export default RadioBtn;
