import ChartDataLabels from "chartjs-plugin-datalabels";
import Tooltip from "./Tooltip";

export default {
  width: 100,
  height: 100,
  redraw: true,
  options: {
    interaction: {
      // Overrides the global setting
      mode: "index",
    },
    legend: {
      display: false,
    },
    tooltips: Tooltip,
    plugins: {
      datalabels: {
        align: "center",
        anchor: "center",
        color: function(context) {
          return context.dataset.backgroundColor;
        },
        font: function(context) {
          var w = context.chart.width;
          return {
            size: w < 512 ? 12 : 14,
            weight: "bold",
          };
        },
      },
    },
  },
  plugins: [ChartDataLabels],
};
