export const MOMENT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const TIMER_BEHIND_DEFAULT = 7200;
export const TIMER_PERIOD_DEFAULT = {
  morning: {
    timeInitial: "00:00:00",
    timeFinal: "11:59:59",
  },
  aftermoon: {
    timeInitial: "12:00:00",
    timeFinal: "23:59:59",
  },
};
