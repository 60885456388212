const INITIAL_STATE = {
  list: [],
};

function userUnit(state = INITIAL_STATE, action) {
  if (action.type === "USER_UNIT_SET") {
    return { ...state, list: action.list };
  } else if (action.type === "USER_UNIT_CLEAR") {
    state = INITIAL_STATE;
  }

  return state;
}

export default userUnit;
