import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Form,
  Dropdown,
} from "react-bootstrap";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import CheckBox from "../CheckBox";
import RadioBtn from "../RadioBtn";
import Select from "react-select";
import VerifyPwWithUser from "./VerifyPwWithUser";
import api from "../../services/api";
import { userHasPermission } from "../../functions/utils";

var unitName = "";

function ModalSensorLowSignal({ ...props }) {
  const {
    handler_modal,
    show_modal,
    beacon_name,
    beacon_id,
    unit_id,
    handler_suspend_alert,
  } = props;
  const user = useSelector((state) => state.user);

  const [showPw, setShowPw] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");

  useEffect(() => {
    function LoadUnit(unitId) {
      api.get(`/units/${unitId}`).then((response) => {
        if (response.status == 200) {
          unitName = response.data.name;
        }
      });
    }

    LoadUnit(props.unit_id);
  }, [props.unit_id]);

  function CloseModal() {
    setLoadingBtn("flex");
    api.put(`/beacons/${beacon_id}`, {
      show_room_status: false,
    });
    setShowPw(false);
    handler_suspend_alert(true);
    handler_modal(false);
  }

  function RenderPage1() {
    return (
      <Modal.Body
        className="p-4 mdd-card-warning-color"
        style={{
          borderRadius: "13px",
        }}
      >
        <div className="modal-all warning-low-signal">
          <div className="my-2">
            <Icon
              className={"m-auto d-block"}
              icon={"warning"}
              size={75}
              color={Colors.brand_red}
            />
            <div className="text-center mt-4">
              <h3 className="text-center">
                Sensor {beacon_name} está fora da {unitName}
              </h3>
              <p>
                Retorne com o sensor para desativar o alerta automaticamente ou
                suspenda manualmente através da verificação com login e senha.
              </p>
            </div>
            <button
              className="default-btn mr-auto ml-auto mt-4"
              onClick={() => {
                if (userHasPermission(user)) {
                  CloseModal();
                  return false;
                }
                setShowPw(true);
              }}
            >
              <span
                style={{
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Suspender o alerta
              </span>
              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </div>
        </div>
      </Modal.Body>
    );
  }

  return (
    <>
      <Modal
        show={true}
        onHide={() => handler_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        {showPw && (
          <VerifyPwWithUser
            handler_show_pw={setShowPw}
            show_pw={showPw}
            handler_request={CloseModal}
          />
        )}
        {RenderPage1()}
      </Modal>
    </>
  );
}

export default ModalSensorLowSignal;
