import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import Colors from "../../../styles/Colors";
import moment from "moment";
import EmptyContainer from "../../Relatorios/EmptyContainer";

function Texture(props) {
  const chartRef = useRef(null);

  const { historicWounds, typeDescriptions } = props;

  const [data, setData] = useState(null);
  const [textureDescriptions, setTextureDescriptions] = useState(null);

  useEffect(() => {
    if (historicWounds && typeDescriptions) {
      const labels = [];
      let texture = typeDescriptions.find((i) => i.name.includes("Textura"));
      let hasData = false;
      let idIncrementerHelper = 0;
      let textureDescriptions = texture.descriptions.map((i) => {
        idIncrementerHelper++;
        return {
          id: idIncrementerHelper,
          originalId: i.id,
          label: i.name,
          data: [],
          borderWidth: 4,
          pointRadius: 5,
          borderColor: Colors.brand_blue,
          backgroundColor: Colors.brand_blue,
          fill: false,
        };
      });
      setTextureDescriptions(textureDescriptions);
      let minId = textureDescriptions.reduce(
        (min, item) => (item.id < min ? item.id : min),
        textureDescriptions[0].id
      );
      for (const historic of historicWounds) {
        let date;
        if (historic.date_realized !== undefined) {
          date = moment(historic.date_realized).format("DD/MM");
        } else if (
          historic.date_confirmed !== undefined &&
          historic.date_confirmed != "Invalid date"
        ) {
          date = moment(historic.date_confirmed).format("DD/MM");
        } else {
          date = moment(historic.date_notification).format("DD/MM");
        }
        labels.push(date);
        let textureHistoric = historic.descriptions.find(
          (i) => parseInt(i.type_description_id) == texture.id
        );
        let textureDescriptionIds = (
          textureHistoric.description_id == "undefined" &&
          textureHistoric.descriptions_ids
            ? textureHistoric.descriptions_ids
            : textureHistoric.description_id
        )
          .replace("[", "")
          .replace("]", "")
          .split(",")
          .filter((i) => !isNaN(parseInt(i)))
          .map((i) => parseInt(i));
        if (textureDescriptionIds.length > 0) {
          hasData = true;
          textureDescriptions.map((i) => {
            if (
              !textureDescriptionIds.includes(i.originalId) &&
              i.label != "Ausente"
            ) {
              i.data.push(null);
            }
          });
          for (const textureDescriptionId of textureDescriptionIds) {
            let textureData = textureDescriptions.find(
              (i) => i.originalId == textureDescriptionId
            );
            textureData.data.push(textureData.id);
          }
        } else {
          textureDescriptions.map((i) => {
            if (i.label != "Ausente") {
              i.data.push(null);
            }
          });
          let noTexture = textureDescriptions.find((i) => i.label == "Ausente");
          if (noTexture) {
            noTexture.id = minId;
            noTexture.data.push(minId);
          }
        }
      }
      if (!hasData) {
        return;
      }

      const textureIds = textureDescriptions.map((i) => i.id);
      textureDescriptions.push({
        data: [Math.min(...textureIds), Math.max(...textureIds)],
        fill: false,
        borderColor: "transparent",
        backgroundColor: "transparent",
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
      });

      setData({ labels, datasets: textureDescriptions });
    }
  }, [historicWounds, typeDescriptions]);

  return (
    <>
      <Row className="mt-4">
        <Col md={12}>
          <div className="card-report">
            <h4 className="mb-0">Textura</h4>
            {data && textureDescriptions ? (
              <div className="mt-4">
                <div class="chartContainer">
                  <div
                    style={{
                      width:
                        data?.datasets[0].data.length > 7
                          ? Math.max(
                              data?.labels.length * 50,
                              window.innerWidth
                            )
                          : null,
                    }}
                  >
                    <Line
                      ref={chartRef}
                      data={data}
                      options={{
                        maintainAspectRatio: false,
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, data) {
                              if (
                                tooltipItem.datasetIndex ==
                                textureDescriptions.length - 1
                              ) {
                                return;
                              }
                              let textureLabel = textureDescriptions.find(
                                (i) => i.id == tooltipItem.yLabel
                              )?.label;
                              return `${textureLabel}: ${tooltipItem.label}`;
                            },
                          },
                        },
                        legend: {
                          display: false,
                        },
                        scales: {
                          yAxes: [
                            {
                              gridLines: {
                                borderDash: [4, 4],
                              },
                              ticks: {
                                callback: function (value, index, values) {
                                  return textureDescriptions.find(
                                    (i) => i.id == value
                                  )?.label;
                                },
                              },
                            },
                          ],
                          xAxes: [
                            {
                              gridLines: {
                                display: false,
                                drawBorder: false,
                              },
                            },
                          ],
                        },
                      }}
                      height={300}
                      width={100}
                    />
                  </div>
                </div>
                <div className="container-subtitle mt-4">
                  <div className="subtitle-item mr-4">
                    <div
                      className="indicator-radius"
                      style={{ backgroundColor: Colors.brand_blue }}
                    />
                    <p>Notificação / Evolução</p>
                  </div>
                </div>
              </div>
            ) : (
              <EmptyContainer
                text="Ainda não há dados sobre a textura da pele adjacente da lesão"
                iconName="line-chart"
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Texture;
