import React, { useEffect, useState } from "react";
import Colors from "../../../../../../../styles/Colors";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { resizeChartBasedOnWidth } from "../../../../../../../functions/utils";

function AdherenceLine(props) {
  const { adherenceData } = props;

  const [labels, setLabels] = useState(null);
  const [data, setData] = useState(null);
  const [chartWidth, setChartWidth] = useState("auto");

  useEffect(() => {
    if (adherenceData && adherenceData.length > 7) {
      setChartWidth(Math.max(adherenceData.length * 200, window.innerWidth));
    }
  }, [adherenceData]);

  useEffect(() => {
    if (adherenceData) {
      const label = [];
      const dataset = [];
      for (const adherence of adherenceData) {
        label.push(moment(Object.keys(adherence)[0]).format("DD/MM/YYYY"));
        dataset.push(
          adherence[Object.keys(adherence)[0]].metaData.adhesionRate
        );
      }
      setLabels(label);
      setData(dataset);
    }
  }, [adherenceData]);

  return (
    <>
      <div class="chartContainer">
        <div style={{ width: chartWidth }}>
          <Line
            height={400}
            width={adherenceData.length * 200}
            options={{
              maintainAspectRatio: false,
              legend: {
                display: false,
                position: "bottom",
                align: "center",
                fullWidth: true,
                labels: {
                  fontColor: "#A0A4A9",
                  usePointStyle: true,
                  fontFamily: "Rubik-Regular",
                  padding: 27,
                },
              },
              elements: {
                point: {
                  radius: 7,
                },
              },
              scales: {
                yAxes: [
                  {
                    position: "left",
                    gridLines: {
                      color: Colors.light_gray,
                      offsetGridLines: false,
                      borderDash: [6],
                      lineWidth: 1,
                    },
                    ticks: {
                      beginAtZero: true,
                      callback: function (value) {
                        if (value % 1 === 0) {
                          return value + "%";
                        }
                      },
                    },
                  },
                ],
              },
            }}
            data={{
              labels,
              datasets: [
                {
                  label: "Aderência ao protocolo (%)",
                  type: "line",
                  fill: false,
                  backgroundColor: Colors.brand_blue,
                  borderColor: Colors.brand_blue,
                  pointBackgroundColor: Colors.brand_blue,
                  pointRadius: 5,
                  pointHoverRadius: 8,
                  data,
                },
              ],
            }}
          />
        </div>
      </div>
      <div className="container-subtitle mt-4">
        <div className="subtitle-item mr-4">
          <div
            className="indicator-radius"
            style={{ backgroundColor: Colors.brand_blue }}
          />
          <p>Aderência ao protocolo (%)</p>
        </div>
      </div>
    </>
  );
}

export default AdherenceLine;
