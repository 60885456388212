import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  GetOrderIndexColor,
  resizeChartBasedOnWidth,
} from "../../functions/utils";

function AnalysisCombinationsPie(props) {
  const width = window.innerWidth;

  const { mostComorbiditiesCombinationsPercentage } = props;

  const [analysisCombination, setAnalysisCombination] = useState(null);

  useEffect(() => {
    if (mostComorbiditiesCombinationsPercentage) {
      const labels = [];
      const values = [];
      const colors = [];

      mostComorbiditiesCombinationsPercentage.forEach((item, index) => {
        labels.push(item.comparisonComorbidities);
        values.push(item.percentage);
        colors.push(GetOrderIndexColor(index));
      });

      const chartObj = {
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: colors,
            borderWidth: 0,
          },
        ],
      };

      setAnalysisCombination(chartObj);
    }
  }, [mostComorbiditiesCombinationsPercentage]);

  const optionsCombinationsPie = {
    plugins: {
      datalabels: {
        color: "#000",
        textAlign: "center",
        font: {
          size: 16,
        },
        formatter: (value) => {
          if (
            value !== 100 &&
            mostComorbiditiesCombinationsPercentage.length > 1
          ) {
            return `${value}%`;
          }
          return "";
        },
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.labels[tooltipItem.index] || "";
          let value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `${label}: ${value}%`;
        },
      },
    },
    legend: {
      position: width <= 1000 ? "bottom" : "right",
      align: "center",
      fullWidth: true,
      labels: {
        fontSize: 14,
        fontColor: "#A0A4A9",
        usePointStyle: true,
        fontFamily: "Rubik-Regular",
        padding: 27,
        generateLabels: function (chart) {
          const data = chart.data;
          return data.labels.map((label, index) => ({
            text: "  " + label,
            fillStyle: data.datasets[0].backgroundColor[index],
            strokeStyle: data.datasets[0].backgroundColor[index],
            lineWidth: 0,
            hidden:
              isNaN(data.datasets[0].data[index]) ||
              chart.getDatasetMeta(0).data[index].hidden,
            index: index,
          }));
        },
      },
    },
    layout: {
      padding: {
        right: width <= 1000 ? 0 : 130,
      },
    },
  };

  return (
    <>
      {analysisCombination && (
        <Pie
          height={200}
          data={analysisCombination}
          options={optionsCombinationsPie}
          plugins={[ChartDataLabels]}
        />
      )}
    </>
  );
}

export default AnalysisCombinationsPie;
