import React from 'react'

const Badge = ({ children, color }) => {
    const badgeColor = `is-${ color || 'brand_gray' }`
    const className = `c-badge d-inline-flex u-fs-14 text-uppercase ${ badgeColor }`
    return (
        <div 
            className={ className }
        >
            { children }
        </div>
    )
}

export default Badge