import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";
import ImageMapper from "react-image-mapper";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";

import MainNavBar from "../../../components/Nav/MainNavbar";
import "./styles.css";
import ModalWound from "../../../components/Modals/ModalWound";
import api from "../../../services/api";
import list_wounds from "../../../functions/list_wounds";
import { Show_Alert } from "../../../store/actions/alert";
import VerifyPwWithUser from "../../../components/Modals/VerifyPwWithUser";
import { userWithoutPermission } from "../../../functions/utils";

const colorDefault = "#7FA7E6";
//ORIGINAIS
const mapping_front_original = {
  name: "front",
  areas: [
    {
      id: 5,
      name: "Orelha direita",
      shape: "circle",
      coords: [140, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 6,
      name: "Orelha esquerda",
      shape: "circle",
      coords: [220, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 1,
      name: "Boca",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 8,
      name: "Ombro esquerdo",
      shape: "circle",
      coords: [280, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 9,
      name: "Ombro direito",
      shape: "circle",
      coords: [90, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 10,
      name: "Trocânter esquerdo",
      shape: "circle",
      coords: [250, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 11,
      name: "Trocânter direito",
      shape: "circle",
      coords: [120, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 43,
      name: "Anterior joelho esquerdo",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 13,
      name: "Externo joelho direto",
      shape: "circle",
      coords: [110, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 14,
      name: "Interno maléolo esquerdo",
      shape: "circle",
      coords: [210, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 15,
      name: "Interno maléolo direito",
      shape: "circle",
      coords: [160, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 16,
      name: "Hálux esquerdo",
      shape: "circle",
      coords: [230, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 17,
      name: "Hálux direito",
      shape: "circle",
      coords: [140, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 42,
      name: "Anterior joelho direto",
      shape: "circle",
      coords: [160, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 30,
      name: "Externo joelho esquerdo",
      shape: "circle",
      coords: [260, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

const mapping_back_original = {
  name: "back",
  areas: [
    {
      id: 18,
      name: "Occipital",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 20,
      name: "Escápula direita",
      shape: "circle",
      coords: [220, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 19,
      name: "Escápula esquerda",
      shape: "circle",
      coords: [140, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 21,
      name: "Coluna",
      shape: "circle",
      coords: [180, 280, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 23,
      name: "Cotovelo direito",
      shape: "circle",
      coords: [300, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 22,
      name: "Cotovelo esquerdo",
      shape: "circle",
      coords: [70, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 24,
      name: "Sacral",
      shape: "circle",
      coords: [180, 350, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 26,
      name: "Ísquio direito",
      shape: "circle",
      coords: [210, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 25,
      name: "Ísquio esquerdo",
      shape: "circle",
      coords: [150, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 28,
      name: "Calcâneo direito",
      shape: "circle",
      coords: [210, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 27,
      name: "Calcâneo esquerdo",
      shape: "circle",
      coords: [160, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 40,
      name: "Glúteo direito",
      shape: "circle",
      coords: [220, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 41,
      name: "Glúteo esquerdo",
      shape: "circle",
      coords: [140, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 36,
      name: "Interno joelho direito",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 39,
      name: "Interno joelho esquerdo",
      shape: "circle",
      coords: [155, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 37,
      name: "Externo maléolo esquerdo",
      shape: "circle",
      coords: [135, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 38,
      name: "Externo maléolo direito",
      shape: "circle",
      coords: [240, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

//USADO NA TELA
const mapping_front = {
  name: "front",
  areas: [
    {
      id: 5,
      name: "Orelha direita",
      shape: "circle",
      coords: [140, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 6,
      name: "Orelha esquerda",
      shape: "circle",
      coords: [220, 76, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 1,
      name: "Boca",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 8,
      name: "Ombro esquerdo",
      shape: "circle",
      coords: [280, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 9,
      name: "Ombro direito",
      shape: "circle",
      coords: [90, 160, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 10,
      name: "Trocânter esquerdo",
      shape: "circle",
      coords: [250, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 11,
      name: "Trocânter direito",
      shape: "circle",
      coords: [120, 370, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 43,
      name: "Anterior joelho esquerdo",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 13,
      name: "Externo joelho direto",
      shape: "circle",
      coords: [110, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 14,
      name: "Interno maléolo esquerdo",
      shape: "circle",
      coords: [210, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 15,
      name: "Interno maléolo direito",
      shape: "circle",
      coords: [160, 730, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 16,
      name: "Hálux esquerdo",
      shape: "circle",
      coords: [230, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 17,
      name: "Hálux direito",
      shape: "circle",
      coords: [140, 790, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 42,
      name: "Anterior joelho direto",
      shape: "circle",
      coords: [160, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 30,
      name: "Externo joelho esquerdo",
      shape: "circle",
      coords: [260, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

const mapping_back = {
  name: "back",
  areas: [
    {
      id: 18,
      name: "Occipital",
      shape: "circle",
      coords: [180, 110, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 20,
      name: "Escápula direita",
      shape: "circle",
      coords: [220, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 19,
      name: "Escápula esquerda",
      shape: "circle",
      coords: [140, 210, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 21,
      name: "Coluna",
      shape: "circle",
      coords: [180, 280, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 23,
      name: "Cotovelo direito",
      shape: "circle",
      coords: [300, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 22,
      name: "Cotovelo esquerdo",
      shape: "circle",
      coords: [70, 280, 20],
      preFillColor: colorDefault,
      strokeColor: "transparent",
    },
    {
      id: 24,
      name: "Sacral",
      shape: "circle",
      coords: [180, 350, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 26,
      name: "Ísquio direito",
      shape: "circle",
      coords: [210, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 25,
      name: "Ísquio esquerdo",
      shape: "circle",
      coords: [150, 430, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 28,
      name: "Calcâneo direito",
      shape: "circle",
      coords: [210, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },

    {
      id: 27,
      name: "Calcâneo esquerdo",
      shape: "circle",
      coords: [160, 780, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 40,
      name: "Glúteo direito",
      shape: "circle",
      coords: [220, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 41,
      name: "Glúteo esquerdo",
      shape: "circle",
      coords: [140, 380, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 36,
      name: "Interno joelho direito",
      shape: "circle",
      coords: [210, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 39,
      name: "Interno joelho esquerdo",
      shape: "circle",
      coords: [155, 580, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 37,
      name: "Externo maléolo esquerdo",
      shape: "circle",
      coords: [135, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
    {
      id: 38,
      name: "Externo maléolo direito",
      shape: "circle",
      coords: [240, 740, 20],
      preFillColor: colorDefault,
      fillColor: "transparent",
      strokeColor: "transparent",
    },
  ],
};

var wound = null;
var wound_id_edit = 0;
var wound_edit = [];

function NotificarLesoes({ history }) {
  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [showModalWound, setShowModalWound] = useState(false);

  const [listWounds, setListWounds] = useState([]);

  const [front, setFront] = useState(mapping_front);
  const [back, setBack] = useState(mapping_back);
  const [hoveredArea, setHoveredArea] = useState(null);
  const [hoveredAreaBack, setHoveredAreaBack] = useState(null);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [listAddWounds, setListAddWounds] = useState([]);

  const [showOtherWound, setShowOtherWound] = useState(false);
  const [otherWound, setOtherWound] = useState("");

  const [patientChooser, setPatientChooser] = useState({
    label: "Selecionar paciente",
    value: 0,
  });

  const [listPatients, setListPatients] = useState([]);

  const [gender, setGender] = useState("");

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  useEffect(() => {
    function LoadPatients() {
      api
        .get(`/patients`, {
          params: {
            unit_id: unit.selecionado?.id,
            page: 1,
            pageSize: 999,
            status: 1,
            orders: "name",
            orders_type: "ASC",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPatients");
            console.log(response.data);

            var array = [];
            response.data.data.map((item) => {
              array.push({
                label: item.name,
                value: item.id,
              });
            });
            setListPatients(array);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    }

    setPatientChooser({
      label: "Selecionar paciente",
      value: 0,
    });
    LoadPatients();
  }, [unit]);

  useEffect(() => {
    function LoadPatient() {
      api
        .get(`/patients/${patientChooser.value}`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPatient");
            console.log(response.data);

            FilterWounds(response.data.wounds);
            setGender(response.data.gender);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    }

    setLoading(true);
    setListAddWounds([]);
    LoadPatient();
  }, [patientChooser]);

  function getTipPosition(area) {
    return { top: `${area.center[1] - 30}px`, left: `${area.center[0]}px` };
  }

  useEffect(() => {
    function ResetColorBall() {
      mapping_front.areas.map((item) => {
        item.preFillColor = "rgba(127,167,230, 0.7)";
      });

      mapping_back.areas.map((item) => {
        item.preFillColor = "rgba(127,167,230, 0.7)";
      });
    }

    ResetColorBall();
  }, []);

  function renderModalOtherWound() {
    return (
      <Modal
        show={showOtherWound}
        onHide={() => {
          setShowOtherWound(false);
          setOtherWound("");
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-pw"
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <div className="modal-title">Adicionar local</div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => {
                  setShowOtherWound(false);
                  setOtherWound("");
                }}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            <Form.Label className="field-label">Local</Form.Label>
            <input
              className="default-input"
              type="text"
              placeholder="Ex.: Dedo anelar direito"
              value={otherWound}
              onChange={(e) => setOtherWound(e.target.value)}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={otherWound == "" ? true : false}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  otherWound == "" ? Colors.lighter_gray : Colors.brand_pink,
              }}
              onClick={() => {
                wound_id_edit = 0;
                setShowOtherWound(false);
                setShowModalWound(true);
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: otherWound == "" ? Colors.darker_gray : "white",
                }}
              >
                Continuar
              </p>
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }

  function FilterWounds(woundsPatient) {
    console.log("WOUNDS PATIENT");
    console.log(woundsPatient);
    //Filtra as lesoes já cadastradas (tanto nas bolas do boneco quanto no select do modal local)
    var filterFront = [];
    var filterBack = [];

    mapping_front_original.areas.map((item) => {
      var found = woundsPatient.find((i) => i.wound_id == item.id);
      if (found == undefined) {
        filterFront.push(item);
      }
    });

    mapping_back_original.areas.map((item) => {
      var found = woundsPatient.find((i) => i.wound_id == item.id);
      if (found == undefined) {
        filterBack.push(item);
      }
    });

    //List wounds select
    var listSelect = [];
    list_wounds.map((item) => {
      var found = woundsPatient.find((i) => i.wound_id == item.value);
      if (found == undefined) {
        listSelect.push(item);
      }
    });

    setListWounds(listSelect);
    mapping_front.areas = filterFront;
    mapping_back.areas = filterBack;
    setFront(mapping_front);
    setBack(mapping_back);
  }

  useEffect(() => {
    function AddColor() {
      //Filtra das que acabaram de ser adicionadas
      var filterFront = [];
      var filterBack = [];

      mapping_front.areas.map((item) => {
        var found = listAddWounds.find((i) => i.wound_id == item.id);
        if (found != undefined) {
          item.preFillColor = Colors.brand_red;
        }
        filterFront.push(item);
      });

      mapping_back.areas.map((item) => {
        var found = listAddWounds.find((i) => i.wound_id == item.id);
        if (found != undefined) {
          item.preFillColor = Colors.brand_red;
        }
        filterBack.push(item);
      });

      //List wounds select
      var listSelect = [];
      list_wounds.map((item) => {
        var found = listAddWounds.find((i) => i.wound_id == item.value);
        if (found == undefined) {
          listSelect.push(item);
        }
      });

      setListWounds(listSelect);
      mapping_front.areas = filterFront;
      mapping_back.areas = filterBack;
      setFront(mapping_front);
      setBack(mapping_back);
    }

    if (listAddWounds.length > 0) {
      AddColor();
    }
  }, [listAddWounds]);

  function mainImgWasPressed(item) {
    if (patientChooser.value == 0) return false;

    var found = listAddWounds.find((i) => i.wound_id == item.id);
    if (found == undefined) {
      wound_id_edit = 0;
      wound = item;
      setShowModalWound(true);
    } else {
      wound_id_edit = item.id;
      setShowModalWound(true);
    }
  }

  function RegisterWound() {
    setLoadingBtn("flex");

    listAddWounds.map((wound, index) => {
      //Descriptions
      var arrayDescriptions = [];
      wound.descriptions.map((item) => {
        var obj = {};
        obj.type_description_id = item.type_description_id;
        if (item.description_ids) {
          obj.description_ids = item.description_ids;
        } else {
          obj.description_id = item.description_id;
        }
        obj.value = item.value;
        arrayDescriptions.push(obj);
      });

      /* Add nova seção quando clica no btn "OUTROS" */
      if (wound.type_description_name != "" && wound.value > 0) {
        arrayDescriptions.push({
          type_description_name: wound.type_description_name,
          value: wound.value,
        });
      }

      api
        .post(`/patients/${patientChooser.value}/wounds`, {
          medical_devices: wound.medical_devices,
          type: wound.type,
          wound_name: wound.wound_name,
          wound_id: wound.is_other == true ? 0 : wound.wound_id,
          stage_id: wound.stage_id,
          note: wound.note,
          user_id: wound.user.value,
          height: wound.height,
          width: wound.width,
          depth: wound.depth,
          descriptions: arrayDescriptions,
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("RegisterWound success ");
            console.log(response.data);
            let last = false;
            if (listAddWounds.length - 1 == index) {
              last = true;
            }
            SaveImages(response.data.id, wound.images, last);
          }
        })
        .catch((error) => {
          console.log("RegisterWound error " + error);

          Show_Alert({
            type: "error",
            msg: "Não foi possível cadastrar essa lesão.",
          });
          setLoadingBtn("none");
        });
    });
  }

  function SaveImages(idPatientWound, images, last) {
    //Verifica se tem imagem nova pra enviar
    if (last && images.length == 0) {
      dispatch(
        Show_Alert({
          type: "success",
          msg:
            listAddWounds.length == 1
              ? "Lesão notificada com sucesso"
              : "Lesões notificadas com sucesso",
        })
      );

      // handler_reload(reload == true ? false : true);
      // handler_show_wound_add(false);
      return false;
    }

    if (images.length == 0) {
      return false;
    }

    var formData = new FormData();
    images.map((item) => {
      formData.append("image[]", item.file);
      formData.append("filename", item.fileName);
    });

    api
      .post(
        `/patients/${patientChooser.value}/wounds/${idPatientWound}/images`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData.boundary}`,
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          if (last) {
            console.log("SaveImages success");
            console.log(response.data);
            dispatch(
              Show_Alert({
                type: "success",
                msg:
                  listAddWounds.length == 1
                    ? "Lesão notificada com sucesso"
                    : "Lesões notificadas com sucesso",
              })
            );

            history.push({ pathname: "/Home" });
            return false;
          }
        }
      })
      .catch((error) => {
        console.log("SaveImages error " + error);

        Show_Alert({
          type: "error",
          msg: "Não foi possível salvar as novas imagens da lesão",
        });
      })
      .finally(() => setLoadingBtn("none"));
  }

  function RegisterWound(userId) {
    setLoadingBtn("flex");
    listAddWounds.map((wound, index) => {
      //Descriptions
      var arrayDescriptions = [];
      wound.descriptions.map((item) => {
        var obj = {};
        obj.type_description_id = item.type_description_id;
        obj.description_id = item.description_id;
        if (item.description_ids) {
          obj.description_ids = item.description_ids;
        } else {
          obj.description_id = item.description_id;
        }
        obj.value = item.value;
        arrayDescriptions.push(obj);
      });

      /* Add nova seção quando clica no btn "OUTROS" */
      if (wound.type_description_name != "" && wound.value > 0) {
        arrayDescriptions.push({
          type_description_name: wound.type_description_name,
          value: wound.value,
        });
      }

      api
        .post(`/patients/${patientChooser.value}/wounds`, {
          medical_devices: wound.medical_devices,
          type: wound.type,
          wound_name: wound.wound_name,
          wound_id: wound.is_other == true ? 0 : wound.wound_id,
          stage_id: wound.stage_id,
          note: wound.note,
          user_id: userId || wound.user.value,
          height: wound.height,
          width: wound.width,
          depth: wound.depth,
          descriptions: arrayDescriptions,
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("RegisterWound success ");
            console.log(response.data);
            let last = false;
            if (listAddWounds.length - 1 == index) {
              last = true;
            }
            SaveImages(response.data.id, wound.images, last);
          }
        })
        .catch((error) => {
          console.log("RegisterWound error " + error);

          Show_Alert({
            type: "error",
            msg: "Não foi possível cadastrar essa lesão.",
          });
          setLoadingBtn("none");
        });
    });
  }

  function SaveImages(idPatientWound, images, last) {
    //Verifica se tem imagem nova pra enviar
    if (last && images.length == 0) {
      dispatch(
        Show_Alert({
          type: "success",
          msg:
            listAddWounds.length == 1
              ? "Lesão notificada com sucesso"
              : "Lesões notificadas com sucesso",
        })
      );

      history.push({ pathname: "/Home" });
      return false;
    }

    if (images.length == 0) {
      return false;
    }

    var formData = new FormData();
    images.map((item) => {
      formData.append("image[]", item.file);
      formData.append("filename", item.fileName);
    });

    api
      .post(
        `/patients/${patientChooser.value}/wounds/${idPatientWound}/images`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData.boundary}`,
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          if (last) {
            console.log("SaveImages success");
            console.log(response.data);
            dispatch(
              Show_Alert({
                type: "success",
                msg:
                  listAddWounds.length == 1
                    ? "Lesão notificada com sucesso"
                    : "Lesões notificadas com sucesso",
              })
            );

            history.push({ pathname: "/Home" });
            return false;
          }
        }
      })
      .catch((error) => {
        console.log("SaveImages error " + error);

        Show_Alert({
          type: "error",
          msg: "Não foi possível salvar as novas imagens da lesão",
        });
      })
      .finally(() => setLoadingBtn("none"));
  }

  return (
    <div>
      <MainNavBar history={history} />

      {showOtherWound && renderModalOtherWound()}

      {showModalWound && (
        <ModalWound
          handler_show_wound={setShowModalWound}
          show_wound={showModalWound}
          list_wounds={listWounds}
          wound_add={wound}
          handler_list_add={setListAddWounds}
          list_add={listAddWounds}
          wound_id_edit={wound_id_edit}
          handler_other_wound={setOtherWound}
          other_wound={otherWound}
          wound_edit={wound_edit}
        />
      )}

      <Container fluid className="main-container">
        <Row
          className="w-100 mb-4"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Col xs={12}>
            <div className="page-title">Notificar lesão por pressão</div>
          </Col>
        </Row>

        <div className="mb-45 w-100" style={{ maxWidth: 500, zIndex: 999 }}>
          <div className="d-flex justify-content-between align-items-center mb-1">
            <Form.Label className="field-label">Paciente</Form.Label>
            <button
              className="btn-noti"
              onClick={() => {
                history.push({
                  pathname: "/Pacientes",
                  state: { addPatient: true },
                });
              }}
            >
              + Novo paciente
            </button>
          </div>
          <Select
            captureMenuScroll={false}
            noOptionsMessage={() => "Sem opções"}
            classNamePrefix="react-select"
            placeholder={"Selecionar"}
            options={listPatients}
            isClearable={false}
            value={patientChooser}
            onChange={(item) => {
              setPatientChooser(item);
            }}
          />
        </div>

        {loading && (
          <div className="mt-3 d-flex justify-content-center w-100 mt-4">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {!loading && (
          <div className="d-flex flex-wrap mt-4">
            <div>
              <p className="wound-modal-title">Selecione o local da lesão</p>

              {front.name != undefined && back.name != undefined && (
                <div className="mb-5 mt-2">
                  <div className="d-flex">
                    <div className="mr-4 container-model">
                      <ImageMapper
                        src={require(`../../../assets/img/model-human/${
                          gender == "F" ? "female" : "men"
                        }-front.jpg`)}
                        width={164}
                        imgWidth={367}
                        map={front}
                        onClick={(item) => mainImgWasPressed(item)}
                        onMouseEnter={(area) => setHoveredArea(area)}
                        onMouseLeave={(area) => setHoveredArea(null)}
                      />
                      {hoveredArea != null && (
                        <span
                          className="tooltip-model"
                          style={{ ...getTipPosition(hoveredArea) }}
                        >
                          {hoveredArea.name}
                        </span>
                      )}
                    </div>

                    <div className="mr-5 container-model">
                      <ImageMapper
                        src={require(`../../../assets/img/model-human/${
                          gender == "F" ? "female" : "men"
                        }-back.jpg`)}
                        width={164}
                        imgWidth={367}
                        map={back}
                        onClick={(item) => mainImgWasPressed(item)}
                        onMouseEnter={(area) => setHoveredAreaBack(area)}
                        onMouseLeave={(area) => setHoveredAreaBack(null)}
                      />
                      {hoveredAreaBack != null && (
                        <span
                          className="tooltip-model"
                          style={{ ...getTipPosition(hoveredAreaBack) }}
                        >
                          {hoveredAreaBack.name}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div
                className="d-flex justify-content-center align-items-center container-other-wound"
                style={{ maxWidth: 326 }}
                onClick={() => {
                  setShowOtherWound(showOtherWound == true ? false : true);
                }}
              >
                <Icon
                  color={Colors.brand_pink}
                  size={18}
                  icon={"plus"}
                  className="mr-2"
                />
                <p>Adicionar outro local</p>
              </div>
            </div>

            <div className="container-wound-add-home">
              <p className="wound-modal-title">Lesões adicionadas</p>

              {listAddWounds.length == 0 && (
                <div className="container-error-default mt-3">
                  <p>Nenhuma lesão adicionada</p>
                </div>
              )}

              <div className="d-flex justify-content-between flex-wrap">
                {listAddWounds.map((item) => {
                  return (
                    <div
                      className="card-new-wound-home mt-3 position-relative"
                      onClick={() => {
                        wound_id_edit = item.wound_id;
                        wound_edit = item;
                        setShowModalWound(true);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className="ball-wound mr-2"
                          style={{ backgroundColor: Colors.brand_red }}
                        />
                        <p className="name-wound-add">{item.wound_name}</p>
                      </div>

                      <p className="stage-wound-add mt-2">
                        {item.stage_name} {item.stage_name && "-"} {""}
                        {item.type == "incidence" ? "Incidência" : "Admissão"}
                      </p>

                      <div className="btn-wound-edit">
                        <Icon
                          color={Colors.darkest_gray}
                          size={18}
                          icon={"edit"}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-flex justify-content-end w-100">
              <button
                disabled={listAddWounds.length > 0 ? false : true}
                className="mb-0 modal-footer-btn btn-confirm mt-4"
                style={{
                  backgroundColor:
                    listAddWounds.length > 0
                      ? Colors.brand_pink
                      : Colors.lighter_gray,
                  maxWidth: 200,
                }}
                onClick={() => {
                  if (userWithoutPermission(user)) {
                    setShowPasswordModal(true);
                  } else {
                    RegisterWound();
                  }
                }}
              >
                <p
                  className="mb-0"
                  style={{
                    color:
                      listAddWounds.length > 0
                        ? Colors.white
                        : Colors.dark_gray,
                    display: loadingBtn == "none" ? "flex" : "none",
                  }}
                >
                  Notificar lesão
                </p>

                <Spinner
                  style={{ display: loadingBtn }}
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                />
              </button>
            </div>
          </div>
        )}
      </Container>
      {showPasswordModal && (
        <VerifyPwWithUser
          handler_show_pw={setShowPasswordModal}
          show_pw={showPasswordModal}
          modal_name={"Autenticar notificação"}
          handler_request={RegisterWound}
        />
      )}
    </div>
  );
}

export default NotificarLesoes;
