import api from "@Services/api"

const SuspensasApi = async ({ page, pageSize, patient_id, date_start, date_end, orders, orders_type }) => {
    if (!patient_id) throw new Error('`patient_id` is empty')
    if (!date_start) throw new Error('`date_start` is empty')
    if (!date_end) throw new Error('`date_end` is empty')

    const endpoint = `/patients/${patient_id}/report-notifications/suspended`
    const queryParams = {
        page: page || 1,
        pageSize: pageSize || 20,
        date_start,
        date_end,
        orders: orders || 'datetime_final',
        orders_type: orders_type || 'DESC'
    }

    try {
        const response = await api.get(endpoint, { params: queryParams })
        if (response.status !== 200) throw new Error(response.statusText)
        
        return response.data
    } catch (error) {
        throw new Error('Não foi possível carregar a lista de mudanças de decúbito esquecidas')
    }
}

export default SuspensasApi