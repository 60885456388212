import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Spinner, Table } from "react-bootstrap";
import api from "../../services/api";
import {
  GetColorAvaliation,
  secondsToHoursMinutes,
} from "../../functions/utils";
import Colors from "../../styles/Colors";
import Icon from "../../functions/Icon";
import EmptyContainer from "./EmptyContainer";
import moment from "moment";
import "./styles.css";
import GeneralDisplayedData from "../../components/Dropdowns/GeneralDisplayedData";

function PatientsGeneralData(props) {
  const {
    ids,
    areaId,
    dateInitial,
    dateFinal,
    situation,
    type,
    woundType,
    removeColumns,
    isMain,
  } = props;

  const unit = useSelector((state) => state.unit);
  const savedHeaders = useSelector((state) => state.generalDataHeaders.headers);

  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const sortData = (field) => {
    const isAsc = sortField === field && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortField(field);

    const sortedData = [...patientsGeneralData].sort((a, b) => {
      switch (field) {
        case "name":
        case "status":
        case "score":
        case "dischargeHospitalDate":
        case "scoreDescription":
          return isAsc
            ? a[field]?.localeCompare(b[field])
            : b[field]?.localeCompare(a[field]);
        case "woundsCount":
          return isAsc ? b[field] - a[field] : a[field] - b[field];
        case "riskFactorsCount":
          const totalRiskA = a.comorbiditesCount + a.riskFactorsCount;
          const totalRiskB = b.comorbiditesCount + b.riskFactorsCount;
          return isAsc ? totalRiskB - totalRiskA : totalRiskA - totalRiskB;
        case "monitoredTime":
          return isAsc ? b[field] - a[field] : a[field] - b[field];
        case "createdAt":
          return isAsc
            ? new Date(b[field]) - new Date(a[field])
            : new Date(a[field]) - new Date(b[field]);
        default:
          return 0;
      }
    });

    setPatientsGeneralData(sortedData);
  };

  const [loading, setLoading] = useState(false);
  const [showInternalModal, setShowInternalModal] = useState(false);
  const [patientsGeneralData, setPatientsGeneralData] = useState(null);

  const [headers, setHeaders] = useState(
    isMain && savedHeaders.length > 0
      ? savedHeaders
      : [
          { name: "id", label: "Nº", selected: true, immutable: true },
          { name: "name", label: "Nome", selected: true },
          { name: "room", label: "Leito", selected: true },
          { name: "woundsCount", label: "Qntd. de lesões", selected: true },
          {
            name: "scoreDescription",
            label: "Avaliação de risco",
            selected: true,
          },
          { name: "woundStage", label: "Estágio", selected: true },
          {
            name: "woundConfirmationDate",
            label: "Data de confirmação",
            selected: true,
          },
          { name: "status", label: "Status", selected: true },
          {
            name: "riskFactorsCount",
            label: "Fatores de risco",
            selected: true,
          },
          { name: "createdAt", label: "Admissão", selected: true },
          { name: "dischargeHospitalDate", label: "Alta", selected: true },
          { name: "monitoredTime", label: "Tempo monitorado", selected: true },
        ]
  );

  const [isPrinting, setIsPrinting] = useState(false);

  const handleBeforePrint = () => {
    setIsPrinting(true);
  };

  const handleAfterPrint = () => {
    setIsPrinting(false);
  };

  useEffect(() => {
    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  useEffect(() => {
    if (unit.selecionado?.id != undefined) {
      LoadPatientsGeneralData();
    }
  }, [unit, dateInitial, dateFinal, situation, woundType]);

  useEffect(() => {
    if (removeColumns && removeColumns.length > 0) {
      setHeaders(
        headers.filter((header) => !removeColumns.includes(header.label))
      );
    }
  }, [removeColumns]);

  function LoadPatientsGeneralData() {
    setLoading(true);
    api
      .get(`/dashboard/patients-general-data`, {
        params: {
          ids: ids?.join(","),
          areaId,
          unitId: unit.selecionado.id,
          dateInitial,
          dateFinal,
          situation,
          type,
          woundType,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setPatientsGeneralData(
            response.data.sort((a, b) => {
              if (a.woundsCount < b.woundsCount) return 1;
              if (a.woundsCount > b.woundsCount) return -1;
              if (a.score === 0 && b.score !== 0) return 1;
              if (a.score !== 0 && b.score === 0) return -1;
              if (a.score > 18 && b.score <= 18) return 1;
              if (a.score <= 18 && b.score > 18) return -1;
              if (a.score > b.score) return 1;
              if (a.score < b.score) return -1;
              if (a.comorbiditesCount < b.comorbiditesCount) return 1;
              if (a.comorbiditesCount > b.comorbiditesCount) return -1;
              return 0;
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      {loading ? (
        <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <div>
            {patientsGeneralData && patientsGeneralData.length > 0 ? (
              <>
                <PatientsTable
                  type={type}
                  data={patientsGeneralData}
                  headers={headers}
                  limit={isPrinting ? null : 3}
                  onHeaderClick={sortData}
                />
                {type === "general" &&
                  !isPrinting && ( // Só exibe o botão se não estiver imprimindo
                    <div className="mt-4 mb-4 d-flex justify-content-center">
                      <button
                        className="btn-visu"
                        onClick={() => setShowInternalModal(true)}
                      >
                        Visualizar lista completa
                      </button>
                    </div>
                  )}
              </>
            ) : (
              <EmptyContainer
                text="Ainda não há dados sobre pacientes de risco"
                iconName="description_1"
              />
            )}
          </div>
        </>
      )}
      {showInternalModal && !isPrinting && (
        <InternalModal
          show_modal={showInternalModal}
          handler_show_modal={setShowInternalModal}
          patientsGeneralData={patientsGeneralData}
          headers={savedHeaders.length > 0 ? savedHeaders : headers}
          headersHandler={setHeaders}
          type={type}
          onHeaderClick={sortData}
        />
      )}
    </div>
  );
}

function PatientsTable({ type, limit, data, headers, onHeaderClick }) {
  const dispatch = useDispatch();

  const tableLimit = window.matchMedia("print").matches ? null : limit;

  const handleMouseDown = (patientItem, e) => {
    dispatch({
      type: "SET_PATIENT_SELECTED",
      dados: { id: patientItem.id, name: patientItem.name },
    });
    if (e.button === 0) {
      dispatch({ type: "RESET_PATIENT_TAB" });
      window.location.href = "/Pacientes/Perfil-Paciente";
    } else if (e.button === 1) {
      window.open("/Pacientes/Perfil-Paciente", "_blank");
    }
  };

  const headerByName = (headerName) => {
    return headers.find((header) => header.name === headerName);
  };

  return (
    <Table responsive hover className="patient-table">
      <thead>
        <tr>
          {headers.map((header, index) => {
            if (!header.selected) return null;
            return (
              <th key={index} onClick={() => onHeaderClick(header.name)}>
                {header.label}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((patientItem, index) => {
            index++;
            if (type === "general" && limit && limit > 0 && index > limit) {
              return null;
            }
            return (
              <tr
                key={index}
                onMouseDown={(e) => handleMouseDown(patientItem, e)}
              >
                <td>{index}</td>
                {headerByName("name").selected && <td>{patientItem.name}</td>}
                {headerByName("room").selected && <td>{patientItem.room}</td>}
                {(type === "general" ||
                  type === "populationalData" ||
                  type === "indicators") &&
                  headerByName("woundsCount").selected && (
                    <td
                      style={{
                        minWidth: "200px",
                      }}
                    >
                      {patientItem.woundsAdmissionCount > 0 ||
                      patientItem.woundsIncidenceCount > 0
                        ? `${
                            patientItem.woundsAdmissionCount > 0
                              ? `${patientItem.woundsAdmissionCount} ${
                                  patientItem.woundsAdmissionCount > 1
                                    ? "admitidas"
                                    : "admitida"
                                }`
                              : ""
                          }${
                            patientItem.woundsAdmissionCount > 0 &&
                            patientItem.woundsIncidenceCount > 0
                              ? " | "
                              : ""
                          }${
                            patientItem.woundsIncidenceCount > 0
                              ? `${patientItem.woundsIncidenceCount} ${
                                  patientItem.woundsIncidenceCount > 1
                                    ? "incididas"
                                    : "incidida"
                                }`
                              : ""
                          }`
                        : patientItem.admissionWound !== null
                        ? "Nenhuma lesão"
                        : "Não informado"}
                    </td>
                  )}
                {(type === "general" || type === "indicators") &&
                  headerByName("scoreDescription").selected && (
                    <td
                      style={{
                        minWidth: "200px",
                      }}
                    >
                      <div className="d-flex">
                        <div
                          style={{
                            transform: "translateY(3px)",
                            width: 14,
                            height: 14,
                            borderRadius: 14,
                            backgroundColor: GetColorAvaliation(
                              patientItem.score
                            ),
                            marginLeft: 1,
                            marginRight: 11,
                          }}
                        ></div>
                        {patientItem.scoreDescription}
                      </div>
                    </td>
                  )}
                {type === "incidenceArea" && (
                  <>
                    <td>{patientItem.woundStage}</td>
                    <td>
                      {moment(patientItem.woundConfirmationDate).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                  </>
                )}
                {headerByName("status").selected && (
                  <td>{patientItem.status}</td>
                )}
                {(type === "general" || type === "indicators") &&
                  headerByName("riskFactorsCount").selected && (
                    <td>
                      {patientItem.comorbiditesCount > 0 ||
                      patientItem.riskFactorsCount > 0 ? (
                        <>
                          {patientItem.comorbiditesCount > 0 && (
                            <p>
                              {patientItem.comorbiditesCount}{" "}
                              {patientItem.comorbiditesCount > 1
                                ? "comorbidades"
                                : "comorbidade"}
                            </p>
                          )}
                          {patientItem.riskFactorsCount > 0 && (
                            <p>
                              {patientItem.riskFactorsCount}{" "}
                              {patientItem.riskFactorsCount > 1
                                ? "estados situacionais"
                                : "estado situacional"}
                            </p>
                          )}
                        </>
                      ) : (
                        <p>Sem fatores de risco</p>
                      )}
                    </td>
                  )}
                {type === "general" && (
                  <>
                    {headerByName("createdAt").selected && (
                      <td>
                        {moment(patientItem.createdAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </td>
                    )}
                    {headerByName("dischargeHospitalDate").selected && (
                      <td>
                        {patientItem.dischargeHospitalDate
                          ? moment(patientItem.dischargeHospitalDate).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : "Sem informação"}
                      </td>
                    )}
                    {headerByName("monitoredTime").selected && (
                      <td>
                        {secondsToHoursMinutes(patientItem.monitoredTime, true)}
                      </td>
                    )}
                  </>
                )}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}

function InternalModal(props) {
  const {
    show_modal,
    handler_show_modal,
    patientsGeneralData,
    headers,
    headersHandler,
    type,
    onHeaderClick,
  } = props;

  return (
    <Modal
      className="populational-data-patients-wound-info-modal"
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      centered
    >
      <div className="card-report populational-data-patients-wound-info">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <p
              style={{
                fontSize: "24px",
                fontFamily: "var(--medium)",
                marginBottom: "5px",
              }}
            >
              Pacientes
            </p>
            <p
              style={{
                fontSize: "16px",
                fontFamily: "var(--medium)",
                color: Colors.darker_gray,
              }}
            >
              {patientsGeneralData.length} pacientes
            </p>
          </div>
          <GeneralDisplayedData headers={headers} handler={headersHandler} />
        </div>
        <div>
          {patientsGeneralData ? (
            <PatientsTable
              data={patientsGeneralData}
              headers={headers}
              type={type}
              onHeaderClick={onHeaderClick}
            />
          ) : (
            <div>Nenhum dado disponível</div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default PatientsGeneralData;
