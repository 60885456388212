import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";

function ModalInfo(props) {
  const dispatch = useDispatch();
  const { type_id, show_modal, handler_show_modal } = props;
  const [infoModal, setInfoModal] = useState({});

  useEffect(() => {
    function LoadInfo() {
      api
        .get("/ranking/infos")
        .then((response) => {
          if (response.status == 200) {
            console.log("Load info");
            console.log(response.data);

            var found = response.data.find(
              (item) => item.ranking_type_id == type_id
            );
            setInfoModal(found);
          }
        })
        .catch((error) => {
          console.log("LoadInfo " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as informações das seções.",
            })
          );
        });
    }

    LoadInfo();
  }, []);

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">{infoModal.title}</div>
            <div className="modal-subtitle">{infoModal.subtitle}</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all mt-2">
          <div className="container-medal-details">
            {infoModal.content != undefined &&
              infoModal.content.map((item, index) => {
                return (
                  <div key={index} className="medal-details">
                    <p className="medal-details-title mb-0">{item.stage}</p>
                    <p className="medal-details-subtitle mb-0">{item.point}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalInfo;
