import { getColor } from "../../src/support/utils";
import { createStripePattern } from "./Utils";

export default function Datasets() {
  const schedules = this.period.getSchedules();
  const historic = this.period.getHistorics();
  console.log("THISSSSSSSS", this.period);

  return {
    // nivel 2 - posicoes
    positions: () => {
      const labels = schedules.map((item) => {
        const { position } = item;
        return position.name;
      });

      return {
        data: schedules.map((item) => item.time),
        schedules,
        backgroundColor: schedules.map((item) => {
          const { position } = item;
          return getColor(position.name);
        }),
        labels,
        dataType: "positions",
        datalabels: {
          formatter(value, context) {
            return labels[context.dataIndex];
          },
          font: {
            size: 10,
          },
          color: "#FFF",
        },
      };
    },

    timer: () => {
      // nivel 3 - horario
      const labels = schedules.map((item) => item.label);
      return {
        data: schedules.map((item) => item.time),
        backgroundColor: new Array(schedules.length).fill("rgb(255,255,255)"),
        labels,
        dataType: "timer",
        datalabels: {
          formatter(value, context) {
            return labels[context.dataIndex];
          },
          color: "rgb(77, 77, 77)",
          font: {
            size: 13,
            weight: "bold",
          },
        },
      };
    },

    historic: () => {
      // nivel 1 - tempo que o sensor ficou na posicao
      if (historic.length === 0) {
        historic.push({
          time: 3600 * 12,
          positionItem: {
            name: "Não há dados",
          },
        });
      }
      const labels = historic.map((item) => {
        const { positionItem: position } = item;
        return position.name;
      });
      return {
        data: historic.map((item) => item.time),
        historic,
        dataType: "historic",
        title: "Registro do sensor",
        backgroundColor: historic.map((item) => {
          const { positionItem: position } = item;
          // Aqui modificamos para usar a função getColor com o parâmetro dark igual a true
          const color = item.suspension
            ? createStripePattern(
                getColor(position.name, true),
                item?.suspensionItem.is_paused
              )
            : getColor(position.name, true); // Adicionamos true aqui para as cores escuras
          return color;
        }),
        labels,
        datalabels: {
          formatter() {
            return null;
          },
        },
      };
    },

    json: (timer, positions, historic) => {
      console.log("json____________________________");
      console.log("data", [historic, positions, timer]);
      console.log("json____________________________");

      //poisitions.data, positions.labels e positions.schedule
      //timer.data, timer.labels e timer.background

      return {
        data: {
          datasets: [historic, positions, timer],
        },
      };
    },
  };
}
