import React, { useEffect, useState } from "react";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import { Doughnut } from "react-chartjs-2";
import ModalPatientsRisks from "./ModalData/ModalPatientsRisks";
import EmptyContainer from "./EmptyContainer";

function Gender(props) {
  const { genders } = props;

  const [gender, setGender] = useState(null);
  const [gendersEmpty, setGendersEmpty] = useState(false);
  const [percentageMale, setPercentageMale] = useState("0");
  const [percentageFemale, setPercentageFemale] = useState("0");

  const [patientIds, setPatientIds] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  useEffect(() => {
    if (genders) {
      const arrayGender = [];
      const arrayCounter = [];
      let genderEmptyVerify = true;
      genders.map((item, index) => {
        if (genderEmptyVerify && item.counter > 0) genderEmptyVerify = false;
        arrayCounter.push(item.counter);
        if (item.gender_type == "M") {
          setPercentageMale(item.percentage);
          arrayGender.push(item.percentage);
        }
        if (item.gender_type == "F") {
          setPercentageFemale(item.percentage);
          arrayGender.push(item.percentage);
        }
      });
      setGendersEmpty(genderEmptyVerify);

      const objGender = {
        labels: ["Mulheres", "Homens"],
        datasets: [
          {
            counter: arrayCounter,
            data: arrayGender,
            backgroundColor: [Colors.brand_pink, Colors.brand_blue],
            borderWidth: 0,
          },
        ],
      };
      setGender(objGender);
    }
  }, [genders]);

  const optionsGender = {
    onClick: function (evt, item) {
      if (item.length > 0) {
        const index = item[0]._index;
        const label = gender.labels[index];
        const counter = gender.datasets[0].counter[index];
        handleModalPatientRisks(
          "Pacientes do sexo " + genders[index].gender_name.toLowerCase(),
          genders[index].patientIds,
          genders[index].percentage
        );
        setShowPatientsRisksData(true);
      }
    },
    onHover: function (event, chartElement) {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    legend: {
      display: false,
      labels: {
        fontColor: Colors.darker_gray,
        fontFamily: "Rubik-Regular",
      },
    },
    cutoutPercentage: 76,
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return data["labels"][tooltipItem[0]["index"]];
        },
        label: function (tooltipItem, data) {
          var temp = data["datasets"][0]["counter"][tooltipItem["index"]];
          return `Quant. de pacientes: ${temp}`;
        },
      },
    },
  };

  function handleModalPatientRisks(title, patientIds, percentage) {
    setPatientIds(patientIds);
    setSelectedData({
      label: title,
      patientQuantity: patientIds.length,
      percentage,
    });
    setShowPatientsRisksData(true);
  }

  return (
    <div className="genders h-100">
      <h4>Gênero</h4>

      <div className="h-100 d-flex justify-content-center align-items-center flex-column flex-md-row">
        {gender ? (
          <>
            {!gendersEmpty ? (
              <>
                <div className="gender-item">
                  <Icon color={Colors.brand_blue} size={50} icon={"male"} />
                  <p className="gender-percentage">{percentageMale}%</p>
                  <p className="gender-title">Homens</p>
                </div>
                <div className="my-5 my-md-0">
                  <Doughnut
                    height={250}
                    data={gender}
                    options={optionsGender}
                  />
                </div>
                <div className="gender-item">
                  <Icon color={Colors.brand_pink} size={50} icon={"female"} />
                  <p className="gender-percentage">{percentageFemale}%</p>
                  <p className="gender-title">Mulheres</p>
                </div>
              </>
            ) : (
              <EmptyContainer
                text="Ainda não há dados sobre gêneros"
                iconName="description_1"
              />
            )}
          </>
        ) : (
          <EmptyContainer
            text="Ainda não há dados sobre gêneros"
            iconName="description_1"
          />
        )}
      </div>

      {showPatientsRisksData && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={selectedData?.label}
          subTitle={
            `${selectedData?.patientQuantity} ${
              selectedData?.patientQuantity === 1 ? "paciente" : "pacientes"
            }` +
            " - " +
            selectedData?.percentage +
            "%"
          }
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Fatores de risco",
            "Avaliação de risco",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="populationalData"
        />
      )}
    </div>
  );
}

export default Gender;
