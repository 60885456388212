const INITIAL_STATE = {
  selected: null,
};

function patientHomeTab(state = INITIAL_STATE, action) {
  if (action.type === "SET_TAB_PATIENT_PAGE") {
    return { ...state, selected: action.selected };
  } else if (action.type === "RESET_TAB_PATIENT_PAGE") {
    state = INITIAL_STATE;
  }

  return state;
}

export default patientHomeTab;
