import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import api from "../../../../../../../../services/api";
import { Show_Alert } from "../../../../../../../../store/actions/alert";
import Icon from "../../../../../../../../functions/Icon";
import Colors from "../../../../../../../../styles/Colors";
import { HexToRgbA } from "../../../../../../../../functions/utils";
import moment from "moment";

function ModalDupla({ ...props }) {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const { handler_show_modal, show, dados } = props;

  function renderNurses() {
    if (dados.nurses.length <= 1) {
      return (
        <Row>
          {dados.nurses.map((item, index) => {
            return (
              <div
                key={index}
                className="w-100"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: "100%",
                    }}
                    src={
                      item.user_image != null
                        ? item.user_image
                        : require("../../../../../../../../assets/img/icon_user.png")
                    }
                  />

                  <div className="dados-default">
                    <div className="name-default">por {item.user_name}</div>
                    <div className="date-default">
                      {moment(dados.datetime_realized).format("LLL")}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      );
    } else {
      var name_nurses = [];
      dados.nurses.map((item, index) => {
        name_nurses.push(item.user_name);
      });
      return (
        <Row>
          <div
            className="w-100"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="d-flex">
                <img
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: "100%",
                    zIndex: 10,
                  }}
                  src={
                    dados.nurses[0].user_image != null
                      ? dados.nurses[0].user_image
                      : require("../../../../../../../../assets/img/icon_user.png")
                  }
                />
                <div className="container-more-nurses">
                  +{dados.nurses.length - 1}
                </div>
              </div>

              <div className="dados-default">
                <div className="name-default">por {name_nurses.join(", ")}</div>
                <div className="date-default">
                  {moment(dados.datetime_realized).format("LLL")}
                </div>
              </div>
            </div>
          </div>
        </Row>
      );
    }
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={() => handler_show_modal(false)}
        centered
        className="modal-decubito"
      >
        <Modal.Body
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            paddingTop: 5,
            paddingBottom: 15,
          }}
        >
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Col xs={6} md={6} lg={6} className="pl-0">
              <div
                className="status-tag"
                style={{
                  backgroundColor: Colors.brand_green,
                }}
              >
                DUPLA CHECAGEM
              </div>
            </Col>

            <Col
              xs={2}
              md={6}
              lg={6}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <Row className="mb-3 align-items-center">
            <p className="modal-title-sm">Detalhes da mudança de decúbito</p>
          </Row>

          <Row className="mb-2 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="calendar"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {moment(dados.datetime_schedule).format("LL")}
            </div>
          </Row>

          <Row className="mb-2 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="clock"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {moment(dados.datetime_schedule).format("LT")}
            </div>
          </Row>

          <Row className="mb-3 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="refresh"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {dados.position}
            </div>
          </Row>

          {renderNurses()}

          <Row className="mt-4 card-bottom-confirmed-double  align-items-center">
            <Icon
              color={Colors.brand_green}
              size={18}
              icon={"check-circle"}
              className="mr-2"
            />
            <p className="mb-0">
              Confirmada por {dados.nurse_doublecheck_name},{" "}
              {moment(dados.datetime_doublecheck).format("LLL")}
            </p>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalDupla;
