import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import { Link } from "react-router-dom";

import perfil from "../../assets/img/exemplo-perfil.jpg";
import { RemoveToken, RemoveUser } from "../../functions/storage";

import { useDispatch, useSelector } from "react-redux";

export const renderPhoto = (user, size = null) => {
  if (user && user.dados && user.dados.image != null)
    return (
      <img
        src={user.dados.url_image}
        className={`c-profile-dropdown__img ${
          size && size === "lg" ? "is-lg" : ""
        }`.trim()}
      />
    );

  let name;

  if (user.dados.name) {
    if (user.dados.name.split(" ").length > 1) {
      name = user.dados.name
        .split(" ")
        .reduce((p, c) => `${p.charAt(0)}${c.charAt(0)}`);
    } else {
      name = user.dados.name.substring(0, 2).toUpperCase();
    }
  } else {
    name = "?";
  }

  return (
    <span
      className={`c-profile-dropdown__initial ${
        size && size === "lg" ? "is-lg" : ""
      }`.trim()}
    >
      {name}
    </span>
  );
};

function ProfileDropdown(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  // Exibir dropdown
  const [show, setShow] = useState(false);

  // Ordenação escolhida
  const [selected, setSelected] = useState(null);

  function Logout() {
    RemoveToken();
    ResetRedux();
    props.history.push({ pathname: "/" });
  }

  function ResetRedux() {
    dispatch({ type: "USER_LOGOUT" });
    dispatch({ type: "USER_UNIT_CLEAR" });
    dispatch({ type: "UNIT_CLEAR" });
    dispatch({ type: "RESET_PATIENT_TAB" });
    dispatch({ type: "RESET_PATIENT_SELECTED" });
  }

  return (
    <Dropdown
      className="drop-profile c-profile-dropdown"
      onToggle={() => setShow(!show)}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Dropdown.Toggle
          id="dropdown-profile"
          className={`c-profile-dropdown__toggle btn-profile text-left ${props.cssClasses} align-items-center`}
          style={props.cssStyles}
        >
          {renderPhoto(user)}
          <span
            className="nav-profile-s limit_word ml-2"
            style={{ maxWidth: 100 }}
          >
            Olá, <br />
            <b className="nav-profile-l">
              {user?.dados?.name ? user.dados?.name.split(" ")[0] : ""}
            </b>
          </span>
          <Icon
            color={Colors.darker_gray}
            size={20}
            icon="arrow-select"
            className="ml-2"
          />
        </Dropdown.Toggle>
      </div>

      <Dropdown.Menu
        rootCloseEvent={"click"}
        className="div-drop-profile dropdown-menu-right"
      >
        <Link
          className="drop-profile-item dropdown-item"
          onSelect={() => props.history.push({ pathname: "/MinhaConta" })}
          to={{
            pathname: "/MinhaConta",
          }}
        >
          <Icon
            color={selected == null ? Colors.brand_blue : Colors.darker_gray}
            size={22}
            icon="settings"
            className="mr-3"
          />
          <div style={selected == null ? { color: Colors.brand_blue } : {}}>
            Configurações
          </div>
        </Link>
        <Dropdown.Item
          className="drop-profile-item"
          onClick={() => Logout()}
          onSelect={() => Logout()}
        >
          <Icon
            color={selected == null ? Colors.brand_blue : Colors.darker_gray}
            size={22}
            icon="logout"
            className="mr-3"
          />
          <div style={selected == null ? { color: Colors.brand_blue } : {}}>
            Sair
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ProfileDropdown;
