import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Spinner,
  Table,
  Tooltip,
  OverlayTrigger,
  Dropdown,
} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../../../../../services/api";
import moment from "moment";
import { Show_Alert } from "../../../../../../../../store/actions/alert";
import Colors from "../../../../../../../../styles/Colors";
import Icon from "../../../../../../../../functions/Icon";
import ModalDetalhado from "./ModalDetalhado";

var itemClicked = null;

const initialDate = moment().subtract(3, "days").format("YYYY-MM-DD");
const endDate = moment().format("YYYY-MM-DD");

function Detalhado() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [date_start, setDate_start] = useState(initialDate);
  const [date_end, setDate_end] = useState(endDate);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [totalResults, setTotalResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [qtdDisplay, setQtdDisplay] = useState(12);
  const [hoverBack, setHoverBack] = useState(false);
  const [hoverNext, setHoverNext] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [suspendNotificationData, setSuspendNotificationData] = useState({});

  function RenderTooltip(text) {
    return <Tooltip id="tooltip-top">{text}</Tooltip>;
  }

  useEffect(() => {
    function LoadList() {
      api
        .get(
          `/patients/${patientSelected.dados.id}/report-notifications/done?page=${currentPage}&pageSize=${qtdDisplay}`,
          {
            params: {
              date_start,
              date_end,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            let positionsTime = response.data.data;
            /*for (let i = positionsTime.length - 1; i >= 0; i--) {
              if (
                positionsTime[i + 1] !== undefined &&
                positionsTime[i].position_name ===
                  positionsTime[i + 1].position_name
              ) {
                positionsTime[i + 1].datetime_position_final =
                  positionsTime[i].datetime_position_final;
                positionsTime[i + 1].time_elapsed +=
                  positionsTime[i].time_elapsed;
                positionsTime.splice(i, 1);
              }
            }*/
            setList(response.data.data);
            setLastPage(response.data.lastPage);
            setTotalResults(response.data.total);
            BuildPagination(response.data.page, response.data.lastPage);
          }
        })
        .catch((error) => {
          console.log("LoadList error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de mudanças de decúbito realizadas",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadList();
  }, [date_start, date_end, currentPage, qtdDisplay, suspendNotificationData]);

  function BuildPagination(currentPage, lastPage) {
    var pagination = [];
    for (var i = currentPage; i < currentPage + 5; i++) {
      if (i == currentPage && currentPage > 2) {
        pagination.push(currentPage - 2);
      }
      if (i == currentPage && currentPage > 1) {
        pagination.push(currentPage - 1);
      }

      if (i <= lastPage) {
        pagination.push(i);
      }
    }
    setPagination(pagination);
  }

  function renderDisplay() {
    var n = 0;

    if (lastPage > 1) {
      n = qtdDisplay * currentPage;
      if (currentPage == lastPage) n = totalResults;
    } else {
      n = totalResults;
    }

    return n;
  }

  function SetarDisplay(qtd) {
    setCurrentPage(1);
    setQtdDisplay(qtd);
  }

  function LoadSuspendNotificationData(notificationId) {
    api
      .get(
        `/patients/${patientSelected.dados.id}/suspend_notifications/${notificationId}`
      )
      .then((response) => {
        if (response.status == 200) {
          setSuspendNotificationData(response.data);
          setShowModal(true);
        }
      })
      .catch((error) => {
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível carregar os dados da notificação suspensa",
          })
        );
      })
      .finally(() => setLoading(false));
  }

  function handleCallback(start, end) {
    setDate_start(moment(start).format("YYYY-MM-DD"));
    setDate_end(moment(end).format("YYYY-MM-DD"));
  }

  return (
    <div>
      {showModal && (
        <ModalDetalhado
          handler_show_modal={setShowModal}
          show={showModal}
          dados={suspendNotificationData}
        />
      )}
      <div className="mt-4">
        <div className="mt-3">
          <Col xs={12} md={8} xl={5}>
            <Form.Label className="field-label">Período</Form.Label>
            <Row className="justify-content-start align-items-center mb-3 mx-0">
              <DateRangePicker
                initialSettings={{
                  locale: {
                    cancelLabel: "Cancelar",
                    applyLabel: "Aplicar",
                  },
                  applyButtonClasses: "btn-dr-custom",
                }}
                onCallback={(start, end) => handleCallback(start, end)}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#ddd",
                    minHeight: 48,
                    borderRadius: 10,
                  }}
                  variant="light"
                  className="d-flex btn-date-range align-items-center"
                >
                  <Icon
                    color={Colors.darker_gray}
                    size={22}
                    icon="calendar"
                    className="mr-2"
                  />
                  <p className="m-0 text_e-left">
                    {date_start != ""
                      ? moment(date_start).format("DD/MM/YYYY")
                      : "Data inicial"}{" "}
                    -{" "}
                    {date_end != ""
                      ? moment(date_end).format("DD/MM/YYYY")
                      : "Data final"}
                  </p>
                </Button>
              </DateRangePicker>
            </Row>
          </Col>
        </div>
      </div>

      {loading && (
        <div className="mt-5 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && list.length == 0 && (
        <div className="container-error-default mt-5">
          <p>Nenhum resultado foi encontrado.</p>
        </div>
      )}

      {!loading && list.length > 0 && (
        <div className="mt-2">
          <Table className="patient-table" responsive hover>
            <thead>
              <tr>
                <th>Data</th>
                <th>Horário</th>
                <th>Decúbito prescrito</th>
                <th>Período que ficou</th>
                <th>Posição que ficou</th>
                <th>Tempo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => {
                let daysTimeElapsed =
                  Math.floor(item.time_elapsed / 86400) > 0
                    ? Math.floor(item.time_elapsed / 86400).toString() + "d "
                    : "";
                return (
                  <tr key={index} style={{ cursor: "unset" }}>
                    <td>
                      {moment(item.datetime_schedule).format("DD/MM/YYYY")}
                    </td>
                    <td> {item.schedule}</td>
                    <td>{item.schedule_position_name}</td>
                    <td>
                      {moment(item.datetime_position_initial).format("HH:mm")}{" "}
                      às {moment(item.datetime_position_final).format("HH:mm")}
                    </td>
                    <td>{item.position_name}</td>
                    <td>
                      {daysTimeElapsed +
                        moment.utc(item.time_elapsed * 1000).format("HH:mm:ss")}
                    </td>
                    <td>
                      {item.suspend_notification_id !== null && (
                        <div>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 10 }}
                            overlay={RenderTooltip("Aviso suspenso")}
                          >
                            <button
                              className="btn-table-user mr-2"
                              onClick={() => {
                                LoadSuspendNotificationData(
                                  item.suspend_notification_id
                                );
                              }}
                            >
                              <Icon
                                color={Colors.darker_gray}
                                size={22}
                                icon="bell-off"
                              />
                            </button>
                          </OverlayTrigger>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      <Row
        className="px-2 w-100 mt-4"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Col xs={4} md={4} lg={3} style={{ textAlign: "left" }}>
          <div className="sm-txt" style={{ color: Colors.dark_gray }}>
            Exibindo
          </div>
          <div className="sm-txt">
            {renderDisplay()} de {totalResults} resultados
          </div>
        </Col>

        <Col xs={4} md={4} lg={6} style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {currentPage > 1 && (
              <div
                className="btn-nav-arrows"
                onMouseEnter={() => setHoverBack(true)}
                onMouseLeave={() => setHoverBack(false)}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <Icon
                  color={hoverBack ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}

            {pagination.map((item) => {
              return (
                <div
                  key={item}
                  className={
                    item == currentPage
                      ? "btn-nav-numbers active"
                      : "btn-nav-numbers"
                  }
                  onClick={() => {
                    setCurrentPage(item);
                  }}
                >
                  {item}
                </div>
              );
            })}

            {currentPage < lastPage && (
              <div
                className="btn-nav-arrows"
                style={{ transform: `rotateY(180deg)` }}
                onMouseEnter={() => setHoverNext(true)}
                onMouseLeave={() => setHoverNext(false)}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <Icon
                  color={hoverNext ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}
          </div>
        </Col>

        <Col xs={4} md={4} lg={3}>
          <Dropdown style={{ textAlign: "right" }}>
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown-nav-btn"
              id="dropdown-basic"
            >
              {qtdDisplay} resultados &nbsp;&nbsp;
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => SetarDisplay(12)}>
                12 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(24)}>
                24 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(36)}>
                36 resultados
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
}

export default Detalhado;
