import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal, Spinner, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import Colors from "../../../styles/Colors";
import Icon from "../../../functions/Icon";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import moment from "moment";
import { renderPhoto } from "../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "./Notificacoes";
import { useLocation } from "react-router-dom";

function NotificacoesConfirmadas({ history }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const unit = useSelector((state) => state.unit);

  const [loading, setLoading] = useState(true);
  const [listNotifications, setListNotifications] = useState([]);

  //Modal
  const [showModal, setShowModal] = useState(false);
  const [patientObj, setPatientObj] = useState({});

  // Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [qtdDisplay, setQtdDisplay] = useState(12);
  const [totalResults, setTotalResults] = useState(null);
  const [pagination, setPagination] = useState([]);
  const [hoverBack, setHoverBack] = useState(false);
  const [hoverNext, setHoverNext] = useState(false);

  useEffect(() => {
    if (location.state?.itemCardConfirmed) {
      setPatientObj(location.state.itemCardConfirmed);
      setShowModal(true);
      let state = { ...history.location.state };
      delete state.itemCardConfirmed;
      history.replace({ ...history.location, state });
    }
  }, []);

  useEffect(() => {
    function LoadNotifications() {
      api
        .get(
          `/patients-wounds/list?page=${currentPage}&pageSize=${qtdDisplay}`,
          {
            params: {
              unit_id: unit.selecionado.id,
              type: "confirmed",
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadNotifications confirmed");
            console.log(response.data);

            setListNotifications(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadNotifications confirmed error" + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as Notificações confirmadas.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    if (unit.selecionado?.id != undefined) {
      LoadNotifications();
    } else {
      setLoading(false);
    }
  }, [unit, currentPage, qtdDisplay]);

  function BuildPagination(currentPage, lastPage) {
    var pagination = [];
    for (var i = currentPage; i < currentPage + 5; i++) {
      if (i == currentPage && currentPage > 2) {
        pagination.push(currentPage - 2);
      }
      if (i == currentPage && currentPage > 1) {
        pagination.push(currentPage - 1);
      }

      if (i <= lastPage) {
        pagination.push(i);
      }
    }
    setPagination(pagination);
  }

  function SetarDisplay(qtd) {
    setCurrentPage(1);
    setQtdDisplay(qtd);
  }

  function renderDisplay() {
    var n = 0;

    if (lastPage > 1) {
      n = qtdDisplay * currentPage;
      if (currentPage == lastPage) n = totalResults;
    } else {
      n = totalResults;
    }

    return n;
  }

  function CounterSubRooms(rooms) {
    var qtd = 0;
    rooms.map((item) => {
      qtd += item.subrooms.length;
    });
    return qtd;
  }

  function GoToWoundsConfirmed() {
    var obj = {
      id: patientObj.patient_id,
      name: patientObj.patient,
      qtd_wounds: patientObj.count_wounds,
      unit_id: patientObj.unit_id,
    };

    dispatch({ type: "SET_PATIENT_SELECTED", dados: obj });
    dispatch({ type: "SET_PATIENT_TAB", selected: 1 });

    history.push({
      pathname: "/Pacientes/Perfil-Paciente",
      state: { openConfirmed: true },
    });
  }

  function renderModal() {
    return (
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <div>
                <a
                  className="modal-title-link"
                  href="#"
                  onClick={() => {
                    history.push({ pathname: "/Pacientes/Perfil-Paciente" });
                    var obj = {
                      id: patientObj.patient_id,
                      name: patientObj.patient,
                      qtd_wounds: patientObj.count_wounds,
                      unit_id: patientObj.unit_id,
                    };

                    dispatch({ type: "SET_PATIENT_SELECTED", dados: obj });
                    dispatch({ type: "SET_PATIENT_TAB", selected: 0 });
                  }}
                >
                  {patientObj.patient_initials}
                </a>
              </div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => setShowModal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            <div
              className="main-card main-card-notif"
              style={{ boxShadow: "none", padding: 0, cursor: "unset" }}
            >
              <div className="card-quarto">
                {" "}
                {patientObj.room == null && patientObj.subroom}
                {patientObj.room != null &&
                  patientObj.subroom != null &&
                  `${patientObj.room}  |  ${patientObj.subroom}`}
              </div>

              <div
                className="card-data-sm mt-3"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon="band-aid"
                  className="mr-2"
                />
                {patientObj.wounds.length}{" "}
                {patientObj.wounds.length == 1
                  ? "lesão confirmada"
                  : "lesões confirmadas"}
              </div>

              <div
                className="card-data-sm mt-2"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon={patientObj.type === "admission" ? "enter" : "hospital"}
                  className="mr-2"
                />

                {patientObj.type == "admission" ? "Admissão" : "Incidida"}
              </div>

              <div
                onClick={() => GoToWoundsConfirmed()}
                className="mt-3"
                style={{
                  maxHeight: "50vh",
                  overflowY: "auto",
                  cursor: "pointer",
                }}
              >
                {patientObj.wounds.map((item, index) => {
                  return (
                    <div className="container-wound">
                      <div className="container-wound-modal">
                        <div className="d-flex justify-content-between">
                          <p className="wound-title">Local</p>
                          <p className="wound-subtitle">{item.wound_name}</p>
                        </div>

                        <div className="d-flex justify-content-between">
                          <p className="wound-title">Classificação</p>
                          <p className="wound-subtitle">{item.stage_name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-end mt-2">
                <button
                  onClick={() => GoToWoundsConfirmed()}
                  className="btn-see"
                >
                  Ver lesões
                </button>
              </div>

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div className="c-profile-dropdown mr-2">
                    {renderPhoto(
                      makeUserDataToPhoto(
                        patientObj.user_name,
                        patientObj.user_image !== null
                          ? patientObj.user_image
                          : null
                      )
                    )}
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="card-data-xs" style={{ color: "#000" }}>
                      Por {patientObj.user_name}
                    </div>
                    <div className="card-data-xs">
                      {moment(patientObj.date_verify).format("LLL")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <div
            style={{ boxShadow: "none", border: "none" }}
            className="card-bottom-confirmed d-flex align-items-start w-100 p-0"
          >
            <Icon
              color={Colors.brand_green}
              size={18}
              icon={"check"}
              className="mr-2"
            />
            <div>
              <p className="mb-0">
                Confirmada por {patientObj.nurse_name},{" "}
                {moment(patientObj.date_verify_nurse).format("LLL")}
              </p>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div className="d-flex flex-wrap">
      {patientObj.patient_id != undefined && renderModal()}

      {!loading && listNotifications.length == 0 && (
        <div className="container-error-default">
          <p>Ainda não foram confirmadas notificações de lesão por pressão.</p>
        </div>
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading &&
        listNotifications.map((item, index) => {
          return (
            <Col
              key={index}
              xs={12}
              md={6}
              lg={4}
              xl={3}
              className="my-2"
              onClick={() => {
                setPatientObj(item);
                setShowModal(true);
              }}
            >
              <div className="main-card main-card-notif">
                <div className="card-name card-name-mdd mb-1">
                  {item.patient_initials}
                </div>

                <div className="card-quarto card-quarto-mdd">
                  {" "}
                  {item.room == null && item.subroom}
                  {item.room != null &&
                    item.subroom != null &&
                    `${item.room}  |  ${item.subroom}`}
                </div>

                <div
                  className="card-data-sm mt-3"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Icon
                    color={Colors.brand_blue}
                    size={20}
                    icon="band-aid"
                    className="mr-2"
                  />
                  {item.wounds.length}{" "}
                  {item.wounds.length == 1
                    ? "lesão confirmadas"
                    : "lesões confirmadas"}
                </div>

                <div
                  className="card-data-sm mt-2"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Icon
                    color={Colors.brand_blue}
                    size={20}
                    icon={item.type === "admission" ? "enter" : "hospital"}
                    className="mr-2"
                  />

                  {item.type == "admission" ? "Admissão" : "Incidida"}
                </div>

                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div className="c-profile-dropdown mr-2">
                    {renderPhoto(
                      makeUserDataToPhoto(
                        item.user_name,
                        item.user_image !== null ? item.user_image : null
                      )
                    )}
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="card-data-xs" style={{ color: "#000" }}>
                      Por {item.user_name}
                    </div>
                    <div className="card-data-xs">
                      {moment(item.date_verify).format("LLL")}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ paddingVertical: 3 }}
                className="card-bottom-confirmed d-flex align-items-start"
              >
                <Icon
                  color={Colors.brand_green}
                  size={18}
                  icon={"check"}
                  className="mr-2"
                />
                <div>
                  <p className="mb-0">Confirmada por {item.nurse_name}</p>
                </div>
              </div>
            </Col>
          );
        })}
    </div>
  );
}

export default NotificacoesConfirmadas;
