const INITIAL_STATE = {
  dados: [],
};

function hospitalPlan(state = INITIAL_STATE, action) {
  if (action.type === "SET_HOSPITAL_PLAN") {
    return { ...state, dados: action.dados };
  } else if (action.type === "RESET_HOSPITAL_PLAN") {
    state = INITIAL_STATE;
  }

  return state;
}

export default hospitalPlan;
