import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";

function LinkSensorDropdown(props) {
  const { handler_agreement, handler_low_battery, patient, handler_patient } =
    props;
  const [showToggleEdit, setShowToggleEdit] = useState(false);
  return (
    <Dropdown
      className="drop-sort mr-3"
      onToggle={() => setShowToggleEdit(!showToggleEdit)}
    >
      <Dropdown.Toggle
        id={`sensor-panel-item-toggle-${patient.patient_name}`}
        className={`beacon-toggle-dropdown btn-filter-mdd ${
          showToggleEdit ? "active" : ""
        }`}
      >
        <Icon
          color={showToggleEdit ? Colors.brand_blue : Colors.darker_gray}
          size={22}
          icon="dots-vertical"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="drop-crud-options"
        style={{
          marginTop: "0px!important",
        }}
      >
        <Dropdown.Item
          onClick={() => {
            handler_low_battery(false);
            handler_agreement(true);
            handler_patient(patient);
          }}
        >
          Vincular sensor
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LinkSensorDropdown;
