import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import InputMask from "react-input-mask";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";
import { ValidateEmail } from "../../../../functions/utils";

function ModalAddUser({ ...props }) {
  const { handler_show_modal, show_modal, handler_reload, reload } = props;
  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const user = useSelector((state) => state.user);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [listUnits, setListUnits] = useState([]);
  const [listPermission, setListPermission] = useState([]);

  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [units, setUnits] = useState([]);
  const [mfaMandatory, setMfaMandatory] = useState(
    user.is_mfa_mandatory == 1 ? true : false
  );
  const [status, setStatus] = useState(true);
  const [permission, setPermission] = useState({
    label: "Selecionar",
    value: 0,
  });

  const [msgError, setMsgError] = useState("");
  const [msgErrorEmail, setMsgErrorEmail] = useState("");

  useEffect(() => {
    function LoadListPermission() {
      api
        .get(`/permissions`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadListPermission success");
            console.log(response.data);
            var array = [];
            response.data.map((item) => {
              array.push({
                label: item.name,
                value: item.id,
              });
            });
            setListPermission(array);
          }
        })
        .catch((error) => {
          console.log("LoadListPermission error " + error);
        });
    }

    LoadListPermission();
  }, []);

  useEffect(() => {
    function VerifyCpf() {
      api
        .get(`/users/verify-cpf/${cpf}`)
        .then((response) => {
          if (response.status == 200) {
            console.log("VerifyCpf success");
            console.log(response.data);

            if (response.data) {
              setMsgError("");
            } else {
              setMsgError("Já existe um usuário com o CPF inserido");
            }
          }
        })
        .catch((error) => {
          setMsgError(
            "Não foi possível verificar se o cpf está cadastrado no sistema"
          );
          console.log("VerifyCpf error " + error);
        });
    }

    if (cpf.length == 14) {
      VerifyCpf();
    }
  }, [cpf]);

  useEffect(() => {
    if (email != "") {
      if (!ValidateEmail(email)) {
        setMsgErrorEmail("Por favor, insira um endereço de e-mail válido");
      } else {
        setMsgErrorEmail("");
      }
    }
  }, [email]);

  useEffect(() => {
    function LoadUnits() {
      api
        .get(`/hospitals/${user.dados.hospital_id}/units`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUnits success");
            console.log(response.data);

            var array = [];
            response.data.map((item) => {
              array.push({
                label: item.name,
                value: item.id,
              });
            });
            setListUnits(array);
          }
        })
        .catch((error) => {
          console.log("LoadUnits error " + error);
        });
    }

    LoadUnits();
  }, []);

  function VerifyFields() {
    var ret = false;

    if (
      name != "" &&
      permission.value > 0 &&
      units &&
      units.length > 0 &&
      email != "" &&
      msgErrorEmail == "" &&
      msgError == ""
    ) {
      ret = true;
    }

    return ret;
  }

  const colourStyles = {
    multiValue: (styles, { data }) => {
      const color = Colors.brand_blue;
      return {
        ...styles,
        backgroundColor: "#ECF3FF",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
      ":hover": {
        backgroundColor: "#ECF3FF",
        color: Colors.brand_blue,
      },
    }),
  };

  function RegisterUser() {
    setLoadingBtn("flex");

    var allUnits = [];
    units.map((item, index) => {
      if (index == 0) return false;
      allUnits.push(item.value);
    });

    api
      .post("/users", {
        name,
        cpf,
        email,
        permission_id: permission.value,
        unit_id: units[0].value,
        units: allUnits,
        email,
        is_mfa_mandatory: mfaMandatory,
        status,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("RegisterUser success");
          console.log(response.data);
          dispatch(
            Show_Alert({
              type: "success",
              msg: "O usuário foi cadastrado",
            })
          );
          handler_show_modal(false);
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("RegisterUser error " + error);
        if (error.response && error.response.status === 409) {
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Esse email já foi cadastrado",
            })
          );
        } else {
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível cadastrar esse usuário",
            })
          );
        }
      })
      .finally(() => setLoadingBtn("none"));
  }

  return (
    <div>
      <Modal
        show={show_modal}
        onHide={() => handler_show_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter"
      >
        <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={8}>
              <div className="modal-title">Novo usuário</div>
            </Col>
            <Col
              xs={2}
              md={2}
              lg={2}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <div className="mt-4">
            <div className="mb-4">
              <p className="modal-text mb-2">Nome</p>
              <input
                className="default-input"
                type="text"
                placeholder="ex. Marcela Medeiros"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <p className="modal-text mb-2">CPF</p>
              <InputMask
                mask={"999.999.999-99"}
                maskChar={null}
                className="default-input"
                type="text"
                placeholder="Digite somente os números"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
              />
              {msgError != "" && <p className="text-error-red">{msgError}</p>}
            </div>

            <div className="mb-4">
              <p className="modal-text mb-2">Perfil de acesso</p>
              <Select
                captureMenuScroll={false}
                noOptionsMessage={() => "Indisponível"}
                classNamePrefix="react-select"
                placeholder={"Selecionar"}
                options={listPermission}
                isClearable={false}
                value={permission}
                onChange={(item) => setPermission(item)}
              />
            </div>

            <div className="mb-4">
              <p className="modal-text mb-2">Unidades</p>
              <Select
                captureMenuScroll={false}
                noOptionsMessage={() => "Sem opções"}
                classNamePrefix="react-select"
                placeholder="Selecionar unidades"
                closeMenuOnSelect={false}
                options={listUnits}
                isMulti
                styles={colourStyles}
                isClearable={false}
                value={units}
                onChange={(array) => {
                  setUnits(array);
                }}
              />
              {/* <Select
                  captureMenuScroll={false}
                noOptionsMessage={() => "Indisponível"}
                classNamePrefix="react-select"
                placeholder={"Selecionar"}
                options={units}
                isClearable={false}
                value={unit}
                onChange={(item) => setUnit(item)}
              /> */}
            </div>

            <div className="mb-4">
              <p className="modal-text mb-2">E-mail</p>
              <input
                className="default-input"
                type="text"
                placeholder="ex. marcelamedeiros@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {msgErrorEmail != "" && (
                <p className="text-error-red">{msgErrorEmail}</p>
              )}
            </div>

            <div className="mb-4">
              <p className="modal-text mb-2">
                Múltiplo Fator de Autenticação (MFA)
              </p>
              <div className="d-flex align-items-center">
                <label className="switch mb-0">
                  <input
                    type="checkbox"
                    checked={mfaMandatory}
                    onClick={() => setMfaMandatory(!mfaMandatory)}
                  />
                  <span className="slider round"></span>
                </label>
                <p className="modal-text ml-3">
                  {mfaMandatory == true ? "Obrigatório" : "Não obrigatório"}
                </p>
              </div>
            </div>

            <div>
              <p className="modal-text mb-2">Status</p>
              <div className="d-flex align-items-center">
                <label className="switch mb-0">
                  <input
                    type="checkbox"
                    checked={status}
                    onClick={() => setStatus(!status)}
                  />
                  <span className="slider round"></span>
                </label>
                <p className="modal-text ml-3">
                  {status == true ? "Ativo" : "Inativo"}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              style={{ borderStyle: "solid" }}
              className="modal-footer-btn mr-0 btn-cancel"
              onClick={() => handler_show_modal(false)}
            >
              Cancelar
            </button>
          </Col>

          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => RegisterUser()}
            >
              <p
                className="mb-0"
                style={{
                  color:
                    VerifyFields() == true ? Colors.white : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Cadastrar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalAddUser;
