import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import VerifyPw from "../../../../../components/Modals/VerifyPw";
import {
  userHasGeneralPermissions,
  userHasPermissionExcludingNurse,
  userWithoutPermission,
} from "../../../../../functions/utils";
import Icon from "../../../../../functions/Icon";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import moment from "moment";
import "./styles.css";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import VerifyPwWithUser from "../../../../../components/Modals/VerifyPwWithUser";

function NewAvaliation({ ...props }) {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);
  const user = useSelector((state) => state.user);

  const { handler_show_modal, show_modal, handler_reload, reload } = props;
  const [listAvaliations, setListAvaliations] = useState({});

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [color, setColor] = useState(Colors.dark_gray);

  const [listUsers, setListUsers] = useState([]);
  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });
  const [showPw, setShowPw] = useState(false);

  //Send
  const [name_result, setName_result] = useState("");
  const [result, setResult] = useState(0);
  const [avaliation, setAvaliation] = useState([]);

  useEffect(() => {
    //Se permission_id for 1,2 seta quem ta logado direto no responsavel
    if (userHasPermissionExcludingNurse(user)) {
      setUserChooser({ label: user.dados.name, value: user.dados.id });
    }
  }, []);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2",
            unit_id: patientSelected.dados.unit_id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userWithoutPermission(user)) {
      LoadUsers();
    }
  }, []);

  useEffect(() => {
    function LoadAvaliations() {
      api
        .get(`/avaliations`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadAvaliations");
            console.log(response.data[0]);
            setListAvaliations(response.data[0]);
          }
        })
        .catch((error) => {
          console.log("LoadAvaliations error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as opções.",
            })
          );
        });
    }

    LoadAvaliations();
  }, []);

  function SetarAvaliation(avaliation_id, factor, score) {
    var obj = {
      avaliation_id,
      factor,
      score,
    };

    var found = avaliation.find((item) => item.avaliation_id == avaliation_id);
    if (found != undefined) {
      var newList = avaliation.filter(
        (item) => item.avaliation_id != avaliation_id
      );
      setAvaliation([...newList, obj]);
    } else {
      setAvaliation([...avaliation, obj]);
    }
  }

  useEffect(() => {
    if (avaliation.length > 0) {
      var total = 0;
      avaliation.map((item) => {
        total += item.score;
      });

      if (total <= 9) {
        setColor(Colors.brand_red);
        setName_result("Risco muito alto");
      } else if (total >= 10 && total <= 12) {
        setColor(Colors.brand_orange);
        setName_result("Risco alto");
      } else if (total >= 13 && total <= 14) {
        setColor(Colors.brand_yellow);
        setName_result("Risco moderado");
      } else if (total >= 15 && total <= 18) {
        setColor(Colors.brand_green);
        setName_result("Risco baixo");
      } else if (total >= 19) {
        setColor(Colors.brand_blue);
        setName_result("Fora de risco");
      }

      setResult(parseInt(total));
    }
  }, [avaliation]);

  function RegisterAvaliation(userId) {
    setLoadingBtn("flex");

    api
      .post(`/patients/${patientSelected.dados.id}/avaliations`, {
        user_id: userId ? userId : userChooser.value,
        name_result,
        result,
        values: avaliation,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("RegisterAvaliation");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "Avaliação de risco salva",
            })
          );

          if (result <= 18 && userHasGeneralPermissions(user)) {
            dispatch({ type: "SET_PATIENT_TAB", selected: 3 });
          }

          handler_reload(!reload);
          handler_show_modal(false);
        }
      })
      .catch((error) => {
        console.log("RegisterAvaliation " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar a avaliação.",
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  function VerifyFields() {
    var ret = false;
    if (avaliation.length == 6) ret = true;
    return ret;
  }

  function RenderTooltip(text) {
    return <Tooltip id="tooltip-top">{text}</Tooltip>;
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        {showPw && (
          <VerifyPwWithUser
            handler_show_pw={setShowPw}
            show_pw={showPw}
            handler_request={RegisterAvaliation}
            handler_hide_tec_users={true}
          />
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">
              Nova avaliação de risco - Escala de Braden
            </div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all mt-4">
          {userHasPermissionExcludingNurse(user) && (
            <div className="mb-4">
              <Form.Label className="field-label">Responsável</Form.Label>
              <Select
                captureMenuScroll={false}
                classNamePrefix="react-select"
                placeholder={"Selecionar"}
                options={listUsers}
                isClearable={false}
                value={userChooser}
                onChange={(item) => {
                  setUserChooser(item);
                  setShowPw(true);
                }}
              />
            </div>
          )}

          <div>
            <div className="header-avaliation d-flex justify-content-between">
              <div className="avaliation-item-first" />
              <div className="avaliation-item">
                <p>1</p>
              </div>
              <div className="avaliation-item">
                <p>2</p>
              </div>
              <div className="avaliation-item">
                <p>3</p>
              </div>
              <div className="avaliation-item">
                <p>4</p>
              </div>
            </div>

            {listAvaliations.id != undefined &&
              listAvaliations.avaliations.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="op-avaliation d-flex justify-content-between"
                  >
                    <div className="avaliation-item-first">
                      <p>{item.name}</p>
                    </div>
                    {item.values.map((i, a) => {
                      var found = avaliation.find(
                        (a) => a.avaliation_id == item.id && a.score == i.score
                      );
                      return (
                        <OverlayTrigger
                          placement={index == 0 ? "bottom" : "top"}
                          delay={{ show: 250, hide: 10 }}
                          overlay={RenderTooltip(i.description)}
                        >
                          <button
                            key={a}
                            style={{
                              backgroundColor:
                                found != undefined
                                  ? Colors.brand_blue
                                  : Colors.lightest_gray,
                            }}
                            className="avaliation-item"
                            onClick={() => {
                              if (i.id == 24) return false;
                              SetarAvaliation(item.id, item.name, i.score);
                            }}
                          >
                            <p
                              style={{
                                color:
                                  found != undefined
                                    ? "white"
                                    : Colors.darker_gray,
                              }}
                            >
                              {i.name}
                            </p>
                          </button>
                        </OverlayTrigger>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end align-items-center">
        <div className="d-flex w-100">
          <Col xs={8}>
            <div
              className="container-show-result"
              style={{
                backgroundColor:
                  avaliation.length > 0 ? color : Colors.lightest_gray,
              }}
            >
              {avaliation.length == 0 && <p>{"Selecione as opções acima"}</p>}
              {avaliation.length > 0 && (
                <p className="text-white">{`Escore ${result} - ${name_result}`}</p>
              )}
            </div>
          </Col>

          <Col xs={4}>
            <div className="d-flex">
              <button
                className="modal-footer-btn mr-3  btn-cancel"
                onClick={() => handler_show_modal(false)}
                style={{ maxWidth: 125 }}
              >
                Cancelar
              </button>
              <button
                disabled={
                  (VerifyFields() == true ? false : true) ||
                  loadingBtn === "flex"
                }
                className="mb-0 modal-footer-btn btn-confirm"
                style={{
                  backgroundColor:
                    VerifyFields() == true
                      ? Colors.brand_pink
                      : Colors.lighter_gray,
                }}
                onClick={() => {
                  if (userHasPermissionExcludingNurse(user)) {
                    RegisterAvaliation();
                  } else {
                    setShowPw(true);
                  }
                }}
              >
                <p
                  className="mb-0"
                  style={{
                    color: VerifyFields() == true ? "white" : Colors.dark_gray,
                    display: loadingBtn == "none" ? "flex" : "none",
                  }}
                >
                  Salvar avaliação
                </p>

                <Spinner
                  style={{ display: loadingBtn }}
                  animation="border"
                  role="status"
                  variant="light"
                  size="sm"
                />
              </button>
            </div>
          </Col>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default NewAvaliation;
