import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

var arrayOrder = [
  {
    name: "paciente",
    value: "patient",
  },
  {
    name: "lesão por pressão",
    value: "woundPressure",
  },
];

function DensityDropdown(props) {
  const { sort } = props;
  const [show, setShow] = useState(false);

  const [selected, setSelected] = useState({
    name: "paciente",
    value: "patient",
  });

  useEffect(() => {
    if (sort.name != undefined) {
      setSelected(sort);
    }
  }, [sort]);

  function SetarHandler(obj) {
    const handler = props.handler;
    handler(obj);
  }

  function GetNameSelected() {
    var name = selected.name;
    return name;
  }

  return (
    <Dropdown className="drop-sort" onToggle={() => setShow(!show)}>
      <Dropdown.Toggle
        id="dropdown-sort"
        className={`btn-filter density-dropdown`}
      >
        {GetNameSelected()}
      </Dropdown.Toggle>

      <Dropdown.Menu rootCloseEvent={"click"} className="div-drop-sort">
        {arrayOrder.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              className="drop-sort-item"
              onClick={() => {
                setSelected(item);
                SetarHandler(item);
              }}
            >
              <div
                style={
                  selected.name == item.name ? { color: Colors.brand_blue } : {}
                }
              >
                {index > 0
                  ? item.name
                  : props.status == false
                  ? "lesão por pressão"
                  : "paciente"}
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DensityDropdown;
