import React, { useState, useEffect } from "react";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import ImageMapper from "react-image-mapper";
import { Line } from "react-chartjs-2";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import moment from "moment";
import "./styles.css";
import MainNavbar from "../../../components/Nav/MainNavbar";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Colors from "../../../styles/Colors";
import mapping_front from "../../../functions/mapping_front";
import mapping_back from "../../../functions/mapping_back";
import { renderPhoto } from "../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../Home/Notificacoes/Notificacoes";
import {
  getRandomColor,
  paginationMaxItemsPerWidth,
  userWithoutPermissionIncludingNurse,
} from "../../../functions/utils";
import Size from "./Size";
import api from "../../../services/api";
import Exposure from "./Exposure";
import AmountExudate from "./AmountExudate";
import OdorExudate from "./OdorExudate";
import TypeExudate from "./TypeExudate";
import Edges from "./Edges";
import Color from "./Color";
import Temperature from "./Temperature";
import Texture from "./Texture";
import Integrity from "./Integrity";
import Icon from "../../../functions/Icon";
import EmptyContainer from "../../Relatorios/EmptyContainer";
import WoundEditConfirm from "../../../components/Modals/WoundEditConfirm";

var woundEditConfirm = null;
var isConfirm = false;
var isConfirmed = false;
var isEvolution = false;

function EvolucaoLesao({ history }) {
  const patientSelected = useSelector((state) => state.patientSelected);
  const user = useSelector((state) => state.user);
  const woundSelected = useSelector(
    (state) => state.evolutionWoundSelected.dados.wound
  );

  const [woundLoaded, setWoundLoaded] = useState(false);
  const [wound, setWound] = useState(woundSelected);
  const [notes, setNotes] = useState(woundSelected.notes);
  const [images, setImages] = useState(woundSelected.images);
  const [imagesCarrousel, setImagesCarrousel] = useState(
    woundSelected.images.reduce((acc, item) => {
      let imageId = item.url.match(/(\d+)-/gm)[0];
      acc[`${imageId}`] = acc[`${imageId}`] || [];
      acc[`${imageId}`].push(item);
      return acc;
    }, {})
  );
  const [typeDescriptions, setTypeDescriptions] = useState(null);
  const [front, setFront] = useState({});
  const [back, setBack] = useState({});
  const [hoveredArea, setHoveredArea] = useState(null);
  const [tissues, setTissues] = useState(null);
  const [historicWounds, setHistoricWounds] = useState(
    woundSelected.woundUsers.map((i) => JSON.parse(i.historic_wound))
  );
  const [notePaginationMinimumIndex, setNotePaginationMinimumIndex] =
    useState(0);
  const [imagePaginationMinimumIndex, setImagePaginationMinimumIndex] =
    useState(0);
  const [note, setNote] = useState(null);
  const [showNote, setShowNote] = useState(false);
  const [showWound, setShowWound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);

  const optionsLine = {
    maintainAspectRatio: false,
    bezierCurve: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: Colors.light_gray,
            offsetGridLines: false,
            borderDash: [6],
            lineWidth: 1,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
      labels: {
        fontColor: Colors.darker_gray,
        fontFamily: "Rubik-Regular",
      },
    },
  };

  function ChangeColorBall(wound) {
    ResetColorBall();
    mapping_front.areas.map((i) => {
      if (i.id == wound.wound_id) {
        i.preFillColor =
          wound.verify_nurse == null ? Colors.brand_blue : Colors.brand_red;
      }
      return i;
    });
    mapping_back.areas.map((i) => {
      if (i.id == wound.wound_id) {
        i.preFillColor =
          wound.verify_nurse == null ? Colors.brand_blue : Colors.brand_red;
      }
      return i;
    });
    setFront(mapping_front);
    setBack(mapping_back);
  }

  function ResetColorBall() {
    mapping_front.areas.map((item) => {
      item.preFillColor = "rgba(127,167,230, 0.7)";
    });

    mapping_back.areas.map((item) => {
      item.preFillColor = "rgba(127,167,230, 0.7)";
    });
  }

  function CalculateArea(width, height) {
    var ret = "";
    if (width != "null" && height != "null") {
      ret = parseFloat(width) * parseFloat(height);
      ret = String(ret.toFixed(2));
    }
    return ret;
  }

  function setTypesFabric() {
    let data = [];
    const labels = [];
    let hasData = false;
    if (!historicWounds || !typeDescriptions) return;
    historicWounds.map((i) => {
      let date;
      if (i.date_realized !== undefined) {
        date = moment(i.date_realized).format("DD/MM");
      } else if (
        i.date_confirmed !== undefined &&
        i.date_confirmed != "Invalid date"
      ) {
        date = moment(i.date_confirmed).format("DD/MM");
      } else {
        date = moment(i.date_notification).format("DD/MM");
      }
      i.descriptions.map((j) => {
        const typeHistoric = typeDescriptions.find(
          (k) => k.id == j.type_description_id
        );
        if (typeHistoric.type !== "percentage") return;
        let percentageValue = j.value === "" ? 0 : parseInt(j.value);
        if (percentageValue > 0) hasData = true;
        let percentageExistent = data.find((k) => k.id == typeHistoric.id);
        if (percentageExistent) {
          percentageExistent.data.push(percentageValue);
        } else {
          data.push({
            id: typeHistoric.id,
            name: typeHistoric.name,
            data: [percentageValue],
          });
        }
      });
      labels.push(date);
    });
    data = data.filter((tissue) => {
      let tissueHasData = false;
      for (const item of tissue.data) {
        if (item > 0) {
          tissueHasData = true;
          break;
        }
      }
      return tissueHasData;
    });
    if (!hasData) {
      return;
    }
    setTissues({
      labels,
      datasets: data.map((i) => {
        let randomColor = getRandomColor();
        if (i.name == "Granulação") {
          randomColor = Colors.brand_blue;
        } else if (i.name == "Epitelização") {
          randomColor = Colors.brand_pink;
        } else if (i.name == "Esfacelo") {
          randomColor = Colors.brand_green;
        } else if (i.name == "Necrose") {
          randomColor = Colors.brand_yellow;
        } else if (i.name == "Bolha") {
          randomColor = "#13ACCE";
        } else if (i.name == "Exposição dérmica") {
          randomColor = "#6672DF";
        } else if (i.name == "Hiperemia não reativa") {
          randomColor = "#AE72DE";
        } else if (i.name == "Músculo") {
          randomColor = "#F77D7D";
        } else if (i.name == "Osso") {
          randomColor = "#B1ADB4";
        } else if (i.name == "Tecido adiposo") {
          randomColor = "#CCA077";
        } else if (i.name == "Tendão") {
          randomColor = "#AFC750";
        }
        return {
          label: i.name,
          data: i.data,
          fill: false,
          backgroundColor: randomColor,
          borderColor: randomColor,
          lineTension: 0,
          pointRadius: 6,
          pointHoverRadius: 6,
          borderWidth: 4,
          pointRadius: 5,
        };
      }),
    });
  }

  function LoadTypeDescriptions() {
    setLoading(true);
    api
      .get("/typedescriptions")
      .then((response) => {
        if (response.data) {
          setTypeDescriptions(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function setGeneralData() {
    setTypesFabric();
  }

  function getTipPosition(area) {
    return { top: `${area.center[1] - 30}px`, left: `${area.center[0]}px` };
  }

  function imagePaginationMinimumIndexAction(action) {
    if (action == "next") {
      if (
        imagePaginationMinimumIndex ==
        Object.keys(imagesCarrousel).length - 1
      )
        return;
      setImagePaginationMinimumIndex(imagePaginationMinimumIndex + 1);
    } else {
      if (imagePaginationMinimumIndex == 0) return;
      setImagePaginationMinimumIndex(imagePaginationMinimumIndex - 1);
    }
  }

  function notePaginationMinimumIndexAction(action) {
    if (action == "next") {
      if (notePaginationMinimumIndex == Object.keys(imagesCarrousel).length - 1)
        return;
      setNotePaginationMinimumIndex(notePaginationMinimumIndex + 1);
    } else {
      if (notePaginationMinimumIndex == 0) return;
      setNotePaginationMinimumIndex(notePaginationMinimumIndex - 1);
    }
  }

  useEffect(() => {
    if (typeDescriptions && historicWounds) {
      setGeneralData();
    }
  }, [typeDescriptions, historicWounds]);

  useEffect(() => {
    setLoading(false);
  }, [back, front]);

  useEffect(() => {
    function LoadPatient() {
      api
        .get(`/patients/${patientSelected.dados.id}`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPatient success");
            console.log(response.data);

            const wound = response.data.wounds.filter(
              (i) => i.id == woundSelected.id
            )[0];
            setWoundLoaded(true);
            setWound(wound);
          }
        })
        .finally(() => setLoading(false));
    }
    LoadPatient();
  }, [reload]);

  useEffect(() => {
    if (wound && woundLoaded) {
      setNotes(wound.notes);
      setImages(wound.images);
      setImagesCarrousel(
        images.reduce((acc, item) => {
          let imageId = item.url.match(/(\d+)-/gm)[0];
          acc[`${imageId}`] = acc[`${imageId}`] || [];
          acc[`${imageId}`].push(item);
          return acc;
        }, {})
      );
      setHistoricWounds(
        wound.woundUsers.map((i) => JSON.parse(i.historic_wound))
      );
      ChangeColorBall(wound);
      LoadTypeDescriptions();
    }
  }, [wound, woundLoaded]);

  return (
    <div>
      <MainNavbar history={history} />

      <Container fluid className="main-container">
        <Breadcrumb
          history={history}
          list_breadcrumb={[
            { name: "Home", url: "Home" },
            { name: "Pacientes", url: "Pacientes" },
            { name: "Detalhes do paciente", url: "Pacientes/Perfil-Paciente" },
            { name: "Evolução da lesão", url: "" },
          ]}
        />
        <h2 className="name-patient">{patientSelected.dados.name}</h2>
        <div className="d-flex">
          <h3 className="page-description">Histórico de evolução da lesão</h3>
          {woundSelected.verify_nurse !== null &&
            userWithoutPermissionIncludingNurse(user) !== 3 && (
              <div style={{ marginLeft: 80 }}>
                <button
                  className="secondary-btn btn-next-lesion"
                  onClick={(e) => {
                    e.stopPropagation();
                    isConfirm = false; // Isso parece ser um estado, deve ser atualizado com um método setState se estiver usando componentes de classe ou o hook useState se estiver usando funções componentes
                    isConfirmed = false; // Mesmo caso acima
                    isEvolution = true; // Mesmo caso acima
                    woundEditConfirm = wound; // Mesmo caso acima, parece que você deseja atualizar um estado
                    setShowWound(true); // Isso parece correto se setShowWound for uma função para atualizar o estado
                  }}
                >
                  <Icon color={Colors.brand_pink} size={20} icon="line-chart" />
                  <p
                    className="mb-0 ml-2"
                    style={{
                      color: "Colors.brand_pink",
                    }}
                  >
                    Evoluir
                  </p>
                </button>
              </div>
            )}
        </div>
        <div className="main-card main-card-notif h-100">
          {!loading && front.name != undefined && back.name != undefined ? (
            <div className="d-flex">
              <div className="mr-4 container-model">
                <ImageMapper
                  src={require(`../../../assets/img/model-human/${
                    patientSelected.dados.gender == "F" ? "female" : "men"
                  }-${
                    wound.wound?.image_position === "back" ? "back" : "front"
                  }.jpg`)}
                  width={window.innerWidth <= 1366 ? 120 : 150}
                  imgWidth={367}
                  map={wound.wound?.image_position === "back" ? back : front}
                  onMouseEnter={(area) => setHoveredArea(area)}
                  onMouseLeave={(area) => setHoveredArea(null)}
                />
                {hoveredArea != null && (
                  <span
                    className="tooltip-model"
                    style={{ ...getTipPosition(hoveredArea) }}
                  >
                    {hoveredArea.name}
                  </span>
                )}
              </div>
              <div className="wound-info w-100">
                <Row className="mt-4">
                  <Col md={3}>
                    <p className="evolution-wound-main-card-description mb-1">
                      Local da lesão
                    </p>
                    <div className="card-name mb-1 d-flex">
                      <div
                        style={{ background: Colors.brand_red }}
                        className="ball-wound mr-2"
                      />{" "}
                      {wound.wound.position != null &&
                        (wound.wound.position == "front"
                          ? "Anterior - "
                          : wound.wound.position === "external"
                          ? "Externo - "
                          : wound.wound.position === "internal"
                          ? "Interno - "
                          : wound.wound.position === null
                          ? ""
                          : "Posterior - ")}
                      {wound.wound.name}
                    </div>
                  </Col>
                  <Col md={3}>
                    <p className="evolution-wound-main-card-description mb-1">
                      Verificação
                    </p>
                    <div className="card-name mb-1 d-flex">
                      {wound.type == "incidence" ? "Incidência" : "Admissão"}
                    </div>
                  </Col>
                  <Col md={3}>
                    <p className="evolution-wound-main-card-description mb-1">
                      Classificação
                    </p>
                    <div className="card-name mb-1 d-flex">
                      {wound.stage?.name}
                    </div>
                  </Col>
                  <Col md={3}>
                    <p className="evolution-wound-main-card-description mb-1">
                      Associada a dispositivo médico
                    </p>
                    <div className="card-name mb-1 d-flex">
                      {wound.medical_devices > 0 ? "Sim" : "Não"}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md={3}>
                    <p className="evolution-wound-main-card-description mb-1">
                      Notificada por
                    </p>
                    <div className="card-name mb-1 d-flex">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div className="c-profile-dropdown">
                          {renderPhoto(
                            makeUserDataToPhoto(
                              wound.user.name,
                              wound.user.image !== null
                                ? wound.user.url_image
                                : null
                            )
                          )}
                        </div>

                        <div className="dados-default">
                          <div className="name-default">{wound.user.name}</div>
                          <div className="date-default">
                            {moment(wound.created_at).format("LLL")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <p className="evolution-wound-main-card-description mb-1">
                      Confirmada por
                    </p>
                    <div className="card-name mb-1 d-flex">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {wound.verify_nurse != null && (
                          <>
                            <div className="c-profile-dropdown">
                              {renderPhoto(
                                makeUserDataToPhoto(
                                  wound.verify_nurse.name,
                                  wound.verify_nurse.image !== null
                                    ? wound.verify_nurse.url_image
                                    : null
                                )
                              )}
                            </div>

                            <div className="dados-default">
                              <div className="name-default">
                                {wound.verify_nurse.name}
                              </div>
                              <div className="date-default">
                                {moment(wound.date_verify_nurse).format("LLL")}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            <div
              className="mt-3 d-flex justify-content-center align-items-center"
              style={{
                height: "200px",
              }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
        <Size historicWounds={historicWounds} />
        {wound.stage?.id == 5 && (
          <Exposure
            historicWounds={historicWounds}
            typeDescriptions={typeDescriptions}
          />
        )}
        <h3 className="page-description mt-4 mb-4">Tecidos</h3>
        <div className="card-report">
          <h4 className="mb-0">Tipos de tecido (%)</h4>
          {tissues ? (
            <div className="mt-4">
              <div class="chartContainer">
                <div
                  style={{
                    width:
                      tissues?.datasets[0].data.length > 7
                        ? Math.max(
                            tissues?.labels.length * 50,
                            window.innerWidth
                          )
                        : null,
                  }}
                >
                  <Line
                    height={400}
                    width={100}
                    data={tissues}
                    options={optionsLine}
                  />
                </div>
              </div>
              <div className="container-subtitle mt-4">
                {tissues.datasets.map((item, index) => (
                  <div className="subtitle-item mr-4">
                    <div
                      className="indicator-radius"
                      style={{ backgroundColor: item.backgroundColor }}
                    />
                    <p>{item.label.replace(/\s+\(DT:\d+\)/, "")}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <EmptyContainer
              text="Ainda não há dados sobre tipos de tecido da lesão"
              iconName="line-chart"
            />
          )}
        </div>
        <div>
          <h3 className="page-description mt-4 mb-4">Exsudato</h3>
          <Row>
            <Col md={6}>
              <AmountExudate
                historicWounds={historicWounds}
                typeDescriptions={typeDescriptions}
              />
            </Col>
            <Col md={6}>
              <OdorExudate
                historicWounds={historicWounds}
                typeDescriptions={typeDescriptions}
              />
            </Col>
            <Col md={12}>
              <TypeExudate
                historicWounds={historicWounds}
                typeDescriptions={typeDescriptions}
              />
            </Col>
          </Row>
        </div>
        <div>
          <h3 className="page-description mt-4 mb-4">Bordas</h3>
          <Edges
            historicWounds={historicWounds}
            typeDescriptions={typeDescriptions}
          />
        </div>
        <div>
          <h3 className="page-description mt-4 mb-4">Pele adjacente</h3>
          <Row>
            <Col md={6}>
              <Color
                historicWounds={historicWounds}
                typeDescriptions={typeDescriptions}
              />
            </Col>
            <Col md={6}>
              <Temperature
                historicWounds={historicWounds}
                typeDescriptions={typeDescriptions}
              />
            </Col>
            <Col md={6}>
              <Texture
                historicWounds={historicWounds}
                typeDescriptions={typeDescriptions}
              />
            </Col>
            <Col md={6}>
              <Integrity
                historicWounds={historicWounds}
                typeDescriptions={typeDescriptions}
              />
            </Col>
          </Row>
        </div>
        {notes.length > 0 ? (
          <div className="notes">
            <div className="d-flex justify-content-end mt-5 mb-4">
              <h3 className="page-description mb-0">Notas</h3>
              <div className="icons-shadow">
                <div
                  className="mr-2"
                  onClick={() => notePaginationMinimumIndexAction("previous")}
                  style={{
                    cursor:
                      notePaginationMinimumIndex == 0
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      notePaginationMinimumIndex == 0
                        ? Colors.lightest_gray
                        : "#FFFF",
                  }}
                >
                  <Icon
                    icon="arrow-left"
                    size={25}
                    color={
                      notePaginationMinimumIndex == 0
                        ? Colors.brand_gray
                        : Colors.brand_pink
                    }
                  />
                </div>
                <div
                  className="mr-2"
                  onClick={() => notePaginationMinimumIndexAction("next")}
                  style={{
                    cursor:
                      notePaginationMinimumIndex == notes.length - 1
                        ? "not-allowed"
                        : "pointer",
                    backgroundColor:
                      notePaginationMinimumIndex == notes.length - 1
                        ? Colors.lightest_gray
                        : "#FFFF",
                  }}
                >
                  <Icon
                    icon="arrow-left"
                    size={25}
                    color={
                      notePaginationMinimumIndex == notes.length - 1
                        ? Colors.brand_gray
                        : Colors.brand_pink
                    }
                  />
                </div>
              </div>
            </div>
            <Row
              style={{
                height: "300px",
              }}
            >
              {notes.map((i, index) => {
                if (index < notePaginationMinimumIndex) return;
                if (
                  index >
                  notePaginationMinimumIndex + paginationMaxItemsPerWidth()
                )
                  return;
                function cutText(text) {
                  const maxLength = 247;
                  if (text.length > maxLength) {
                    let trimmedText = text.substring(0, maxLength);

                    if (
                      trimmedText.endsWith(". ") ||
                      trimmedText.endsWith(".")
                    ) {
                      trimmedText = trimmedText.substring(
                        0,
                        trimmedText.length - 1
                      );
                    }

                    return trimmedText + "...";
                  }
                  return text;
                }

                return (
                  <Col md={6} lg={3} sm={12} xs={12} xl={3}>
                    <div
                      className="card-report d-flex flex-column justify-content-between"
                      style={{
                        height: "100%",
                      }}
                    >
                      <div>
                        <p className="mb-3 notes-creation">
                          {moment(i.created_at).format("DD/MM/YYYY")}
                        </p>
                        <p className="notes-note">{cutText(i.note)}</p>
                      </div>
                      {i.note.length >= 247 && (
                        <p
                          className="notes-more"
                          onClick={() => {
                            setNote(i.note);
                            setShowNote(true);
                          }}
                        >
                          Continuar lendo
                        </p>
                      )}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        ) : (
          <div className="mt-4 mb-4">
            <h3 className="page-description mb-4">Notas</h3>
            <div className="card-report">
              <EmptyContainer
                text="Ainda não foram registradas notas"
                iconName="notebook"
              />
            </div>
          </div>
        )}
        {images.length > 0 ? (
          <div className="d-flex justify-content-end mt-5 mb-4">
            <h3 className="page-description mb-0">Evoluções</h3>
            <div className="icons-shadow">
              <div
                className="mr-2"
                onClick={() => imagePaginationMinimumIndexAction("previous")}
                style={{
                  cursor:
                    imagePaginationMinimumIndex == 0
                      ? "not-allowed"
                      : "pointer",
                  backgroundColor:
                    imagePaginationMinimumIndex == 0
                      ? Colors.lightest_gray
                      : "#FFFF",
                }}
              >
                <Icon
                  icon="arrow-left"
                  size={25}
                  color={
                    imagePaginationMinimumIndex == 0
                      ? Colors.brand_gray
                      : Colors.brand_pink
                  }
                />
              </div>
              <div
                className="mr-2"
                onClick={() => imagePaginationMinimumIndexAction("next")}
                style={{
                  cursor:
                    imagePaginationMinimumIndex ==
                    Object.keys(imagesCarrousel).length - 1
                      ? "not-allowed"
                      : "pointer",
                  backgroundColor:
                    imagePaginationMinimumIndex ==
                    Object.keys(imagesCarrousel).length - 1
                      ? Colors.lightest_gray
                      : "#FFFF",
                }}
              >
                <Icon
                  icon="arrow-left"
                  size={25}
                  color={
                    imagePaginationMinimumIndex ==
                    Object.keys(imagesCarrousel).length - 1
                      ? Colors.brand_gray
                      : Colors.brand_pink
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-5 mb-4">
            <h3 className="page-description mb-4">Evoluções</h3>
            <div className="card-report">
              <EmptyContainer
                text="Ainda não foram realizadas evoluções da lesão"
                iconName="line-chart"
              />
            </div>
          </div>
        )}
        <Row>
          {Object.keys(imagesCarrousel).map((i, index) => {
            if (index < imagePaginationMinimumIndex) return;
            if (
              index >
              imagePaginationMinimumIndex + paginationMaxItemsPerWidth()
            )
              return;
            let firstImage = imagesCarrousel[i][0];
            let secondImage = imagesCarrousel[i][1];
            let imageInfos = secondImage?.info;
            let dimensions, states;
            let area = CalculateArea(imageInfos?.width, imageInfos?.height);
            dimensions = {
              "Largura (cm)": imageInfos?.width ?? "-",
              "Comprimento (cm)": imageInfos?.height ?? "-",
              "Área (cm²)": isNaN(area) ? "-" : area,
            };
            states = {
              Epitelização: {
                value: imageInfos?.epithelization ?? "-",
                color: Colors.brand_orange,
              },
              Esfacelo: {
                value: imageInfos?.sludge ?? "-",
                color: Colors.brand_green,
              },
              Granulação: {
                value: imageInfos?.graining ?? "-",
                color: Colors.brand_yellow,
              },
              Hiperemia: {
                value: imageInfos?.hyperemia ?? "-",
                color: Colors.brand_pink,
              },
              Inflamação: {
                value: imageInfos?.inflammation ?? "-",
                color: Colors.brand_green,
              },
              Maceração: {
                value: imageInfos?.maceration ?? "-",
                color: Colors.brand_blue,
              },
              Necrose: {
                value: imageInfos?.necrosis ?? "-",
                color: "brown",
              },
            };
            return (
              <Col
                md={6}
                lg={3}
                sm={12}
                xs={12}
                xl={3}
                className="wound-image mb-2"
              >
                <div className="main-card">
                  <p className="font-weight-bold mb-3 mt-1">
                    {moment(firstImage.created_at).format("DD/MM/YYYY")}
                  </p>
                  <Carousel
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                  >
                    {imagesCarrousel[i].map((j) => {
                      return (
                        <div>
                          <img src={j.url} height={320} />
                        </div>
                      );
                    })}
                  </Carousel>
                  <div className="mt-3 mb-4">
                    {Object.keys(dimensions).map((k) => {
                      return (
                        <div
                          className="d-flex justify-content-between mb-1"
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <p className="font-weight-bold">{k}</p>
                          <p className="font-weight-bold">{dimensions[k]}</p>
                        </div>
                      );
                    })}
                    <div
                      style={{
                        height: "1.1px",
                        backgroundColor: "#f1e7e752",
                        margin: ".8rem 0",
                      }}
                    ></div>
                    {Object.keys(states).map((k) => {
                      return (
                        <div
                          className="d-flex justify-content-between mb-1"
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <span
                              className="mr-2 dot"
                              style={{
                                backgroundColor: states[k].color,
                              }}
                            ></span>
                            <p className="font-weight-bold">{k}</p>
                          </div>
                          <p className="font-weight-bold">
                            {states[k].value}{" "}
                            {states[k].value != "-" ? "%" : ""}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  {firstImage.user && (
                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div className="c-profile-dropdown mr-1">
                        {renderPhoto(
                          makeUserDataToPhoto(
                            firstImage.user.name,
                            firstImage.user.image !== null
                              ? firstImage.user.url_image
                              : null
                          )
                        )}
                      </div>

                      <div className="dados-default">
                        <div className="name-default">
                          por {firstImage.user.name}
                        </div>
                        <div className="date-default">
                          {moment(firstImage.created_at).format("LLL")}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      {showNote && (
        <ModalNote note={note} show={showNote} handler={setShowNote} />
      )}
      {showWound && (
        <WoundEditConfirm
          show_wound={showWound}
          handler_reload={setReload}
          reload={reload}
          handler_show_wound={setShowWound}
          wound_edit_confirm={woundEditConfirm}
          is_confirm={isConfirm}
          is_confirmed={isConfirmed}
          is_evolution={isEvolution}
        />
      )}
    </div>
  );
}

function ModalNote(props) {
  const { note, show, handler } = props;
  return (
    <Modal
      show={show}
      onHide={() => handler(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-alta"
    >
      <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Col xs={6} md={2} lg={2}>
            <div className="modal-title">Nota</div>
          </Col>
          <Col xs={2} md={2} lg={2} className="pr-0 d-flex justify-content-end">
            <div className="modal-close-btn" onClick={() => handler(false)}>
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </Col>
        </Row>

        <div className="mt-3 mb-2">
          <p
            style={{
              fontFamily: "var(--regular)",
              fontSize: "15px",
            }}
          >
            {note}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EvolucaoLesao;
