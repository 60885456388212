import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  ProgressBar,
} from "react-bootstrap";
import Select from "react-select";

import VerifyPwWithUser from "../../../../../components/Modals/VerifyPwWithUser";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import {
  GetImc,
  CalculateAge,
  userHasPermission,
} from "../../../../../functions/utils";
import moment from "moment";
import "./styles.css";
import BatteryAlertCard from "../../../../../components/BaterryAlertCard";

function ModalBeacon(props) {
  const {
    handler_show_modal,
    show_modal,
    handler_reload,
    reload,
    link,
    beacon_name,
    patient_name,
  } = props;

  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [listUsers, setListUsers] = useState([]);
  const [showPw, setShowPw] = useState(false);

  //Dado form
  const [listBeacons, setListBeacons] = useState([]);
  const [beacon, setBeacon] = useState({
    label: "Selecionar sensor",
    value: 0,
  });

  useEffect(() => {
    function LoadBeacons() {
      api
        .get(`/beacons`, {
          params: {
            pagination: false,
            unit_id: patientSelected.dados.unit_id || unit.selecionado?.id,
            status: 1,
            free: true,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadBeacons");
            console.log(response.data);

            var array = [{ label: "Selecionar sensor", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListBeacons(array);
          }
        })
        .catch((error) => {
          console.log("LoadBeacons error " + error);
        });
    }

    LoadBeacons();
  }, []);

  function VerifyFields() {
    var verify = false;
    if (beacon.value > 0 || !link) {
      verify = true;
    }

    return verify;
  }

  function Save(userId) {
    setLoadingBtn("flex");
    api
      .put(`/patients/${patientSelected.dados.id}`, {
        name: patient_name,
        beacon_id: link ? beacon?.value : 0,
        user_id: userId,
      })
      .then((response) => {
        console.log("response");
        console.log(response);
        if (response.status == 200) {
          console.log("Save with success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: `${link ? "Sensor vinculado" : "Sensor desvinculado"}`,
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("Edit error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: `Não foi possível ${
              link ? "vincular" : "desvincular"
            } o sensor. Tente novamente mais tarde`,
          })
        );
      })
      .finally(() => {
        setLoadingBtn("none");
        handler_show_modal(false);
      });
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => {
        handler_show_modal(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=" modal-pw"
    >
      {showPw && (
        <VerifyPwWithUser
          handler_show_pw={setShowPw}
          show_pw={showPw}
          handler_request={Save}
        />
      )}
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">
              {link ? "Vincular sensor" : "Desvincular sensor"}
            </div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => {
                handler_show_modal(false);
              }}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        {link ? (
          <div className="modal-all" style={{ overflow: "unset" }}>
            <div className="mt-4">
              <Form.Label className="field-label">Sensor</Form.Label>
              <Select
                captureMenuScroll={false}
                noOptionsMessage={() => "Sem opções"}
                classNamePrefix="react-select"
                placeholder={"Selecionar sensor"}
                options={listBeacons}
                isClearable={false}
                value={beacon}
                onChange={(item) => setBeacon(item)}
              />
            </div>
            <div className="card-correct">
              <BatteryAlertCard
                unitId={patientSelected.dados.unit_id || unit.selecionado?.id}
                beaconId={beacon.value}
              />
            </div>
          </div>
        ) : (
          <div className="modal-all">
            <p className="mt-4 text-confirm">
              Tem certeza que deseja desvincular o{" "}
              <span>sensor {beacon_name} </span> do paciente{" "}
              <span> {patient_name} </span>
            </p>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end align-items-center">
        <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
          <button
            className="modal-footer-btn mr-0 btn-cancel"
            onClick={() => {
              handler_show_modal(false);
            }}
          >
            Cancelar
          </button>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={
              (VerifyFields() == true ? false : true) || loadingBtn === "flex"
            }
            className="mb-0 modal-footer-btn btn-confirm"
            style={{
              backgroundColor:
                VerifyFields() == true
                  ? Colors.brand_pink
                  : Colors.lighter_gray,
            }}
            onClick={() => {
              if (userHasPermission(user)) {
                Save(user.dados.id);
                return false;
              }
              setShowPw(true);
            }}
          >
            <p
              className="mb-0"
              style={{
                color: VerifyFields() == true ? Colors.white : Colors.dark_gray,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              {link ? "Vincular" : "Desvincular"}
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalBeacon;
