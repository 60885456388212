import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Modal, Button, Spinner } from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import VerifyPwWithUser from "./VerifyPwWithUser";
import api from "../../services/api";
import ModalMdd from "./ModalMdd";
import { userWithoutPermission } from "../../functions/utils";
import { Show_Alert } from "../../store/actions/alert";

function ModalUnlinkSensor(props) {
  const {
    handler_modal,
    handler_father_modal,
    handler_modal_and_mdd_modal,
    show_modal,
    patient_name,
    sensor,
    patient_id,
    reload,
    handler_reload,
  } = props;

  const dispatch = useDispatch();

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [showPw, setShowPw] = useState(false);

  const user = useSelector((state) => state.user);

  function Save(userId) {
    setLoadingBtn("flex");
    api
      .put(`/patients/${patient_id}`, {
        name: patient_name,
        beacon_id: 0,
        user_id: userId,
      })
      .then((response) => {
        console.log("response");
        console.log(response);
        if (response.status == 200) {
          console.log("Save with success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: `${"Sensor desvinculado"}`,
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("Edit error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: `Não foi possível desvincular o sensor. Tente novamente mais tarde`,
          })
        );
      })
      .finally(() => {
        setLoadingBtn("none");
        handler_modal(false);
        handler_father_modal(false);
      });
  }

  function RenderPage() {
    return (
      <>
        <Modal.Body className="p-0 py-3">
          <div style={{ paddingLeft: 40, paddingRight: 40 }}>
            <Row
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col xs={12} md={10} lg={10} className="pl-0">
                <div className="modal-title">Desvincular sensor</div>
              </Col>
              <Col
                xs={4}
                md={2}
                lg={2}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_modal_and_mdd_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
              <Col xs={12} md={10} lg={12} className="pl-0 py-3">
                <div className="mb-1 mt-1 modal-text-sm">
                  <p className="text-confirm">
                    Tem certeza que deseja desvincular o sensor
                    <span> {sensor} </span> do paciente{" "}
                    <span> {patient_name} </span>?
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center mx-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-2">
            <div
              className="secondary-btn"
              onClick={() => handler_modal_and_mdd_modal(false)}
            >
              Cancelar
            </div>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={loadingBtn === "flex"}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor: Colors.brand_pink,
              }}
              onClick={() => {
                if (userWithoutPermission(user)) {
                  setShowPw(true);
                } else {
                  Save();
                }
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: Colors.white,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Desvincular
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </>
    );
  }
  return (
    <>
      <Modal
        centered
        size="sm"
        show={show_modal}
        onHide={() => handler_modal_and_mdd_modal(false)}
        className="custom-modal-unlink-sensor"
        aria-labelledby="contained-modal-title-center"
      >
        {RenderPage()}
      </Modal>
      {showPw && (
        <VerifyPwWithUser
          handler_show_pw={setShowPw}
          show_pw={showPw}
          handler_request={Save}
        />
      )}
    </>
  );
}

export default ModalUnlinkSensor;
