import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import api from "../../../../../../services/api";
import moment from "moment";
import { Show_Alert } from "../../../../../../store/actions/alert";
import "./styles.css";
import Colors from "../../../../../../styles/Colors";
import Icon from "../../../../../../functions/Icon";
import DetailsCheckList from "../../CheckListPaciente/DetailsCheckList"; //Usando Modal da tab checklist pq é igual

var checkListObj = {};

function RelatorioCheckList() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [date_start, setDate_start] = useState("");
  const [date_end, setDate_end] = useState("");
  const [listChecklist, setListChecklist] = useState([]);
  const [showCheckList, setShowCheckList] = useState(false);

  function handleCallback(start, end) {
    setDate_start(moment(start).format("YYYY-MM-DD"));
    setDate_end(moment(end).format("YYYY-MM-DD"));
  }

  useEffect(() => {
    function LoadChecklist() {
      let date_start_convert = "";
      let date_end_convert = "";
      if (date_start != "") {
        date_start_convert = moment(date_start).format("YYYY-MM-DD");
      }
      if (date_end != "") {
        date_end_convert = moment(date_end).format("YYYY-MM-DD");
      }

      api
        .get(`/patients/${patientSelected.dados.id}/checklists`, {
          params: {
            date_start: date_start_convert,
            date_end: date_end_convert,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadChecklist");
            console.log(response.data);

            setListChecklist(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadChecklist erro " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível a lista de check lists desse paciente.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadChecklist();
  }, [date_end]);

  return (
    <div>
      {showCheckList && (
        <DetailsCheckList
          handler_show_modal={setShowCheckList}
          show_modal={showCheckList}
          check_list={checkListObj}
        />
      )}

      <div className="mt-4">
        <div className="mt-3">
          <Col xs={12} md={8} xl={5}>
            <Form.Label className="field-label">Período</Form.Label>
            <Row className="justify-content-start align-items-center mb-3 mx-0">
              <DateRangePicker
                initialSettings={{
                  locale: {
                    cancelLabel: "Cancelar",
                    applyLabel: "Aplicar",
                  },
                  drops: "up",
                  applyButtonClasses: "btn-dr-custom",
                }}
                onCallback={(start, end) => handleCallback(start, end)}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#ddd",
                    minHeight: 48,
                    borderRadius: 10,
                  }}
                  variant="light"
                  className="d-flex btn-date-range align-items-center"
                >
                  <Icon
                    color={Colors.darker_gray}
                    size={22}
                    icon="calendar"
                    className="mr-2"
                  />
                  <p className="m-0 text_e-left">
                    {date_start != ""
                      ? moment(date_start).format("DD/MM/YYYY")
                      : "Data inicial"}{" "}
                    -{" "}
                    {date_end != ""
                      ? moment(date_end).format("DD/MM/YYYY")
                      : "Data final"}
                  </p>
                </Button>
              </DateRangePicker>
            </Row>
          </Col>
        </div>
      </div>

      {loading && (
        <div className="mt-5 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && listChecklist.length == 0 && (
        <div className="container-error-default mt-5">
          <p>Não foram encontradas check lists para esse paciente.</p>
        </div>
      )}

      {!loading && listChecklist.length > 0 && (
        <div className="mt-2">
          <Table className="patient-table" responsive hover>
            <thead>
              <tr>
                <th>Data</th>
                <th>Horário</th>
                <th>Responsável</th>
              </tr>
            </thead>
            <tbody>
              {listChecklist.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      checkListObj = item;
                      setShowCheckList(true);
                    }}
                  >
                    <td>{moment(item.created).format("DD/MM")}</td>
                    <td>{moment(item.created).format("LT")}</td>
                    <td>{item.user_name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default RelatorioCheckList;
