import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import CheckBox from "../../../../components/CheckBox";

var list = [
  {
    id: 1,
    name: "A mudança de decúbito estiver próxima",
    type: "alert_soon",
  },
  {
    id: 2,
    name: "A mudança de decúbito estiver atrasada",
    type: "alert_delayed",
  },
  {
    id: 3,
    name: "O paciente estiver na posição errada",
    type: "alert_position",
  },
  {
    id: 4,
    name: "O paciente estiver na angulação errada",
    type: "alert_angle",
  },
];

function TempoSonoro() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [loading, setLoading] = useState(true);
  const [hospital, setHospital] = useState({});
  const [time, setTime] = useState("");
  const [listType, setListType] = useState([]);
  const [alert_soon, setAlert_soon] = useState(0);
  const [alert_delayed, setAlert_delayed] = useState(0);
  const [alert_position, setAlert_position] = useState(0);
  const [alert_angle, setAlert_angle] = useState(0);

  useEffect(() => {
    function LoadHospital() {
      api
        .get(`/hospitals/${user.dados.hospital_id}`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadHospital sucess");
            var data = response.data;
            console.log(data);
            setHospital(data);
            setTime(data.time_sound);

            //Types
            setAlert_soon(data.alert_soon);
            setAlert_delayed(data.alert_delayed);
            setAlert_position(data.alert_position);
            setAlert_angle(data.alert_angle);
          }
        })
        .catch((error) => {
          console.log("LoadHospital error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as informações do hospital",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadHospital();
  }, []);

  function SaveTime() {
    setLoadingBtn("flex");
    api
      .put(`/hospitals/${user.dados.hospital_id}`, {
        name: hospital.name,
        time_sound: time,
        alert_soon,
        alert_delayed,
        alert_position,
        alert_angle,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("SaveTime sucess");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "O tempo sonoro dos avisos foi atualizado",
            })
          );
        }
      })
      .catch((error) => {
        console.log("SaveTime error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar o tempo sonoro.",
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  function InvertNumber(number) {
    if (number == 0) return 1;
    else return 0;
  }

  function SetarType(type) {
    if (type == "alert_soon") setAlert_soon(InvertNumber(alert_soon));
    else if (type == "alert_delayed")
      setAlert_delayed(InvertNumber(alert_delayed));
    else if (type == "alert_position")
      setAlert_position(InvertNumber(alert_position));
    else if (type == "alert_angle") setAlert_angle(InvertNumber(alert_angle));
  }

  function VerifyType(type) {
    if (type == "alert_soon") return alert_soon;
    else if (type == "alert_delayed") return alert_delayed;
    else if (type == "alert_position") return alert_position;
    else if (type == "alert_angle") return alert_angle;
  }

  return (
    <div>
      <h2 className="screen-name mb-4">Tempo sonoro dos avisos</h2>

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && (
        <>
          <Col xs={12} md={10} lg={8} xl={4} className="mb-4 px-0">
            <Form.Label className="field-label">
              Tempo sonoro da notificação (min)
            </Form.Label>
            <input
              className="default-input"
              type="number"
              value={time}
              onChange={(e) => {
                var val = e.target.value;
                if (val <= 0) val = 1;
                setTime(val);
              }}
            />
          </Col>

          <Col xs={12} className="mb-4 px-0">
            <Form.Label className="field-label">Notificar quando</Form.Label>

            {list.map((item, index) => {
              return (
                <div
                  key={index}
                  className="modal-item"
                  onClick={() => SetarType(item.type)}
                >
                  <p
                    style={{
                      width: "90%",
                      color:
                        VerifyType(item.type) == 1
                          ? Colors.brand_blue
                          : Colors.darkest_gray,
                    }}
                    className="modal-name mb-0"
                  >
                    {item.name}
                  </p>

                  <CheckBox
                    checked={VerifyType(item.type) == 1}
                    value={item}
                    onclick={SetarType}
                  />
                </div>
              );
            })}
          </Col>

          <div className="mt-5">
            <button
              onClick={() => SaveTime()}
              className=" modal-footer-btn btn-confirm float-right"
              style={{
                backgroundColor: Colors.brand_pink,
                width: 100,
              }}
            >
              <p
                className="mb-0"
                style={{
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Salvar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default TempoSonoro;
