import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Spinner,
  Form,
} from "react-bootstrap";
import Select from "react-select";
import CheckBox from "../../../components/CheckBox";

import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import "../../../styles/global.css";

import { useSelector, useDispatch } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import VerifyPw from "../../../components/Modals/VerifyPw";
import {
  userHasPermission,
  userWithoutPermission,
} from "../../../functions/utils";

function ModalChecklist(props) {
  // Dados de usuário
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const unit = useSelector((state) => state.unit);

  // Exibição do modal
  const [show, setShow] = useState(props.show);
  const handler = props.handler;

  // Seleções/dados
  const defaultSelect = { value: 0, label: "Selecionar paciente" };
  const [patient, setPatient] = useState(undefined);
  const [patients, setPatients] = useState([]);
  const [preventivas, setPreventivas] = useState([]);

  // Flags
  const [modified, setModified] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [listUsers, setListUsers] = useState([]);
  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });
  const [showPw, setShowPw] = useState(false);

  const [checkAll, setCheckAll] = useState(false);

  function GetDesc() {
    if (
      user.dados.permission_id == 1 ||
      user.dados.permission_id == 2 ||
      user.dados.permission_id >= 6
    ) {
      return "Preencha a prescrição antes de realizar o checklist.";
    } else {
      return "Solicite à (o) enfermeira(o) que preencha a prescrição antes de realizar o checklist.";
    }
  }

  // Handler de exibição
  useEffect(() => {
    handler(show);
  }, [show]);

  // Carregamento de dados
  useEffect(() => {
    LoadPatients();
  }, []);

  // Quando muda paciente, altera preventivas e original
  useEffect(() => {
    MedidasPreventivas();
    setModified([]);
  }, [patient]);

  useEffect(() => {
    //Se permission_id for 1,2 ou 3 seta quem ta logado direto no responsavel
    if (userHasPermission(user)) {
      setUserChooser({ label: user.dados.name, value: user.dados.id });
    }
  }, []);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: unit.selecionado.id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userWithoutPermission(user)) {
      LoadUsers();
    }
  }, []);

  // Carrega lista de pacientes
  function LoadPatients() {
    return api
      .get(`/patients`, {
        params: {
          unit_id: unit.selecionado?.id,
          page: 1,
          pageSize: 999,
          status: 1,
          orders: "name",
          orders_type: "ASC",
        },
      })
      .then((response) => {
        if (response.status == 200) {
          // console.log("LoadPatients");
          // console.log(response.data);
          var array = [];
          response.data.data.map((item) => {
            array.push({
              key: item.id,
              label: item.name,
              value: item.id,
            });
          });
          setPatients(array);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Carrega checklist
  function MedidasPreventivas() {
    if (patient != undefined) {
      setLoading(true);
      return (
        api
          // .get(`/patients/${patient.value}/prescriptions`)
          .get(`/patients/${patient.value}/prescriptions?checklist=1`)
          .then((response) => {
            if (response.status == 200) {
              console.log("Response medidas:");
              console.log(response.data);
              var array = [];

              if (response.data.length > 0) {
                response.data.map((item) => {
                  item.is_checked = 0;
                  array.push(item);
                });
              }

              setPreventivas(array);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          })
      );
    }
  }

  // Marcar/desmarcar item
  function ToggleCheck(p) {
    // Modifica preventivas
    var lista = [];
    preventivas.forEach((item, i) => {
      if (item.preventive_id == p.preventive_id) {
        item.is_checked =
          p.is_checked > 0 ? (p.is_checked = 0) : (p.is_checked = 1);

        if (modified.length > 0 && modified.includes(item.preventive_id)) {
          var lista1 = [];
          lista1 = modified.filter((id) => id != item.preventive_id);
          setModified(lista1);
        } else {
          var lista1 = [];
          modified.forEach((prev) => {
            lista1.push(prev);
          });
          lista1.push(item.preventive_id);
          setModified(lista1);
        }
      }

      lista.push(item);
    });

    setCheckAll(false);
    setPreventivas(lista);
  }

  useEffect(() => {
    if (preventivas.length > 0) {
      var array = [];
      preventivas.map((i) => {
        array.push(i.preventive_id);
      });
      var found = preventivas.find((item) => item.is_checked == 0);
      if (found == undefined) {
        setCheckAll(true);
        setModified(array);
      }
    }
  }, [preventivas]);

  // Enviar checklist
  function Salvar() {
    if (
      patient == undefined ||
      modified.length == 0 ||
      userChooser.value == 0
    ) {
      return false;
    }

    // Formatação
    var checklist = [];
    checklist = preventivas.map((p) => {
      return {
        value_prescription_id: p.id,
        value: p.value,
        is_checked: p.is_checked,
      };
    });

    if (preventivas.length > 0) {
      setLoadingBtn(true);
      api
        .post(`/patients/${patient.value}/checklists`, {
          user_id: userChooser.value,
          prescriptions: checklist,
        })
        .then((response) => {
          if (response.status == 200) {
            dispatch(
              Show_Alert({
                type: "success",
                msg: "Checklist cadastrada com sucesso.",
              })
            );
          }
        })
        .catch((error) => {
          console.log("SaveChecklist error: ");
          console.log(error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível salvar a checklist, tente novamente.",
            })
          );
        })
        .finally(() => {
          setLoadingBtn(false);
          setShow(false);
        });
    }
  }

  function CheckAll() {
    if (checkAll) {
      setModified([]);
    }

    var newList = preventivas.map((item) => {
      item.is_checked = checkAll == false ? 1 : 0;
      return item;
    });

    setCheckAll(!checkAll);
    setPreventivas(newList);
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-checklist px-0"
      >
        {showPw && (
          <VerifyPw
            handler_show_pw={setShowPw}
            show_pw={showPw}
            handler_user={setUserChooser}
            id_user={userChooser.value}
          />
        )}
        <Modal.Body className="pt-2 px-0">
          <div style={{ paddingLeft: "2em", paddingRight: "2em" }}>
            <Row className="justify-content-between align-items-center mb-4 mx-0">
              <Col className="px-0">
                <div className="modal-title">Checklist</div>
              </Col>
              <Col
                xs={2}
                md={2}
                lg={2}
                className="px-0 d-flex justify-content-end"
              >
                <div className="modal-close-btn" onClick={() => setShow(false)}>
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>

            {userWithoutPermission(user) && (
              <div className="mb-4">
                <Form.Label className="field-label">Responsável</Form.Label>
                <Select
                  captureMenuScroll={false}
                  classNamePrefix="react-select"
                  placeholder={"Selecionar"}
                  options={listUsers}
                  isClearable={false}
                  value={userChooser}
                  onChange={(item) => {
                    setUserChooser(item);
                    setShowPw(true);
                  }}
                />
              </div>
            )}

            <label className="field-label">Paciente</label>
            <Select
              captureMenuScroll={false}
              classNamePrefix="react-select"
              placeholder={"Selecionar"}
              options={patients}
              isClearable={false}
              value={patient}
              onChange={(p) => {
                setCheckAll(false);
                setPatient(p);
              }}
            />

            <div className="bold-txt my-3">Prescrição do paciente</div>

            {patient == undefined && (
              <div
                style={{
                  background: Colors.lightest_gray,
                  borderRadius: "12px",
                  padding: "2em",
                  fontFamily: "var(--regular)",
                  fontSize: "0.85em",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="mb-3"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    variant="dark"
                    size="lg"
                  />
                ) : (
                  "Selecione o paciente para visualizar a prescrição."
                )}
              </div>
            )}

            {!loading && preventivas.length > 0 && patient != undefined && (
              <div className="modal-item mt-3" onClick={() => CheckAll()}>
                <p
                  style={{
                    width: "90%",
                    color:
                      checkAll == true
                        ? Colors.brand_blue
                        : Colors.darkest_gray,
                  }}
                  className="modal-name mb-0"
                >
                  {checkAll == true ? "Desmarcar todos" : "Selecionar todos"}
                </p>

                <CheckBox checked={checkAll} onclick={CheckAll} />
              </div>
            )}

            {patient != undefined &&
              (preventivas == undefined || preventivas.length == 0) && (
                <div
                  style={{
                    background: Colors.lightest_gray,
                    borderRadius: "12px",
                    padding: "2em",
                    fontFamily: "var(--regular)",
                    fontSize: "0.85em",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="mb-3"
                >
                  {GetDesc()}
                </div>
              )}
          </div>

          {preventivas != undefined && preventivas.length > 0 && (
            <div style={{ paddingRight: 32, paddingLeft: 32 }}>
              {preventivas.map((p) => {
                return (
                  <div className="modal-item">
                    <div
                      className="checklist-item-txt"
                      onClick={() => ToggleCheck(p)}
                      style={{
                        width: "90%",
                        color:
                          p.is_checked == 1
                            ? Colors.brand_blue
                            : Colors.darkest_gray,
                      }}
                    >
                      {p.value}
                    </div>
                    <div>
                      <CheckBox
                        checked={p.is_checked > 0}
                        value={p}
                        onclick={ToggleCheck}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0 mx-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-3">
            <div
              className="secondary-btn modal-footer-btn"
              onClick={() => setShow(false)}
            >
              Cancelar
            </div>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <div
              onClick={() => Salvar()}
              className={
                patient != undefined &&
                modified.length > 0 &&
                userChooser.value > 0
                  ? "default-btn mb-0 modal-footer-btn"
                  : "default-btn disabled mb-0 modal-footer-btn"
              }
            >
              {loadingBtn ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Salvar
                  <Spinner
                    className="ml-2"
                    animation="border"
                    role="status"
                    variant="light"
                    size="sm"
                  />
                </div>
              ) : (
                <div>Salvar</div>
              )}
            </div>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalChecklist;
