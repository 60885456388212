import React, { useEffect } from "react";
import Routes from "../src/routes";
import CacheBuster from "react-cache-buster";
import "./App.scss";

function App() {
  useEffect(() => {
    window.addEventListener("touchstart", disablePullToRefresh);
    return () => {
      window.removeEventListener("touchstart", disablePullToRefresh);
    };
  }, []);

  function disablePullToRefresh(event) {
    if (event.touches.length !== 1) {
      return;
    }

    const scrollY =
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    if (scrollY > 0) {
      return;
    }

    // Prevents pull-to-refresh actions
    event.preventDefault();
  }
  //Remove console.log em produção
  //console.log = () => {};
  // if (process.env.NODE_ENV !== "development") console.log = () => {};
  return <CacheBuster isEnabled={process.env.REACT_APP_IS_PRODUCTION != 'false'}><Routes /></CacheBuster>;
}

export default App;
