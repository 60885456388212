import React, { useState } from "react";
import { Col, Row, Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

import api from "@Services/api";
import { Show_Alert } from "@Store/actions/alert";
import Colors from "@Styles/Colors";
import Icon from "@Functions/Icon";

import "../../../Configuracoes/Tabs/PainelSensores/PainelSensores.scss";

function ModalDeleteShift({ ...props }) {
  const dispatch = useDispatch();
  const { handler_show_modal, show_modal, handler_reload, reload, shift } =
    props;

  const [loadingBtn, setLoadingBtn] = useState("none");

  function Delete() {
    setLoadingBtn("flex");
    api
      .delete(`/hospitals/1/shifts/${shift.id}`)
      .then((response) => {
        if (response.status == 200) {
          dispatch(
            Show_Alert({
              type: "success",
              msg: "O plantão foi excluído com sucesso",
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("Delete error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível excluir o plantão",
          })
        );
      })
      .finally(() => {
        handler_show_modal(false);
        setLoadingBtn("none");
      });
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-filter"
      data-testid="beacon-panel-toggle"
    >
      <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Col xs={8}>
            <div className="modal-title">Excluir sensor</div>
          </Col>
          <Col xs={2} md={2} lg={2} className="pr-0 d-flex justify-content-end">
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </Col>
        </Row>

        <div className="mt-4">
          <p className="text-delete-user">
            O plantão <span>{shift.name}</span> será excluído, tem certeza que
            deseja continuar?
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer className="row justify-content-end align-items-center pb-0">
        <Col xs={4} md={3} lg={3} className="px-0 mr-3">
          <div
            className="secondary-btn modal-footer-btn"
            onClick={() => handler_show_modal(false)}
          >
            Cancelar
          </div>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={loadingBtn === "flex"}
            style={{
              backgroundColor: Colors.brand_red,
              cursor: "pointer",
            }}
            // style={{ backgroundColor: Colors.brand_pink, cursor: "pointer" }}
            className="mb-0 modal-footer-btn btn-confirm"
            onClick={() => Delete()}
            data-testid="beacon-panel-toggle-action"
          >
            <p
              className="mb-0"
              style={{
                color: Colors.white,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              Excluir
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDeleteShift;
