import React, { useState, useEffect } from "react";
import { Col, Row, Form, Spinner, Button, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Colors from "../../../../../../../styles/Colors";
import Icon from "../../../../../../../functions/Icon";
import CheckBox from "../../../../../../../components/CheckBox";

import "../styles.css";
import TotalTimePositionPie from "./TotalTimePositionPie";
import SensorPrescriptionTimeTwoBar from "./SensorPrescriptionTimeTwoBar";
import api from "../../../../../../../services/api";
import {
  secondsToDays,
  secondsToHoursMinutes,
} from "../../../../../../../functions/utils";
import EmptyContainer from "../../../../../../Relatorios/EmptyContainer";
import AdherenceLine from "./AdherenceLine";
import AdhesionsByShiftBar from "./AdhesionsByShiftBar";
import LastShiftsSuspensionsDropdown from "../../../../../../../components/Dropdowns/LastShiftsSuspensionsDropdown";
import SuspensionsByShiftBar from "./SuspensionsByShiftBar";
import SuspensionsRanking from "./SuspensionsRanking";
import DateRangerPicker from "../../../../../../../components/DateRangerPicker";

function VisaoGeral() {
  const startOfMonth = moment().startOf("month");
  const initialPeriod = {
    start: startOfMonth.format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };

  const patientSelected = useSelector((state) => state.patientSelected);

  const [patient, setPatient] = useState(null);
  const [allPeriod, setAllPeriod] = useState(false);
  const [dateInterval, setDateInterval] = useState(initialPeriod);
  const [loading, setLoading] = useState(false);
  const [loadingAdherence, setLoadingAdherence] = useState(false);
  const [loadingShiftAdherence, setLoadingShiftAdherence] = useState(false);
  const [loadingShiftSuspension, setLoadingShiftSuspension] = useState(false);
  const [overviewHistorics, setOverviewHistorics] = useState(null);
  const [overviewSuspensions, setOverviewSuspensions] = useState(null);
  const [overviewSchedules, setOverviewSchedules] = useState(null);
  const [overviewAverageDelayedTime, setOverviewAverageDelayedTime] =
    useState(null);
  const [protocolAdherence, setProtocolAdherence] = useState(null);
  const [protocolAdherenceGeneral, setProtocolAdherenceGeneral] =
    useState(null);
  const [shiftsAdherence, setShiftsAdherence] = useState(null);
  const [shiftsSupensionsOriginal, setShiftsSuspensionsOriginal] =
    useState(null);
  const [shiftsSupensions, setShiftsSuspensions] = useState(null);
  const [suspensions, setSuspensions] = useState(null);

  const [adherenceShiftsQuantity, setAdherenceShiftsQuantity] = useState({
    name: "Últimos 7 plantões",
    value: 7,
  });
  const [suspensionsShiftsQuantity, setSuspensionsShiftsQuantity] = useState({
    name: "Últimos 7 plantões",
    value: 7,
  });

  function handleCallback(start, end) {
    setDateInterval({
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
    });
  }

  function setPatientAllPeriod() {
    if (patient) {
      if (loading) {
        return;
      }
      if (!allPeriod) {
        const patientCreatedAt = moment(patient.created_at);
        const patientDischarge = patient.datetime_discharge_hospital
          ? moment(patient.datetime_discharge_hospital)
          : null;
        if (patientCreatedAt && patientDischarge) {
          setDateInterval({
            start: patientCreatedAt.format("YYYY-MM-DD"),
            end: patientDischarge.format("YYYY-MM-DD"),
          });
        } else {
          setDateInterval({
            start: patientCreatedAt.format("YYYY-MM-DD"),
            end: initialPeriod.end,
          });
        }
        setAllPeriod(true);
      } else {
        setDateInterval(initialPeriod);
        setAllPeriod(false);
      }
    }
  }

  function LoadPatientOverview() {
    setOverviewHistorics(null);
    setOverviewSuspensions(null);
    setOverviewSchedules(null);
    setLoading(true);
    api
      .get(`patients/${patientSelected.dados.id}/overview`, {
        params: {
          dateInitial: dateInterval.start,
          dateFinal: dateInterval.end,
        },
      })
      .then((response) => {
        if (response.data) {
          if (response.data.averageDelayedTime) {
            setOverviewAverageDelayedTime(response.data.averageDelayedTime);
          }
          if (response.data.historics.totalTime > 0) {
            setOverviewHistorics(response.data.historics);
          }
          if (
            response.data.suspensions.suspensions > 0 ||
            response.data.suspensions.breaks > 0
          ) {
            setOverviewSuspensions(response.data.suspensions);
            if (
              Object.keys(response.data.suspensions.suspensionsGeneralData)
                .length > 0
            ) {
              setSuspensions(
                response.data.suspensions.suspensionsGeneralData.sort(
                  (a, b) => b.totalSuspensions - a.totalSuspensions
                )
              );
            }
          }
          setOverviewSchedules(response.data.schedules);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function LoadPatientAdherence() {
    setLoadingAdherence(true);
    api
      .get("/dashboard/protocol-adherence", {
        params: {
          patientId: patientSelected.dados.id,
          initialDate: dateInterval.start,
          finalDate: dateInterval.end,
        },
      })
      .then((response) => {
        if (response.data && Object.keys(response.data[0]).length > 0) {
          let { off, M, F, ...shifts } = response.data[0];
          setProtocolAdherenceGeneral(off[0]);
          off.shift();
          if (off.length > 0) {
            setProtocolAdherence(off);
          }
          if (Object.keys(shifts).length > 0) {
            setShiftsAdherence(shifts);
          }
        }
      })
      .finally(() => {
        setLoadingAdherence(false);
      });
  }

  function LoadLastShiftsSuspensions() {
    setLoadingShiftSuspension(true);
    setShiftsAdherence(null);
    api
      .get("/dashboard/suspensions", {
        params: {
          dateInitial: dateInterval.start,
          dateFinal: dateInterval.end,
          quantity: adherenceShiftsQuantity.value,
          patientId: patientSelected.dados.id,
        },
      })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setShiftsSuspensionsOriginal(response.data[0].lastShifts);
          if (response.data[0].lastShifts.length > 0) {
            setShiftsSuspensions(
              response.data[0].lastShifts.slice(
                -suspensionsShiftsQuantity.value
              )
            );
          }
        }
      })
      .finally(() => {
        setLoadingShiftSuspension(false);
      });
  }

  useEffect(() => {
    if (patientSelected.dados) {
      LoadPatientOverview();
      LoadPatientAdherence();
      LoadLastShiftsSuspensions();
    }
  }, [patientSelected, dateInterval]);

  useEffect(() => {
    if (patientSelected.dados) {
      //LoadLastShiftsAdherence();
    }
  }, [patientSelected, dateInterval, adherenceShiftsQuantity]);

  useEffect(() => {
    if (shiftsSupensions && shiftsSupensions.length > 0) {
      let shiftsSuspensionsTempArr = shiftsSupensionsOriginal.slice(
        -suspensionsShiftsQuantity.value
      );
      setShiftsSuspensions(shiftsSuspensionsTempArr);
    }
  }, [suspensionsShiftsQuantity]);

  useEffect(() => {
    api.get("/patients/" + patientSelected.dados.id).then((response) => {
      if (response.data) {
        setPatient(response.data);
      }
    });
  }, [patientSelected]);

  return (
    <div className="mt-4">
      <div className="mt-3 mb-4">
        <Row>
          <Col xs={12} md={8} xl={5}>
            <Form.Label className="field-label">Período</Form.Label>
            <Row className="justify-content-start align-items-center mb-3 mx-0">
              <DateRangerPicker
                dateInterval={dateInterval}
                dateIntervalHandler={setDateInterval}
              />
            </Row>
            {patient && (
              <div
                className="overview-all-hospitalization-period"
                onClick={setPatientAllPeriod}
              >
                <CheckBox size="20px" noMargin={true} checked={allPeriod} />
                <p
                  className="ml-2 mb-0"
                  style={{
                    color: allPeriod ? Colors.brand_blue : "",
                  }}
                >
                  Período total de internação
                </p>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div className="card-report overview-card-report h-100">
        <h4 className="mb-4">Visão geral</h4>
        {false && (
          <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {!loading ? (
          <div
            className="card-report-info overview-card-report-info"
            style={{
              backgroundColor: "#ffff",
            }}
          >
            <Row>
              <Col md={6} sm={12}>
                <span className="report-value">
                  {overviewHistorics?.totalTime
                    ? secondsToDays(overviewHistorics.totalTime)
                    : 0}
                </span>
                <span className="report-desc">Total de dias monitorados</span>
              </Col>
              <Col md={6} sm={12}>
                <span className="report-value">
                  {overviewHistorics?.totalTime
                    ? secondsToHoursMinutes(overviewHistorics.totalTime)
                    : "Sem informação"}
                </span>
                <span className="report-desc">
                  Tempo total de monitoramento
                </span>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6} sm={12}>
                <span className="report-value">
                  {overviewSuspensions?.suspensions
                    ? overviewSuspensions?.suspensions
                    : 0}
                </span>
                <span className="report-desc">Total de avisos suspensos</span>
              </Col>
              <Col md={6} sm={12}>
                <span className="report-value">
                  {overviewSuspensions?.suspensions
                    ? secondsToHoursMinutes(overviewSuspensions.totalTime)
                    : "0 min"}
                </span>
                <span className="report-desc">
                  Tempo total de avisos suspensos
                </span>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6} sm={12}>
                <span className="report-value">
                  {overviewSuspensions?.breaks
                    ? overviewSuspensions?.breaks
                    : 0}
                </span>
                <span className="report-desc">Total de avisos pausados</span>
              </Col>
              <Col md={6} sm={12}>
                <span className="report-value">
                  {overviewSuspensions?.breaks
                    ? secondsToHoursMinutes(overviewSuspensions.totalTimePaused)
                    : "0 min"}
                </span>
                <span className="report-desc">
                  Tempo total de avisos pausados
                </span>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6} sm={12}>
                <span className="report-value">
                  {overviewAverageDelayedTime
                    ? secondsToHoursMinutes(overviewAverageDelayedTime)
                    : "0 min"}
                </span>
                <span className="report-desc">
                  Tempo médio de atraso na mudança de decúbito
                </span>
              </Col>
              <Col md={6} sm={12}>
                <span className="report-value">
                  {protocolAdherenceGeneral
                    ? protocolAdherenceGeneral.avgTotalRate + "%"
                    : "Sem informação"}
                </span>
                <span className="report-desc">Aderência média</span>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="my-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
      <div className="card-report overview-card-report h-100 overview-total-time-per-position">
        <h4 className="mb-4">Tempo total por posição</h4>
        {loading ? (
          <div className="my-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : overviewHistorics?.positionTime ? (
          <Row className="justify-content-center align-items-center">
            <Col md={6} sm={12}>
              {overviewHistorics.positionTime.map(
                (item, index) =>
                  index % 2 === 0 && (
                    <Row key={index}>
                      <Col md={6} sm={12}>
                        <div className="overview-total-time-per-position-item">
                          <p className="overview-total-time-per-position-time">
                            {secondsToHoursMinutes(item.time)}
                          </p>
                          <p className="overview-total-time-per-position-position">
                            {item.position}
                          </p>
                        </div>
                      </Col>
                      {overviewHistorics.positionTime[index + 1] && (
                        <Col md={6} sm={12}>
                          <div className="overview-total-time-per-position-item">
                            <p className="overview-total-time-per-position-time">
                              {secondsToHoursMinutes(
                                overviewHistorics.positionTime[index + 1].time
                              )}
                            </p>
                            <p className="overview-total-time-per-position-position">
                              {
                                overviewHistorics.positionTime[index + 1]
                                  .position
                              }
                            </p>
                          </div>
                        </Col>
                      )}
                    </Row>
                  )
              )}
            </Col>
            <Col md={6} sm={12}>
              <TotalTimePositionPie historics={overviewHistorics} />
            </Col>
          </Row>
        ) : (
          <EmptyContainer text="Ainda não há dados sobre históricos nesse período"></EmptyContainer>
        )}
      </div>

      <div className="card-report overview-card-report h-100 overview-monitored-time-prescription-time">
        <h4 className="mb-4">Tempo monitorado x Prescrição</h4>
        {!loading ? (
          <>
            {overviewSchedules && overviewHistorics ? (
              <SensorPrescriptionTimeTwoBar
                schedules={overviewSchedules}
                historics={overviewHistorics}
              />
            ) : (
              <EmptyContainer text="Ainda não há dados sobre históricos nesse período"></EmptyContainer>
            )}
          </>
        ) : (
          <div className="my-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
      <div className="card-report overview-card-report h-100 overview-monitored-time-prescription-time">
        <h4 className="mb-4">Aderência ao protocolo</h4>
        {!loadingAdherence ? (
          <>
            {protocolAdherence ? (
              <AdherenceLine adherenceData={protocolAdherence} />
            ) : (
              <EmptyContainer text="Ainda não há dados sobre aderência ao protocolo"></EmptyContainer>
            )}
          </>
        ) : (
          <div className="my-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
      <div className="card-report overview-card-report h-100 overview-monitored-time-prescription-time">
        <div className="last-shifts-title align-items-center mb-4">
          <h4 className="mb-0">Aderência ao protocolo por plantão</h4>
        </div>
        {!loadingAdherence ? (
          <>
            {shiftsAdherence ? (
              <AdhesionsByShiftBar adherenceData={shiftsAdherence} />
            ) : (
              <EmptyContainer text="Ainda não há dados sobre aderência ao protocolo relacionado a plantões" />
            )}
          </>
        ) : (
          <div className="my-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
      <div className="card-report overview-card-report h-100 overview-monitored-time-prescription-time">
        <div className="last-shifts-title align-items-center mb-4">
          <h4 className="mb-0">Suspensões por plantão</h4>
          {!loadingShiftSuspension && (
            <LastShiftsSuspensionsDropdown
              sort={suspensionsShiftsQuantity}
              handler={setSuspensionsShiftsQuantity}
            />
          )}
        </div>
        {!loadingShiftSuspension ? (
          <>
            {shiftsSupensions ? (
              <SuspensionsByShiftBar suspensionsData={shiftsSupensions} />
            ) : (
              <EmptyContainer text="Ainda não há dados sobre suspensões relacionado a plantões" />
            )}
          </>
        ) : (
          <div className="my-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
      <div className="card-report overview-card-report h-100 overview-monitored-time-prescription-time">
        <div className="last-shifts-title align-items-center mb-4">
          <h4 className="mb-0">Ranking motivos de suspensão</h4>
        </div>
        {!loading ? (
          <>
            {suspensions ? (
              <SuspensionsRanking suspensions={suspensions} />
            ) : (
              <EmptyContainer text="Ainda não há dados de suspensões relacionados a este paciente" />
            )}
          </>
        ) : (
          <div className="my-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </div>
  );
}

export default VisaoGeral;
