import React from "react";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";

function EmptyContainer(props) {
  const { text, iconName, iconSize, minHeight } = props;

  return (
    <div
      className="container-empty w-100 mt-3"
      style={{
        minHeight: minHeight == false ? "" : "300px",
        padding: "0px 20px",
        maxHeight: "90%",
      }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Icon
          color={Colors.darker_gray}
          size={iconSize | 32}
          icon={iconName}
          className="mb-3"
        />
        <p>{text}</p>
      </div>
    </div>
  );
}

export default EmptyContainer;
