import React, { useState, useEffect } from "react";
import { Col, Container, Row, Spinner, Modal, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import Colors from "../../../styles/Colors";
import Icon from "../../../functions/Icon";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import moment from "moment";
import WoundEditConfirm from "../../../components/Modals/WoundEditConfirm";
import { renderPhoto } from "../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "./Notificacoes";

var woundEditConfirm = null;
var isConfirm = false;
var isConfirmed = false;

function NotificacoesPendentes({ history }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const unit = useSelector((state) => state.unit);

  const [loading, setLoading] = useState(true);
  const [listNotifications, setListNotifications] = useState([]);
  const [reload, setReload] = useState(false);

  //Modal
  const [showModal, setShowModal] = useState(false);
  const [patientObj, setPatientObj] = useState({});

  const [showWound, setShowWound] = useState(false);
  const [wounds, setWounds] = useState([]);

  // Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [qtdDisplay, setQtdDisplay] = useState(12);
  const [totalResults, setTotalResults] = useState(null);
  const [pagination, setPagination] = useState([]);
  const [hoverBack, setHoverBack] = useState(false);
  const [hoverNext, setHoverNext] = useState(false);

  useEffect(() => {
    if (location.state?.itemCard) {
      setPatientObj(location.state.itemCard);
      setShowModal(true);
      //Reset state
      let state = { ...history.location.state };
      delete state.itemCard;
      history.replace({ ...history.location, state });
    }
  }, []);

  useEffect(() => {
    function LoadNotifications() {
      api
        .get(`/patients-wounds/list`, {
          params: {
            unit_id: unit.selecionado.id,
            type: "pending",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadNotificaftions");
            console.log(response.data);

            setListNotifications(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadNotifications pending error" + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar as Notificações pedentes.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    if (unit.selecionado?.id != undefined) {
      LoadNotifications();
    } else {
      setLoading(false);
    }
  }, [unit, reload, currentPage, qtdDisplay]);

  function BuildPagination(currentPage, lastPage) {
    var pagination = [];
    for (var i = currentPage; i < currentPage + 5; i++) {
      if (i == currentPage && currentPage > 2) {
        pagination.push(currentPage - 2);
      }
      if (i == currentPage && currentPage > 1) {
        pagination.push(currentPage - 1);
      }

      if (i <= lastPage) {
        pagination.push(i);
      }
    }
    setPagination(pagination);
  }

  function SetarDisplay(qtd) {
    setCurrentPage(1);
    setQtdDisplay(qtd);
  }

  function renderDisplay() {
    var n = 0;

    if (lastPage > 1) {
      n = qtdDisplay * currentPage;
      if (currentPage == lastPage) n = totalResults;
    } else {
      n = totalResults;
    }

    return n;
  }

  function LoadPatient() {
    api
      .get(`/patients/${patientObj.patient_id}`)
      .then((response) => {
        if (response.status == 200) {
          console.log("LoadPatient success");
          console.log(response.data);

          var array = [];
          response.data.wounds.map((item) => {
            patientObj.wounds.map((i) => {
              if (item.id == i.patient_wound_id) {
                array.push(item);
              }
            });
          });
          setWounds(array);

          woundEditConfirm = array[0];
          setShowModal(false);
          setShowWound(true);
        }
      })
      .catch((error) => {
        console.log("LoadPatient " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível carregar as lesoes desse paciente.",
          })
        );
      });
  }

  function renderModal() {
    return (
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <a
                className="modal-title-link"
                href="#"
                onClick={() => {
                  history.push({ pathname: "/Pacientes/Perfil-Paciente" });
                  var obj = {
                    id: patientObj.patient_id,
                    name: patientObj.patient,
                    qtd_wounds: patientObj.count_wounds,
                    unit_id: patientObj.unit_id,
                  };

                  dispatch({ type: "SET_PATIENT_SELECTED", dados: obj });
                  dispatch({ type: "SET_PATIENT_TAB", selected: 0 });
                }}
              >
                {patientObj.patient_initials}
              </a>
            </div>
            <div className="d-flex">
              <div
                className="modal-close-btn mr-2"
                onClick={() => {
                  isConfirm = false;
                  isConfirmed = false;
                  LoadPatient();
                }}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="edit" />
              </div>
              <div
                className="modal-close-btn"
                onClick={() => setShowModal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            <div
              className="main-card main-card-notif"
              style={{ boxShadow: "none", padding: 0, cursor: "unset" }}
            >
              <div className="card-quarto">
                {" "}
                {patientObj.room == null && patientObj.subroom}
                {patientObj.room != null &&
                  patientObj.subroom != null &&
                  `${patientObj.room}  |  ${patientObj.subroom}`}
              </div>

              <div
                className="card-data-sm mt-3"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon="band-aid"
                  className="mr-2"
                />
                {patientObj.wounds.length}{" "}
                {patientObj.wounds.length == 1
                  ? "lesão notificada"
                  : "lesões notificadas"}
              </div>

              <div
                className="card-data-sm mt-2"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon={patientObj.type === "admission" ? "enter" : "hospital"}
                  className="mr-2"
                />

                {patientObj.type == "admission" ? "Admissão" : "Incidida"}
              </div>

              <div
                className="mt-3"
                style={{ maxHeight: "50vh", overflowY: "auto" }}
              >
                {patientObj.wounds.map((item, index) => {
                  return (
                    <div key={index} className="container-wound">
                      <div className="container-wound-modal">
                        <div className="d-flex justify-content-between">
                          <p className="wound-title">Local</p>
                          <p className="wound-subtitle">{item.wound_name}</p>
                        </div>

                        <div className="d-flex justify-content-between">
                          <p className="wound-title">Classificação</p>
                          <p className="wound-subtitle">{item.stage_name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="d-flex justify-content-between align-items-center mt-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div className="c-profile-dropdown mr-2">
                    {renderPhoto(
                      makeUserDataToPhoto(
                        patientObj.user_name,
                        patientObj.user_image !== null
                          ? patientObj.user_image
                          : null
                      )
                    )}
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="card-data-xs" style={{ color: "#000" }}>
                      Por {patientObj.user_name}
                    </div>
                    <div className="card-data-xs">
                      {moment(patientObj.date_verify).format("LLL")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              className="modal-footer-btn mr-0 btn-cancel"
              onClick={() => setShowModal(false)}
            >
              Cancelar
            </button>
          </Col>

          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              onClick={() => {
                isConfirm = true;
                isConfirmed = false;
                LoadPatient();
              }}
              disabled={DisableBtn(
                patientObj.button_avaliation,
                patientObj.user_id
              )}
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  DisableBtn(
                    patientObj.button_avaliation,
                    patientObj.user_id
                  ) == false
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
            >
              <p
                style={{
                  color:
                    DisableBtn(
                      patientObj.button_avaliation,
                      patientObj.user_id
                    ) == false
                      ? "white"
                      : Colors.dark_gray,
                }}
              >
                Confirmar
              </p>
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }

  function DisableBtn(button, id) {
    var disable = true;
    if (button && id != user.dados.id) disable = false;
    return disable;
  }

  return (
    <div className="d-flex flex-wrap">
      {patientObj.patient_id != undefined && renderModal()}
      {showWound && (
        <WoundEditConfirm
          show_wound={showWound}
          handler_show_wound={setShowWound}
          handler_reload={setReload}
          reload={reload}
          wound_edit_confirm={woundEditConfirm}
          is_confirm={isConfirm}
          is_confirmed={isConfirmed}
          wounds={wounds}
        />
      )}

      {!loading && listNotifications.length == 0 && (
        <div className="container-error-default">
          <p>Ainda não foram realizadas notificações de lesão por pressão.</p>
        </div>
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading &&
        listNotifications.map((item, index) => {
          return (
            <Col
              key={index}
              xs={12}
              md={6}
              lg={4}
              xl={3}
              className="my-2"
              onClick={() => {
                setPatientObj(item);
                setShowModal(true);
              }}
            >
              <div className="main-card main-card-notif">
                <div className="card-name card-name-mdd mb-1">
                  {item.patient_initials}
                </div>

                <div className="card-quarto card-quarto-mdd">
                  {" "}
                  {item.room == null && item.subroom}
                  {item.room != null &&
                    item.subroom != null &&
                    `${item.room}  |  ${item.subroom}`}
                </div>

                <div
                  className="card-data-sm mt-3"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Icon
                    color={Colors.brand_blue}
                    size={20}
                    icon="band-aid"
                    className="mr-2"
                  />
                  {item.wounds.length}{" "}
                  {item.wounds.length == 1
                    ? "lesão notificada"
                    : "lesões notificadas"}
                </div>

                <div
                  className="card-data-sm mt-2"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Icon
                    color={Colors.brand_blue}
                    size={20}
                    icon={item.type === "admission" ? "enter" : "hospital"}
                    className="mr-2"
                  />

                  {item.type == "admission" ? "Admissão" : "Incidida"}
                </div>

                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div className="c-profile-dropdown mr-2">
                    {renderPhoto(
                      makeUserDataToPhoto(
                        item.user_name,
                        item.user_image !== null ? item.user_image : null
                      )
                    )}
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="card-data-xs" style={{ color: "#000" }}>
                      Por {item.user_name}
                    </div>
                    <div className="card-data-xs">
                      {moment(item.date_verify).format("LLL")}
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="mb-0 modal-footer-btn btn-confirm no-radius"
                style={{
                  backgroundColor:
                    DisableBtn(item.button_avaliation, item.user_id) == false
                      ? Colors.brand_pink
                      : Colors.lighter_gray,
                }}
                onClick={() => {
                  setPatientObj(item);
                  setShowModal(true);
                }}
              >
                <p
                  style={{
                    color:
                      DisableBtn(item.button_avaliation, item.user_id) == false
                        ? "white"
                        : Colors.dark_gray,
                  }}
                >
                  Confirmar
                </p>
              </button>
            </Col>
          );
        })}
    </div>
  );
}

export default NotificacoesPendentes;
