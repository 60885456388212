import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

var arrayOrder = [
  {
    icon: "zoom-in",
    value: 3,
    name: "100%"
  },
  {
    icon: "zoom-in",
    value: 4,
    name: "75%"
  },
  {
    icon: "zoom-in",
    value: 6,
    name: "50%"
  }
];

function ZoomDecubitMapDropdown(props) {
  const dispatch = useDispatch();
  const zoomState = useSelector((state) => state.zoomSelected);

  const { zoom, handler } = props;
  // Exibir dropdown
  const [show, setShow] = useState(false);

  // Ordenação escolhida
  const [selected, setSelected] = useState({
    icon: "zoom-in",
    value: "3",
    name: "100%"
  });

  useEffect(() => {
    if (zoom.name != undefined) {
      let zoomSelected = zoomState.dados ? zoomState.dados : zoom;
      handler(zoomSelected);
      setSelected(zoomSelected);
    }
  }, [zoom]);

  function SetHandler(obj) {
    handler(obj);
    dispatch({
      type: "SET_ZOOM_SELECTED",
      dados: obj,
    });
  }

  function GetNameSelected() {
    var name = selected.name;
    return name;
  }

  return (
    <Dropdown
      className="drop-sort mr-3"
      onToggle={() => setShow(!show)}
      style={{ fontSize: "17px" }}
    >
      <Dropdown.Toggle
        id="dropdown-zoom"
        className={
          show || selected.name != "100%"
            ? `btn-filter-mdd active`
            : `btn-filter-mdd`
        }
        style={{
          padding: "30px!important",
        }}
      >
        <Icon
          color={
            show || selected.name != "100%"
              ? Colors.brand_blue
              : Colors.darker_gray
          }
          size={22}
          icon={selected.icon}
          className="mr-2"
        />
        {GetNameSelected()}
      </Dropdown.Toggle>

      <Dropdown.Menu rootCloseEvent={"click"} className="div-drop-sort">
        {arrayOrder.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              className="drop-sort-item"
              onClick={() => {
                setSelected(item);
                SetHandler(item);
              }}
              //   onSelect={() => setSelected(null)}
            >
              <Icon
                color={
                  selected.name == item.name
                    ? Colors.brand_blue
                    : Colors.darker_gray
                }
                size={22}
                icon={item.icon}
                className="mr-3"
              />
              <div
                style={
                  selected.name == item.name ? { color: Colors.brand_blue } : {}
                }
              >
                {item.name}
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ZoomDecubitMapDropdown;
