import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Colors from "../../../styles/Colors";
import Icon from "../../../functions/Icon";
import PatientsGeneralData from "../PatientsGeneralData";

function ModalPatientsRisks(props) {
  const {
    show_modal,
    handler_show_modal,
    title,
    subTitle,
    removeColumns,
    dateInitial,
    dateFinal,
    situation,
    type,
    woundType,
    ids,
    areaId,
  } = props;

  return (
    <Modal
      className="populational-data-patients-wound-info-modal"
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      centered
    >
      <div className="card-report populational-data-patients-wound-info">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <p
              style={{
                fontSize: "24px",
                fontFamily: "var(--medium)",
                marginBottom: "5px",
              }}
            >
              {title}
            </p>
            <p
              style={{
                fontSize: "16px",
                fontFamily: "var(--medium)",
                color: Colors.darker_gray,
              }}
            >
              {subTitle}
            </p>
          </div>
          <div
            className="modal-close-btn"
            onClick={() => handler_show_modal(false)}
          >
            <Icon color={Colors.darkest_gray} size={15} icon="x" />
          </div>
        </div>
        <div>
          <PatientsGeneralData
            ids={ids}
            areaId={areaId}
            dateInitial={dateInitial}
            dateFinal={dateInitial}
            situation={situation}
            type={type}
            woundType={woundType}
            removeColumns={removeColumns}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ModalPatientsRisks;
