import React, { useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import Colors from "../../../styles/Colors";
import ModalPatientsRisks from "../ModalData/ModalPatientsRisks";
import { resizeChartBasedOnWidth } from "../../../functions/utils";

function TwoBarVertically(props) {
  const { labels, datasets, firstDatasetPatientIds, secondDatasetPatientIds } =
    props;
  const data = {
    labels,
    datasets,
  };

  const chartRef = useRef(null);
  const [patientIds, setPatientIds] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  const options = {
    onClick: function (event) {
      const elements = chartRef.current.chartInstance.getElementAtEvent(event);

      if (elements.length > 0) {
        const firstPoint = elements[0];
        const barIndex = firstPoint._datasetIndex;
        const barGroupIndex = firstPoint._index;
        const patientIdsDatasets = [
          firstDatasetPatientIds,
          secondDatasetPatientIds,
        ];
        if (firstDatasetPatientIds) {
          setPatientIds(patientIdsDatasets[barIndex][barGroupIndex]);
          handleModalPatientRisks(
            `Pacientes de risco ${
              barGroupIndex == 1 ? " com lesão por pressão" : ""
            }`,
            patientIdsDatasets[barIndex][barGroupIndex]
          );
        }
      }
    },
    onHover: function (event, chartElement) {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: Colors.darker_gray,
        fontFamily: "Rubik-Regular",
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 0,
            stepSize: 2,
          },
        },
      ],
    },
  };

  function handleModalPatientRisks(title, patientIds, type) {
    setPatientIds(patientIds);
    setSelectedData({
      label: title,
      patientQuantity: patientIds.length,
    });
    setShowPatientsRisksData(true);
  }

  return (
    <div>
      <Bar
        data={data}
        options={options}
        ref={chartRef}
        height={resizeChartBasedOnWidth()}
      />
      {showPatientsRisksData && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={selectedData?.label}
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="indicators"
        />
      )}
    </div>
  );
}

export default TwoBarVertically;
