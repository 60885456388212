import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import InputMask from "react-input-mask";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";

function EditUnit({ ...props }) {
  const { handler_show_modal, show_modal, handler_reload, reload, unitObj } =
    props;

  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const user = useSelector((state) => state.user);
  const updateUnit = useSelector((state) => state.updateUnit);

  const [loadingBtn, setLoadingBtn] = useState("none");

  const [nameUnit, setNameUnit] = useState(unitObj.name);
  const [rooms, setRooms] = useState(unitObj.rooms);
  const [roomsDelete, setRoomsDelete] = useState([]);

  function DeleteLocal(obj, isRoom) {
    let newList = [];
    let roomsCopy = [...rooms];

    if (isRoom) {
      newList = roomsCopy.filter((item) => item.id != obj.id);
    } else {
      roomsCopy.map((item) => {
        var temp = Object.assign({}, item);

        var arraySub = [];
        temp.subrooms.map((i) => {
          var tempSub = Object.assign({}, i);
          if (i.id != obj.id) arraySub.push(tempSub);
        });

        temp.subrooms = arraySub;
        newList.push(temp);
      });
    }

    setRooms(newList);
    setRoomsDelete([...roomsDelete, obj]);
  }

  function VerifyFields() {
    var ret = false;

    var found = rooms.find((item) => item.name == "");

    var subroomEmpty = false;
    rooms.map((item) => {
      var found = item.subrooms.find((i) => i.name == "");
      if (found != undefined && !subroomEmpty) {
        subroomEmpty = true;
      }
    });

    if (nameUnit != "" && found == undefined && !subroomEmpty) {
      ret = true;
    }

    return ret;
  }

  function RegisterUnit() {
    setLoadingBtn("flex");
    api
      .put(`/units/${unitObj.id}`, {
        name: nameUnit,
        hospital_id: user.dados.hospital_id,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("RegisterUnit success");
          console.log(response.data);
          RegisterRoomAndSubroom(unitObj.id);
        }
      })
      .catch((error) => {
        console.log("RegisterUnit error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível editar a unidade",
          })
        );
      });
  }

  function RegisterRoomAndSubroom(unit_id) {
    api
      .post(`/units/${unit_id}/rooms`, {
        rooms: rooms,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("RegisterRoomAndSubroom success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "A unidade foi atualizada",
            })
          );

          if (roomsDelete.length == 0) {
            dispatch(
              Show_Alert({
                type: "success",
                msg: "A unidade foi atualizada",
              })
            );
            handler_show_modal(false);
            handler_reload(!reload);

            //Update dropdown unit on header
            dispatch({ type: "UPDATE_UNIT_SET", reload: !updateUnit.reload });
          } else {
            Delete();
          }
        }
      })
      .catch((error) => {
        console.log("RegisterRoomAndSubroom error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível cadastrar os quartos/leitos",
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  function Delete() {
    roomsDelete.map((item, index) => {
      api
        .delete(`/rooms/${item.id}`)
        .then((response) => {
          if (response.status == 204) {
            console.log("Delete success");
            console.log(response.data);

            if (index == roomsDelete.length - 1) {
              dispatch(
                Show_Alert({
                  type: "success",
                  msg: "A unidade foi atualizada",
                })
              );
              handler_show_modal(false);
              handler_reload(!reload);

              //Update dropdown unit on header
              dispatch({ type: "UPDATE_UNIT_SET", reload: !updateUnit.reload });
            }
          }
        })
        .catch((error) => {
          console.log("Delete error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível deletar os quartos/leitos",
            })
          );
        });
    });
  }

  return (
    <div>
      <Modal
        show={show_modal}
        onHide={() => handler_show_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <div className="modal-title">Nova unidade</div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            <div className="mt-4">
              <div className="mb-4">
                <p className="modal-text mb-2">Nome da unidade</p>
                <input
                  className="default-input"
                  type="text"
                  placeholder="ex. Unidade 01"
                  value={nameUnit}
                  onChange={(e) => setNameUnit(e.target.value)}
                />
              </div>

              <div>
                {rooms.map((item, index) => {
                  return (
                    <>
                      <div>
                        <p className="modal-text mb-2">Nome do quarto</p>
                      </div>
                      <Row className="mb-1 mt-2">
                        <Col xs={12} md={10}>
                          <input
                            className="default-input"
                            type="text"
                            placeholder="ex. Quarto 01"
                            value={item.name}
                            onChange={(e) => {
                              var newList = rooms.map((i, ind) => {
                                if (ind == index) {
                                  i.name = e.target.value;
                                }
                                return i;
                              });
                              setRooms(newList);
                            }}
                          />
                        </Col>

                        <Col xs={12} md={2}>
                          <button
                            title="Remover quarto"
                            className="btn-remove w-100 m-0"
                            disabled={index == 0 ? true : false}
                            onClick={() => {
                              if (item.id != undefined) {
                                DeleteLocal(item, true);
                                return false;
                              }

                              var newList = rooms.filter((c, d) => {
                                if (index != d) {
                                  return c;
                                }
                              });
                              setRooms(newList);
                            }}
                          >
                            <Icon
                              color={
                                index == 0
                                  ? Colors.dark_gray
                                  : Colors.darkest_gray
                              }
                              size={15}
                              icon="minus"
                            />
                          </button>
                        </Col>
                      </Row>

                      <div>
                        {item.subrooms.length > 0 && (
                          <div className="mt-3">
                            <p className="modal-text mb-2">Nome do Leito</p>
                          </div>
                        )}
                        {item.subrooms.map((i, ind) => {
                          return (
                            <Row className="mb-1">
                              <Col xs={12} md={10}>
                                <input
                                  className="default-input"
                                  type="text"
                                  placeholder="ex. Leito 01"
                                  value={i.name}
                                  onChange={(e) => {
                                    var newList = rooms.map((room, indroom) => {
                                      if (indroom == index) {
                                        room.subrooms.map(
                                          (subroom, indsubroom) => {
                                            if (indsubroom == ind) {
                                              i.name = e.target.value;
                                            }
                                          }
                                        );
                                      }
                                      return room;
                                    });
                                    setRooms(newList);
                                  }}
                                />
                              </Col>

                              <Col xs={12} md={2}>
                                <button
                                  title="Remover leito"
                                  className="btn-remove w-100 m-0"
                                  onClick={() => {
                                    if (i.id != undefined) {
                                      DeleteLocal(i, false);
                                      return false;
                                    }

                                    var array = [];
                                    rooms.map((room, indroom) => {
                                      if (indroom == index) {
                                        console.log("entrou");
                                        room.subrooms.filter(
                                          (subroom, indsubroom) => {
                                            if (indsubroom != ind)
                                              array.push(subroom);
                                          }
                                        );
                                      }

                                      return room;
                                    });

                                    var newList = rooms.map((room, indroom) => {
                                      if (indroom == index) {
                                        room.subrooms = [];
                                        array.map((a) => {
                                          room.subrooms.push(a);
                                        });
                                      }
                                      return room;
                                    });

                                    setRooms(newList);
                                  }}
                                >
                                  <Icon
                                    color={Colors.darkest_gray}
                                    size={15}
                                    icon="minus"
                                  />
                                </button>
                              </Col>
                            </Row>
                          );
                        })}

                        <button
                          className="d-flex align-items-center mt-2 mb-4 reset-button"
                          onClick={() => {
                            let roomsCopy = [...rooms];
                            var newList = [];

                            roomsCopy.map((item, indexRoom) => {
                              var temp = Object.assign({}, item);

                              var arraySub = [];
                              temp.subrooms.map((i) => {
                                var tempSub = Object.assign({}, i);
                                arraySub.push(tempSub);
                              });

                              if (indexRoom == index)
                                arraySub.push({ name: "" });
                              temp.subrooms = arraySub;
                              newList.push(temp);
                            });

                            setRooms(newList);
                          }}
                        >
                          <Icon
                            color={Colors.brand_pink}
                            size={15}
                            icon="plus"
                            className="mr-2"
                          />
                          <p className="mb-0 add-other-text">
                            Adicionar outro Leito
                          </p>
                        </button>
                      </div>
                    </>
                  );
                })}

                <button
                  className="d-flex align-items-center mt-3 reset-button"
                  onClick={() => {
                    setRooms([...rooms, { name: "", subrooms: [] }]);
                  }}
                >
                  <Icon
                    color={Colors.brand_pink}
                    size={15}
                    icon="plus"
                    className="mr-2"
                  />
                  <p className="mb-0 add-other-text">Adicionar outro quarto</p>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              className="secondary-btn modal-footer-btn mr-0 btn-cancel"
              onClick={() => handler_show_modal(false)}
            >
              Cancelar
            </button>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => RegisterUnit()}
            >
              <p
                className="mb-0"
                style={{
                  color:
                    VerifyFields() == true ? Colors.white : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Confirmar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditUnit;
