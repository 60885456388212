import React, { useState } from "react"
import {
  Col,
  Row,
  Dropdown
} from "react-bootstrap"

import Icon from "@Functions/Icon"

const Pagination = ({ data, onChange }) => {
    const [page] = useState(data.page || 1)
    const [pageSize] = useState(data.perPage || 12)
    const [totals] = useState(data.total || 0)
    const [lastPage] = useState(data.lastPage || 0)
    const pageSizeOptions = [12, 24, 36]

    const renderDisplay = () => {
      var showed = 0;

      if (lastPage > 1) {
        showed = pageSize * page
        if (page === lastPage) showed = totals
      } else {
        showed = totals
      }

      return `${showed} de ${totals} resultados`
    }

    const onChangePage = ({ pageNumber, perPage }) => {
      onChange({
        page: pageNumber,
        pageSize: perPage
      })
    }

    const onPaginationForward = () => {
      onChangePage({ pageNumber: page + 1, perPage: pageSize })
    }

    const onPaginationPrevious = () => {
      onChangePage({ pageNumber: page - 1, perPage: pageSize })
    }

    const onPageSizeChange = (size) => {
      if (size === pageSize) return 

      onChangePage({ pageNumber: page, perPage: size })
    }

    return (
      <div className="c-pagination my-4">
        <Row
          className="m-0 w-100 justify-content-between align-items-center"
        >
          <Col 
            className="c-pagination__display text-left"
            xs={4} 
            md={4} 
            lg={3}
          >
            <p className="m-0 u-color-darkGray u-fs-12">
              Exibindo <span className="d-block u-fs-14 u-color-darkestGray">{ renderDisplay() }</span>
            </p>
          </Col>

          <Col 
            xs={4} 
            md={4} 
            lg={3} 
            className="c-pagination__links text-center d-flex justify-content-center align-items-center"
          >
            <button 
              className="btn c-pagination__link is-previous"
              disabled={ page === 1 }
              onClick={ onPaginationPrevious }
            >
              <Icon
                size={22}
                icon="arrow-left"
                className="c-pagination__icon o-icon"
              />
            </button>
            <div className="c-pagination__pages d-flex justify-content-center align-items-center mx-2">
              <div className="c-pagination__page is-current u-radius-8 d-flex justify-content-center align-items-center">
                { page }
              </div> 
            </div>
            <button 
              className="btn c-pagination__link is-forward"
              disabled={ page === lastPage }
              onClick={ onPaginationForward }
            >
              <Icon
                size={22}
                icon="arrow-left"
                className="c-pagination__icon o-icon"
              />
            </button>
          </Col>

          <Col xs={4} md={4} lg={3}>
            <Dropdown className="text-right">
              <Dropdown.Toggle
                variant="secondary"
                className="dropdown-nav-btn"
                id="dropdown-basic"
              >
                { pageSize } resultados &nbsp;&nbsp;
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  pageSizeOptions.map((size, index) => {
                    return (
                      <Dropdown.Item key={index}  onClick={() => onPageSizeChange(size) }>
                        { size } resultados
                      </Dropdown.Item>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
    )
}

export default Pagination