import React, { useEffect, useState } from "react";
import "../../styles.css";

const SwitchSelector = (props) => {
  const { selected_decubit, handler_select_decubit } = props;
  const [isOn, setIsOn] = useState(true);

  const handleClick = () => {
    setIsOn(!isOn);
    handler_select_decubit(isOn ? 1 : 0);
  };

  useEffect(() => {
    setIsOn(selected_decubit === 0 ? true : false);
  }, [selected_decubit]);

  return (
    <div className="switch-selector" onClick={handleClick}>
      <div className={`slider-selector ${isOn ? "on" : "off"}`}></div>
      <span className={`slider-key ${isOn ? "active" : "default"}`}>
        Tempo regressivo
      </span>
      <span className={`slider-key ${!isOn ? "active" : "default"}`}>
        Tempo progressivo
      </span>
    </div>
  );
};

export default SwitchSelector;
