const Colors = {
  brand_blue: "#468dff",
  brand_pink: "#fe8bc4",
  dark_pink: "#E67EB0",
  darker_pink: "#D977A7",
  light_pink: "#FFDEEE",
  lighter_pink: "#FFF3F9",
  lightest_pink: "#FFF3F9",

  darkest_gray: "#333333",
  darker_gray: "#666666",
  dark_gray: "#999999",
  brand_gray: "#c2c2c2",
  light_gray: "#dddddd",
  lighter_gray: "#eeeeee",
  lightest_gray: "#f5f6f9",

  light_blue: "#1fb6ff",
  lighter_blue: "#E3EBFA",
  brand_green: "#13ce66",
  brand_red: "#ff4949",
  brand_yellow: "#ffcb2c",

  darker_red: "#540303",
  dark_red: "#b21515",
  light_red: "#ff6d6d",
  brand_orange: "#ffb760",
  light_yellow: "#FFE088",
  brand_teal: "#1be099",
  brand_purple: "#7b69df",
  dark_blue: "#234780",

  new_orange: "#ff7b49",
  dark_orange: "#E85F2C",

  colorBlind_red: "#FF4948",
  colorBlind_darkRed: "#E82D2C",

  colorBlind_green: "#13CE66",
  colorBlind_darkGreen: "#208D51",

  colorBlind_blue: "#468DFF",
  colorBlind_darkBlue: "#2C74E8",

  colorBlind_purple: "#7B69DF",
  colorBlind_darkPurple: "#6556B8",

  transparent: "transparent",
};

export default Colors;
