import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import moment from "moment";
import ButtonFilter from "./Buttons/ButtonFilter";
import { GetColorAvaliation } from "../../functions/utils";
import RiskPatientsPie from "./RiskPatientsPie";
import IncidenceArea from "./ModalInfo/IncidenceArea";
import api from "../../services/api";
import { useSelector } from "react-redux";
import IshikawaDiagram from "./IshikawaDiagram";
import WoundStageClassificationPie from "./WoundStageClassificationPie";
import Gender from "./Gender";
import AgeGroup from "./AgeGroup";
import BodyMassIndex from "./BodyMassIndex";
import Comorbidities from "./Comorbidities";
import PatientsGeneralData from "./PatientsGeneralData";
import "./styles.css";
import DateRangerPicker from "../../components/DateRangerPicker";

function PopulationalData() {
  const unit = useSelector((state) => state.unit);

  const latestRequestRef = useRef(0);

  const [type, setType] = useState("");
  const [loadingWounds, setLoadingWounds] = useState(false);
  const [loadingDiagrams, setLoadingDiagrams] = useState(false);
  const [loadingRiskPatients, setLoadingRiskPatients] = useState(false);
  const [dateInterval, setDateInterval] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });

  const [wounds, setWounds] = useState(null);
  const [woundStages, setWoundStages] = useState(null);
  const [woundChooser, setWoundChooser] = useState({
    label: "Todos",
    value: 0,
  });
  const [patientRisks, setPatientRisks] = useState(null);
  const [ishikawaDiagrams, setIshikawaDiagrams] = useState(null);
  const [genders, setGenders] = useState(null);
  const [ageGroups, setAgeGroups] = useState(null);
  const [bodyMassIndexes, setBodyMassIndexes] = useState(null);
  const [comorbidities, setComorbidities] = useState(null);
  const [allComorbidities, setAllComorbidities] = useState(null);

  const [filterNoneWound, setFilterNoneWound] = useState(false);
  const [filterIncidenceWound, setFilterIncidenceWound] = useState(false);
  const [filterAdmissionWound, setFilterAdmissionWound] = useState(false);

  const [filterActiveSituation, setFilterActiveSituation] = useState(false);
  const [filterDischargedSituation, setFilterDischargedSituation] =
    useState(false);
  const [situation, setSituation] = useState("");
  const [isPrinting, setIsPrinting] = useState(false);

  function handleCallback(start, end) {
    setLoadingWounds(true);
    setLoadingDiagrams(true);
    setLoadingRiskPatients(true);
    setDateInterval({
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
    });
  }

  function LoadWounds() {
    const requestId = ++latestRequestRef.current;

    setLoadingWounds(true);

    let queryParams = {
      unit_id: unit.selecionado.id,
      date_start: dateInterval.start,
      date_end: dateInterval.end,
      type,
      situation,
    };

    if (woundChooser.value > 0) {
      queryParams.wound_id = woundChooser.value;
    }

    api
      .get(`/dashboard/wounds`, {
        params: queryParams,
      })
      .then((response) => {
        if (response.status == 200 && requestId === latestRequestRef.current) {
          setWounds(response.data.wounds);
          setGenders(response.data.gender);
          setAgeGroups(response.data.age);
          setBodyMassIndexes(response.data.IMC);
          setWoundStages(response.data.stages);
          setComorbidities(response.data.commorbidities);
          setAllComorbidities(response.data.all_commorbidities);
        }
      })
      .finally(() => {
        setLoadingWounds(false);
      });
  }

  function LoadRiskPatients() {
    setLoadingRiskPatients(true);
    api
      .get(`/dashboard/patients_risks`, {
        params: {
          unit_id: unit.selecionado.id,
          date_start: dateInterval.start,
          date_end: dateInterval.end,
          type,
          situation,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setPatientRisks(response.data);
        }
      })
      .finally(() => {
        setLoadingRiskPatients(false);
      });
  }

  function LoadIshikawaDiagrams() {
    setLoadingDiagrams(true);
    api
      .get(`/dashboard/diagram`, {
        params: {
          unit_id: unit.selecionado.id,
          date_start: dateInterval.start,
          date_end: dateInterval.end,
          type,
          situation,
          completed: true,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setIshikawaDiagrams(response.data);
        }
      })
      .finally(() => {
        setLoadingDiagrams(false);
      });
  }

  useEffect(() => {
    if (unit.selecionado?.id != undefined) {
      LoadWounds();
      LoadRiskPatients();
      LoadIshikawaDiagrams();
    }
  }, [unit, dateInterval, type, situation]);

  useEffect(() => {
    if (unit.selecionado?.id != undefined) {
      LoadWounds();
    }
  }, [woundChooser]);

  useEffect(() => {
    let type = "";

    if (filterIncidenceWound && filterAdmissionWound && filterNoneWound) {
      type = "";
    } else if (filterIncidenceWound && filterAdmissionWound) {
      type = "incidence,admission";
    } else if (filterIncidenceWound && filterNoneWound) {
      type = "incidence,none";
    } else if (filterAdmissionWound && filterNoneWound) {
      type = "admission,none";
    } else if (filterIncidenceWound) {
      type = "incidence";
    } else if (filterAdmissionWound) {
      type = "admission";
    } else if (filterNoneWound) {
      type = "none";
    }

    setType(type);
  }, [filterIncidenceWound, filterAdmissionWound, filterNoneWound]);

  useEffect(() => {
    let situation = "";

    if (filterActiveSituation && filterDischargedSituation) {
      situation = "";
    } else if (filterActiveSituation) {
      situation = "active";
    } else if (filterDischargedSituation) {
      situation = "discharged";
    }

    setSituation(situation);
  }, [filterActiveSituation, filterDischargedSituation]);

  return (
    <div className="populational-data my-4">
      <div className="populational-data-buttons-filter d-flex">
        <div>
          <Form.Label className="field-label">Período</Form.Label>
          <Row
            className="justify-content-start align-items-center mb-3 mx-0"
            style={{
              minWidth: "248px",
            }}
          >
            <DateRangerPicker
              dateInterval={dateInterval}
              dateIntervalHandler={setDateInterval}
              maxDate={moment().endOf("year")} // Configura a data máxima para o final do ano atual
            />
          </Row>
        </div>
        <div className="mr-3 ml-4 populational-data-buttons-filter-wounds">
          <p className="field-label">Filtrar por pacientes:</p>
          <ButtonFilter
            checked={filterIncidenceWound}
            text="Com lesão incidida"
            onClick={() => {
              setFilterIncidenceWound(!filterIncidenceWound);
            }}
          />
          <ButtonFilter
            checked={filterAdmissionWound}
            text="Com lesão admitida"
            onClick={() => {
              setFilterAdmissionWound(!filterAdmissionWound);
            }}
          />
          <ButtonFilter
            checked={filterNoneWound}
            text="Sem lesão"
            onClick={() => {
              setFilterNoneWound(!filterNoneWound);
            }}
          />
        </div>
        <div>
          <p className="field-label">Filtrar por situação:</p>
          <ButtonFilter
            checked={filterActiveSituation}
            text="Internados"
            onClick={() => {
              setFilterActiveSituation(!filterActiveSituation);
            }}
          />
          <ButtonFilter
            checked={filterDischargedSituation}
            text="Alta"
            onClick={() => {
              setFilterDischargedSituation(!filterDischargedSituation);
            }}
          />
        </div>
      </div>
      <div className="my-3 card-report populational-data-patients-wound-info">
        <p>Pacientes</p>
        <PatientsGeneralData
          dateInitial={dateInterval.start}
          dateFinal={dateInterval.end}
          situation={situation}
          type={"general"}
          isMain={true}
          woundType={type}
          removeColumns={["Estágio", "Data de confirmação"]}
        />
      </div>
      <Row>
        <Col xs={12} md={6} className="mt-4 ">
          <div className="card-report h-100 ">
            {loadingRiskPatients ? (
              <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100 ">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <RiskPatientsPie patientRisks={patientRisks} />
            )}
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-4">
          <div className="card-report h-100">
            {loadingWounds ? (
              <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <IncidenceArea wounds={wounds} />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mt-4">
          <div className="card-report h-100">
            {loadingDiagrams ? (
              <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <IshikawaDiagram ishikawaDiagrams={ishikawaDiagrams} />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mt-4">
          <div className="card-report h-100">
            {loadingWounds ? (
              <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <WoundStageClassificationPie
                wounds={wounds}
                woundStageClassifications={woundStages}
                woundChooser={woundChooser}
                handlerWoundChooser={setWoundChooser}
              />
            )}
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-4">
          <div className="card-report h-100">
            {loadingWounds ? (
              <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Gender genders={genders} />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mt-4">
          <div className="card-report h-100">
            {loadingWounds ? (
              <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <AgeGroup ageGroups={ageGroups} />
            )}
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-4">
          <div className="card-report h-100">
            {loadingWounds ? (
              <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <BodyMassIndex bodyMassIndexes={bodyMassIndexes} />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Comorbidities
          comorbidities={comorbidities}
          allComorbidities={allComorbidities}
          loading={loadingWounds}
        />
      </Row>
    </div>
  );
}

export default PopulationalData;
