const INITIAL_STATE = {
  selecionado: [],
};

function unit(state = INITIAL_STATE, action) {
  if (action.type === "UNIT_SET") {
    return { ...state, selecionado: action.selecionado };
  } else if (action.type === "UNIT_CLEAR") {
    state = INITIAL_STATE;
  }

  return state;
}

export default unit;
