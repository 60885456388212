import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

var arrayOrder = [
  {
    name: "Últimos 7 plantões",
    value: 7,
  },
  {
    name: "Últimos 15 plantões",
    value: 15,
  },
];

function LastShiftsSuspensionsDropdown(props) {
  const { sort } = props;
  // Exibir dropdown
  const [show, setShow] = useState(false);

  // Ordenação escolhida
  const [selected, setSelected] = useState({
    name: "Últimos 7 plantões",
    value: 7,
  });

  useEffect(() => {
    if (sort.name != undefined) {
      setSelected(sort);
    }
  }, [sort]);

  function SetarHandler(obj) {
    const handler = props.handler;
    handler(obj);
  }

  function GetNameSelected() {
    var name = selected.name;
    return name;
  }

  return (
    <Dropdown className="drop-sort" onToggle={() => setShow(!show)}>
      <Dropdown.Toggle
        id="dropdown-sort"
        className={`btn-filter last-shifts-dropdown`}
        style={props.cssStyles}
      >
        {GetNameSelected()}
      </Dropdown.Toggle>

      <Dropdown.Menu rootCloseEvent={"click"} className="div-drop-sort">
        {arrayOrder.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              className="drop-sort-item"
              onClick={() => {
                setSelected(item);
                SetarHandler(item);
              }}
              //   onSelect={() => setSelected(null)}
            >
              <div
                style={
                  selected.name == item.name ? { color: Colors.brand_blue } : {}
                }
              >
                {index > 0
                  ? item.name
                  : props.status == false
                  ? "Últimos 15 plantões"
                  : "Últimos 7 plantões"}
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LastShiftsSuspensionsDropdown;
