import React, { useEffect, useState } from "react";
import { renderDisplay, setDisplay } from "../functions/utils";
import { Col, Dropdown, Row } from "react-bootstrap";
import Colors from "../styles/Colors";
import Icon from "../functions/Icon";

function NewPagination(props) {
  const {
    currentPage,
    handlerCurrentPage,
    lastPage,
    handlerLastPage,
    qtdDisplay,
    handlerQtdDisplay,
    totalResults,
    handlerTotalResults,
    pagination,
    handlerPagination,
  } = props;

  const [hoverBack, setHoverBack] = useState(false);
  const [hoverNext, setHoverNext] = useState(false);

  return (
    <Row
      className="px-2 w-100"
      style={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <Col xs={4} md={4} lg={3} style={{ textAlign: "left" }}>
        <div className="sm-txt" style={{ color: Colors.dark_gray }}>
          Exibindo
        </div>
        <div className="sm-txt">
          {renderDisplay(lastPage, qtdDisplay, currentPage, totalResults)} de{" "}
          {totalResults} resultados
        </div>
      </Col>

      <Col xs={4} md={4} lg={3} style={{ textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {currentPage > 1 && (
            <div
              className="btn-nav-arrows"
              onMouseEnter={() => setHoverBack(true)}
              onMouseLeave={() => setHoverBack(false)}
              onClick={() => handlerCurrentPage(currentPage - 1)}
            >
              <Icon
                color={hoverBack ? Colors.brand_blue : Colors.darker_gray}
                size={22}
                icon="arrow-left"
              />
            </div>
          )}

          {pagination.map((item) => {
            return (
              <div
                key={item}
                className={
                  item == currentPage
                    ? "btn-nav-numbers active"
                    : "btn-nav-numbers"
                }
                onClick={() => {
                  handlerCurrentPage(item);
                }}
              >
                {item}
              </div>
            );
          })}

          {currentPage < lastPage && (
            <div
              className="btn-nav-arrows"
              style={{ transform: `rotateY(180deg)` }}
              onMouseEnter={() => setHoverNext(true)}
              onMouseLeave={() => setHoverNext(false)}
              onClick={() => handlerCurrentPage(currentPage + 1)}
            >
              <Icon
                color={hoverNext ? Colors.brand_blue : Colors.darker_gray}
                size={22}
                icon="arrow-left"
              />
            </div>
          )}
        </div>
      </Col>

      <Col xs={4} md={4} lg={3}>
        <Dropdown style={{ textAlign: "right" }}>
          <Dropdown.Toggle
            variant="secondary"
            className="dropdown-nav-btn"
            id="dropdown-basic"
          >
            {qtdDisplay} resultados &nbsp;&nbsp;
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                setDisplay(12, handlerCurrentPage, handlerQtdDisplay)
              }
            >
              12 resultados
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                setDisplay(24, handlerCurrentPage, handlerQtdDisplay)
              }
            >
              24 resultados
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                setDisplay(36, handlerCurrentPage, handlerQtdDisplay)
              }
            >
              36 resultados
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default NewPagination;
