import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";

function ConfirmacaoLesao() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [defaultWoundState, setDefaultWoundState] = useState(false);

  useEffect(() => {
    function loadHospitalWoundState() {
      api
        .get("default-settings/hospitals/wound-state")
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadHospital sucess");
            setDefaultWoundState(response.data.is_enable);
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setDefaultWoundState(false);
          } else {
            console.log("LoadHospital error " + error);
            dispatch(
              Show_Alert({
                type: "error",
                msg: "Não foi possível carregar as informações do hospital",
              })
            );
          }
        })
        .finally(() => setLoading(false));
    }
    loadHospitalWoundState();
  }, []);

  function SaveWoundState() {
    setLoading(true);
    setDefaultWoundState(!defaultWoundState);
    api
      .post("default-settings/hospitals/wound-state", {
        is_enable: !defaultWoundState,
      })
      .then((response) => {
        dispatch({
          type: "SET_DEFAULT_WOUND_STATE",
          selected: response.data.is_enable,
        });
        dispatch(
          Show_Alert({
            type: "success",
            msg: "Confirmação automática do hospital editada com sucesso",
          })
        );
      })
      .catch((error) => {
        console.log("LoadHospital error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível alterar a confirmação automática do hospital",
          })
        );
      });
    setLoading(false);
  }

  return (
    <div>
      <h2 className="screen-name mb-4">Confirmação de lesão</h2>
      <div className="panel-state-wound">
        <div className="info-wound">
          <p>Exigir confirmação de lesão após notificação.</p>
          <p>
            Todas as notificações de lesões precisarão ser confirmadas para
            serem validadas no sistema.
          </p>
        </div>
        <div>
          <Form.Check
            type="switch"
            label=" "
            disabled={loading}
            readOnly={true}
            checked={defaultWoundState}
            onClick={SaveWoundState}
            id={`sensor-status`}
            className={`d-inline-block z-index-10 o-toggle o-toggle-success fixed ${
              true ? "is-checked" : ""
            }`}
          />
        </div>
      </div>
    </div>
  );
}

export default ConfirmacaoLesao;
