import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";
import "./styles.css";

function ModalDeleteUnit({ ...props }) {
  const {
    handler_show_modal,
    show_modal,
    handler_reload,
    reload,
    unitObj,
  } = props;
  const dispatch = useDispatch();
  const updateUnit = useSelector((state) => state.updateUnit);

  const [loadingBtn, setLoadingBtn] = useState("none");

  function Delete() {
    setLoadingBtn("flex");
    api
      .delete(`/units/${unitObj.id}`)
      .then((response) => {
        if (response.status == 204) {
          console.log("Delete success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "A unidade foi excluída",
            })
          );
          handler_reload(!reload);

          //Update dropdown unit on header
          dispatch({ type: "UPDATE_UNIT_SET", reload: !updateUnit.reload });
        }
      })
      .catch((error) => {
        console.log("Delete error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível deletar essa unidade",
          })
        );
      })
      .finally(() => {
        handler_show_modal(false);
        setLoadingBtn("none");
      });
  }

  return (
    <div>
      <Modal
        show={show_modal}
        onHide={() => handler_show_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter"
      >
        <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={8}>
              <div className="modal-title">Excluir unidade</div>
            </Col>
            <Col
              xs={2}
              md={2}
              lg={2}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <div className="mt-4">
            <p className="text-delete-user">
              A unidade <span>{unitObj.name}</span> será excluída. Tem certeza
              que deseja excluí-la?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-3">
            <div
              className="secondary-btn modal-footer-btn"
              onClick={() => handler_show_modal(false)}
            >
              Cancelar
            </div>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <div
              className="default-btn-user mb-0 modal-footer-btn"
              onClick={() => Delete()}
            >
              <p
                className="mb-0"
                style={{
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Excluir
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </div>
          </Col>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalDeleteUnit;
