import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import PwStrengthMeter from "../../../components/PwStrengthMeter";

import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import "../../../styles/global.css";
import "./styles.css";

function AlterarSenha() {
  const [senha, setSenha] = useState("");
  const [viewPwd, setViewPwd] = useState(false);
  const [levelPwd, setLevelPwd] = useState(0);
  const [sucesso, setSucesso] = useState(false);

  // Enviar para API
  function send() {
    if (levelPwd < 3) {
      setSucesso(false);
      return false;
    } else {
      setSucesso(true);
    }
  }

  function successScreen() {
    return (
      <div className="box-sucesso">
        <Icon color={Colors.brand_green} size={70} icon="check-circle" />

        <h5 className="text-left title mt-3">
          Sua senha foi alterada com sucesso!
        </h5>

        <div
          className="default-btn text-center mt-4 mb-0"
          onClick={() => (window.location = "/")}
        >
          Voltar para Login
        </div>
      </div>
    );
  }

  return (
    <Container fluid className="container-alterar">
      {!sucesso && (
        <div className="box-alterar">
          <h5 className="text-left title mt-3">Definir nova senha</h5>
          <h6 className="text-left sm-title">
            Defina abaixo uma nova senha para sua conta.
          </h6>

          <div className="field-label">Nova senha</div>

          <div className="default-input">
            <input
              type={viewPwd ? "text" : "password"}
              name="Senha"
              placeholder="Digite sua senha"
              onChange={(v) => setSenha(v.target.value)}
              className="borderless-input"
            />
            <div onClick={() => setViewPwd(!viewPwd)}>
              <Icon
                color={Colors.darker_gray}
                size={24}
                icon={viewPwd ? "eye-off" : "eye-on"}
              />
            </div>
          </div>

          <PwStrengthMeter value={senha} handler={setLevelPwd} />

          <div
            className="default-btn text-center mt-4 mb-0"
            onClick={() => console.log("oi")}
          >
            Definir nova senha
          </div>
        </div>
      )}

      {sucesso && successScreen()}
    </Container>
  );
}

export default AlterarSenha;
