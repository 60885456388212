import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Table,
  Dropdown,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import api from "../../../../services/api";
import { Show_Alert } from "../../../../store/actions/alert";
import Colors from "../../../../styles/Colors";
import Icon from "../../../../functions/Icon";
import "./styles.css";
import AddUnit from "./AddUnit";
import EditUnit from "./EditUnit";
import ModalDeleteUnit from "./ModalDeleteUnit";

var unitObj = null;

function Unidades() {
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [word, setWord] = useState("");
  const [listUnits, setListUnits] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [reload, setReload] = useState(false);

  const [showAddUnit, setShowAddUnit] = useState(false);
  const [showEditUnit, setShowEditUnit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  // Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [qtdDisplay, setQtdDisplay] = useState(12);
  const [totalResults, setTotalResults] = useState(null);
  const [pagination, setPagination] = useState([]);

  const [hoverBack, setHoverBack] = useState(false);
  const [hoverNext, setHoverNext] = useState(false);

  function RenderTooltip(text) {
    return <Tooltip id="tooltip-top">{text}</Tooltip>;
  }

  useEffect(() => {
    function LoadUnits() {
      api
        .get(
          `/hospitals/${user.dados.hospital_id}/units?page=${currentPage}&pageSize=${qtdDisplay}`,
          {
            params: {
              pagination: true,
              name: word,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUnits success");
            console.log(response.data);

            setListUnits(response.data.data);
            setLastPage(response.data.lastPage);
            setTotalResults(response.data.total);
            BuildPagination(response.data.page, response.data.lastPage);
          }
        })
        .catch((error) => {
          console.log("LoadUnits error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de unidades",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadUnits();
  }, [reload, word, currentPage, qtdDisplay]);

  function BuildPagination(currentPage, lastPage) {
    var pagination = [];
    for (var i = currentPage; i < currentPage + 5; i++) {
      if (i == currentPage && currentPage > 2) {
        pagination.push(currentPage - 2);
      }
      if (i == currentPage && currentPage > 1) {
        pagination.push(currentPage - 1);
      }

      if (i <= lastPage) {
        pagination.push(i);
      }
    }
    setPagination(pagination);
  }

  function SetarDisplay(qtd) {
    setCurrentPage(1);
    setQtdDisplay(qtd);
  }

  function renderDisplay() {
    var n = 0;

    if (lastPage > 1) {
      n = qtdDisplay * currentPage;
      if (currentPage == lastPage) n = totalResults;
    } else {
      n = totalResults;
    }

    return n;
  }

  function CounterSubRooms(rooms) {
    var qtd = 0;
    rooms.map((item) => {
      qtd += item.subrooms.length;
    });
    return qtd;
  }

  return (
    <div>
      {showAddUnit && (
        <AddUnit
          handler_show_modal={setShowAddUnit}
          show_modal={showAddUnit}
          handler_reload={setReload}
          reload={reload}
        />
      )}
      {showEditUnit && (
        <EditUnit
          handler_show_modal={setShowEditUnit}
          show_modal={showEditUnit}
          handler_reload={setReload}
          reload={reload}
          unitObj={unitObj}
        />
      )}
      {showDelete && (
        <ModalDeleteUnit
          handler_show_modal={setShowDelete}
          show_modal={showDelete}
          handler_reload={setReload}
          reload={reload}
          unitObj={unitObj}
        />
      )}
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h2 className="screen-name mb-4">Unidades</h2>
        <button
          onClick={() => setShowAddUnit(true)}
          style={{ width: "fit-content" }}
          className="default-btn"
        >
          Nova unidade
        </button>
      </div>

      <Row className="align-items-end">
        <Col xs={12} md={8} lg={6} xl={10}>
          <div>
            <Form.Label className="field-label">Buscar unidade</Form.Label>
            <input
              className="default-input"
              type="text"
              placeholder="Digite o nome da unidade"
              onChange={(e) => {
                setCurrentPage(1);
                setWord(e.target.value);
              }}
              value={word}
            />
          </div>
        </Col>
      </Row>

      {!loading && listUnits.length == 0 && (
        <div className="container-error-default my-3">
          <p>Nenhum resultado foi encontrado.</p>
        </div>
      )}

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && listUnits.length > 0 && (
        <div className="mt-3">
          <Table className="user-table" responsive>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Quartos</th>
                <th>Leitos</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listUnits.map((item, index) => {
                return (
                  <tr key={index} onClick={() => {}}>
                    <td>{item.name}</td>
                    <td>{item.rooms.length}</td>
                    <td>{CounterSubRooms(item.rooms)}</td>
                    <td>
                      <div>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 10 }}
                          overlay={RenderTooltip("Editar")}
                        >
                          <button
                            className="btn-table-user mr-2"
                            onClick={() => {
                              unitObj = { ...item };
                              setShowEditUnit(true);
                            }}
                          >
                            <Icon
                              color={Colors.darker_gray}
                              size={22}
                              icon="edit"
                            />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 10 }}
                          overlay={RenderTooltip("Excluir")}
                        >
                          <button
                            className="btn-table-user"
                            onClick={() => {
                              unitObj = item;
                              setShowDelete(true);
                            }}
                          >
                            <Icon
                              color={Colors.darker_gray}
                              size={22}
                              icon="trash"
                            />
                          </button>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <Row
        className="px-2 w-100"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Col xs={4} md={4} lg={3} style={{ textAlign: "left" }}>
          <div className="sm-txt" style={{ color: Colors.dark_gray }}>
            Exibindo
          </div>
          <div className="sm-txt">
            {renderDisplay()} de {totalResults} resultados
          </div>
        </Col>

        <Col xs={4} md={4} lg={6} style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {currentPage > 1 && (
              <div
                className="btn-nav-arrows"
                onMouseEnter={() => setHoverBack(true)}
                onMouseLeave={() => setHoverBack(false)}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                <Icon
                  color={hoverBack ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}

            {pagination.map((item) => {
              return (
                <div
                  key={item}
                  className={
                    item == currentPage
                      ? "btn-nav-numbers active"
                      : "btn-nav-numbers"
                  }
                  onClick={() => {
                    setCurrentPage(item);
                  }}
                >
                  {item}
                </div>
              );
            })}

            {currentPage < lastPage && (
              <div
                className="btn-nav-arrows"
                style={{ transform: `rotateY(180deg)` }}
                onMouseEnter={() => setHoverNext(true)}
                onMouseLeave={() => setHoverNext(false)}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                <Icon
                  color={hoverNext ? Colors.brand_blue : Colors.darker_gray}
                  size={22}
                  icon="arrow-left"
                />
              </div>
            )}
          </div>
        </Col>

        <Col xs={4} md={4} lg={3}>
          <Dropdown style={{ textAlign: "right" }}>
            <Dropdown.Toggle
              variant="secondary"
              className="dropdown-nav-btn"
              id="dropdown-basic"
            >
              {qtdDisplay} resultados &nbsp;&nbsp;
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => SetarDisplay(12)}>
                12 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(24)}>
                24 resultados
              </Dropdown.Item>
              <Dropdown.Item onClick={() => SetarDisplay(36)}>
                36 resultados
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
}

export default Unidades;
