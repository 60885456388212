import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

function ModalAlta(props) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-alta"
      >
        <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={6} md={2} lg={2}>
              <div className="modal-title">Dar alta</div>
            </Col>
            <Col
              xs={2}
              md={2}
              lg={2}
              className="pr-0 d-flex justify-content-end"
            >
              <div className="modal-close-btn" onClick={() => setShow(false)}>
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <div className="modal-text mt-3 mb-2">Unidade</div>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0">
          <Col xs={4} md={3} lg={3} className="px-0 mr-3">
            <div className="secondary-btn modal-footer-btn">Limpar</div>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <div className="default-btn mb-0 modal-footer-btn">Aplicar</div>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalAlta;
