import React, { useEffect, useState } from "react";
import Colors from "../../../../../../../styles/Colors";
import { Bar, Line } from "react-chartjs-2";
import moment from "moment";
import { Chart } from "chart.js";
import { resizeChartBasedOnWidth } from "../../../../../../../functions/utils";

function AdhesionsByShiftBar(props) {
  const { adherenceData } = props;

  const [data, setData] = useState(null);
  const [options, setOptions] = useState(null);

  const [chartWidth, setChartWidth] = useState("auto");
  const [chartBiggestWidth, setChartBiggestWidth] = useState(0);

  useEffect(() => {
    if (
      chartBiggestWidth > 0 &&
      data?.datasets &&
      data?.datasets[0].data.length > 7
    ) {
      setChartWidth(Math.max(chartBiggestWidth * 200, window.innerWidth));
    }
  }, [chartBiggestWidth, data]);

  useEffect(() => {
    if (adherenceData) {
      const shiftsAdhesionsByDate = {};
      let index = 0;
      for (const key of Object.keys(adherenceData)) {
        const subObjeto = adherenceData[key];
        if (chartBiggestWidth < subObjeto.length) {
          setChartBiggestWidth(subObjeto.length);
        }
        for (const adherenceDate of subObjeto) {
          let date = Object.keys(adherenceDate)[0];
          let dateAdhesion = adherenceDate[date].metaData;
          if (dateAdhesion) {
            if (!shiftsAdhesionsByDate[date]) {
              shiftsAdhesionsByDate[date] = [
                {
                  key,
                  color: dateAdhesion.shiftColor,
                  adhesion: dateAdhesion.adhesionRate,
                },
              ];
            } else {
              shiftsAdhesionsByDate[date].push({
                key,
                color: dateAdhesion.shiftColor,
                adhesion: dateAdhesion.adhesionRate,
              });
            }
          }
        }
      }
      const sortedDates = Object.keys(shiftsAdhesionsByDate).sort();
      const shifts = {};
      sortedDates.forEach((date) => {
        shiftsAdhesionsByDate[date].forEach((turno) => {
          if (!shifts[turno.key]) {
            shifts[turno.key] = {
              color: turno.color,
              data: Array(sortedDates.length).fill(null), // Inicializa com null
            };
          }
        });
      });

      sortedDates.forEach((date, index) => {
        shiftsAdhesionsByDate[date].forEach((turno) => {
          shifts[turno.key].data[index] = turno.adhesion;
        });
      });

      const chartDataSets = Object.keys(shifts).map((key) => ({
        label: "Plantão " + key,
        data: shifts[key].data,
        borderColor: shifts[key].color,
        backgroundColor: shifts[key].color,
        pointBackgroundColor: shifts[key].color,
        pointRadius: 5,
        pointHoverRadius: 8,
        fill: false,
      }));

      const chartData = {
        labels: Object.keys(shiftsAdhesionsByDate)
          .sort((a, b) => new Date(a) - new Date(b))
          .map((date) => moment(date).format("DD/MM/YYYY")),
        datasets: chartDataSets,
      };

      const options = {
        maintainAspectRatio: false,
        spanGaps: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        legend: {
          display: false,
          position: "bottom",
          align: "center",
          fullWidth: true,
          labels: {
            fontColor: "#A0A4A9",
            usePointStyle: true,
            fontFamily: "Rubik-Regular",
            padding: 27,
          },
        },
        elements: {
          point: {
            radius: 7,
          },
        },
        scales: {
          yAxes: [
            {
              position: "left",
              gridLines: {
                color: Colors.light_gray,
                offsetGridLines: false,
                borderDash: [6],
                lineWidth: 1,
              },
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value + "%";
                  }
                },
              },
            },
          ],
        },
      };

      /*for (const shift of adherenceData.reverse()) {
        let arrayData = new Array(adherenceData.length).fill(0);
        arrayData[index] = shift.adhesion;
        labels.push(shift.startDayMonth + " - " + shift.endDayMonth);
        labelsTime.push(shift.startHour + " - " + shift.endHour);
        datasets.push({
          label: "Plantão " + shift.name + ` (DT:${index + 1})`,
          data: arrayData,
          backgroundColor: shift.color,
          borderColor: shift.color,
          borderWidth: 1,
        });
        index++;
      }*/
      setData(chartData);
      setOptions(options);
    }
  }, []);

  return (
    data &&
    options && (
      <>
        <div class="chartContainer">
          <div style={{ width: chartWidth }}>
            <Line
              height={400}
              width={chartBiggestWidth * 200}
              data={data}
              options={options}
            />
          </div>
        </div>
        <div className="container-subtitle mt-4">
          {data.datasets.map((item, index) => (
            <div className="subtitle-item mr-4">
              <div
                className="indicator-radius"
                style={{ backgroundColor: item.backgroundColor }}
              />
              <p>{item.label.replace(/\s+\(DT:\d+\)/, "")}</p>
            </div>
          ))}
        </div>
      </>
    )
  );
}

export default AdhesionsByShiftBar;
