import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import Colors from "../../../styles/Colors";
import moment from "moment";
import EmptyContainer from "../../Relatorios/EmptyContainer";

function Temperature(props) {
  const chartRef = useRef(null);

  const { historicWounds, typeDescriptions } = props;

  const [data, setData] = useState(null);
  const [temperatureDescriptions, setTemperatureDescriptions] = useState(null);

  useEffect(() => {
    if (historicWounds && typeDescriptions) {
      const labels = [];
      let temperature = typeDescriptions.find((i) =>
        i.name.includes("Temperatura")
      );
      let hasData = false;
      let idIncrementerHelper = 0;
      let temperatureDescriptions = temperature.descriptions.map((i) => {
        idIncrementerHelper++;
        return {
          id: idIncrementerHelper,
          originalId: i.id,
          label: i.name,
          data: [],
          borderWidth: 4,
          pointRadius: 5,
          borderColor: Colors.brand_blue,
          backgroundColor: Colors.brand_blue,
          fill: false,
        };
      });
      setTemperatureDescriptions(temperatureDescriptions);
      let minId = temperatureDescriptions.reduce(
        (min, item) => (item.id < min ? item.id : min),
        temperatureDescriptions[0].id
      );
      for (const historic of historicWounds) {
        let date;
        if (historic.date_realized !== undefined) {
          date = moment(historic.date_realized).format("DD/MM");
        } else if (
          historic.date_confirmed !== undefined &&
          historic.date_confirmed != "Invalid date"
        ) {
          date = moment(historic.date_confirmed).format("DD/MM");
        } else {
          date = moment(historic.date_notification).format("DD/MM");
        }
        labels.push(date);
        let temperatureHistoric = historic.descriptions.find(
          (i) => parseInt(i.type_description_id) == temperature.id
        );
        let temperatureDescriptionIds = temperatureHistoric.description_id
          .split(",")
          .filter((i) => !isNaN(parseInt(i)))
          .map((i) => parseInt(i));
        if (temperatureDescriptionIds.length > 0) {
          hasData = true;
          temperatureDescriptions.map((i) => {
            if (
              !temperatureDescriptionIds.includes(i.originalId) &&
              i.label != "Ausente"
            ) {
              i.data.push(null);
            }
          });
          for (const temperatureDescriptionId of temperatureDescriptionIds) {
            let temperatureData = temperatureDescriptions.find(
              (i) => i.originalId == temperatureDescriptionId
            );
            temperatureData.data.push(temperatureData.id);
          }
        } else {
          temperatureDescriptions.map((i) => {
            if (i.label != "Ausente") {
              i.data.push(null);
            }
          });
          let noTemperature = temperatureDescriptions.find(
            (i) => i.label == "Ausente"
          );
          if (noTemperature) {
            noTemperature.id = minId;
            noTemperature.data.push(minId);
          }
        }
      }
      if (!hasData) {
        return;
      }

      const temperatureIds = temperatureDescriptions.map((i) => i.id);
      temperatureDescriptions.push({
        data: [Math.min(...temperatureIds), Math.max(...temperatureIds)],
        fill: false,
        borderColor: "transparent",
        backgroundColor: "transparent",
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
      });

      setData({ labels, datasets: temperatureDescriptions });
    }
  }, [historicWounds, typeDescriptions]);

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="card-report">
            <h4 className="mb-0">Temperatura</h4>
            {data && temperatureDescriptions ? (
              <div className="mt-4">
                <div class="chartContainer">
                  <div
                    style={{
                      width:
                        data?.datasets[0].data.length > 7
                          ? Math.max(
                              data?.labels.length * 50,
                              window.innerWidth
                            )
                          : null,
                    }}
                  >
                    <Line
                      ref={chartRef}
                      data={data}
                      options={{
                        maintainAspectRatio: false,
                        tooltips: {
                          callbacks: {
                            label: function (tooltipItem, data) {
                              if (
                                tooltipItem.datasetIndex ==
                                temperatureDescriptions.length - 1
                              ) {
                                return;
                              }
                              let temperatureLabel =
                                temperatureDescriptions.find(
                                  (i) => i.id == tooltipItem.yLabel
                                )?.label;
                              return `${temperatureLabel}: ${tooltipItem.label}`;
                            },
                          },
                        },
                        legend: {
                          display: false,
                        },
                        scales: {
                          yAxes: [
                            {
                              gridLines: {
                                borderDash: [4, 4],
                              },
                              ticks: {
                                callback: function (value, index, values) {
                                  return temperatureDescriptions.find(
                                    (i) => i.id == value
                                  )?.label;
                                },
                              },
                            },
                          ],
                          xAxes: [
                            {
                              gridLines: {
                                display: false,
                                drawBorder: false,
                              },
                            },
                          ],
                        },
                      }}
                      height={300}
                      width={100}
                    />
                  </div>
                </div>
                <div className="container-subtitle mt-4">
                  <div className="subtitle-item mr-4">
                    <div
                      className="indicator-radius"
                      style={{ backgroundColor: Colors.brand_blue }}
                    />
                    <p>Notificação / Evolução</p>
                  </div>
                </div>
              </div>
            ) : (
              <EmptyContainer
                text="Ainda não há dados sobre a temperatura da pele adjacente da lesão"
                iconName="line-chart"
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Temperature;
