import React from "react";
import { useSelector, useDispatch } from "react-redux";

import "./styles.css";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import { Show_Alert, Hidden_Alert } from "../../store/actions/alert";

function AlertBox() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  var icon = "";
  var color = "";
  var colorBtn = "";
  if (alert.options.type == "success") {
    colorBtn = "#36D57D";
    color = Colors.brand_green;
    icon = "check-circle";
  } else if (alert.options.type == "error") {
    colorBtn = Colors.brand_red;
    color = Colors.light_red;
    icon = "warning";
  } else if (alert.options.type == "warning") {
    colorBtn = "#FF8346";
    color = "#FF8F27";
    icon = "warning";
  }

  //Esconde o alert automaticamente, seta no redux
  setTimeout(() => {
    dispatch(Hidden_Alert());
  }, 9000);

  if (alert.options.msg != undefined) {
    return (
      <div style={{ backgroundColor: color }} className="alertBox">
        <div className="left">
          <Icon color={"white"} size={20} icon={icon} className="mr-3" />
          <p className="mb-0">{alert.options.msg}</p>
        </div>

        <div className="right ml-3">
          <button
            style={{ backgroundColor: colorBtn }}
            onClick={() => dispatch(Hidden_Alert())}
          >
            <Icon color={"white"} size={20} icon="x" />
          </button>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export default AlertBox;
