import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import MainNavbar from "../../../components/Nav/MainNavbar";
import NotificacoesPendentes from "./NotificacoesPendentes";
import NotificacoesConfirmadas from "./NotificacoesConfirmadas";

import "./styles.css";
import { useDispatch, useSelector } from "react-redux";

export function makeUserDataToPhoto(name, image) {
  return {
    dados: {
      name: name,
      image: image ? image : null,
      url_image: image ? image : null,
    },
  };
}

function Notificacoes({ history }) {
  const woundState = useSelector((state) => state.defaultWoundState);
  const [aba, setAba] = useState(
    woundState.selected ? "pendentes" : "confirmadas"
  );

  return (
    <div>
      <MainNavbar history={history} />

      <Container fluid className="main-container">
        <div className="px-2">
          <Row
            className="w-100"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={12}>
              <div className="page-title">Notificações de lesões</div>
            </Col>
          </Row>

          <Row className="tabs mt-5">
            <Col xs={6} md={2} lg={2} onClick={() => setAba("pendentes")}>
              <h6
                className={aba == "pendentes" ? "active-text" : ""}
                onClick={() => setAba("pendentes")}
              >
                Pendentes
              </h6>
            </Col>
            <Col xs={6} md={2} lg={2} onClick={() => setAba("confirmadas")}>
              <h6
                className={aba == "confirmadas" ? "active-text" : ""}
                onClick={() => setAba("confirmadas")}
              >
                Confirmadas
              </h6>
            </Col>
          </Row>

          <Row className="tab-bar">
            <Col
              xs={2}
              md={2}
              lg={2}
              onClick={() => setAba("pendentes")}
              className={aba == "pendentes" ? "active-tab" : ""}
            />
            <Col
              xs={2}
              md={2}
              lg={2}
              onClick={() => setAba("confirmadas")}
              className={aba == "confirmadas" ? "active-tab" : ""}
            />
            <Col xs={8} md={8} lg={8}></Col>
          </Row>
        </div>

        {aba == "pendentes" && <NotificacoesPendentes history={history} />}

        {aba == "confirmadas" && <NotificacoesConfirmadas history={history} />}
      </Container>
    </div>
  );
}

export default Notificacoes;
