import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

import "./styles.css";
import api from "../../services/api";
import list_wounds from "../../functions/list_wounds";
import { Show_Alert } from "../../store/actions/alert";
import CheckBox from "../CheckBox";
import { useDispatch } from "react-redux";

var originalUsers = null;

function DeleteDischargedPatient(props) {
  const { patient, handler_show_modal, show_modal, handler_reload, reload } =
    props;

  const dispatch = useDispatch();

  const [loadingBtn, setLoadingBtn] = useState("none");

  function Delete() {
    setLoadingBtn("flex");
    api
      .delete(`/patients/${patient.id}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(
            Show_Alert({
              type: "success",
              msg: "O paciente foi excluído com sucesso",
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("Delete error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível excluir o paciente",
          })
        );
      })
      .finally(() => {
        handler_show_modal(false);
        setLoadingBtn("none");
      });
  }

  return (
    <>
      <Modal
        show={show_modal}
        onHide={() => handler_show_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <div className="modal-title">Excluir paciente</div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>
          <div className="modal-all">
            <div className="section-discharge mt-4">
              <p className="text-delete-user">
                O paciente <span>{patient.name}</span> será excluído, tem
                certeza que deseja continuar?
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              className="secondary-btn modal-footer-btn mr-0 btn-cancel"
              onClick={() => handler_show_modal(false)}
            >
              Cancelar
            </button>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={loadingBtn === "flex"}
              style={{
                backgroundColor: Colors.brand_red,
                cursor: "pointer",
              }}
              // style={{ backgroundColor: Colors.brand_pink, cursor: "pointer" }}
              className="mb-0 modal-footer-btn btn-confirm"
              onClick={() => Delete()}
            >
              <p
                className="mb-0"
                style={{
                  color: Colors.white,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Excluir
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteDischargedPatient;
