import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import "../styles/global.css";
import { monthAbbToFullDictionary } from "../functions/utils";
import Icon from "../functions/Icon";
import Colors from "../styles/Colors";
import LastMonthsPeriodDropdown from "./Dropdowns/LastMonthsPeriodDropdown";
import moment from "moment";

function DateRangePickerMonthYear({
  interval,
  intervalHandler,
  period,
  periodHandler,
}) {
  const [show, setShow] = useState(false);

  const [years, setYears] = useState([
    2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
    2024, 2025, 2026, 2027,
  ]);

  const [yearSelected, setYearSelected] = useState(null);
  const [monthSelected, setMonthSelected] = useState(null);

  const [months, setMonths] = useState([
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ]);

  const getRows = (yearsArray) => {
    const rows = [];
    for (let i = 0; i < yearsArray.length; i += 4) {
      rows.push(yearsArray.slice(i, i + 4));
    }
    return rows;
  };

  useEffect(() => {
    setMonthSelected(null);
    setYearSelected(null);
  }, [show]);

  return (
    <Row>
      <Col md={2} className="mb-2">
        <p
          className="mb-1"
          style={{
            color: Colors.darker_gray,
          }}
        >
          Período
        </p>
        <LastMonthsPeriodDropdown
          period={period}
          periodHandler={periodHandler}
        />
      </Col>
      <Col md={2}>
        <p
          className="mb-1"
          style={{
            color: Colors.darker_gray,
          }}
        >
          A partir de:
        </p>
        <Dropdown
          className="drop-sort dropdown-hide-icon"
          show={show}
          onToggle={() => setShow(!show)}
          style={{
            padding: "9px!important",
            fontSize: "16px!important",
          }}
        >
          <Dropdown.Toggle
            id="dropdown-sort"
            className={`dropdown-clear`}
            style={{
              textTransform: "capitalize",
            }}
          >
            <span>
              {interval.month} / {interval.year}
            </span>
            <span>
              <Icon icon="arrow-select" size={20} />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu
            rootCloseEvent={"click"}
            className="div-drop-sort"
            style={{
              borderRadius: "10px",
            }}
          >
            <div>
              {monthSelected &&
                getRows(years).map((yearGroup, index) => (
                  <div key={index} className="year-row">
                    {yearGroup.map((year) => (
                      <div
                        key={year}
                        className={`year ${year < 2020 ? "disabled" : ""}`}
                        onClick={() => {
                          if (year >= 2020) {
                            setYearSelected(year);
                            intervalHandler({
                              month: monthAbbToFullDictionary()[monthSelected],
                              year: year,
                            });
                            setShow(false);
                          }
                        }}
                      >
                        <p>{year}</p>
                      </div>
                    ))}
                  </div>
                ))}
              {!monthSelected &&
                getRows(months).map((monthGroup, index) => (
                  <div key={index} className="year-row">
                    {monthGroup.map((month) => (
                      <div
                        key={month}
                        className="year"
                        onClick={() => {
                          setMonthSelected(month);
                        }}
                      >
                        <p>{month}</p>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default DateRangePickerMonthYear;
