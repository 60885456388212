// BatteryAlertCard.js
import React, { useState, useEffect } from "react";
import Icon from "../functions/Icon";
import Colors from "../styles/Colors";
import api from "../services/api";

const BatteryAlertCard = ({ unitId, beaconId }) => {
  const [batteryLevel, setBatteryLevel] = useState(null);

  useEffect(() => {
    const loadBatteryStatus = async () => {
      try {
        const response = await api.get(`/beacons/${beaconId}`, {
          params: { unit_id: unitId },
        });
        if (response.status === 200) {
          setBatteryLevel(response.data.battery);
        }
      } catch (error) {
        console.error("Error loading beacon battery level:", error);
      }
    };

    if (beaconId && unitId) {
      loadBatteryStatus();
    }
  }, [unitId, beaconId]);

  if (batteryLevel == null || batteryLevel > 30) {
    return null;
  }

  return (
    <div className="d-flex flex-column align-items-start mt-2">
      <div className="card-low-battery" style={{ width: "100%" }}>
        <div className="icon-and-text">
          <div className="circle-style">
            <Icon color={Colors.brand_red} size={24} icon="warning" />
          </div>
          <p className="font-card-low-battery">
            O sensor selecionado está com bateria fraca. Recomendamos utilizar
            outro sensor ou fazer a troca da bateria.
          </p>
        </div>
        <div className="separator-line"></div>
        <p className="battery-level-text">
          Nível de bateria atual: {batteryLevel}%
        </p>
      </div>
    </div>
  );
};

export default BatteryAlertCard;
