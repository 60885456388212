import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

var arrayOrder = [
  {
    icon: "bed",
    value: "room",
    name: "Quarto / leito",
  },
  {
    icon: "refresh",
    value: "status",
    name: "Status",
  },
];

function DecubitMapDropdown(props) {
  const dispatch = useDispatch();
  const sortState = useSelector((state) => state.sortSelected);

  const { sort, handler } = props;
  // Exibir dropdown
  const [show, setShow] = useState(false);

  // Ordenação escolhida
  const [selected, setSelected] = useState({
    icon: "bed",
    value: "room",
    name: "Quarto / leito",
  });

  useEffect(() => {
    if (sort.name != undefined) {
      let sortSelected = sortState.dados ? sortState.dados : sort;
      handler(sortSelected);
      setSelected(sortSelected);
    }
  }, [sort]);

  function SetarHandler(obj) {
    handler(obj);
    dispatch({
      type: "SET_SORT_SELECTED",
      dados: obj,
    });
  }

  function GetNameSelected() {
    var name = selected.name;
    return name;
  }

  return (
    <Dropdown
      className="drop-sort mr-3"
      onToggle={() => setShow(!show)}
      style={{ fontSize: "17px" }}
    >
      <Dropdown.Toggle
        id="dropdown-sort"
        className={
          show || selected.name != "Quarto / leito"
            ? `btn-filter-mdd active`
            : `btn-filter-mdd`
        }
        style={{
          padding: "30px!important",
        }}
      >
        <Icon
          color={
            show || selected.name != "Quarto / leito"
              ? Colors.brand_blue
              : Colors.darker_gray
          }
          size={22}
          icon={selected.icon}
          className="mr-2"
        />
        {GetNameSelected()}
      </Dropdown.Toggle>

      <Dropdown.Menu rootCloseEvent={"click"} className="div-drop-sort">
        {arrayOrder.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              className="drop-sort-item"
              onClick={() => {
                setSelected(item);
                SetarHandler(item);
              }}
              //   onSelect={() => setSelected(null)}
            >
              <Icon
                color={
                  selected.name == item.name
                    ? Colors.brand_blue
                    : Colors.darker_gray
                }
                size={22}
                icon={item.icon}
                className="mr-3"
              />
              <div
                style={
                  selected.name == item.name ? { color: Colors.brand_blue } : {}
                }
              >
                {item.name}
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DecubitMapDropdown;
