import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  ProgressBar,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import VerifyPw from "../../../../../components/Modals/VerifyPw";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import {
  GetImc,
  CalculateAge,
  userHasPermission,
  userWithoutPermission,
} from "../../../../../functions/utils";
import moment from "moment";
import "./styles.css";

function NovaObs(props) {
  const { handler_show_modal, show_modal, noteObj, handler_reload, reload } =
    props;

  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [listUsers, setListUsers] = useState([]);
  const [showPw, setShowPw] = useState(false);

  //Dado form
  const [note, setNote] = useState("");
  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });

  useEffect(() => {
    if (noteObj != null) {
      setNote(noteObj.note);
      setUserChooser({ label: noteObj.user.name, value: noteObj.user.id });
    } else {
      //Se permission_id for 1, 2 ou 3 seta quem ta logado direto no responsavel
      if (userHasPermission(user)) {
        setUserChooser({ label: user.dados.name, value: user.dados.id });
      }
    }
  }, []);

  function VerifyFields() {
    var verify = false;
    if (note != "" && userChooser.value > 0) {
      verify = true;
    }

    return verify;
  }

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: patientSelected.dados.unit_id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (userWithoutPermission(user)) {
      LoadUsers();
    }
  }, []);

  function SaveNote() {
    setLoadingBtn("flex");

    if (noteObj == null) {
      api
        .post(`/patients/${patientSelected.dados.id}/notes`, {
          note,
          user_id: userChooser.value,
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("SaveNote success");
            console.log(response.data);
            dispatch(
              Show_Alert({
                type: "success",
                msg: "Observações salvas com sucesso",
              })
            );

            handler_reload(reload == true ? false : true);
          }
        })
        .catch((error) => {
          console.log("SaveNote error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível salvar essa observação. Verifique sua conexão.",
            })
          );
        })
        .finally(() => {
          setLoadingBtn("none");
          handler_show_modal(false);
        });
    } else {
      api
        .put(`/patients/${patientSelected.dados.id}/notes/${noteObj.id}`, {
          note,
          user_id: userChooser.value,
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("EditNote success");
            console.log(response.data);
            dispatch(
              Show_Alert({
                type: "success",
                msg: "Observação atualizada",
              })
            );

            handler_reload(reload == true ? false : true);
          }
        })
        .catch((error) => {
          console.log("EditNote error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível editar essa observação. Verifique sua conexão.",
            })
          );
        })
        .finally(() => {
          setLoadingBtn("none");
          handler_show_modal(false);
        });
    }
  }

  return (
    <Modal
      show={show_modal}
      onHide={() => {
        handler_show_modal(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=" modal-pw"
    >
      {showPw && (
        <VerifyPw
          handler_show_pw={setShowPw}
          show_pw={showPw}
          handler_user={setUserChooser}
          id_user={userChooser.value}
        />
      )}
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">
              {noteObj == null ? "Nova observação" : "Editar observação"}
            </div>
            {noteObj != null && (
              <p className="text-obs-edit">
                {noteObj.created_at == noteObj.updated_at
                  ? "Criado"
                  : "Editado"}{" "}
                {moment(noteObj.updated_at).format("LLL")}
              </p>
            )}
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => {
                handler_show_modal(false);
              }}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div style={{ paddingRight: 20, paddingLeft: 20 }} className="">
          <div className="mt-4">
            <div className="mb-4">
              <Form.Label className="field-label">
                Observações sobre o paciente
              </Form.Label>
              <textarea
                rows={5}
                className="default-input"
                type="text"
                placeholder="Digitar observações"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>

            {userWithoutPermission(user) && (
              <div className="mb-1">
                <Form.Label className="field-label">Responsável</Form.Label>
                <Select
                  captureMenuScroll={false}
                  classNamePrefix="react-select"
                  placeholder={"Selecionar"}
                  options={listUsers}
                  isClearable={false}
                  value={userChooser}
                  onChange={(item) => {
                    setUserChooser(item);
                    setShowPw(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end align-items-center">
        <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
          <button
            className="modal-footer-btn mr-0 btn-cancel"
            onClick={() => {
              handler_show_modal(false);
            }}
          >
            Cancelar
          </button>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={
              (VerifyFields() == true ? false : true) || loadingBtn === "flex"
            }
            className="mb-0 modal-footer-btn btn-confirm"
            style={{
              backgroundColor:
                VerifyFields() == true
                  ? Colors.brand_pink
                  : Colors.lighter_gray,
            }}
            onClick={() => SaveNote()}
          >
            <p
              className="mb-0"
              style={{
                color: VerifyFields() == true ? Colors.white : Colors.dark_gray,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              {noteObj == null ? "Adicionar" : "Salvar"}
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default NovaObs;
