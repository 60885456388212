import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";

import Icon from "../../../../../functions/Icon";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import moment from "moment";
import "./styles.css";
import { renderPhoto } from "../../../../../components/Dropdowns/ProfileDropdown";
import { makeUserDataToPhoto } from "../../../../Home/Notificacoes/Notificacoes";

function DetailsCheckList({ ...props }) {
  const { handler_show_modal, show_modal, check_list } = props;

  return (
    <Modal
      show={show_modal}
      onHide={() => handler_show_modal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Detalhes da {check_list.title}</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_show_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all mt-3 pb-0">
          <div className="container-list-gray">
            {check_list.checklist.map((item) => {
              return (
                <div className="list-gray-details mb-2">
                  <p
                    className="list-gray-details-title mb-0"
                    style={{ maxWidth: "70%" }}
                  >
                    {item.value}
                  </p>
                  <p className="list-gray-details-subtitle mb-0">
                    <Icon
                      color={
                        item.is_checked == 1
                          ? Colors.brand_green
                          : Colors.darker_gray
                      }
                      size={24}
                      icon={item.is_checked == 1 ? "check" : "minus"}
                    />
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>

      <div className="px-4 pb-4 mt-3">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div className="c-profile-dropdown">
            { renderPhoto(makeUserDataToPhoto(check_list.user_name, check_list.user_image !== null ? check_list.user_image : null)) }
          </div>

          <div className="dados-default">
            <div className="name-default">Por {check_list.user_name}</div>
            <div className="date-default">
              {moment(check_list.created).format("LLL")}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DetailsCheckList;
