import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import { GetImc, CalculateAge } from "../../functions/utils";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import "./styles.css";
import api from "../../services/api";
import list_wounds from "../../functions/list_wounds";
import { Show_Alert } from "../../store/actions/alert";

var originalUsers = null;

function VerifyPw(props) {
  const { handler_show_pw, show_pw, handler_user, id_user } = props;
  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);

  // Exibição do modal
  const [loadingBtn, setLoadingBtn] = useState("none");

  //Modal pw
  const [password, setPassword] = useState("");
  const [msgPw, setMsgPw] = useState("");
  const [viewPwd, setViewPwd] = useState(false);

  function VerifyPw() {
    setLoadingBtn("flex");

    api
      .post("/verify-login", {
        user_id: id_user,
        password,
      })
      .then((response) => {
        if (response.status == 200) {
          if (response.data) {
            handler_show_pw(false);
          } else {
            setMsgPw("A senha inserida está incorreta");
          }
        }
      })
      .catch((error) => {
        console.log("VerifyPw " + error);
        setMsgPw("Algo não saiu como o esperado. Tente novamente mais tarde.");
      })
      .finally(() => setLoadingBtn("none"));
  }

  return (
    <Modal
      show={show_pw}
      onHide={() => {
        handler_show_pw(false);
        handler_user({ label: "Selecionar", value: 0 });
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-pw"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      tabIndex="1"
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          VerifyPw();
        }
      }}
    >
      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Autenticar confirmação</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => {
                handler_user({ label: "Selecionar", value: 0 });
                handler_show_pw(false);
              }}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all">
          <Form.Label className="field-label">Senha</Form.Label>
          <div className="default-input last-input">
            <input
              type={viewPwd ? "text" : "password"}
              className="borderless-input"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div onClick={() => setViewPwd(!viewPwd)}>
              <Icon
                color={Colors.darker_gray}
                size={24}
                icon={viewPwd ? "eye-off" : "eye-on"}
              />
            </div>
          </div>

          {msgPw != "" && <p className="mt-2 mb-0 msg-pw">{msgPw}</p>}
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end align-items-center">
        <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
          <button
            className="modal-footer-btn mr-0 btn-cancel"
            onClick={() => {
              handler_user({ label: "Selecionar", value: 0 });
              handler_show_pw(false);
            }}
          >
            Cancelar
          </button>
        </Col>
        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={(password != "" ? false : true) || loadingBtn === "flex"}
            className="mb-0 modal-footer-btn btn-confirm"
            style={{
              backgroundColor:
                password != "" ? Colors.brand_pink : Colors.lighter_gray,
            }}
            onClick={() => VerifyPw()}
          >
            <p
              className="mb-0"
              style={{
                color: password != "" ? Colors.white : Colors.dark_gray,
                display: loadingBtn == "none" ? "flex" : "none",
              }}
            >
              Continuar
            </p>

            <Spinner
              style={{ display: loadingBtn }}
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default VerifyPw;
