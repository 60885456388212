import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../services/api";
import { Container, Form, Spinner } from "react-bootstrap";

import "./styles.css";
import logo from "../../../assets/img/logo.png";
import Colors from "../../../styles/Colors";
import { Show_Alert } from "../../../store/actions/alert";

function CadastrarOtp({ history }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [token, setToken] = useState("");
  const [qrCode, setQrCode] = useState(null);
  const [secret, setSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState("none");

  function RegisterOtp() {
    setLoading(true);
    api
      .get("/otp/register")
      .then((response) => {
        if (response.data) {
          if (response.data.hasMfaValidatorEnabled) {
            history.push("/Otp/Validador");
          }
          setQrCode(response.data.image64);
          setSecret(response.data.secret);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function ValidateOtp() {
    setLoadingBtn("flex");
    api
      .post("/otp/validator", {
        token,
      })
      .then((response) => {
        if (response.data) {
          history.push({
            pathname: "/Home",
          });
        } else {
          dispatch(
            Show_Alert({
              type: "error",
              msg: "O código de segurança está inválido.",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          Show_Alert({
            type: "error",
            msg: "O código de segurança está inválido.",
          })
        );
      })
      .finally(() => {
        setLoadingBtn("none");
      });
  }

  useEffect(() => {
    RegisterOtp();
  }, []);

  return (
    <div>
      <Container
        fluid
        className="main-container d-flex justify-content-center align-items-center container-otp"
      >
        <img
          src={logo}
          className="logo-nav mb-5"
          style={{
            maxHeight: "4.2em",
          }}
        />
        <div className="card-report otp-register">
          <h4 className="mb-4">Configuração do MFA</h4>
          <p>
            Para prosseguir com a autenticação na aplicação da Preveni, é
            necessário um aplicativo de autenticação instalado em seu
            dispositivo. Recomendamos o uso do Microsoft Authenticator ou Google
            Authenticator, disponível para download tanto em dispositivos{" "}
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=pt_BR&gl=US"
            >
              Android
            </a>{" "}
            quanto{" "}
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
            >
              iOS
            </a>
            . Este aplicativo gera códigos de segurança temporários que serão
            usados no processo de login, garantindo maior proteção para sua
            conta
          </p>
          {loading && (
            <div className="container-loading-report mt-3 d-flex justify-content-center align-items-center w-100">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {!loading && (
            <div>
              <div className="mt-4 flex-column d-flex align-items-center">
                <p>
                  Escaneie o QRCode utilizando o seu aplicativo de autenticação.
                </p>
                <img width={250} src={qrCode} alt="QRCode TOTP"></img>
              </div>
              <div className="mt-4 flex-column d-flex align-items-center">
                <p>Ou insira o código abaixo</p>
                <p className="otp-secret">{secret}</p>
              </div>
            </div>
          )}
          <div className="mt-4 d-flex flex-column align-items-center">
            <div>
              <Form.Label>
                Digite o código de verificação do seu aplicativo de autenticação
              </Form.Label>
              <input
                className="default-input"
                type="text"
                name="secret"
                placeholder="000000"
                onChange={(e) => setToken(e.target.value)}
              />
            </div>
            <button
              disabled={token.length !== 6}
              onClick={ValidateOtp}
              style={{
                background:
                  token.length == 6 ? Colors.brand_pink : Colors.lightest_gray,
              }}
              className="btn-click mt-3"
            >
              <p
                className="mb-0"
                style={{
                  display: loadingBtn == "none" ? "flex" : "none",
                  color: token.length == 6 ? "white" : Colors.dark_gray,
                }}
              >
                Habilitar MFA
              </p>
              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CadastrarOtp;
