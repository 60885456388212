import React, { useEffect, useState } from "react";
import Select from "react-select";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import { Form } from "react-bootstrap";
import {
  IncidenceStageColor,
  resizeChartBasedOnWidth,
} from "../../functions/utils";
import { Pie } from "react-chartjs-2";
import ModalPatientsRisks from "./ModalData/ModalPatientsRisks";
import EmptyContainer from "./EmptyContainer";

const arrayFake1 = [
  {
    name: "Estágio 1",
    color: IncidenceStageColor(0),
  },
  {
    name: "Estágio 2",
    color: IncidenceStageColor(1),
  },
  {
    name: "Estágio 3",
    color: IncidenceStageColor(2),
  },
  {
    name: "Estágio 4",
    color: IncidenceStageColor(3),
  },
  {
    name: "Não classificável",
    color: IncidenceStageColor(4),
  },

  {
    name: "Tissular profunda",
    color: IncidenceStageColor(5),
  },

  {
    name: "Membranas mucosas",
    color: IncidenceStageColor(6),
  },

  {
    name: "Dispositivo médico",
    color: IncidenceStageColor(7),
  },
];

function WoundStageClassificationPie(props) {
  const {
    wounds,
    woundStageClassifications,
    woundChooser,
    handlerWoundChooser,
  } = props;

  const [listWounds, setListWounds] = useState(null);
  const [listStage, setListStage] = useState(null);
  const [patientIds, setPatientIds] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  useEffect(() => {
    if (wounds && woundStageClassifications) {
      const arrayListWounds = [{ label: "Todos", value: 0 }];
      wounds.map((item) => {
        arrayListWounds.push({
          label: item.wound_name,
          value: item.wound_id,
        });
      });
      const arrayStage = [];
      const arrayStageLabels = [];
      const arrayStageColors = [];
      const arrayCounter = [];
      let stageEmptyVerify = true;
      woundStageClassifications.map((item, index) => {
        if (stageEmptyVerify && item.counter > 0) stageEmptyVerify = false;
        arrayCounter.push(item.counter);
        arrayStageLabels.push(`${item.percentage}% ${item.stage_name}`);
        arrayStage.push(item.percentage);
        arrayStageColors.push(IncidenceStageColor(index));
      });
      setListStage(
        stageEmptyVerify
          ? null
          : {
              labels: arrayStageLabels,
              datasets: [
                {
                  counter: arrayCounter,
                  data: arrayStage,
                  backgroundColor: arrayStageColors,
                  borderWidth: 0,
                },
              ],
            }
      );
      setListWounds(arrayListWounds);
    }
  }, [wounds, woundStageClassifications]);

  const optionsPie = {
    onClick: function (evt, item) {
      if (item.length > 0) {
        const index = item[0]._index;
        const label = listStage.labels[index];
        const counter = listStage.datasets[0].counter[index];
        handleModalPatientRisks(
          "Classificação: " + label.split("%")[1],
          woundStageClassifications[index].patientIds,
          woundStageClassifications[index].percentage
        );
        setShowPatientsRisksData(true);
      }
    },
    onHover: function (event, chartElement) {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    legend: {
      position: "right",
      align: "start",
      fullWidth: true,
      labels: {
        fontColor: Colors.darker_gray,
        usePointStyle: true,
        padding: 20,
        fontFamily: "Rubik-Regular",
      },
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return data["labels"][tooltipItem[0]["index"]];
        },
        label: function (tooltipItem, data) {
          var temp = data["datasets"][0]["counter"][tooltipItem["index"]];
          return `Quant. de lesões: ${temp}`;
        },
      },
    },
  };

  function handleModalPatientRisks(title, patientIds, percentage) {
    setPatientIds(patientIds);
    setSelectedData({
      label: title,
      patientQuantity: patientIds.length,
      percentage,
    });
    setShowPatientsRisksData(true);
  }

  return (
    <div className="wound-stage-classifications">
      <div className="d-flex align-items-center">
        <h4 className="mb-0">Classificação de lesões por pressão</h4>
      </div>

      {listStage ? (
        <>
          {listStage && (
            <div className="mt-4">
              <Form.Label className="field-label">Local da lesão</Form.Label>
              <Select
                captureMenuScroll={false}
                noOptionsMessage={() => "Sem opções"}
                classNamePrefix="react-select"
                options={listWounds}
                isClearable={false}
                value={woundChooser}
                onChange={(item) => {
                  handlerWoundChooser(item);
                }}
              />
            </div>
          )}

          <div className="mt-5">
            <Pie
              data={listStage}
              options={optionsPie}
              height={resizeChartBasedOnWidth()}
            />
          </div>
        </>
      ) : (
        <EmptyContainer
          text="Ainda não há dados sobre regiões de classificação de lesões por pressão"
          iconName="description_1"
        />
      )}
      {showPatientsRisksData && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={selectedData?.label}
          subTitle={
            `${selectedData?.patientQuantity} ${
              selectedData?.patientQuantity === 1 ? "paciente" : "pacientes"
            }` +
            " - " +
            selectedData?.percentage +
            "%"
          }
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Fatores de risco",
            "Avaliação de risco",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="populationalData"
        />
      )}
    </div>
  );
}

export default WoundStageClassificationPie;
