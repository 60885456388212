import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import api from "../../../../../../../../services/api";
import { Show_Alert } from "../../../../../../../../store/actions/alert";
import Icon from "../../../../../../../../functions/Icon";
import Colors from "../../../../../../../../styles/Colors";
import { HexToRgbA } from "../../../../../../../../functions/utils";
import moment from "moment";

function ModalEsquecidas({ ...props }) {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const { handler_show_modal, show, dados } = props;

  return (
    <div>
      <Modal
        show={show}
        onHide={() => handler_show_modal(false)}
        centered
        className="modal-decubito"
      >
        <Modal.Body
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            paddingTop: 5,
            paddingBottom: 15,
          }}
        >
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Col xs={6} md={6} lg={6} className="pl-0">
              <div
                className="status-tag"
                style={{
                  backgroundColor: Colors.brand_red,
                }}
              >
                ESQUECIDA
              </div>
            </Col>

            <Col
              xs={2}
              md={6}
              lg={6}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <Row className="mb-3 align-items-center">
            <p className="modal-title-sm">Detalhes da mudança de decúbito</p>
          </Row>

          <Row className="mb-2 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="calendar"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {moment(dados.datetime_schedule).format("LL")}
            </div>
          </Row>

          <Row className="mb-2 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="clock"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {moment(dados.datetime_schedule).format("LT")}
            </div>
          </Row>

          <Row className="mb-3 align-items-center">
            <Icon
              color={Colors.brand_blue}
              size={20}
              icon="refresh"
              className="mr-2"
            />
            <div
              className="modal-text-sm"
              style={{ color: Colors.darker_gray }}
            >
              {dados.position_name}
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalEsquecidas;
