import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Spinner, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Ws from "@adonisjs/websocket-client";

import MainNavbar from "../../../components/Nav/MainNavbar";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import Icon from "../../../functions/Icon";
import IconAchievements from "../../../functions/IconAchievements";
import Colors from "../../../styles/Colors";
import { HexToRgbA, ToggleFullScreen } from "../../../functions/utils";
import ModalSensor from "../../../components/Modals/ModalSensor";

var list2 = [];

function Vinculacao({ history }) {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [showModalSensor, setShowModalSensor] = useState(false);
  const [list, setList] = useState([1, 2]);
  const [chooser, setChooser] = useState({});
  const [reload, setReload] = useState(false);

  useEffect(() => {
    function WebSocketInit() {
      //Web Socket Adonis
      const ws = Ws(`${process.env.REACT_APP_WEB_SOCKET_ENDPOINT}`, {
        path: "ws",
        reconnection: false,
      });
      ws.connect();

      const topic = ws.subscribe("websocket");
      topic.on("ready", () => {
        topic.on("notification", (response) => {
          var obj = response.data;
          if (
            obj.unit_id != unit.selecionado?.id ||
            obj?.patient_id == undefined
          )
            return false;

          var found = list2.find((item) => item?.patient_id == obj?.patient_id);
          if (found != undefined) {
            var newList = list2.map((item) => {
              if (item.patient_id == obj.patient_id) {
                item.age = obj.age;
                item.beacon_id = obj?.beacon?.id;
                item.beacon_name = obj?.beacon?.name;
                item.button = obj.link_beacon ? "link" : "unlink";
                item.date_birth = obj.date_birth;
                item.initials = obj.initials;
                item.name = obj.name;
                item.patient_id = obj.patient_id;
                item.room = obj.room;
                item.subroom = obj.subroom;
              }
              if (obj.link_beacon || obj.unlink_beacon) return item;
            });
            setList(newList);
            list2 = newList;
          }
        });
      });

      ws.on("open", () => {
        console.log("ws connect");
      });

      ws.on("close", () => {
        console.log("ws disconnect");
        setTimeout(() => {
          WebSocketInit();
        }, 9000);
      });
    }

    WebSocketInit();
  }, [unit]);

  useEffect(() => {
    function LoadBeacons() {
      api
        .get(`/beacons/patients`, {
          params: {
            unit_id: unit.selecionado.id,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadVincu success");
            console.log(response.data);
            setList(response.data);
            list2 = response.data;
          }
        })
        .catch((error) => {
          console.log("LoadVincu error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de Vinculação e desvinculação de sensores",
            })
          );
        });
    }

    LoadBeacons();
  }, [reload]);

  return (
    <div>
      <MainNavbar history={history} />

      {showModalSensor && (
        <ModalSensor
          data={chooser}
          handler_modal={setShowModalSensor}
          show_modal={showModalSensor}
          link={chooser.button == "link" ? true : false}
          handler_reload={setReload}
          reload={reload}
          patient_id={chooser.patient_id}
          room={chooser.room}
          subroom={chooser.subroom}
          date_birth={chooser.date_birth}
          age={chooser.age}
          patient_name={chooser.name}
          beacon_name={chooser?.beacon_name}
          beacon_id={chooser?.beacon_id}
          unit_id={chooser.unit_id}
        />
      )}

      <Container
        fluid
        className="bg-white"
        style={{ paddingRight: 80, paddingLeft: 80 }}
      >
        <div className="pt-5 container-header-screen d-flex justify-content-between align-items-center">
          <h2 className="p-0">Vinculação e desvinculação de sensores</h2>
        </div>

        <Row className="justify-content-start align-items-center">
          {loading && (
            <div className="mt-3 w-100 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          )}

          {!loading && list.length == 0 && (
            <div className="container-error-default">
              <p>Não há sensores para vincular ou desvincular</p>
            </div>
          )}

          {!loading &&
            list.map((item, index) => {
              return (
                <Col xs={12} md={4} lg={3} className="my-2">
                  <div
                    className="mdd-card-sensor"
                    onClick={() => {
                      setChooser(item);
                      setShowModalSensor(true);
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 15,
                      }}
                    >
                      <div className="d-flex justify-content-between mb-2">
                        <p className="card-name card-name-mdd">
                          {item.initials}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="card-quarto card-quarto-mdd mb-3">
                          {item.room == null && item.subroom}
                          {item.room != null &&
                            item.room + " | " + item.subroom}
                        </div>
                      </div>
                    </div>

                    {item.button == "link" ? (
                      <button className="btn-vincular">
                        <p>Vincular sensor</p>
                      </button>
                    ) : (
                      <button
                        className="btn-vincular"
                        style={{ backgroundColor: Colors.dark_gray }}
                      >
                        <p>Desvincular sensor</p>
                      </button>
                    )}
                  </div>
                </Col>
              );
            })}

          {/* {!loading && (
            <Col xs={12} md={4} lg={3} className="my-2">
              <div
                className="mdd-card-sensor"
                onClick={() => setShowModalSensor(true)}
              >
                <div
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 15,
                  }}
                >
                  <div className="d-flex justify-content-between mb-2">
                    <p className="card-name">Matheus Henrique</p>
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="card-quarto mb-3">Q1 | L2</div>
                  </div>
                </div>

                <button className="btn-vincular">
                  <p>Vincular sensor</p>
                </button>
              </div>
            </Col>
          )} */}
        </Row>
      </Container>
    </div>
  );
}

export default Vinculacao;
