import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

function ModalTermos(props) {

    // Exibição do modal
    const [show, setShow] = useState(props.show);
    const handler = props.handler

    useEffect(() => {
        handler(show)
    }, [show])


    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-filter modal-termos"
                scrollable
                centered
            >
                <Row style={{ justifyContent: "space-between", alignItems: "center" }} className="mb-4 px-2">
                    <Col>
                        <div className="modal-title">Termos de uso</div>
                    </Col>
                    <Col
                    xs={2}
                    md={2}
                    lg={2}
                    className="pr-0 d-flex justify-content-end"
                    >
                        <div className="modal-close-btn" onClick={() => setShow(false)}>
                            <Icon color={Colors.darkest_gray} size={15} icon="x" />
                        </div>
                    </Col>
                </Row>

                <Modal.Body style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 40 }}>

                    <h6 style={{ textAlign: 'justify', marginTop: 20 }}>
                        Agradecemos pela utilização dos nossos produtos e serviços. Ao
                        utilizá-los, o usuário concorda com esses termos.
                    </h6>

                    <div style={{ textAlign: 'justify' }}>
                        <p className="my-3">
                            Este Termos de uso (“Termo”) aplica-se ao uso dos produtos e serviços
                            oferecidos pela PREVENI DESENVOLVIMENTO DE SISTEMAS DE TECNOLOGIA DA
                            INFORMACAO LTDA, CNPJ - 35.502.256/0001-81, proprietária dos
                            softwares, aplicativos, sistemas web, hardwares e produtos oferecidos
                            pela Preveni ou seus parceiros, cujos serviços respeitarão os
                            dispositivos deste Termo, denominada doravante Preveni.
                        </p>

                        <p className="my-3">
                            O usuário pessoa jurídica ou física, capaz e maior, que tenha
                            preenchido o Cadastro, cujos dados e informações passam a ser parte
                            integrante deste Termo, e aceito este Termo ao utilizar qualquer
                            sistema, aplicativo ou produto digital da Preveni, denominado
                            doravante usuário e/ou usuários.
                        </p>

                        <p className="my-3">
                            São regidos por este conteúdo os serviços prestados pelos sistemas,
                            softwares, hardwares, aplicativos, sites e produtos da Preveni.
                        </p>

                        <p className="my-3">
                            Ao dar procedimento no cadastro de forma eletrônica, o usuário estará
                            concordando e se submetendo a eventuais futuras alterações, devendo
                            verificar as atualizações e a aceitar as demais políticas dos
                            sistemas, produtos e serviços da Preveni.
                        </p>

                        <p className="my-3">
                            A Preveni elaborou conteúdo, próprios ou através de parceiros, de seus
                            sistemas para utilização e informação. Essa informação não é destinada
                            a substituir ou modificar a orientação de seu enfermeiro ou médico ou
                            prestador de serviço de saúde. Por favor, consulte um profissional de
                            saúde qualificado para orientações acerca de sua condição médica
                            específica ou de seus pacientes. A informação e conteúdo, na ausência
                            de contato com o seu profissional de saúde, deve ser considerado
                            apenas como um serviço educativo/informativo e não é destinado a
                            substituir a análise independente de um enfermeiro ou médico ou outro
                            profissional de saúde sobre os riscos de uma condição ou procedimentos
                            para o paciente.
                        </p>

                        <p className="my-3">
                            Todo o conteúdo e informações publicados nos materiais da Preveni é de
                            responsabilidade do autor ou usuário que criou o conteúdo. Eventuais
                            publicações nos materiais por uma parte não implica em aprovação de
                            conteúdo, produtos ou serviço de nenhuma outra parte.
                        </p>

                        <p className="my-3">
                            Os sistemas da Preveni poderão ser utilizados como um canal de
                            comunicação aberto com a comunidade de profissionais de saúde,
                            permitindo aos profissionais realizar questionamentos sobre
                            especificações de protocolos, procedimentos e metodologias.
                        </p>

                        <p className="my-3">
                            É disponibilizado a reunião de informações de boas práticas
                            profissionais, disponíveis na comunidade e do conhecimento adquirido
                            dos usuários dos sistemas, que poderão informar sobre procedimentos,
                            metodologias e experiências, para troca de informações entre os
                            profissionais de saúde. Portanto, os usuários devem estar cientes que
                            qualquer alteração realizada nos procedimentos apresentadas nos
                            sistemas é de sua exclusiva responsabilidade, isentando a Preveni de
                            qualquer ressarcimento ou responsabilidade ao pretendente por
                            quaisquer danos e/ou perdas pela falta de diligência quanto ao uso do
                            conteúdo disponibilizado nos sistemas. O usuário dos sistemas da
                            Preveni concorda e declara que todo o conteúdo compartilhado ou
                            publicado pelo usuário nos sistemas é de sua exclusiva
                            responsabilidade, não possuindo a Preveni qualquer responsabilidade
                            sobre o mesmo.
                        </p>

                        <p className="my-3">
                            Se for verificado que qualquer informação disponibilizada nos sistemas
                            não está correta ou atualizada, deverá solicitar que seja corrigido
                            para a Preveni, através do e-mail contato@preveni.com.br, que será
                            verificado em até 24 (vinte e quatro) horas úteis, a contar do
                            recebimento do e-mail.
                        </p>

                        <p className="my-3">
                            Se os usuários dos sistemas desejam reclamar sobre qualquer
                            comportamento inaceitável ou alguma inveracidade no conteúdo, os
                            usuários devem enviar sua denúncia através dos canais de comunicação
                            da Preveni.
                        </p>

                        <p className="my-3">
                            O login e a senha que dão acesso aos usuários nos sistemas da Preveni
                            são de uso intransferível, particular e pessoal do usuário, portanto,
                            a Preveni não se responsabiliza por eventual utilização indevida ou
                            não autorizada das informações por terceiros, logo os usuários
                            precisam considerar todas as medidas seguras e necessárias para manter
                            em cuidado e sigilo o seu acesso e as referidas informações.
                        </p>

                        <p className="my-3">
                            Caso ocorra ou o usuário suspeite de qualquer uso não permitido ou não
                            autorizado de sua conta, bem como, o acesso não autorizado por
                            terceiros à mesma, o usuário deve avisar imediatamente a Preveni, para
                            bloqueio de acesso da sua conta. O usuário é o único responsável pelas
                            operações realizados em sua conta, pois o acesso a mesma só é possível
                            através da senha, cujo conhecimento é exclusivo do usuário.
                        </p>

                        <p className="my-3">
                            A Preveni poderá notificar, advertir, suspender, bloquear ou cancelar,
                            de forma temporária ou definitiva o usuário, em qualquer momento,
                            iniciando possíveis ações legais e/ou suspendendo a prestação dos
                            serviços se o usuário não atender este Termo e demais normas dos
                            sistemas ou se alguma informação fornecida por ele estiver incorreta.
                        </p>

                        <p className="my-3">
                            O usuário poderá ter apenas um único cadastro sob sua titularidade,
                            não sendo permitido, em hipótese alguma, o login e/ou cadastro
                            simultâneos de titularidade do mesmo usuário ou a transferência/cessão
                            da sua conta. Poderá ser verificado, o nome do usuário, número de
                            registro em conselho de classe profissional, CPF, CNPJ, IP, endereço
                            de e-mail, telefone fixo ou móvel, entre outros dados. Se o usuário
                            trocar de dado ou precise atualizar qualquer informação do seu
                            cadastro, deverá atualizar seu cadastro no sistema.
                        </p>

                        <p className="my-3">
                            Se o usuário mudar o equipamento de acesso aos sistemas, deve sair do
                            seu login (deslogar) naquele equipamento e acessar novamente o seu
                            cadastro, através de login e senha, no outro equipamento que deseja
                            utilizar para acesso aos sistemas.
                        </p>

                        <p className="my-3">
                            Os nomes, logins ou apelidos que o usuário utiliza nos sistemas não
                            poderão serem semelhantes ou iguais com o nome “preveni ou Preveni”.
                            Também serão suspensos ou banidos nomes, logins ou apelidos
                            considerados ofensivos, bem como os que contenham dados pessoais do
                            usuário ou alguma URL ou endereço eletrônico.
                        </p>

                        <p className="my-3">
                            A Preveni não será responsabilizada por falhas, erros, interrupções,
                            atrasos ou outras imperfeições que possam ocorrer nos sistemas e/ou
                            serviços, que são possíveis de acontecer em se tratando de sistemas
                            digitais computacionais e em informática, não garantindo, a Preveni,
                            de nenhuma forma, a prestação de serviço de forma ininterrupta e/ou
                            isenta de erros. Caso alguma possível falha acontece, estaremos
                            disponíveis nos canais de comunicação para solução da questão o mais
                            rápido possível.
                        </p>

                        <p className="my-3">
                            O usuário será responsável por certificar-se que o seu equipamento
                            está de acordo com os requisitos mínimos de segurança, como antivírus
                            atualizados e demais recursos de segurança, para evitar acessos não
                            autorizados ou perda de dados. A Preveni estará isenta de qualquer
                            responsabilidade decorrente da não observância do disposto nos itens
                            deste Termo.
                        </p>

                        <p className="my-3">
                            Em razão do uso dos sistemas da Preveni como usuário premium, o
                            usuário pagará no período de recorrência disponível, antecipadamente,
                            por meio de pagamento online disponibilizado, o valor previsto na
                            página de planos ou conforme proposta comercial.
                        </p>

                        <p className="my-3">
                            Quando uma assinatura recorrente é contratada, o vencimento ocorrerá
                            no próximo período (aniversário) da data da contratação, realizada no
                            momento do cadastro. Após o período finalizado da primeira
                            recorrência, o usuário confirma, mediante aceite deste termo, a
                            renovação automática do seu cadastro.
                        </p>

                        <p className="my-3">
                            Para o usuário que tenha contratado um plano com desconto promocional,
                            no primeiro aniversário da data de contratação, os valores serão
                            renovados, de forma automática, nos valores vigentes do plano premium,
                            pois a recorrência com desconto só é válida pelo prazo informado. Se o
                            usuário não desejar a renovação automaticamente, ele deverá solicitar
                            o cancelamento antes do prazo. Se este cancelamento não for efetuado,
                            o usuário concorda com a renovação automática dos valores nos planos
                            vigentes.
                        </p>

                        <p className="my-3">
                            Se o usuário não desejar a renovação automaticamente, deverá comunicar
                            através dos canais de comunicação da Preveni ou diretamente no seu
                            cadastro, a não renovação, que será efetuado em até 48 (quarenta e
                            oito) horas úteis a contar do pedido. Se a contratação dos sistemas
                            ocorrerem em lojas de aplicativos, como Apple App Store® e Google Play
                            Store®, o cancelamento apenas será efetuado se o usuário cancelar
                            diretamente na própria plataforma da qual realizou a compra, em razão
                            das regras da própria plataforma, não existindo neste caso a opção de
                            cancelamento mediante os canais de comunicação da Preveni.
                        </p>

                        <p className="my-3">
                            Se o usuário fizer a escolha de contratação por um plano superior ao
                            período mensal, para usufruir de eventuais descontos, em caso de
                            cancelamento antes do aniversário da contratação, ou seja, antes do
                            período contratado, os valores já pagos não serão reembolsados, mesmo
                            que de maneira pro rata.
                        </p>

                        <p className="my-3">
                            Ao cancelar o seu contrato ou cadastro, o usuário terá o prazo de 45
                            (quarenta e cinco) dias a contar da data do cancelamento para exportar
                            os seus dados. Após este período, não será mais garantido a
                            disponibilidade dos dados. Informamos que o usuário deve manter um
                            backup/cópia de segurança em local protegido destes arquivos
                            independente dos produtos e/ou serviços fornecidos pelos sistemas da
                            Preveni.
                        </p>

                        <p className="my-3">
                            A Preveni poderá rescindir este Termo ou bloquear o acesso ao cadastro
                            e sistemas contratados, se o usuário estiver inadimplente por mais de
                            7 (sete) dias úteis.
                        </p>

                        <p className="my-3">
                            Os valores serão reajustados de forma automática a cada 12 (doze)
                            meses, contados da contratação dos serviços, de acordo com o índice
                            IGP-M ou com o índice que vier a substituí-lo, independentemente de
                            prévia notificação aos usuários.
                        </p>

                        <p className="my-3">
                            Se ocorrer a criação de nova tributação ou alteração dos tributos
                            existentes, que impactem os valores de remuneração vigentes, os custos
                            adicionais resultantes poderão ser repassados ao usuário e somados aos
                            valores vigentes, para restabelecer o equilíbrio econômico-financeiro
                            da prestação dos serviços.
                        </p>

                        <p className="my-3">
                            Se durante o Período de Teste, de 7 dias, conforme legislação
                            aplicável, o usuário não cancelar a contratação do plano, o usuário
                            renuncia ao seu direito de arrependimento.
                        </p>

                        <p className="my-3">
                            As condições deste Termo podem ser alteradas a qualquer momento, para
                            melhoria dos serviços prestados da Preveni. As novas condições
                            entrarão em vigor 10 (dez) dias após a publicação no site ou
                            aplicativo ou demais sistemas da Preveni. Se o usuário não concordar
                            com as alterações ou atualizações, fica definido o prazo de 5 (cinco)
                            dias, contados a partir da publicação, para informar ao e-mail da
                            Preveni (contato@preveni.com.br) a sua não concordância e solicitar o
                            cancelamento do seu cadastro. Se não existir manifestação no prazo
                            acima, o usuário aceita de forma tácita todas as condições deste
                            Termo.
                        </p>

                        <p className="my-3">
                            Se for verificado que o usuário esteja violando este Termo, normativos
                            legais, regimentos, a ética, aos bons costumes ou praticando atividade
                            ilícita, além do cancelamento, suspensão ou bloqueio do seu cadastro,
                            os dados do usuário infrator poderão ser encaminhados às autoridades
                            correspondentes.
                        </p>
            
                        <p className="my-3">
                            Os sistemas da Preveni poderão utilizar, receber ou enviar os
                            “cookies” (dados do computador, tablet, celular ou outro dispositivo
                            do usuário) para possibilitar a identificação e melhorar a qualidade
                            das informações oferecidas. Se o usuário não desejar receber cookies
                            ou preferir uma notificação prévia antes de seu uso, deverá programar
                            no seu navegador da web estas opções.
                        </p>
            
                        <p className="my-3">
                            Além dos dados fornecidos, e que podem ser utilizados, de forma
                            anonimizada, respeitando a privacidade do usuário e paciente, para
                            fins de pesquisa e desenvolvimento na melhoria dos serviços prestados
                            aos usuários, os sistemas poderão recolher outras informações
                            técnicas, como o endereço de protocolo de internet, o sistema
                            operacional do equipamento, o tipo de navegador, e outros dados
                            relevantes ao funcionamento dos serviços, sempre respeitando a
                            privacidade e a segurança do usuário Marco Civil da Internet, Lei
                            Geral de Proteção de Dados e demais legislação pertinente.
                        </p>
            
                        <p className="my-3">
                            Informamos que os sistemas da Preveni não fornecerão os dados do
                            usuário a terceiros sem prévia autorização do mesmo ou ocorrerão
                            quando são necessários para entidades governamentais, via solicitações
                            judiciais.
                        </p>
            
                        <p className="my-3">
                            Os dados armazenados nos bancos de dados poderão ser utilizados, sem
                            exceder os requisitos ou limitações legais, para dirimir eventuais
                            disputas, solucionar problemas e garantir os direitos da Preveni,
                            assim como os termos e condições presentes na política de privacidade.
                        </p>

                        <p className="my-3">
                            Os dados cedidos pelo usuário e registrados em razão do uso do
                            sistema, exceto ao conteúdo de mensagens particulares, poderão ser
                            utilizados para o mapeamento de informações de mercado e geração de
                            estatísticas. Pelo cadastro, uso e fornecimento dos dados aos sistemas
                            da Preveni, o usuário de forma deliberada aceita este Termo e as
                            condições previstas nas políticas de privacidade sobre o uso dos
                            dados.
                        </p>
            
                        <p className="my-3">
                            Para cancelar seu cadastro ou seus dados nos sistemas da Preveni, o
                            usuário poderá realizar através de solicitação aos administradores
                            pelo e-mail (contato@preveni.com.br). A Preveni ainda manterá dados,
                            única e exclusivamente para atender eventuais exigências legais a que
                            está submetida, e as fornecerá somente sob ordem judicial.
                        </p>
                
                        <p className="my-3">
                            É proibido a utilização de dispositivo, software ou outro recurso para
                            interferir nas atividades dos sistemas da Preveni, nas contas ou
                            bancos de dados. Se houver eventual invasão, intromissão, tentativa ou
                            atividade que viole ou contrarie as leis de direito de propriedade
                            intelectual e/ou as proibições estipuladas neste Termo, que efetuem
                            cópias não autorizadas, engenharia reversa, e/ou alterações que possam
                            criar obras derivadas desta, o responsável será passível das ações
                            legais pertinentes e das sanções aqui previstas, sendo responsável
                            pelas indenizações por eventuais danos causados.
                        </p>
            
                        <p className="my-3">
                            Se existir dúvida sobre a proteção a dados pessoais e/ou para
                            conseguir mais informações sobre os dados pessoais, favor entrar em
                            contato pelo email contato@preveni.com.br.
                        </p>
            
                        <p className="my-3">
                            É possível a navegação pelos sistemas da Preveni sem a cobrança de
                            acesso para as seções gratuitas, outras seções poderão ser acessadas
                            ao contratar um plano.
                        </p>
                
                        <p className="my-3">
                            A Preveni não se responsabiliza pela veracidade, legitimidade ou
                            existência dos dados cadastrados ou registrados pelos usuários,
                            profissionais ou pacientes em seus sistemas, sendo que estes dados são
                            inseridos por sua própria conta e risco.
                        </p>
            
                        <p className="my-3">
                            A Preveni não será responsável por qualquer prejuízo, dano ou perda
                            nos equipamentos dos usuários ocasionado por eventuais falhas na
                            internet, nos sistemas ou nos servidores utilizados, decorrente de
                            condutas de terceiros, caso fortuito ou força maior. A Preveni também
                            não se responsabiliza por eventuais vírus ou similares que possam
                            prejudicar os equipamentos dos usuários em decorrência do acesso ou
                            utilização dos sistemas. Os usuários não poderão atribuir à Preveni
                            nenhuma responsabilidade e/ou exigir eventuais pagamentos por lucro
                            cessante e/ou custos e/ou despesas diretas e indiretas, em razão de
                            eventuais prejuízos resultantes de dificuldades técnicas ou falhas nos
                            sistemas ou na internet. Ocasionalmente, os sistemas poderão estar
                            indisponíveis por falhas de conectividade ou por motivos técnicos ou
                            por eventos fortuitos e força maior, alheio aos controles da Preveni.
                            Por estes motivos, reforçamos a utilização de métodos seguros e
                            antivírus atualizados e confiáveis para utilização dos sistemas.
                        </p>
                
                        <p className="my-3">
                            Em razão dos sistemas e produtos da Preveni serem ferramentas de
                            registro, controle, acesso, organização, manejo de dados,
                            automatização de eventuais processos, as responsabilidades por todas
                            as obrigações que recaiam nas atividades dos usuários, sejam fiscais,
                            tributárias, trabalhistas ou de qualquer outra natureza, serão
                            exclusivamente dos usuários e/ou das pessoas jurídicas a ela
                            vinculadas. Em eventual interpelação judicial que tenha como ré a
                            Preveni, cujos fatos fundem-se em ações do usuário e/ou da pessoa
                            jurídica a ela vinculada, estes serão chamados ao processo devendo
                            arcar com todos os ônus que daí decorram, nos termos do Código de
                            Processo Civil.
                        </p>
            
                        <p className="my-3">
                            Este Termos de Uso não criará ou irá gerar nenhum contrato de
                            sociedade, franquia ou relação de trabalho entre a Preveni e os
                            usuários.
                        </p>
            
                        <p className="my-3">
                            Algumas seções dos sistemas permitem a publicação de conteúdos,
                            comentários, sugestões, recomendações e avaliações dos usuários.
                            Portanto, todo usuário será o único responsável pelo que publicar,
                            isentando, desde já, a Preveni de qualquer dano causado em decorrência
                            destas publicações.
                        </p>
                
                        <p className="my-3">
                            O usuário se compromete a não divulgar ou publicar ou comentar
                            conteúdos que possam provocar dano material, pessoal, perda, prejuízo
                            físico, emocional, mental, morte, para qualquer pessoa, a atividade
                            ilícita ou a sua apologia, formas discriminatórias, racistas,
                            difamatórias, invasivas da privacidade alheia, caluniosas,
                            humilhantes, dentre outros tipos de assédio, que contenham informações
                            ilegais ou que violem a propriedade intelectual de terceiros.
                        </p>
                
                        <p className="my-3">
                            O presente Termos de Uso possui prazo de vigência indeterminado, está
                            em vigor a partir da data do aceite eletrônico do usuário no momento
                            do seu cadastro ou da contratação e/ou serviços da Preveni. Poderá ser
                            rescindido a prestação dos serviços dos sistemas, objeto do presente
                            Termo, a qualquer momento, através prévia comunicação expressa à outra
                            parte no prazo de 7 (sete) dias úteis, com a eventual indenização e
                            quitação, decorrente de terceiros, por conta do má utilização dos
                            serviços pelo usuário.
                        </p>
                
                        <p className="my-3">
                            Este Termo poderá ser rescindido, independentemente de qualquer
                            notificação ou interpelação, judicial ou extrajudicial, quando ocorrer
                            a quebra de qualquer uma das disposições do presente Termo, por
                            qualquer das partes, que impeça a continuidade da execução dos
                            serviços ou sistemas; se os usuários comprometerem a imagem pública da
                            Preveni e/ou de seus parceiros associados nesta prestação dos
                            serviços, e/ou se os usuários utilizarem de atividades ilegais, contra
                            a ordem pública, a moral, os bons costumes, e/ou a política de
                            segurança e privacidade.
                        </p>
                
                        <p className="my-3">
                            O uso do termo Preveni como marca, nome empresarial e/ou nome de
                            domínio, os conteúdos dos sistemas, aplicativos, telas dos serviços
                            disponíveis, programas web, hardwares, wearables, bancos de dados,
                            redes e arquivos, que permitem que os usuários acessem e utilizando
                            sua conta e cadastro, são de propriedade da Preveni ou de seus
                            parceiros e estão protegidos pelas leis e tratados nacionais e
                            internacionais de direito autoral, marcas, patentes, modelos, desenhos
                            industriais e programas de computadores ou outros equipamentos
                            eletrônicos. O uso indevido e a reprodução parcial ou total dos
                            conteúdos não são permitidos, exceto quando expressamente autorizado
                            pela Preveni.
                        </p>
                
                        <p className="my-3">
                            Os sistemas da Preveni poderão, eventualmente, utilizar links ou
                            materiais publicados em outros sites da internet, o que significa que
                            esses sites ou materiais não são de propriedade ou operados pela
                            Preveni. Como não há controle sobre esses sites e/ou materiais, a
                            Preveni não é responsável pelos conteúdos, práticas e/ou serviços
                            ofertados nos mesmos. A eventual presença de links para outros sites
                            e/ou de materiais não implica relação de sociedade, de supervisão, de
                            cumplicidade, de parceria, relação trabalhista ou solidariedade da
                            Preveni para com esses sites e seus conteúdos. Se os responsáveis
                            pelos sites e/ou materiais desejarem que seja removido o conteúdo,
                            favor entrar em contato com os canais de comunicação da Preveni para
                            que os mesmos sejam removidos.
                        </p>
                                
                        <p className="my-3">
                            Os usuários indenização a Preveni, seus parceiros, administradores,
                            colaboradores, representantes e/ou fornecedores por eventuais demandas
                            promovida por terceiros decorrentes de suas atividades nos sistemas,
                            por descumprimento deste Termo e demais políticas da Preveni, pela
                            violação de qualquer lei ou direitos de terceiros, incluindo
                            honorários de advogados.
                        </p>
                
                        <p className="my-3">
                            Os usuários cadastrados que utilizem os sistemas da Preveni
                            expressamente aceitam que a Preveni e/ou seus parceiros,
                            eventualmente, enviem avisos e/ou mensagens de e-mail, referentes a
                            comunicações, informativas, educativas ou de natureza comercial, como
                            ofertas dos parceiros da Preveni, novidades nos sistemas, serviços,
                            entre outras informações e conteúdos.
                        </p>
            
                        <p className="my-3">
                            Com o objetivo de manter e atender os requisitos mais atualizados de
                            tecnologia e mercado, a Preveni poderá a seu critério, modificar, a
                            forma, conteúdo, suspender, bloquear ou cancelar, a qualquer momento,
                            quaisquer dos serviços, produtos, utilidades, aplicações e/ou
                            recursos, disponibilizados por si ou por terceiros, mediante prévio
                            aviso aos usuários, inclusive com relação aos serviços disponíveis nos
                            sistemas.
                        </p>
                
                        <p className="my-3">
                            A tolerância da Preveni com os usuários, quanto ao eventual
                            descumprimento de qualquer das obrigações ora assumidas, não será
                            considerada novação ou renúncia a qualquer direito, constituindo mera
                            liberalidade, que não impedirá a Preveni de exigir posteriormente o
                            fiel cumprimento das condições previstas neste Termo, a qualquer
                            momento.
                        </p>
                
                        <p className="my-3">
                            Os usuários não poderão ceder o seu cadastro, dados ou acesso para
                            terceiros ou outros usuários. A Preveni poderá, eventualmente e
                            independentemente de qualquer aviso ou notificação, ceder os serviços
                            prestados pelos sistemas, no todo ou em parte, a empresas parceiras ou
                            de seu grupo econômico, a qualquer tempo.
                        </p>
                
                        <p className="my-3">
                            Ao aceitar este Termo, os usuários autorizam, desde já e de forma
                            expressa, o uso gratuito de sua imagem, voz, nome e/ou quaisquer
                            publicações do usuário, em locais públicos dos sistemas da Preveni, em
                            todos os materiais, publicações e/ou peças e formas de divulgação de
                            campanhas informativas e/ou publicitárias da Preveni, com ou sem
                            indicação de seu nome.
                        </p>
                
                        <p className="my-3">
                            Os usuários declaram e concordam de forma expressa que são maiores de
                            18 anos e/ou possuem capacidade jurídica ou autorização para utilizar
                            ou contratar os serviços dos sistemas, que possuem e mantêm
                            atualizados programas antivírus, similares e outros que impeçam a
                            violação do sistema que é utilizado para ter acesso aos serviços e que
                            mantenham as informações seguras e em sigilo de sua senha da conta
                            cadastrada, não os fornecendo a terceiros. O usuário deverá avisar a
                            Preveni caso tome ciência de qualquer violação de segurança
                            relacionada aos serviços disponibilizados pelos sistemas ou uso não
                            autorizado de seu nome de usuário e senha, que há somente um login e
                            cadastro sob sua titularidade, e não o cederá para terceiros, sob pena
                            de suspensão e/ou cancelamento de seu cadastro nos sistemas, que têm
                            condições financeiras de arcar com os pagamentos, custos e despesas
                            decorrentes do presente Termo, que reconhecem que o presente Termo se
                            formaliza, vinculando as partes, com a sua aceitação eletrônica pelo
                            usuário, o que se fará mediante a continuidade no seu cadastro e que
                            leu e está ciente e de pleno acordo com todos os termos e condições do
                            presente, razão pela qual o aceita de livre e espontânea vontade.
                        </p>
                
                        <p className="my-3">
                            O usuário concorda integralmente com as disposições deste Termo, se
                            comprometendo a respeitar as condições aqui previstas.
                        </p>
                
                        <p className="my-3">
                            Todos os itens deste Termo são regidos pelas leis vigentes. Para
                            assuntos referentes à sua interpretação e cumprimento, as partes se
                            submeterão ao Foro da Comarca de Londrina, Paraná. Para eventuais
                            questões apresentadas pelos usuários consumidores, poderão se submeter
                            ao foro de seu domicílio.
                        </p>
                
                        <p className="my-3">
                            A Preveni se reserva o direito de modificar este Termo quando
                            necessário, a qualquer momento e as alterações entrarão em vigor após
                            a publicação dos termos de uso modificados.
                        </p>
                
                        <p className="my-3">
                            Se ocorrer eventuais alterações nestes termos de uso, o usuário será
                            notificado pelo e-mail cadastrado. Recomendamos os usuários a
                            revisarem periodicamente este Termo para saber as informações mais
                            atualizadas. Se o usuário não aceitar os termos deste documento, a
                            Preveni informa para que este não se cadastre e não use os sistemas,
                            produtos e/ou serviços da Preveni. Ao continuar a usar os sistemas,
                            produtos e serviços após as eventuais modificações realizadas para
                            este Termos, o usuário concorda com tais mudanças de forma tácita.
                        </p>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
    
}

export default ModalTermos;
