import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";

import VerifyPw from "../../../../../components/Modals/VerifyPw";
import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import moment from "moment";
import CheckBox from "../../../../../components/CheckBox";
import {
  userHasGeneralPermissions,
  userWithoutPermission,
  userWithoutPermissionIncludingNurse,
} from "../../../../../functions/utils";

const icon_arrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <g id="Camada_2" data-name="Camada 2">
      <g id="Camada_1-2" data-name="Camada 1">
        <g id="Grupo_228" data-name="Grupo 228">
          <rect fill="none" width="24" height="24" x={0} />
          <path
            fill={Colors.dark_gray}
            d="M20.29,7.5h0a1,1,0,0,0-1.41,0L13,13.38l-1,1-1-1L5.12,7.5a1,1,0,0,0-1.41,0h0a1,1,0,0,0,0,1.41l7.58,7.59a1,1,0,0,0,1.42,0h0l7.58-7.59A1,1,0,0,0,20.29,7.5Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

function Prescricao() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState("none");
  const [listPrescriptions, setListPrescriptions] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [lastAvaliation, setLastAvaliation] = useState({});
  const [checkAll, setCheckAll] = useState(false);

  const [listUsers, setListUsers] = useState([]);
  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });
  const [showPw, setShowPw] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    function LoadAvaliations() {
      api
        .get(`patients/${patientSelected.dados.id}/avaliations`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadAvaliations success");
            console.log(response.data);
            if (response.data.length > 0) setLastAvaliation(response.data[0]);
          }
        })
        .catch((error) => {
          console.log("LoadAvaliations error " + error);
        });
    }

    LoadAvaliations();
  }, []);

  useEffect(() => {
    //Se permission_id for 1 ou 2 seta quem ta logado direto no responsavel
    if (userHasGeneralPermissions(user)) {
      setUserChooser({ label: user.dados.name, value: user.dados.id });
    }
  }, []);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2",
            unit_id: patientSelected.dados.unit_id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (userWithoutPermission(user)) {
      LoadUsers();
    }
  }, []);

  useEffect(() => {
    if (listPrescriptions.length > 0 && prescriptions.length > 0) {
      VerifyCheck();
    }
  }, [listPrescriptions, prescriptions]);

  function VerifyCheck() {
    var qtd = 0;
    listPrescriptions.map((item) => {
      qtd += item.preventives.length;
    });
    if (qtd == prescriptions.length) {
      setCheckAll(true);
    }
  }

  useEffect(() => {
    function LoadPrescriptions() {
      api
        .get(`/prescriptions`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPrescriptions success");
            console.log(response.data);

            var newList = response.data.map((item) => {
              item.show = true;
              return item;
            });
            setListPrescriptions(newList);
          }
        })
        .catch((error) => {
          console.log("LoadPrescriptions error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de prescriçao",
            })
          );
        });
    }

    LoadPrescriptions();
  }, []);

  useEffect(() => {
    function LoadPrescriptionsPatient() {
      api
        .get(`patients/${patientSelected.dados.id}/prescriptions?checklist=1`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadPrescriptionsPatient success");
            console.log(response.data);

            setPrescriptions(response.data);

            if (response.data.length > 0) {
              var obj = {
                name: response.data[0].user.name,
                image: response.data[0].user.image,
                url_image: response.data[0].user.url_image,
                updated_at: response.data[0].updated_at,
              };
              setUserInfo(obj);
            }
          }
        })
        .catch((error) => {
          console.log("LoadPrescriptionsPatient error " + error);
        })
        .finally(() => setLoading(false));
    }

    LoadPrescriptionsPatient();
  }, []);

  function SetarPrescription(obj) {
    if (userWithoutPermissionIncludingNurse(user)) return false;

    var newObj = {
      preventive_id: obj.id,
      value: obj.value,
      is_checked: 1,
    };

    var found = prescriptions.find(
      (item) => item.preventive_id == newObj.preventive_id
    );

    if (found != undefined) {
      var newList = prescriptions.filter(
        (item) => item.preventive_id != newObj.preventive_id
      );
      setCheckAll(false);
      setPrescriptions(newList);
    } else {
      setPrescriptions([...prescriptions, newObj]);
    }
  }

  function CheckAll() {
    if (userWithoutPermissionIncludingNurse(user)) return false;

    var array = [];
    if (!checkAll) {
      listPrescriptions.map((item) => {
        item.preventives.map((i) => {
          array.push({
            preventive_id: i.id,
            value: i.value,
            is_checked: 1,
          });
        });
      });
    }

    setCheckAll(!checkAll);
    setPrescriptions(array);
  }

  function VerifyFields() {
    var ret = false;
    if (
      prescriptions.length > 0 &&
      userChooser.value > 0 &&
      userWithoutPermissionIncludingNurse(user) != 3
    )
      ret = true;
    return ret;
  }

  function RegisterPrescription() {
    var arrayPrescription = [];
    prescriptions.map((item) => {
      arrayPrescription.push({
        preventive_id: item.preventive_id,
        value: item.value,
        is_checked: 1,
      });
    });

    listPrescriptions.map((item) => {
      var found = prescriptions.find((i) => i.id == item.id);
      if (found == undefined) {
        arrayPrescription.push({
          preventive_id: item.id,
          value: item.value,
          is_checked: 0,
        });
      }
    });

    setLoadingBtn("flex");
    api
      .post(`/patients/${patientSelected.dados.id}/prescriptions`, {
        patient_avaliation_id:
          lastAvaliation.id != undefined ? lastAvaliation.id : "",
        user_id: userChooser.value,
        prescriptions: arrayPrescription,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("RegisterPrescription success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "A prescrição foi salva",
            })
          );

          //Apos salvar manda pra tab 'Relogio de decubito'
          dispatch({ type: "SET_PATIENT_TAB", selected: 4 });
        }
      })
      .catch((error) => {
        console.log("RegisterPrescription error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar a prescrição.",
          })
        );
      })
      .finally(() => setLoadingBtn("none"));
  }

  return (
    <div>
      {showPw && (
        <VerifyPw
          handler_show_pw={setShowPw}
          show_pw={showPw}
          handler_user={setUserChooser}
          id_user={userChooser.value}
        />
      )}

      <h2 className="screen-name mb-4">Prescrição</h2>

      {userWithoutPermission(user) == 4 && (
        <div className="mb-4">
          <Form.Label className="field-label">Responsável</Form.Label>
          <Select
            captureMenuScroll={false}
            classNamePrefix="react-select"
            placeholder={"Selecionar"}
            options={listUsers}
            isClearable={false}
            value={userChooser}
            onChange={(item) => {
              setUserChooser(item);
              setShowPw(true);
            }}
          />
        </div>
      )}

      <div className="mb-4">
        <div className="d-flex justify-content-between">
          <p className="screen-subtitle">
            Selecione as medidas preventivas que serão utilizadas no Checklist
          </p>
          <CheckBox
            disabled={userWithoutPermissionIncludingNurse(user) ? true : false}
            checked={checkAll}
            onclick={CheckAll}
          />
        </div>
      </div>

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && (
        <div>
          {listPrescriptions.map((i) => {
            return (
              <div key={i.id} className="mb-4">
                <div
                  onClick={() => {
                    var newList = listPrescriptions.map((a) => {
                      if (a.id == i.id) {
                        a.show = !a.show;
                      }
                      return a;
                    });
                    setListPrescriptions(newList);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  className="d-flex justify-content-between align-items-center mb-2 py-1"
                >
                  <p className="screen-subtitle">{i.name}</p>
                  <div
                    style={{ marginRight: 3 }}
                    className={i.show && "arrow-rotate"}
                  >
                    {icon_arrow}
                  </div>
                </div>
                {i.show && (
                  <div>
                    {i.preventives.map((item, index) => {
                      var found = prescriptions.find(
                        (i) => i.preventive_id == item.id
                      );

                      return (
                        <div
                          key={index}
                          className={
                            userWithoutPermissionIncludingNurse(user) == 3
                              ? "modal-item-no-hover"
                              : "modal-item"
                          }
                          onClick={() => SetarPrescription(item)}
                        >
                          <p
                            style={{
                              width: "90%",
                              color:
                                found != undefined
                                  ? Colors.brand_blue
                                  : Colors.darkest_gray,
                            }}
                            className="modal-name mb-0"
                          >
                            {item.value}
                          </p>

                          <CheckBox
                            disabled={
                              userWithoutPermissionIncludingNurse(user)
                                ? true
                                : false
                            }
                            checked={found != undefined}
                            value={item}
                            onclick={SetarPrescription}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}

          {userInfo.name != undefined && (
            <div
              className="mt-2"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: 40, height: 40, borderRadius: "100%" }}
                src={
                  userInfo.image != null
                    ? userInfo.url_image
                    : require("../../../../../assets/img/icon_user.png")
                }
              />
              <div className="dados-default">
                <div className="name-default">
                  Última modificação por {userInfo.name}
                </div>
                <div className="date-default">
                  {moment(userInfo.updated_at).format("LLL")}
                </div>
              </div>
            </div>
          )}

          <div className="mt-5">
            <button
              onClick={() => RegisterPrescription()}
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="modal-footer-btn btn-confirm float-right"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
                width: 190,
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyFields() == true ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Salvar prescrição
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Prescricao;
