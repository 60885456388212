import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import "./styles.css";
import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import Icon from "../../../functions/Icon";
import Colors from "../../../styles/Colors";
import {
  userHasPermission,
  userWithoutPermission,
} from "../../../functions/utils";
import moment from "moment";
import VerifyPw from "../../../components/Modals/VerifyPw";

function ModalDouble({ ...props }) {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const { handler_show_modal, show, dados, handler_reload, reload } = props;

  const [loadingBtn, setLoadingBtn] = useState("none");
  const [page, setPage] = useState(1);
  const [motiveChooser, setMotiveChooser] = useState({ id: 0 });
  const [otherMotive, setOtherMotive] = useState("");
  const [listMotives, setListMotives] = useState([]);
  const [listUsers, setListUsers] = useState([]);

  const [userChooser, setUserChooser] = useState({
    label: "Selecione",
    value: 0,
  });
  const [showPw, setShowPw] = useState(false);

  useEffect(() => {
    if (userHasPermission(user)) {
      setUserChooser({ label: user.dados.name, value: user.dados.id });
    }
  }, []);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: unit.selecionado.id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    LoadUsers();
  }, []);

  useEffect(() => {
    function LoadMotives() {
      api
        .get(`/motives`, {
          params: {
            is_suspend: true,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadMotives success");
            console.log(response.data);
            setListMotives(response.data);
          }
        })
        .catch((error) => console.log("LoadMotives " + error));
    }

    LoadMotives();
  }, []);

  function RegisterDouble() {
    setLoadingBtn("flex");

    api
      .put(`/doublecheck/${dados.notification_id}`, {
        datetime_realized: moment().format("YYYY-MM-DD HH:mm:ss"),
        nurse_id: userChooser.value,
        motive_id: motiveChooser.id > 0 ? motiveChooser.id : "",
        other_motive: otherMotive,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("RegisterDouble success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "Dupla checagem confirmada",
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("RegisterDouble error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar essa alteração",
          })
        );
      })
      .finally(() => {
        ResetFields();
        setLoadingBtn("none");
        handler_show_modal(false);
      });
  }

  function ResetFields() {
    setUserChooser({ label: "Selecione", value: 0 });
    setMotiveChooser({ id: 0 });
    setOtherMotive("");
  }

  function VerifyFields() {
    var ret = false;

    if (page == 2) {
      if (userChooser.value > 0 && motiveChooser.id > 0) ret = true;
    } else if (page == 3) {
      if (userChooser.value > 0) ret = true;
    }

    return ret;
  }

  function renderNurses() {
    if (dados.nurses.length <= 1) {
      return (
        <Row>
          {dados.nurses.map((item, index) => {
            return (
              <div
                key={index}
                className="mt-3 w-100"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: "100%",
                    }}
                    src={
                      item.user_image != null
                        ? item.user_image
                        : require("../../../assets/img/icon_user.png")
                    }
                  />

                  <div className="dados-default">
                    <div className="name-default">por {item.user_name}</div>
                    <div className="date-default">
                      {moment(dados.datetime_realized).format("LLL")}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Row>
      );
    } else {
      var name_nurses = [];
      dados.nurses.map((item, index) => {
        name_nurses.push(item.user_name);
      });
      return (
        <Row>
          <div
            className="mt-3 w-100"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="d-flex">
                <img
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: "100%",
                    zIndex: 10,
                  }}
                  src={
                    dados.nurses[0].user_image != null
                      ? dados.nurses[0].user_image
                      : require("../../../assets/img/icon_user.png")
                  }
                />
                <div className="container-more-nurses">
                  +{dados.nurses.length - 1}
                </div>
              </div>

              <div className="dados-default">
                <div className="name-default">por {name_nurses.join(", ")}</div>
                <div className="date-default">
                  {moment(dados.datetime_realized).format("LLL")}
                </div>
              </div>
            </div>
          </div>
        </Row>
      );
    }
  }

  return (
    <div>
      {showPw && (
        <VerifyPw
          handler_show_pw={setShowPw}
          show_pw={showPw}
          handler_user={setUserChooser}
          id_user={userChooser.value}
        />
      )}

      <Modal
        show={show}
        onHide={() => handler_show_modal(false)}
        centered
        className="modal-decubito"
      >
        {page === 1 && (
          <Modal.Body
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 5,
              paddingBottom: 15,
            }}
          >
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Col xs={6} md={6} lg={6} className="pl-0">
                <div
                  className="status-tag"
                  style={{
                    backgroundColor: Colors.brand_yellow,
                  }}
                >
                  PENDENTE
                </div>
              </Col>

              <Col
                xs={2}
                md={6}
                lg={6}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_show_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>

            <Row>
              <div className="modal-title-sm">{dados.name}</div>
            </Row>

            <Row>
              <div
                className="modal-text-sm mb-4"
                style={{ color: Colors.dark_gray }}
              >
                {dados.room == null && dados.subroom}
                {dados.room != null && dados.room + " | " + dados.subroom}
              </div>
            </Row>

            <Row className="mb-2 align-items-center">
              <Icon
                color={Colors.brand_blue}
                size={20}
                icon="clock"
                className="mr-2"
              />
              <div
                className="modal-text-sm"
                style={{ color: Colors.darker_gray }}
              >
                {moment(dados.datetime_schedule).format("LT")}
              </div>
            </Row>

            <Row className="mb-4 align-items-center">
              <Icon
                color={Colors.brand_blue}
                size={20}
                icon="refresh"
                className="mr-2"
              />
              <div
                className="modal-text-sm"
                style={{ color: Colors.darker_gray }}
              >
                {dados.position}
              </div>
            </Row>

            {renderNurses()}

            <Row className="mb-3 justify-content-center align-items-center mt-4">
              <div
                className="modal-text-bold"
                style={{ color: Colors.darker_gray }}
              >
                Tudo certo com a mudança de decúbito do paciente?
              </div>
            </Row>

            <Row className="mb-3 justify-content-center align-items-center">
              <Button
                className="btn-modal-footer mr-3"
                style={{ backgroundColor: Colors.brand_red }}
                onClick={() => setPage(2)}
              >
                <Icon color={"#fff"} size={20} icon="x" className="mr-2" />
                Não
              </Button>
              <Button
                className="btn-modal-footer"
                style={{ backgroundColor: Colors.brand_green }}
                onClick={() => {
                  if (userChooser.value == 0) {
                    setPage(3);
                    return false;
                  }
                  RegisterDouble();
                }}
              >
                <Icon color={"#fff"} size={20} icon="check" className="mr-2" />
                Sim
              </Button>
            </Row>
          </Modal.Body>
        )}

        {page === 2 && (
          <>
            <Modal.Body
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 5,
                paddingBottom: 15,
              }}
            >
              <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col xs={8} md={10} lg={10} className="pl-0">
                    <div className="modal-title">
                      Por que não foi realizada?
                    </div>
                  </Col>
                  <Col
                    xs={4}
                    md={2}
                    lg={2}
                    className="pr-0 d-flex justify-content-end"
                  >
                    <div
                      className="modal-close-btn"
                      onClick={() => handler_show_modal(false)}
                    >
                      <Icon color={Colors.darkest_gray} size={15} icon="x" />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="modal-list">
                {userWithoutPermission(user) && (
                  <div className="mb-4 px-3">
                    <Form.Label className="field-label">Responsável</Form.Label>
                    <Select
                      captureMenuScroll={false}
                      classNamePrefix="react-select"
                      placeholder={"Selecionar"}
                      options={listUsers}
                      isClearable={false}
                      value={userChooser}
                      onChange={(item) => {
                        setUserChooser(item);
                        setShowPw(true);
                      }}
                    />
                  </div>
                )}

                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <Row
                    className="mt-3 mb-1 modal-text-bold-sm"
                    style={{ color: Colors.darkest_gray }}
                  >
                    Motivo
                  </Row>
                </div>

                {listMotives.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="modal-item modal-item-edit"
                      onClick={() => setMotiveChooser(item)}
                    >
                      <p
                        style={{
                          color:
                            item.id === motiveChooser?.id
                              ? Colors.brand_blue
                              : Colors.darkest_gray,
                        }}
                        className="modal-name mb-0"
                      >
                        {item.name}
                      </p>

                      <div className="container-check">
                        <input
                          className={`input-round ${
                            item.id != motiveChooser?.id && "radio-inactive"
                          }`}
                          type="radio"
                          checked={item.id === motiveChooser?.id}
                          onClick={() => setMotiveChooser(item)}
                        />
                      </div>
                    </div>
                  );
                })}

                {motiveChooser.name == "Outros" && (
                  <div className="mb-3 px-4">
                    <input
                      className="default-input md"
                      type="text"
                      placeholder="Digite outro motivo aqui"
                      value={otherMotive}
                      onChange={(e) => setOtherMotive(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer className="row justify-content-end align-items-center pb-0 mx-0">
              <Col xs={4} md={3} lg={3} className="px-0 mr-2">
                <div
                  className="secondary-btn"
                  onClick={() => handler_show_modal(false)}
                >
                  Cancelar
                </div>
              </Col>
              <Col xs={4} md={3} lg={3} className="px-0">
                <button
                  disabled={
                    (VerifyFields() == true ? false : true) ||
                    loadingBtn === "flex"
                  }
                  className="mb-0 modal-footer-btn btn-confirm"
                  style={{
                    backgroundColor:
                      VerifyFields() == true
                        ? Colors.brand_pink
                        : Colors.lighter_gray,
                  }}
                  onClick={() => RegisterDouble()}
                >
                  <p
                    className="mb-0"
                    style={{
                      color:
                        VerifyFields() == true ? "white" : Colors.dark_gray,
                      display: loadingBtn == "none" ? "flex" : "none",
                    }}
                  >
                    Confirmar
                  </p>

                  <Spinner
                    style={{ display: loadingBtn }}
                    animation="border"
                    role="status"
                    variant="light"
                    size="sm"
                  />
                </button>
              </Col>
            </Modal.Footer>
          </>
        )}

        {page == 3 && (
          <>
            <Modal.Body
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 5,
                paddingBottom: 15,
              }}
            >
              <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col xs={8} md={10} lg={10} className="pl-0">
                    <div className="modal-title">Autenticar confirmação</div>
                  </Col>
                  <Col
                    xs={4}
                    md={2}
                    lg={2}
                    className="pr-0 d-flex justify-content-end"
                  >
                    <div
                      className="modal-close-btn"
                      onClick={() => handler_show_modal(false)}
                    >
                      <Icon color={Colors.darkest_gray} size={15} icon="x" />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="modal-list" style={{ minHeight: 200 }}>
                <div className="my-4 px-3">
                  <Form.Label className="field-label">Responsável</Form.Label>
                  <Select
                    captureMenuScroll={false}
                    classNamePrefix="react-select"
                    placeholder={"Selecionar"}
                    options={listUsers}
                    isClearable={false}
                    value={userChooser}
                    onChange={(item) => {
                      setUserChooser(item);
                      setShowPw(true);
                    }}
                  />
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer className="row justify-content-end align-items-center pb-0 mx-0">
              <Col xs={4} md={3} lg={3} className="px-0 mr-2">
                <div
                  className="secondary-btn"
                  onClick={() => handler_show_modal(false)}
                >
                  Cancelar
                </div>
              </Col>
              <Col xs={4} md={3} lg={3} className="px-0">
                <button
                  disabled={
                    (VerifyFields() == true ? false : true) ||
                    loadingBtn === "flex"
                  }
                  className="mb-0 modal-footer-btn btn-confirm"
                  style={{
                    backgroundColor:
                      VerifyFields() == true
                        ? Colors.brand_pink
                        : Colors.lighter_gray,
                  }}
                  onClick={() => RegisterDouble()}
                >
                  <p
                    className="mb-0"
                    style={{
                      color:
                        VerifyFields() == true ? "white" : Colors.dark_gray,
                      display: loadingBtn == "none" ? "flex" : "none",
                    }}
                  >
                    Confirmar
                  </p>

                  <Spinner
                    style={{ display: loadingBtn }}
                    animation="border"
                    role="status"
                    variant="light"
                    size="sm"
                  />
                </button>
              </Col>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
}

export default ModalDouble;
