import React from "react";
import Colors from "../../styles/Colors";
import Icon from "../../functions/Icon";

function InformationButton(props) {
  const { iconSize, modalHandler } = props;
  return (
    <button onClick={() => modalHandler(true)} className="btn-info-chart">
      <Icon color={Colors.brand_gray} size={iconSize | 22} icon="info" />
    </button>
  );
}

export default InformationButton;
