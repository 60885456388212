import React, { useState, useEffect } from "react";
import {
    Col,
    Container,
    Row,
    Modal,
    Button
} from "react-bootstrap";

import Colors from "../../../styles/Colors";
import "../../../styles/global.css";
import "./styles.css";

import Icon from "../../../functions/Icon";

// Suspender Avisos
// B2.8 - B2.10
function ModalAvisos(props)
{

}

export default ModalAvisos;