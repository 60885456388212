import React, { useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  GetColorAvaliation,
  createStripePattern,
  resizeChartBasedOnWidth,
} from "../../../functions/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Icon from "../../../functions/Icon";

function TwoBarStacked(props) {
  const {
    labels,
    scores,
    comorbiditiesCounter,
    situationalStatesCounter,
    comorbiditiesName,
    situationalStatesName,
    colors,
  } = props;

  const data = {
    labels,
    datasets: [
      {
        label: "Comorbidades",
        data: comorbiditiesCounter,
        backgroundColor: colors,
      },
      {
        label: "Estado situacional",
        data: situationalStatesCounter,
        backgroundColor: colors.map((i) => createStripePattern(i + "c2")),
      },
    ],
  };

  const options = {
    legend: {
      position: "bottom",
      labels: {
        fontFamily: "Rubik-Regular",
        generateLabels: function (chart) {
          return chart.data.datasets.map(function (dataset, index) {
            const color =
              index === 0 ? "#666666" : createStripePattern("#c2c2c2");
            return {
              text: dataset.label,
              fillStyle: color,
              lineWidth: 0,
              strokeStyle: color,
            };
          });
        },
      },
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.3,
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            stepSize: 3,
          },
        },
      ],
    },
    tooltips: {
      titleFontStyle: "bold",
      titleFontSize: 15,
      bodyFontSize: 15,
      displayColors: false,
      callbacks: {
        title: function (tooltipItems, data) {
          const tooltipItem = tooltipItems[0];
          const label = data.datasets[tooltipItem.datasetIndex].label;
          const value = tooltipItem.yLabel;
          return `${label} (${value})`;
        },
        label: function (tooltipItem, data) {
          let items;
          if (tooltipItem.datasetIndex == 0) {
            items = comorbiditiesName[tooltipItem.index];
          } else {
            items = situationalStatesName[tooltipItem.index];
          }
          return items;
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} height={resizeChartBasedOnWidth()} />
      <div
        className={
          "d-flex justify-content-center mt-2" +
          (resizeChartBasedOnWidth() >= 300
            ? " flex-column align-items-center"
            : "")
        }
      >
        {scores &&
          scores.map((score) => {
            return (
              <div
                className="d-flex mr-3"
                style={{
                  fontSize: "15px",
                  fontFamily: "var(--regular)",
                  color: "#6e6a6a",
                }}
              >
                <div
                  style={{
                    transform: "translateY(4px)",
                    width: 14,
                    height: 14,
                    borderRadius: 14,
                    backgroundColor: GetColorAvaliation(score.score),
                    marginLeft: "0.8rem",
                    marginRight: 11,
                  }}
                ></div>
                {score.description}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TwoBarStacked;
