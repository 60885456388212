import React from "react"
import {
  Col,
  Row,
  Modal,
} from "react-bootstrap"
import moment from "moment"

import Icon from "@Functions/Icon"
import Colors from "@Styles/Colors"
import Badge from "@Components/Badge/Badge"

import "./ModalSupensas.scss"

const ModalSuspensas = ({ handler_show_modal, show, suspended }) => {
  const { notification, notificationMotive, time_minutes, user, created_at } = suspended
  const { position } = notification

  const suspendMinutesFormat = minutes => {
    if (minutes > 60) return `${parseInt(minutes/60)} horas`

    if (minutes > 1) return `${minutes} minutos`

    return `${minutes} minuto`
  }

  const suspendMotive = ({ motive, other_motive }) => {
    if (motive.name === 'Outros') return other_motive

    return motive.name
  }

  return (
    <div className="patients-notifications-suspends__modal">
      <Modal
        show={show}
        onHide={() => handler_show_modal(false)}
        centered
        className="modal-decubito"
      >
        <Modal.Body
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            paddingTop: 5,
            paddingBottom: 15,
          }}
        >
          <Row
            className="justify-content-between align-items-center mb-3"
          >
            <Col xs={6} md={6} lg={6}>
              <Badge color="dark_gray">Suspensa</Badge>
            </Col>

            <Col
              xs={2}
              md={6}
              lg={6}
              className="d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => handler_show_modal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>

          <section 
            className="patients-notifications-suspends__details" 
            id="patients-notifications-suspends__details"
          >
            <h2 className="modal-title-sm">Detalhes da mudança de decúbito</h2>

            <div className="patients-notifications-suspends__detail mb-2 d-flex justify-content-start align-items-center">
                <Icon
                  color={Colors.brand_blue}
                  size={20}
                  icon="calendar"
                  className="mr-2"
                  role="icon"
                />
                <p className="modal-text-sm u-fs-16 u-color-darker_gray">
                  { moment(created_at).format("DD [de] dddd [de] YYYY") }
                </p>
            </div>

            <div className="patients-notifications-suspends__detail mb-2 d-flex justify-content-start align-items-center">
              <Icon
                color={Colors.brand_blue}
                size={20}
                icon="clock"
                className="mr-2"
              />
              <p className="modal-text-sm u-fs-16 u-color-darker_gray">
                { moment(created_at).format("HH:mm") }
              </p>
            </div>

            <div className="patients-notifications-suspends__detail mb-2 d-flex justify-content-start align-items-center">
              <Icon
                color={Colors.brand_blue}
                size={20}
                icon="clock-arrow-up"
                className="mr-2"
              />
              <p className="modal-text-sm u-fs-16 u-color-darker_gray">
                Suspenso por { suspendMinutesFormat(time_minutes) }
              </p>
            </div>

            <div className="patients-notifications-suspends__detail mb-2 d-flex justify-content-start align-items-center">
              <Icon
                color={Colors.brand_blue}
                size={20}
                icon="refresh"
                className="mr-2"
              />
              <p className="modal-text-sm u-fs-16 u-color-darker_gray">
                { position.name }
              </p>
            </div>
          </section>

          <hr />

          <section className="patients-notifications-suspends__motive">
            <h2 className="title-motive">Motivo</h2>
            <p className="text-motive">{ suspendMotive(notificationMotive) }</p>
          </section>

          <section className="patients-notifications-suspends__user d-flex justify-content-start align-items-center mt-4">
            <img
              width="40"
              height="40"
              src={ user.url_image || require("@Assets/img/icon_user.png")}
              alt="ícone: User"
              className="mr-3 rounded-circle"
              loading="lazy"
            />
            <div className="patients-notifications-suspends__user-details">
              <p className="u-fs-14 u-color-darkestGray">Por { user.name }</p>
              <p className="u-fs-12 u-color-darkGray">{ moment(created_at).format('DD [de] dddd [de] YYYY [ás] HH:mm') }</p>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalSuspensas;
