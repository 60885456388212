import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import ModalAge from "./ModalInfo/ModalAge";
import EmptyContainer from "./EmptyContainer";
import ModalPatientsRisks from "./ModalData/ModalPatientsRisks";
import { resizeChartBasedOnWidth } from "../../functions/utils";

function AgeGroup(props) {
  const { ageGroups } = props;

  const [age, setAge] = useState(null);
  const [showAge, setShowAge] = useState(false);
  const [patientIds, setPatientIds] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showPatientsRisksData, setShowPatientsRisksData] = useState(false);

  useEffect(() => {
    if (ageGroups) {
      const arrayAge = [];
      let ageEmptyVerify = true;
      if (
        ageGroups.none.counter.wounds > 0 ||
        ageGroups.under.counter.wounds > 0 ||
        ageGroups.twentytwo.counter.wounds > 0 ||
        ageGroups.forty.counter.wounds > 0 ||
        ageGroups.sixty.counter.wounds > 0 ||
        ageGroups.seventy.counter.wounds > 0 ||
        ageGroups.eighty.counter.wounds > 0 ||
        ageGroups.none.counter.patient_risks > 0 ||
        ageGroups.under.counter.patient_risks > 0 ||
        ageGroups.twentytwo.counter.patient_risks > 0 ||
        ageGroups.forty.counter.patient_risks > 0 ||
        ageGroups.sixty.counter.patient_risks > 0 ||
        ageGroups.seventy.counter.patient_risks > 0 ||
        ageGroups.eighty.counter.patient_risks > 0
      ) {
        ageEmptyVerify = false;
      }
      arrayAge.push(
        {
          fill: false,
          type: "line",
          label: "Lesão por pressão",
          data: [
            ageGroups.none.counter.wounds,
            ageGroups.under.counter.wounds,
            ageGroups.twentytwo.counter.wounds,
            ageGroups.forty.counter.wounds,
            ageGroups.sixty.counter.wounds,
            ageGroups.seventy.counter.wounds,
            ageGroups.eighty.counter.wounds,
          ],
          backgroundColor: Colors.brand_blue,
          borderColor: Colors.brand_blue,
        },
        {
          type: "bar",
          label: "Pacientes de risco",
          data: [
            ageGroups.none.counter.patient_risks,
            ageGroups.under.counter.patient_risks,
            ageGroups.twentytwo.counter.patient_risks,
            ageGroups.forty.counter.patient_risks,
            ageGroups.sixty.counter.patient_risks,
            ageGroups.seventy.counter.patient_risks,
            ageGroups.eighty.counter.patient_risks,
          ],
          backgroundColor: Colors.dark_blue,
        }
      );

      var objAge = {
        labels: [
          "Sem idade",
          "Abaixo de 22",
          "22 a 39",
          "40 a 59",
          "60 a 69",
          "70 a 79",
          "Acima de 80",
        ],
        datasets: arrayAge,
      };
      setAge(ageEmptyVerify ? null : objAge);
    }
  }, [ageGroups]);

  const optionsAge = {
    onClick: function (evt, item) {
      if (item.length > 0) {
        const index = item[0]._index;
        const datasetIndex = item[0]._datasetIndex;
        const ageItems = Object.keys(ageGroups).map((key) => ({
          ...ageGroups[key],
          id: key,
        }));
        const ageSelectedItem = ageItems[index];
        if (ageSelectedItem.patientIds.length > 0) {
          handleModalPatientRisks(
            "Faixa etária: " +
              (ageSelectedItem.text === "Sem idade"
                ? ageSelectedItem.text
                : ageSelectedItem.text + " anos"),
            ageSelectedItem.patientIds
          );
          setShowPatientsRisksData(true);
        }
      }
    },
    onHover: function (event, chartElement) {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 7,
      },
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.7,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: Colors.light_gray,
            offsetGridLines: false,
            borderDash: [6],
            lineWidth: 1,
          },
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              if (value % 1 === 0) {
                return value;
              }
            },
          },
        },
      ],
    },
  };

  function handleModalPatientRisks(title, patientIds) {
    setPatientIds(patientIds);
    setSelectedData({
      label: title,
      patientQuantity: new Set(patientIds).size,
    });
    setShowPatientsRisksData(true);
  }

  return (
    <div className="age-groups h-100">
      <div className="d-flex align-items-center">
        <h4 className="mb-0">Faixa etária</h4>
        <button onClick={() => setShowAge(true)} className="btn-info-chart">
          <Icon
            color={Colors.brand_gray}
            size={22}
            icon="info"
            className="ml-2"
          />
        </button>
      </div>

      {age ? (
        <div className="mt-3">
          <Bar
            data={age}
            options={optionsAge}
            height={resizeChartBasedOnWidth()}
          />
          <div className="container-subtitle mt-4">
            <div className="subtitle-item mr-2">
              <div className="indicator-square" />
              <p>Pacientes de risco</p>
            </div>
            <div className="subtitle-item">
              <div className="indicator-radius" />
              <p>Lesão por pressão</p>
            </div>
          </div>
        </div>
      ) : (
        <EmptyContainer
          text="Ainda não há dados sobre pacientes de risco de acordo com as
        faixas etárias"
          iconName="bar-chart"
        />
      )}
      {showAge && (
        <ModalAge handler_show_modal={setShowAge} show_modal={showAge} />
      )}
      {showPatientsRisksData && (
        <ModalPatientsRisks
          handler_show_modal={setShowPatientsRisksData}
          show_modal={showPatientsRisksData}
          title={selectedData?.label}
          subTitle={`${selectedData?.patientQuantity} ${
            selectedData?.patientQuantity === 1 ? "paciente" : "pacientes"
          }`}
          removeColumns={[
            "Estágio",
            "Data de confirmação",
            "Fatores de risco",
            "Avaliação de risco",
            "Admissão",
            "Alta",
            "Tempo monitorado",
          ]}
          ids={patientIds}
          type="populationalData"
        />
      )}
    </div>
  );
}

export default AgeGroup;
