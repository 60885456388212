import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";

import Colors from "../styles/Colors";
import "../styles/global.css";

function PwStrengthMeter(props) {
  const [level, setLevel] = useState(0);
  const [color, setColor] = useState(Colors.lighter_gray);
  const [textColor, setTextColor] = useState(Colors.darker_gray);
  const [text, setText] = useState(
    "Utilize 8 ou mais caracteres com letra maiúscula, número e símbolo."
  );

  // Verificação da senha quando muda
  useEffect(() => {
    if (props.value.length == 0) {
      setColor(Colors.lighter_gray);
      setTextColor(Colors.darker_gray);
      setText(
        "Utilize 8 ou mais caracteres com letra maiúscula, número e símbolo."
      );
      setLevel(0);
      return;
    }

    const letterUpper = RegExp("[A-Z]").test(props.value);
    const letterLower = RegExp("[a-z]").test(props.value);
    const numbers = RegExp("[0-9]").test(props.value);
    const specials = RegExp("[$@$!%*#?&.++/;:`~]").test(props.value);

    if (props.value.length < 8) {
      setColor(Colors.lighter_gray);
      setTextColor(Colors.brand_red);
      setText("Muito curta, utilize 8 ou mais caracteres.");
      setLevel(0);
      return;
    }

    if (((letterLower || letterUpper) && numbers) == false) {
      setColor(Colors.brand_red);
      setTextColor(Colors.brand_red);
      setText("Muito fraca, experimente utilizar letras e números.");
      setLevel(1);
      return;
    }

    if ((letterLower && letterUpper && numbers && specials) == false) {
      setColor(Colors.brand_yellow);
      setTextColor(Colors.brand_yellow);
      setText("Fraca. Experimente usar maiúscula, número e símbolo.");
      setLevel(2);
      return;
    } else {
      setLevel(3);

      if (props.value.length < 12) {
        setColor(Colors.brand_blue);
        setTextColor(Colors.brand_blue);
        setText("Boa. Experimente usar mais caracteres.");
        return;
      }

      if (props.value.length >= 12) {
        setColor(Colors.brand_green);
        setTextColor(Colors.brand_green);
        setText("Senha forte, excelente!");
        setLevel(4);
        return;
      }
    }
  }, [props.value]);

  useEffect(() => {
    const handler = props.handler;
    handler(level);
  }, [level]);

  return (
    <Container fluid>
      <Row style={{ marginTop: 8 }}>
        <Col
          className="pw-bar"
          style={
            level > 0
              ? { backgroundColor: color }
              : { backgroundColor: Colors.light_gra }
          }
        ></Col>
        <Col
          className="pw-bar"
          style={
            level > 1
              ? { backgroundColor: color }
              : { backgroundColor: Colors.light_gra }
          }
        ></Col>
        <Col
          className="pw-bar"
          style={
            level > 2
              ? { backgroundColor: color }
              : { backgroundColor: Colors.light_gra }
          }
        ></Col>
        <Col
          className="pw-bar"
          style={
            level > 3
              ? { backgroundColor: color }
              : { backgroundColor: Colors.light_gra }
          }
        ></Col>
      </Row>
      <Row>
        <Col style={{ paddingRight: 0, paddingLeft: 0 }}>
          <div className="pw-txt" style={{ color: textColor }}>
            {text}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PwStrengthMeter;
