import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import VerifyPwWithUser from "./VerifyPwWithUser";
import api from "../../services/api";

function ModalHelpContact({ ...props }) {
  //const { handler_modal, show_modal } = props;
  const handler_modal = props.handler_modal;
  const show_modal = props.show_modal;
  function RenderPage1() {
    return (
      <Modal.Body
        className="p-3"
        style={{
          borderRadius: "13px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Ajuda</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => handler_modal(false)}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>
        <div className="text-center">
          <img
            className="img-help-modal-qrcode"
            src="https://preveni-img.s3.sa-east-1.amazonaws.com/users/3BiQQTB.png"
            alt="QRCode com o WhatsApp da equipe Preveni"
          />
          <p className="text-help-modal">
            Escaneie o QRCode acima para ser redirecionado <br /> para o
            WhatsApp de Ajuda.
          </p>
        </div>
      </Modal.Body>
    );
  }

  return (
    <>
      <Modal
        show={show_modal}
        onHide={() => handler_modal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {RenderPage1()}
      </Modal>
    </>
  );
}

export default ModalHelpContact;
