import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";
import ImageMapper from "react-image-mapper";
import { SRLWrapper } from "simple-react-lightbox";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";
import {
  GetImc,
  CalculateAge,
  GenerateId,
  RemoveVirgulas,
  userHasPermission,
} from "../../functions/utils";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import "./styles.css";
import api from "../../services/api";
import list_wounds from "../../functions/list_wounds";
import { Show_Alert } from "../../store/actions/alert";
import VerifyPw from "./VerifyPw";

var indexImg = 0;

function ModalWound(props) {
  const {
    handler_show_wound,
    show_wound,
    list_wounds,
    wound_add,
    handler_list_add,
    list_add,
    wound_id_edit,
    handler_other_wound,
    other_wound,
    wound_edit,
  } = props;

  const user = useSelector((state) => state.user);
  const hospitalPlan = useSelector((state) => state.hospitalPlan);
  const patientSelected = useSelector((state) => state.patientSelected);

  //Mask
  const [maskWidth, setMaskWidth] = useState("9,999");
  const [maskHeight, setMaskHeight] = useState("9,999");

  //Campos pra editar no modal wound add
  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });
  const [wound, setWound] = useState({});
  const [idStage, setIdStage] = useState(0);
  const [noExposure, setNoExposure] = useState(true);
  const [exposureTypeSetado, setExposureTypeSetado] = useState(false);
  const [btnsSetado, setBtnsSetado] = useState(false);
  const [type, setType] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [depth, setDepth] = useState("");
  const [descriptions, setDescriptions] = useState([]);
  const [note, setNote] = useState("");
  const [woundTypeNote, setWoundTypeNote] = useState("");
  const [images, setImages] = useState([]);
  const [newName, setNewName] = useState("");
  const [newSlider, setNewSlider] = useState(0);
  const [medicalDevices, setMedicalDevices] = useState(null);
  const [exposureType, setExposureType] = useState([]);
  const [btnsSelected, setBtnsSelected] = useState([]);
  const [titleTypeDescription, setTitleTypeDescription] = useState([
    {
      title: "Bordas",
      subtitle: "Tipos de bordas",
    },
  ]);
  const [newTitlesTypeDescription, setNewTitlesTypeDescription] = useState([
    {
      name: "Cor",
      title: "Pele adjacente",
    },
    {
      name: "Quantidade de exsudato",
      title: "Exsudato",
    },
  ]);

  const [listUsers, setListUsers] = useState([]);
  const [listWounds, setListWounds] = useState(list_wounds);
  const [listStages, setListStages] = useState([]);

  const [showPw, setShowPw] = useState(false);

  const [listTypeDescriptions, setListTypeDescriptions] = useState([]);
  const [listExposureTypes, setListExposureTypes] = useState({});
  const [listDescItems, setListDescItems] = useState({});
  const [showOther, setShowOther] = useState(false);
  const [viewPwd, setViewPwd] = useState(false);
  const [showModalDeleteImg, setShowModalDeleteImg] = useState(false);

  const [
    showPercentageExceedLimitWarning,
    setShowPercentageExceedLimitWarning,
  ] = useState(false);

  const colourStyles = {
    multiValue: (styles, { data }) => {
      const color = Colors.brand_blue;
      return {
        ...styles,
        backgroundColor: "#ECF3FF",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
      ":hover": {
        backgroundColor: "#ECF3FF",
        color: Colors.brand_blue,
      },
    }),
  };

  useEffect(() => {
    function Edit() {
      //Se ta editando a lesao
      var found = wound_edit;
      if (found != undefined) {
        setUserChooser({ label: found.user.label, value: found.user.value });
        setWound({ label: found.wound_name, value: found.wound_id });
        setIdStage(found.stage_id);
        setType(found.type);
        setWoundTypeNote(found.wound_type_note);
        setWidth(found.width);
        setMaskWidth(found.width.length == 5 ? "99,99" : "9,99");
        setHeight(found.height);
        setMaskHeight(found.height.length == 5 ? "99,99" : "9,99");
        setDepth(found.depth);
        setNote(found.note);
        setMedicalDevices(found.medical_devices);
        setImages(found.images);
        setDescriptions(found.descriptions);

        if (found.type_description_name != "") {
          setNewName(found.type_description_name);
          setNewSlider(found.value);
          setShowOther(true);
        } else {
          setNewName("");
          setNewSlider(0);
          setShowOther(false);
        }
      }
    }

    if (wound_id_edit != 0) {
      Edit();
    } else {
      if (other_wound != "") {
        setWound({ label: other_wound, value: null });
      } else {
        setWound({
          label: wound_add.name,
          value: wound_add.id,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (userHasPermission(user)) {
      setUserChooser({
        label: user.dados.name,
        value: user.dados.id,
      });
    }
  }, []);

  useEffect(() => {
    var found = wound_edit;
    if (
      (found !== undefined && found.length > 0) ||
      (found !== undefined && !Array.isArray(found))
    ) {
      setDescriptions(found.descriptions);
    }
  }, [descriptions]);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: patientSelected.dados.unit_id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (user.dados.permission_id > 3 || user.dados.permission_id >= 6) {
      LoadUsers();
    }
  }, []);

  useEffect(() => {
    function LoadStages() {
      api
        .get(`/stages`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadStages");
            console.log(response.data);

            setListStages(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    LoadStages();
  }, []);

  useEffect(() => {
    setNoExposure(exposureType.length > 0 ? false : true);
    SetarDescriptions(exposureType, listExposureTypes.id);
  }, [exposureType]);

  function VerifyField() {
    let verify = true;
    if (
      type == "" ||
      (hospitalPlan.dados.type !== 2 && idStage == 0) ||
      medicalDevices == null
    )
      verify = false;
    return verify;
  }

  useEffect(() => {
    function LoadTypeDescriptions(desc) {
      api
        .get(`/typedescriptions`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadDescriptions");
            console.log(response.data);

            var array = [];
            response.data.map((item) => {
              var obj = {};
              obj.description_id = "";
              obj.type_description_id = item.id;
              obj.description_name = "";
              obj.value = "";
              obj.typeItem = item.type;
              if (desc != null) {
                var found = desc.find((i) => i.type_description_id == item.id);
                if (found != undefined) {
                  obj.description_id = found.description_id;
                  if (found.value != "" && found.value != null) {
                    obj.value = parseInt(found.value);
                  } else {
                    obj.value = found.value == null ? "" : "";
                  }
                }
              }

              array.push(obj);
            });

            response.data.map((item) => {
              var array = [];
              item.descriptions.map((i, index) => {
                var obj = {};
                obj.label = i.name;
                obj.value = i.id;
                obj.itemType = item.type;
                array.push(obj);
              });
            });

            var listTypeDescriptions = response.data.map((item) => {
              if (item.descriptions.length > 0 && item.id === 16) {
                item.descriptions.unshift({
                  id: 0,
                  name: "Selecionar",
                  type_description_id: item.descriptions[0].type_description_id,
                });
              }
              return item;
            });
            var exposureTypes = listTypeDescriptions.find((item) => {
              if (item.descriptions.length > 0) {
                return item.name === "Exposição de:";
              }
            });

            if (wound_id_edit == 0) {
              setDescriptions(array);
            }
            setListTypeDescriptions(listTypeDescriptions);
            setListExposureTypes(exposureTypes);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    LoadTypeDescriptions();
  }, []);

  function SetarSlider(value, id) {
    let percentageAtualValue = 0;
    setShowPercentageExceedLimitWarning(false);
    descriptions.forEach((i) => {
      const storedValue = parseInt(i.value, 10);
      if (!isNaN(storedValue) && i.type_description_id !== id) {
        percentageAtualValue += storedValue;
      } else if (i.type_description_id === id) {
        percentageAtualValue += parseInt(value);
      }
    });
    if (percentageAtualValue > 100) {
      setShowPercentageExceedLimitWarning(true);
      value = 100 - (percentageAtualValue - parseInt(value));
    }

    var newList = descriptions.map((item) => {
      if (item.type_description_id == id) {
        item.value = value;
      }
      return item;
    });

    setDescriptions(newList);
  }

  function SetarDescription(value, id) {
    var newList = descriptions.map((item) => {
      if (item.type_description_id == id) {
        item.description_id = value;
        item.value = "";
        item.description_name = "";
      }
      return item;
    });
    setDescriptions(newList);
  }

  function SetarDescriptions(value, id) {
    var newList = descriptions.map((item) => {
      if (item.type_description_id == id) {
        item.description_ids = value;
        item.value = "";
        item.description_name = "";
      }
      return item;
    });
    setDescriptions(newList);
  }

  function SetarBtns(value, id) {
    var btns = btnsSelected.find((item) => item.typeDescriptionId === id);
    if (btns) {
      var existentBtns = btnsSelected.map((item) => {
        if (item.typeDescriptionId == id) {
          if (item.values.indexOf(value) > -1) {
            item.values = item.values.filter((i) => i !== value);
          } else {
            item.values.push(value);
          }
        }
        return item;
      });
      setBtnsSelected(existentBtns);
    } else {
      var newBtns = {
        typeDescriptionId: id,
        values: [value],
      };
      SetarDescriptions([value], id);
      setBtnsSelected((btnsSelected) => [...btnsSelected, newBtns]);
    }
  }

  function Preview(files) {
    if (files == undefined || files == null) return false;

    const reader = new FileReader();
    reader.onload = (r) => {
      var obj = {
        file: files[0],
        path: r.target.result,
        fileName: files[0].name,
        created_at: new Date(),
      };

      setImages([...images, obj]);
    };
    reader.readAsDataURL(files[0]);
  }

  function renderModalDeleteImg() {
    return (
      <Modal
        show={showModalDeleteImg}
        onHide={() => {
          setShowModalDeleteImg(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter modal-pw"
      >
        <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={6} md={2} lg={6}>
              <div className="modal-title">
                Deseja realmente excluir essa foto?
              </div>
            </Col>
            <Col
              xs={2}
              md={2}
              lg={2}
              className="pr-0 d-flex justify-content-end"
            >
              <div
                className="modal-close-btn"
                onClick={() => {
                  setShowModalDeleteImg(false);
                }}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="row justify-content-end align-items-center pb-0">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              className="modal-footer-btn mr-0 btn-cancel"
              onClick={() => {
                setShowModalDeleteImg(false);
              }}
            >
              Cancelar
            </button>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              className="mb-0 modal-footer-btn btn-confirm"
              style={{ backgroundColor: Colors.brand_pink }}
              onClick={() => {
                var newList = images.filter((item, index) => index != indexImg);
                setImages(newList);
                setShowModalDeleteImg(false);
              }}
            >
              <p className="mb-0">Sim</p>
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }

  function CalculateArea(width, height) {
    var newWidth = width.replace(",", ".");
    var newHeight = height.replace(",", ".");
    var multi = (newWidth * newHeight).toFixed(2).replace(".", ",");
    return multi;
  }

  return (
    <Modal
      show={show_wound}
      onHide={() => {
        handler_other_wound("");
        handler_show_wound(false);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-pw"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
    >
      {showPw && (
        <VerifyPw
          handler_show_pw={setShowPw}
          show_pw={showPw}
          handler_user={setUserChooser}
          id_user={userChooser.value}
        />
      )}

      {showModalDeleteImg && renderModalDeleteImg()}

      <Modal.Body className="p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
            paddingBottom: 6,
          }}
        >
          <div>
            <div className="modal-title">Nova Lesão</div>
          </div>
          <div>
            <div
              className="modal-close-btn"
              onClick={() => {
                handler_other_wound("");
                handler_show_wound(false);
              }}
            >
              <Icon color={Colors.darkest_gray} size={15} icon="x" />
            </div>
          </div>
        </div>

        <div className="modal-all">
          <div className="mb-3">
            <Form.Label className="field-label label-bold">
              Local <span className="obrigatory-asterisk">*</span>
            </Form.Label>
            <Select
              captureMenuScroll={false}
              noOptionsMessage={() => "Sem opções"}
              isDisabled={wound_id_edit != 0}
              classNamePrefix="react-select"
              placeholder={"Selecionar"}
              options={listWounds}
              isClearable={false}
              value={wound}
              onChange={(item) => setWound(item)}
            />
          </div>

          <div className="mb-2">
            <Form.Label className="field-label w-100 label-bold">
              Verificação <span className="obrigatory-asterisk">*</span>
            </Form.Label>
            <button
              style={{
                background:
                  type == "admission"
                    ? Colors.brand_blue
                    : Colors.lightest_gray,
              }}
              className="btn-click"
              onClick={() => setType("admission")}
            >
              <p
                className="mb-0"
                style={{
                  color: type == "admission" ? "white" : Colors.darker_gray,
                }}
              >
                Admissão
              </p>
            </button>

            <button
              style={{
                background:
                  type == "incidence"
                    ? Colors.brand_blue
                    : Colors.lightest_gray,
              }}
              className="btn-click"
              onClick={() => setType("incidence")}
            >
              <p
                className="mb-0"
                style={{
                  color: type == "incidence" ? "white" : Colors.darker_gray,
                }}
              >
                Incidência
              </p>
            </button>
          </div>

          {hospitalPlan.dados.type == 2 && (
            <div className="mb-4">
              <Form.Label className="field-label label-bold">
                Tipo de lesão
              </Form.Label>
              <Form.Control
                placeholder="Digite o tipo de lesão"
                as="textarea"
                rows={1}
                className="default-input"
                style={{ borderRadius: 8 }}
                type="text"
                value={woundTypeNote}
                onChange={(e) => setWoundTypeNote(e.target.value)}
              />
            </div>
          )}

          <div className="mb-3">
            <Form.Label className="field-label w-100 label-bold">
              Estágio{" "}
              {hospitalPlan.dados.type !== 2 && (
                <span className="obrigatory-asterisk">*</span>
              )}
            </Form.Label>
            {listStages.map((item, index) => {
              return (
                <button
                  key={index}
                  style={{
                    background:
                      item.id == idStage
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                  }}
                  className="btn-click"
                  onClick={() => setIdStage(item.id)}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: item.id == idStage ? "white" : Colors.darker_gray,
                    }}
                  >
                    {item.name}
                  </p>
                </button>
              );
            })}
          </div>

          <div className="mb-3">
            <Form.Label className="field-label w-100 label-bold">
              Associada a dispositivo médico{" "}
              <span className="obrigatory-asterisk">*</span>
            </Form.Label>
            <button
              style={{
                background:
                  medicalDevices == 1
                    ? Colors.brand_blue
                    : Colors.lightest_gray,
                width: 100,
              }}
              className="btn-click"
              onClick={() => setMedicalDevices(1)}
            >
              <p
                className="mb-0"
                style={{
                  color: medicalDevices == 1 ? "white" : Colors.darker_gray,
                }}
              >
                Sim
              </p>
            </button>

            <button
              style={{
                background:
                  medicalDevices == 0
                    ? Colors.brand_blue
                    : Colors.lightest_gray,
                width: 100,
              }}
              className="btn-click"
              onClick={() => setMedicalDevices(0)}
            >
              <p
                className="mb-0"
                style={{
                  color: medicalDevices == 0 ? "white" : Colors.darker_gray,
                }}
              >
                Não
              </p>
            </button>
          </div>

          <div className="d-flex flex-wrap">
            <p className="w-100 label-bold">Tamanho</p>
            <Row>
              <Col xs={12} md={4}>
                <Form.Label className="field-label">Largura (cm)</Form.Label>
                <InputMask
                  mask={maskWidth}
                  maskChar={null}
                  className="default-input"
                  type="text"
                  placeholder="0,00"
                  value={width}
                  onChange={(e) => {
                    var value = e.target.value;
                    var mask = "9,999";

                    if (value.length == 4) {
                      var parts = value.split("");

                      if (parts[2] == ",") {
                        value = `${parts[0]},${parts[1]}${parts[3]}`;
                      }
                    }

                    if (value.length > 4) {
                      mask = "99,99";
                      var parts = value.split("");

                      if (parts[1] == ",") {
                        value = `${parts[0]}${parts[2]},${parts[3]}${parts[4]}`;
                      }
                    }

                    setMaskWidth(mask);
                    setWidth(value);
                  }}
                />
              </Col>

              <Col xs={12} md={4} className="my-3 my-md-0">
                <Form.Label className="field-label">Compr. (cm)</Form.Label>
                <InputMask
                  mask={maskHeight}
                  maskChar={null}
                  className="default-input"
                  type="text"
                  placeholder="0,00"
                  value={height}
                  onChange={(e) => {
                    var value = e.target.value;
                    var mask = "9,999";

                    if (value.length == 4) {
                      var parts = value.split("");

                      if (parts[2] == ",") {
                        value = `${parts[0]},${parts[1]}${parts[3]}`;
                      }
                    }

                    if (value.length > 4) {
                      mask = "99,99";
                      var parts = value.split("");

                      if (parts[1] == ",") {
                        value = `${parts[0]}${parts[2]},${parts[3]}${parts[4]}`;
                      }
                    }

                    setMaskHeight(mask);
                    setHeight(value);
                  }}
                />
              </Col>

              <Col xs={12} md={4}>
                <Form.Label className="field-label">Área (cm²)</Form.Label>
                <input
                  style={{
                    backgroundColor: "rgba(70,141,255,.1)",
                    borderWidth: 0,
                    color: Colors.brand_blue,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  disabled={true}
                  className="default-input"
                  type="text"
                  placeholder="0.00"
                  value={CalculateArea(width, height)}
                />
              </Col>
            </Row>
          </div>

          <div className="mb-4 mt-4">
            <Form.Label className="field-label">Profundidade (cm)</Form.Label>
            <InputMask
              mask={"9,99"}
              maskChar={null}
              className="default-input"
              type="text"
              placeholder="0,00"
              value={depth}
              onChange={(e) => {
                setDepth(e.target.value);
              }}
            />
          </div>

          <div className="mb-4">
            {idStage === 5 && (
              <div>
                <Form.Label className="field-label">
                  {listExposureTypes.name}
                </Form.Label>
                <div>
                  {listExposureTypes.descriptions &&
                    listExposureTypes.descriptions.map((item, index) => {
                      var exposureBtns = descriptions.find((i) => {
                        return (
                          i.type_description_id == item.type_description_id
                        );
                      });
                      if (
                        exposureType.length == 0 &&
                        exposureBtns.description_ids !== undefined &&
                        exposureBtns.description_ids !== null &&
                        !exposureTypeSetado
                      ) {
                        let exposures = exposureBtns.description_ids;
                        setExposureType(
                          exposures
                            .filter((i) => {
                              return i !== null;
                            })
                            .filter((i) => i !== "")
                        );
                        setExposureTypeSetado(true);
                      }
                      if (item.name !== "Sem Exposição") {
                        return (
                          <button
                            key={index}
                            style={{
                              background: exposureType.includes(item.id)
                                ? Colors.brand_blue
                                : Colors.lightest_gray,
                            }}
                            className="btn-click"
                            onClick={() => {
                              let index = exposureType.indexOf(item.id);
                              if (index > -1) {
                                setExposureType((exposureType) =>
                                  exposureType.filter((i) => i !== item.id)
                                );
                              } else {
                                setExposureType((exposureType) => [
                                  ...exposureType,
                                  item.id,
                                ]);
                              }
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{
                                color: exposureType.includes(item.id)
                                  ? "white"
                                  : Colors.darker_gray,
                              }}
                            >
                              {item.name}
                            </p>
                          </button>
                        );
                      } else {
                        return (
                          <div className="d-flex">
                            <label className="simple-checkbox">
                              <input
                                type="checkbox"
                                checked={noExposure}
                                onChange={() => {
                                  setNoExposure(true);
                                  setExposureType([]);
                                  SetarDescription(listExposureTypes.id, 0);
                                }}
                              ></input>
                              <span></span>
                            </label>
                            <labeL
                              className="ml-2"
                              style={{
                                fontSize: "11px",
                                fontWeight: 500,
                                color: "rgb(100 87 87)",
                                transform: "translateY(2.5px)",
                              }}
                            >
                              Sem Exposição
                            </labeL>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            )}
            <div className="label-w-description mb-2 mt-2">
              <p className="label-bold">Descrição</p>
              <span>Tipos de Tecido</span>
              {showPercentageExceedLimitWarning && (
                <div className="container-error mt-2 mb-3">
                  <p>
                    Você pode ajustar as porcentagens de cada tecido, mas a soma
                    precisa ser igual a 100%.
                  </p>
                </div>
              )}
            </div>
            {listTypeDescriptions.map(function (item, index) {
              var found = descriptions.find(
                (a) => a.type_description_id == item.id
              );
              var found2 = item.descriptions.find(
                (i) => i.id == found.description_id
              );
              var found3 = [];
              if (item.descriptions.length > 0) {
                var arrayList = [];
                item.descriptions.map((i) => {
                  arrayList.push({ label: i.name, value: i.id });
                });
                var descSelected = { label: "Selecionar", value: 0 };
                if (found2 != undefined) {
                  descSelected = { label: found2.name, value: found2.id };
                }
                if (
                  item.type === "check-multi" &&
                  found.description_ids !== undefined &&
                  found.description_ids.length > 0
                ) {
                  found.description_ids.map((i) => {
                    let itemDescription = item.descriptions.find(
                      (j) => j.id == i
                    );
                    found3.push({
                      label: itemDescription.name,
                      value: itemDescription.id,
                    });
                  });
                }
                let title = item.name;
                let subTitle = titleTypeDescription.find(
                  (i) => i.title == title
                )?.subtitle;
                let newTitle = newTitlesTypeDescription.find(
                  (i) => i.name == title
                )?.title;
                const divIsEmpty =
                  (item.type === "check" && item.name !== "Exposição de:") ||
                  (item.type === "btn-multi" &&
                    item.name !== "Exposição de:") ||
                  item.type === "check-multi";
                return (
                  divIsEmpty && (
                    <div key={index} className="mb-3 mt-1">
                      {item.type === "btn-multi" &&
                        item.name !== "Exposição de:" && (
                          <div>
                            <div className="label-w-description">
                              <p className="label-bold">{title}</p>
                              <span className="mb-3 d-block">
                                {subTitle !== null && subTitle}
                              </span>
                            </div>
                            <div>
                              {item.descriptions.map((item, index) => {
                                var multiBtns = descriptions.find((i) => {
                                  return (
                                    i.type_description_id ==
                                    item.type_description_id
                                  );
                                });
                                if (
                                  btnsSelected.length == 0 &&
                                  multiBtns.description_ids !== undefined &&
                                  multiBtns.description_ids !== null &&
                                  !btnsSetado
                                ) {
                                  let btns = multiBtns.description_ids;
                                  setBtnsSelected([
                                    {
                                      typeDescriptionId:
                                        item.type_description_id,
                                      values: btns
                                        .filter((i) => {
                                          return i !== null;
                                        })
                                        .filter((i) => i !== ""),
                                    },
                                  ]);
                                  setBtnsSetado(true);
                                }
                                var btns = btnsSelected.find(
                                  (i) =>
                                    i.typeDescriptionId ==
                                    item.type_description_id
                                );
                                return (
                                  <button
                                    key={index}
                                    style={{
                                      background: (
                                        btns
                                          ? btns.values.includes(item.id)
                                          : false
                                      )
                                        ? Colors.brand_blue
                                        : Colors.lightest_gray,
                                    }}
                                    className="btn-click"
                                    onClick={() => {
                                      SetarBtns(
                                        item.id,
                                        item.type_description_id
                                      );
                                      if (btns) {
                                        SetarDescriptions(
                                          btns.values,
                                          item.type_description_id
                                        );
                                      }
                                    }}
                                  >
                                    <p
                                      className="mb-0"
                                      style={{
                                        color: (
                                          btns
                                            ? btns.values.includes(item.id)
                                            : false
                                        )
                                          ? "white"
                                          : Colors.darker_gray,
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      {item.type === "check-multi" && (
                        <div>
                          <p className="w-100 label-bold">
                            {newTitle !== null && newTitle}
                          </p>
                          <Form.Label className="field-label">
                            {item.name}
                          </Form.Label>
                          <Select
                            captureMenuScroll={false}
                            noOptionsMessage={() => "Sem opções"}
                            classNamePrefix="react-select"
                            placeholder={"Selecionar"}
                            options={arrayList}
                            defaultValue={found3}
                            isClearable={false}
                            isMulti
                            styles={colourStyles}
                            closeMenuOnSelect={false}
                            onChange={(i) => {
                              var types = [];
                              for (let description of i !== null ? i : []) {
                                types.push(description.value);
                              }
                              SetarDescriptions(types, item.id);
                            }}
                          />
                        </div>
                      )}
                      {item.type === "check" && item.name !== "Exposição de:" && (
                        <div>
                          <div className="mt-3 mb-1">
                            <p className="w-100 label-bold">
                              {newTitle !== null && newTitle}
                            </p>
                            <Form.Label className="field-label">
                              {item.name}
                            </Form.Label>
                          </div>
                          <Select
                            captureMenuScroll={false}
                            noOptionsMessage={() => "Sem opções"}
                            classNamePrefix="react-select"
                            placeholder={"Selecionar"}
                            options={arrayList}
                            isClearable={false}
                            value={descSelected}
                            onChange={(i) => {
                              var type = item.descriptions.find(
                                (a) => a.id == i.value
                              );
                              SetarDescription(
                                i.value,
                                type.type_description_id
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )
                );
              } else {
                return (
                  <div key={index} className="mb-2">
                    <p>{item.name}</p>
                    <div className="d-flex">
                      <Form.Control
                        type="range"
                        value={found.value == "" ? 0 : found.value}
                        onChange={(e) => SetarSlider(e.target.value, item.id)}
                      />
                      <div className="d-flex">
                        <input
                          className="text-right input-description-slider"
                          value={found.value == "" ? 0 : found.value}
                          onChange={(e) => {
                            var value = e.target.value;
                            if (value > 100) value = 100;
                            if (value < 0 || value == "") value = 0;

                            SetarSlider(value, item.id);
                          }}
                        />
                        <p>%</p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <button
              onClick={() => setShowOther(showOther == true ? false : true)}
              style={{
                width: 150,
                height: 36,
                background: showOther == true ? "#FFE8F3" : "white",
                borderRadius: 8,
                borderWidth: 1,
                borderColor: Colors.brand_pink,
                color: Colors.brand_pink,
                borderStyle: "solid",
              }}
            >
              <p>Adicionar outro</p>
            </button>
            {showOther && (
              <div className="mt-3">
                <input
                  className="default-input"
                  type="text"
                  placeholder="Digitar outro"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
                <div className="mt-2">
                  <div className="d-flex">
                    <Form.Control
                      type="range"
                      value={newSlider}
                      onChange={(e) => setNewSlider(e.target.value)}
                    />
                    <div className="d-flex">
                      <input
                        className="text-right input-description-slider"
                        value={newSlider}
                        onChange={(e) => {
                          var value = e.target.value;
                          if (value > 100) value = 100;
                          if (value < 0 || value == "") value = 0;

                          setNewSlider(value);
                        }}
                      />
                      <p>%</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-4">
              <Form.Label className="field-label label-bold">
                {hospitalPlan.dados.type == 2
                  ? "Observações e condutas"
                  : "Notas"}
              </Form.Label>
              <Form.Control
                placeholder={
                  "Digite uma nova " +
                  (hospitalPlan.dados.type == 2
                    ? "observação e conduta"
                    : "nota")
                }
                as="textarea"
                rows={3}
                mask={"99.9"}
                maskChar={null}
                className="default-input"
                style={{ borderRadius: 8 }}
                type="text"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <Form.Label className="field-label mb-0 label-bold">
                  Fotos
                </Form.Label>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setViewPwd(viewPwd == true ? false : true)}
                >
                  <Icon
                    color={Colors.darkest_gray}
                    size={20}
                    icon={viewPwd ? "eye-off" : "eye-on"}
                  />
                </div>
              </div>

              <SRLWrapper
                options={{
                  buttons: {
                    showDownloadButton: false,
                  },
                }}
              >
                <ul className="images p-0 d-flex overflow-auto">
                  {images.map((item, index) => {
                    return (
                      <li
                        className="d-flex flex-column position-relative"
                        style={{ minWidth: 120, minHeight: 140 }}
                      >
                        <div key={index} className="photo-item mr-2">
                          <div
                            className="btn-remove-photo"
                            onClick={() => {
                              indexImg = index;
                              setShowModalDeleteImg(true);
                            }}
                          >
                            <Icon color={"white"} size={15} icon={"x"} />
                          </div>

                          <img
                            alt={moment(item.created_at).format("LLL")}
                            src={
                              item.patient_wound_id == undefined
                                ? item.path
                                : item.url
                            }
                            style={{
                              filter: `blur(${viewPwd == true ? "0" : "3px"})`,
                            }}
                          />
                        </div>
                        <p className="photo-date">
                          {moment(item.created_at).format("LLL")}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </SRLWrapper>

              <button
                onClick={() => {
                  document.getElementById("fileUpload").click();
                }}
                className="mt-3"
                style={{
                  width: "100%",
                  background: "white",
                  borderRadius: 10,
                  padding: 10,
                  borderWidth: 1,
                  borderColor: Colors.brand_pink,
                  color: Colors.brand_pink,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  color={Colors.brand_pink}
                  size={20}
                  icon={"image"}
                  className="mr-2"
                />
                <p>Adicionar fotos</p>
              </button>
              <input
                onChange={(e) => Preview(e.target.files)}
                className="d-none"
                type="file"
                id="fileUpload"
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end align-items-center">
        <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
          <button
            style={{ borderStyle: "solid" }}
            className="modal-footer-btn mr-0 btn-cancel"
            onClick={() => {
              handler_other_wound("");
              handler_show_wound(false);
            }}
          >
            Cancelar
          </button>
        </Col>

        <Col xs={4} md={3} lg={3} className="px-0">
          <button
            disabled={VerifyField() == true ? false : true}
            className="mb-0 modal-footer-btn btn-confirm"
            style={{
              backgroundColor:
                VerifyField() == true ? Colors.brand_pink : Colors.lighter_gray,
            }}
            onClick={() => {
              var foundStage = listStages.find((i) => i.id == idStage);

              var newDescriptions = [];
              descriptions.map((item) => {
                var obj = {};
                obj.description_ids = item.description_ids
                  ? item.description_ids
                  : null;
                if (item.typeItem === "check-multi") {
                  obj.description_ids = item.description_ids
                    ? item.description_ids
                    : [];
                } else {
                  obj.description_id = item.description_id;
                }
                obj.type_description_id = item.type_description_id;
                obj.description_name = item.description_name;
                obj.value = item.value;
                newDescriptions.push(obj);
              });

              var newImages = [];
              images.map((item) => {
                var obj = {};
                obj.file = item.file;
                obj.path = item.path;
                obj.fileName = item.fileName;
                obj.created_at = item.created_at;
                newImages.push(obj);
              });

              var obj = {
                medical_devices: medicalDevices,
                type: type,
                height: height,
                width: width,
                depth: depth,
                wound_id: wound.value,
                wound_name: wound.label,
                is_other: other_wound == "" ? false : true,
                stage_id: idStage,
                stage_name: foundStage?.name,
                note: note,
                wound_type_note: woundTypeNote,
                user: userChooser,
                descriptions: newDescriptions,
                images: newImages,
                type_description_name: newName,
                value: newSlider,
              };

              var found = list_add.find((i) => i.wound_name == wound.label);
              if (found != undefined) {
                var newListAddWounds = list_add.filter(
                  (i) => i.wound_id != wound.value
                );
                newListAddWounds.push(obj);
                handler_list_add(newListAddWounds);
              } else {
                handler_list_add([...list_add, obj]);
              }

              handler_other_wound("");
              handler_show_wound(false);
            }}
          >
            <p
              className="mb-0"
              style={{
                color: VerifyField() == true ? Colors.white : Colors.dark_gray,
              }}
            >
              Adicionar
            </p>
          </button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalWound;
