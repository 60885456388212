import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
  Table,
  Dropdown,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import Icon from "../../../../../functions/Icon";
import api from "../../../../../services/api";
import { Show_Alert } from "../../../../../store/actions/alert";
import Colors from "../../../../../styles/Colors";
import "../../../../../styles/global.css";
import {
  buildPagination,
  renderDisplay,
  setDisplay,
} from "../../../../../functions/utils";
import moment from "moment";
import "./styles.css";

import DetailsCheckListHistoric from "./DetailsCheckListHistoric";
import DetailsAvaliationHistoric from "./DetailsAvaliationHistoric";
import DetailsDoubleHistoric from "./DetailsDoubleHistoric";
import DetailsDecubitoHistoric from "./DetailsDecubitoHistoric";
import { renderPhoto } from "../../../../../components/Dropdowns/ProfileDropdown";
import NewPagination from "../../../../../components/NewPagination";

function Historico() {
  const dispatch = useDispatch();
  const patientSelected = useSelector((state) => state.patientSelected);

  const [loading, setLoading] = useState(true);
  const [date_start, setDate_start] = useState("");
  const [date_end, setDate_end] = useState("");

  const [listType, setListType] = useState([]);
  const [type, setType] = useState([{ label: "Todas", value: 0 }]);

  const [listHistoric, setListHistoric] = useState([]);
  const [details, setDetails] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [showModalCheckList, setShowModalCheckList] = useState(false);
  const [showModalAvaliation, setShowModalAvaliation] = useState(false);
  const [showModalDouble, setShowModalDouble] = useState(false);
  const [showModalDecubito, setShowModalDecubito] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [qtdDisplay, setQtdDisplay] = useState(12);
  const [totalResults, setTotalResults] = useState(null);

  const [hoverBack, setHoverBack] = useState(false);
  const [hoverNext, setHoverNext] = useState(false);

  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    function LoadType() {
      api
        .get("/patients/historic/types")
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadType success");
            console.log(response.data);

            var array = [{ label: "Todas", value: 0 }];
            response.data.map((item) => {
              var obj = {};
              obj.label = item.name;
              obj.value = item.id;
              array.push(obj);
            });
            setListType(array);
          }
        })
        .catch((error) => {
          console.log("LoadType error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar a lista de ações",
            })
          );
        });
    }

    LoadType();
  }, []);

  useEffect(() => {
    function LoadHistoric() {
      let date_start_convert = "";
      let date_end_convert = "";
      if (date_start != "") {
        date_start_convert = moment(date_start).format("YYYY-MM-DD");
      }
      if (date_end != "") {
        date_end_convert = moment(date_end).format("YYYY-MM-DD");
      }

      api
        .get(
          `/patients/${patientSelected.dados.id}/historic?page=${currentPage}&pageSize=${qtdDisplay}`,
          {
            params: {
              date_start: date_start_convert,
              date_end: date_end_convert,
              type_historic_id: type.value == 0 ? "all" : type.value,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadHistoric success");
            console.log(response.data);
            setListHistoric(response.data.data);
            setLastPage(response.data.lastPage);
            setTotalResults(response.data.total);
            buildPagination(
              response.data.page,
              response.data.lastPage,
              setPagination
            );
          }
        })
        .catch((error) => {
          console.log("LoadHistoric error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar o histórico desse paciente",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    LoadHistoric();
  }, [date_end, type, currentPage, qtdDisplay]);

  function renderUserImg(obj) {
    var url = require("../../../../../assets/img/icon_user.png");
    if (obj?.image != null) {
      url = obj.url_image;
    }

    return (
      <img style={{ width: 40, height: 40, borderRadius: "100%" }} src={url} />
    );
  }

  function renderModal() {
    return (
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
            }}
          >
            <div>
              <div className="modal-title">Detalhes da ação</div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => setShowModal(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            <p className="details-text">{details.details.text}</p>

            {details.details.text == "Recebeu alta." && (
              <>
                <p className="details-text">{details.details.motive_name}</p>
                {/* <p className="details-text">
                  {" "}
                  {details.details.is_wound == "true"
                    ? "Com lesão"
                    : "Sem lesão"}
                </p> */}
              </>
            )}

            {details.details.is_wound == "true" && (
              <div className="mt-3">
                {details.details.wounds.map((item, index) => {
                  return (
                    <div key={index} className="container-wound">
                      <div className="container-wound-modal">
                        <div className="d-flex justify-content-between">
                          <p className="wound-title">Local</p>
                          <p className="wound-subtitle">{item.wound_name}</p>
                        </div>

                        <div className="d-flex justify-content-between">
                          <p className="wound-title">Classificação</p>
                          <p className="wound-subtitle">{item.stage_name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {details.type_historic_patient_id == 3 && (
              <div>
                <div className="container-wound mt-4">
                  <div
                    className="container-wound-modal"
                    style={{ borderBottomWidth: 0 }}
                  >
                    <div className="d-flex justify-content-between">
                      <p className="wound-title">Local</p>
                      <p className="wound-subtitle">
                        {details.details.wound_name}
                      </p>
                    </div>

                    <div className="d-flex justify-content-between">
                      <p className="wound-title">Classificação</p>
                      <p className="wound-subtitle">
                        {details.details.stage_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {details.details.type != undefined && (
              <div>
                <div
                  className="card-data-sm mt-3"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Icon
                    color={Colors.brand_blue}
                    size={20}
                    icon={
                      details.details.type == "admission" ? "enter" : "hospital"
                    }
                    className="mr-2"
                  />

                  {details.details.type == "admission"
                    ? "Admitida"
                    : "Incidida"}
                </div>

                <div>
                  <div className="container-wound mt-4">
                    <div
                      className="container-wound-modal"
                      style={{ borderBottomWidth: 0 }}
                    >
                      <div className="d-flex justify-content-between">
                        <p className="wound-title">Local</p>
                        <p className="wound-subtitle">
                          {details.details.wound_name}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between">
                        <p className="wound-title">Classificação</p>
                        <p className="wound-subtitle">
                          {details.details.stage_name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer
          style={{ border: "none" }}
          className="pt-0 d-flex justify-content-end align-items-center"
        >
          <div className="w-100">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="c-profile-dropdown">
                {renderPhoto({ dados: details.user })}
              </div>

              <div className="dados-default">
                <div className="name-default">Por {details.user?.name}</div>
                <div className="date-default">
                  {moment(details.created_at).format("LLL")}
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  function handleCallback(start, end) {
    setDate_start(moment(start).format("YYYY-MM-DD"));
    setDate_end(moment(end).format("YYYY-MM-DD"));
  }

  return (
    <div>
      {details.id != undefined && showModal && renderModal()}
      {details.id != undefined && showModalCheckList && (
        <DetailsCheckListHistoric
          handler_show_modal={setShowModalCheckList}
          show_modal={showModalCheckList}
          check_list={details}
        />
      )}

      {details.id != undefined && showModalAvaliation && (
        <DetailsAvaliationHistoric
          handler_show_modal={setShowModalAvaliation}
          show_modal={showModalAvaliation}
          avaliation={details}
        />
      )}

      {details.id != undefined && showModalDouble && (
        <DetailsDoubleHistoric
          handler_show_modal={setShowModalDouble}
          show={showModalDouble}
          dados={details}
        />
      )}

      {details.id != undefined && showModalDecubito && (
        <DetailsDecubitoHistoric
          handler_show_modal={setShowModalDecubito}
          show={showModalDecubito}
          dados={details}
        />
      )}

      <h2 className="screen-name mb-4">Histórico do paciente</h2>

      <Row>
        <Col xs={12} md={6} xl={4}>
          <div className="mt-4">
            <Form.Label className="field-label">Período</Form.Label>
            <Row className="justify-content-start align-items-center mb-3 mx-0">
              <DateRangePicker
                initialSettings={{
                  locale: {
                    cancelLabel: "Cancelar",
                    applyLabel: "Aplicar",
                  },
                  applyButtonClasses: "btn-dr-custom",
                }}
                onCallback={(start, end) => handleCallback(start, end)}
              >
                <Button
                  style={{
                    backgroundColor: "transparent",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#ddd",
                    minHeight: 48,
                    borderRadius: 10,
                  }}
                  variant="light"
                  className="d-flex btn-date-range align-items-center"
                >
                  <Icon
                    color={Colors.darker_gray}
                    size={22}
                    icon="calendar"
                    className="mr-2"
                  />
                  <p className="m-0 text_e-left">
                    {date_start != ""
                      ? moment(date_start).format("DD/MM/YYYY")
                      : "Data inicial"}{" "}
                    -{" "}
                    {date_end != ""
                      ? moment(date_end).format("DD/MM/YYYY")
                      : "Data final"}
                  </p>
                </Button>
              </DateRangePicker>
            </Row>
          </div>
        </Col>

        <Col xs={12} md={6} xl={4}>
          <div className="mt-4">
            <Form.Label className="field-label">Ações</Form.Label>
            <Select
              captureMenuScroll={false}
              noOptionsMessage={() => "Sem opções"}
              classNamePrefix="react-select"
              options={listType}
              isClearable={false}
              value={type}
              onChange={(item) => {
                setType(item);
              }}
            />
          </div>
        </Col>
      </Row>

      {loading && (
        <div className="mt-3 d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!loading && listHistoric.length == 0 && (
        <div className="container-error-default">
          <p>
            Nenhum resultado foi encontrado, experimente alterar os filtros.
          </p>
        </div>
      )}

      {listHistoric.length > 0 && (
        <div className="mt-2">
          <Table className="patient-table" responsive hover>
            <thead>
              <tr>
                <th>Data</th>
                <th>Ação</th>
                <th>Responsável</th>
              </tr>
            </thead>
            <tbody>
              {listHistoric.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      setDetails(item);
                      if (
                        item.type_historic_patient_id == 5 ||
                        item.type_historic_patient_id == 6
                      ) {
                        setShowModalCheckList(true);
                      } else if (item.type_historic_patient_id == 4) {
                        setShowModalAvaliation(true);
                      } else if (
                        item.type_historic_patient_id == 8 ||
                        item.type_historic_patient_id == 10
                      ) {
                        if (item.details.users == undefined) {
                          setShowModalDouble(true);
                        } else {
                          setShowModalDecubito(true);
                        }
                      } else {
                        setShowModal(true);
                      }
                    }}
                  >
                    <td>{moment(item.created_at).format("LLL")}</td>
                    <td>{item.title}</td>
                    <td>{item.user?.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <NewPagination
            currentPage={currentPage}
            handlerCurrentPage={setCurrentPage}
            lastPage={lastPage}
            handlerLastPage={setLastPage}
            qtdDisplay={qtdDisplay}
            handlerQtdDisplay={setQtdDisplay}
            totalResults={totalResults}
            handlerTotalResults={setTotalResults}
            pagination={pagination}
            handlerPagination={setPagination}
          />
        </div>
      )}
    </div>
  );
}

export default Historico;
