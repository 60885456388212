import React from "react";
import { Row, Col } from "react-bootstrap";
import { Doughnut as DoughnutChartJS } from "react-chartjs-2";

import DecubitOverview from "../DecubitOverview/DecubitOverview";
import DecubitWatchApp from "./src/App";
import Config from "./src/Config";

export default function DecubitWatch(props) {
  console.log("DecubitWatch", props);

  const {
    positionsSchedule: schedules,
    beaconsHistoricPosition: historics,
    date,
  } = props;

  const morning = new DecubitWatchApp({
    date,
    schedules,
    historics,
    period: "morning",
  });

  console.log("morning DEFAULT", morning);

  const aftermoon = new DecubitWatchApp({
    date,
    schedules,
    historics,
    period: "aftermoon",
  });

  console.log("aftermoon DEFAULT", aftermoon.getData());

  const overview = [...morning.getOverview(), ...aftermoon.getOverview()];

  return (
    <div className="c-chart c-chart__decubitWatch">
      <Row>
        {morning && (
          <Col xs={12} md={6} className="c-chart__container">
            <DoughnutChartJS {...Config} {...morning.getData()} />
          </Col>
        )}
        {aftermoon && (
          <Col xs={12} md={6} className="c-chart__container">
            <DoughnutChartJS {...Config} {...aftermoon.getData()} />
          </Col>
        )}

        {morning && aftermoon && (
          <Col xs={12} lg={10} className="offset-lg-2 mt-4">
            <DecubitOverview overview={overview} />
          </Col>
        )}
      </Row>
    </div>
  );
}
