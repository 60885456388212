import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";

import Icon from "../../functions/Icon";
import Colors from "../../styles/Colors";
import "../../styles/global.css";

import { useSelector, useDispatch } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

import "./styles.css";
import api from "../../services/api";
import list_wounds from "../../functions/list_wounds";
import { Show_Alert } from "../../store/actions/alert";
import CheckBox from "../CheckBox";
import {
  userHasPermission,
  userWithoutPermission,
} from "../../functions/utils";
import VerifyPwWithUser from "./VerifyPwWithUser";

var originalUsers = null;

function DischargePatient(props) {
  const {
    patient,
    listPatient,
    handler_patient,
    handler_show_discharge,
    show_discharge,
    handler_reload,
    reload,
    handler_user,
    handler_show_pw,
    id_user,
    handler_reload_beacons,
  } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [listMotives, setListMotives] = useState([]);
  const [showPw, setShowPw] = useState(false);

  //Form
  const [idMotive, setIdMotive] = useState(0);
  const [hasWound, setHasWound] = useState(null);
  const [wounds, setWounds] = useState([{ wound_id: 0, stage_id: 0 }]);
  const [other, setOther] = useState("");
  const [item, setItem] = useState({});

  const [loadingBtn, setLoadingBtn] = useState("none");

  useEffect(() => {
    if (userHasPermission(user)) {
      handler_user(user.dados.id);
    }
  }, []);

  useEffect(() => {
    function LoadMotives() {
      api
        .get(`/motive_discharge_hospitals`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadMotives success");
            console.log(response.data);
            setListMotives(response.data);
          }
        })
        .catch((error) => {
          console.log("LoadMotives " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar os motivos.",
            })
          );
        });
    }

    LoadMotives();
  }, []);

  function FullFields() {
    let verify = false;

    if (idMotive > 0) {
      verify = true;

      if (item.name == "Outros") {
        if (other == "") verify = false;
      }
    }

    //Essa verificação era usada usantes de remover a parte de lesoes
    // if (idMotive > 0 && hasWound != null && id_user > 0) {
    //   verify = true;
    // }
    // if (hasWound) {
    //   if (wounds[0].wound_id > 0 && wounds[0].stage_id > 0) {
    //     verify = true;
    //   } else {
    //     verify = false;
    //   }
    // }

    return verify;
  }

  function Discharge(userId) {
    setLoadingBtn("flex");
    api
      .post(`/patients/${patient.id}/discharge_hospital`, {
        user_id: userId ? userId : id_user,
        motive_discharge_hospital_id: idMotive,
        is_wound: hasWound,
        wounds,
        other_motive: other,
      })
      .then((response) => {
        if ((response.status = 200)) {
          //Filtra lista pacientes removendo o paciente que foi dado alta
          var list = listPatient;
          var newList = list.filter((item) => item.id != patient.id);
          handler_patient(newList);

          //Atualiza a lista de paciente
          handler_reload(!reload);
          handler_reload_beacons(!reload);
          dispatch(
            Show_Alert({
              type: "success",
              msg: "Alta informada com sucesso",
            })
          );
        }
      })
      .catch((error) => {
        console.log("DischargePatient error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível concluir a alta do paciente. Tente novamente mais tarde.",
          })
        );
      })
      .finally(() => {
        ResetFields();
        setLoadingBtn("none");
        handler_show_discharge(false);
      });
  }

  function ResetFields() {
    handler_user("");
    setIdMotive(0);
    setHasWound(null);
    setWounds([]);
    setOther("");
  }

  return (
    <>
      <Modal
        show={show_discharge}
        onHide={() => handler_show_discharge(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <div className="modal-title">Dar alta</div>
            </div>
            <div>
              <div
                className="modal-close-btn"
                onClick={() => handler_show_discharge(false)}
              >
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>
          <div className="modal-all">
            <div className="header-discharge mt-3">
              <p className="patient mb-1">Paciente</p>
              {patient.id != undefined && (
                <>
                  <p className="patient-name mb-0">{patient.name}</p>
                  <div className="room-subroom mt-1">
                    {patient.room == null && patient.subroom}
                    {patient.room != null &&
                      patient.subroom != null &&
                      `${patient.room}  |  ${patient.subroom}`}
                  </div>
                </>
              )}
            </div>

            <div className="section-discharge mt-4">
              <p className="title mb-3">Motivo</p>
              <div className="justify-content-start align-items-center mb-3">
                {listMotives.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="modal-item"
                      onClick={() => {
                        setOther("");
                        setItem(item);
                        setIdMotive(item.id);
                      }}
                    >
                      <p
                        style={{
                          color:
                            item.id === idMotive
                              ? Colors.brand_blue
                              : Colors.darkest_gray,
                        }}
                        className="modal-name mb-0"
                      >
                        {item.name}
                      </p>

                      <CheckBox
                        checked={item.id == idMotive}
                        value={item.id}
                        onclick={setIdMotive}
                      />
                    </div>
                  );
                })}
              </div>

              {item.name == "Outros" && (
                <input
                  className="default-input"
                  type="text"
                  placeholder="Digite o motivo aqui"
                  onChange={(e) => setOther(e.target.value)}
                />
              )}
            </div>
            <div className="section-discharge mt-4">
              {/* <p className="title">Lesões</p>
            <div className="d-flex">
              <button
                style={{
                  background:
                    hasWound == true ? Colors.brand_blue : Colors.lightest_gray,
                }}
                className="btn-click"
                onClick={() => setHasWound(true)}
              >
                <p
                  className="mb-0"
                  style={{
                    color: hasWound == true ? "white" : Colors.darker_gray,
                  }}
                >
                  Com lesão
                </p>
              </button>
              <button
                style={{
                  background:
                    hasWound == false
                      ? Colors.brand_blue
                      : Colors.lightest_gray,
                }}
                className="btn-click"
                onClick={() => {
                  setWounds([{ wound_id: 0, stage_id: 0 }]);
                  setHasWound(false);
                }}
              >
                <p
                  className="mb-0"
                  style={{
                    color: hasWound == false ? "white" : Colors.darker_gray,
                  }}
                >
                  {" "}
                  Sem lesão
                </p>
              </button>
            </div>

            {hasWound == true && (
              <div className="section-discharge mt-2">
                {wounds.map((item, index) => {
                  return (
                    <Row key={index} className="mb-3">
                      <Col className="mb-3 mb-md-0" col={12} md={5}>
                        <Form.Label className="field-label">
                          Local da lesão
                        </Form.Label>
                        <Select
                  captureMenuScroll={false}
                          classNamePrefix="react-select"
                          placeholder={"Selecionar"}
                          options={listWounds}
                          isClearable={false}
                          onChange={(item) => {
                            var newList = wounds.map((it, ind) => {
                              if (ind == index) {
                                it.stage_id = item.value;
                              }
                              return it;
                            });
                            setWounds(newList);
                          }}
                        />
                      </Col>

                      <Col col={12} md={5}>
                        <Form.Label className="field-label">
                          Estágio da lesão
                        </Form.Label>
                        <Select
                  captureMenuScroll={false}
                          classNamePrefix="react-select"
                          placeholder={"Selecionar"}
                          options={listStages}
                          isClearable={false}
                          onChange={(item) => {
                            var newList = wounds.map((it, ind) => {
                              if (ind == index) {
                                it.wound_id = item.value;
                              }
                              return it;
                            });
                            setWounds(newList);
                          }}
                        />
                      </Col>

                      <Col col={12} md={2}>
                        <button
                          title="Remover local"
                          className="btn-remove w-100"
                          disabled={index == 0 ? true : false}
                          onClick={() => {
                            var newList = wounds.filter((c, d) => {
                              if (index != d) {
                                return c;
                              }
                            });
                            setWounds(newList);
                          }}
                        >
                          <Icon
                            color={
                              index == 0
                                ? Colors.light_gray
                                : Colors.darkest_gray
                            }
                            size={15}
                            icon="minus"
                          />
                        </button>
                      </Col>
                    </Row>
                  );
                })}

                <button
                  className="d-flex align-items-center mt-3 reset-button"
                  onClick={() =>
                    setWounds([...wounds, { wound_id: 0, stage_id: 0 }])
                  }
                >
                  <Icon
                    color={Colors.brand_pink}
                    size={15}
                    icon="plus"
                    className="mr-2"
                  />
                  <p className="mb-0 add-other-text">Adicionar outra lesão</p>
                </button>
              </div>
            )} */}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              className="secondary-btn modal-footer-btn mr-0 btn-cancel"
              onClick={() => handler_show_discharge(false)}
            >
              Cancelar
            </button>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={
                (FullFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  FullFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                if (!userWithoutPermission(user)) {
                  Discharge();
                  return;
                } else {
                  setShowPw(true);
                }
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: FullFields() == true ? Colors.white : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Confirmar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
      {showPw && (
        <VerifyPwWithUser
          handler_show_pw={setShowPw}
          show_pw={showPw}
          modal_name={"Autenticar confirmação"}
          handler_request={Discharge}
        />
      )}
    </>
  );
}

export default DischargePatient;
