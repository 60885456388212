import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import MainNavbar from "../../../components/Nav/MainNavbar";

import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import "./styles.css";
import Icon from "../../../functions/Icon";
import IconAchievements from "../../../functions/IconAchievements";
import Colors from "../../../styles/Colors";
import { HexToRgbA } from "../../../functions/utils";

const arrayMeses = [
  {
    label: "Janeiro",
    value: 1,
  },
  {
    label: "Fevereiro",
    value: 2,
  },
  {
    label: "Março",
    value: 3,
  },
  {
    label: "Abril",
    value: 4,
  },
  {
    label: "Maio",
    value: 5,
  },
  {
    label: "Junho",
    value: 6,
  },
  {
    label: "Julho",
    value: 7,
  },
  {
    label: "Agosto",
    value: 8,
  },
  {
    label: "Setembro",
    value: 9,
  },
  {
    label: "Outubro",
    value: 10,
  },
  {
    label: "Novembro",
    value: 11,
  },
  {
    label: "Dezembro",
    value: 12,
  },
];

const iconFisrt = require("../../../assets/img/first.png");
const iconSecond = require("../../../assets/img/second.png");
const iconThird = require("../../../assets/img/third.png");

function Ranking({ history }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userUnit = useSelector((state) => state.userUnit);
  const unitS = useSelector((state) => state.unit);

  const [loading, setLoading] = useState(true);
  const [aba, setAba] = useState("unit");
  const [unit, setUnit] = useState({});
  const [month, setMonth] = useState({});
  const [listUnits, setListUnits] = useState([]);
  const [meses, setMeses] = useState(arrayMeses);
  const [listRanking, setListRanking] = useState([]);
  const [topRanking, setTopRanking] = useState([]);
  const [topList, setTopList] = useState([]);
  const [userLogado, setUserLogado] = useState({});

  useEffect(() => {
    var date = new Date();
    var m = date.getMonth() + 1;

    var newList = meses.filter((item) => {
      if (item.value <= m) return item;
    });

    if (m < 10) {
      m = `0${m}`;
    }

    var mesAtual = meses.find((item) => item.value == m);

    setMeses(newList);
    setMonth(mesAtual);
  }, []);

  useEffect(() => {
    var array = [];
    userUnit.list.map((item, index) => {
      var obj = {};
      obj.label = item.name;
      obj.value = item.id;
      if (index == 0) setUnit(obj);
      array.push(obj);
    });
    setListUnits(array);
  }, []);

  useEffect(() => {
    function LoadRanking() {
      let monthConvert = month.value <= 9 ? `0${month.value}` : month.value;

      var obj = {
        month: monthConvert,
        unit_id: unit.value,
      };
      if (aba == "hospital") {
        obj = {
          month: monthConvert,
          hospital_id: user.dados.hospital_id,
        };
      }

      api
        .get(`/ranking/general`, {
          params: obj,
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadRanking");
            console.log(response.data);

            var obj = { void: true };
            var arrayTopRanking = [];
            if (response.data.general[1]?.name != undefined)
              arrayTopRanking.push(response.data.general[1]);
            else arrayTopRanking.push(obj);

            if (response.data.general[0]?.name != undefined)
              arrayTopRanking.push(response.data.general[0]);
            else arrayTopRanking.push(obj);

            if (response.data.general[2]?.name != undefined)
              arrayTopRanking.push(response.data.general[2]);
            else arrayTopRanking.push(obj);

            //Top 3 modificado (ordem alterada => 2,1,3)
            setTopRanking(arrayTopRanking);

            //Do 4 em diante
            setListRanking(response.data.general.slice(3));

            //Top 3 original
            setTopList(response.data.general.slice(0, 3));

            //User logado
            var objUserLogado = response.data.user;
            if (response.data.user.name == undefined) {
              objUserLogado = {
                name: user.dados.name,
                ranking_number: 0,
                counter: 0,
                image:
                  user.dados.image != null
                    ? user.dados.url_image
                    : require("../../../assets/img/icon_user.png"),
              };
            }
            setUserLogado(objUserLogado);
          }
        })
        .catch((error) => {
          console.log("LoadRanking error " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar o ranking.",
            })
          );
        })
        .finally(() => setLoading(false));
    }

    console.log("here");
    console.log(unitS.selecionado?.id);
    if (unitS.selecionado?.id != undefined) {
      LoadRanking();
    } else {
      setLoading(false);
    }
  }, [unitS, unit, month, aba]);

  function VerifyRanking() {
    var verify = false;
    var found = topRanking.find((item) => item.void == undefined);
    if (found != undefined) verify = true;
    return verify;
  }

  function Destaques(props) {
    var ranking = props.ranking;

    return (
      <div
        className="destaques-card bg-white mt-2"
        style={{ display: "flex", flexDirection: "row" }}
      >
        {ranking[0].void != true && (
          <div className="col-destaques mr-5 position-relative">
            <img
              className="medium-photo mb-2 mt-2"
              src={
                ranking[0].image != null
                  ? ranking[0].image
                  : require("../../../assets/img/icon_user.png")
              }
            />
            <div className="card-name mt-3">
              {ranking[0].name.split(" ")[0]}
            </div>
            <div className="card-score">{ranking[0].counter} pontos</div>
            <div
              style={{
                position: "absolute",
                right: 0,
              }}
            >
              <img style={{ width: 28, height: 28 }} src={iconSecond} />
            </div>
          </div>
        )}

        {ranking[1].void != true && (
          <div className="col-destaques position-relative mr-5">
            <img
              className="large-photo"
              src={
                ranking[1].image != null
                  ? ranking[1].image
                  : require("../../../assets/img/icon_user.png")
              }
            />
            <div className="card-name mt-3">
              {ranking[1].name.split(" ")[0]}
            </div>
            <div className="card-score">{ranking[1].counter} pontos</div>
            <div
              style={{
                position: "absolute",
                right: 0,
              }}
            >
              <img style={{ width: 32, height: 32 }} src={iconFisrt} />
            </div>
          </div>
        )}

        {ranking[2].void != true && (
          <div className="col-destaques position-relative">
            <img
              className="medium-photo mb-2 mt-2"
              src={
                ranking[2].image != null
                  ? ranking[2].image
                  : require("../../../assets/img/icon_user.png")
              }
            />
            <div className="card-name mt-3">
              {ranking[2].name.split(" ")[0]}
            </div>
            <div className="card-score">{ranking[2].counter} pontos</div>
            <div
              style={{
                position: "absolute",
                right: 0,
              }}
            >
              <img style={{ width: 30, height: 30 }} src={iconThird} />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <MainNavbar history={history} />

      <Container
        fluid
        className="bg-white"
        style={{ paddingRight: 80, paddingLeft: 80 }}
      >
        <div className="container-header-screen">
          <h2>Ranking</h2>
        </div>

        <div className="px-2 mt-4">
          <Row className="tabs mt-4">
            <Col xs={6} md={2} lg={2} onClick={() => setAba("unit")}>
              <h6
                className={aba == "unit" ? "active-text" : ""}
                onClick={() => setAba("unit")}
              >
                Unidade
              </h6>
            </Col>
            <Col xs={6} md={2} lg={2} onClick={() => setAba("hospital")}>
              <h6
                className={aba == "hospital" ? "active-text" : ""}
                onClick={() => setAba("hospital")}
              >
                Hospital
              </h6>
            </Col>
          </Row>

          <Row className="tab-bar">
            <Col
              xs={2}
              md={2}
              lg={2}
              onClick={() => setAba("unit")}
              className={aba == "unit" ? "active-tab" : ""}
            />
            <Col
              xs={2}
              md={2}
              lg={2}
              onClick={() => setAba("hospital")}
              className={aba == "hospital" ? "active-tab" : ""}
            />
            <Col xs={8} md={8} lg={8}></Col>
          </Row>
        </div>

        <Row>
          {aba == "unit" && (
            <Col col={12} md={4} lg={2}>
              <Form.Label className="field-label">Unidade</Form.Label>
              <Select
                captureMenuScroll={false}
                classNamePrefix="react-select"
                placeholder={"Selecionar"}
                options={listUnits}
                isClearable={false}
                value={unit}
                onChange={(item) => setUnit(item)}
              />
            </Col>
          )}

          <Col col={12} md={4} lg={2}>
            <Form.Label className="field-label">Mês</Form.Label>
            <Select
              captureMenuScroll={false}
              classNamePrefix="react-select"
              placeholder={"Selecionar"}
              options={meses}
              isClearable={false}
              value={month}
              onChange={(item) => setMonth(item)}
            />
          </Col>
        </Row>

        {!loading && !VerifyRanking() && (
          <div className="container-error-default mt-3">
            <p>
              Os profissionais mais engajados serão reconhecidos e exibidos
              aqui.
            </p>
          </div>
        )}

        {loading && (
          <div className="mt-3 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {!loading && VerifyRanking() && <Destaques ranking={topRanking} />}

        {!loading && VerifyRanking() && (
          <Row className="mt-4">
            <Col xs={12} md={9}>
              <div>
                {topList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`ranking-item ${
                        item.name == user.dados.name && "user-logado"
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          style={{ width: 28, height: 28 }}
                          src={
                            index == 0
                              ? iconFisrt
                              : index == 1
                              ? iconSecond
                              : iconThird
                          }
                        />
                        <div className="d-flex align-items-center">
                          <img
                            className="ranking-user-img"
                            src={
                              item.image != null
                                ? item.image
                                : require("../../../assets/img/icon_user.png")
                            }
                          />
                          <p className="ranking-username">{item.name}</p>
                        </div>
                      </div>
                      <p className="ranking-points-list">{item.counter}</p>
                    </div>
                  );
                })}

                {listRanking.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`ranking-item ${
                        item.name == user.dados.name && "user-logado"
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <p className="ranking-position">{index + 4}°</p>

                        <div className="d-flex align-items-center">
                          <img
                            className="ranking-user-img"
                            src={
                              item.image != null
                                ? item.image
                                : require("../../../assets/img/icon_user.png")
                            }
                          />
                          <p className="ranking-username">{item.name}</p>
                        </div>
                      </div>
                      <p className="ranking-points-list">{item.counter}</p>
                    </div>
                  );
                })}
              </div>
            </Col>

            <Col xs={12} md={3} className="mt-3 mt-md-0">
              <div>
                <p className="your">Seu desempenho esse mês</p>

                <div className="right-logado mt-2 mb-4">
                  <img
                    className="ranking-user-img m-0"
                    src={
                      userLogado.image != null
                        ? userLogado.image
                        : require("../../../assets/img/icon_user.png")
                    }
                  />
                  <p>{userLogado.name}</p>
                </div>

                <Row>
                  <Col xs={12} xl={6} className="mb-2 mb-xl-0">
                    <div className="right-item">
                      <Icon color={Colors.brand_blue} size={24} icon="podium" />
                      <p className="right-title">
                        {userLogado.ranking_number}°
                      </p>
                      <p className="right-subtitle">Posição esse mês</p>
                    </div>
                  </Col>

                  <Col xs={12} xl={6}>
                    <div className="right-item">
                      <Icon color={Colors.brand_blue} size={24} icon="medal" />
                      <p className="right-title">{userLogado.counter}</p>
                      <p className="right-subtitle">Pontuação no mês</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default Ranking;
