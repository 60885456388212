import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Container,
    Row,
    Button,
} from "react-bootstrap";
import MainNavbar from "../../../components/Nav/MainNavbar";
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import moment from "moment";

import Colors from "../../../styles/Colors";
import "../../../styles/global.css";
import "../styles.css";

import Icon from "../../../functions/Icon";

const MOCK = {
    id: 1,
    title: "Noticia 1 v.1",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    link_extern: "https://br.lipsum.com/feed/html",
    date_published: "2020-03-15T03:00:00.000Z",
    status: 1,
    type_news_id: 1,
    hospital_id: null,
    created_at: "2020-04-29 15:04:52",
    updated_at: "2020-04-29 15:14:23",
    images: [
        {
            "id": 1,
            "news_id": 1,
            "path": "1588190261343-AJUDA_VCGANHA_1.jpg",
            "created_at": "2020-04-29 16:57:41",
            "updated_at": "2020-04-29 16:57:41",
            "url": "http://127.0.0.1:3333/images_news/1588190261343-AJUDA_VCGANHA_1.jpg"
        }
    ]
}

const MOCK_MAIS = [
    {
        id: 1,
        title: 'Lorem ipsum dolor sit amet',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum mi in dignissim aliquet. Donec feugiat dolor ac elit imperdiet placerat. Sed aliquam ullamcorper pharetra. Sed aliquet, lorem eget ornare fringilla, dui neque pellentesque lectus, a ultricies turpis velit sit amet mauris. Aliquam erat volutpat. Nunc sodales convallis varius. Vivamus scelerisque ex scelerisque luctus interdum. Nulla rhoncus justo quis velit dapibus tincidunt. Ut vel rutrum ex. Suspendisse potenti. Sed sit amet aliquet leo. Sed eu luctus metus. Aliquam id rhoncus tortor. Donec ultrices, orci in sodales aliquam, erat mauris rhoncus orci, sed posuere velit dolor vel enim. Curabitur cursus quam ut nunc lobortis, vitae auctor turpis eleifend. Nam quis venenatis lectus. Etiam vulputate dolor eget enim dictum vehicula. Mauris tristique rutrum mi. Suspendisse luctus egestas elementum. Etiam elit nisl, condimentum id porta ut, luctus non justo.',
        date_published: '2020-03-15T03:00:00.000Z',
    },
    {
        id: 2,
        title: 'Lorem ipsum dolor sit amet',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum mi in dignissim aliquet. Donec feugiat dolor ac elit imperdiet placerat. Sed aliquam ullamcorper pharetra. Sed aliquet, lorem eget ornare fringilla, dui neque pellentesque lectus, a ultricies turpis velit sit amet mauris. Aliquam erat volutpat. Nunc sodales convallis varius. Vivamus scelerisque ex scelerisque luctus interdum. Nulla rhoncus justo quis velit dapibus tincidunt. Ut vel rutrum ex. Suspendisse potenti. Sed sit amet aliquet leo. Sed eu luctus metus. Aliquam id rhoncus tortor. Donec ultrices, orci in sodales aliquam, erat mauris rhoncus orci, sed posuere velit dolor vel enim. Curabitur cursus quam ut nunc lobortis, vitae auctor turpis eleifend. Nam quis venenatis lectus. Etiam vulputate dolor eget enim dictum vehicula. Mauris tristique rutrum mi. Suspendisse luctus egestas elementum. Etiam elit nisl, condimentum id porta ut, luctus non justo.',
        date_published: '2020-03-15T03:00:00.000Z',
    },
    {
        id: 3,
        title: 'Lorem ipsum dolor sit amet',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vestibulum mi in dignissim aliquet. Donec feugiat dolor ac elit imperdiet placerat. Sed aliquam ullamcorper pharetra. Sed aliquet, lorem eget ornare fringilla, dui neque pellentesque lectus, a ultricies turpis velit sit amet mauris. Aliquam erat volutpat. Nunc sodales convallis varius. Vivamus scelerisque ex scelerisque luctus interdum. Nulla rhoncus justo quis velit dapibus tincidunt. Ut vel rutrum ex. Suspendisse potenti. Sed sit amet aliquet leo. Sed eu luctus metus. Aliquam id rhoncus tortor. Donec ultrices, orci in sodales aliquam, erat mauris rhoncus orci, sed posuere velit dolor vel enim. Curabitur cursus quam ut nunc lobortis, vitae auctor turpis eleifend. Nam quis venenatis lectus. Etiam vulputate dolor eget enim dictum vehicula. Mauris tristique rutrum mi. Suspendisse luctus egestas elementum. Etiam elit nisl, condimentum id porta ut, luctus non justo.',
        date_published: '2020-03-15T03:00:00.000Z',
    },
]

function Detalhes({ history, news }) {

    // Formatação de datas
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    moment.locale("pt-BR")

    // Data publicação
    var datef_published = new Date(MOCK.date_published)
    var timef = datef_published.toTimeString()
    datef_published = moment(datef_published).format("DD,MMMM,YYYY,HH:mm").split(",")
    var month_published = datef_published[1].charAt(0).toUpperCase() + datef_published[1].slice(1)
    datef_published = datef_published[0] + " de " + month_published + " de " + datef_published[2] + " às " + datef_published[3]


    return(
        <div>
            <MainNavbar history={history} />

            <Container fluid className="main-container" style={{ display: 'flex', flexDirection: 'column' }}>
                <Breadcrumb
                    history={history}
                    list_breadcrumb={[
                        { name: "Descubra", url: "Descubra" },
                        { name: "Comunicados", url: "Descubra/Comunicados" }, // substituir por tipo de news
                        { name: "Detalhes", url: "Descubra/Detalhes" },
                    ]}
                />

                <h2 className="title mb-3 mt-2">Lorem ipsum dolor sit amet</h2>

                <h6 className="subtitle">{ datef_published }</h6>

                <img
                        src={require("../../../assets/img/section2@2x.png")}
                        className="header-img mb-5"
                />

                <div className="post-content mb-5">
                    { MOCK.content }
                </div>

                <h6 className="veja-mais mb-3 mt-2 align-self-start">
                    <b>Veja também</b>
                </h6>

                <Row className="align-items-center">
                    {MOCK_MAIS.map((item) => {
                        const options = { year: 'numeric', month: 'long', day: 'numeric' };
                        var datef = new Date(item.date_published)
                        var timef = datef.toTimeString()

                        moment.locale("pt-BR")
                        datef = moment(datef).format("DD,MMMM,YYYY,HH:mm").split(",")

                        var month = datef[1].charAt(0).toUpperCase() + datef[1].slice(1)

                        datef = datef[0] + " de " + month + " de " + datef[2] + " às " + datef[3]

                        return(
                            <Col xs={6} md={4} lg={4} className="mb-4">
                                <div className="main-card">
                                    <div className="card-title">{item.title}</div>
                                    <div className="card-desc">{item.content.substring(0, 135)}...</div>
                                    <div className="card-date mt-2">{datef}</div>
                                </div>
                            </Col>
                        )

                        // fazer um if com tipo de news
                        // return(
                        //     <Col xs={6} md={4} lg={4} className="mb-4">
                        //         <div className="main-card">
                        //             <div className="card-title">{item.title}</div>
                        //             <div className="card-date mt-2">Última revisão: {datef}</div>
                        //         </div>
                        //     </Col>
                        // )
                    })}
                </Row>

            </Container>
        </div>
    );
}

export default Detalhes;