import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import CheckBox from "../../../components/CheckBox";
import RadioBtn from "../../../components/RadioBtn";
import Select from "react-select";

import Colors from "../../../styles/Colors";
import "../../../styles/global.css";
import "./styles.css";

import api from "../../../services/api";
import { Show_Alert } from "../../../store/actions/alert";
import Icon from "../../../functions/Icon";
import { useSelector, useDispatch } from "react-redux";
import list_wounds from "../../../functions/list_wounds";
import moment from "moment";

var originalUsers = null;

function ModalDecubito(props) {
  const dispatch = useDispatch();
  const { handler_show_modal, show, data, handler_reload, reload } = props;
  const unit = useSelector((state) => state.unit);

  // Exibição do modal
  const [dados, setDados] = useState(data);

  // Página do modal
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
    // setShow(props.show);
    setDados(data);
  }, [data, show]);

  const [loadingBtn, setLoadingBtn] = useState("none");

  // Dados selecionados
  const [search, setSearch] = useState("");
  const [responsavel, setResponsavel] = useState([]);
  const [motivo, setMotivo] = useState(null);
  const [lesoes, setLesoes] = useState(null);
  const [hoverItem, setHoverItem] = useState(null);

  const [motiveChooser, setMotiveChooser] = useState({ id: 0 });
  const [listUserChooser, setListUserChooser] = useState([]);
  const [otherMotive, setOtherMotive] = useState("");

  const [listMotives, setListMotives] = useState([]);
  const [listUsers, setListUsers] = useState([]);

  const [hasWound, setHasWound] = useState(null);
  const [wounds, setWounds] = useState([{ wound_id: 0, stage_id: 0 }]);
  const [listWounds, setListWounds] = useState(list_wounds);
  const [listStages, setListStages] = useState([]);

  /*
  page 2 = Sim
  page 3 = Não
  page 4 = Suspender aviso
  */

  useEffect(() => {
    setListUserChooser([]);
    setMotiveChooser({ id: 0 });
    setOtherMotive("");
    setHasWound(null);
    setWounds([{ wound_id: 0, stage_id: 0 }]);
  }, [show]);

  useEffect(() => {
    function LoadStages() {
      api
        .get(`/stages`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadStages success");
            console.log(response.data);

            var array = [];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListStages(array);
          }
        })
        .catch((error) => {
          console.log("LoadPatient " + error);
          dispatch(
            Show_Alert({
              type: "error",
              msg: "Não foi possível carregar os estágios.",
            })
          );
        });
    }

    LoadStages();
  }, []);

  useEffect(() => {
    function LoadMotives() {
      api
        .get(`/motives`, {
          params: {
            is_suspend: page == 4 ? true : false,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadMotives success");
            console.log(response.data);
            setListMotives(response.data);
          }
        })
        .catch((error) => console.log("LoadMotives " + error));
    }

    LoadMotives();
  }, [page]);

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: unit.selecionado.id,
            name: search,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            originalUsers = response.data;
            setListUsers(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    LoadUsers();
  }, [page, search]);

  // Submits
  function Confirm() {
    setLoadingBtn("flex");
    var arrayNurses = [];
    listUserChooser.map((item) => {
      arrayNurses.push({ id: item.id });
    });

    // console.log("done " + page == 2 ? true : false);
    // console.log("unit_id " + unit.selecionado.id);
    // console.log("datetime_realized " + moment().format("YYYY-MM-DD HH:mm:ss"));
    // console.log("nurses ");
    // console.log(arrayNurses);
    // console.log("motives");
    // var m = {
    //   id: motiveChooser.id > 0 ? motiveChooser.id : "",
    //   other: otherMotive,
    //   is_suspend: "false",
    // };
    // console.log(m);
    // console.log("notification_id " + dados.notification_id);

    api
      .put(`/notifications/${dados.notification_id}`, {
        done: page == 2 ? true : false,
        unit_id: unit.selecionado.id,
        datetime_realized: moment().format("YYYY-MM-DD HH:mm:ss"),
        nurses: arrayNurses,
        motives: {
          id: motiveChooser.id > 0 ? motiveChooser.id : "",
          other: otherMotive,
          is_suspend: "false",
        },
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("Suspend success");
          console.log(response.data);
          var msg = "";

          if (page == 2) {
            msg = "A mudança de decúbito foi confirmada  com sucesso";
          } else if (page == 3) {
            msg = "Informado com sucesso";
          }

          dispatch(
            Show_Alert({
              type: "success",
              msg,
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("Suspend error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar essa alteração",
          })
        );
      })
      .finally(() => {
        ResetFields();
        setLoadingBtn("none");
        handler_show_modal(false);
      });
  }

  function Suspend() {
    setLoadingBtn("flex");
    var arrayNurses = [];
    listUserChooser.map((item) => {
      arrayNurses.push({ id: item.id });
    });

    api
      .put(`/patients/${dados.patient_id}/suspend_notifications`, {
        user_id: arrayNurses[0].id,
        motive_id: motiveChooser.id,
        other_motive: otherMotive,
        is_wound: hasWound,
        wounds,
      })
      .then((response) => {
        if (response.status == 204) {
          console.log("Suspend success");
          console.log(response.data);

          dispatch(
            Show_Alert({
              type: "success",
              msg: "Os avisos foram suspensos com sucesso",
            })
          );
          handler_reload(!reload);
        }
      })
      .catch((error) => {
        console.log("Suspend error " + error);
        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível salvar essa alteração",
          })
        );
      })
      .finally(() => {
        ResetFields();
        setLoadingBtn("none");
        handler_show_modal(false);
      });
  }

  function ResetFields() {
    setMotiveChooser({ id: 0 });
    setListUserChooser([]);
    setOtherMotive("");
    setHasWound(null);
    setWounds({ wound_id: 0, stage_id: 0 });
  }

  // console.log("motivos");
  // console.log(motiveChooser);

  function VerifyFields() {
    var ret = false;

    if (page == 2) {
      //Sim
      if (listUserChooser.length > 0) ret = true;
    } else if (page == 3) {
      //Não
      if (listUserChooser.length > 0 && motiveChooser.id > 0) ret = true;
    } else if (page == 4) {
      //Suspender aviso
      if (
        listUserChooser.length > 0 &&
        motiveChooser.id > 0 &&
        hasWound != null
      ) {
        ret = true;
      }

      if (hasWound) {
        wounds.map((item) => {
          if (item.wound_id == 0 || item.stage_id == 0) ret = false;
        });
      }
    }

    if (motiveChooser?.name == "Outros") {
      if (otherMotive == "") ret = false;
    }

    return ret;
  }

  function SetarUser(obj) {
    var found = listUserChooser.find((item) => item.id == obj.id);
    if (found != undefined) {
      var newList = listUserChooser.filter((item) => item.id != obj.id);
      setListUserChooser(newList);
    } else {
      if (listUserChooser.length == 4) return false;
      if (listUserChooser.length == 1 && page == 4) return false;
      setListUserChooser([...listUserChooser, obj]);
    }
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={() => handler_show_modal(false)}
        centered
        className="modal-decubito"
      >
        {page === 1 && (
          <Modal.Body
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 5,
              paddingBottom: 15,
            }}
          >
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Col xs={6} md={6} lg={6} className="pl-0">
                <div
                  className="status-tag"
                  style={{
                    backgroundColor:
                      dados.tag == "delayed"
                        ? Colors.brand_red
                        : dados.tag == "realized"
                        ? Colors.brand_green
                        : Colors.brand_yellow,
                  }}
                >
                  {dados.tag == "delayed"
                    ? "ATRASADA"
                    : dados.tag == "realized"
                    ? "REALIZADA"
                    : "EM BREVE"}
                </div>
              </Col>

              <Col
                xs={2}
                md={6}
                lg={6}
                className="pr-0 d-flex justify-content-end"
              >
                <div
                  className="modal-close-btn"
                  onClick={() => handler_show_modal(false)}
                >
                  <Icon color={Colors.darkest_gray} size={15} icon="x" />
                </div>
              </Col>
            </Row>

            <Row>
              <div className="modal-title-sm">{dados.name}</div>
            </Row>

            <Row>
              <div
                className="modal-text-sm mb-4"
                style={{ color: Colors.dark_gray }}
              >
                {dados.room == null && dados.subroom}
                {dados.room != null && dados.room + " | " + dados.subroom}
              </div>
            </Row>

            <Row className="mb-2 align-items-center">
              <Icon
                color={Colors.brand_blue}
                size={20}
                icon="clock"
                className="mr-2"
              />
              <div
                className="modal-text-sm"
                style={{ color: Colors.darker_gray }}
              >
                {moment(dados.datetime_schedule).format("LT")}
              </div>
            </Row>

            <Row className="mb-4 align-items-center">
              <Icon
                color={Colors.brand_blue}
                size={20}
                icon="refresh"
                className="mr-2"
              />
              <div
                className="modal-text-sm"
                style={{ color: Colors.darker_gray }}
              >
                {dados.position}
              </div>
            </Row>

            <Row className="mb-3 justify-content-center align-items-center">
              <div
                className="modal-text-bold"
                style={{ color: Colors.darker_gray }}
              >
                A mudança de decúbito foi realizada?
              </div>
            </Row>

            <Row className="mb-3 justify-content-center align-items-center">
              <Button
                className="btn-modal-footer mr-3"
                style={{ backgroundColor: Colors.brand_red }}
                onClick={() => setPage(3)}
              >
                <Icon color={"#fff"} size={20} icon="x" className="mr-2" />
                Não
              </Button>
              <Button
                className="btn-modal-footer"
                style={{ backgroundColor: Colors.brand_green }}
                onClick={() => setPage(2)}
              >
                <Icon color={"#fff"} size={20} icon="check" className="mr-2" />
                Sim
              </Button>
            </Row>

            <Row className="justify-content-center align-items-center">
              <a
                className="modal-link"
                style={{ color: Colors.dark_gray, cursor: "pointer" }}
                onClick={() => setPage(4)}
              >
                Suspender avisos
              </a>
            </Row>
          </Modal.Body>
        )}

        {page === 2 && (
          <>
            <Modal.Body
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 5,
                paddingBottom: 15,
              }}
            >
              <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col xs={8} md={10} lg={10} className="pl-0">
                    <div className="modal-title">Quem realizou a mudança?</div>
                  </Col>
                  <Col
                    xs={4}
                    md={2}
                    lg={2}
                    className="pr-0 d-flex justify-content-end"
                  >
                    <div
                      className="modal-close-btn"
                      onClick={() => handler_show_modal(false)}
                    >
                      <Icon color={Colors.darkest_gray} size={15} icon="x" />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="modal-list">
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <Row
                    className="mt-2 mb-1 modal-text-xs"
                    style={{ color: Colors.darkest_gray }}
                  >
                    Buscar responsável
                  </Row>

                  <Row className="mb-4">
                    <input
                      className="default-input md"
                      placeholder="Digite o nome do responsável"
                      onChange={(v) => setSearch(v.target.value)}
                    />
                  </Row>
                </div>

                {listUsers.map((item, index) => {
                  var found = listUserChooser.find((i) => i.id == item.id);
                  return (
                    <div
                      key={index}
                      className="modal-item modal-item-edit"
                      onClick={() => SetarUser(item)}
                    >
                      <p
                        style={{
                          color:
                            found != undefined
                              ? Colors.brand_blue
                              : Colors.darkest_gray,
                        }}
                        className="modal-name mb-0"
                      >
                        {item.name}
                      </p>

                      <CheckBox
                        checked={found != undefined}
                        value={item}
                        onclick={SetarUser}
                      />
                    </div>
                  );
                })}
              </div>
            </Modal.Body>

            <Modal.Footer className="row justify-content-end align-items-center pb-0 mx-0">
              <Col xs={4} md={3} lg={3} className="px-0 mr-2">
                <div
                  className="secondary-btn"
                  onClick={() => handler_show_modal(false)}
                >
                  Cancelar
                </div>
              </Col>
              <Col xs={4} md={3} lg={3} className="px-0">
                <button
                  disabled={
                    (VerifyFields() == true ? false : true) ||
                    loadingBtn === "flex"
                  }
                  className="mb-0 modal-footer-btn btn-confirm"
                  style={{
                    backgroundColor:
                      VerifyFields() == true
                        ? Colors.brand_pink
                        : Colors.lighter_gray,
                  }}
                  onClick={() => Confirm()}
                >
                  <p
                    className="mb-0"
                    style={{
                      color:
                        VerifyFields() == true ? "white" : Colors.dark_gray,
                      display: loadingBtn == "none" ? "flex" : "none",
                    }}
                  >
                    Confirmar
                  </p>

                  <Spinner
                    style={{ display: loadingBtn }}
                    animation="border"
                    role="status"
                    variant="light"
                    size="sm"
                  />
                </button>
              </Col>
            </Modal.Footer>
          </>
        )}

        {page === 3 && (
          <>
            <Modal.Body
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 5,
                paddingBottom: 15,
              }}
            >
              <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col xs={8} md={10} lg={10} className="pl-0">
                    <div className="modal-title">
                      Por que não foi realizada?
                    </div>
                  </Col>
                  <Col
                    xs={4}
                    md={2}
                    lg={2}
                    className="pr-0 d-flex justify-content-end"
                  >
                    <div
                      className="modal-close-btn"
                      onClick={() => handler_show_modal(false)}
                    >
                      <Icon color={Colors.darkest_gray} size={15} icon="x" />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="modal-list">
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <Row
                    className="mt-3 mb-1 modal-text-bold-sm"
                    style={{ color: Colors.darkest_gray }}
                  >
                    Motivo
                  </Row>
                </div>

                {listMotives.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="modal-item modal-item-edit"
                      onClick={() => {
                        setOtherMotive("");
                        setMotiveChooser(item);
                      }}
                    >
                      <p
                        style={{
                          color:
                            item.id === motiveChooser?.id
                              ? Colors.brand_blue
                              : Colors.darkest_gray,
                        }}
                        className="modal-name mb-0"
                      >
                        {item.name}
                      </p>

                      <div className="container-check">
                        <input
                          className={`input-round ${
                            item.id != motiveChooser?.id && "radio-inactive"
                          }`}
                          type="radio"
                          checked={item.id === motiveChooser?.id}
                          onClick={() => {
                            setOtherMotive("");
                            setMotiveChooser(item);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}

                {motiveChooser.name == "Outros" && (
                  <div className="mb-3 px-4">
                    <input
                      className="default-input md"
                      type="text"
                      placeholder="Digite outro motivo aqui"
                      value={otherMotive}
                      onChange={(e) => setOtherMotive(e.target.value)}
                    />
                  </div>
                )}

                <hr className="my-0" />
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <Row
                    className="mt-4 mb-1 modal-text-bold-sm"
                    style={{ color: Colors.darkest_gray }}
                  >
                    Responsável
                  </Row>

                  <Row
                    className="mt-2 mb-1 modal-text-xs"
                    style={{ color: Colors.darkest_gray }}
                  >
                    Buscar responsável
                  </Row>

                  <Row className="mb-4">
                    <input
                      className="default-input md"
                      placeholder="Digite o nome do responsável"
                      onChange={(v) => setSearch(v.target.value)}
                    />
                  </Row>
                </div>

                {listUsers.map((item, index) => {
                  var found = listUserChooser.find((i) => i.id == item.id);
                  return (
                    <div
                      key={index}
                      className="modal-item modal-item-edit"
                      onClick={() => SetarUser(item)}
                    >
                      <p
                        style={{
                          color:
                            found != undefined
                              ? Colors.brand_blue
                              : Colors.darkest_gray,
                        }}
                        className="modal-name mb-0"
                      >
                        {item.name}
                      </p>

                      <CheckBox
                        checked={found != undefined}
                        value={item}
                        onclick={SetarUser}
                      />
                    </div>
                  );
                })}
              </div>
            </Modal.Body>

            <Modal.Footer className="row justify-content-end align-items-center pb-0 mx-0">
              <Col xs={4} md={3} lg={3} className="px-0 mr-2">
                <div
                  className="secondary-btn"
                  onClick={() => handler_show_modal(false)}
                >
                  Cancelar
                </div>
              </Col>
              <Col xs={4} md={3} lg={3} className="px-0">
                <button
                  disabled={
                    (VerifyFields() == true ? false : true) ||
                    loadingBtn === "flex"
                  }
                  className="mb-0 modal-footer-btn btn-confirm"
                  style={{
                    backgroundColor:
                      VerifyFields() == true
                        ? Colors.brand_pink
                        : Colors.lighter_gray,
                  }}
                  onClick={() => Confirm()}
                >
                  <p
                    className="mb-0"
                    style={{
                      color:
                        VerifyFields() == true ? "white" : Colors.dark_gray,
                      display: loadingBtn == "none" ? "flex" : "none",
                    }}
                  >
                    Confirmar
                  </p>

                  <Spinner
                    style={{ display: loadingBtn }}
                    animation="border"
                    role="status"
                    variant="light"
                    size="sm"
                  />
                </button>
              </Col>
            </Modal.Footer>
          </>
        )}

        {page === 4 && (
          <>
            <Modal.Body
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 5,
                paddingBottom: 15,
              }}
            >
              <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col xs={8} md={10} lg={10} className="pl-0">
                    <div className="modal-title">Suspender avisos</div>
                  </Col>
                  <Col
                    xs={4}
                    md={2}
                    lg={2}
                    className="pr-0 d-flex justify-content-end"
                  >
                    <div
                      className="modal-close-btn"
                      onClick={() => handler_show_modal(false)}
                    >
                      <Icon color={Colors.darkest_gray} size={15} icon="x" />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="modal-list">
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <Row
                    className="mt-3 mb-1 modal-text-bold-sm"
                    style={{ color: Colors.darkest_gray }}
                  >
                    Motivo
                  </Row>
                </div>

                {listMotives.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="modal-item modal-item-edit"
                      onClick={() => {
                        setOtherMotive("");
                        setMotiveChooser(item);
                      }}
                    >
                      <p
                        style={{
                          color:
                            item.id === motiveChooser?.id
                              ? Colors.brand_blue
                              : Colors.darkest_gray,
                        }}
                        className="modal-name mb-0"
                      >
                        {item.name}
                      </p>

                      <div className="container-check">
                        <input
                          className={`input-round ${
                            item.id != motiveChooser?.id && "radio-inactive"
                          }`}
                          type="radio"
                          checked={item.id === motiveChooser?.id}
                          onClick={() => {
                            setOtherMotive("");
                            setMotiveChooser(item);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}

                {motiveChooser.name == "Outros" && (
                  <div className="mb-3 px-4">
                    <input
                      className="default-input md"
                      type="text"
                      placeholder="Digite outro motivo aqui"
                      value={otherMotive}
                      onChange={(e) => setOtherMotive(e.target.value)}
                    />
                  </div>
                )}

                <div
                  className="mt-4"
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                >
                  <p className="mt-4 mb-1 modal-text-bold-sm">Lesões</p>
                  <div className="d-flex">
                    <button
                      style={{
                        background:
                          hasWound == true
                            ? Colors.brand_blue
                            : Colors.lightest_gray,
                      }}
                      className="btn-click"
                      onClick={() => setHasWound(true)}
                    >
                      <p
                        className="mb-0"
                        style={{
                          color:
                            hasWound == true ? "white" : Colors.darker_gray,
                        }}
                      >
                        Com lesão
                      </p>
                    </button>
                    <button
                      style={{
                        background:
                          hasWound == false
                            ? Colors.brand_blue
                            : Colors.lightest_gray,
                      }}
                      className="btn-click"
                      onClick={() => {
                        setWounds([{ wound_id: 0, stage_id: 0 }]);
                        setHasWound(false);
                      }}
                    >
                      <p
                        className="mb-0"
                        style={{
                          color:
                            hasWound == false ? "white" : Colors.darker_gray,
                        }}
                      >
                        {" "}
                        Sem lesão
                      </p>
                    </button>
                  </div>

                  {hasWound == true && (
                    <div className="section-discharge mt-2">
                      {wounds.map((item, index) => {
                        return (
                          <Row key={index} className="mb-3">
                            <Col className="mb-3 mb-md-0" col={12} md={5}>
                              <Form.Label className="field-label">
                                Local da lesão
                              </Form.Label>
                              <Select
                                captureMenuScroll={false}
                                classNamePrefix="react-select"
                                placeholder={"Selecionar"}
                                options={listWounds}
                                isClearable={false}
                                onChange={(item) => {
                                  var newList = wounds.map((it, ind) => {
                                    if (ind == index) {
                                      it.stage_id = item.value;
                                    }
                                    return it;
                                  });
                                  setWounds(newList);
                                }}
                              />
                            </Col>

                            <Col col={12} md={5}>
                              <Form.Label className="field-label">
                                Estágio da lesão
                              </Form.Label>
                              <Select
                                captureMenuScroll={false}
                                classNamePrefix="react-select"
                                placeholder={"Selecionar"}
                                options={listStages}
                                isClearable={false}
                                onChange={(item) => {
                                  var newList = wounds.map((it, ind) => {
                                    if (ind == index) {
                                      it.wound_id = item.value;
                                    }
                                    return it;
                                  });
                                  setWounds(newList);
                                }}
                              />
                            </Col>

                            <Col col={12} md={2}>
                              <button
                                title="Remover local"
                                className="btn-remove w-100"
                                disabled={index == 0 ? true : false}
                                onClick={() => {
                                  var newList = wounds.filter((c, d) => {
                                    if (index != d) {
                                      return c;
                                    }
                                  });
                                  setWounds(newList);
                                }}
                              >
                                <Icon
                                  color={
                                    index == 0
                                      ? Colors.light_gray
                                      : Colors.darkest_gray
                                  }
                                  size={15}
                                  icon="minus"
                                />
                              </button>
                            </Col>
                          </Row>
                        );
                      })}

                      <button
                        className="d-flex align-items-center mt-3 reset-button"
                        onClick={() =>
                          setWounds([...wounds, { wound_id: 0, stage_id: 0 }])
                        }
                      >
                        <Icon
                          color={Colors.brand_pink}
                          size={15}
                          icon="plus"
                          className="mr-2"
                        />
                        <p className="mb-0 add-other-text">
                          Adicionar outra lesão
                        </p>
                      </button>
                    </div>
                  )}
                </div>

                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <Row
                    className="mt-4 mb-1 modal-text-bold-sm"
                    style={{ color: Colors.darkest_gray }}
                  >
                    Responsável
                  </Row>

                  <Row
                    className="mt-2 mb-1 modal-text-xs"
                    style={{ color: Colors.darkest_gray }}
                  >
                    Buscar responsável
                  </Row>

                  <Row className="mb-4">
                    <input
                      className="default-input md"
                      placeholder="Digite o nome do responsável"
                      onChange={(v) => setSearch(v.target.value)}
                    />
                  </Row>
                </div>

                {listUsers.map((item, index) => {
                  var found = listUserChooser.find((i) => i.id == item.id);
                  return (
                    <div
                      key={index}
                      className="modal-item modal-item-edit"
                      onClick={() => SetarUser(item)}
                    >
                      <p
                        style={{
                          color:
                            found != undefined
                              ? Colors.brand_blue
                              : Colors.darkest_gray,
                        }}
                        className="modal-name mb-0"
                      >
                        {item.name}
                      </p>

                      <CheckBox
                        checked={found != undefined}
                        value={item}
                        onclick={SetarUser}
                      />
                    </div>
                  );
                })}
              </div>
            </Modal.Body>

            <Modal.Footer className="row justify-content-end align-items-center pb-0 mx-0">
              <Col xs={4} md={3} lg={3} className="px-0 mr-2">
                <div
                  className="secondary-btn"
                  onClick={() => handler_show_modal(false)}
                >
                  Cancelar
                </div>
              </Col>
              <Col xs={6} md={5} className="px-0">
                <button
                  disabled={
                    (VerifyFields() == true ? false : true) ||
                    loadingBtn === "flex"
                  }
                  className="mb-0 modal-footer-btn btn-confirm"
                  style={{
                    backgroundColor:
                      VerifyFields() == true
                        ? Colors.brand_pink
                        : Colors.lighter_gray,
                  }}
                  onClick={() => Suspend()}
                >
                  <p
                    className="mb-0"
                    style={{
                      color:
                        VerifyFields() == true ? "white" : Colors.dark_gray,
                      display: loadingBtn == "none" ? "flex" : "none",
                    }}
                  >
                    Suspender avisos
                  </p>

                  <Spinner
                    style={{ display: loadingBtn }}
                    animation="border"
                    role="status"
                    variant="light"
                    size="sm"
                  />
                </button>
              </Col>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
}

export default ModalDecubito;
