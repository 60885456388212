import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Container,
    Row,
    Button,
} from "react-bootstrap";
import MainNavbar from "../../components/Nav/MainNavbar";

import Colors from "../../styles/Colors";
import "../../styles/global.css";
import "./styles.css";

import Icon from "../../functions/Icon";

function Descubra({ history }) {
    const userUnit = useSelector((state) => state.userUnit);

    return (
        <div>
            <MainNavbar history={history} />

            <Container fluid className="main-container" style={{ display: 'flex', flexDirection: 'column' }}>
                <h2 className="title mb-4">Descubra</h2>

                <section id="hospital">
                    <Row className="justify-content-start align-items-center mb-3">
                        <Col>
                            <div className="medium-title">Hospital</div>
                        </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                        <Col xs={6} md={4} lg={3} onClick={() => history.push({ pathname: "/Descubra/Comunicados" })}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="chat-rounded" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Comunicados
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={6} md={4} lg={3} onClick={() => history.push({ pathname: "/Descubra/Protocolos" })}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="list" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Protocolos
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={6} md={4} lg={3} onClick={() => history.push({ pathname: "/Descubra/Bundle" })}>
                        <div className="main-card">
                            <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="check-square" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Bundle
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </section>

                <section id="comunidade">
                    <Row className="justify-content-start align-items-center mt-5 mb-3">
                        <Col>
                            <div className="medium-title">Comunidade</div>
                        </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                        {userUnit.list.map((item) => {
                            return (
                                <Col xs={6} md={4} lg={3}>
                                    <div className="main-card">
                                        <Row className="mb-3">
                                            <Col>
                                                <Icon color={Colors.brand_blue} size={24} icon="stethoscope" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="card-text">
                                                {item.name}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            )}
                        )}
                        <Col xs={6} md={4} lg={3}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="home-plus" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Hospital
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="brasil" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Brasil
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </section>

                <section id="participe">
                    <Row className="justify-content-start align-items-center mt-5 mb-3">
                        <Col>
                            <div className="medium-title">Participe</div>
                        </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                        <Col xs={6} md={4} lg={3}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="courses" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Cursos
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="calendar-star" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Eventos
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </section>

                <section id="saiba-mais">
                    <Row className="justify-content-start align-items-center mt-5 mb-3">
                        <Col>
                            <div className="medium-title">Saiba mais</div>
                        </Col>
                    </Row>
                    <Row className="justify-content-start align-items-center">
                        <Col xs={6} md={4} lg={3}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="file" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Notícias
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="articles" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Artigos
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="law" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Leis
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <div className="main-card">
                                <Row className="mb-3">
                                    <Col>
                                        <Icon color={Colors.brand_blue} size={24} icon="notebook" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="card-text">
                                        Notas técnicas
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </section>

            </Container>
        </div>
    );
}

export default Descubra;