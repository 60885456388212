import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainNavbar from "../../components/Nav/MainNavbar";
import PacientesView from "./PacientesView";
import { useLocation } from "react-router-dom";

import "../../styles/global.css";
import "./styles.css";
import AddPatient from "../../components/Modals/AddPatient";
import { useDispatch, useSelector } from "react-redux";

var newP = undefined;
var filter_overview = undefined;
function Pacientes({ history }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const patientHomeTab = useSelector((state) => state.patientHomeTab);
  const [aba, setAba] = useState("ativos");
  const [newPatient, setNewPatient] = useState({});
  const [reloadBeacons, setReloadBeacons] = useState(false);
  const [reloadPatients, setReloadPatients] = useState(false);

  useEffect(() => {
    newP = undefined;
    filter_overview = undefined;
    if (location.state?.addPatient) {
      //Reset state
      newP = true;
      let state = { ...history.location.state };
      delete state.addPatient;
      history.replace({ ...history.location, state });
    }

    if (location.state?.discharge !== undefined) {
      let dischargeState = location.state.discharge;
      if (dischargeState) {
        setAba("alta");
      } else {
        setAba("ativos");
      }
      let state = { ...history.location.state };
      delete state.discharge;
      history.replace({ ...history.location, state });
      //Reset state
    }

    if (location.state?.filter_overview) {
      filter_overview = location.state?.filter_overview;
      //Reset state
      let state = { ...history.location.state };
      delete state.filter_overview;
      history.replace({ ...history.location, state });
    }
  }, [location]);

  function setarAba(aba) {
    setAba(aba);
    dispatch({
      type: "SET_TAB_PATIENT_PAGE",
      selected: aba,
    });
  }

  useEffect(() => {
    if (patientHomeTab.selected) {
      setAba(patientHomeTab.selected);
    }
  }, []);

  return (
    <div>
      <MainNavbar history={history} screen="patients" />

      <Container fluid className="main-container">
        <div className="px-2">
          <Row
            className="w-100"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Col xs={12} md={4} lg={3}>
              <div className="page-title">Pacientes</div>
            </Col>
            <AddPatient
              add_patient={newP}
              history={history}
              handler_new_patient={setNewPatient}
              reload_beacons={reloadBeacons}
              handler_reload_beacons={setReloadBeacons}
              reload_patients={reloadPatients}
              handler_reload_patients={setReloadPatients}
            />
          </Row>

          <Row className="tabs mt-4">
            <Col xs={6} md={2} lg={2} onClick={() => setarAba("ativos")}>
              <h6
                className={aba == "ativos" ? "active-text" : ""}
                onClick={() => setarAba("ativos")}
              >
                Internados
              </h6>
            </Col>
            <Col xs={6} md={2} lg={2} onClick={() => setarAba("alta")}>
              <h6
                className={aba == "alta" ? "active-text" : ""}
                onClick={() => setarAba("alta")}
              >
                Alta
              </h6>
            </Col>
          </Row>

          <Row className="tab-bar">
            <Col
              xs={2}
              md={2}
              lg={2}
              onClick={() => setarAba("ativos")}
              className={aba == "ativos" ? "active-tab" : ""}
            />
            <Col
              xs={2}
              md={2}
              lg={2}
              onClick={() => setarAba("alta")}
              className={aba == "alta" ? "active-tab" : ""}
            />
            <Col xs={8} md={8} lg={8}></Col>
          </Row>
        </div>

        {aba == "ativos" && (
          <PacientesView
            new_patient={newPatient}
            handler_new_patient={setNewPatient}
            ativos={true}
            history={history}
            filter_overview={filter_overview}
            handler_reload_beacons={setReloadBeacons}
            reload_patients={reloadPatients}
          />
        )}

        {aba == "alta" && (
          <PacientesView
            new_patient={newPatient}
            handler_new_patient={setNewPatient}
            ativos={false}
            history={history}
            filter_overview={filter_overview}
            handler_reload_beacons={setReloadBeacons}
          />
        )}
      </Container>
    </div>
  );
}

export default Pacientes;
