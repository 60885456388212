const INITIAL_STATE = {
  dados: [],
};

function user(state = INITIAL_STATE, action) {
  if (action.type === "USER_LOGIN") {
    return { ...state, dados: action.dados };
  } else if (action.type === "USER_LOGOUT") {
    state = INITIAL_STATE;
  }

  return state;
}

export default user;
